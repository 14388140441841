import React, { useState } from 'react'
import styles from './index.module.scss'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

interface Props {
  _text: string
  _selectData: any
  _variant: 'text' | 'outlined' | 'contained'
  _colorBG: 'blue' | 'green' | 'red' | 'white' | 'black' | 'secondary' | 'darkblue' | 'gray'
  _colorText: 'blue' | 'green' | 'red' | 'white' | 'black' | 'secondary' | 'gray'
  _defualt?: any
}

const listColor: any = [
  {
    name: 'gray',
    code: '#626262',
  },
  {
    name: 'darkblue',
    code: '#004CA8',
  },
  {
    name: 'blue',
    code: '#3A77DA',
  },
  {
    name: 'green',
    code: '#8AC82D',
  },
  {
    name: 'red',
    code: '#FF6161',
  },
  {
    name: 'white',
    code: '#FFFFFF',
  },
  {
    name: 'black',
    code: '#000000',
  },
  {
    name: 'secondary',
    code: '#5357BB',
  },
]

const ButtonSelect: React.FC<Props> = (props: Props): JSX.Element => {
  const codeBG: string = listColor.find((ele: any) => ele.name === props._colorBG).code
  const codeText: string = listColor.find((ele: any) => ele.name === props._colorText).code
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  return (
    <>
      <div className={styles.container}>
        <Stack direction="row" spacing={2} style={{ height: '100%' }}>
          <Button
            onClick={handleClick}
            size="small"
            variant={props._variant}
            endIcon={<KeyboardArrowDownIcon />}
            component="label"
            style={{ backgroundColor: codeBG, color: codeText, height: 'inherit', borderColor: codeText }}
            defaultValue={'FF'}
          >
            <span>{props._text}</span>
          </Button>
        </Stack>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {props._selectData.map((data: any) => (
            <MenuItem
              key={data.menu}
              onClick={() => {
                setAnchorEl(null)
              }}
            >
              <p>{data.menu}</p>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  )
}
export default ButtonSelect
