interface IAction {
  type: string
  companyID: string
}

export interface IInitialStateCompanyID {
  companyID: string
}

const initialState: IInitialStateCompanyID = {
  companyID: '',
}

const reducer = (state = initialState, action: IAction): any => {
  switch (action.type) {
    case 'COMPANY/CHANGE':
      return {
        ...state,
        companyID: action.companyID.toUpperCase(),
      }
    default:
      return state
  }
}

export default reducer
