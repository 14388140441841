import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { ButtonComponent, NavSideBarComponent, NavTopBarComponent, ButtonSelect } from '../../component'
import search from '../../image/search.svg'
import { useNavigate } from 'react-router-dom'
import CollapsibleTable from './table'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { queryListPosition } from '../../adapter/xhr'
const MainPosition: React.FC = (): JSX.Element => {
  const [searchbar, setSearchbar] = useState(false)
  const [deleteList, setDeletelist] = useState()
  const navigate = useNavigate()
  //! Data
  const [listPosition, setListPosition] = useState([])
  useEffect(() => {
    queryListPosition().then((res: any) => {
      const list = res.data.data.listPosition.items
      setListPosition(list)
    })
  }, [deleteList])
  // console.log('listPosition', listPosition)
  // console.log('deleteList', deleteList)
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            <h1 className={styles.title}>ตำแหน่งงาน</h1>
            <div className={styles.head}>
              <div className={styles.iconBar}>
                {!searchbar && (
                  <div className={styles.ellipse}>
                    <img src={search} alt="" className={styles.search} onClick={() => setSearchbar(true)} />
                  </div>
                )}

                {searchbar && (
                  <div className={styles.searchbar} onClick={() => setSearchbar(false)}>
                    <TextField
                      label="Search Employee"
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: '25ch' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={search} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.cardTable}>
            <div className={styles.head}>
              <h1 className={styles.title}>รายชื่อตำแหน่งงาน</h1>
              <div className={styles.buttonBox}>
                <div className={styles.btAdd}>
                  <ButtonComponent
                    _isIcon="add"
                    _variant="contained"
                    _text="เพิ่มตำแหน่งงาน"
                    _colorText="white"
                    _colorBG="blue"
                    _type="button"
                    _functionOnClick={() => navigate('../employee/position/add')}
                  />
                </div>
              </div>
            </div>
            <div className={styles.table}>
              <CollapsibleTable list={listPosition} setDeletelist={setDeletelist} />
            </div>
          </div>
        </div>
      </div>
      <div className="footer">Footer54321</div>
    </div>
  )
}

export default MainPosition
