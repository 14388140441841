import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../../state/actions'
import * as yup from 'yup'
import { Formik } from 'formik'
import { ButtonComponent, ButtonSelectCustom, InputTimeComponentCustom, UploadButton } from '../../../component'
import { InputDateComponentCustome, InputSelectComponentCustom } from '../../../component/InputsFormik'
import { CreateLeaveDocument } from '../../../adapter/xhr'
import { ELeave, ESaveDescrip, SaveType, toTime } from '../EType'
import { CheckIntervals } from '../../../utils/common'
import swal from 'sweetalert'
import Switch from '../../../component/Switch'

export const ModalLeave: React.FC<any> = (props: any): JSX.Element => {
  const [type, setType] = React.useState('leave')
  const [status, setStatus] = React.useState('APPROVE')
  const dispatch = useDispatch()

  const statusType = (e: any) => {
    setStatus(e)
  }

  const LeaveList = [
    { label: 'ลาป่วย', value: 'REASON_SICK' },
    { label: 'ลาพักร้อน', value: 'REASON_ANNUAL_LEAVE' },
    { label: 'ลากิจ', value: 'REASON_PERSONAL_LEAVE' },
    {
      label: 'ขาด',
      value: 'ABSENT',
    },
  ]
  const LeavFormList = [
    {
      label: 'กำหนดเอง',
      value: 'CUSTOM_TIME',
    },
    {
      label: 'ลาเต็มวัน',
      value: 'FULLDAY_LEAVE',
    },
    {
      label: 'ลาครึ่งวันเช้า',
      value: 'HALFDAY_MORNING_LEAVE',
    },
    {
      label: 'ลาครึ่งวันบ่าย',
      value: 'HALFDAY_AFTERNOON_LEAVE',
    },
  ]

  const date = props.date
  const employeeId = props.attendance.employeeID
  const validationSchema = yup.object({
    leaveType: yup.string().required('เลือกประเภทการลา'),
  })

  const otherProps = props

  const [leaveForm, setLeaveForm] = useState('')
  const [value, setValues] = useState<any>('')

  const SetIntervalsLeave = (leaveForm: any, formik: any) => {
    if (leaveForm === 'FULLDAY_LEAVE') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', otherProps.attendance.shiftStartTime)
      formik.setFieldValue('endedAt', otherProps.attendance.shiftEndTime)
    } else if (leaveForm == 'HALFDAY_MORNING_LEAVE') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', otherProps.attendance.shiftStartTime)
      formik.setFieldValue('endedAt', otherProps.attendance.breakTimeList[0].startTime)
    } else if (leaveForm == 'HALFDAY_AFTERNOON_LEAVE') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', otherProps.attendance.breakTimeList[0].endTime)
      formik.setFieldValue('endedAt', otherProps.attendance.shiftEndTime)
    } else if (leaveForm == 'CUSTOM_TIME') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', '')
      formik.setFieldValue('endedAt', '')
    }
    return leaveForm
  }

  useEffect(() => {
    SetIntervalsLeave(leaveForm, value)
  }, [leaveForm, value])

  return (
    <Modal
      open={props.openModalLeave}
      onClose={() => {
        props.setOpenModalLeave(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalLeaveEdaite}>
        <div className={styles.card}>
          <Formik
            initialValues={{
              leaveType: '',
              startedAt: '',
              endedAt: '',
              leaveDocumentAttachment: '',
              isLeaveDeductMoney: false,
              status: '',
              leaveForm: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              const DocumentLeaveVariables = {
                input: {
                  date: date,
                  employeeID: employeeId,
                  status: status,
                  leaveType: values.leaveType,
                  startedAt: values.startedAt,
                  endedAt: values.endedAt,
                  isLeaveDeductMoney: values.isLeaveDeductMoney,
                  leaveForm: values.leaveForm,
                },
              }
              console.log(DocumentLeaveVariables, 'DocumentLeaveVariables')
              console.log(otherProps.leaveList, 'otherProps')
              const NewIntervals: any = { startedAt: values.startedAt, endedAt: values.endedAt }
              const resultIntervalsCheck = CheckIntervals(otherProps.leaveList, NewIntervals)
              console.log(resultIntervalsCheck, 'resultIntervalsCheck')

              if (resultIntervalsCheck === true) {
                swal({
                  icon: 'error',
                  title: 'มีช่วงเวลาการลานี้แล้ว',
                  text: 'สามารถสร้างรายการจากเอกสารแนะนำหรือเลือกช่วงเวลาอื่น',
                  dangerMode: true,
                })
              } else if (resultIntervalsCheck === false) {
                dispatch(
                  allAction.modalConfirmAction.setOpenModalConfirm(
                    `${ESaveDescrip[`${status}`]} ${ELeave[`${values.leaveType}`]} `,
                    `วันที่ ${date} เวลา ${values.startedAt} - ${values.endedAt} `,
                    'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                    `ใช่,${ESaveDescrip[`${status}`]}`,
                    () => {
                      CreateLeaveDocument(DocumentLeaveVariables).then((res: any) => {
                        props.leaveList.push({
                          leaveType: values.leaveType,
                          startedAt: values.startedAt,
                          endedAt: values.endedAt,
                          leaveDocumentAttachment: values.leaveDocumentAttachment,
                          status: status,
                          leaveForm: values.leaveForm,
                          type: type,
                          leaveRequestID: res.data.data.createLeaveRequest.id,
                        })
                        props.setFieldValue(`leaveList`, props.leaveList)
                      })
                      console.log(DocumentLeaveVariables, 'Document')
                      dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันทำรายการเรียบร้อย', '', 'success'))
                      dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                      props.setOpenModalLeave(false)
                    },
                  ),
                )
              }
            }}
          >
            {(formikProps: any) => (
              <form className={styles.wrapperForm} onSubmit={formikProps.handleSubmit}>
                <div className={styles.header}>
                  <div className={styles.bt}>
                    <ButtonComponent
                      _type={'button'}
                      _colorBG={'white'}
                      _colorText={'red'}
                      _variant={'text'}
                      _text={'ยกเลิก'}
                      _sx={{ fontSize: '16px' }}
                      _functionOnClick={() => otherProps.setOpenModalLeave(false)}
                    />
                  </div>
                  <p className={styles.textHead}>สร้างใหม่</p>
                  <div className={styles.btSelect}>
                    <ButtonSelectCustom
                      _text={'Save'}
                      _selectData={SaveType}
                      _onchange={(e: any) => {
                        statusType(e)
                      }}
                    />
                  </div>
                </div>
                <div className={styles.FormGroup}>
                  <div className={styles.filedInputFlex}>
                    <p>ประเภทการลา</p>
                    <div className={styles.inputBox}>
                      <InputSelectComponentCustom
                        _name="leaveType"
                        _label={'ประเภทการลา'}
                        _menuList={LeaveList}
                        _helperText={formikProps.errors.leaveType}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.SwitchArea}>
                      <div className={styles.switch}>
                        <Switch
                          options={[
                            { label: 'ลาไม่หักเงิน', value: 'leave' },
                            { label: 'ลาหักเงิน', value: 'leaveDeduct' },
                          ]}
                          onChange={(value: any) => {
                            setType(value)
                            console.log(value, 'val')
                            if (value === 'leaveDeduct') {
                              formikProps.setFieldValue('isLeaveDeductMoney', true)
                            } else if (value === 'leave') {
                              formikProps.setFieldValue('isLeaveDeductMoney', false)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.filedInputFlex}>
                    <p>รูปแบบการลา</p>
                    <div className={styles.inputBox}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          shrink
                          sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white', padding: '0px 5px' }}
                        >
                          {'รูปแบบการลา'}
                        </InputLabel>
                        <Select
                          id="leaveForm"
                          defaultValue={formikProps.values.leaveForm}
                          sx={{ borderRadius: 3, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
                          // value={formikProps.values.leaveForm}
                          label={'รูปแบบการลา'}
                          onChange={(e: any) => {
                            setLeaveForm(e.target.value)
                            setValues(formikProps)
                          }}
                          name="leaveForm"
                          // disabled={formikProps._disabled}
                        >
                          {LeavFormList.map((data: any, index: number) => (
                            <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
                              {console.log(data, 'daat')}
                              {data.label}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* <p className={styles.errorMsg}>
                          {touched[formikProps._name] && errors[formikProps._name] && formikProps._helperText}
                        </p> */}
                      </FormControl>
                    </div>
                  </div>

                  <div className={styles.filedInputTime}>
                    <p>ช่วงเวลา</p>
                    <div className={styles.GroupBox}>
                      <div className={styles.InputTime}>
                        {/* <Datepicker view='' /> */}
                        <InputTimeComponentCustom
                          _name="startedAt"
                          _label={'เริ่มต้น'}
                          _helperText={formikProps.errors.startedAt}
                          _value={formikProps.values.startedAt}
                          // _disabled={formikProps.values.leaveForm === 'CUSTOM_TIME' ? false : true}
                        />
                      </div>
                      <div className={styles.InputTime}>
                        <InputTimeComponentCustom
                          _name="endedAt"
                          _label={'สิ้นสุด'}
                          _helperText={formikProps.errors.endedAt}
                          _value={formikProps.values.endedAt}
                          // _disabled={formikProps.values.leaveForm === 'CUSTOM_TIME' ? false : true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.filedInputFlex}>
                    <p>แนบไฟล์</p>
                    <div className={styles.inputUpload}>
                      <div className={styles.UploadBt}>
                        <UploadButton _name="leaveDocumentAttachment" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.filedInputFlex}>
                    <p>วันที่</p>
                    <div className={styles.inputBox}>
                      <InputDateComponentCustome _name={'date'} _value={date} _disabled={true} _label={'วันที่'} />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
