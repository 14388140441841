import React, { useState, useEffect, useRef } from 'react'
import styles from './index.module.scss'
// import { Formik } from 'formik'
import blueCalendar from '../../image/blueCalendar.svg'

// import * as yup from 'yup'
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent } from '../../component'
import { ListTimeAttenLog, createTimeAttendanceBatch, getTimeAttendanceStreamCount } from '../../adapter/xhr'

import { paginationFunc, packCSVFile, minuteToHourAndMinute, countHourScanInOutString } from '../../utils/common'
// import TextField from '@mui/material/TextField'
// import CircularProgress from '@mui/material/CircularProgress'
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { TableAttend } from './Table'
import moment from 'moment'
import { TimeAttendance } from '../../utils/generated'
import { PaginationBar } from '../../component'

const AttendanceLogPage: React.FC = (): JSX.Element => {
  // const { setFieldValue } = useFormikContext<any>()
  const [logProcessed, setLogProcessed] = useState<number>(0)
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false)
  const [logTotal, setLogTotal] = useState<number>(0)
  const [isDateRangeOpen, setIsDateRangeOpen] = useState<boolean>(false)
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [listItemIndex, setListItemIndex] = useState<number>(0)
  const [listAttendanceLog, setAttendanceLog] = useState<TimeAttendance[]>([])

  const [pageSite, setPageSite] = useState<number>(30)
  const inputFile = useRef<any>()

  const myCurrentDate = new Date()
  const myPastDate = new Date(myCurrentDate)
  myPastDate.setDate(myPastDate.getDate() - 7)

  const [dateRangeStartEnd, setDateRangeStartEnd] = React.useState<DateRange<Date>>([myPastDate, new Date()])

  useEffect(() => {
    ListTimeAttenLog({ nextToken: nextToken }).then((res: any) => {
      console.log(res.data.data.listTimeAttendance.items, 'res')
      if (res.data.data.listTimeAttendance.items && res.data.data.listTimeAttendance.items.length > 0) {
        setAttendanceLog(res.data.data.listTimeAttendance.items)
      }
      setNextToken(res.data.data.listTimeAttendance.nextToken)
    })
  }, [])

  //! onClick
  const csvFileReader = (csvFile: any) => {
    const reader = new FileReader()
    reader.onload = async function (x) {
      if (x.target) {
        const timeAttendanceGenerate = packCSVFile(x.target.result)
        console.log(timeAttendanceGenerate, 'generate file items')
        if (timeAttendanceGenerate.items && timeAttendanceGenerate.items.length > 0) {
          const CHUNK_SIZE = 250
          setLogProcessed(timeAttendanceGenerate.items.length)
          setIsLoadingFile(true)
          for (let i = 0, j = timeAttendanceGenerate.items.length; i < j; i += CHUNK_SIZE) {
            const variableCreateTimeAttendanceBatch = {
              input: { items: timeAttendanceGenerate.items.slice(i, i + CHUNK_SIZE) },
              startDate: moment(dateRangeStartEnd[0]).format('YYYY-MM-DD'),
              endDate: moment(dateRangeStartEnd[1]).format('YYYY-MM-DD'),
            }
            console.log(variableCreateTimeAttendanceBatch, 'packCSVFile(x.target.result)')
            const successString = await (
              await createTimeAttendanceBatch(variableCreateTimeAttendanceBatch)
            ).data.data.createTimeAttendanceBatch
            if (successString === 'Success') {
              if (i + CHUNK_SIZE <= timeAttendanceGenerate.items.length) {
                setLogTotal(i + CHUNK_SIZE)
              } else {
                setIsLoadingFile(false)
                setLogTotal(0)
                setLogProcessed(0)
              }
              console.log('timeAttendanceGenerate.items', timeAttendanceGenerate.items)
            }
          }
        }
        const intervalStreamCount = setInterval(() => {
          getTimeAttendanceStreamCount({})
            .then((res: any) => {
              console.log(res, 'res StreamCount')
              const responseLogTotal = res.data.data.getTimeAttendanceReportStreamCount.logTotal
              const responseLogProcessed = res.data.data.getTimeAttendanceReportStreamCount.logProcessed
              setLogTotal(responseLogTotal)
              setLogProcessed(responseLogProcessed)
              // console.log(!res.data.data.getTimeAttendanceReportStreamCount)
              if (!res.data.data.getTimeAttendanceReportStreamCount || responseLogTotal === responseLogProcessed) {
                setIsLoadingFile(false)
                clearInterval(intervalStreamCount)
                ListTimeAttenLog({}).then((res: any) => {
                  if (res.data.data.listTimeAttendance.items && res.data.data.listTimeAttendance.items.length > 0) {
                    setAttendanceLog(res.data.data.listTimeAttendance.items)
                  }
                  setNextToken(res.data.data.listTimeAttendance.nextToken)
                })
              } else {
                setIsLoadingFile(true)
              }
            })
            .catch(() => {
              setIsLoadingFile(false)
              clearInterval(intervalStreamCount)
              ListTimeAttenLog({}).then((res: any) => {
                if (res.data.data.listTimeAttendance.items && res.data.data.listTimeAttendance.items.length > 0) {
                  setAttendanceLog(res.data.data.listTimeAttendance.items)
                }
                setNextToken(res.data.data.listTimeAttendance.nextToken)
              })
            })
        }, 1000)
      }
    }
    reader.readAsText(csvFile.target.files[0])
  }

  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            <h1 className={styles.title}>Attendance Log</h1>
          </div>

          <div className={styles.contain}>
            <section className={styles.header}>
              <article className={styles.headerText}>
                <h1>Upload CSV</h1>
                {isLoadingFile ? (
                  <section className={styles.progressbarBox}>
                    <Box sx={{ minWidth: '200px', width: '100%' }}>
                      <LinearProgress
                        sx={{ backgroundColor: '#D9D9D9', borderRadius: '10px' }}
                        variant="determinate"
                        value={(logTotal / logProcessed) * 100}
                      />
                    </Box>
                    <span>{`${((logTotal / logProcessed) * 100).toFixed()}%`}</span>
                  </section>
                ) : null}
              </article>
              <div className={styles.buttonBox}>
                <div className={styles.dateToImportContainer}>
                  <p className={styles.dateToImportTopic}>เลือกวันที่นำเข้าข้อมูล</p>
                  <div className={styles.dateRangeContainer}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateRangePicker
                        // startText="Check-in"
                        // endText="Check-out"
                        open={isDateRangeOpen}
                        onOpen={() => {
                          setIsDateRangeOpen(true)
                        }}
                        onClose={() => {
                          setIsDateRangeOpen(false)
                        }}
                        value={dateRangeStartEnd}
                        onChange={(newValue: any) => {
                          setDateRangeStartEnd(newValue)
                        }}
                        renderInput={() => <></>}
                        // renderInput={(startProps: any, endProps: any) => (
                        //   <React.Fragment>
                        //     <TextField inputRef={inputDateRank} {...startProps} />
                        //     <TextField {...endProps} />
                        //   </React.Fragment>
                        // )}
                      />
                    </LocalizationProvider>
                  </div>
                  <img
                    className={styles.blueCalendar}
                    src={blueCalendar}
                    onClick={() => {
                      setIsDateRangeOpen(true)
                    }}
                    alt=""
                  />
                  <section className={styles.dateRankStartEnd}>{`${moment(dateRangeStartEnd[0]).format(
                    'YYYY/MM/DD',
                  )} - ${moment(dateRangeStartEnd[1]).format('YYYY/MM/DD')}`}</section>
                </div>
                <input
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      csvFileReader(e)
                    }
                  }}
                  className={styles.inputFile}
                  accept=".csv"
                  ref={inputFile}
                />
                <div className={styles.buttonImport}>
                  <ButtonComponent
                    _type="button"
                    _colorBG="blue"
                    _colorText="white"
                    _variant="outlined"
                    _isIcon="add"
                    _text="Import"
                    _functionOnClick={() => {
                      inputFile.current.click()
                    }}
                  />
                </div>
              </div>
            </section>
            <div className={styles.TableAttend}>
              <TableAttend
                log={
                  paginationFunc(listAttendanceLog, pageSite)[listItemIndex]
                    ? paginationFunc(listAttendanceLog, pageSite)[listItemIndex]
                    : []
                }
              />
            </div>
            <PaginationBar
              _dropDownValue={pageSite}
              _onDropdownChange={(e: any) => {
                setPageSite(e.target.value)
                setListItemIndex(0)
              }}
              _selectedNumber={listItemIndex + 1}
              _numberList={paginationFunc(listAttendanceLog, pageSite).map((e: any, index: number) => index + 1)}
              _setSelectedNumber={(event: any, number: number, index: number) => {
                if (listItemIndex !== index) {
                  setListItemIndex(index)
                  if (index + 2 === paginationFunc(listAttendanceLog, pageSite).length && nextToken) {
                    ListTimeAttenLog({ nextToken: nextToken }).then((res: any) => {
                      if (res.data.data.listTimeAttendance.items && res.data.data.listTimeAttendance.items.length > 0) {
                        setAttendanceLog([...listAttendanceLog, ...res.data.data.listTimeAttendance.items])
                      }
                      setNextToken(res.data.data.listTimeAttendance.nextToken)
                    })
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="footer">Footer</div>
    </div>
  )
}

export default AttendanceLogPage
