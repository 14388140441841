import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import dropIcon from '../../image/sort.svg'
import upIcon from '../../image/sortUp.svg'
import Avatar from '@mui/material/Avatar'
import editIcon from '../../image/editicon.svg'

export const TableMember: React.FC<any> = (props: any): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const member = [
    {
      no: 1,
      name: 'Thanyarat.S',
      email: 'Thanyarat@gmail.com',
      path: '/static/images/avatar/1.jpg',
      role: 'User',
      department: 'Product',
      position: 'Marketing Engineering',
    },
    {
      no: 2,
      name: 'Admin.A',
      email: 'Thanyarat@gmail.com',
      path: '/static/images/avatar/1.jpg',
      role: 'Admin',
      department: 'Marketing',
      position: 'Marketing Engineering',
    },
    {
      no: 3,
      name: 'Beam.B',
      email: 'Thanyarat@gmail.com',
      path: '/static/images/avatar/1.jpg',
      role: 'Employee',
      department: 'Marketing',
      position: 'Marketing Engineering',
    },
  ]
  React.useEffect(() => {
    setUsers(member)
  }, [])
  const [users, setUsers] = React.useState([] as any)
  //sorting
  const [order, setOrder] = React.useState('ASC')
  const sorting = (col: any) => {
    if (order === 'ASC') {
      const sorted = [...users].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1))
      setUsers(sorted)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...users].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1))
      setUsers(sorted)
      setOrder('ASC')
    }
  }

  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="left" style={{ minWidth: '100px' }}>
              <div className={styles.headcolumn}>
                <h3>Username</h3>{' '}
                {order === 'ASC' ? (
                  <img src={dropIcon} onClick={() => sorting('name')} />
                ) : (
                  <img src={upIcon} onClick={() => sorting('name')} />
                )}
              </div>
            </TableCell>
            <TableCell align="left" style={{ minWidth: '100px' }}>
              <h3>Role</h3>
            </TableCell>
            <TableCell align="center" style={{ minWidth: '120px' }}>
              <h3>Department</h3>
            </TableCell>
            <TableCell align="center" style={{ minWidth: '120px' }}>
              <h3>Position</h3>
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: any, index: any) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': {} }}>
              <TableCell component="th" scope="row" align="center">
                <Avatar alt="" src={user.path} />
              </TableCell>
              <TableCell align="left">
                {user.name}
                <br />
                {user.email}
              </TableCell>
              <TableCell align="left">{user.role}</TableCell>
              <TableCell align="center">{user.department}</TableCell>
              <TableCell align="center">{user.position}</TableCell>
              <TableCell align="center">
                <img
                  src={editIcon}
                  alt=""
                  className={styles.editIcon}
                  onClick={() => navigate('../usermanage/user/view')}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
