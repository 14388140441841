import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { ProfileUpload, InputDateComponent, InputRadioComponent, UploadButton } from '../../component'
import {
  InputCheckboxComponentCustom,
  InputDateComponentCustome,
  InputNumberComponentCustom,
  InputNumberUnitComponent,
  InputSelectComponentCustom,
  InputTextComponentCustom,
} from '../../component/InputsFormik'
import CustomizedSwitches from '../../component/AntSwitch'
import {
  Box,
  CircularProgress,
  Input,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material'
import deleteIcon from '../../image/deleteRedIcon.svg'
import questionIcon from '../../image/question.svg'
import PlusIcon from '../../image/plus.svg'
import { useNavigate } from 'react-router-dom'
// import axios from 'axios'
import { queryListDepartment, queryListPosition, queryListShift, UpdateEmployeeProfile } from '../../adapter/xhr'
// import { listDepartment } from '../../adapter/xhr/query'
import ModalTable from './modal'
// import { isValid } from 'date-fns'
import moment from 'moment'
import { useFormikContext } from 'formik'
import { handleUploadFile } from '../../utils/common'
import FileIcon from '../../image/fileUpload.svg'
import FileIconWhite from '../../image/fileWhite.svg'
import ProfileBg from '../../image/profile-BG.svg'
import IconCamera from '../../image/camera.svg'

const ENameTitle = [
  { label: 'นาย', value: 'MR' },
  { label: 'นางสาว', value: 'MISS' },
  { label: 'นาง', value: 'MRS' },
]

const EGenger = [
  { label: 'ชาย', value: 'MALE' },
  { label: 'หญิง', value: 'FEMALE' },
]
const mockUpSelect = [
  { label: 'นาย', value: 'MR' },
  { label: 'นางสาว', value: 'MISS' },
]

const EPersonalRelation = [
  { label: 'โสด', value: 'SINGLE' },
  { label: 'สมรส', value: 'MARRIED' },
  { label: 'หย่าร้าง', value: 'DIVORCE' },
]
export const PersonalTab: React.FC<any> = (props: any): JSX.Element => {
  const [address, setAddress] = useState(false)

  return (
    <>
      <div className={styles.AreaData}>
        {props.loading && props.mode === 'update' ? (
          <Box sx={{ width: '100%' }}>
            <p style={{ color: '#5357bb' }}>กำลังดาวน์โหลดข้อมูลพนักงาน</p>
            <LinearProgress />
          </Box>
        ) : (
          <div className={styles.box}>
            <section className={styles.GroupInput}>
              <p className={styles.title}>คำนำหน้า</p>
              <InputRadioComponent _row={true} _name="nameTitle" _menuList={ENameTitle} />
            </section>
            <section className={styles.GroupInput}>
              <p className={styles.title}>ภาษาไทย</p>
              <div className={styles.GroupFlex}>
                <InputTextComponentCustom
                  _name="fullName"
                  _label="ชื่อ"
                  _value={props.values.values.fullName}
                  _helperText={props.values.errors.fullName}
                />
                <InputTextComponentCustom
                  _name="middleName"
                  _label="ชื่อกลาง"
                  _value={props.values.values.middleName}
                />
                <InputTextComponentCustom
                  _name="lastName"
                  _label="นามสกุล"
                  _value={props.values.values.lastName}
                  _helperText={props.values.errors.lastName}
                />
              </div>
              <div className={styles.GroupFlex}>
                <InputTextComponentCustom
                  _name="personalID"
                  _label="เลขบัตรประชาชน/ID พาสปอร์ต"
                  _value={props.values.values.personalID}
                />
                <InputTextComponentCustom
                  _name="nationality"
                  _label="สัญชาติ"
                  _value={props.values.values.nationality}
                />
                <InputTextComponentCustom _name="ethnicity" _label="เชื้อชาติ" _value={props.values.values.ethnicity} />
              </div>
            </section>
            <section className={styles.GroupInput}>
              <p className={styles.title}>ภาษาอังกฤษ</p>
              <div className={styles.GroupFlex}>
                <InputTextComponentCustom
                  _name="fullNameEnglish"
                  _label="fullName"
                  _helperText={props.values.errors.fullNameEnglish}
                  _value={props.values.values.fullNameEnglish}
                />
                <InputTextComponentCustom
                  _name="middleNameEnglish"
                  _label="Mid name"
                  _value={props.values.values.middleNameEnglish}
                  // _value={props.values.values.middleNameEnglish}
                />
                <InputTextComponentCustom
                  _name="lastNameEnglish"
                  _label="Last name"
                  _value={props.values.values.lastNameEnglish}
                />
              </div>
            </section>
            <section className={styles.GroupInput}>
              <div className={styles.GroupFlex}>
                <InputDateComponent _name="birthDate" _label="วันเกิด" _helperText={props.values.errors.birthDate} />
                <InputTextComponentCustom _name="nickName" _label="ชื่อเล่น" _value={props.values.values.nickName} />
              </div>
            </section>
            <section className={styles.GroupInput}>
              <p className={styles.title}>เพศ</p>
              <InputRadioComponent _name="sex" _row={true} _menuList={EGenger} />
            </section>
            <section className={styles.GroupInput}>
              <p className={styles.title}>สถานภาพ</p>
              <InputRadioComponent _name="personalRelation" _row={true} _menuList={EPersonalRelation} />
            </section>
            <section className={styles.GroupInput}>
              <p className={styles.title}>ที่อยู่ตามบัตรประชาชน</p>
              <div className={styles.GroupColumn}>
                <InputTextComponentCustom
                  _name={`addressPersonalID.addressNumber`}
                  _label="บ้านเลขที่"
                  _value={props.values.values.addressPersonalID.addressNumber}
                />
                <InputTextComponentCustom
                  _name={`addressPersonalID.group`}
                  _label="หมู่"
                  _value={props.values.values.addressPersonalID.group}
                />
                <InputTextComponentCustom
                  _name={`addressPersonalID.optional`}
                  _label="ข้อมูลเพิ่มเติมอื่น ๆ"
                  _value={props.values.values.addressPersonalID.optional}
                />
                <InputTextComponentCustom
                  _name={`addressPersonalID.province`}
                  _label="จังหวัด"
                  _value={props.values.values.addressPersonalID.province}
                />

                <div className={styles.GroupFlex}>
                  <InputTextComponentCustom
                    _name={`addressPersonalID.district`}
                    _label="เขต/อำเภอ"
                    _value={props.values.values.addressPersonalID.district}
                  />
                  <InputTextComponentCustom
                    _name={`addressPersonalID.subDistrict`}
                    _label="แขวง/ตำบล"
                    _value={props.values.values.addressPersonalID.subDistrict}
                  />
                </div>

                <InputNumberComponentCustom
                  _name={`addressPersonalID.zipCode`}
                  _label="รหัสไปรษณีย์"
                  _value={props.values.values.addressPersonalID.zipCode}
                />
              </div>
            </section>

            <section className={styles.GroupInput}>
              <div className={styles.GroupHeadText}>
                <p className={styles.title}>ที่อยู่ปัจจุบัน</p>
                <section
                  className={styles.textLink}
                  onClick={() => {
                    setAddress(!address)
                    if (!address) {
                      console.log('SETADDRESS')
                      props.values.setFieldValue(
                        'addressCurrent.addressNumber',
                        props.values.values.addressPersonalID.addressNumber,
                      )
                      props.values.setFieldValue('addressCurrent.group', props.values.values.addressPersonalID.group)
                      props.values.setFieldValue(
                        'addressCurrent.optional',
                        props.values.values.addressPersonalID.optional,
                      )
                      props.values.setFieldValue(
                        'addressCurrent.province',
                        props.values.values.addressPersonalID.province,
                      )
                      props.values.setFieldValue(
                        'addressCurrent.subDistrict',
                        props.values.values.addressPersonalID.subDistrict,
                      )
                      props.values.setFieldValue(
                        'addressCurrent.district',
                        props.values.values.addressPersonalID.district,
                      )
                      props.values.setFieldValue(
                        'addressCurrent.zipCode',
                        props.values.values.addressPersonalID.zipCode,
                      )
                    } else {
                      props.values.setFieldValue('addressCurrent.addressNumber', '')
                      props.values.setFieldValue('addressCurrent.group', '')
                      props.values.setFieldValue('addressCurrent.optional', '')
                      props.values.setFieldValue('addressCurrent.province', '')
                      props.values.setFieldValue('addressCurrent.subDistrict', '')
                      props.values.setFieldValue('addressCurrent.district', '')
                      props.values.setFieldValue('addressCurrent.zipCode', '')
                    }
                  }}
                >
                  {!address ? (
                    <p>
                      <p>ใช้ที่อยู่ตามบัตรประชาชน</p>
                    </p>
                  ) : (
                    <p>ใช้ที่อยู่ปัจจุบัน</p>
                  )}
                </section>
              </div>
              {/* {props.values.values.addressCurrent.map((item: any, addressIndex: number) => ( */}
              <div className={styles.GroupColumn}>
                <InputTextComponentCustom
                  _name={`addressCurrent.addressNumber`}
                  _label="บ้านเลขที่"
                  _value={props.values.values.addressCurrent.addressNumber}
                />
                <InputTextComponentCustom
                  _name={`addressCurrent.group`}
                  _label="หมู่"
                  _value={props.values.values.addressCurrent.group}
                />
                <InputTextComponentCustom
                  _name={`addressCurrent.optional`}
                  _label="ข้อมูลเพิ่มเติมอื่น ๆ"
                  _value={props.values.values.addressCurrent.optional}
                />
                <InputTextComponentCustom
                  _name={`addressCurrent.province`}
                  _label="จังหวัด"
                  _value={props.values.values.addressCurrent.province}
                />

                <div className={styles.GroupFlex}>
                  <InputTextComponentCustom
                    _name={`addressCurrent.district`}
                    _label="เขต/อำเภอ"
                    _value={props.values.values.addressCurrent.district}
                  />
                  <InputTextComponentCustom
                    _name={`addressCurrent.subDistrict`}
                    _label="แขวง/ตำบล"
                    _value={props.values.values.addressCurrent.subDistrict}
                  />
                </div>

                <InputNumberComponentCustom
                  _name={`addressCurrent.zipCode`}
                  _label="รหัสไปรษณีย์"
                  _value={props.values.values.addressCurrent.zipCode}
                />
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  )
}

//Tab2

const employeeType = [
  {
    label: 'พนักงานรายเดือน',
    value: 'FULLTIME_MONTH',
  },
  {
    label: 'พนักงานรายวัน',
    value: 'FULLTIME_DAY',
  },
  {
    label: 'พนักงานรายชั่วโมง',
    value: 'PARTTIME',
  },
  {
    label: 'บุคลากรภายนอก (จ้างงานชั่วคราว)',
    value: 'OUTSOURCE',
  },
]

const employeeWorkStataus = [
  {
    label: 'ทดลองงาน',
    value: 'PROBATION',
  },
  {
    label: 'บรรจุพนักงานแล้ว',
    value: 'EMPLOYEE',
  },
  {
    label: 'พ้นสภาพ',
    value: 'RETIRED',
  },
]

const EplaceType = [
  {
    label: 'ออฟฟิศ',
    value: 'OFFICE',
  },
  {
    label: 'นอกออฟฟิศ',
    value: 'OFFSITE',
  },
]

const EholidayType = [
  {
    label: 'ระเบียบปฏิทินแผนก',
    value: 'DEPARTMENT',
  },
  {
    label: 'ระเบียบปฏิทินกำหนดเอง',
    value: 'CUSTOM',
  },
  {
    label: 'ระเบียบปฏิทินบริษัท',
    value: 'DEFAULT',
  },
]

const GetRetireDate = (EmployeeType: any, birthDate: string, hireDate: string) => {
  const probation: any = 90
  const retireAge: any = 60
  const hireDateNew = new Date(hireDate)
  const birthDateNew = new Date(birthDate)
  if (EmployeeType === 'PROBATION') {
    const retireDate = moment(new Date(new Date().setDate(hireDateNew.getDate() + probation))).format('YYYY-MM-DD')
    console.log(moment(retireDate).isValid(), 'F')
    if (moment(retireDate).isValid() === false) return
    console.log('retireDate', retireDate)
    return retireDate
  } else {
    console.log(
      moment(new Date(new Date().setFullYear(birthDateNew.getFullYear() + retireAge))).format('YYYY-MM-DD'),
      'Retire Employee',
    )
    return moment(new Date(new Date().setFullYear(birthDateNew.getFullYear() + retireAge))).format('YYYY-MM-DD')
  }
}

export const Employment: React.FC<any> = (props: any): JSX.Element => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<any>()
  const [showInput, setShowInput] = useState(false)
  const [listShift, setListShift] = useState<any>([])

  const ListShift: any = []

  useEffect(() => {
    queryListShift().then((res: any) => {
      console.log(res, 'ListShift')
      setListShift(res.data.data.listShift)
    })
  }, [])
  const Shift = listShift.items?.map((item: any) => {
    ListShift.push({ label: item.shiftName, value: item.shiftID })
  })

  const ListDepartment: any = []
  const [department, setDepartment] = useState<any>([])
  useEffect(() => {
    queryListDepartment().then((res: any) => {
      setDepartment(res.data.data.listDepartment)
    })
  }, [])

  const ListPosition: any = []
  const [positions, setPositions] = useState<any>([])
  useEffect(() => {
    queryListPosition().then((res: any) => {
      setPositions(res.data.data.listPosition)
    })
  }, [])

  const Position = positions.items?.map((item: any) => {
    ListPosition.push({ label: item.positionName, value: item.id })
  })

  const Department = department.items?.map((item: any) => {
    ListDepartment.push({ label: item.departmentName, value: item.id })
  })

  return (
    <>
      <div className={styles.AreaData}>
        {/* {console.log(values, 'values')} */}
        <div className={styles.box}>
          <section className={styles.GroupInput}>
            <p className={styles.title}>สถานะพนักงาน</p>
            <InputRadioComponent _row={true} _name="employeeWorkStatus" _menuList={employeeWorkStataus} />
          </section>
          <section className={styles.GroupInput}>
            <div className={styles.GroupFlex}>
              <InputTextComponentCustom _name="id" _label="รหัสพนักงาน" _value={props.values.values.id} />
              <InputTextComponentCustom
                _name="finger"
                _label="รหัสลายนิ้วมือ"
                _value={props.values.values.fingerPrintId}
              />
            </div>
          </section>

          <section className={styles.GroupInput}>
            <div className={styles.GroupFlex}>
              <TextField
                InputProps={{
                  style: {
                    fontFamily: 'KanitRegular',
                    color: 'gray',
                    borderRadius: 4,
                  },
                }}
                size="small"
                type="date"
                fullWidth
                value={props.values.values.hireDate}
                id="outlined-basic"
                label="วันที่เริ่มงาน"
                name="hireDate"
                onChange={(e) => {
                  props.values.setFieldValue('hireDate', e.target.value)
                  props.values.setFieldValue(
                    'retireDate',
                    GetRetireDate(
                      props.values.values.employeeWorkStatus,
                      props.values.values.birthDate,
                      e.target.value,
                    ),
                  )
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 16, fontFamily: 'KanitRegular' },
                }}
              />

              <InputDateComponentCustome
                _name="retireDate"
                _label={
                  props.values.values.employeeWorkStatus === 'PROBATION'
                    ? 'วันที่สิ้นสุดการทดลองงาน'
                    : 'วันสิ้นสุดการทำงาน'
                }
                _value={props.values.values.retireDate}
                _disabled={true}
              />
            </div>
          </section>

          <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>ประเภทพนักงาน</p>
              <InputSelectComponentCustom
                _name="employeeType"
                _label="ประเภทพนักงาน"
                _menuList={employeeType}
                _value={props.values.values.employeeType}
                _helperText={props.values.errors.employeeType}
              />
            </div>

            <div className={styles.GridInput}>
              <p>แผนก</p>
              <InputSelectComponentCustom
                _name="departmentID"
                _label="แผนก"
                _menuList={ListDepartment}
                _value={props.values.values.departmentID}
                _helperText={props.values.errors.departmentID}
              />
            </div>
          </section>

          <section className={styles.GroupSelect}>
            {/* <div className={styles.GridInput}>
              <p>ทีมงาน</p>
              <InputSelectComponentCustom _name="employeeType" _label="ประเภทพนักงาน" _menuList={mockUpSelect} />
            </div> */}

            <div className={styles.GridInput}>
              <p>ตำแหน่ง</p>
              <InputSelectComponentCustom
                _name="positionID"
                _label="ตำแหน่ง"
                _menuList={ListPosition}
                _value={props.values.values.positionID}
                _helperText={props.values.errors.positionID}
              />
            </div>
          </section>

          {/* <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>หัวหน้าพนักงาน</p>
              <InputSelectComponentCustom _name="employeeType" _label="ประเภทพนักงาน" _menuList={mockUpSelect} />
            </div>
          </section> */}

          <section className={styles.GroupInput}>
            <p className={styles.title}>ตั้งค่าการทำงาน</p>
            <div className={styles.GroupFlex}>
              <p>บันทึกเวลาเข้า - ออกงาน</p>
              <CustomizedSwitches
                _name="isTimeAttendance"
                _onClick={(e: any) => props.values.setFieldValue('isTimeAttendance', e.target.checked)}
                _checked={props.values.values.isTimeAttendance}
              />
            </div>

            <div className={styles.GroupFlex}>
              <p>กะการทำงาน</p>
              <InputRadioComponent
                _row={true}
                _name="setShiftWorking"
                _menuList={[
                  { label: 'เปิดใช้งาน', value: 'ENABLE' },
                  { label: 'ปิดใช้งาน', value: 'DISABLE' },
                ]}
              />

              {props.values.values.setShiftWorking === 'ENABLE' ? (
                <div className={styles.Box}>
                  <InputSelectComponentCustom
                    _name="shiftWorkingID"
                    _label="กะการทำงาน"
                    _menuList={ListShift}
                    _value={props.values.values.shiftWorkingID}
                  />
                </div>
              ) : (
                ''
              )}

              {/* <CustomizedSwitches _name="shiftSetting" _onClick={(e: any) => setShowInput(!showInput)} /> */}
              {showInput ? (
                <div className={styles.Box}>
                  <InputSelectComponentCustom _name="shift" _label="กะการทำงาน" _menuList={mockUpSelect} />
                </div>
              ) : (
                ''
              )}
            </div>
          </section>

          <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>สถานที่ทำงาน</p>
              <InputSelectComponentCustom
                _name="workPlace"
                _label="สถานที่ทำงาน"
                _menuList={EplaceType}
                _value={props.values.values.workPlace}
              />
            </div>
          </section>

          {/* <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>แพ็คเกจ Group</p>
              <InputSelectComponentCustom _name="employeeType" _label="แพ็คเกจ Group" _menuList={mockUpSelect} />
            </div>
          </section> */}

          <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>วันหยุดประจำปี</p>
              <InputSelectComponentCustom
                _name="holidayLink"
                _label="วันหยุดประจำปี"
                _menuList={EholidayType}
                _value={props.values.values.holidayLink}
              />
            </div>
          </section>

          {/* <section className={styles.GroupInput}>
            <p className={styles.title}>การลา</p>
            <p>Package Group Name</p>

            <div className={styles.TablePackageGroup}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                    <TableCell align="center">
                      <h4>รายการ</h4>
                    </TableCell>
                    <TableCell align="center">
                      <h4>จำนวนโคว้ต้า</h4>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </div>
          </section> */}
        </div>
      </div>
    </>
  )
}

//Tab3
export const Compensation: React.FC<any> = (props: any): JSX.Element => {
  const [open, setOpen] = useState(false)
  const HeadIncome = ['ประเภท', 'รายการ', 'มูลค่า', 'หน่วย']
  const HeadWellfare = ['ชื่อ', 'จำนวนเงิน', 'หน่วย']
  const Headfund = [
    'ชื่อกองทุน',
    'เลขที่กองทุน',
    'วันที่สัญญากองทุน',
    'วิธีการหักเงิน',
    'เรทกองทุน',
    'วิธีการสมทบ',
    'บริษัทสมทบ',
    'ยอดสมทบ',
    'ผู้ได้รับผลประโยชน์',
    'หมายเหตุ',
  ]

  const Headvat = ['ปี', 'จำนวนเดือน', 'มูลค่า']

  const Headdeduct = ['รายการ', 'เรทจำนวนเงินลดหย่อน', 'จำนวนเงินหักลดหย่อน']

  const Type = [
    {
      label: 'รายได้',
      value: 'Income',
    },
    {
      label: 'รายหัก',
      value: 'Deduct',
    },
  ]

  const Eunit = [
    { label: 'บาท/วัน', value: 'BAHT_PER_DAY' },
    { label: 'บาท/เดือน', value: 'BAHT_PER_MONTH' },
    { label: 'บาท/ปี', value: 'BAHT_PER_YEAR' },
  ]

  const EName = [{ label: 'ค่าเดินทาง', value: 'TRAVEL' }]

  const EIncomeExpenseType = [
    { label: 'รายได้', value: 'INCOME' },
    { label: 'รายหัก', value: 'EXPENSE' },
    { label: 'กองทุน', value: 'FUND' },
    { label: 'สวัสดิการ', value: 'WELFARE' },
    { label: 'อื่น ๆ', value: 'AUTO' },
  ]
  return (
    <>
      <div className={styles.AreaData}>
        <div className={styles.box}>
          <section className={styles.GroupGrid}>
            <div>
              <p className={styles.title}>ค่าจ้าง</p>
              <InputRadioComponent
                _name="salaryOptional"
                _menuList={[
                  { label: 'ค่าคงที่', value: 'STABLE' },
                  { label: 'ระบบคำนวณเงินเดือน JD', value: 'JD_CALCULATE' },
                ]}
              />
              <p style={{ cursor: 'pointer', color: '#3080f8', textDecoration: 'underline' }}>
                ตั้งค่าระบบคำนวณเงินเดือน JD
              </p>
            </div>
            <div className={styles.BoxSalary}>
              {props.values.values.salaryOptional === 'STABLE' ? (
                <InputNumberUnitComponent _name="salary" _label="ค่าจ้าง" _unit="บาท" />
              ) : (
                ''
              )}
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>รายได้รายหักคงที่</p>
            <div className={styles.Table}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                    {HeadIncome.map((item: any, index: number) => (
                      <TableCell align="center" key={index}>
                        <h4>{item}</h4>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.values.values.incomeExpense.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                        <InputSelectComponentCustom
                          _name={`incomeExpense[${index}].incomeExpenseType`}
                          _menuList={EIncomeExpenseType}
                          _label={'เลือกประเภท'}
                          _value={item.incomeExpenseType}
                        />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                        <InputSelectComponentCustom
                          _name={`incomeExpense[${index}].name`}
                          _menuList={EName}
                          _label={'เลือกรายการ'}
                          _value={item.name}
                        />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                        <InputNumberComponentCustom _name={`incomeExpense[${index}].total`} _value={item.total} />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                        <InputSelectComponentCustom
                          _name={`incomeExpense[${index}].unit`}
                          _menuList={Eunit}
                          _label={'เลือกรายการ'}
                          _value={item.unit}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>รายได้รายหักอัตโนมัติ</p>
            <div className={styles.AreaCard}>
              <p className={styles.title}>1. ประกันสังคม</p>
              <div className={styles.Group}>
                <div>
                  <p>ประเภทบัตรประจำตัว</p>
                  <InputRadioComponent
                    _row={true}
                    _name="socialSecurity"
                    _menuList={[
                      { label: 'บัตรประชาชน', value: 'PERSONAL_IDCARD' },
                      { label: 'ใบอนุญาติ', value: 'LICENSE' },
                      { label: 'ใบต่างด้าว', value: 'FOREIGN_IDCARD' },
                    ]}
                  />
                </div>

                <section className={styles.GroupSelect}>
                  <div className={styles.GridInput}>
                    <p>รหัสประจำตัว</p>
                    <InputTextComponentCustom
                      _name="personalIDNumber"
                      _label="รหัสประจำตัว"
                      _value={props.values.values.personalIDNumber}
                    />
                  </div>
                </section>

                <section className={styles.GroupSelect}>
                  <div className={styles.GridInput}>
                    <p>โรงพยาบาล</p>
                    <InputTextComponentCustom
                      _name="hospitalService"
                      _label="โรงพยาบาล"
                      _value={props.values.values.hospitalService}
                    />
                    {/* <InputSelectComponentCustom _name="employeeType" _label="โรงพยาบาล" _menuList={mockUpSelect} /> */}
                  </div>
                </section>

                <div>
                  <p>การคำนวณประกันสังคม</p>
                  <div className={styles.CalBox}>
                    <InputRadioComponent
                      _row={true}
                      _name="socialSecurityCalculate"
                      _menuList={[
                        { label: 'คิดตามฐานเงินเดือนจริง', value: 'BASE_ON_SALARY' },
                        { label: 'ไม่คิดประกันสังคม', value: 'NOT_BASE_ON' },
                      ]}
                    />

                    <div className={styles.Formtext}>
                      <p className={styles.link}>ติดต่อทีมงาน</p>
                      <p className={styles.text}>หากต้องการเพิ่มการคิดประกันสังคม</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.AreaCard}>
              <div className={styles.flexCard}>
                <p className={styles.title}>2. เบี้ยขยัน</p>
                <CustomizedSwitches
                  _name="diligentAllowance"
                  _onClick={(e: any) => props.values.setFieldValue('diligentAllowance', e.target.checked)}
                  _checked={props.values.values.diligentAllowance}
                />
              </div>
            </div>
          </section>
          {/* <section className={styles.GroupInput}>
            <p className={styles.title}>สวัสดิการ</p>
            <div>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                    {HeadWellfare.map((item: any, index: number) => (
                      <TableCell align="center" key={index}>
                        <h4>{item}</h4>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </div>
          </section> */}
          <section className={styles.GroupInput}>
            <div className={styles.GroupHeadText}>
              <p className={styles.title}>กองทุน</p>
              <p className={styles.textLink} onClick={() => setOpen(true)}>
                ขยาย
              </p>
            </div>

            <div>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                    {Headfund.map((item: any, index: number) => (
                      <TableCell align="center" key={index}>
                        <h4>{item}</h4>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.values.values.employeeFund.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputTextComponentCustom
                          _name={`employeeFund[${index}].name`}
                          _label="ชื่อกองทุน"
                          _value={item.name}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputNumberComponentCustom
                          _name={`employeeFund[${index}].fundNumber`}
                          _label="เลขที่กองทุน"
                          _value={item.fundNumber}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputDateComponentCustome
                          _name={`employeeFund[${index}].contractDate`}
                          _label="เลขที่กองทุน"
                          _value={item.contractDate}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputSelectComponentCustom
                          _label="วิธีการหักเงิน"
                          _name={`employeeFund[${index}].deductMethod`}
                          _value={item.deductMethod}
                          _menuList={[
                            { label: 'คิดเป็น %', value: 'PERCENTAGE' },
                            { label: 'คิดเป็นบาท', value: 'BAHT' },
                          ]}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputNumberComponentCustom
                          _name={`employeeFund[${index}].fundRate`}
                          _label="เรทกองทุน"
                          // _value={item.fundNumber}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputSelectComponentCustom
                          _label="วิธีการสมทบ"
                          _name={`employeeFund[${index}].contributionMethod`}
                          _value={item.contributionMethod}
                          _menuList={[
                            { label: 'คิดเป็น %', value: 'PERCENTAGE' },
                            { label: 'คิดเป็นบาท', value: 'BAHT' },
                          ]}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputTextComponentCustom
                          _name={`employeeFund[${index}].contributionCompany`}
                          _label="บริษัทสมทบ"
                          _value={item.contributionCompany}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputTextComponentCustom
                          _name={`employeeFund[${index}].contributionTotal`}
                          _label="ยอดสมทบ"
                          _value={item.contributionTotal}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputTextComponentCustom
                          _name={`employeeFund[${index}].beneficiary`}
                          _label="ผู้ได้รับผลประโยชน์"
                          _value={item.beneficiary}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputTextComponentCustom
                          _name={`employeeFund[${index}].remark`}
                          _label="หมายเหตุ"
                          _value={item.remark}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>ลดหย่อนภาษี</p>
            <div>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                    {Headvat.map((item: any, index: number) => (
                      <TableCell align="center" key={index}>
                        <h4>{item}</h4>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.values.values.taxDeductOne.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputTextComponentCustom
                          _name={`taxDeductOne[${index}].year`}
                          _value={item.year}
                          _label="ปี"
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputTextComponentCustom
                          _name={`taxDeductOne[${index}].month`}
                          _value={item.month}
                          _label="จำนวนเดือน"
                        />
                      </TableCell>

                      <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                        <InputNumberComponentCustom
                          _name={`taxDeductOne[${index}].total`}
                          _label="มูลค่า"
                          _value={item.total}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </section>
          {/* <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>สถานภาพ</p>
              <InputSelectComponentCustom _name="employeeType" _label="สถานภาพ" _menuList={mockUpSelect} />
            </div>
          </section>{' '} */}
          <InputCheckboxComponentCustom
            _name="employeeHandicappedAndAgeOut"
            _value={props.values.employeeHandicappedAndAgeOut}
            _label="พนักงานมีอายุมากกว่า 65 ขึ้นไป / ผู้พิการ"
          />

          <div>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                  {Headdeduct.map((item: any, index: number) => (
                    <TableCell align="center" key={index}>
                      <h4>{item}</h4>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.values.values.taxDeductTwo.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom
                        _name={`taxDeductTwo[${index}].name`}
                        _label="รายการ"
                        _value={item.name}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputNumberComponentCustom
                        _name={`taxDeductTwo[${index}].rateDeduct`}
                        _label="เรทจำนวนเงินลดหย่อน"
                        _value={item.rateDeduct}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputNumberComponentCustom
                        _name={`taxDeductTwo[${index}].totalDeduct`}
                        _label="จำนวนเงินหักลดหย่อน"
                        _value={item.totalDeduct}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      <ModalTable open={open} setOpen={setOpen} values={props.values} />
    </>
  )
}

//Tab4

export const Payment: React.FC<any> = (props: any): JSX.Element => {
  const EBankProviderThailand = [
    { label: 'ธนาคารกรุงเทพ ', value: 'KRUNGTHEP' },
    { label: 'ธนาคารกรุงไทย', value: 'KRUNGTHAI' },
    { label: 'ธนาคารกรุงศรีอยุธยา', value: 'KRUNGSRI' },
    { label: 'ธนาคารกสิกรไทย', value: 'KASIKORN' },
    { label: 'ธนาคารทหารไทยธนชาต', value: 'THANACHAT' },
    { label: 'ธนาคารไทยพาณิชย์', value: 'THAI_PANICH' },
    { label: 'ธนาคารซีไอเอ็มบี ไทย ', value: 'CIMB' },
    { label: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) ', value: 'STANDARD_CHADTER' },
    { label: 'ธนาคารยูโอบี', value: 'UOB' },
    { label: 'ธนาคารทิสโก้', value: 'TISCO' },
    { label: 'ธนาคารเกียรตินาคินภัทร', value: 'KIET_NAKIN' },
    { label: 'ธนาคารแลนด์ แอนด์ เฮ้าส์', value: 'LAND_AND_HOUSE' },
    { label: 'ธนาคารไอซีบีซี (ไทย)', value: 'ICBC' },
    { label: 'ธนาคารไทยเครดิต เพื่อรายย่อย', value: 'THAI_CREDIT' },
    { label: 'ธนาคารเมกะ สากลพาณิชย์', value: 'MEGE_PANICH' },
    { label: 'ธนาคารแห่งประเทศจีน (ไทย) ', value: 'CHINESE' },
    { label: 'ธนาคารเอเอ็นแซด (ไทย)', value: 'ANZ' },
    { label: 'ธนาคารซูมิโตโม มิตซุย ทรัสต์ (ไทย)', value: 'SUMITOMO_MITSUI_TRUST' },
  ]
  return (
    <>
      <div className={styles.AreaData}>
        <div className={styles.box}>
          <section className={styles.GroupInput}>
            <p className={styles.title}>ช่องทางการชำระเงิน</p>
            <div className={styles.GroupFlex}>
              <InputRadioComponent
                _row={true}
                _name="paymentMethod"
                _menuList={[
                  { label: 'ธนาคาร', value: 'BANK' },
                  { label: 'เงินสด', value: 'CASH' },
                ]}
              />
              <div className={styles.Formtext}>
                <p className={styles.link}>ติดต่อทีมงาน</p>
                <p className={styles.text}>หากต้องการเพิ่มช่องทางชำระเงิน</p>
              </div>
            </div>
          </section>

          {props.values.values.paymentMethod === 'BANK' ? (
            <div>
              {' '}
              <section className={styles.GroupInput}>
                <p className={styles.title}>จ่ายเงินผ่านธนาคาร</p>
                <div className={styles.GridInput}>
                  <p>ธนาคาร</p>
                  <InputSelectComponentCustom
                    _name="bankPayment"
                    _label="ธนาคาร"
                    _menuList={EBankProviderThailand}
                    _value={props.values.values.bankPayment}
                  />
                </div>
              </section>
              <section className={styles.GroupInput}>
                <p className={styles.title}>บัญชีพนักงาน</p>
                <div className={styles.GridInput}>
                  <p>ธนาคาร</p>
                  <InputSelectComponentCustom
                    _name="bankAccountName"
                    _label="ธนาคาร"
                    _menuList={EBankProviderThailand}
                    _value={props.values.values.bankAccountName}
                  />
                </div>
                <div className={styles.GridInput}>
                  <p>เลขที่บัญชี</p>
                  <InputTextComponentCustom
                    _name="bankAccountId"
                    _label="เลขที่บัญชี"
                    _value={props.values.values.bankAccountId}
                  />
                </div>
              </section>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

//Tab5
export const Settings: React.FC<any> = (props: any): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [image, setImage] = useState('')
  const [loading, setLoading] = useState(true)
  return (
    <>
      {console.log(loading, 'loading')}
      <div className={styles.AreaData}>
        {console.log(props.values.values, 'props')}
        <section className={styles.CenterBox}>
          <label className={styles.container}>
            <div className={styles.imageContainer}>
              {props.values.values.profileImage === '' ? (
                <img src={ProfileBg} alt="" />
              ) : (
                <>
                  {/* {loading ? (
                    <div className={styles.Loader}>
                      <CircularProgress />
                    </div>
                  ) : ( */}
                  <img src={props.values.values.profileImage} alt="profileBG" />
                  {/* )} */}
                </>
              )}
            </div>

            <div className={styles.dotCamera}>
              <img src={IconCamera} alt="camera" />
            </div>

            <input
              type="file"
              id="profileImage"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => {
                setLoading(true)
                Promise.all(handleUploadFile(e.target.files, `Profile/${props.values.values.id}/images/`)).then(
                  (profileImage: any) => {
                    props.values.setFieldValue('profileImage', profileImage[0])
                    setLoading(false)
                  },
                )
              }}
            />
          </label>
          {/* <ProfileUpload image={image} setImage={setImage} /> */}
          <div className={styles.InputField}>
            <InputTextComponentCustom
              _name="username"
              _label="Username"
              _value={props.values.values.username}
              _helperText={props.values.errors.username}
            />
          </div>
          <div className={styles.InputField}>
            <InputTextComponentCustom
              _name="password"
              _label="Password"
              _value={props.values.values.password}
              _helperText={props.values.errors.password}
            />
            <p className={styles.txetLink} onClick={() => navigate('../forgotpassword')}>
              เปลี่ยนรหัสผ่าน
            </p>
          </div>

          {props.mode === 'update' ? (
            <div className={styles.bottom}>
              <p className={styles.tetxLink}>ลืมรหัสผ่านใช่ไหม ?</p>
              <div
                className={styles.GroupText}
                onClick={() => {
                  dispatch(
                    allAction.modalConfirmAction.setOpenModalConfirm(
                      'คุณต้องการลบบัญชีพนักงาน ?',
                      'ไม่สามารถแก้ไขข้อมูลได้',
                      '',
                      'ใช่ลบข้อมูล',
                      () => {
                        UpdateEmployeeProfile({ input: { id: props.values.values.id, status: 'INACTIVE' } }).then(
                          (res: any) => {
                            console.log('Del Employee', res)
                          },
                        )

                        navigate(-1)
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                      },
                    ),
                  )
                }}
              >
                <img src={deleteIcon} alt="" />
                <p>ลบ Account พนักงาน</p>
                <Tooltip
                  arrow
                  title={
                    <p>
                      ลบข้อมูลของพนักงานคนนี้ทั้งหมดในฐานข้อมูล จะไม่สามารถค้นหา หรือตั้งค่าพนักงานคนนี้ได้อีก
                      การดำเนินการนี้จะเริ่มต้นเมื่อกดปุ่มยืนยัน และการดำเนินการนี้จะไม่กระทบกับพนักงานคนอื่น
                    </p>
                  }
                >
                  <img src={questionIcon} alt="" />
                </Tooltip>
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
      </div>
    </>
  )
}

//Tab6
export const Background: React.FC<any> = (props: any): JSX.Element => {
  const [editFamily, setEditFamily] = useState(false)
  const [editEducate, setEducate] = useState(false)
  const [inputTalent, setinputTalent] = useState(false)
  const [editWork, setEditeWork] = useState(false)
  const HeaderFamily = ['ชื่อ', 'อายุ', 'อาชีพ', 'ความสัมพันธ์', '']
  const HeadEducation = ['ระดับการศึกษา', 'สถาบันการศึกษา', 'สาขาวิชา', 'ปีที่เริ่มการศึกษา', 'ปีที่จบการศึกษา', '']
  const HeadExperian = [
    'สถานที่ทำงาน',
    'วันเริ่มงาน',
    'วันออกงาน',
    'ตำแหน่งงาน',
    'ลักษณะงาน',
    'ค่าจ้าง',
    'เหตุผลที่ออก',
    '',
  ]
  const [talent, setTalent] = useState<any>([
    'พิมพ์เร็ว',
    'พูดภาษาอังกฤษได้คล่อง',
    'Microsoft office',
    'คิดบวก',
    'มีความเป็นผู้นำ',
    'มีความอดทน',
    'เข้าสังคม',
  ])
  // const talent = [
  //   'พิมพ์เร็ว',
  //   'พูดภาษาอังกฤษได้คล่อง',
  //   'Microsoft office',
  //   'คิดบวก',
  //   'มีความเป็นผู้นำ',
  //   'มีความอดทน',
  //   'เข้าสังคม',
  // ]
  const onFormSubmit = (e: any) => {
    e.preventDefault()
    // send state to server with e.g. `window.fetch`
  }

  const handleClick = (e: any, index: any) => {
    // console.log(index)
    // console.log(e)
    const ItemFind = props.listTalent.find((item: any) => item === e)
    // console.log(ItemFind, 'ItemFind')
    if (ItemFind === e) {
      props.setListTalent(props.listTalent.filter((item: any) => item !== e))
    } else {
      props.setListTalent([...props.listTalent, talent.find((item: any) => item === e)])
    }
    console.log(props.listTalent, 'listTalent')
  }

  const OnkeyDown = (e: any) => {
    if (e.key === 'Enter') {
      console.log('ENTER')
      setTalent([...talent, e.target.value])
      console.log(talent, 'Talent')
    }
  }
  return (
    <>
      <div className={styles.AreaData}>
        <section className={styles.GroupInput}>
          <p className={styles.title}>ประวัติครอบครัว</p>
          <div className={styles.TableCard}>
            {!editFamily ? (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEditFamily(!editFamily)
                }}
              >
                แก้ไข
              </p>
            ) : (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEditFamily(!editFamily)
                }}
              >
                เสร็จสิ้น
              </p>
            )}

            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                  {HeaderFamily.map((item: any, index: number) => (
                    <TableCell align="center" key={index}>
                      <h4>{item}</h4>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.values.values.historyFamily.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom _name={`historyFamily[${index}].fullName`} _value={item.fullName} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9', borderRadius: 10 }}>
                      <InputTextComponentCustom _name={`historyFamily[${index}].age`} _value={item.age} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom _name={`historyFamily[${index}].job`} _value={item.job} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom _name={`historyFamily[${index}].relation`} _value={item.relation} />
                    </TableCell>

                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      {editFamily ? (
                        <img
                          src={deleteIcon}
                          alt=""
                          className={styles.Icon}
                          onClick={() => {
                            props.values.values.historyFamily.splice(index, 1)
                            console.log(index)
                            props.values.setFieldValue('historyFamily', props.values.values.historyFamily)
                          }}
                        />
                      ) : (
                        ''
                      )}{' '}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <p
              className={styles.LinkText}
              onClick={() => {
                props.values.values.historyFamily.push({
                  fullName: '',
                  age: '',
                  job: '',
                  relation: '',
                })
                props.values.setFieldValue('historyFamily', props.values.values.historyFamily)
              }}
            >
              เพิ่มแถวตาราง
            </p>
          </div>
        </section>

        <section className={styles.GroupInput}>
          <p className={styles.title}>ประวัติการศึกษา</p>
          <div className={styles.TableCard}>
            {!editEducate ? (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEducate(!editEducate)
                }}
              >
                แก้ไข
              </p>
            ) : (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEducate(!editEducate)
                }}
              >
                เสร็จสิ้น
              </p>
            )}

            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                  {HeadEducation.map((item: any, index: number) => (
                    <TableCell align="center" key={index}>
                      <h4>{item}</h4>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.values.values.historyEducation.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom
                        _name={`historyEducation[${index}].educationLevel`}
                        _value={item.educationLevel}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9', borderRadius: 10 }}>
                      <InputTextComponentCustom
                        _name={`historyEducation[${index}].educationName`}
                        _value={item.educationName}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom
                        _name={`historyEducation[${index}].majorName`}
                        _value={item.majorName}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom
                        _name={`historyEducation[${index}].startedYear`}
                        _value={item.startedYear}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom
                        _name={`historyEducation[${index}].endedYear`}
                        _value={item.endedYear}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      {editEducate ? (
                        <img
                          src={deleteIcon}
                          alt=""
                          className={styles.Icon}
                          onClick={() => {
                            props.values.values.historyEducation.splice(index, 1)
                            props.values.setFieldValue('historyEducation', props.values.values.historyEducation)
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <p
              className={styles.LinkText}
              onClick={() => {
                props.values.values.historyEducation.push({
                  educationLevel: '',
                  educationName: '',
                  majorName: '',
                  startedYear: '',
                  endedYear: '',
                })
                props.values.setFieldValue('historyEducation', props.values.values.historyEducation)
              }}
            >
              เพิ่มแถวตาราง
            </p>
          </div>
        </section>

        <section className={styles.GroupInput}>
          <p className={styles.title}>ประวัติการทำงาน</p>
          <div className={styles.TableCard}>
            {!editWork ? (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEditeWork(!editWork)
                }}
              >
                แก้ไข
              </p>
            ) : (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEditeWork(!editWork)
                }}
              >
                เสร็จสิ้น
              </p>
            )}

            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                  {HeadExperian.map((item: any, index: number) => (
                    <TableCell align="center" key={index}>
                      <h4>{item}</h4>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.values.values.historyWork.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom _name={`historyWork[${index}].workName`} _value={item.workName} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9', borderRadius: 10 }}>
                      <InputDateComponent _name={`historyWork[${index}].startedAt`} _label={'วันเริ่มงาน'} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputDateComponent _name={`historyWork[${index}].endedAt`} _label={'วันสิ้นสุดการทำงาน'} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom
                        _name={`historyWork[${index}].positionName`}
                        _value={item.positionName}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom
                        _name={`historyWork[${index}].descPosition`}
                        _value={item.descPosition}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom _name={`historyWork[${index}].salary`} _value={item.salary} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      <InputTextComponentCustom _name={`historyWork[${index}].leaveReason`} _value={item.leaveReason} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #D9D9D9' }}>
                      {editWork ? (
                        <img
                          src={deleteIcon}
                          alt=""
                          className={styles.Icon}
                          onClick={() => {
                            props.values.values.historyWork.splice(index, 1)
                            console.log(index)
                            props.values.setFieldValue('historyWork', props.values.values.historyWork)
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <p
              className={styles.LinkText}
              onClick={() => {
                props.values.values.historyWork.push({
                  workName: '',
                  startedAt: '',
                  endedAt: '',
                  positionName: '',
                  descPosition: '',
                  salary: '',
                  leaveReason: '',
                })
                props.values.setFieldValue('historyWork', props.values.values.historyWork)
              }}
            >
              เพิ่มแถวตาราง
            </p>
          </div>
        </section>

        <section className={styles.GroupInput}>
          <p className={styles.title}>ความสามารถพิเศษ</p>

          <div className={styles.GroupPill}>
            {talent.map((item: any, Index: number) => (
              <div
                className={
                  props.listTalent.map((item: any) => item).includes(item) === true ? styles.pillSelect : styles.pill
                }
                key={Index}
                onClick={(e: any) => {
                  handleClick(item, Index)
                }}
              >
                {item}
              </div>
            ))}
            <div
              className={styles.pill}
              // onClick={(e: any) => {
              //   handleClick(item, Index)
              // }}
            >
              อื่น ๆ
              <img src={PlusIcon} alt="" className={styles.Icon} onClick={() => setinputTalent(!inputTalent)} />
              {inputTalent ? (
                // <div className={styles.inputBox}>
                <input
                  className={styles.inputBox}
                  name="talent"
                  placeholder="กรอกความสามารถพิเศษ"
                  // onChange={(e: any) => console.log(e.key, e, 'e')}
                  onKeyDown={(e: any) => OnkeyDown(e)}
                />
              ) : (
                // </div>
                ''
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

//Tab7

export const Document: React.FC<any> = (props: any): JSX.Element => {
  return (
    <>
      <div className={styles.AreaData}>
        <div className={styles.box}>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์บัตรประชาชน</p>
            <div className={styles.UploadFile}>
              {console.log(props.values.values.personalIdAttachment, 'propss')}
              <input
                id={'personalIdAttachment'}
                type="file"
                accept=".pdf .jpeg .png"
                onChange={(e) => {
                  Promise.all(
                    handleUploadFile(e.target.files, `DocumentPersonal/${props.values.values.id}/file/`),
                  ).then((personalIdAttachment: any) => {
                    props.values.setFieldValue('personalIdAttachment', personalIdAttachment[0])
                  })
                }}
                style={{ display: 'none' }}
              />
              {props.values.values.personalIdAttachment ? (
                <p>
                  <label className={styles.labelshowfile} htmlFor="personalIdAttachment">
                    <img className={styles.filelogo} src={FileIcon} alt="filelogo" />{' '}
                    <p>{props.values.values.personalIdAttachment}</p>
                  </label>
                </p>
              ) : (
                <p className="margin-Bottom15px">
                  <label className={styles.labelfile} htmlFor="personalIdAttachment">
                    <span className={styles.primaryuploadspan}>
                      <img className={styles.filelogo} src={FileIcon} alt="filelogo" /> Upload File
                    </span>
                    <span className={styles.secondaryuploadspan}>Upload File/slip (JPEG,PNG,PDF)</span>
                  </label>
                </p>
              )}
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์สำเนาทะเบียนบ้าน</p>
            <div className={styles.UploadFile}>
              <input
                id={'personalHouseAttachment'}
                type="file"
                accept=".pdf .jpeg .png"
                onChange={(e) => {
                  Promise.all(
                    handleUploadFile(e.target.files, `DocumentPersonal/${props.values.values.id}/file/`),
                  ).then((personalHouseAttachment: any) => {
                    props.values.setFieldValue('personalHouseAttachment', personalHouseAttachment[0])
                  })
                }}
                style={{ display: 'none' }}
              />
              {props.values.values.personalHouseAttachment ? (
                <p>
                  <label className={styles.labelshowfile} htmlFor="personalHouseAttachment">
                    <img className={styles.filelogo} src={FileIcon} alt="filelogo" />{' '}
                    <p>{props.values.values.personalHouseAttachment}</p>
                  </label>
                </p>
              ) : (
                <p className="margin-Bottom15px">
                  <label className={styles.labelfile} htmlFor="personalHouseAttachment">
                    <span className={styles.primaryuploadspan}>
                      <img className={styles.filelogo} src={FileIconWhite} alt="filelogo" /> Upload File
                    </span>
                    <span className={styles.secondaryuploadspan}>อัปโหลดเอกสารสำเนาทะเบียนบ้าน (JPEG,PNG,PDF)</span>
                  </label>
                </p>
              )}
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์สำเนาวุฒิการศึกษา</p>
            <div className={styles.UploadFile}>
              <input
                id={'personalEducationalAttachment'}
                type="file"
                accept=".pdf .jpeg .png"
                onChange={(e) => {
                  Promise.all(
                    handleUploadFile(e.target.files, `DocumentPersonal/${props.values.values.id}/file/`),
                  ).then((personalEducationalAttachment: any) => {
                    props.values.setFieldValue('personalEducationalAttachment', personalEducationalAttachment[0])
                  })
                }}
                style={{ display: 'none' }}
              />
              {props.values.values.personalEducationalAttachment ? (
                <p>
                  <label className={styles.labelshowfile} htmlFor="personalEducationalAttachment">
                    <img className={styles.filelogo} src={FileIcon} alt="filelogo" />{' '}
                    <p>{props.values.values.personalEducationalAttachment}</p>
                  </label>
                </p>
              ) : (
                <p className="margin-Bottom15px">
                  <label className={styles.labelfile} htmlFor="personalEducationalAttachment">
                    <span className={styles.primaryuploadspan}>
                      <img className={styles.filelogo} src={FileIconWhite} alt="filelogo" /> Upload File
                    </span>
                    <span className={styles.secondaryuploadspan}>อัปโหลดเอกสารสำเนาวุฒิการศึกษา (JPEG,PNG,PDF)</span>
                  </label>
                </p>
              )}
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์เอกสารอบรม (ถ้ามี)</p>
            <div className={styles.UploadFile}>
              <input
                id={'personalTrainningAttachment'}
                type="file"
                accept=".pdf .jpeg .png"
                onChange={(e) => {
                  Promise.all(
                    handleUploadFile(e.target.files, `DocumentPersonal/${props.values.values.id}/file/`),
                  ).then((personalTrainningAttachment: any) => {
                    props.values.setFieldValue('personalTrainningAttachment', personalTrainningAttachment[0])
                  })
                }}
                style={{ display: 'none' }}
              />
              {props.values.values.personalTrainningAttachment ? (
                <p>
                  <label className={styles.labelshowfile} htmlFor="personalTrainningAttachment">
                    <img className={styles.filelogo} src={FileIcon} alt="filelogo" />{' '}
                    <p>{props.values.values.personalTrainningAttachment}</p>
                  </label>
                </p>
              ) : (
                <p className="margin-Bottom15px">
                  <label className={styles.labelfile} htmlFor="personalTrainningAttachment">
                    <span className={styles.primaryuploadspan}>
                      <img className={styles.filelogo} src={FileIconWhite} alt="filelogo" /> Upload File
                    </span>
                    <span className={styles.secondaryuploadspan}>อัปโหลดเอกสารการอบรม (JPEG,PNG,PDF)</span>
                  </label>
                </p>
              )}
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์เอกสารอื่น ๆ</p>
            <div className={styles.UploadFile}>
              <input
                id={'personalOptionalAttachment'}
                type="file"
                accept=".pdf .jpeg .png"
                onChange={(e) => {
                  Promise.all(
                    handleUploadFile(e.target.files, `DocumentPersonal/${props.values.values.id}/file/`),
                  ).then((personalOptionalAttachment: any) => {
                    props.values.setFieldValue('personalOptionalAttachment', personalOptionalAttachment[0])
                  })
                }}
                style={{ display: 'none' }}
              />
              {props.values.values.personalOptionalAttachment ? (
                <p>
                  <label className={styles.labelshowfile} htmlFor="personalOptionalAttachment">
                    <img className={styles.filelogo} src={FileIcon} alt="filelogo" />{' '}
                    <p>{props.values.values.personalOptionalAttachment}</p>
                  </label>
                </p>
              ) : (
                <p className="margin-Bottom15px">
                  <label className={styles.labelfile} htmlFor="personalOptionalAttachment">
                    <span className={styles.primaryuploadspan}>
                      <img className={styles.filelogo} src={FileIconWhite} alt="filelogo" /> Upload File
                    </span>
                    <span className={styles.secondaryuploadspan}>อัปโหลดเอกสารอื่น ๆ (JPEG,PNG,PDF)</span>
                  </label>
                </p>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
