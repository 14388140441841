import React, { useState } from 'react'
import styles from './index.module.scss'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import FileUploadIcon from '@mui/icons-material/FileUpload'
//images
import UploadIcon from './images/upLoadIcon.svg'
import NoteIcon from './images/note.svg'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import UploadFileBlue from '../../image/UploadFileblue.svg'
//Formik
import { useFormikContext } from 'formik'

interface Props {
  _name?: string
  _isShowFileName?: any
  _functionOnChange?: any
  _values?: any
}

const UploadButton: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  // const { values, handleChange, setFieldValue } = useFormikContext<any>()

  const [fileName, setFileName] = useState('')
  const [fileUrl, setFileUrl] = useState<any>(null)

  // setFieldValue('experience', props.values)
  console.log(fileName)
  console.log(fileUrl)
  return (
    <>
      {/* {fileName === '' ? (
        ''
      ) : fileName !== '' && props._isShowFileName === true ? (
        <p className={styles.textFile}>
          <span>
            <img src={NoteIcon} alt="" />
          </span>
          {fileName}
        </p>
      ) : (
        ''
      )} */}
      {fileName ? (
        <p className={styles.textFile}>
          <span>
            <img src={UploadFileBlue} alt="" />
          </span>
          {fileName}
        </p>
      ) : (
        <Stack direction="row" spacing={2}>
          <Button
            fullWidth
            style={{ backgroundColor: '#3A77DA' }}
            variant="contained"
            startIcon={<UploadFileIcon />}
            component="label"
          >
            Upload
            <input
              // value={values[fileName]}
              id={props._name}
              name={props._name}
              value={props._values}
              type="file"
              hidden
              onChange={(e: any) => {
                if (e.target.files.length !== 0) {
                  handleChange(e)
                  setFileName(e.target.files[0].name)
                }

                // props?._functionOnChange?.(),
                //   e.target.files && setFileName(e.target.files[0].name),
                //   setFileUrl(URL.createObjectURL(e.target.files[0]))
                //
              }}
            />
          </Button>
        </Stack>
      )}
    </>
  )
}
export default UploadButton
