import { combineReducers } from 'redux'
import modalReducer, { IInitialState } from './modal'
import modalComfirmReducer, { IInitialStateModalConfirm } from './modalConfirm'
import companyIDReducer, { IInitialStateCompanyID } from './companyID'
import loginReducer, { IInitialStateLogin } from './login'

export interface ICombineReducers {
  modalReducer: IInitialState
  modalComfirmReducer: IInitialStateModalConfirm
  companyIDReducer: IInitialStateCompanyID
  loginReducer: IInitialStateLogin
}

const rootReducer = combineReducers<ICombineReducers>({
  modalReducer,
  modalComfirmReducer,
  companyIDReducer,
  loginReducer,
})
export default rootReducer
