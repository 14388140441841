import React from 'react'
import styles from './index.module.scss'

// Components
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent } from '../../component'

//Formik
import * as yup from 'yup'
import { Formik } from 'formik'
import {
  InputTextComponent,
  InputSelectComponent,
  InputCheckboxComponent,
  InputTextUnitComponent,
} from '../../component'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { useNavigate } from 'react-router-dom'

const OvertimePolicyEditPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const validationSchema = yup.object({
    nameOvertime: yup.string().required('Name overtime is required'),
    typeOvertime: yup.string().required('Type overtime is required'),
    wage: yup.string().required('Wage is required'),
  })
  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <Formik
          initialValues={{
            employee: false,
            daily: false,
            hourly: false,
            outsourceDaily: false,
            outsourceHourly: false,
            outsourceMonthly: false,
            outsourceProject: false,
            nameOvertime: '',
            typeOvertime: '',
            wage: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              // alert(JSON.stringify(values, null, 2))
              actions.setSubmitting(false)
            }, 1000)
            dispatch(
              allAction.modalConfirmAction.setOpenModalConfirm(
                'ยืนยันการทำรายการ',
                'คุณต้องการยืนยันการทำรายการ',
                'เพิ่มข้อมูลการทำงานล่วงเวลา?',
                'ใช่, ทำรายการ',
                () => {
                  dispatch(allAction.modalAction.setOpenModalAlertStatus('เพิ่มรายการเรียบร้อย', '', 'success'))
                  dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                },
              ),
            )
          }}
        >
          {(props: any) => (
            <div className="main">
              <form onSubmit={props.handleSubmit}>
                {console.log(props.values)}
                <div className={styles.container}>
                  <section className={styles.topicPageWrapper}>
                    <p className={styles.topicPage}>เพิ่มระเบียบการทำงานล่วงเวลา</p>
                  </section>
                  <div className={styles.cardContainer}>
                    <div className={styles.inputContainer}>
                      <section>
                        <p className={styles.subTopic}>ประเภทงาน</p>
                        <div className={styles.rowWrapper}>
                          {dataCheckBox1.map((Element, index) => {
                            return (
                              <div key={index} className={styles.checkBox}>
                                <InputCheckboxComponent _label={Element.checkboxLabel} _name={Element.checkboxName} />
                              </div>
                            )
                          })}
                        </div>
                        <div className={styles.rowWrapper}>
                          {dataCheckBox2.map((Element, index) => {
                            return (
                              <div key={index} className={styles.checkBox}>
                                <InputCheckboxComponent _label={Element.checkboxLabel} _name={Element.checkboxName} />
                              </div>
                            )
                          })}
                        </div>
                      </section>

                      <section className={styles.inputBox}>
                        <InputTextComponent
                          _name="nameOvertime"
                          _label="ชื่อ OT"
                          _helperText={props.errors.nameOvertime}
                        />
                        <InputSelectComponent
                          _name="typeOvertime"
                          _label="ประเภทโอที"
                          _helperText={props.errors.typeOvertime}
                          _menuList={[
                            { label: 'ค่าล่วงเวลาวันปกติ', value: 'ค่าล่วงเวลาวันปกติ' },
                            { label: 'ค่าล่วงเวลาวันหยุด', value: 'ค่าล่วงเวลาวันหยุด' },
                            { label: 'ค่าทำงานในวันหยุด', value: 'ค่าทำงานในวันหยุด' },
                          ]}
                        />
                        <InputTextUnitComponent
                          _name="wage"
                          _label="ค่าแรง"
                          _descriptionText="เช่น 1, 1.5, 2, 3 เท่า"
                          _unit="เท่า"
                          _helperText={props.errors.wage}
                        />
                      </section>
                    </div>
                  </div>
                  <section className={styles.btnFooterWrapper}>
                    <div className={styles.btnBox}>
                      <ButtonComponent
                        _colorBG="white"
                        _colorText="red"
                        _text="ยกเลิก"
                        _variant="contained"
                        _type="button"
                        _functionOnClick={() => navigate(-1)}
                      />
                    </div>
                    <div className={styles.btnBox}>
                      <ButtonComponent
                        _colorBG="blue"
                        _colorText="white"
                        _text="เพิ่ม"
                        _variant="contained"
                        _type="submit"
                      />
                    </div>
                  </section>
                </div>
              </form>
            </div>
          )}
        </Formik>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default OvertimePolicyEditPage

const dataCheckBox1 = [
  {
    checkboxLabel: 'พนักงานรายเดือน',
    checkboxName: 'employee',
  },
  {
    checkboxLabel: 'รายวัน',
    checkboxName: 'daily',
  },
  {
    checkboxLabel: 'รายชั่วโมง',
    checkboxName: 'hourly',
  },
]
const dataCheckBox2 = [
  {
    checkboxLabel: 'จ้างเหมารายวัน',
    checkboxName: 'outsourceDaily',
  },
  {
    checkboxLabel: 'จ้างเหมารายชั่วโมง',
    checkboxName: 'outsourceHourly',
  },
  {
    checkboxLabel: 'จ้างเหมารายเดือน',
    checkboxName: 'outsourceMonthly',
  },
  {
    checkboxLabel: 'จ้างเหมารายโปรเจ็ค',
    checkboxName: 'outsourceProject',
  },
]
