import React from 'react'
import { Bar } from 'react-chartjs-2'
// เเสดงข้อมูลในBar เเต่ละอัน
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)

interface Props {
  // responsive?: boolean
  // data?: number[]
  // labels?: string[]
  // indexAxis?: 'x' | 'y'
  labels: string[]
  firstLabel: string
  firstData: number[]
  firstColor: string
  secondLabel?: string
  secondData?: number[]
  secondColor?: string
  stackX: boolean
  stackY: boolean
  height: number
}

const GraphBarLineCard: React.FC<Props> = (props: Props): JSX.Element => {
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: props.firstLabel,
        // borderRadius: 30,
        data: props.firstData,
        backgroundColor: props.firstColor,
      },
      {
        label: props.secondLabel,
        // borderRadius: 30,
        data: props.secondData,
        backgroundColor: props.secondColor,
      },
    ],
  }

  const options: any = {
    indexAxis: 'x',
    // indexAxis: 'y',
    // indexAxis: props.indexAxis,
    maintainAspectRatio: true,
    // responsive: props.responsive,
    responsive: true,
    plugins: {
      // ข้อมูลใน Bar เเต่ละอัน
      datalabels: {
        color: 'red',
        display: false,
      },
      // type ข้อมูล
      legend: {
        position: 'bottom',
        labels: {
          color: 'black',
          font: {
            family: 'Kanit',
            size: 12,
          },
        },
        display: false,
      },
    },
    layout: {
      padding: {
        left: 0,
        // right: props.indexAxis === 'y' ? 30 : 0,
        // top: props.indexAxis === 'x' ? 30 : 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    font: {
      family: 'Kanit',
    },
    scales: {
      // yAxes: [
      //   {
      //     display: true,
      //     scaleLabel: {
      //       display: true,
      //       labelString: 'adsasddas',
      //     },
      //   },
      // ],
      x: {
        // suggestedMax: 5,

        stacked: props.stackX,
        // suggestedMin: 0,
        ticks: {
          font: {
            size: 12,
          },
        },
        grid: {
          display: true,
          lineWidth: 1,
        },
      },
      // xAxes: [
      //   {
      //     display: true,
      //     scaleLabel: {
      //       display: true,
      //       labelString: 'Time',
      //     },
      //   },
      // ],
      y: {
        stacked: props.stackY,
        // suggestedMax: 5,
        // suggestedMin: 0,
        ticks: {
          font: {
            size: 12,
          },
        },
        grid: {
          display: true,
          lineWidth: 1,
        },
      },
    },
  }
  return <Bar data={data} height={props.height} options={options} />
}
export default GraphBarLineCard
