import {
  Avatar,
  AvatarGroup,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ButtonComponent, NavSideBarComponent, NavTopBarComponent } from '../../component'
import styles from './index.module.scss'

import AddUser from '../../image/addUser.png'
import Lessthan from '../../image/lessThan.png'
import CountUser from '../../image/countUser.png'
import RowOfTotal from './rowOfTotal'
import RowOfMoney from './rowOfMoney'
import { ModalCreatePackage } from './ModalCreate'
import ImgIcon from '../../image/picUser.svg'
import { TRUE } from 'node-sass'
import { useNavigate } from 'react-router-dom'

const CreatePackage: React.FC = (): JSX.Element => {
  const [addPackage, setAddPackage] = useState(false)
  const [open, setOpen] = useState(false)
  const [addUser, setAddUser] = useState(false)
  const [newlistUser, setNewlistUser] = useState<any>([])
  const [movePackageEmployee, setMovePackageEmployee] = useState(false)
  const [listpackage, setListPackage] = useState<any>({})
  const navigate = useNavigate()

  const mockUser = [
    {
      name: 'แผนกบัญชี',
      user: [
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '01',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '02',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '03',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '04',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '05',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '06',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '07',
        },
      ],
    },
    {
      name: 'แผนกการตลาด',
      user: [
        {
          name: 'Adison Saris',
          img: ImgIcon,
          money: 300,
          id: '08',
        },
        {
          name: 'Adison Saris',
          img: ImgIcon,
          money: 300,
          id: '09',
        },
        {
          name: 'Adison Saris',
          img: ImgIcon,
          money: 300,
          id: '10',
        },
      ],
    },
    {
      name: 'แผนกฝ่ายบุคคล',
      user: [
        {
          name: 'Talan Schleifer',
          img: ImgIcon,
          money: 300,
          id: '11',
        },
        {
          name: 'Beam Schleifer',
          img: ImgIcon,
          money: 300,
          id: '12',
        },
      ],
    },
  ]

  const Leave = [
    {
      name: 'ลาป่วย',
      workAge: 18,
      total: 70,
      totalofLeave: 8,
      link: false,
      // choice: true,
    },
    {
      name: 'ลาพบเเพทย์',
      workAge: 24,
      total: 70,
      totalofLeave: 0,
      link: true,

      // choice: true,
    },
    {
      name: 'ลากิจ',
      workAge: 121,
      total: 6,
      totalofLeave: 0,
      link: true,

      // choice: true,
    },
    {
      name: 'ลาบวช',
      workAge: 4,
      total: 70,
      totalofLeave: 0,
      link: false,

      // choice: true,
    },
    {
      name: 'ลาคลอด',
      workAge: 18,
      total: 70,
      totalofLeave: 0,
      link: true,

      // choice: true,
    },
    // {
    //   name: 'ลาพักร้อน',
    // },
  ]
  const Welfare = [
    {
      name: 'ค่าขนม',
      money: 300,
    },
    {
      name: 'ค่าอาหาร',
      money: 300,
    },
    {
      name: 'ค่าเดินทาง',
      money: 300,
    },
    {
      name: 'ค่าเดินทาง',
      money: 300,
    },
    {
      name: 'ค่าอาหารสัตว์',
      money: 300,
    },
  ]

  const PackageData: any[] = [
    {
      name: 'เเพ็กเกจ1',
      id: '01',
      leaveDetail: [
        {
          name: 'ลาป่วย',
          workAge: 18,
          total: 70,
          totalofLeave: 8,
        },
        {
          name: 'ลาพบเเพทย์',
          workAge: 24,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลากิจ',
          workAge: 121,
          total: 6,
          totalofLeave: 0,
        },
        {
          name: 'ลาบวช',
          workAge: 4,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลาคลอด',
          workAge: 18,
          total: 70,
          totalofLeave: 0,
        },
      ],
      userLits: [
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
      ],
    },
    {
      id: '02',
      name: 'เเพ็กเกจ2',
      leaveDetail: [
        {
          name: 'ลาป่วย',
          workAge: 18,
          total: 70,
          totalofLeave: 8,
        },
        {
          name: 'ลาพบเเพทย์',
          workAge: 24,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลากิจ',
          workAge: 121,
          total: 6,
          totalofLeave: 0,
        },
        {
          name: 'ลาบวช',
          workAge: 4,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลาคลอด',
          workAge: 18,
          total: 70,
          totalofLeave: 0,
        },
      ],
      userLits: [
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
      ],
    },
  ]

  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>
      <div className="main">
        <div className={styles.mainInPage}>
          <div className={styles.headZone}>
            <h1 className={styles.title}>ตั้งค่าเเพ็กเกจ</h1>
            <div className={styles.btnZone}>
              <div className={styles.button}>
                <ButtonComponent
                  _colorBG="transparent"
                  _text="ย้ายเเพ็กเกจพนักงาน"
                  _colorText="blue"
                  _type={'button'}
                  _variant="outlined"
                  _functionOnClick={() => navigate('../employee/tranPackage')}
                />
              </div>

              <div className={styles.button}>
                <ButtonComponent
                  _colorBG="blue"
                  _text="เพิ่มแพ็กเกจ"
                  _colorText="white"
                  _type={'button'}
                  _variant="contained"
                  _functionOnClick={() => {
                    setOpen(true)
                    setListPackage({})
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.dataZone}>
            {movePackageEmployee && (
              <div className={styles.rowUserZone} style={{ width: '50%', backgroundColor: '#fff' }}>
                {mockUser.map((c: any, n: number) => {
                  return (
                    <div className={styles.oneDepartment} key={n}>
                      <h4 className={styles.departmentText}>{c.name}</h4>
                      <div className={styles.allUser}>
                        {c.user.map((e: any, i: number) => {
                          return (
                            <div className={styles.oneUser} key={i}>
                              <div className={styles.leftZoneUser}>
                                <img className={styles.userImg} src={e.img} alt={e.name} />
                                <div>{e.name}</div>
                              </div>
                              <input
                                className={styles.checkboxItem}
                                onClick={() => {
                                  setNewlistUser([...newlistUser, e])
                                }}
                                type="checkbox"
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            <div
              style={
                movePackageEmployee
                  ? {
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '2em 3em',
                    }
                  : { width: '100%' }
              }
              className={styles.allPackage}
            >
              {console.log(listpackage, 'PackageData')}
              {PackageData.map((e: any, i) => {
                return (
                  <div
                    className={styles.onePackage}
                    key={i}
                    onClick={() => {
                      setListPackage(e)
                      setOpen(true)
                    }}
                  >
                    <h2>{e.name}</h2>
                    <div className={styles.detailInOnePackage}>
                      {e.leaveDetail.map((a: any, n: number) => {
                        return (
                          <div className={styles.detailItem} key={n}>
                            <div>{a.name}</div>
                            <div>{a.totalofLeave}</div>
                          </div>
                        )
                      })}
                    </div>
                    <AvatarGroup className={styles.allAvata} max={4}>
                      {e.userLits.map((q: any, w: number) => {
                        return <Avatar key={w} alt={q.name} src={q.img} />
                      })}
                    </AvatarGroup>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <ModalCreatePackage open={open} setOpen={setOpen} leaveData={Leave} user={mockUser} listpackage={listpackage} />
    </div>
  )
}
export default CreatePackage
