import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import {
  ButtonComponent,
  NavSideBarComponent,
  NavTopBarComponent,
  ButtonSelect,
  PaginationBar,
  // InputTextComponentCustom,
} from '../../component'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
import search from '../../image/search.svg'
import List from '../../image/btList.svg'
import Grid from '../../image/btGrid.svg'
import { useNavigate } from 'react-router-dom'
import CollapsibleTable from './table'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Profile from '../../image/profile.svg'
// import detail from '../../image/detail.svg'
// import delIcon from '../../image/del.svg'
// import editIcon from '../../image/edit.svg'
// import { useDispatch } from 'react-redux'
// import allAction from '../../state/actions'
import { paginationFunc } from '../../utils/common'
import { QueryEmployeeList } from '../../adapter/xhr'

export const filterEmployeeActive = {
  filter: { status: { eq: 'ACTIVE' } },
}
const MenuEmployeeMain: React.FC = (): JSX.Element => {
  const exportData = [{ menu: 'pdf' }, { menu: 'csv' }]
  const [pageSite, setPageSite] = React.useState<number>(10)
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [employeeList, setEmployeeList] = useState<any>([])

  const [listItemIndex, setListItemIndex] = React.useState<number>(0)
  // const dispatch = useDispatch()
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const open = Boolean(anchorEl)
  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  const [showList, setShowlist] = useState(false)
  const [searchbar, setSearchbar] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    QueryEmployeeList(filterEmployeeActive).then((res: any) => {
      setEmployeeList(res.data.data.listEmployee.items)
      console.log(res, 'resssssssssss')
    })
  }, [])

  const employee = paginationFunc(employeeList, pageSite)[listItemIndex]
    ? paginationFunc(employeeList, pageSite)[listItemIndex]
    : []

  // const [employees, setEmployees] = useState([])
  // const Employees: any = () => {
  //   paginationFunc(employeeList, pageSite)[listItemIndex] ? paginationFunc(employeeList, pageSite)[listItemIndex] : []
  // }

  const [employeename, setEmployeeName] = useState('')
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            <h1 className={styles.title}>พนักงาน</h1>
            <div className={styles.head}>
              <div className={styles.iconBar}>
                {!searchbar && (
                  <div className={styles.ellipse}>
                    <img src={search} alt="" className={styles.search} onClick={() => setSearchbar(!searchbar)} />
                  </div>
                )}

                {searchbar && (
                  <div className={styles.searchbar}>
                    <TextField
                      label={<p>{'Search Employee'}</p>}
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: '25ch', borderRadius: '10px' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={search} alt="" onClick={() => setSearchbar(!searchbar)} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e: any) => setEmployeeName(e.target.value)}
                    />
                  </div>
                )}

                <img src={List} alt="" className={styles.icon} onClick={() => setShowlist(false)} />
                <img src={Grid} alt="" className={styles.icon} onClick={() => setShowlist(true)} />
              </div>
            </div>
          </div>
          {!showList && (
            <div className={styles.cardTable}>
              <div className={styles.head}>
                <h1 className={styles.title}>รายชื่อพนักงาน</h1>
                <div className={styles.buttonBox}>
                  <div className={styles.btImport}>
                    <ButtonComponent
                      _variant="contained"
                      _text="Import"
                      _colorText="white"
                      _colorBG="secondary"
                      _type="button"
                      _functionOnClick={() => navigate('ot/create')}
                    />
                  </div>

                  <div className={styles.btExport}>
                    <ButtonSelect
                      _text="Export"
                      _colorBG="blue"
                      _colorText="white"
                      _variant="outlined"
                      _selectData={exportData}
                    />
                  </div>

                  <div className={styles.btAdd}>
                    <ButtonComponent
                      _isIcon="add"
                      _variant="contained"
                      _text="เพิ่มพนักงาน"
                      _colorText="white"
                      _colorBG="blue"
                      _type="button"
                      _functionOnClick={() => navigate('../employee/employee/add')}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.Areatable}>
                <div className={styles.table}>
                  <CollapsibleTable
                    employeeList={
                      paginationFunc(employeeList, pageSite)[listItemIndex]
                        ? paginationFunc(employeeList, pageSite)[listItemIndex]
                        : []
                    }
                    employeename={employeename}
                  />
                </div>

                <div className={styles.bottomtable}>
                  <PaginationBar
                    _dropDownValue={pageSite}
                    _onDropdownChange={(e: any) => {
                      setPageSite(e.target.value)
                      setListItemIndex(0)
                    }}
                    _selectedNumber={listItemIndex + 1}
                    _numberList={paginationFunc(employeeList, pageSite).map((e: any, index: number) => index + 1)}
                    _setSelectedNumber={(event: any, number: number, index: number) => {
                      if (listItemIndex !== index) {
                        setListItemIndex(index)
                        if (index + 2 === paginationFunc(employeeList, pageSite).length && nextToken) {
                          QueryEmployeeList(filterEmployeeActive).then((res: any) => {
                            if (res.data.data.listEmployee.items && res.data.data.listEmployee.items.length > 0) {
                              setEmployeeList([...employeeList, ...res.data.data.listEmployee.items])
                            }
                            // setNextToken(res.data.data.listEmployee.nextToken)
                          })
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {showList && (
            <div className={styles.cardView}>
              <div className={styles.head}>
                <div className={styles.btImport}>
                  <ButtonComponent
                    _variant="contained"
                    _text="Import"
                    _colorText="white"
                    _colorBG="secondary"
                    _type="button"
                    _functionOnClick={() => navigate('ot/create')}
                  />
                </div>
                <div className={styles.btExport}>
                  <ButtonSelect
                    _text="Export"
                    _colorBG="secondary"
                    _colorText="white"
                    _variant="outlined"
                    _selectData={exportData}
                  />
                </div>
                <div className={styles.btAdd}>
                  <ButtonComponent
                    _isIcon="add"
                    _variant="contained"
                    _text="เพิ่มพนักงาน"
                    _colorText="white"
                    _colorBG="blue"
                    _type="button"
                    _functionOnClick={() => navigate('../employee/employee/add')}
                  />
                </div>
              </div>
              <div className={styles.content}>
                {employee
                  .filter((item: any) => {
                    if (employeename === '') {
                      return item
                    } else if (
                      item.fullName.toLocaleLowerCase().includes(employeename.toLocaleLowerCase()) ||
                      item.lastName.toLocaleLowerCase().includes(employeename.toLocaleLowerCase())
                    ) {
                      return item
                    }
                  })
                  .map((employee: any, empIndex: number) => (
                    <div
                      className={styles.card}
                      key={empIndex}
                      onClick={() => navigate(`../employee/employee/edit/${employee.id}`)}
                    >
                      <div className={styles.profile}>
                        <img src={Profile} alt="" />
                      </div>
                      <p className={styles.nametext}>
                        {employee.fullName} {employee.lastName}
                      </p>
                      <p className={styles.postext}>{employee.department.departmentName}</p>
                    </div>
                  ))}

                <PaginationBar
                  _dropDownValue={pageSite}
                  _onDropdownChange={(e: any) => {
                    setPageSite(e.target.value)
                    setListItemIndex(0)
                  }}
                  _selectedNumber={listItemIndex + 1}
                  _numberList={paginationFunc(employeeList, pageSite).map((e: any, index: number) => index + 1)}
                  _setSelectedNumber={(event: any, number: number, index: number) => {
                    if (listItemIndex !== index) {
                      setListItemIndex(index)
                      if (index + 2 === paginationFunc(employeeList, pageSite).length && nextToken) {
                        QueryEmployeeList(filterEmployeeActive).then((res: any) => {
                          if (res.data.data.listEmployee.items && res.data.data.listEmployee.items.length > 0) {
                            setEmployeeList([...employeeList, ...res.data.data.listEmployee.items])
                          }
                          // setNextToken(res.data.data.listEmployee.nextToken)
                        })
                      }
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer">Footer54321</div>
    </div>
  )
}

export default MenuEmployeeMain
