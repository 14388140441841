/* eslint-disable react/no-children-prop */
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './index.scss'
import { ModalAlertStatus, ModalConfirm } from './component'
import {
  HomePage,
  LoginAdminPage,
  Attendance,
  AttendanceEdit,
  LeavePolicy,
  LeavePolicyEdit,
  BenefitPolicy,
  BenefitPolicyEdit,
  MenuEmployeeMain,
  Department,
  DepartmentNewEdit,
  ApprovalSystemPage,
  Payroll,
  PayrollCreate,
  OvertimePolicy,
  OvertimePolicyEdit,
  ApprovalCheckInOut,
  ApprovalCheckInOutEdit,
  AttendanceLog,
  Team,
  TeamNewEdit,
  MainPosition,
  CreatePosition,
  UserManageTeam,
  UserManageTeamAdduser,
  UserManageTeamNewEdite,
  UserManagementView,
  UserManagementNewEdit,
  PayMent,
  HolidayPolicyPage,
  HolidayPolicyEditPage,
  AttendanceLogPage,
  CreatePackage,
  Dashboard,
  CalendarDetailPage,
  TranPackage,
  MenuEmployeeEdit,
  ForgotPassword,
  PageNotFound,
  LeaveDocument,
  OvertimeDocument,
} from './pages'
import useCompanyID from './hooks/useCompanyID'

const App: React.FC = (): JSX.Element => {
  useCompanyID()
  // console.log(subdomain, 'subdomain')

  return (
    <>
      <Routes>
        {/* Central Login */}
        <Route path="/" element={<LoginAdminPage />} />

        <Route path="/homePage" element={<HomePage />} />

        {/* Admin Route */}
        <Route path="/admin">
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="calendar/detail" element={<CalendarDetailPage />} />
          <Route path="homePage" element={<HomePage />} />
          <Route path="employee/employee" element={<MenuEmployeeMain />} />
          <Route path="employee/employee/add" element={<MenuEmployeeEdit />} />
          <Route path="employee/employee/edit/:id" element={<MenuEmployeeEdit />} />
          <Route path="employee/position" element={<MainPosition />} />
          <Route path="employee/position/add" element={<CreatePosition />} />
          <Route path="employee/position/edit/:id" element={<CreatePosition />} />
          <Route path="employee/department" element={<Department />} />
          <Route path="employee/department/add" element={<DepartmentNewEdit />} />
          <Route path="employee/department/edit/:id" element={<DepartmentNewEdit />} />
          <Route path="employee/team" element={<Team />} />
          <Route path="employee/team/add" element={<TeamNewEdit />} />
          <Route path="employee/team/edit/:id" element={<TeamNewEdit />} />
          <Route path="employee/creatPackage" element={<CreatePackage />} />
          <Route path="employee/tranPackage" element={<TranPackage />} />

          <Route path="wage/attendanceReport" element={<AttendanceLog />} />
          <Route path="wage/approval" element={<ApprovalCheckInOut />} />
          <Route path="wage/approval/add" element={<ApprovalCheckInOutEdit />} />
          <Route path="wage/approval/edit/:id" element={<ApprovalCheckInOutEdit />} />
          <Route path="wage/payroll" element={<Payroll />} />
          <Route path="wage/payroll/add" element={<PayrollCreate />} />
          <Route path="wage/payroll/edit/:id" element={<PayrollCreate />} />
          <Route path="wage/payment" element={<PayMent />} />
          <Route path="wage/attendanceLog" element={<AttendanceLogPage />} />

          <Route path="forgotpassword" element={<ForgotPassword />} />

          <Route path="masterdata/attendance" element={<Attendance />} />
          <Route path="masterdata/attendance/add" element={<AttendanceEdit />} />
          <Route path="masterdata/attendance/edit/:id" element={<AttendanceEdit />} />
          <Route path="masterdata/leavepolicy" element={<LeavePolicy />} />
          <Route path="masterdata/leavepolicy/add" element={<LeavePolicyEdit />} />
          <Route path="masterdata/leavepolicy/edit" element={<LeavePolicyEdit />} />
          <Route path="masterdata/benefitpolicy" element={<BenefitPolicy />} />
          <Route path="masterdata/benefitpolicy/add" element={<BenefitPolicyEdit />} />
          <Route path="masterdata/benefitpolicy/edit" element={<BenefitPolicyEdit />} />
          <Route path="masterdata/overtimepolicy" element={<OvertimePolicy />} />
          <Route path="masterdata/overtimepolicy/add" element={<OvertimePolicyEdit />} />
          <Route path="masterdata/overtimepolicy/edit" element={<OvertimePolicyEdit />} />
          <Route path="masterdata/holidayPolicy" element={<HolidayPolicyPage />} />
          <Route path="masterdata/holidayPolicy/edit/:id" element={<HolidayPolicyEditPage />} />
          <Route path="masterdata/holidayPolicy/edit/" element={<HolidayPolicyEditPage />} />

          <Route path="approvalsystem" element={<ApprovalSystemPage />} />

          <Route path="usermanage/team" element={<UserManageTeam />} />
          <Route path="usermanage/team/adduser" element={<UserManageTeamAdduser />} />
          <Route path="usermanage/team/addteam" element={<UserManageTeamNewEdite />} />
          <Route path="usermanage/team/addteam/:id" element={<UserManageTeamNewEdite />} />

          <Route path="usermanage/user/view" element={<UserManagementView />} />
          <Route path="usermanage/user/new" element={<UserManagementNewEdit />} />
          <Route path="usermanage/user/edit/:id" element={<UserManagementNewEdit />} />

          <Route path="document/LeaveDocument" element={<LeaveDocument />} />

          <Route path="document/OvertimeDocument" element={<OvertimeDocument />} />
        </Route>

        {/* Employee Route */}
        <Route path="/employee"></Route>

        <Route path="/pagenotFound" element={<PageNotFound />} />
      </Routes>

      <ModalConfirm />
      <ModalAlertStatus />
    </>
  )
}

export default App
