import React from 'react'
import styles from './index.module.scss'
import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import SearchIcon from '@mui/icons-material/Search'
import { SxProps, Theme } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CalculateIcon from '../../image/calculate.svg'
import PayrollIcon from '../../image/closePayroll.svg'
import GearDollarSign from '../../image/gearDollarSign.svg'
import FilterIcon from '../../image/filterIcon.svg'
import FileIcon from '../../image/fileWhite.svg'
interface Props {
  _type: 'button' | 'submit'
  _colorBG:
    | 'blue'
    | 'green'
    | 'red'
    | 'white'
    | 'black'
    | 'secondary'
    | 'darkblue'
    | 'gray'
    | 'transparent'
    | 'lightBlue'
  _colorText: 'blue' | 'green' | 'red' | 'white' | 'black' | 'secondary' | 'gray'
  _variant: 'text' | 'outlined' | 'contained'
  _isIcon?: 'add' | 'search' | 'reset' | 'calculate' | 'payroll' | 'expenseIncome' | 'filter' | 'file'
  _text: string
  _functionOnClick?: any
  _sx?: SxProps<Theme>
  _isDisabled?: boolean
  _value?: any
}

const listColor: any = [
  {
    name: 'gray',
    code: '#626262',
  },
  {
    name: 'darkblue',
    code: '#004CA8',
  },
  {
    name: 'blue',
    code: '#3A77DA',
  },
  {
    name: 'green',
    code: '#8AC82D',
  },
  {
    name: 'red',
    code: '#FF6161',
  },
  {
    name: 'white',
    code: '#FFFFFF',
  },
  {
    name: 'black',
    code: '#000000',
  },
  {
    name: 'secondary',
    code: '#5357BB',
  },
  {
    name: 'transparent',
    code: 'transparent',
  },
  {
    name: 'lightBlue',
    code: '#D4D5EE',
  },
]

const ButtonComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const codeBG: string = listColor.find((ele: any) => ele.name === props._colorBG).code
  const codeText: string = listColor.find((ele: any) => ele.name === props._colorText).code

  return (
    <Button
      fullWidth
      type={props._type}
      sx={props._sx}
      onClick={(event: any) => props?._functionOnClick?.(event)}
      startIcon={
        props._isIcon === 'add' ? (
          <AddCircleOutlineIcon />
        ) : props._isIcon === 'search' ? (
          <SearchIcon />
        ) : props._isIcon === 'reset' ? (
          <AutorenewIcon />
        ) : props._isIcon === 'calculate' ? (
          <img src={CalculateIcon} alt="" />
        ) : props._isIcon === 'payroll' ? (
          <img src={PayrollIcon} alt="" />
        ) : props._isIcon === 'expenseIncome' ? (
          <img src={GearDollarSign} alt="" />
        ) : props._isIcon === 'filter' ? (
          <img src={FilterIcon} alt="" />
        ) : props._isIcon === 'file' ? (
          <img src={FileIcon} alt="" />
        ) : (
          ''
        )
      }
      variant={props._variant}
      value={props._value}
      size="small"
      style={
        props._isDisabled
          ? { backgroundColor: '#626262', color: codeText, height: 'inherit', borderColor: codeText }
          : { backgroundColor: codeBG, color: codeText, height: 'inherit', borderColor: codeText }
      }
      disabled={props._isDisabled}
    >
      <span>{props._text}</span>
    </Button>
  )
}

export default ButtonComponent
