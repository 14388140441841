import React, { useState } from 'react'
import styles from './index.module.scss'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { ButtonComponent } from '../../../component'
import { InputSelectComponentCustom } from '../../../component/InputsFormik'

interface Props {
  dataList: any
  list: string
  setList: any
  data: any
  _name?: any
  _checked?: boolean
  _onChange?: any
}
const NameComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const [isCheckAll, setIsCheckAll] = useState(false)

  const [isSelect, setIsSelect] = React.useState('ALL')

  const isSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSelect(event.target.value)
  }

  // const handleSelectAll = (e: any) => {
  //   setIsCheckAll(!isCheckAll)
  //   setIsCheck(list.map((li) => li.id))
  //   if (isCheckAll) {
  //     setIsCheck([])
  //   }
  // }

  const isSelectCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    {
      setIsSelect('รายการทั้งหมด')
    }
  }

  const handleselect = (event: any) => {
    return 'ALL'
  }

  // useEffect(() => {
  //   setList(Catalogues)
  // }, [list])
  return (
    <>
      <div className={styles.container}>
        <section>
          <TextField
            className={styles.textField}
            id="outlined-select-currency"
            select
            label=""
            value={isSelect}
            onChange={isSelectChange}
            size="small"
          >
            {props.dataList.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                <p>{option.label}</p>
              </MenuItem>
            ))}
          </TextField>
        </section>
        <section className={styles.btnWrapper}>
          <div>
            <FormControlLabel
              style={{ color: '#FFFFFF' }}
              control={
                <div className={styles.checkboxBox}>
                  <Checkbox
                    sx={{
                      color: '#3A77DA',
                      '&.Mui-checked': {
                        color: '#3A77DA',
                      },
                    }}
                    checked={props._checked}
                    onChange={props._onChange}
                    // value={props._name}
                  />
                </div>
              }
              label="เลือกทั้งหมด"
            />
          </div>
          <div className={styles.btnBox}>
            <ButtonComponent
              _variant="outlined"
              _text="ค้นหา"
              _colorText="white"
              _colorBG="darkblue"
              _type="button"
              _functionOnClick={() => props.setList(isSelect)}
            />
          </div>
        </section>
      </div>
    </>
  )
}
export default NameComponent
