import * as React from 'react'
import { Fade, Popper, Tooltip } from '@mui/material'
import styles from './index.module.scss'
import { Formik } from 'formik'
import allAction from '../../state/actions'
import { QueryGetAttendance, UpdateAttendanceReport } from '../../adapter/xhr'
import { ButtonComponent, InputTextComponentCustom } from '../../component'
import { useDispatch } from 'react-redux'
import closeIcon from '../../image/close.svg'
import noteBlueIcon from '../../image/noteBlue.svg'
import AddIcon from '../../image/addblue.svg'
import { toTime } from './EType'
import { ModalOverTimePopper } from './ModalPopper'

export const PopperOverTime: React.FC<any> = (props: any): JSX.Element => {
  const [openModalOT, setOpenModalOT] = React.useState(false)
  const [getReport, setGetReport] = React.useState<any>({})
  const [otListIndex, setOtListIndex] = React.useState<number>(0)

  const sendReport = (index: number) => {
    setGetReport(props.reportOT[index])
    console.log(getReport, 'getReportIndex')
  }
  const reportOtList = () => {
    return props.reportOT.slice(0, 3)
  }

  return (
    <div>
      <Popper transition placement="right" anchorEl={props.anchorEl} open={props.openpopper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={styles.Popper}>
              <div className={styles.pointer}></div>
              <div className={styles.modal}>
                <div className={styles.modalGroup}>
                  {reportOtList().map((listot: any, OTIndex: number) => (
                    <div key={OTIndex} className={styles.GroupText}>
                      <section className={styles.detail}>
                        <p>
                          {toTime(listot.startedAt)} - {toTime(listot.endedAt)}
                        </p>
                      </section>
                      <div
                        className={
                          listot.status === 'PENDING'
                            ? styles.pillWarn
                            : listot.status === 'REJECT'
                            ? styles.pillReject
                            : listot.status === 'DRAFT'
                            ? styles.pillDraft
                            : listot.status === 'APPROVE'
                            ? styles.pill
                            : styles.notpill
                        }
                      >
                        {listot.status ? (
                          <p>{listot.status}</p>
                        ) : (
                          <Tooltip title={<p>สร้างรายการเอกสารโอที</p>} placement="bottom" arrow>
                            <img
                              src={AddIcon}
                              alt=""
                              className={styles.Icon}
                              onClick={() => {
                                setOpenModalOT(true)
                                sendReport(listot.index)
                                setOtListIndex(listot.index)
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>

                      <img src={noteBlueIcon} alt="" className={styles.Icon} />
                    </div>
                  ))}
                  {props?.reportOT.length > 3 && (
                    <p className={styles.textBottom}>+ {props.reportOT.length - 3} รายการ</p>
                  )}
                </div>
              </div>
            </div>
          </Fade>
        )}
      </Popper>
      <ModalOverTimePopper
        openModalOT={openModalOT}
        setOpenModalOT={setOpenModalOT}
        getReport={getReport}
        QueryReport={props.QueryReport}
        date={props.date}
        employeeId={props.employeeId}
        reportList={reportOtList}
        onSentOvertime={(res: any, status: string) => {
          const newOverTime: any = {
            overtimeRequestID: res.data.data.createOvertimeRequest.id,
            overtimeType: res.data.data.createOvertimeRequest.overtimeType,
            status: res.data.data.createOvertimeRequest.status,
            startedAt: new Date(res.data.data.createOvertimeRequest.startedAt).toISOString(),
            endedAt: new Date(res.data.data.createOvertimeRequest.endedAt).toISOString(),
          }

          const employeeReport = props.report.find((r: any) => r.employeeID === props.employeeId)
          const dateReport = employeeReport.items.find((e: any) => e.date === props.date)

          // UPDATE OT LIST IN REPORT
          dateReport.overtimeList[otListIndex].status = status
          dateReport.overtimeApproveList.push(newOverTime)

          const UpdateAttendanceVariables = {
            input: {
              employeeID: props.employeeId,
              date: props.date,
              overtimeApproveList: dateReport.overtimeApproveList,
              overtimeList: dateReport.overtimeList,
            },
          }
          UpdateAttendanceReport(UpdateAttendanceVariables).then((attendance: any) => {
            console.log(attendance, 'attendance')
            setTimeout(() => {
              props.QueryReport()
            }, 100)
          })
        }}
      />
    </div>
  )
}

export const PooperRemark: React.FC<any> = (props: any): JSX.Element => {
  const inputRef = React.useRef<any>()
  const dispatch = useDispatch()

  const QueryRemark = () => [
    QueryGetAttendance({ date: props.date, employeeId: props.employeeId }).then((res: any) => {
      if (res.data.data?.getTimeAttendanceReport?.remark) {
        inputRef.current.setFieldValue('remark', res.data.data.getTimeAttendanceReport.remark)
      } else {
        inputRef.current.setFieldValue('remark', '')
      }
    }),
  ]
  React.useEffect(() => {
    QueryRemark()
  }, [props.date, props.employeeId])

  const propscurrent = props

  return (
    <Formik
      innerRef={inputRef}
      initialValues={{
        remark: '',
      }}
      onSubmit={(values, action) => {
        const VariablesRemark: any = {
          input: {
            employeeID: props.employeeId,
            date: props.date,
            remark: values.remark,
          },
        }
        dispatch(
          allAction.modalConfirmAction.setOpenModalConfirm(
            `เพิ่มหมายเหตุ`,
            `${values.remark}`,
            '',
            'ใช่,ทำรายการ',
            () => {
              UpdateAttendanceReport(VariablesRemark).then((res: any) => console.log(res))
              dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
              props.setPopperRemark(false)
              dispatch(allAction.modalAction.setOpenModalAlertStatus('บันทึกการแก้ไขเรียบร้อยแล้ว', '', 'success'))
              setTimeout(() => {
                props.QueryReport(props.startdate, props.endAt)
              }, 100)
              // inputRef.current.setFieldValue('remark', values.remark)
            },
          ),
        )
      }}
      //   validationSchema={validationSchema}
    >
      {(props: any) => (
        <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
          <div>
            <Popper open={propscurrent.popperRemark} anchorEl={propscurrent.anchorEl} placement="left">
              <div className={styles.area}>
                <div className={styles.modal}>
                  <div className={styles.modalGroup}>
                    <div className={styles.header}>
                      <p className={styles.text}>เพิ่มหมายเหตุ</p>
                      <img
                        src={closeIcon}
                        alt=""
                        className={styles.close}
                        onClick={() => propscurrent.setPopperRemark(false)}
                      />
                    </div>
                    <InputTextComponentCustom _name="remark" _value={props.values.remark} />
                    <section className={styles.buttonFooter}>
                      <ButtonComponent
                        _colorBG="white"
                        _colorText="red"
                        _text="ลบ"
                        _variant="contained"
                        _type="button"
                        _functionOnClick={() => inputRef.current.setFieldValue('remark', '')}
                      />
                      <ButtonComponent
                        _colorBG="blue"
                        _colorText="white"
                        _text="บันทึก"
                        _variant="contained"
                        _type="submit"
                        _functionOnClick={props.handleSubmit}
                      />
                    </section>
                  </div>
                </div>
                <div className={styles.pointer}></div>
              </div>
            </Popper>
          </div>
        </form>
      )}
    </Formik>
  )
}
