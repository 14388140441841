import { Checkbox } from '@mui/material'
import React, { useState } from 'react'
import styles from './index.module.scss'

interface Props {
  _leftMenuList?: string[]
  _rightMenuList?: string[]
  _menuList?: any[]
  _content?: any[]
  _head?: any[]
}
const Accordion: React.FC<Props> = (props: Props): JSX.Element => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className={styles.accordioncard}>
      <div className={styles.header}>
        <div className={styles.headerCheckboxContainer}>
          <Checkbox />
        </div>
        <div className={styles.menulist} onClick={() => setIsActive(!isActive)}>
          <section className={styles.leftMenuList}>
            {props._leftMenuList?.map((leftMenuString: string, index) => {
              return (
                <p className={styles.leftTextHead} style={{ textAlign: 'left' }} key={index}>
                  {leftMenuString}
                </p>
              )
            })}
          </section>
          <section></section>
          <section className={styles.rightMenuList}>
            {props._rightMenuList?.map((rightMenuString: string, index) => {
              return (
                <p className={styles.rightTextHead} key={index}>
                  {rightMenuString}
                </p>
              )
            })}
          </section>
        </div>
      </div>
      {isActive && (
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            {props._head?.map((header, index) => {
              return (
                <p key={index} className={styles.textmenu}>
                  {header}
                </p>
              )
            })}
          </div>

          {props._content?.map((item: any, index: any) => (
            <div className={styles.contentColumn} key={index}>
              {' '}
              {item.map((e: any, index: number) => (
                <div key={index} className={styles.subdata}>
                  <p className={styles.text}> {index + 1}</p>
                  {e.map((sub: any, index: number) => {
                    return (
                      <p key={index} className={styles.text}>
                        {sub}
                      </p>
                    )
                  })}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Accordion
