import React, { useState, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent } from '../../component'

const PageNotFound: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <div className="main">
          <div className={styles.center}>
            <div className={styles.error}>
              <div className={styles.number}>4</div>
              <div className={styles.illustration}>
                <div className={styles.circle}></div>
                <div className={styles.clip}>
                  <div className={styles.paper}>
                    <div className={styles.face}>
                      <div className={styles.eyes}>
                        <div className={`${styles.eye} ${styles['eye-left']}`}></div>
                        <div className={`${styles.eye} ${styles['eye-right']}`}></div>
                      </div>
                      <div className={`${styles.rosyCheeks} ${styles['rosyCheeks-left']}`}></div>
                      <div className={`${styles.rosyCheeks} ${styles['rosyCheeks-right']}`}></div>
                      <div className={styles.mouth}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.number}>4</div>
            </div>

            <div className={styles.text}>{`- We're coming soon. Please wait! -`}</div>
            {/* <a className={styles.button} href="/">
              Back Home
            </a> */}
          </div>
        </div>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default PageNotFound
