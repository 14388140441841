import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent, CardMasterDate } from '../../component'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { useNavigate } from 'react-router-dom'
import { DelHoliday, QueryCalendarPolicy, UpdateDefaultHoliday } from '../../adapter/xhr'
// Components
const HolidayPolicyPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [calendarlist, setCalendarList] = useState<any>([])
  const NewQueryCalendar = () => {
    QueryCalendarPolicy().then((res: any) => {
      console.log(res, 'res')

      setCalendarList(res.data.data.listHoliday)
      console.log(calendarlist, 'listCalendar')
    })
  }
  useEffect(() => {
    NewQueryCalendar()
  }, [])
  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <div className="main">
          <div className={styles.container}>
            <section className={styles.topicPageWrapper}>
              <p className={styles.topicPage}>ระเบียบวันหยุดประจำปี</p>
            </section>
            <div className={styles.contentContainer}>
              <section className={styles.btnTopWrapper}>
                <div className={styles.btnTop}>
                  <ButtonComponent
                    _type="button"
                    _colorBG="blue"
                    _colorText="white"
                    _variant="outlined"
                    _isIcon="add"
                    _text="เพิ่มระเบียบวันหยุดประจำปี"
                    _functionOnClick={() => navigate('../masterdata/holidayPolicy/edit')}
                  />
                </div>
              </section>
              <section className={styles.cardDetailWrapper}>
                {calendarlist.items?.map((data: any, index: number) => {
                  // {
                  //   console.log(JSON.parse(data.holidayYear)[2022].length, 'data')
                  // }
                  return (
                    <div key={index}>
                      <CardMasterDate
                        _topic={data.holidayName}
                        _subTopic={data.subTopic}
                        _title="จำนวนวัน"
                        _content={JSON.parse(data.holidayYear)[2022].length}
                        _defaultData={calendarlist.getDefaultHoliday}
                        isDefault={calendarlist.getDefaultHoliday === data.holidayID}
                        _functionOnClickDefault={() => {
                          UpdateDefaultHoliday({ input: { holidayId: data.holidayID } }).then((res: any) => {
                            console.log(res, 'Update Default')
                          })
                        }}
                        _functionOnClickEdit={() => navigate(`../masterdata/holidayPolicy/edit/${data.holidayID}`)}
                        _functionOnClickDelete={() => {
                          dispatch(
                            allAction.modalConfirmAction.setOpenModalConfirm(
                              'ลบระเบียบวันหยุด',
                              `คุณต้องการลบ ${data.holidayName} ใช่หรือไม่`,
                              'หากลบแล้ว ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                              'ใช่, ลบรายการ',
                              () => {
                                DelHoliday({ holidayId: data.holidayID }).then((res: any) => {
                                  console.log(res, 'Del')
                                })

                                dispatch(
                                  allAction.modalAction.setOpenModalAlertStatus(
                                    'ลบข้อมูลรายการวันหยุดร้อยแล้ว',
                                    '',
                                    'success',
                                  ),
                                )
                                dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                                setTimeout(() => {
                                  NewQueryCalendar()
                                }, 300)
                              },
                            ),
                          )
                        }}
                      />
                    </div>
                  )
                })}
              </section>
            </div>
          </div>
        </div>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default HolidayPolicyPage
