import React from 'react'
import styles from './index.module.scss'
// import closeIcon from './close.svg'
import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { ButtonComponent } from '../../component'
// import moment from 'moment'
import EmailIcon from '../../image/email.svg'
import PhoneIcon from '../../image/phone.svg'
const ModalView: React.FC<any> = (props: any): JSX.Element => {
  const dispatch = useDispatch()
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalBox}>
        <div className={styles.card}>
          <div className={styles.head}>
            <p className={styles.textHead}>กู้คืนรหัสผ่านใหม่</p>
            {/* <img
              src={closeIcon}
              alt=""
              onClick={() => {
                props.setOpen(false)
              }}
              className={styles.Closeicon}
            /> */}
          </div>
          <div className={styles.description}>
            <p>เลือกช่องทางยืนยันตัวตนที่คุณต้องการใช้ในการ เปลี่ยนรหัสผ่าน</p>
            <div
              className={styles.cardSend}
              onClick={() => {
                props.setOpen(false)
                dispatch(
                  allAction.modalAction.setOpenModalAlertStatus(
                    'ทำการกู้คืนรหัสผ่านให้ท่านแล้ว',
                    'โปรดตรวจสอบอีเมล',
                    'success',
                  ),
                )
              }}
            >
              <img src={EmailIcon} alt="" />
              <p>Email</p>
            </div>

            <div
              className={styles.cardSend}
              onClick={() => {
                props.setOpen(false)
                dispatch(
                  allAction.modalAction.setOpenModalAlertStatus(
                    'ทำการกู้คืนรหัสผ่านให้ท่านแล้ว',
                    'โปรดตรวจผ่านโทรศัพท์มือถือ',
                    'success',
                  ),
                )
              }}
            >
              <img src={PhoneIcon} alt="" />
              <p>หมายเลขโทรศัพท์</p>
            </div>
          </div>

          <div className={styles.bottom}>
            <div className={styles.btnForm}>
              <div className={styles.button}>
                <ButtonComponent
                  _colorBG="blue"
                  _colorText="white"
                  _text="ปิด"
                  _type="button"
                  _variant="contained"
                  _functionOnClick={() => props.setOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalView
