import React, { useEffect, useState, useRef } from 'react'
import styles from './index.module.scss'
// Components
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent, CalendarComponent } from '../../component'
//PrintCalendar
// import ReactToPrint from 'react-to-print'
//Formik
import * as yup from 'yup'
import { Formik } from 'formik'
import { InputTextComponent } from '../../component'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { useNavigate } from 'react-router-dom'
// import PrinterIcon from '../../image/prin.svg'
import { CreateHolidayPolicy, GetHoliday, UpdateHolidayOnYear } from '../../adapter/xhr'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const HolidayPolicyEditPage: React.FC = (): JSX.Element => {
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [year, setYear] = useState<number>(new Date().getFullYear())
  console.log(year, 'year')

  const formikRef: any = useRef()

  const baseURL =
    'https://www.googleapis.com/calendar/v3/calendars/th.th%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAoZVNllZof84luxMOlGXXJc9snfZb5UqI'
  const [holiday, setHoliday] = useState([])
  const queryHoliday = () => {
    axios.get(baseURL).then((res: any) => {
      console.log(res, 'Calendar')
      setHoliday(res.data.items)
    })
  }
  useEffect(() => {
    queryHoliday()
  }, [])
  useEffect(() => {
    if (id) {
      GetHoliday({ holidayId: id, year: year })
        .then((res: any) => {
          formikRef.current.setFieldValue('holidayName', res.data.data.getHoliday.holidayName)
          formikRef.current.setFieldValue('holidayYear', JSON.parse(res.data.data.getHoliday.holidayYear)[year])

          console.log(res, 'ress')

          // console.log(JSON.parse(res.data.data.getHoliday.holidayYear), 'res data ping')
        })
        .catch((error: any) => {
          console.log(error, 'error')
        })
      setMode('update')
    } else {
      setMode('create')
    }
  }, [])

  const GoogleCalendar: any[] = []
  holiday.map((item: any) => {
    return GoogleCalendar.push({
      date: item.start.date,
      description: item.summary,
    })
  })
  const HolidayOfYear = GoogleCalendar.filter((findele: any) => {
    return new Date(findele.date).getFullYear() === year
  })

  const Sort = HolidayOfYear.sort((a, b) => b.date - a.date)
  console.log(Sort, 'sort')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const validationSchema = yup.object({
    holidayName: yup.string().required('กรอกชื่อวันหยุด'),
  })

  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <Formik
          innerRef={formikRef}
          initialValues={{
            year: '',
            holidayName: '',
            holidayYear: [
              // {
              //   date: '',
              //   description: '',
              // },
            ],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const UpdateHolidayListVariable = {
              input: {
                holidayID: id,
                holidayName: values.holidayName,
                year: year,
                holidayYear: values.holidayYear,
              },
            }

            dispatch(
              allAction.modalConfirmAction.setOpenModalConfirm(
                'ยืนยันการทำรายการ',
                'คุณต้องการยืนยันการทำรายการ',
                'เพิ่มข้อมูลการลา?',
                'ใช่, ทำรายการ',
                () => {
                  if (mode === 'create') {
                    CreateHolidayPolicy({
                      year: year,
                      input: { holidayName: values.holidayName, holidayYear: values.holidayYear },
                    }).then((res: any) => {
                      console.log('Createt Holiday', res)
                    })
                  } else if (mode === 'update') {
                    UpdateHolidayOnYear({
                      input: {
                        year: year,
                        holidayID: id,
                        holidayName: values.holidayName,
                        holidayYear: values.holidayYear,
                      },
                    }).then((res: any) => {
                      console.log(res, 'Update')
                    })
                  }
                  // console.log('UpdateHolidayListVariable', UpdateHolidayListVariable)
                  dispatch(allAction.modalAction.setOpenModalAlertStatus('เพิ่มรายการเรียบร้อย', '', 'success'))
                  dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                  navigate(-1)
                },
              ),
            )
          }}
        >
          {(props: any) => (
            <div className="main">
              <form onSubmit={props.handleSubmit}>
                <div className={styles.container}>
                  <section className={styles.topicPageWrapper}>
                    {mode === 'create' && <p className={styles.topicPage}>เพิ่มระเบียบวันหยุดประจำปี</p>}
                    {mode === 'update' && <p className={styles.topicPage}>แก้ไขระเบียบวันหยุดประจำปี</p>}
                    {/* <img src={PrinterIcon} alt="" onClick={() => window.print()} /> */}
                    {/* <ReactToPrint trigger={() => <img src={PrinterIcon} alt="" />} content={() => } /> */}
                  </section>

                  <div className={styles.cardContainer}>
                    <p className={styles.textred}>*เลือกวันหยุดประจำปีขั้นต่ำ 13 วันตามกฎหมายแรงงาน</p>
                    <section className={styles.headerCalenDar}>
                      {' '}
                      <div className={styles.inputHoliday}>
                        {' '}
                        <InputTextComponent
                          _name="holidayName"
                          _label="ชื่อวันหยุดประจำปี"
                          _helperText={props.errors.holidayName}
                        />
                      </div>
                      <div className={styles.ButtonHoliday}>
                        {' '}
                        <ButtonComponent
                          _colorBG="blue"
                          _colorText="white"
                          _text="ดึงข้อมูลวันหยุด"
                          _variant="contained"
                          _type="button"
                          _functionOnClick={() => {
                            props.setFieldValue('holidayYear', HolidayOfYear)
                          }}
                        />
                      </div>
                    </section>
                    <CalendarComponent
                      values={props.values}
                      setFieldValue={props.setFieldValue}
                      year={year}
                      setYear={setYear}
                    />
                    <section className={styles.btnFooterWrapper}>
                      <div className={styles.btnBox}>
                        <ButtonComponent
                          _colorBG="white"
                          _colorText="red"
                          _text="ยกเลิก"
                          _variant="contained"
                          _type="button"
                          _functionOnClick={() => navigate(-1)}
                        />
                      </div>
                      <div className={styles.btnBox}>
                        <ButtonComponent
                          _colorBG="blue"
                          _colorText="white"
                          _text="บันทึกวันหยุด"
                          _variant="contained"
                          _type="submit"
                          // _functionOnClick={() => console.log('xxx')}
                        />
                      </div>
                    </section>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default HolidayPolicyEditPage
