import React from 'react'
import styles from './index.module.scss'
import closeIcon from '../../image/close.svg'
import { Modal, Backdrop, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import allAction from '../../state/actions'
import * as yup from 'yup'
import {
  InputDateComponentCustome,
  InputNumberComponentCustom,
  InputSelectComponentCustom,
  InputTextComponent,
  InputTextComponentCustom,
} from '../../component/InputsFormik'
import ButtonComponent from '../../component/Button'

const ModalTable: React.FC<any> = (props: any): JSX.Element => {
  const dispatch = useDispatch()
  const Headfund = [
    'ชื่อกองทุน',
    'เลขที่กองทุน',
    'วันที่สัญญากองทุน',
    'วิธีการหักเงิน',
    'เรทกองทุน',
    'วิธีการสมทบ',
    'บริษัทสมทบ',
    'ยอดสมทบ',
    'ผู้ได้รับผลประโยชน์',
    'หมายเหตุ',
  ]

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalBox}>
        <div className={styles.CardTable}>
          <Table aria-label="simple table" sx={{ width: '100%' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#D9D9D9' }}>
                {Headfund.map((item: any, index: number) => (
                  <TableCell align="center" key={index}>
                    <h4>{item}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.values.values.employeeFund.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputTextComponentCustom
                      _name={`employeeFund[${index}].name`}
                      _label="ชื่อกองทุน"
                      _value={item.name}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputNumberComponentCustom
                      _name={`employeeFund[${index}].fundNumber`}
                      _label="เลขที่กองทุน"
                      // _value={item.fundNumber}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputDateComponentCustome
                      _name={`employeeFund[${index}].contractDate`}
                      _label="เลขที่กองทุน"
                      _value={item.contractDate}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputSelectComponentCustom
                      _label="วิธีการหักเงิน"
                      _name={`employeeFund[${index}].deductMethod`}
                      _value={item.deductMethod}
                      _menuList={[
                        { label: 'คิดเป็น %', value: 'PERCENTAGE' },
                        { label: 'คิดเป็นบาท', value: 'BAHT' },
                      ]}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputNumberComponentCustom
                      _name={`employeeFund[${index}].fundRate`}
                      _label="เรทกองทุน"
                      // _value={item.fundNumber}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputSelectComponentCustom
                      _label="วิธีการสมทบ"
                      _name={`employeeFund[${index}].contributionMethod`}
                      _value={item.contributionMethod}
                      _menuList={[
                        { label: 'คิดเป็น %', value: 'PERCENTAGE' },
                        { label: 'คิดเป็นบาท', value: 'BAHT' },
                      ]}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputTextComponentCustom
                      _name={`employeeFund[${index}].contributionCompany`}
                      _label="บริษัทสมทบ"
                      _value={item.contributionCompany}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputTextComponentCustom
                      _name={`employeeFund[${index}].contributionTotal`}
                      _label="ยอดสมทบ"
                      _value={item.contributionTotal}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputTextComponentCustom
                      _name={`employeeFund[${index}].beneficiary`}
                      _label="ผู้ได้รับผลประโยชน์"
                      _value={item.beneficiary}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid #D9D9D9' }}>
                    <InputTextComponentCustom
                      _name={`employeeFund[${index}].remark`}
                      _label="หมายเหตุ"
                      _value={item.remark}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </Modal>
  )
}
export default ModalTable
