import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useParams } from 'react-router-dom'

// Mui
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import Checkbox from '@mui/material/Checkbox'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ListItemIcon from '@mui/material/ListItemIcon'
//icon
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '../../image/edit.svg'
import DeleteIcon from '../../image/delete.svg'
import ViewIcon from '../../image/viewIcon.svg'
import FormControl from '@mui/material/FormControl'
import MenuItemList from './menuItem'
interface Props {
  _checkList?: any
  _setCheckList?: any
  _ClickView?: any
  _ClickEdit?: any
  _ClickDelete?: any
  _dataList?: any
}
const TableCheck: React.FC<Props> = (props: Props): JSX.Element => {
  // console.log('Data >> props', props._dataList)
  //* Menu item
  // const [selectedRowItem, setSelectedRowItem] = useState<string[]>([])
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const open = Boolean(anchorEl)
  // const handleClick = (event: any, list: string[]) => {
  //   setAnchorEl(event.currentTarget)
  //   console.log('list..->', list)
  //   setSelectedRowItem(list)
  // }
  // const handleClose = () => {
  //   console.log('menu')

  //   setSelectedRowItem([])
  //   setAnchorEl(null)
  // }
  const [isAllChecked, setIsAllChecked] = useState(true)
  useEffect(() => {
    const listCheckboxBoolean: boolean[] = []
    props._dataList.map(() => {
      listCheckboxBoolean.push(false)
    })
    props._setCheckList(listCheckboxBoolean)
  }, [])

  const checkChange = (event: any) => {
    const listCheckboxBoolean: boolean[] = []
    props._dataList.map(() => {
      listCheckboxBoolean.push(event.target.values)
    })
    props._setCheckList(listCheckboxBoolean)
    setIsAllChecked(event.target.check)
  }

  // console.log('Check >>', checked)
  console.log('>>Props', props)
  return (
    <>
      <div className={styles.container}>
        <section className={styles.headTable}>
          <FormControl>
            <Checkbox
              // disabled={true}
              // value={isAllChecked}
              onChange={(event, value) => {
                console.log(event.target, value, 'event.target')
                setIsAllChecked(value)
              }}
              checked={isAllChecked}
            />
          </FormControl>
          {/* <Checkbox
            value={isAllChecked}
            onChange={(event, value) => {
              console.log(event.target, value, 'event.target')
              setIsAllChecked(value)
            }}
          /> */}
          <article className={styles.rowWrapper}>
            <div className={styles.leftHeaderContainer}>
              {headStartData.map((leftHeaderString: string, index: number) => {
                return (
                  <p key={index} className={styles.headerText}>
                    {leftHeaderString}
                  </p>
                )
              })}
            </div>
            <div></div>
            <div className={styles.rightHeaderContainer}>
              {headEndData.map((rightHeaderString: string, index: number) => {
                return (
                  <p key={index} className={styles.headerText}>
                    {rightHeaderString}
                  </p>
                )
              })}
            </div>
          </article>
        </section>
        <section>
          {props._dataList.map((list: any, dataListIndex: number) => {
            console.log('list >>', list)
            // console.log('_set >> ', props._setList)
            return (
              <div className={styles.card} key={dataListIndex}>
                <section className={styles.headCard}>
                  {/* <FormControl> */}
                  <Checkbox
                    // value={props._checkList[dataListIndex]}
                    value={list.isCheck}
                    onChange={(event, values) => {
                      // const cloneCheckedBox = props._checkList
                      // cloneCheckedBox[dataListIndex] = !cloneCheckedBox[dataListIndex]
                      // console.log(cloneCheckedBox, dataListIndex, 'cloneCheckedBox')
                      // props._setCheckList(cloneCheckedBox)
                      // if (!props._checkList.includes(false)) {
                      //   setIsAllChecked(true)
                      // } else {
                      //   setIsAllChecked(false)
                      // }
                      console.log(list, 'listttt')
                      list.isCheck = values
                      console.log(values, 'values')
                    }}
                  />
                  {/* </FormControl> */}
                  <article className={styles.rowWrapper}>
                    <div className={styles.leftSubHeader}>
                      {list.tableTopicStart.map((leftContentHeaderString: string, index: number) => {
                        return (
                          <p key={index} className={styles.headerText}>
                            {leftContentHeaderString}
                          </p>
                        )
                      })}
                    </div>

                    <div></div>
                    <div className={styles.rightSubHeader}>
                      {list.tableTopicEnd.map((rightContentHeaderString: string, index: number) => {
                        return (
                          <p key={index} className={styles.headerText}>
                            {rightContentHeaderString}
                          </p>
                        )
                      })}
                    </div>
                  </article>
                </section>
                <section className={styles.contentCard}>
                  <div>
                    <article className={styles.rowWrapper2}>
                      {headContent.map((subHeaderText: string, index: number) => {
                        return (
                          <p key={index} className={styles.headerText}>
                            {subHeaderText}
                          </p>
                        )
                      })}
                    </article>
                    <article className={styles.rowWrapper2}>
                      {list.data.map((data: any) =>
                        data.content.map((content: any) => {
                          return (
                            <>
                              {content === 'Draft' ? (
                                <div className={styles.statusDraft}>
                                  <p>{content}</p>
                                </div>
                              ) : content === 'Success' ? (
                                <p className={styles.statusSuccess}>{content}</p>
                              ) : content === 'Pending' ? (
                                <div className={styles.statusPenging}>
                                  <p>{content}</p>
                                </div>
                              ) : (
                                <p>{content}</p>
                              )}
                            </>
                          )
                        }),
                      )}
                    </article>
                  </div>

                  <div>
                    <div className={styles.menuitem}>
                      <MenuItemList edit={list.editOnclick} />
                    </div>
                  </div>
                </section>
              </div>
            )
          })}
        </section>
      </div>
    </>
  )
}
export default TableCheck

const headStartData = ['ID', 'Name', 'ตำแหน่ง', 'ประเภทงาน']
const headEndData = ['วันที่เริ่มต้น', 'วันที่สิ้นสุด']
const headContent = [
  'จำนวนวันลาทั้งหมด',
  'เวลามาสาย',
  'ชั่วโมงการทำงานล่วงเวลา',
  'จำนวนการมาทำงาน',
  'สวัสดิการ',
  'สถานะ',
]
// interface IDataList {
//   tableTopicStart: string[]
//   tableTopicEnd: string[]
//   data: IData[]
//   viewOnclick: () => void
//   editOnclick: () => void
//   deleteOnclick: () => void
// }

// interface IData {
//   content: string[]
// }
