import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import * as yup from 'yup'
import { Formik } from 'formik'
import { ELeave, ESaveDescrip, SaveType } from './EType'
import { CreateLeaveDocument, CreateOvertimeDocument, UpdateAttendanceReport } from '../../adapter/xhr'
import { ButtonComponent, ButtonSelectCustom, UploadButton } from '../../component'
import {
  InputDateComponentCustome,
  InputSelectComponentCustom,
  InputTextComponentCustom,
  InputTimeComponentCustom,
} from '../../component/InputsFormik'
import { getHour, toTime } from '../../utils/common'
import Switch from '../../component/Switch'

export const ModalOverTimePopper: React.FC<any> = (props: any): JSX.Element => {
  const [status, setStatus] = React.useState('APPROVE')
  const [overtimeList, setOvertimeList] = React.useState([])
  const dispatch = useDispatch()

  const SaveTypeDoc = [
    {
      menu: 'Save',
      value: 'PENDING',
    },
    { menu: 'Save and Approve', value: 'APPROVE' },
  ]

  const otherProps = props

  const statusType = (e: any) => {
    setStatus(e)
  }

  const OTList = [
    { label: 'วันทำงาน', value: 'WORK' },
    { label: 'ทำงานล่วงเวลา', value: 'OVERTIME' },
  ]

  const validationSchema = yup.object({
    overtimeType: yup.string().required('เลือกประเภทการทำงานล่วงเวลา'),
    startedAt: yup.string().required('กรอกเวลาเริ่มต้น'),
    endedAt: yup.string().required('กรอกเวลาสิ้นสุด'),
  })
  return (
    <Modal
      open={props.openModalOT}
      onClose={() => {
        props.setOpenModalOT(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalLeaveEdaite}>
        <div className={styles.card}>
          <Formik
            initialValues={{
              startedAt: toTime(otherProps.getReport.startedAt),
              endedAt: toTime(otherProps.getReport.endedAt),
              status: '',
              overtimeType: otherProps.getReport.overtimeType,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              console.log(status, 'status')

              const DocumentOvertimeVariables = {
                input: {
                  date: props.date,
                  employeeID: props.employeeId,
                  startedAt: values.startedAt,
                  endedAt: values.endedAt,
                  status: status,
                  overtimeType: values.overtimeType,
                },
              }
              dispatch(
                allAction.modalConfirmAction.setOpenModalConfirm(
                  `${ESaveDescrip[`${status}`]} OT`,
                  `วันที่ ${props.date} เวลา ${values.startedAt} - ${values.endedAt} `,
                  'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                  `ใช่,${ESaveDescrip[`${status}`]}`,
                  () => {
                    CreateOvertimeDocument(DocumentOvertimeVariables).then((res: any) => {
                      console.log(res, 'res')
                      props.onSentOvertime?.call(null, res, status)
                    })

                    dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันทำรายการเรียบร้อย', '', 'success'))
                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                    props.setOpenModalOT(false)
                  },
                ),
              )
            }}
          >
            {(props: any) => (
              <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                {' '}
                <div className={styles.header}>
                  <div className={styles.bt}>
                    <ButtonComponent
                      _type={'button'}
                      _colorBG={'white'}
                      _colorText={'red'}
                      _variant={'text'}
                      _text={'ยกเลิก'}
                      _sx={{ fontSize: '16px' }}
                      _functionOnClick={() => otherProps.setOpenModalOT(false)}
                    />
                  </div>
                  <p className={styles.textHead}>สร้างเอกสารโอที</p>
                  <div className={styles.btSelect}>
                    <ButtonSelectCustom
                      _selectData={SaveTypeDoc}
                      _onchange={(e: any) => {
                        statusType(e)
                      }}
                    />
                  </div>
                </div>
                <div className={styles.FormGroup}>
                  <div className={styles.filedInputFlex}>
                    <p>ประเภทโอที</p>
                    <div className={styles.inputBox}>
                      <InputSelectComponentCustom
                        _name="overtimeType"
                        _label={'ประเภทโอที'}
                        _menuList={OTList}
                        _helperText={props.errors.overtimeType}
                        _value={props.values.overtimeType}
                      />
                    </div>
                  </div>

                  <div className={styles.filedInputTime}>
                    <p>ช่วงเวลา</p>

                    <section className={styles.GroupBox}>
                      <div className={styles.InputTime}>
                        <InputTimeComponentCustom
                          _name="startedAt"
                          _label={'เริ่มต้น'}
                          _helperText={props.errors.startedAt}
                          _value={props.values.startedAt}
                        />
                      </div>
                      <div className={styles.InputTime}>
                        <InputTimeComponentCustom
                          _name="endedAt"
                          _label={'สิ้นสุด'}
                          _helperText={props.errors.endedAt}
                          _value={props.values.endedAt}
                        />
                      </div>
                    </section>
                  </div>
                  {/* <p className={styles.sumHour}>
                    รวมเวลา {getHour(date, props.values.startedAt, props.values.endedAt)} ชั่วโมง
                  </p> */}

                  <div className={styles.filedInputFlex}>
                    <p>วันที่</p>
                    <div className={styles.inutBox}>
                      <InputDateComponentCustome
                        _name={'date'}
                        _value={otherProps.date}
                        _disabled={true}
                        _label={'วันที่'}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export const ModalLeavePopper: React.FC<any> = (props: any): JSX.Element => {
  console.log(props, 'propsssssssssss')

  const [type, setType] = React.useState('leave')
  const [status, setStatus] = React.useState('APPROVE')
  const dispatch = useDispatch()

  const statusType = (e: any) => {
    setStatus(e)
  }

  const LeaveList = [
    { label: 'ลาป่วย', value: 'REASON_SICK' },
    { label: 'ลาพักร้อน', value: 'REASON_ANNUAL_LEAVE' },
    { label: 'ลากิจ', value: 'REASON_PERSONAL_LEAVE' },
    {
      label: 'ขาด',
      value: 'ABSENT',
    },
  ]
  const LeavFormList = [
    {
      label: 'กำหนดเอง',
      value: 'CUSTOM_TIME',
    },
    {
      label: 'ลาเต็มวัน',
      value: 'FULLDAY_LEAVE',
    },
    {
      label: 'ลาครึ่งวันเช้า',
      value: 'HALFDAY_MORNING_LEAVE',
    },
    {
      label: 'ลาครึ่งวันบ่าย',
      value: 'HALFDAY_AFTERNOON_LEAVE',
    },
  ]

  const date = props.date
  const employeeId = props.attendance.employeeID
  const validationSchema = yup.object({
    leaveType: yup.string().required('เลือกประเภทการลา'),
  })

  const otherProps = props

  const [leaveForm, setLeaveForm] = useState('')
  const [value, setValues] = useState<any>('')

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalLeaveEdaite}>
        <div className={styles.card}>
          <Formik
            initialValues={{
              leaveType: '',
              startedAt: '',
              endedAt: '',
              leaveDocumentAttachment: '',
              isLeaveDeductMoney: false,
              status: '',
              leaveForm: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              const DocumentLeaveVariables = {
                input: {
                  date: date,
                  employeeID: employeeId,
                  status: status,
                  leaveType: values.leaveType,
                  startedAt: props.startedAt,
                  endedAt: props.endedAt,
                  isLeaveDeductMoney: values.isLeaveDeductMoney,
                  leaveForm: 'CUSTOM_TIME',
                },
              }
              console.log(DocumentLeaveVariables, 'DocumentLeaveVariables')

              dispatch(
                allAction.modalConfirmAction.setOpenModalConfirm(
                  `${ESaveDescrip[`${status}`]} ${ELeave[`${values.leaveType}`]} `,
                  `วันที่ ${date} เวลา ${values.startedAt} - ${values.endedAt} `,
                  'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                  `ใช่,${ESaveDescrip[`${status}`]}`,
                  () => {
                    CreateLeaveDocument(DocumentLeaveVariables).then((res: any) => {
                      console.log(res, 'res')
                      props.leaveList.push({
                        leaveType: values.leaveType,
                        startedAt: props.startedAt,
                        endedAt: props.endedAt,
                        leaveDocumentAttachment: values.leaveDocumentAttachment,
                        status: status,
                        leaveForm: 'CUSTOM_TIME',
                        type: type,
                        leaveRequestID: res.data.data.createLeaveRequest.id,
                      })
                      props.setFieldValue(`leaveList`, props.leaveList)
                    })
                    console.log(DocumentLeaveVariables, 'Document')
                    dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันทำรายการเรียบร้อย', '', 'success'))
                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                    props.setOpen(false)
                  },
                ),
              )
            }}
          >
            {(formikProps: any) => (
              <form className={styles.wrapperForm} onSubmit={formikProps.handleSubmit}>
                <div className={styles.header}>
                  <div className={styles.bt}>
                    <ButtonComponent
                      _type={'button'}
                      _colorBG={'white'}
                      _colorText={'red'}
                      _variant={'text'}
                      _text={'ยกเลิก'}
                      _sx={{ fontSize: '16px' }}
                      _functionOnClick={() => otherProps.setOpen(false)}
                    />
                  </div>
                  <p className={styles.textHead}>สร้างใหม่</p>
                  <div className={styles.btSelect}>
                    <ButtonSelectCustom
                      _text={'Save'}
                      _selectData={SaveType}
                      _onchange={(e: any) => {
                        statusType(e)
                        console.log('clikkk')
                      }}
                    />
                  </div>
                </div>
                <div className={styles.FormGroup}>
                  <div className={styles.filedInputFlex}>
                    <p>ประเภทการลา</p>
                    <div className={styles.inputBox}>
                      <InputSelectComponentCustom
                        _name="leaveType"
                        _label={'ประเภทการลา'}
                        _menuList={LeaveList}
                        _helperText={formikProps.errors.leaveType}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.toggle}>
                      <Switch
                        options={[
                          { label: 'ลาไม่หักเงิน', value: 'leave' },
                          { label: 'ลาหักเงิน', value: 'leaveDeduct' },
                        ]}
                        onChange={(value: any) => {
                          setType(value)
                          console.log(value, 'val')
                          if (value === 'leaveDeduct') {
                            formikProps.setFieldValue('isLeaveDeductMoney', true)
                          } else if (value === 'leave') {
                            formikProps.setFieldValue('isLeaveDeductMoney', false)
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.filedInputFlex}>
                    <p>รูปแบบการลา</p>
                    <div className={styles.inputBox}>
                      <InputTextComponentCustom _name="leaveForm" _value={'กำหนดเอง'} _disabled={true} />
                    </div>
                  </div>

                  <div className={styles.filedInputTime}>
                    <p>ช่วงเวลา</p>
                    <div className={styles.GroupBox}>
                      <div className={styles.InputTime}>
                        {/* <Datepicker view='' /> */}
                        <InputTimeComponentCustom
                          _name="startedAt"
                          _label={'เริ่มต้น'}
                          _helperText={formikProps.errors.startedAt}
                          _value={props.startedAt}
                          _disabled={true}
                        />
                      </div>
                      <div className={styles.InputTime}>
                        <InputTimeComponentCustom
                          _name="endedAt"
                          _label={'สิ้นสุด'}
                          _helperText={formikProps.errors.endedAt}
                          _value={props.endedAt}
                          _disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.filedInputFlex}>
                    <p>แนบไฟล์</p>
                    <div className={styles.inputUpload}>
                      <div className={styles.UploadBt}>
                        <UploadButton _name="leaveDocumentAttachment" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.filedInputFlex}>
                    <p>วันที่</p>
                    <div className={styles.inputBox}>
                      <InputDateComponentCustome _name={'date'} _value={date} _disabled={true} _label={'วันที่'} />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
