import React, { useState } from 'react'
import styles from './index.module.scss'
import LogoSVG from './images/logo.svg'
// import ExpandSVG from './images/expand.svg'
import BellSVG from './images/bell.svg'
import ChatSVG from './images/chat.svg'
// import QuestionSVG from './images/question.svg'
import ProfileSVG from './images/profile.svg'
import HamburgurIcon from './images/hamburgur.svg'
import { useNavigate } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// import { checkAllTokens } from '../../adapter/xhr'
import useUser from '../../hooks/useUser'

const NavTopBarComponent: React.FC = (): JSX.Element => {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const { user, loading, status } = useUser()

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const [showNavside, setShowNavSide] = useState(false)

  return (
    <section className={styles.wrapper}>
      <div className={styles.leftSide}>
        <img alt="ExpandSVG" src={HamburgurIcon} width={30} height={30} onClick={() => console.log('clickk')} />
        <img alt="LogoSVG" src={LogoSVG} width={185} height={35} />
      </div>
      <div className={styles.rightSide}>
        {/* <p>Trial expires in 14 Day</p> */}
        <img alt="ChatSVG" src={ChatSVG} width={30} height={30} />
        <img alt="BellSVG" src={BellSVG} width={30} height={30} />
        {/* <img alt="QuestionSVG" src={QuestionSVG} width={30} height={30} /> */}
        <p>{user.username}</p>
        <img onClick={handleClick} alt="ProfileSVG" src={ProfileSVG} width={40} height={40} />
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {options.map((data) => (
            <MenuItem
              key={data.menu}
              onClick={() => {
                setAnchorEl(null)
                navigate(data.path)

                if (data.menu === 'Sign Out') {
                  localStorage.clear()
                }
              }}
            >
              {data.menu}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </section>
  )
}
export default NavTopBarComponent

const options = [
  { menu: 'Profile', path: '../profilesetting' },
  { menu: 'Edit Profile', path: '../profilesetting/Edit' },
  { menu: 'Account Setting', path: '#' },
  { menu: 'User History', path: '#' },
  { menu: 'Sign Out', path: '/' },
]
