import * as React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
// import { TableDraf, TablePay } from './tablepayroll'
import styles from './index.module.scss'
import Edit from '../../image/editblur.svg'
import ClosePeriod from './closePeriod'
import ExpenseIncome from './expenseIncome'
import SummaryIncome from './summarySalary'
import WorkListPayroll from './WorkList'
// import { TableData, TableUser } from './table'

interface ITabPayroll {
  payrollList?: any
  period?: string
  date?: string
  isShowMoney?: boolean
}

export const TabPayroll: React.FC<ITabPayroll> = (props: ITabPayroll): JSX.Element => {
  const [value, setValue] = React.useState('1')
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }
  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="รายการทำงาน" value="1" sx={{ fontFamily: 'KanitRegular' }} />
          <Tab label="รายรับรายจ่าย" value="2" sx={{ fontFamily: 'KanitRegular' }} />
          <Tab label="สรุปเงินเดือน" value="3" sx={{ fontFamily: 'KanitRegular' }} />
          <Tab label="ปิดงวดเงินเเดือน" value="4" sx={{ fontFamily: 'KanitRegular' }} />
        </TabList>
      </Box>

      <TabPanel value="1">
        <WorkListPayroll payrollList={props.payrollList} />
      </TabPanel>
      <TabPanel value="2">
        <ExpenseIncome payrollList={props.payrollList} period={props.period} date={props.date} />
      </TabPanel>
      <TabPanel value="3">
        <SummaryIncome
          isShowMoney={props.isShowMoney}
          payrollList={props.payrollList}
          period={props.period}
          date={props.date}
        />
      </TabPanel>
      <TabPanel value="4">
        <ClosePeriod payrollList={props.payrollList} period={props.period} date={props.date} />
      </TabPanel>
    </TabContext>
  )
}

export const TabData: React.FC<any> = (props: any): JSX.Element => {
  const [value, setValue] = React.useState('1')
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }

  const leaveData = [
    {
      no: '1',
      leave: 'ลาป่วย',
      count: 10,
      spentday: 12,
      hour: 0,
      title: 'สิทธิลาป่วยได้รับค่าจ้าง',
      subtitle: 'สิทธิลาป่วยไม่ได้รับค่าจ้าง',
    },
    {
      no: '2',
      leave: 'ลากิจ',
      count: 10,
      spentday: 9,
      hour: 0,
      title: 'สิทธิลากิจได้รับค่าจ้าง',
      subtitle: 'สิทธิลากิจไม่ได้รับค่าจ้าง',
    },
    {
      no: '3',
      leave: 'ลาพักร้อน',
      count: 7,
      spentday: 3,
      hour: 0,
      title: 'สิทธิลาพักร้อนได้รับค่าจ้าง',
      subtitle: 'สิทธิลาพักร้อนไม่ได้รับค่าจ้าง',
    },
    {
      no: '4 ',
      leave: 'ขาด',
      count: 10,
      spentday: 3,
      hour: 0,
      title: 'สิทธิขาดได้รับค่าจ้าง',
      subtitle: 'สิทธิขาดไม่ได้รับค่าจ้าง',
    },
  ]

  const late = [{ no: '1', minute: 32 }]

  const OT = [{ no: '1', hour: '10:30' }]

  const hourWork = [{ no: '1', hour: '40' }]

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList variant="scrollable" onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="วันลา" value="1" sx={{ fontFamily: 'KanitRegular' }} />
            <Tab label="มาสาย" value="2" sx={{ fontFamily: 'KanitRegular' }} />
            <Tab label="การทำงานล่วงเวลา " value="3" sx={{ fontFamily: 'KanitRegular' }} />
            <Tab label="การเข้า/ออกงาน " value="4" sx={{ fontFamily: 'KanitRegular' }} />
          </TabList>
        </Box>
        <TabPanel value="1">
          <div className={styles.cardLeave}>
            {' '}
            <div className={styles.content}>
              {leaveData.map((data) => (
                <div className={styles.card} key={data.no}>
                  <div className={styles.headdata}>
                    <p className={styles.title}>{data.leave}</p>
                    <img src={Edit} alt="" className={styles.editIcon} />
                  </div>

                  <div className={styles.textbottom}>
                    <span className={styles.quataText}>
                      {data.title} {data.spentday} วัน {data.hour} ชม.
                    </span>
                    <br />
                    <span className={styles.subtitle}>
                      {data.subtitle}
                      {data.spentday} วัน {data.hour} ชม.
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div className={styles.cardLate}>
            {' '}
            <div className={styles.content}>
              {late.map((data) => (
                <div className={styles.card} key={data.no}>
                  <div className={styles.lategroup}>
                    <p className={styles.title}>มาสายรวม</p>
                    <div className={styles.lateText}>
                      <span className={styles.numtext}>
                        <span className={styles.text}>{data.minute}</span> /120
                      </span>
                    </div>
                    <p className={styles.subtext}>นาที</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
        <TabPanel value="3">
          <div className={styles.cardLate}>
            {' '}
            <div className={styles.content}>
              {OT.map((data) => (
                <div className={styles.card} key={data.no}>
                  <div className={styles.lategroup}>
                    <p className={styles.title}>ทำงานล่วงเวลารวม</p>
                    <div className={styles.lateText}>
                      <span className={styles.numtext}>
                        <span className={styles.text}>{data.hour}</span>
                      </span>
                    </div>
                    <p className={styles.subtext}>ชั่วโมง</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>

        <TabPanel value="4">
          <div className={styles.cardLate}>
            {' '}
            <div className={styles.content}>
              {hourWork.map((data) => (
                <div className={styles.card} key={data.no}>
                  <div className={styles.lategroup}>
                    <p className={styles.title}>ทำงานรวมทั้งหมด</p>
                    <div className={styles.lateText}>
                      <span className={styles.numtext}>
                        <span className={styles.text}>{data.hour}</span>
                      </span>
                    </div>
                    <p className={styles.subtext}>ชั่วโมง</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
