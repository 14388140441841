import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
// import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Checkbox } from '@mui/material'
import styles from '../Menu_Employee/index.module.scss'
import { useNavigate } from 'react-router-dom'
//svg
import editIcon from '../../image/edit.svg'
import deleteIcon from '../../image/del.svg'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'

interface Props {
  tableList: any
}
export const DepartmentTable: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Team = [
    {
      no: 1,
      id: '001',
      name: 'Thanyarat',
      position: 'หัวหน้า',
      depart: 'HR',
      status: 'Active',
    },
    {
      no: 2,
      id: '002',
      name: 'Beam',
      position: 'สมาชิก',
      depart: 'HR',
      status: 'Inactive',
    },
  ]
  const [users, setUsers] = React.useState([] as any)
  const [dataTable, setDataTable] = React.useState([] as any) //todo Check query > gen arr
  React.useEffect(() => {
    console.log('tableList >', props.tableList.items)
    setUsers(Team)
  }, [])
  //checkbox
  const handleChange = (e: any) => {
    const { name, checked } = e.target
    if (name === 'allSelect') {
      const tempUser = users.map((user: any) => {
        return { ...user, isChecked: checked }
      })
      setUsers(tempUser)
    } else {
      const tempUser = users.map((user: any) => (user.name === name ? { ...user, isChecked: checked } : user))
      setUsers(tempUser)
    }
  }

  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Checkbox
                name="allSelect"
                checked={!users.some((user: any) => user?.isChecked !== true)}
                onChange={handleChange}
                sx={{
                  color: '#5357BB',
                  '&.Mui-checked': {
                    color: '#5357BB',
                  },
                }}
              />
            </TableCell>
            <TableCell align="center">
              <h3>ID</h3>
            </TableCell>
            <TableCell align="center">
              <h3>Name</h3>
            </TableCell>
            <TableCell align="center">
              <h3>ระดับ</h3>
            </TableCell>
            <TableCell align="center">
              <h3>สมาชิก</h3>
            </TableCell>
            <TableCell align="left">
              <h3>Status</h3>
            </TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: any, index: any) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': {} }}>
              <TableCell align="center">
                <Checkbox
                  name={user.name}
                  checked={user?.isChecked || false}
                  onChange={handleChange}
                  sx={{
                    color: '#5357BB',
                    '&.Mui-checked': {
                      color: '#5357BB',
                    },
                  }}
                />
              </TableCell>
              <TableCell align="center">{user.id}</TableCell>
              <TableCell align="center">{user.name}</TableCell>
              <TableCell align="center">{user.position}</TableCell>
              <TableCell align="center">{user.depart}</TableCell>
              <TableCell align="center">
                <div className={styles.status}>
                  <div className={user.status == 'Active' ? styles.statusActive : styles.statusinActive}> </div>
                  <p className={styles.text}>{user.status}</p>
                </div>
              </TableCell>
              <TableCell align="left">
                <div className={styles.action}>
                  <button type="button" className={styles.btEdit}>
                    <img src={editIcon} alt="" onClick={() => navigate('../team/edit/:id')} />
                  </button>
                  <button type="button" className={styles.btDelete}>
                    <img
                      src={deleteIcon}
                      alt=""
                      onClick={() =>
                        dispatch(
                          allAction.modalConfirmAction.setOpenModalConfirm(
                            'ลบข้อมูลพนักงาน',
                            'คุณต้องการลบข้อมูลพนักงาน',
                            'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                            'ใช่,ลบข้อมูล',
                            () => {
                              dispatch(allAction.modalAction.setOpenModalAlertStatus('ลบข้อมูลสำเร็จ', '', 'success'))
                              dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                            },
                          ),
                        )
                      }
                    />
                  </button>
                  <div> </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
