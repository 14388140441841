import React, { FC, useEffect, useRef, useState } from 'react'
import { NavSideBarComponent, NavTopBarComponent } from '../../component'
import BigCalendar, { CalendarRef } from './bigCalendar'
import * as Icons from '@mui/icons-material'
import styles from './index.module.scss'
import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material'
import styled from '@emotion/styled'
import {
  calendarTypeColor,
  CALENDAR_EVENT_TYPE_THAI,
  ECalendarEventType,
  ECalendarType,
  getThaiDate,
} from '../../utils/common'
import { useQueryShareCalendar } from '../../adapter/xhr'

const Card = styled.div<{ type: ECalendarType; padding?: any; minWidth?: any }>(({ type, padding, minWidth }) => ({
  border: `1px solid ${calendarTypeColor[type].main}`,
  backgroundColor: calendarTypeColor[type].light,
  padding,
  borderRadius: 4,
  minWidth,
}))

const CalendarDetailPage: FC = (): JSX.Element => {
  const [calendarDate, setCalendarDate] = useState<Date>(new Date())
  const [filterCalendar, setFilterCalendar] = useState({
    isAll: true,
    isAbsent: true,
    isLate: true,
    isLeave: true,
    isOvertime: true,
  })
  const { data, loading, refetch } = useQueryShareCalendar(null, true)

  const calendarRef = useRef<CalendarRef | null>(null)

  useEffect(() => {
    refetch({
      input: {
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth() + 1,
        filter: filterCalendar,
      },
    })
  }, [calendarDate, filterCalendar])

  const calendarEvents = React.useMemo<any[]>(() => {
    if (loading || !data) return []

    const _events: any[] = []

    data.items.forEach((item: any) => {
      if (!item.eventTypeUnique?.length && item.isHoliday) {
        _events.push({
          date: item.date,
          isHoliday: item.isHoliday,
          holidayDescription: item.holidayDescription,
        })
        return
      }
      item.eventTypeUnique.forEach((e: ECalendarEventType) => {
        _events.push({
          title: CALENDAR_EVENT_TYPE_THAI[e],
          date: item.date,
          start: new Date(item.date),
          end: new Date(item.date),
          isHoliday: item.isHoliday,
          holidayDescription: item.holidayDescription,
          type: e,
        })
      })
    })
    return _events
  }, [data, loading])

  const calendarEventData = React.useMemo<any[]>(() => {
    if (loading || !data) return []

    return data.items
  }, [data, loading])

  const calendarEventTotalMinute = React.useMemo<{
    absentMinutes: number
    lateMinutes: number
    leaveMinutes: number
    overtimeMinutes: number
  }>(() => {
    if (!calendarEventData) return {}

    const sum = calendarEventData.reduce(
      (acc: any, item: any) => {
        item.events.forEach((e: any) => {
          if (e.Type === 'ABSENT') {
            acc.absentMinutes += e.event.leaveMinute
          } else if (e.Type === 'LEAVE') {
            acc.leaveMinutes += 1
          } else if (e.Type === 'LATE') {
            acc.lateMinutes += 1
          } else if (e.Type === 'OVERTIME') {
            acc.overtimeMinutes += 1
          }
        })

        return acc
      },
      {
        absentMinutes: 0,
        lateMinutes: 0,
        leaveMinutes: 0,
        overtimeMinutes: 0,
      },
    )

    return sum
  }, [calendarEventData])

  const sumEmployee = (type: string) => {
    const arr: any[] = []
    const sum = calendarEventData.map((item: any) => {
      item.events.forEach((e: any) => {
        if (e.Type === type) {
          return arr.push(e)
        }
      })
    })
    return arr.length
  }
  const onFilterChange = (key: keyof typeof filterCalendar) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterCalendar((prev) => ({ ...prev, [key]: e.target.checked }))
    }
  }

  const onNextMonth = () => {
    const newDate = calendarRef.current!.handleNextMonth()
    setCalendarDate(newDate)
  }

  const onPreviousMonth = () => {
    const newDate = calendarRef.current!.handlePreviousMonth()
    setCalendarDate(newDate)
  }

  const onToday = () => {
    const newDate = calendarRef.current!.handleToday()
    setCalendarDate(newDate)
  }

  const toHour = (minute: number) => {
    return Math.floor(minute / 60)
  }

  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>
      <div className="main">
        <div className={styles.toolsbar}>
          <Stack direction="row" spacing={2}>
            <h4 className={styles.calendarTitle}>{getThaiDate(calendarDate).format('day date month year')}</h4>
            <div className={styles.navigateBar}>
              <button onClick={onPreviousMonth} className={styles.navigate}>
                <Icons.ArrowBackIosNew fontSize="small" />
              </button>
              <button onClick={onToday} className={styles.navigateToday}>
                วันนี้
              </button>
              <button onClick={onNextMonth} className={styles.navigate}>
                <Icons.ArrowForwardIos fontSize="small" />
              </button>
            </div>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              sx={{ minWidth: 0, width: 40, backgroundColor: '#fff', ':hover': { backgroundColor: '#fff' } }}
            >
              <Icons.MoreVert fontSize="small" style={{ color: '#000' }} />
            </Button>
            <Button variant="contained">ดาวน์โหลดไฟล์</Button>
          </Stack>
        </div>

        <div className={styles.calendarContainer}>
          <BigCalendar events={calendarEvents} eventData={calendarEventData} ref={calendarRef} />
        </div>

        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" padding="1rem">
          <Stack direction="row" spacing={2} alignItems="center">
            <p>เลือกแสดง:</p>
            <Card type="ABSENT" padding="0 6px">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ width: '40px', padding: 0, color: calendarTypeColor['ABSENT'].main }}
                    checked={filterCalendar.isAbsent}
                    onChange={onFilterChange('isAbsent')}
                  />
                }
                label={<p>ขาด</p>}
              />
            </Card>
            <Card type="LEAVE" padding="0 6px">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ width: '40px', padding: 0, color: calendarTypeColor['LEAVE'].main }}
                    checked={filterCalendar.isLeave}
                    onChange={onFilterChange('isLeave')}
                  />
                }
                label={<p>ลา</p>}
              />
            </Card>
            <Card type="LATE" padding="0 6px">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ width: '40px', padding: 0, color: calendarTypeColor['LATE'].main }}
                    checked={filterCalendar.isLate}
                    onChange={onFilterChange('isLate')}
                  />
                }
                label={<p>มาสาย</p>}
              />
            </Card>
            <Card type="OVERTIME" padding="0 6px">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ width: '40px', padding: 0, color: calendarTypeColor['OVERTIME'].main }}
                    checked={filterCalendar.isOvertime}
                    onChange={onFilterChange('isOvertime')}
                  />
                }
                label={<p>ล่วงเวลา</p>}
              />
            </Card>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Card type="ABSENT" padding="0 6px" minWidth={125}>
              <p>{sumEmployee('ABSENT')}</p>
              <p>ขาด (คน)</p>
            </Card>
            <Card type="LEAVE" padding="0 6px" minWidth={125}>
              <p>{sumEmployee('LEAVE')}</p>
              <p>ลา (คน)</p>
            </Card>
            <Card type="LATE" padding="0 6px" minWidth={125}>
              <p>{sumEmployee('LATE')}</p>
              <p>มาสาย (คน)</p>
            </Card>
            <Card type="OVERTIME" padding="0 6px" minWidth={125}>
              <p>{sumEmployee('OVERTIME')}</p>
              <p>ล่วงเวลา (คน)</p>
            </Card>
          </Stack>
        </Stack>
      </div>
    </div>
  )
}
export default CalendarDetailPage
