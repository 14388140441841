export const albumBucketName = 'linklook-bucket'
export const bucketRegion = 'ap-southeast-1'
export const IdentityPoolId = 'ap-southeast-1:275352561933'

export const USER_POOL_ID = 'ap-southeast-1_AME2acwiq'
export const USER_POOL_REGION = 'ap-southeast-1'
export const AWS_ACCESS_KEY_ID = 'AKIAUAHCKVEGYTY3QNU7'
export const AWS_SECRET_KEY = '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'

// THIS IS NOT RECOMMENDED?!??
// AWS.config.update({ region: USER_POOL_REGION, accessKeyId: AWS_ACCESS_KEY_ID, secretAccessKey: AWS_SECRET_KEY })
