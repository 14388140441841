import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './index.module.scss'
import DashbroadSVG from './images/dashboard.svg'
import EmployeeSVG from './images/employee.svg'
// import AttendanceReportSVG from './images/attendanceReport.svg'
import PayrollSVG from './images/payroll.svg'
// import ApprovalSVG from './images/approval.svg'
// import MasterDataSVG from './images/masterData.svg'
// import IntragationSVG from './images/intregation.svg'
// import PackageSVG from './images/package.svg'
import ArrowSVG from './images/arrow.svg'
import DownArrowSVG from './images/downArrow.svg'
// import SubmenuSVG from './images/submenu.svg'
import Calendar from './images/calendarSvg.svg'
import CalendarWhite from './whiteImages/calendar.svg'
import DashboradWhite from './whiteImages/dashborad.svg'
// import BlueCircleSVG from './blueImages/blueCircle.svg'
import EmployeeWhite from './whiteImages/employee.svg'
import PayrollWhite from './whiteImages/payroll.svg'
import DocumentSVG from './images/document.svg'
import DocumentWhite from './whiteImages/document.svg'
import ReportSVG from './images/Report.svg'
import ReportWhite from './whiteImages/report.svg'
import HelperSVG from './images/helpIcon.svg'
import HelpWhite from './whiteImages/help.svg'
import SettingSVG from './images/setting.svg'
import SettingWhite from './whiteImages/setting.svg'
import ApproveSVG from './images/approve.svg'
import ArroveWhite from './whiteImages/approve.svg'

const NavSideBarComponent: React.FC = (): JSX.Element => {
  const [arrSplitPathname, setArrSplitPathname] = React.useState<string[]>([])
  const navigate = useNavigate()
  const location = useLocation()
  React.useEffect(() => {
    setArrSplitPathname(location.pathname.split('/'))
  }, [])

  // const CheckStyles = () => {}

  return (
    <section className={styles.wrapper}>
      {arrSplitPathname[1] === 'masterdata' ? (
        <div className={styles.aside}>
          <div className={styles.headCard}>
            <img
              src={ArrowSVG}
              alt=""
              className={styles.icon}
              onClick={() => {
                navigate('../dashboard')
              }}
            />
            <p>Setting</p>
          </div>
          <div className={styles.CardButton}>
            {menuSetting.map((data, index) => {
              console.log(menuSetting.length - 1, 'length')
              return (
                <>
                  <div className={styles.groupNavSettingItems} key={index}>
                    <button
                      type="button"
                      onClick={() => {
                        if (data.link === '') {
                          navigate('/pagenotFound')
                        } else {
                          navigate(data.link)
                        }
                      }}
                      className={
                        arrSplitPathname[2] === data.link.split('/')[2] && index === 0
                          ? styles.button
                          : arrSplitPathname[2] === data.link.split('/')[2] && index === menuSetting.length - 1
                          ? styles.buttonBottom
                          : index === 0
                          ? styles.buttonWhite
                          : index === menuSetting.length - 1
                          ? styles.buttonWhitebottom
                          : arrSplitPathname[2] === data.link.split('/')[2]
                          ? styles.buttonBlue
                          : styles.buttonW
                      }
                    >
                      <p> {data.name}</p>
                      <img src={ArrowSVG} alt="" />
                    </button>
                    {/* <div className={styles.line} /> */}
                  </div>
                </>
              )
            })}
          </div>
          <div className={styles.CardButton}>
            {menuSettingfinance.map((data, index) => {
              return (
                <>
                  <div className={styles.groupNavSettingItems} key={index}>
                    <button
                      type="button"
                      onClick={() => {
                        if (data.link === '') {
                          console.log('VL')

                          navigate('/pagenotFound')
                        } else {
                          navigate(data.link)
                        }
                      }}
                      className={
                        arrSplitPathname[2] === data.link.split('/')[2] && index === 0
                          ? styles.button
                          : arrSplitPathname[2] === data.link.split('/')[2] && index === menuSettingfinance.length - 1
                          ? styles.buttonBottom
                          : index === 0
                          ? styles.buttonWhite
                          : index === menuSettingfinance.length - 1
                          ? styles.buttonWhitebottom
                          : arrSplitPathname[2] === data.link.split('/')[2]
                          ? styles.buttonBlue
                          : styles.buttonW
                      }
                    >
                      <p> {data.name}</p>
                      <img src={ArrowSVG} alt="" />
                    </button>
                    {/* <div className={styles.line} /> */}
                  </div>
                </>
              )
            })}
          </div>
          <div className={styles.CardButton}>
            {menuSettingCompany.map((data, index) => {
              return (
                <>
                  <div className={styles.groupNavSettingItems} key={index}>
                    <button
                      type="button"
                      onClick={() => {
                        if (data.link === '') {
                          console.log('VL')

                          navigate('/pagenotFound')
                        } else {
                          navigate(data.link)
                        }
                      }}
                      className={
                        arrSplitPathname[2] === data.link.split('/')[2] && index === 0
                          ? styles.button
                          : arrSplitPathname[2] === data.link.split('/')[2] && index === menuSettingCompany.length - 1
                          ? styles.buttonBottom
                          : index === 0
                          ? styles.buttonWhite
                          : index === menuSettingCompany.length - 1
                          ? styles.buttonWhitebottom
                          : arrSplitPathname[2] === data.link.split('/')[2]
                          ? styles.buttonBlue
                          : styles.buttonW
                      }
                    >
                      <p> {data.name}</p>
                      <img src={ArrowSVG} alt="" />
                    </button>
                    {/* <div className={styles.line} /> */}
                  </div>
                </>
              )
            })}
          </div>
          <div className={styles.CardButton}>
            {menuSettingIntegration.map((data, index) => {
              return (
                <>
                  <div className={styles.groupNavSettingItems} key={index}>
                    <button
                      type="button"
                      onClick={() => {
                        if (data.link === '') {
                          console.log('VL')

                          navigate('/pagenotFound')
                        } else {
                          navigate(data.link)
                        }
                      }}
                      className={
                        arrSplitPathname[2] === data.link.split('/')[2] && index === 0
                          ? styles.button
                          : arrSplitPathname[2] === data.link.split('/')[2] &&
                            index === menuSettingIntegration.length - 1
                          ? styles.buttonBottom
                          : index === 0
                          ? styles.buttonWhite
                          : index === menuSettingIntegration.length - 1
                          ? styles.buttonWhitebottom
                          : arrSplitPathname[2] === data.link.split('/')[2]
                          ? styles.buttonBlue
                          : styles.buttonW
                      }
                    >
                      <p> {data.name}</p>
                      <img src={ArrowSVG} alt="" />
                    </button>
                    {/* <div className={styles.line} /> */}
                  </div>
                </>
              )
            })}
          </div>
        </div>
      ) : (
        <div className={styles.GroupMenu}>
          {menuList.map((data, index) => (
            <div className={styles.groupNavItems} key={index}>
              <button
                type="button"
                onClick={() => {
                  if (data.link === '') {
                    navigate('/pagenotFound')
                  } else {
                    navigate(data.link)
                  }
                }}
                className={styles[`menu-${arrSplitPathname[1] === data.link.split('/')[1] ? true : false}`]}
              >
                <div className={styles.groubText}>
                  <img src={arrSplitPathname[1] === data.link.split('/')[1] ? data.imgHover : data.img} />
                  {data.name}
                </div>

                {data.submenu && arrSplitPathname[1] === data.link.split('/')[1] ? (
                  <img src={DownArrowSVG} />
                ) : data.submenu && !(arrSplitPathname[1] === data.link.split('/')[1]) ? (
                  <img src={ArrowSVG} />
                ) : (
                  ''
                )}
              </button>
              {data.submenu && arrSplitPathname[1] === data.link.split('/')[1] && (
                <div className={styles.submenuContainer}>
                  {data.submenu.map((submenuElement: any, submenuIndex: number) => {
                    return (
                      <button
                        type="button"
                        onClick={() => navigate(submenuElement.link)}
                        className={
                          arrSplitPathname[2] === submenuElement.link.split('/')[2]
                            ? styles.blueSubmenu
                            : styles.whiteSubmenu
                        }
                        key={submenuIndex}
                      >
                        {submenuElement.name}
                      </button>
                    )
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* <div className={styles.line}></div> */}

      <section className={styles.asideFooter}>
        <div className={styles.GroupLink}>
          <p>เกี่ยวกับ</p>
          <p>ติดต่อเรา</p>
          <p>คุกกี้</p>
          <p>บทความ</p>
          <p>นโยบายความเป็นส่วนตัว</p>
        </div>

        <p className={styles.textsub}>© 2022 Datability.info</p>
      </section>
    </section>
  )
}
export default NavSideBarComponent

//ListMenuSetting
const menuSetting = [
  {
    name: 'Holiday Policy',
    link: '../masterdata/holidayPolicy/',
  },
  {
    name: 'OT Policy',
    link: '../masterdata/overtimepolicy',
  },
  {
    name: 'Leave Policy',
    link: '../masterdata/leavepolicy',
  },
  {
    name: 'Shift Policy',
    link: '../masterdata/attendance',
  },
]

const menuSettingfinance = [
  {
    name: 'Welfare Policy',
    link: '',
  },
  {
    name: 'Calculation Policy',
    link: '',
  },
  {
    name: 'Income Expense Policy',
    link: '',
  },
  {
    name: 'Social Security Policy',
    link: '',
  },
  {
    name: 'Diligent Policy',
    link: '',
  },
  {
    name: 'Provident Fund Policy',
    link: '',
  },
  {
    name: 'Tax Policy',
    link: '',
  },
  {
    name: 'Income Calculation Policy',
    link: '',
  },
]

const menuSettingCompany = [
  {
    name: 'Approval Policy',
    link: '',
  },
  {
    name: 'Retirement Policy',
    link: '',
  },
  {
    name: 'Punishment Policy',
    link: '',
  },
  {
    name: 'Social Security Policy',
    link: '',
  },
  {
    name: 'Company Detail',
    link: '',
  },
  {
    name: 'Organization Chart',
    link: '',
  },
  {
    name: 'Location',
    link: '',
  },
  {
    name: 'Role Management',
    link: '',
  },
]

const menuSettingIntegration = [
  {
    name: 'Integration',
    link: '',
  },
]

const menuList = [
  {
    img: DashbroadSVG,
    imgHover: DashboradWhite,
    name: 'Dashboard',
    link: `../dashboard`,
  },
  {
    img: Calendar,
    imgHover: CalendarWhite,
    name: 'Calendar',
    link: `../calendar/detail`,
  },
  {
    img: ApproveSVG,
    imgHover: ArroveWhite,
    name: 'Approve',
    link: `../approvalsystem`,
  },
  {
    img: EmployeeSVG,
    imgHover: EmployeeWhite,
    name: 'Employee Management',
    link: '../employee/employee',
    submenu: [
      { name: 'Employee Profile', link: '../employee/employee' },
      { name: 'Position', link: '../employee/position' },
      { name: 'Department', link: '../employee/department' },
      { name: 'Team', link: '../employee/team' },
      { name: 'Package Group', link: '../employee/creatPackage' },
    ],
  },

  {
    img: PayrollSVG,
    imgHover: PayrollWhite,
    name: 'Payroll Management',
    // link: `/wage/payroll`,
    link: '../wage/attendanceLog',
    submenu: [
      { name: 'Attendance Log', link: '../wage/attendanceLog' },
      { name: 'Attendance Report', link: '../wage/attendanceReport' },
      { name: 'Payroll', link: '../wage/payroll' },
    ],
  },

  {
    img: DocumentSVG,
    imgHover: DocumentWhite,
    name: 'Document Management',
    // link: `/wage/payroll`,
    link: '../document/LeaveDocument',
    submenu: [
      { name: 'Leave', link: '../document/LeaveDocument' },
      { name: 'OT', link: '../document/OvertimeDocument' },
      // { name: 'Payroll', link: '/wage/payroll' },
    ],
  },
  {
    img: ReportSVG,
    imgHover: ReportWhite,
    name: 'Report',
    // link: `/wage/payroll`,
    link: '..',
  },
  {
    img: SettingSVG,
    imgHover: SettingWhite,
    name: 'Setting',
    link: '../masterdata/holidayPolicy/',
  },
  {
    img: HelperSVG,
    imgHover: HelpWhite,
    name: 'Help',
    link: '..',
  },

  // {
  //   img: ApprovalSVG,
  //   name: 'การอนุมัติรายการ',
  //   link: `/approvalsystem`,
  // },
  // {
  //   img: ReportSVG,
  //   name: 'Report',
  //   link: `/report`,
  // },
  // {
  //   img: MasterDataSVG,
  //   name: 'Master Data',
  //   link: `/masterdata/attendance`,
  //   submenu: [
  //     { name: 'ระเบียบการเข้างาน', link: '/masterdata/attendance' },
  //     { name: 'ระเบียบการลา', link: '/masterdata/leavepolicy' },
  //     { name: 'ระเบียบสวัสดิการ/ค่าใช้จ่าย', link: '/masterdata/benefitpolicy' },
  //     { name: 'ระเบียบปฏิทิน', link: '/masterdata/holidayPolicy' },
  //     { name: 'ระเบียบการทำงานล่วงเวลา', link: '/masterdata/overtimepolicy' },
  //   ],
  // },
  // {
  //   img: IntragationSVG,
  //   name: 'Intregation',
  //   link: `/intregation`,
  // },
  // {
  //   img: PackageSVG,
  //   name: 'แพคเกจ',
  //   link: `/package`,
  // },
]
