import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
  NavTopBarComponent,
  NavSideBarComponent,
  ButtonComponent,
  InputTextComponent,
  ButtonSelect,
  InputSelectComponent,
  InputDateComponent,
  Accordion,
} from '../../component'
import { Checkbox } from '@mui/material'
import { queryListPayment } from '../../adapter/xhr'
import { ModalPayment } from './modal'
import moment from 'moment'

const PayMent: React.FC = (): JSX.Element => {
  const [modalpay, setModalpay] = useState(false)
  // const [open, setOpen] = useState(false)
  const [payment, setPayment] = useState([] as any)

  const navigate = useNavigate()
  useEffect(() => {
    queryListPayment().then((res) => {
      setPayment(res.data.data.listPayment.items)
      console.log('list Payment', payment)
    })
  }, [])

  const Newdata: any = []
  payment.map((item: any) => {
    return Newdata.push({
      head: ['No', 'Bank Account No.', 'Recipient Name', 'Amount'],
      leftTopics: [item.paymentName, moment(item.date).format('L')],
      rightTopics: [
        item.employee
          .map((num: any) => parseInt(num.bankPaymentAmount))
          .reduce((prev: string, curr: string) => prev + curr, 0),
        item.status,
      ],
      topics: [
        moment(item.date).format('L'),
        item.paymentName,
        item.employee
          .map((num: any) => parseInt(num.bankPaymentAmount))
          .reduce((prev: string, curr: string) => prev + curr, 0),
        item.status,
      ],
      content: [
        item.employee.map((emp: any) => [
          emp.bankAccountNumber,
          emp.fullName + '  ' + emp.lastName,
          emp.bankPaymentAmount + 'THB',
        ]),
      ],
    })
  })

  const CardData = [
    {
      title: 'รายการ รอจ่ายเงิน',
      sum: payment.map((num: any) =>
        num.employee.map((item: any) => parseInt(item.bankPaymentAmount)).reduce((a: any, b: any) => a + b, 0),
      ),
      // .reduce((prevRowSumNumber: number, currRowSumNumber: number) => prevRowSumNumber + currRowSumNumber),
      sub: 'คน',
    },
    {
      title: 'รวมยอดจ่ายเงินทั้งหมด',
      sum: payment.map((num: any) =>
        num.employee.map((item: any) => parseInt(item.bankPaymentAmount)).reduce((a: any, b: any) => a + b, 0),
      ),
      // .reduce((prevRowSumNumber: number, currRowSumNumber: number) => prevRowSumNumber + currRowSumNumber),
      sub: 'บาท',
    },
  ]

  // console.log(
  //   payment
  //     .map((num: any) =>
  //       num.employee.map((item: any) => parseInt(item.bankPaymentAmount)).reduce((a: any, b: any) => a + b),
  //     )
  //     .reduce((prevRowSumNumber: number, currRowSumNumber: number) => prevRowSumNumber + currRowSumNumber),
  //   'hello',
  // )
  console.log('Show', Newdata)

  const validationSchema = yup.object({})
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            <h1 className={styles.title}>การจ่ายเงิน</h1>
          </div>
          <div className={styles.cardSearch}>
            <Formik
              initialValues={{
                username: '',
                name: '',
                status: '',
                startdate: '',
                enddate: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2))
                  actions.setSubmitting(false)
                }, 1000)
              }}
            >
              {(props: any) => (
                <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                  <div className={styles.InputArea}>
                    <div className={styles.inputBox}>
                      <InputTextComponent _name="username" _label="รหัสพนักงาน" _helperText={props.errors.username} />
                    </div>
                    <div className={styles.inputBox}>
                      <InputTextComponent _name="name" _label="ชื่อ - นามสกุล" _helperText={props.errors.username} />
                    </div>
                    <div className={styles.inputBox}>
                      <InputSelectComponent
                        _name="status"
                        _label="สถานะ"
                        _helperText={props.errors.selected}
                        _menuList={[
                          { label: 'Draft', value: 'draft' },
                          { label: 'Success', value: 'success' },
                        ]}
                      />
                    </div>
                  </div>
                  <div className={styles.InputArea}>
                    <div className={styles.inputBox}>
                      <InputDateComponent _name="startdate" _label="วันที่เริ่ม" _helperText={props.errors.hiredate} />
                    </div>

                    <span className={styles.text}>-</span>
                    <div className={styles.inputBox}>
                      <InputDateComponent _name="enddate" _label="วันที่สิ้นสุด" _helperText={props.errors.enddate} />
                    </div>
                  </div>

                  <div className={styles.btSearch}>
                    {' '}
                    <ButtonComponent
                      _variant="contained"
                      _text="ค้นหา"
                      _colorText="white"
                      _colorBG="blue"
                      _type="button"
                      _functionOnClick={() => navigate('../menuemployee')}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className={styles.cardPayroll}>
            <div className={styles.content}>
              {CardData.map((data: any, index: number) => (
                <div className={styles.card} key={index}>
                  <p className={styles.title}>{data.title}</p>
                  <p className={data.title === 'Pay' ? styles.titlePay : styles.titlePending}>{data.sum}</p>
                  <p className={styles.subtitle}>{data.sub}</p>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.cardTable}>
            <div className={styles.head}>
              <h1 className={styles.title}>รายการคำนวณเงินเดือน</h1>
              <div className={styles.buttonBox}>
                <div className={styles.btImport}>
                  {' '}
                  <ButtonComponent
                    _variant="contained"
                    _text="Import"
                    _colorText="white"
                    _colorBG="secondary"
                    _type="button"
                    _functionOnClick={() => navigate('ot/create')}
                  />
                </div>

                <div className={styles.btExport}>
                  {''}
                  <ButtonSelect
                    _text="Export"
                    _colorBG="secondary"
                    _colorText="white"
                    _variant="outlined"
                    _selectData={[]}
                  />
                </div>

                <div className={styles.btAdd}>
                  {' '}
                  <ButtonComponent
                    _isIcon="add"
                    _variant="contained"
                    _text="สร้างรายการจ่ายเงิน"
                    _colorText="white"
                    _colorBG="blue"
                    _type="button"
                    _functionOnClick={() => navigate('../wage/payroll/add')}
                  />
                </div>
              </div>
            </div>
            <div className={styles.table}>
              <div className={styles.header}>
                <div className={styles.texthead}>
                  <Checkbox
                    name="allSelect"
                    // checked={!data.some((user: any) => user?.isChecked !== true)}
                    // onChange={handleChange}
                    sx={{
                      color: '#5357BB',
                      '&.Mui-checked': {
                        color: '#5357BB',
                      },
                    }}
                  />
                </div>
                <div className={styles.headerTopicList}>
                  <section className={styles.leftTopicList}>
                    <h4 className={styles.texthead}>ID</h4>
                    {/* <h4 className={styles.texthead}>รายการ</h4> */}
                  </section>
                  <section></section>
                  <section className={styles.rightTopicList}>
                    <h4 className={styles.texthead}>จำนวนเงินทั้งหมด</h4>
                    <h4 className={styles.texthead}>สถานะ</h4>
                  </section>
                </div>
              </div>
              <div className={styles.listAccor}>
                {''}
                {console.log('res >>>>>', payment)} {console.log('Newdata', Newdata)}
                {Newdata.map((e: any, index: number) => (
                  <Accordion
                    key={index}
                    _leftMenuList={e.leftTopics}
                    _rightMenuList={e.rightTopics}
                    _menuList={e.topics}
                    _content={e.content}
                    _head={e.head}
                  />
                ))}
              </div>
            </div>

            <div className={styles.sumBox}>
              <p className={styles.textsum}>ยอดรวมการจ่ายเงิน ธันวาคม : 200,000 THB จำนวนการจ่ายเงิน : 2 รายการ</p>
            </div>
          </div>

          <div className={styles.buttonPay}>
            <ButtonComponent
              _colorBG="green"
              _text="Pay"
              _colorText="white"
              _type="button"
              _variant="contained"
              _functionOnClick={() => setModalpay(!modalpay)}
            />
          </div>
        </div>
      </div>

      <div className="footer">Footer</div>

      <ModalPayment open={modalpay} setOpen={setModalpay} />
    </div>
  )
}

export default PayMent
