import React, { useState } from 'react'
import styles from './index.module.scss'
import { Checkbox } from '@mui/material'
import { EApprove } from '../DetailPage'

interface Props {
  topic: string
  subtopic: string
  description?: string
  name: string
  position: string
  date: any
  Check?: boolean
  isCheckChange?: any
  functionOnClick?: any
  lastName?: string
  departmentName?: string
  minuteHour?: any
  id?: any
  _values?: any
  _name?: any
  _checked?: any
  _onChange?: any
  _key?: any
}

const ListCard: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <div className={props._checked === true ? styles.containerClick : styles.container}>
        <section className={styles.textConteiner} onClick={() => props.functionOnClick()}>
          <div className={styles.columnWrapper}>
            <section className={styles.HeadColumn}>
              <article className={styles.textRowWrapper}>
                <Checkbox
                  sx={{
                    color: '#3A77DA',
                    '&.Mui-checked': {
                      color: '#3A77DA',
                    },
                  }}
                  checked={props._checked}
                  onChange={props._onChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="secondary"
                  key={props._key}
                  // value={props._name}
                />
                <p className={styles.textTopic}>{EApprove[`${props.topic}`]}</p>
              </article>
              <p className={styles.textSubtopic}>{props.subtopic}</p>
            </section>

            <section className={styles.GroupColumn}>
              <div className={styles.textLeft}>
                <p className={styles.textBold}>
                  {props.name} {props.lastName}
                </p>
                <p className={styles.text}>{props.departmentName}</p>
              </div>
              <div className={styles.textRigth}>
                {props.minuteHour ? <p className={styles.text}>{props.minuteHour} ชั่วโมง</p> : ''}
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  )
}

export default ListCard
