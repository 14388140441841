import * as React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { TableData, TableUser } from './table'
export const TabTeam: React.FC<any> = (props: any): JSX.Element => {
  const [value, setValue] = React.useState('1')
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="รายชื่อผู้ใช้งาน" value="1" />
            <Tab label="รายชื่อทีมงาน" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <TableUser />
        </TabPanel>
        <TabPanel value="2">
          <TableData />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
