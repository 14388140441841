import React from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop } from '@mui/material'
import allAction from '../../../state/actions'
import { useDispatch } from 'react-redux'

//icon
import IconClose from '../../../image/close.svg'
import IconPhone from '../../../image/phone.svg'
import IconEmail from '../../../image/email.svg'

//Components
import { ButtonComponent } from '../../../component'

interface Props {
  openModalForgetPassword: boolean
  setOpenModalForgetPassword: any
  topic?: string
}
const ModalForgetPassword: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <>
      <Modal
        open={props.openModalForgetPassword}
        onClose={() => props.setOpenModalForgetPassword(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={styles.container}>
          <nav className={styles.navTop}>
            <p className={styles.textTopic}>{props.topic}</p>
            <button type="button" className={styles.btnClose} onClick={() => props.setOpenModalForgetPassword(false)}>
              <img className={styles.iconClose} src={IconClose} alt="close" />
            </button>
          </nav>
          <div className={styles.contentContainer}>
            <article>
              <p className={styles.textContent}>เลือกช่องทางยืนยันตัวตนที่คุณต้องการใช้ในการ</p>
              <p className={styles.textContent}> เปลี่ยนรหัสผ่าน</p>
            </article>

            <button
              type="button"
              className={styles.cardContract}
              onClick={() => {
                props.setOpenModalForgetPassword(false)
                dispatch(
                  allAction.modalAction.setOpenModalAlertStatus(
                    'ทำการกู้คืนรหัสผ่านให้ท่านแล้ว',
                    'โปรดตรวจสอบ {อีเมล/หมายเลขโทรศัพท์}',
                    'success',
                  ),
                )
                dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
              }}
            >
              <img src={IconEmail} alt="IconEmail" />
              <p>อีเมล (th********@hotmail.com)</p>
            </button>
            <button
              type="button"
              className={styles.cardContract}
              onClick={() => {
                props.setOpenModalForgetPassword(false)
                dispatch(
                  allAction.modalAction.setOpenModalAlertStatus(
                    'ทำการกู้คืนรหัสผ่านให้ท่านแล้ว',
                    'โปรดตรวจสอบ {อีเมล/หมายเลขโทรศัพท์}',
                    'success',
                  ),
                )
                dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
              }}
            >
              <img src={IconPhone} alt="IconEmail" />
              <p>หมายเลขโทรศัพท์ (**********89)</p>
            </button>
          </div>
          <footer className={styles.footer}>
            <div className={styles.btnBox}>
              <ButtonComponent
                _variant="contained"
                _text="ปิด"
                _colorText="white"
                _colorBG="blue"
                _type="button"
                _functionOnClick={() => {
                  props.setOpenModalForgetPassword(false)
                  dispatch(
                    allAction.modalAction.setOpenModalAlertStatus(
                      'ทำการกู้คืนรหัสผ่านให้ท่านแล้ว',
                      'โปรดตรวจสอบ {อีเมล/หมายเลขโทรศัพท์}',
                      'success',
                    ),
                  )
                  dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                }}
              />
            </div>
          </footer>
        </div>
      </Modal>
    </>
  )
}
export default ModalForgetPassword
