import React from 'react'
import styles from './index.module.scss'
// Components
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent, InputTextComponentCustom } from '../../component'

//Formik
import * as yup from 'yup'
import { Formik } from 'formik'
import {
  InputTextComponent,
  InputSelectComponent,
  InputRadioComponent,
  InputCheckboxComponent,
  InputTextUnitComponent,
} from '../../component'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { Checkbox, Divider } from '@mui/material'

const LeavePolicyEditPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()

  const validationSchema = yup.object({
    nameAttendance: yup.string().required('Name attendance is required'),
    leaveDay: yup.string().required('Leave day per year is required'),
    leaveDayUnit: yup.string().required('Unit is required'),
    leaveDayPay: yup.string().required('leave day Pay is required'),
    leaveDayPayUnit: yup.string().required('Unit is required'),
    maxDayPerYear: yup.string().required('Max days rer year is required'),
  })
  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <Formik
          initialValues={{
            nameAttendance: '',
            employee: false,
            daily: false,
            hourly: false,
            outsourceDaily: false,
            outsourceHourly: false,
            outsourceMonthly: false,
            outsourceProject: false,
            quotaLeaveDay: '',
            leaveDay: '',
            leaveDayUnit: 'ปี',
            // leaveDayPay: '',
            // leaveDayPayUnit: '',
            maxDayPerYear: '',
            isCalculateDeduction: false,
            amount: '',
            detail: '',
            typeLeave: 'law',
            condition: [{ sevice: 0, quata: 0, leave: 0 }],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            console.log('Add')
            setTimeout(() => {
              // alert(JSON.stringify(values, null, 2))
              actions.setSubmitting(false)
            }, 1000)
            dispatch(
              allAction.modalConfirmAction.setOpenModalConfirm(
                'ยืนยันการทำรายการ',
                'คุณต้องการยืนยันการทำรายการ',
                'เพิ่มข้อมูลการลา?',
                'ใช่, ทำรายการ',
                () => {
                  dispatch(allAction.modalAction.setOpenModalAlertStatus('เพิ่มรายการเรียบร้อย', '', 'success'))
                  dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                },
              ),
            )
          }}
        >
          {(props: any) => (
            <div className="main">
              <form onSubmit={props.handleSubmit}>
                <div className={styles.container}>
                  <section className={styles.topicPageWrapper}>
                    <p className={styles.topicPage}>เพิ่มระเบียบการลา</p>
                  </section>
                  <div className={styles.cardContainer}>
                    <section className={styles.GroupData}>
                      <div className={styles.GroupFlex}>
                        <div className={styles.GroupInput}>
                          <div className={styles.flexInput}>
                            <p className={styles.title}>ชื่อการลา</p>
                            <InputTextComponentCustom _name="leavename" _label="ชื่อการลา" />
                          </div>
                          <div className={styles.flexInput}>
                            <p className={styles.title}>หน่วยวันที่ลา</p>
                            <InputTextComponentCustom _name="leavename" _label="หน่วยวันที่ลา" />
                          </div>
                        </div>
                        <div className={styles.GroupRadio}>
                          <p className={styles.title}>ประเภทวันลา</p>
                          <p className={styles.description}>
                            แบ่งประเภทวันลาให้เป็นตามกฎหมายแรงงาน หรือตามนโยบายบริษัท
                          </p>
                          <InputRadioComponent
                            _name="typeLeave"
                            _menuList={[
                              {
                                label: 'ประเภทวันลาตามกฎหมายแรงงาน',
                                value: 'law',
                              },
                              {
                                label: 'ประเภทวันลาตามนโยบายบริษัท',
                                value: 'company',
                              },
                            ]}
                          />
                        </div>
                      </div>

                      <Divider />
                      <div className={styles.AreaData}>
                        <div className={styles.Header}>
                          <p className={styles.title}>เพศพนักงานที่ลาได้</p>
                          <p className={styles.textLink}>เลือกทั้งหมด</p>
                        </div>
                        <div className={styles.CheckBoxArea}>
                          {TypeEmployees.map((item: any, index: number) => (
                            <div className={styles.Checkbox} key={index}>
                              <Checkbox
                                sx={{
                                  color: '#3A77DA',
                                  '&.Mui-checked': {
                                    color: '#3A77DA',
                                  },
                                }}
                                checked={props._checked}
                                onChange={props._onChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                color="secondary"
                                // key={index}
                                // value={props._name}
                              />
                              <p>{item.label}</p>
                            </div>
                          ))}
                        </div>

                        {/* <InputCheckboxComponent _name="typeEmployees" /> */}
                      </div>
                      <Divider />

                      <div className={styles.AreaData}>
                        <p className={styles.title}>เงื่อนไข</p>
                        <div className={styles.CheckBoxArea}></div>

                        <section className={styles.flexInput}></section>
                      </div>
                    </section>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default LeavePolicyEditPage

const TypeEmployees = [
  {
    label: 'พนักงานเพศชาย',
    value: 'men',
  },
  {
    label: 'พนักงานเพศหญิง',
    value: 'men',
  },
  {
    label: 'พนักงานรายเดือน',
    value: 'men',
  },
  {
    label: 'พนักงานพาร์ทไทม์',
    value: 'men',
  },
  {
    label: 'พนักงานรายวัน',
    value: 'men',
  },
  {
    label: 'พนักงานจ้างเหมา',
    value: 'men',
  },
]

const dataCheckBox1 = [
  {
    checkboxLabel: 'พนักงานรายเดือน',
    checkboxName: 'employee',
  },
  {
    checkboxLabel: 'รายวัน',
    checkboxName: 'daily',
  },
  {
    checkboxLabel: 'รายชั่วโมง',
    checkboxName: 'hourly',
  },
]
const dataCheckBox2 = [
  {
    checkboxLabel: 'จ้างเหมารายวัน',
    checkboxName: 'outsourceDaily',
  },
  {
    checkboxLabel: 'จ้างเหมารายชั่วโมง',
    checkboxName: 'outsourceHourly',
  },
  {
    checkboxLabel: 'จ้างเหมารายเดือน',
    checkboxName: 'outsourceMonthly',
  },
  {
    checkboxLabel: 'จ้างเหมารายโปรเจ็ค',
    checkboxName: 'outsourceProject',
  },
]
