import React, { useState, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
  NavTopBarComponent,
  NavSideBarComponent,
  ButtonComponent,
  InputTextComponent,
  InputDateComponent,
  PaginationBar,
  FilterReportTable,
} from '../../component'
import { QueryAttendanceReport, ResetAttendanceReport } from '../../adapter/xhr'

import HistoryIcon from '../../image/historyEdit.svg'
import { TableAttendReport } from './TableAttendance'
import { ModalHistory } from './modal'
import { LinearProgress, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { convertDateString } from '../../utils/periodCalCulate'
import { paginationFunc } from '../../utils/common'
import { TimeAttendanceReportGroupByEmployeeQuery } from '../../utils/generated'

const AttendanceReport: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const inputRef = useRef<any>()
  const [startdate, setStartDate] = React.useState<string>()
  const [enddate, setEnddate] = React.useState<string>()
  const [listAttend, setListAttend] = React.useState<TimeAttendanceReportGroupByEmployeeQuery[]>([])
  const [showAttend, setShowAttend] = React.useState<TimeAttendanceReportGroupByEmployeeQuery[]>([])
  const [searchName, setSearchName] = useState('')

  const [nextToken, setNextToken] = useState<string | null>(null)
  const [logTotal, setLogTotal] = useState<number>(0)
  const [pageSite, setPageSite] = React.useState<number>(10)
  const [listItemIndex, setListItemIndex] = React.useState<number>(0)
  const [logProcessed, setLogProcessed] = useState<number>(0)

  const [modalHistory, setModalHistory] = useState(false)

  const myCurrentDate = new Date()
  const myPastDate = new Date(myCurrentDate.getTime())
  myPastDate.setDate(myPastDate.getDate() - 30)

  const colorList: any = [
    {
      color: '#FFD24C',
      name: 'ล่วงเวลา (OT)',
    },
    {
      color: '#FF6363',
      name: 'วันลา',
    },
    {
      color: '#DFDFDE',
      name: 'วันหยุดประจำสัปดาห์',
    },
    {
      color: '#FFA8A8',
      name: 'วันหยุดประจำปี',
    },
    {
      color: '#FFFFFF',
      name: 'ข้อมูลไม่สมบูรณ์',
    },
  ]

  const QueryReport = (startdate: any, enddate: any, filter?: any) => {
    const variables = {
      startDate: startdate,
      endDate: enddate,
      filter,
    }
    QueryAttendanceReport(variables).then((res: any) => {
      const Report = res.data.data.listTimeAttendanceReportGroupByEmployee.items
      if (Report && Report.length > 0) {
        setListAttend(Report)
        setShowAttend(Report)
      }
      setNextToken(Report.nextToken)
    })
  }

  const [resetLoading, setResetLoading] = React.useState<boolean>(false)
  const validationSchema = yup.object({
    startdate: yup.string().required('กรอกวันที่เริ่มต้น'),
    enddate: yup.string().required('กรอกวันที่สิ้นสุด'),
  })

  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            <h1 className={styles.title}>Attendance Report</h1>
          </div>
          <div className={styles.contain}>
            <div className={styles.cardSearch}>
              <Formik
                innerRef={inputRef}
                validationSchema={validationSchema}
                initialValues={{
                  employeeID: '',
                  startdate: convertDateString(myPastDate),
                  enddate: convertDateString(myCurrentDate),
                  employeeName: '',
                  // startdate: '2022-01-01',
                  // enddate: '2022-01-19',
                }}
                onSubmit={(values, actions) => {
                  console.log(values)

                  const FilterEmployeeId = {
                    employeeID: {
                      beginsWith: values.employeeID,
                    },
                  }
                  QueryReport(values.startdate, values.enddate, FilterEmployeeId)
                  setStartDate(values.startdate)
                  setEnddate(values.enddate)
                }}
              >
                {(props: any) => (
                  <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                    <div className={styles.InputArea}>
                      <div className={styles.inputBox}>
                        <InputTextComponent _name="employeeID" _label="รหัสพนักงาน" />
                      </div>
                      <div className={styles.inputBox}>
                        <TextField
                          name="employeeName"
                          label="ชื่อ-นามสกุลพนักงาน"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                            style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
                          }}
                          InputProps={{
                            style: {
                              color: 'gray',
                              borderRadius: 10,
                              backgroundColor: 'white',
                              fontFamily: 'KanitRegular',
                            },
                          }}
                          size="small"
                          placeholder="ค้นหาพนักงาน"
                          onChange={(e: any) => setSearchName(e.target.value)}
                        />
                      </div>
                      {/* <div className={styles.inputBox}>
                        <InputSelectComponent
                          _name="status"
                          _label="ทีมงาน"
                          _menuList={[
                            { label: 'Draft', value: 'draft' },
                            { label: 'Success', value: 'success' },
                          ]}
                        />
                      </div> */}
                    </div>
                    <div className={styles.InputArea}>
                      <div className={styles.inputBox}>
                        <InputDateComponent
                          _name="startdate"
                          _label="วันที่เริ่ม"
                          _helperText={props.errors.startdate}
                          // _onChange={() => {}}
                        />
                      </div>

                      <span className={styles.text}>-</span>
                      <div className={styles.inputBox}>
                        <InputDateComponent _name="enddate" _label="วันที่สิ้นสุด" _helperText={props.errors.enddate} />
                      </div>
                      {/* <div className={styles.inputBox}>
                        <InputSelectComponent
                          _name="time"
                          _label="ช่วงเวลา"
                          _menuList={[
                            { label: 'Draft', value: 'draft' },
                            { label: 'Success', value: 'success' },
                          ]}
                        />
                      </div> */}
                    </div>
                    <article className={styles.btnSearchWrapper}>
                      <div className={styles.btSearch}>
                        <ButtonComponent
                          _isIcon="search"
                          _variant="contained"
                          _text="ค้นหา"
                          _colorText="white"
                          _colorBG="blue"
                          _type="submit"
                        />
                      </div>
                      <div className={styles.btSearch}>
                        <ButtonComponent
                          _isIcon="reset"
                          _variant="outlined"
                          _text="รีเซตตัวกรอง"
                          _colorText="blue"
                          _colorBG="white"
                          _type="button"
                          _functionOnClick={() => {
                            inputRef.current.setFieldValue('employeeID', '')
                            inputRef.current.setFieldValue('startdate', '')
                            inputRef.current.setFieldValue('enddate', '')
                          }}
                        />
                      </div>
                      <div className={styles.btReset}>
                        <ButtonComponent
                          _sx={{ fontSize: '16px' }}
                          _variant="contained"
                          _text="Reset"
                          _colorText="white"
                          _colorBG="secondary"
                          _type="button"
                          _isIcon="reset"
                          _functionOnClick={() => {
                            if (props.values.startdate && props.values.enddate) {
                              dispatch(
                                allAction.modalConfirmAction.setOpenModalConfirm(
                                  'คุณต้องการรีเซ็ทข้อมูล',
                                  `ตั้งแต่วันที่ ${props.values.startdate} - ${props.values.enddate}`,
                                  'ไม่สาารถเปลี่ยนแปลงการแก้ไขได้',
                                  'ใช่,รีเซ็ทข้อมูล',
                                  () => {
                                    setResetLoading(true)
                                    ResetAttendanceReport({
                                      input: { startDate: props.values.startdate, endDate: props.values.enddate },
                                    }).then((res: any) => {
                                      setResetLoading(false)
                                      QueryReport(props.values.startdate, props.values.enddate)
                                    })
                                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                                  },
                                ),
                              )
                            } else {
                              dispatch(
                                allAction.modalAction.setOpenModalAlertStatus(
                                  'กรุณาเลือกวันเริ่มต้นและสิ้นสุดของข้อมูล',
                                  '',
                                  'error',
                                ),
                              )
                            }
                          }}
                        />
                      </div>
                    </article>
                  </form>
                )}
              </Formik>
            </div>

            <div className={styles.cardTable}>
              <div className={styles.head}>
                <div className={styles.historybutton} onClick={() => setModalHistory(true)}>
                  <img src={HistoryIcon} alt="" className={styles.Icon} />
                  <p>ประวัติการแก้ไขข้อมูลคลิกที่นี่</p>
                </div>
              </div>
              <div className={styles.dayType}>
                <section className={styles.FilterGroup}>
                  {/* <FilterReportTable /> */}
                  {/* <div className={styles.filterBox}>
                    <ButtonComponent
                      _sx={{ fontSize: '16px' }}
                      _variant="outlined"
                      _text="ตัวกรอง"
                      _colorText="blue"
                      _colorBG="white"
                      _type="button"
                      _isIcon="filter"
                      _functionOnClick={() => setFilterShow(!filtershow)}
                    />
                  </div>
                  {filtershow && (
                    <div className={styles.filterHidden}>
                      <div className={styles.filterBox}>
                        <ButtonComponent
                          _sx={{ fontSize: '16px' }}
                          _variant="outlined"
                          _text="ทำงานล่วงเวลา"
                          _colorText="blue"
                          _colorBG="white"
                          _type="button"
                        />
                      </div>
                      <div className={styles.filterBox}>
                        <ButtonComponent
                          _sx={{ fontSize: '16px' }}
                          _variant="outlined"
                          _text="ลาหักเงิน"
                          _colorText="blue"
                          _colorBG="white"
                          _type="button"
                        />
                      </div>
                      <div className={styles.filterBox}>
                        <ButtonComponent
                          _sx={{ fontSize: '16px' }}
                          _variant="outlined"
                          _text="ลาไม่หักเงิน"
                          _colorText="blue"
                          _colorBG="white"
                          _type="button"
                        />
                      </div>
                      <div className={styles.filterBox}>
                        <ButtonComponent
                          _sx={{ fontSize: '16px' }}
                          _variant="outlined"
                          _text="หมายเหตุ"
                          _colorText="blue"
                          _colorBG="white"
                          _type="button"
                        />
                      </div>
                      <p className={styles.textClear}>ล้างตัวกรอง</p>
                    </div>
                  )} */}
                </section>
                <div className={styles.RigthCard}>
                  {colorList.map((listItem: any, listIndex: number) => (
                    <section className={styles.group} key={listIndex}>
                      <div
                        className={
                          listItem.color === '#FFFFFF'
                            ? styles.colorattend
                            : listItem.color === '#FFD24C'
                            ? styles.colorOt
                            : listItem.color === '#FF6363'
                            ? styles.colorLeave
                            : listItem.color === '#DFDFDE'
                            ? styles.colorWeekend
                            : listItem.color === '#FFA8A8'
                            ? styles.colorHoliday
                            : ''
                        }
                      ></div>
                      <p className={styles.namecolor}>{listItem.name}</p>
                    </section>
                  ))}
                </div>
              </div>
              {resetLoading ? (
                <div className={styles.LoadingCard}>
                  <LinearProgress sx={{ borderRadius: '10px' }} />
                </div>
              ) : (
                <div className={styles.table}>
                  <TableAttendReport
                    listAttend={listAttend}
                    setListAttend={setListAttend}
                    resetLoading={resetLoading}
                    QueryReport={QueryReport}
                    date={startdate}
                    name={searchName}
                    // value={props.values}
                    endDate={enddate}
                    report={
                      paginationFunc(listAttend, pageSite)[listItemIndex]
                        ? paginationFunc(listAttend, pageSite)[listItemIndex]
                        : []
                    }
                  />

                  <PaginationBar
                    _dropDownValue={pageSite}
                    _onDropdownChange={(e: any) => {
                      setPageSite(e.target.value)
                      setListItemIndex(0)
                    }}
                    _selectedNumber={listItemIndex + 1}
                    _numberList={paginationFunc(listAttend, pageSite).map((e: any, index: number) => index + 1)}
                    _setSelectedNumber={(event: any, number: number, index: number) => {
                      if (listItemIndex !== index) {
                        setListItemIndex(index)
                        if (index + 2 === paginationFunc(listAttend, pageSite).length && nextToken) {
                          QueryAttendanceReport({ nextToken: nextToken }).then((res: any) => {
                            if (
                              res.data.data.listTimeAttendanceReportGroupByEmployee.items &&
                              res.data.data.listTimeAttendanceReportGroupByEmployee.items.length > 0
                            ) {
                              setListAttend([
                                ...listAttend,
                                ...res.data.data.listTimeAttendanceReportGroupByEmployee.items,
                              ])
                            }
                            setNextToken(res.data.data.listTimeAttendanceReportGroupByEmployee.nextToken)
                          })
                        }
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">Footer</div>

      <ModalHistory modalHistory={modalHistory} setModalHistory={setModalHistory} />
    </div>
  )
}

export default AttendanceReport
