import React, { useState, useEffect, useRef, Fragment } from 'react'
import styles from './index.module.scss'
import * as yup from 'yup'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Profile from '../../image/avatar.svg'
import {
  NavTopBarComponent,
  NavSideBarComponent,
  InputTextAreaComponent,
  InputTextComponent,
  ButtonComponent,
  AutocompleteIconComponent,
} from '../../component'
import { createTeam2, updateTeam, queryGetTeam } from '../../adapter/xhr'

const TeamNewEdit: React.FC = (): JSX.Element => {
  const formikRef: any = useRef(null)
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [getTeam, setGetTeam] = useState<any>({})
  const [getlistMember, setGetListmember] = useState([])
  // const [inlistMember, setInlistMember] = useState<any>([])

  //* gen Option
  const listMember = getlistMember.map((list: any) => ({
    img: <img src={list.getEmployee.profileImage[0]} />,
    title: list.getEmployee.fullName,
    subtitle: list.getEmployee.position.positionName,
  }))

  // setInlistMember(listMember)
  console.log('listMember >>', listMember)
  const member = [
    { img: <img src={Profile} />, title: 'Thanyarat', subtitle: 'Frontend' },
    { img: <img src={Profile} />, title: 'Beam', subtitle: 'UX/UI' },
    { img: <img src={Profile} />, title: 'Admin', subtitle: 'Mobile' },
  ]
  useEffect(() => {
    if (id) {
      setMode('update')
      const getTeamValidation = {
        getTeamId: id,
      }
      queryGetTeam(getTeamValidation).then((res: any) => {
        console.log(res.data.data.getTeam, '<<<<< This is getTeam')
        setGetTeam(res.data.data.getTeam)
        formikRef.current.setFieldValue('teamname', res.data.data.getTeam.teamName)
        setGetListmember(res.data.data.getTeam.listMember.items)
      })
    } else {
      setMode('create')
    }
  }, [])
  // console.log('formikRef', formikRef)
  const dispatch = useDispatch()

  const validationSchema = yup.object({
    teamname: yup.string().required('ใส่ชื่อทีม'),
    lead: yup.array().required('เลือกหัวหน้าทีม'),
  })
  // console.log('ID...', id)
  console.log('GetTeam...', getTeam)
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>
      <div className="main">
        <nav className={styles.navtop}>
          {mode === 'create' && <h1 className={styles.title}>เพิ่มทีมงาน</h1>}
          {mode === 'update' && <h1 className={styles.title}>แก้ไขทีมงาน</h1>}
        </nav>
        <div className={styles.container}>
          <div className={styles.cardform}>
            <Formik
              innerRef={formikRef}
              initialValues={{
                teamname: '',
                lead: [],
                member: [],
                detail: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                if (mode === 'create') {
                  const createTeam2Valiable = {
                    input: {
                      teamName: values.teamname,
                      teamLeaderID: values.lead,
                      listMemberID: values.member,
                      remark: values.detail,
                    },
                  }
                  createTeam2(createTeam2Valiable).then((res: any) => {
                    console.log('team create api call', res.data.data)
                  })
                } else {
                  const updateTeamValiable = {
                    input: {
                      id: id,
                      teamName: values.teamname,
                      teamLeaderID: values.lead,
                      listMemberID: values.member,
                      remark: values.detail,
                    },
                  }
                  updateTeam(updateTeamValiable).then((res: any) => {
                    console.log('team update api call', res.data.data)
                  })
                }

                console.log('submit')
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2))
                  actions.setSubmitting(false)
                }, 1000)
              }}
            >
              {(props: any) => (
                <form onSubmit={props.handleSubmit} className={styles.formgroup}>
                  {console.log('Value', props.values)}
                  <div className={styles.BoxInput}>
                    <InputTextComponent _name="teamname" _label="ชื่อทีม" _helperText={props.errors.teamname} />
                    <AutocompleteIconComponent
                      _label="หัวหน้าทีม"
                      _option={listMember}
                      _name="lead"
                      _placeholder="กรอกรหัสพนักงาน"
                    />
                    <AutocompleteIconComponent
                      _label="สมาชิก"
                      _option={listMember}
                      _name="member"
                      _placeholder="กรอกรหัสพนักงาน"
                      _isIcon={true}
                    />
                    <InputTextAreaComponent _name="detail" _label="รายละเอียด" />
                  </div>

                  <div className={styles.formButton}>
                    <div className={styles.btCancle}>
                      <ButtonComponent
                        _colorBG="white"
                        _colorText="red"
                        _text="Cancle"
                        _variant="contained"
                        _type="button"
                        _functionOnClick={() => history.back()}
                      />
                    </div>
                    {mode === 'create' && (
                      <div className={styles.btAdd}>
                        <ButtonComponent
                          _colorBG="blue"
                          _colorText="white"
                          _text="Add"
                          _variant="contained"
                          _type="submit"
                        />
                      </div>
                    )}
                    {mode === 'update' && (
                      <div className={styles.btAdd}>
                        <ButtonComponent
                          _colorBG="blue"
                          _colorText="white"
                          _text="Edit"
                          _variant="contained"
                          _type="submit"
                        />
                      </div>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="footer">Footer</div>
    </div>
  )
}
export default TeamNewEdit
