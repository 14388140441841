import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { NavTopBarComponent, NavSideBarComponent, PaginationBar } from '../../component'
import { useDispatch } from 'react-redux'
import { TableLeaveDocument } from './table'
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import SearchIcon from '../LeaveDocument/image/search.svg'
import FilterIcon from '../LeaveDocument/image/filter.svg'
import { ListOvertimeDocument } from 'src/adapter/xhr'
import { paginationFunc } from 'src/utils/common'
import { LeaveGroupByEmployeeQuery } from 'src/utils/generated'
import { PopperFilter } from './Modal'

const OvertimeDocument: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const [Listdocument, setListDocument] = useState<LeaveGroupByEmployeeQuery[]>([])
  const [pageSite, setPageSite] = useState<number>(20)
  const [listItemIndex, setListItemIndex] = useState<number>(0)
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openpopper, setOpenPopper] = useState(false)

  const [fillStatus, setfillStatus] = useState<any>('')
  const [fillStartdate, setfillStartdate] = useState<any>('')

  const [filterType, setFilterType] = useState<any>('')
  const [search, setSearch] = useState<any>('')
  const [counter, setCounter] = useState(0)
  console.log(filterType, fillStartdate, 'FILTER')

  const filter = {
    overtimeType: {
      beginsWith: filterType,
    },
    status: {
      beginsWith: fillStatus,
    },
    date: {
      beginsWith: fillStartdate,
    },
  }

  console.log(filter, 'filter')

  const handleClickPopper = (event: any) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper((previousOpen) => !previousOpen)
  }

  const QueryListOvertimeDocument = () => {
    ListOvertimeDocument({ nextToken: nextToken, filter: filter }).then((res: any) => {
      console.log(res.data.data.listOvertimeGroupByEmployee.items, 'res')
      if (
        res.data.data.listOvertimeGroupByEmployee.items &&
        res.data.data.listOvertimeGroupByEmployee.items.length > 0
      ) {
        setListDocument(res.data.data.listOvertimeGroupByEmployee.items)
      }
      setNextToken(res.data.data.listOvertimeGroupByEmployee.nextToken)
    })
  }
  useEffect(() => {
    QueryListOvertimeDocument()
  }, [])

  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <div className="main">
          <div className={styles.container}>
            <div className={styles.GroupArea}>
              <section className={styles.headArea}>
                <div className={styles.BoxInput}>
                  <FormControl fullWidth sx={{ m: 1, backgroundColor: '#FFFFFF', fontFamily: 'KanitRegular' }}>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value)
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt="" />
                        </InputAdornment>
                      }
                      sx={{ fontFamily: 'KanitRegular' }}
                      placeholder="Search employee"
                    />
                  </FormControl>
                </div>
                <img
                  src={FilterIcon}
                  alt=""
                  style={{ cursor: 'pointer' }}
                  onClick={(e: any) => {
                    handleClickPopper(e)
                  }}
                />
              </section>
              <section className={styles.TableLeave}>
                <TableLeaveDocument
                  Listdocument={
                    paginationFunc(Listdocument, pageSite)[listItemIndex]
                      ? paginationFunc(Listdocument, pageSite)[listItemIndex]
                      : []
                  }
                  nextToken={nextToken}
                  QueryListOvertimeDocument={QueryListOvertimeDocument}
                  search={search}
                />

                <PaginationBar
                  _dropDownValue={pageSite}
                  _onDropdownChange={(e: any) => {
                    setPageSite(e.target.value)
                    setListItemIndex(0)
                  }}
                  _selectedNumber={listItemIndex + 1}
                  _numberList={paginationFunc(Listdocument, pageSite).map((e: any, index: number) => index + 1)}
                  _setSelectedNumber={(event: any, number: number, index: number) => {
                    if (listItemIndex !== index) {
                      setListItemIndex(index)
                      if (index + 2 === paginationFunc(Listdocument, pageSite).length && nextToken) {
                        QueryListOvertimeDocument()
                        // ListOvertimeDocument({ nextToken: nextToken, filter: filter }).then((res: any) => {
                        //   if (
                        //     res.data.data.listOvertimeGroupByEmployee.items &&
                        //     res.data.data.listOvertimeGroupByEmployee.items.length > 0
                        //   ) {
                        //     setListDocument([...Listdocument, ...res.data.data.listOvertimeGroupByEmployee.items])
                        //   }
                        //   setNextToken(res.data.data.listOvertimeGroupByEmployee.nextToken)
                        // })
                      }
                    }
                  }}
                />
              </section>
            </div>
          </div>
        </div>
        <div className="footer">Footer</div>
      </div>
      <PopperFilter
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        openpopper={openpopper}
        setOpenPopper={setOpenPopper}
        filterType={filterType}
        setFilterType={setFilterType}
        fillStartdate={fillStartdate}
        setfillStartdate={setfillStartdate}
        fillStatus={fillStatus}
        setfillStatus={setfillStatus}
        QueryListOvertimeDocument={QueryListOvertimeDocument}
      />
    </>
  )
}
export default OvertimeDocument
