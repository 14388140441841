import {
  EDayType,
  ELeaveType,
  Employee,
  EOvertimeHourHalf,
  EOverTimeRoundFloor,
  EOverTimeRoundType,
  EOverTimeType,
  EScanReasonType,
  ETimeAttendanceReportType,
  Holiday,
  Leave,
  LeaveReport,
  Overtime,
  OvertimeConfig,
  OvertimeReport,
  Shift,
  TimeAttendance,
  TimeAttendanceConfig,
  TimeAttendanceReport,
} from './generated'
export type HolidayYearList = Holiday['holidayYear']
type AttendanceLogToReport = {
  attendanceLogs: TimeAttendance[]
  employees: Employee[]
  defaultShift: Shift
  attendanceConfig: TimeAttendanceConfig
  startDate: Date
  endDate: Date
  leaveList: (Leave & LeaveReport)[]
  holidayList: HolidayYearList
  overtimeApproveList: Overtime[]
  overtimeConfig?: OvertimeConfig
}

export default ({
  attendanceLogs,
  employees,
  defaultShift,
  attendanceConfig,
  startDate,
  endDate,
  leaveList,
  holidayList,
  overtimeApproveList,
  overtimeConfig,
}: AttendanceLogToReport): TimeAttendanceReport[] => {
  const reports: TimeAttendanceReport[] = []
  const currentDate = new Date()

  for (
    let dateFlag = new Date(startDate);
    dateFlag <= endDate && currentDate >= dateFlag;
    dateFlag.setDate(dateFlag.getDate() + 1)
  ) {
    const date = dateFmt(dateFlag).getDate

    employees.forEach((employee) => {
      //  ------------------------- ถ้าวันที่ log น้อยกว่า วันที่พนักงานสร้าง ออกฟังก์ชัน -------------------------
      //   if (
      //     new Date(dateFmt(employee.hireDate || employee.createdAt).getDate) > new Date(date) ||
      //     new Date(dateFmt(employee.retireDate).getDate) < new Date(date)
      //   )
      //     return

      const foundAttendance = attendanceLogs.filter(
        (e: any) =>
          e.employeeID === employee.id &&
          withRange(
            new Date(e.scanAt),
            new Date(date).getTime() - 7 * 60 * 60 * 1000,
            new Date(date).getTime() + 17 * 60 * 60 * 1000,
          ),
      )

      const employeeShift = getShiftByEmployeeViaDate({
        shift: defaultShift,
        employeeID: employee.id,
        date,
        holidayList,
        leaveList,
        overtimeApproveList,
      })

      const baseReport: any = {
        employeeID: employee.id,
        date,
        createdAt: new Date(),
        updatedAt: new Date(),
        isLink: true,
        remark: '',
        dayType: employeeShift.dayType,
      }

      const { foundLeave, foundOTApprove } = employeeShift

      if (employeeShift.dayType === EDayType.Workday) {
        baseReport.shiftName = employeeShift.shift.shiftName
        baseReport.shiftStartTime = employeeShift.shiftWorkTime.startTime
        baseReport.shiftEndTime = employeeShift.shiftWorkTime.endTime
        baseReport.shiftVersion = (employeeShift.shift as any).version
        baseReport.shiftMinute = employeeShift.shiftWorkMinute
        baseReport.breakTimeList = employeeShift.shiftBreakTimeList
      }

      foundLeave.forEach((leave) => {
        const _leaveReport = {
          leaveRequestID: leave.id,
          startedAt: new Date(leave.startedAt),
          endedAt: new Date(leave.endedAt),
          leaveType: leave.leaveType,
          leaveForm: leave.leaveForm,
          leaveDocumentAttachment: leave.leaveDocumentAttachment,
          status: leave.status,
          leaveMinute: avoidBreakTimeMinute({
            startedAt: new Date(leave.startedAt),
            endedAt: new Date(leave.endedAt),
            breakList: employeeShift.shiftBreakTimeList,
          }),
        }

        // IS DEDUCT ลาหักเงิน
        if (getIsLeaveDeduct(leave)) {
          baseReport['leaveDeductList'] = pushList(baseReport['leaveDeductList'], _leaveReport)
        } else {
          baseReport['leaveList'] = pushList(baseReport['leaveList'], _leaveReport)
        }
      })

      foundOTApprove.forEach((ot: any) => {
        baseReport['overtimeApproveList'] = pushList(baseReport['overtimeApproveList'], {
          overtimeRequestID: ot.id,
          startedAt: new Date(ot.startedAt),
          endedAt: new Date(ot.endedAt),
          overtimeType: ot.overtimeType,
          locationType: ot.locationType,
          status: ot.status,
          overtimeApproveMinute: toMinute(new Date(ot.endedAt).getTime() - new Date(ot.startedAt).getTime()),
        })
      })

      // ------------------------- QUOTA -------------------------
      const inLateQuota = attendanceConfig.workInLateQuotaMinute || 0
      const outEarlyQuota = attendanceConfig.workOutEarlyQuotaMinute || 0
      const breakLateQuota = attendanceConfig.breakOvertimeQuotaMinute || 0

      const { workingTimeList } = employeeShift
      // ------------------------- เจอการเข้างาน -------------------------
      if (foundAttendance.length) {
        //  (date + "scanIn")
        // สแกนเข้างานแรกของวัน
        const scanIn = new Date(
          foundAttendance.filter((atd: any) => atd.scanReason === EScanReasonType.ScanIn)[0]?.scanAt,
        )

        // // สแกนออกงานสุดท้ายของวัน
        const scanOut = new Date(
          lastItem<any>(foundAttendance.filter((atd: any) => atd.scanReason === EScanReasonType.ScanOut))?.scanAt,
        )

        let attendanceReport: any = { ...baseReport, scanIn, scanOut: isNaN(scanOut.getTime()) ? null : scanOut }

        // ------------------------- ทำงานในวันปกติ -------------------------
        if (employeeShift.dayType === EDayType.Workday) {
          const OVERTIME_THREHOLD = 60
          const shiftWorkTimeStart = employeeShift.shiftWorkTime.startedAt
          const shiftWorkTimeEnd = employeeShift.shiftWorkTime.endedAt

          // ---------------------- เข้าทำงานในช่วงกะ ----------------------
          if (scanIn.getTime() < shiftWorkTimeEnd!.getTime()) {
            // ---------------------- ลาทั้งวัน (ไม่เหลือชั่วโมงทำงาน) ----------------------
            if (!workingTimeList.length) {
              const scanOutDiffMinute = toMinute(new Date(scanOut).getTime() - new Date(shiftWorkTimeEnd!).getTime())
              if (scanOutDiffMinute >= OVERTIME_THREHOLD) {
                attendanceReport['overtimeList'] = getOvertime({ employeeShift, scanOut })
              }

              sumReportAllMinute({
                report: attendanceReport,
              })
              reports.push(withDefaultReport(attendanceReport))
              return
            }

            let _breakTimeList: any[] = []

            // หาช่วงเวลาการเข้างาน
            const _workingTimeList = workingTimeList.map((workingTime, idx) => {
              const filterScanIn = foundAttendance.filter((atd: any) => {
                if (atd.scanReason !== 'SCAN_IN') return
                // first

                if (idx === 0) {
                  return withRange(
                    new Date(atd.scanAt),
                    new Date(date).getTime() - 7 * 60 * 60 * 1000,
                    new Date(workingTime.endedAt!),
                  )
                }
                // last
                if (idx === workingTimeList.length - 1) {
                  return withRange(
                    new Date(atd.scanAt),
                    new Date(workingTimeList[idx - 1].endedAt!),
                    new Date(date).getTime() + 17 * 60 * 60 * 1000,
                  )
                }
                // else
                return withRange(
                  new Date(atd.scanAt),
                  new Date(workingTimeList[idx - 1].endedAt!),
                  new Date(workingTime.startedAt!),
                )
              })

              const filterScanOut = foundAttendance.filter((atd: any) => {
                if (atd.scanReason !== 'SCAN_OUT') return

                if (workingTimeList.length === 1) {
                  return scanOut
                }

                // first
                if (idx === 0) {
                  return withRange(
                    new Date(atd.scanAt),
                    new Date(date).getTime() - 7 * 60 * 60 * 1000,
                    new Date(
                      workingTimeList.length === 1
                        ? workingTimeList[0].startedAt!
                        : workingTimeList[idx + 1].startedAt!,
                    ),
                  )
                }
                // last
                if (idx === workingTimeList.length - 1) {
                  return withRange(
                    new Date(atd.scanAt),
                    new Date(workingTime.startedAt!),
                    new Date(date).getTime() + 17 * 60 * 60 * 1000,
                  )
                }
                // else
                return withRange(
                  new Date(atd.scanAt),
                  new Date(
                    workingTimeList.length === 1 ? workingTimeList[0].endedAt! : workingTimeList[idx + 1].endedAt!,
                  ),
                  new Date(workingTime.startedAt!),
                )
              })

              // [08:00 - 12:00, 13:00 - 17:00] | 11:00 - 15:00
              const _foundLeave = foundLeave.find(
                (leave: any) => new Date(leave.startedAt).getTime() === new Date(workingTime.endedAt!).getTime(),
              )

              // 08:00 - 12:00, 13:00 - 17:00 | 08:00 - 10:00, 11:00 - 12:00, 13:00 - 17:00
              if (employeeShift.shiftBreakTimeList.length) {
                const _isBreak = employeeShift.shiftBreakTimeList.find(
                  (b) => b.startedAt!.getTime() === workingTime.endedAt!.getTime(),
                )
                if (_isBreak && workingTimeList[idx + 1]?.startedAt) {
                  _breakTimeList.push({
                    idx,
                    startedAt: _isBreak.startedAt,
                    endedAt: workingTimeList[idx + 1].startedAt,
                    isScanStart: _isBreak.isScanStart,
                    isScanEnd: workingTimeList[idx + 1].isScanStart,
                  })
                }
              }

              return {
                ...workingTime,
                scanInList: filterScanIn,
                scanIn: filterScanIn[0] && {
                  ...filterScanIn[0],
                  scanInDiff: toMinute(
                    new Date(filterScanIn[0].scanAt).getTime() - new Date(workingTime.startedAt!).getTime(),
                  ),
                },
                scanOutList: filterScanOut,
                scanOut: lastItem(filterScanOut) && {
                  ...(lastItem(filterScanOut) as any),
                  scanOutDiff: toMinute(
                    new Date(lastItem<any>(filterScanOut)?.scanAt).getTime() - new Date(workingTime.endedAt!).getTime(),
                  ),
                },
                foundLeave: _foundLeave,
              }
            })

            _breakTimeList = _breakTimeList.map((b) => {
              return {
                ...b,
                endedAt: b.endedAt,
                scanInList: _workingTimeList[b.idx].scanOutList,
                scanIn: _workingTimeList[b.idx].scanOut,
                scanOutList: _workingTimeList[b.idx + 1].scanInList,
                scanOut: _workingTimeList[b.idx + 1].scanIn,
              }
            })

            // ------------------------------- ATTENDANCE_REPORT -------------------------------
            attendanceReport = {
              ...attendanceReport,
              Type: ETimeAttendanceReportType.Attendance,
              scanIn: scanIn,
              inDiffMinute: toMinute(new Date(scanIn).getTime() - new Date(workingTimeList[0].startedAt!).getTime()),
              outDiffMinute: lastItem(_workingTimeList)?.scanOut?.scanOutDiff,
            }

            const SCAN_IN_ABSENT_THRESHOLD = attendanceConfig.maxWorkInLateMinute || 0
            const SCAN_OUT_ABSENT_THRESHOLD = attendanceConfig.maxWorkOutEarlyMinute || 0

            // ------------------------------- SCAN_IN ABSENT -------------------------------
            if (attendanceReport['inDiffMinute'] > SCAN_IN_ABSENT_THRESHOLD) {
              attendanceReport['leaveDeductList'] = pushList(attendanceReport['leaveDeductList'], {
                startedAt: shiftWorkTimeStart,
                endedAt: scanIn,
                leaveMinute: toMinute(new Date(scanIn).getTime() - new Date(shiftWorkTimeStart!).getTime()),
                leaveType: 'ABSENT',
              })
              // if (scanIn.getTime() < shiftWorkTimeStart!.getTime()) {
              //   attendanceReport["leaveDeductList"] = pushList(attendanceReport["leaveDeductList"], {
              //     startedAt: shiftWorkTimeStart,
              //     endedAt: scanIn,
              //     leaveMinute: toMinute(new Date(scanIn).getTime() - new Date(shiftWorkTimeStart!).getTime()),
              //     leaveType: "ABSENT",
              //   })
              // } else {
              //   attendanceReport["leaveDeductList"] = pushList(attendanceReport["leaveDeductList"], {
              //     startedAt: shiftWorkTimeStart,
              //     endedAt: shiftWorkTimeEnd,
              //     leaveMinute: avoidBreakTimeMinute({
              //       startedAt: shiftWorkTimeStart!,
              //       endedAt: shiftWorkTimeEnd!,
              //       breakList: employeeShift.shiftBreakTimeList,
              //     }),
              //     leaveType: "ABSENT",
              //   })
              // }
            }

            // ------------------------------- SCAN_OUT ABSENT -------------------------------
            if (attendanceReport['outDiffMinute'] * -1 > SCAN_OUT_ABSENT_THRESHOLD) {
              attendanceReport['leaveDeductList'] = pushList(attendanceReport['leaveDeductList'], {
                startedAt: scanOut,
                endedAt: shiftWorkTimeEnd,
                leaveMinute: toMinute(new Date(shiftWorkTimeEnd!).getTime() - new Date(scanOut).getTime()),
                leaveType: 'ABSENT',
              })
            }

            // ------------------------------- WORKING_TIME_LIST -------------------------------

            attendanceReport['workingTimeList'] = []
            _workingTimeList.forEach((w, idx) => {
              let startedAt

              if (w.scanIn?.scanAt) {
                startedAt = new Date(Math.max(new Date(w.startedAt!).getTime(), new Date(w.scanIn?.scanAt).getTime()))
              } else {
                startedAt = new Date(w.startedAt!)
              }

              if (!w.scanOut?.scanAt) {
                if (w.isScanEnd && !w.foundLeave && idx !== _breakTimeList.length - 1 && !isNaN(scanOut.getTime())) {
                  attendanceReport['leaveDeductList'] = pushList(attendanceReport['leaveDeductList'], {
                    startedAt: w.startedAt,
                    endedAt: w.endedAt,
                    leaveMinute: toMinute(w.endedAt!.getTime() - w.startedAt!.getTime()),
                    leaveType: ELeaveType.Absent,
                  })
                  return
                }

                if (!isNaN(scanOut.getTime())) {
                  attendanceReport['workingTimeList'].push({
                    startedAt,
                    endedAt: w.endedAt,
                    workingMinute: toMinute(w.endedAt!.getTime() - startedAt.getTime()),
                  })
                }
              } else {
                const endedAt: Date = new Date(Math.min(w.endedAt!.getTime(), new Date(scanOut).getTime()))
                attendanceReport['workingTimeList'].push({
                  startedAt,
                  endedAt,
                  workingMinute: toMinute(endedAt!.getTime() - startedAt.getTime()),
                })
              }
            })

            if (isNaN(scanOut!.getTime())) {
              attendanceReport['leaveDeductList'] = pushList(attendanceReport['leaveDeductList'], {
                startedAt: shiftWorkTimeStart,
                endedAt: shiftWorkTimeEnd,
                leaveMinute:
                  toMinute(shiftWorkTimeEnd!.getTime() - shiftWorkTimeStart!.getTime()) -
                  employeeShift.shiftBreakMinute,
                leaveType: 'ABSENT',
              })
            }

            attendanceReport['breakDiffMinute'] = 0
            _breakTimeList.forEach((b) => {
              if (b.isScanStart && !b.scanIn) {
                attendanceReport['breakDiffMinute'] += toMinute(b.startedAt.getTime() - b.endedAt.getTime())
              }

              if (b.isScanEnd && !b.scanOut) {
                attendanceReport['breakDiffMinute'] += attendanceConfig.maxBreakOvertimeMinute || 0
              }
            })

            const scanOutDiffMinute = toMinute(new Date(scanOut).getTime() - new Date(shiftWorkTimeEnd!).getTime())

            // ------------------------------- OVERTIME -------------------------------
            const isOT = scanOutDiffMinute >= OVERTIME_THREHOLD

            if (isOT) {
              attendanceReport['Type'] = ETimeAttendanceReportType.Overtime
              attendanceReport['overtimeList'] = getOvertime({ employeeShift, scanOut })
            }

            // attendanceReport["breakDiffMinute"] += breakDiffMinute

            sumReportAllMinute({
              report: attendanceReport,
              SCAN_IN_ABSENT_THRESHOLD,
              SCAN_OUT_ABSENT_THRESHOLD,
              breakLateQuota,
              inLateQuota,
              outEarlyQuota,
              overtimeConfig,
            })

            reports.push(withDefaultReport(attendanceReport))
          }
          // ---------------------- เข้าทำงานเลยเวลากะ ----------------------
          else {
            if (workingTimeList.length) {
              attendanceReport['leaveDeductList'] = pushList(attendanceReport['leaveDeductList'], {
                startedAt: shiftWorkTimeStart,
                endedAt: shiftWorkTimeEnd,
                leaveMinute:
                  toMinute(shiftWorkTimeEnd!.getTime() - shiftWorkTimeStart!.getTime()) -
                  employeeShift.shiftBreakMinute,
                leaveType: ELeaveType.Absent,
              })
            }
            // ไม่มีสแกนออก
            if (isNaN(scanOut.getTime())) {
              sumReportAllMinute({ report: attendanceReport, overtimeConfig })
              reports.push(withDefaultReport(attendanceReport))
              return
            }

            attendanceReport['overtimeList'] = [
              {
                startedAt: scanIn,
                endedAt: scanOut,
                overtimeMinute: toMinute(scanOut.getTime() - scanIn.getTime()),
                overtimeType: EOverTimeType.Overtime,
              },
            ]

            sumReportAllMinute({ report: attendanceReport, overtimeConfig })
            reports.push(withDefaultReport(attendanceReport))
          }
        }
        // ------------------------- ทำงานในวันหยุด ------------------------- DONE
        else {
          attendanceReport = {
            ...attendanceReport,
            Type: employeeShift.dayType,
            scanIn,
          }

          if (!attendanceReport.scanOut) {
            sumReportAllMinute({
              report: attendanceReport,
              overtimeConfig,
            })

            reports.push(withDefaultReport(attendanceReport))
            return
          }

          attendanceReport['overtimeList'] = getOvertime({ employeeShift, scanOut, scanIn })
          if (attendanceReport['overtimeList'].length > 1) {
            attendanceReport['Type'] = ETimeAttendanceReportType.Overtime
          }

          sumReportAllMinute({
            report: attendanceReport,
            overtimeConfig,
          })

          reports.push(withDefaultReport(attendanceReport))
        }
      }

      // ------------------------- ไม่เข้างานเลย (ขาด) -------------------------
      if (!foundAttendance.length && employeeShift.dayType === EDayType.Workday) {
        if (!workingTimeList.length) {
          sumReportAllMinute({
            report: baseReport,
            SCAN_IN_ABSENT_THRESHOLD: 0,
            SCAN_OUT_ABSENT_THRESHOLD: 0,
            overtimeConfig,
          })
          return reports.push(withDefaultReport(baseReport))
        }
        const leaveReport: any = {
          ...baseReport,
        }

        // ------------------------- ขาด -------------------------

        if (foundLeave.length) {
          workingTimeList.forEach((w) => {
            leaveReport['leaveDeductList'] = pushList(leaveReport['leaveDeductList'], {
              startedAt: w.startedAt,
              endedAt: w.endedAt,
              leaveMinute: toMinute(new Date(w.endedAt!).getTime() - new Date(w.startedAt!).getTime()),
              leaveType: ELeaveType.Absent,
            })
          })
        } else {
          leaveReport['leaveDeductList'] = [
            {
              startedAt: employeeShift.shiftWorkTime.startedAt,
              endedAt: employeeShift.shiftWorkTime.endedAt,
              leaveMinute:
                toMinute(
                  employeeShift.shiftWorkTime.endedAt!.getTime() - employeeShift.shiftWorkTime.startedAt!.getTime(),
                ) - employeeShift.shiftBreakMinute,
              leaveType: ELeaveType.Absent,
            },
          ]
        }

        sumReportAllMinute({
          report: leaveReport,
          SCAN_IN_ABSENT_THRESHOLD: 0,
          SCAN_OUT_ABSENT_THRESHOLD: 0,
          overtimeConfig,
        })
        reports.push(withDefaultReport(leaveReport))
        // ------------------------- ถ้าเป็นวันหยุด เพื่มข้อมูล report ------------------------- #DONE#
      } else if (!foundAttendance.length && employeeShift.dayType !== EDayType.Workday) {
        const report: any = { ...baseReport, Type: employeeShift.dayType }
        sumReportAllMinute({
          report: report,
          SCAN_IN_ABSENT_THRESHOLD: 0,
          SCAN_OUT_ABSENT_THRESHOLD: 0,
          overtimeConfig,
        })
        reports.push(withDefaultReport(report))
      }
    })
  }
  return reports
}

// ------------------------------------ SHIFT ----------------------------------------

export const filterLeave = ({
  date,
  employeeID,
  leaveList,
}: {
  date: string
  employeeID: string
  leaveList: (Leave & LeaveReport)[]
}): (Leave & LeaveReport)[] => {
  if (!leaveList.length) return []

  const foundLeave = leaveList.filter(
    (leave) =>
      leave.employeeID === employeeID &&
      new Date(dateFmt(leave.startedAt!).getDate) <= new Date(date) &&
      new Date(leave.endedAt!) >= new Date(date),
  )
  return foundLeave
}

export const getIsLeaveDeduct = (leave: Leave & LeaveReport): boolean | null | undefined => {
  // return /\|deduct$/.test(leaveID);
  return leave.isLeaveDeductMoney
}

export const leaveMinuteReducer = (acc: number, curr: Leave & LeaveReport) => {
  return acc + (curr?.leaveMinute || 0)
}

/*
  diff 10 quota 5 -> used 5, late 5
  diff 2 quota 5 -> used 3, late 0
  diff 0 quota 5 -> used 0, late 0} diffMinute
  08:20 | 30 | -> 20
  08:40 | 30 | -> 30
  08:00 | 30 | -> 0
      ScanIn   QuotaTime               ScanIn   Shift
  if (08:00 <= 08:30 === 08:30) return 08:00 - 08:00
      ScanIn   QuotaTime    Shift  QuotaTime
  if (08:40 > 08:30) return 08:00 - 08:30

 */

export const getUsedQuotaMinute = (minute: number, quotaMinute: number) => {
  if (minute > quotaMinute) return quotaMinute
  return Math.max(minute, 0)
}

export type GetShiftByEmployeeViaDate = (props: {
  shift: any
  employeeID: string
  date: string
  holidayList: HolidayYearList
  leaveList?: (Leave & LeaveReport)[]
  overtimeApproveList?: Overtime[]
}) => {
  dayType: EDayType
  shiftWorkTime: ShiftWorkTime
  shiftBreakTimeList: ShiftWorkTime[]
  shift: Shift
  shiftWorkMinute: number
  shiftBreakMinute: number
  workingTimeList: ShiftWorkTime[]
  foundLeave: (Leave & LeaveReport)[]
  foundOTApprove: Overtime[]
}

export type ShiftWorkTime = {
  startedAt?: Date
  endedAt?: Date
  startTime?: string
  endTime?: string
  isScanStart?: boolean
  isScanEnd?: boolean
}

export const getShiftByEmployeeViaDate: GetShiftByEmployeeViaDate = ({
  shift,
  employeeID,
  date,
  holidayList,
  leaveList = [],
  overtimeApproveList = [],
}) => {
  let dayType = EDayType.Weekend
  let shiftWorkTime: ShiftWorkTime = {}
  let shiftBreakTimeList: ShiftWorkTime[] = []
  let shiftWorkMinute = 0
  let shiftBreakMinute = 0

  const _date = dateFmt(date)

  const day = _date.getDay

  ;(() => {
    const isHoliday = holidayList?.find((h: any) => h.date === date)
    if (isHoliday) {
      dayType = EDayType.Holiday
      return
    }

    for (const workDay of shift.workDayPolicy) {
      if (workDay.dayList.some((e: any) => e === day)) {
        dayType = EDayType.Workday
        shiftWorkTime = workDay.timeList[0] as ShiftWorkTime

        shiftWorkTime.startedAt = _date.timeToDate(shiftWorkTime.startTime!)
        shiftWorkTime.endedAt = _date.timeToDate(shiftWorkTime.endTime!)

        shiftWorkMinute = toMinute(shiftWorkTime.endedAt.getTime() - shiftWorkTime.startedAt.getTime())
        break
      }
    }

    for (const breakDay of shift.breakPolicy) {
      if (breakDay.dayList.some((e: any) => e === day)) {
        shiftBreakTimeList = breakDay.timeList

        shiftBreakMinute = shiftBreakTimeList.reduce((acc: number, curr: any, i: number) => {
          shiftBreakTimeList[i].startedAt = _date.timeToDate(curr.startTime)
          shiftBreakTimeList[i].endedAt = _date.timeToDate(curr.endTime)
          const minute = toMinute(shiftBreakTimeList[i].endedAt!.getTime() - shiftBreakTimeList[i].startedAt!.getTime())
          return acc + minute
        }, 0)
        break
      }
    }
  })()

  const foundLeave = filterLeave({
    date,
    employeeID,
    leaveList,
  })

  const foundOTApprove = overtimeApproveList.filter((ot: any) => {
    return ot.employeeID === employeeID && date === dateFmt(ot.startedAt).getDate
  })

  return {
    dayType,
    shiftWorkTime,
    shiftBreakTimeList,
    shift,
    shiftTotalWorkMinute: shiftWorkMinute,
    shiftWorkMinute: shiftWorkMinute - shiftBreakMinute,
    shiftBreakMinute,
    workingTimeList:
      dayType === EDayType.Workday ? getWorkingTimeList(shiftWorkTime, shiftBreakTimeList, foundLeave) : [],
    foundLeave,
    foundOTApprove,
  }
}

export const avoidBreakTimeMinute = ({
  startedAt,
  endedAt,
  breakList,
}: {
  startedAt: Date
  endedAt: Date
  breakList: ShiftWorkTime[]
}): number => {
  let breakOffsetMinute = 0
  const totalMinute = toMinute(endedAt.getTime() - startedAt.getTime())

  breakList.forEach((b) => {
    const inBreakRange = (time: Date) => withRange(time.getTime(), b.startedAt!.getTime(), b.endedAt?.getTime())

    // over break
    if (b.startedAt!.getTime() > startedAt.getTime() && b.endedAt!.getTime() < endedAt.getTime()) {
      breakOffsetMinute += toMinute(b.endedAt!.getTime() - b.startedAt!.getTime())
    }
    // in break
    else if (inBreakRange(startedAt) || inBreakRange(endedAt)) {
      const start = Math.max(startedAt.getTime(), b.startedAt!.getTime())
      const end = Math.min(endedAt.getTime(), b.endedAt!.getTime())
      breakOffsetMinute += toMinute(end - start)
    }
  })

  return totalMinute - breakOffsetMinute
}

export const workingTimeWithLeave = (
  workingTime: ShiftWorkTime,
  leaveList: (Leave & LeaveReport)[],
): ShiftWorkTime[] => {
  if (!leaveList.length) return [workingTime]

  const isLeaveFull = leaveList.find(
    (leave) =>
      new Date(leave.startedAt).getTime() <= workingTime.startedAt!.getTime() &&
      new Date(leave.endedAt).getTime() >= workingTime.endedAt!.getTime(),
  )

  if (isLeaveFull) return []

  const foundLeave = leaveList.filter(
    (leave) =>
      withRange(new Date(leave.startedAt), workingTime.startedAt!, new Date(workingTime.endedAt!)) ||
      withRange(new Date(leave.endedAt!), workingTime.startedAt!, new Date(workingTime.endedAt!)),
  )

  if (!foundLeave.length) return [workingTime]

  foundLeave.sort((a, b) => (new Date(a.startedAt).getTime() < new Date(b.startedAt).getTime() ? -1 : 1))

  const arr: ShiftWorkTime[] = []

  if (new Date(foundLeave[0].startedAt).getTime() > workingTime.startedAt!.getTime()) {
    arr.push({
      startedAt: workingTime.startedAt,
      endedAt: new Date(foundLeave[0].startedAt),
      isScanStart: workingTime.isScanStart,
      isScanEnd: false,
    })
  }

  for (let i = 0; i < foundLeave.length - 1; i++) {
    if (new Date(foundLeave[i].endedAt) < new Date(foundLeave[i + 1].startedAt)) {
      arr.push({
        startedAt: new Date(foundLeave[i].endedAt),
        endedAt: new Date(foundLeave[i + 1].startedAt),
        isScanStart: true,
        isScanEnd: false,
      })
    }
  }

  if (new Date(lastItem(foundLeave).endedAt).getTime() < new Date(workingTime.endedAt!).getTime()) {
    arr.push({
      startedAt: new Date(lastItem(foundLeave).endedAt),
      endedAt: workingTime.endedAt,
      isScanStart: true,
      isScanEnd: workingTime.isScanEnd,
    })
  }

  return arr
}

// 08:00 - 17:00, [12:00 - 13:00] -> [08:00 - 12:00, 13:00 - 17:00]
// 08:00 - 17:00, [12:00 - 13:00, 14:00 - 14:30] -> [08:00 - 12:00, 13:00 - 14:00, 14:30 - 17:00]
export const splitWorkTimeFromBreakTime = (shiftTime: ShiftWorkTime, breakTimeList: ShiftWorkTime[]) => {
  if (!breakTimeList?.length)
    return [
      {
        startedAt: shiftTime.startedAt,
        endedAt: shiftTime.endedAt,
        isScanStart: shiftTime.isScanStart,
        isScanEnd: shiftTime.isScanEnd,
      },
    ]

  const workingTimeList: ShiftWorkTime[] = []

  const timeList = [shiftTime, ...breakTimeList].sort((a, b) =>
    a.startedAt!.getTime() < b.startedAt!.getTime() ? -1 : 1,
  )

  // first
  for (let i = 0; i < timeList.length; i++) {
    if (i === 0) {
      workingTimeList.push({
        startedAt: timeList[i].startedAt,
        endedAt: timeList[i + 1].startedAt,
        isScanStart: shiftTime.isScanStart,
        isScanEnd: timeList[i + 1].isScanStart,
      })

      // last
    } else if (i === timeList.length - 1) {
      if (shiftTime.endedAt?.getTime() !== timeList[i].endedAt?.getTime()) {
        workingTimeList.push({
          startedAt: timeList[i].endedAt,
          endedAt: shiftTime.endedAt,
          isScanStart: timeList[i].isScanEnd,
          isScanEnd: shiftTime.isScanEnd,
        })
      }
    } else {
      workingTimeList.push({
        startedAt: timeList[i].endedAt,
        endedAt: timeList[i + 1].startedAt,
        isScanStart: timeList[i].isScanStart,
        isScanEnd: timeList[i + 1].isScanEnd,
      })
    }
  }

  return workingTimeList
}

export const getWorkingTimeList = (
  shiftWorkTime: ShiftWorkTime,
  shiftBreakTimeList: ShiftWorkTime[],
  leaveList: (Leave & LeaveReport)[] = [],
) => {
  const workingTimeList = splitWorkTimeFromBreakTime(shiftWorkTime, shiftBreakTimeList).flatMap((e) => e)

  if (!leaveList.length) return workingTimeList

  return workingTimeList.map((workingTime) => workingTimeWithLeave(workingTime, leaveList)).flatMap((e) => e)
}

export const sumReportAllMinute = ({
  report,
  SCAN_IN_ABSENT_THRESHOLD = 0,
  SCAN_OUT_ABSENT_THRESHOLD = 0,
  OVERTIME_THRESHOLD = 60,
  inLateQuota = 0,
  outEarlyQuota = 0,
  breakLateQuota = 0,
  overtimeConfig = {},
}: {
  report: any
  SCAN_IN_ABSENT_THRESHOLD?: number
  SCAN_OUT_ABSENT_THRESHOLD?: number
  OVERTIME_THRESHOLD?: number
  inLateQuota?: number
  outEarlyQuota?: number
  breakLateQuota?: number
  overtimeConfig?: OvertimeConfig
}) => {
  // workingMinute
  report['workingMinute'] = report['workingTimeList']?.reduce(reducerKey('workingMinute', 0), 0)

  // overtimeApproveMinute
  report['overtimeApproveMinute'] = report['overtimeApproveList']?.length
    ? roundOvertime({
        minute: report['overtimeApproveList']
          ?.filter((ot: any) => ot?.status === 'APPROVE')
          .reduce(reducerKey('overtimeApproveMinute', 0), 0),
        roundType: overtimeConfig.overtimeRoundType || EOverTimeRoundType.NotRound,
        roundFloor: overtimeConfig.overtimeRoundFloor || EOverTimeRoundFloor.Round,
        type: overtimeConfig.overtimeRoundHour || EOvertimeHourHalf.FullHour,
      })
    : 0

  // overtimeMinute

  // ? report["overtimeList"]?.reduce(reducerKey("overtimeMinute", 0), 0)
  report['overtimeMinute'] = report['overtimeList']?.length
    ? roundOvertime({
        minute: report['overtimeList']?.reduce(reducerKey('overtimeMinute', 0), 0),
        roundType: overtimeConfig.overtimeRoundType || EOverTimeRoundType.NotRound,
        roundFloor: overtimeConfig.overtimeRoundFloor || EOverTimeRoundFloor.Round,
        type: overtimeConfig.overtimeRoundHour || EOvertimeHourHalf.FullHour,
      })
    : 0

  // leaveDeductMinute
  report['leaveDeductMinute'] = report['leaveDeductList']?.length
    ? (report['leaveDeductList'] as (Leave & LeaveReport)[])?.reduce(leaveMinuteReducer, 0)
    : 0

  // leaveMinute
  report['leaveMinute'] = report['leaveList']?.length
    ? (report['leaveList'] as (Leave & LeaveReport)[])?.reduce(leaveMinuteReducer, 0)
    : 0

  // inLateQuotaUsed
  report['inLateQuotaUsed'] = withRange(report['inDiffMinute'], 0, SCAN_IN_ABSENT_THRESHOLD)
    ? getUsedQuotaMinute(report['inDiffMinute'], inLateQuota)
    : 0

  // outEarlyUsedQuota
  report['outEarlyUsedQuota'] = withRange(report['outDiffMinute'] * -1, 0, SCAN_OUT_ABSENT_THRESHOLD)
    ? getUsedQuotaMinute(report['outDiffMinute'] * -1, outEarlyQuota)
    : 0

  // breakLateUsedQuota
  report['breakLateUsedQuota'] = getUsedQuotaMinute(report['breakDiffMinute'], breakLateQuota)

  // inDiffMinute
  if (report['inDiffMinute'] > SCAN_IN_ABSENT_THRESHOLD) {
    report['inDiffMinute'] = 0
  }

  // outDiffMinute
  if (report['outDiffMinute'] * -1 > SCAN_IN_ABSENT_THRESHOLD) {
    report['outDiffMinute'] = 0
  }

  if (report['outDiffMinute'] >= OVERTIME_THRESHOLD) {
    report['outDiffMinute'] = 0
  }

  return report
}

export const withDefaultReport = (report: any): TimeAttendanceReport => {
  const defaultValue: Partial<TimeAttendanceReport> = {
    leaveList: [],
    leaveMinute: 0,
    leaveDeductList: [],
    leaveDeductMinute: 0,
    overtimeList: [],
    overtimeMinute: 0,
    overtimeApproveList: [],
    overtimeApproveMinute: 0,
    scanIn: null,
    scanOut: null,
    breakDiffMinute: 0,
    inDiffMinute: 0,
    outDiffMinute: 0,
    isLink: true,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    remark: '',
    workingTimeList: [],
    workingMinute: 0,
    shiftVersion: '0',
  }

  Object.keys(defaultValue).forEach((key) => {
    if (report[key] === undefined) {
      report[key] = defaultValue[key as keyof typeof defaultValue]
    }
  })

  if (report.overtimeApproveList.length || (report.dayType !== EDayType.Workday && report.overtimeList.length)) {
    report['Type'] = ETimeAttendanceReportType.Overtime
  }

  if (report.leaveList.length || report.leaveDeductList.length) {
    report['Type'] = ETimeAttendanceReportType.Leave
  }

  if (report.scanIn && !report.scanOut) {
    report['Type'] = ETimeAttendanceReportType.Incomplete
  }

  return report
}

export const getOvertime = ({
  employeeShift,
  scanOut,
  scanIn,
}: {
  employeeShift: ReturnType<typeof getShiftByEmployeeViaDate>
  scanOut: Date | string
  scanIn?: Date | string
}): OvertimeReport[] => {
  const overtimeList: OvertimeReport[] = []

  switch (employeeShift.dayType) {
    // WORKDAY
    case EDayType.Workday: {
      const shiftWorkTimeEnd = employeeShift.shiftWorkTime.endedAt!
      const scanOutDiffMinute = toMinute(new Date(scanOut).getTime() - shiftWorkTimeEnd.getTime())

      if (employeeShift.shiftWorkMinute < 8 * 60) {
        const otDiff = 8 * 60 - employeeShift.shiftWorkMinute
        const outDiff = toMinute(new Date(scanOut).getTime() - shiftWorkTimeEnd!.getTime())

        const startedOtAt = new Date(shiftWorkTimeEnd)
        const endedOtAt = new Date(new Date(shiftWorkTimeEnd).getTime() + otDiff * 60 * 1000)
        overtimeList.push({
          startedAt: startedOtAt,
          endedAt: endedOtAt,
          overtimeMinute: toMinute(endedOtAt.getTime() - startedOtAt.getTime()),
          overtimeType: EOverTimeType.Work,
        })

        if (outDiff > otDiff) {
          pushList(overtimeList, {
            startedAt: endedOtAt,
            endedAt: scanOut,
            overtimeMinute: toMinute(new Date(scanOut).getTime() - endedOtAt.getTime()),
            overtimeType: EOverTimeType.Overtime,
          })
        }
      } else {
        const startedOtAt = new Date(shiftWorkTimeEnd!)
        const otMinute = scanOutDiffMinute

        overtimeList.push({
          startedAt: startedOtAt,
          endedAt: scanOut,
          overtimeMinute: otMinute,
          overtimeType: EOverTimeType.Overtime,
        })
      }
      return overtimeList
    }
    // WEEKEND | HOLIDAY
    default: {
      let otMinute = toMinute(new Date(scanOut).getTime() - new Date(scanIn!).getTime())

      // กฏหมายแรงงาน ทำงาน >= 7 Hr บังคับพักเบรก 1 Hr
      otMinute = otMinute >= 7 ? otMinute - 60 : otMinute

      const flag = Math.ceil(otMinute / 60 / 8)
      // ทำงาน 8 ชั่วโมงแรก overtimeType = WORKDAY และ ที่เหลือ overtimeType = OVERTIME
      const hour = 60 * 60 * 1000
      for (let i = 0; i < flag; i++) {
        overtimeList.push({
          // 8 ชั่วโมงแรก เวลาเริ่ม = scanIn และหลังจาก 8 ชั่วโมง เวลาเริ่ม = 8 ชั่วโมง * i + scanIn
          startedAt: i === 0 ? scanIn : new Date(new Date(scanIn!).getTime() + 8 * hour * (i || 1)).toISOString(),
          // ถ้า OT มากกว่า 1 ขั้น ขั้นแรก scanOut = scanIn + 8 ชั่วโมง หลังจากนั้น scanOut = scanOut
          endedAt:
            i === 0 && flag > 1 ? new Date(new Date(scanIn!).getTime() + 8 * hour * (i || 1)).toISOString() : scanOut,
          overtimeMinute: otMinute >= 8 * 60 ? 8 * 60 : otMinute,
          overtimeType: i === 0 ? EOverTimeType.Work : EOverTimeType.Overtime,
        })
        otMinute -= 8 * 60
      }

      return overtimeList
    }
  }
}

type RoundOvertimeProps = {
  minute: number
  roundType: EOverTimeRoundType
  type?: EOvertimeHourHalf
  roundFloor?: EOverTimeRoundFloor
  OVERTIME_THRESHOLD?: number
}
export const roundOvertime = ({ minute, roundType, type, roundFloor, OVERTIME_THRESHOLD = 60 }: RoundOvertimeProps) => {
  if (minute < OVERTIME_THRESHOLD) return 0

  if (roundType === EOverTimeRoundType.NotRound) return minute

  if (type === EOvertimeHourHalf.FullHour) {
    if (roundFloor === EOverTimeRoundFloor.Floor) {
      return Math.floor(minute / 60) * 60
    }

    return Math.round(minute / 60) * 60
  }
  // HALF
  else if (type === EOvertimeHourHalf.HalfHour) {
    const _h = Math.floor(minute / 60) * 60
    let _m = 0
    if (roundFloor === EOverTimeRoundFloor.Floor) {
      _m = Math.floor((minute % 60) / 30) * 30
      console.log(minute, _m)
    } else {
      _m = Math.round((minute % 60) / 30) * 30
    }

    return _h + _m
  }

  return minute
}

// ------------------------------------ HELPER ----------------------------------------

export const dateFmt = (date: string | Date | number) => {
  const _date: Date = new Date(date)

  return {
    getTime: _date.toLocaleTimeString('en', {
      timeStyle: 'short',
      hour12: false,
      timeZone: 'Asia/Bangkok',
    }),
    getDate: _date.toISOString().split('T')[0],
    getDay: _date.getDay(),
    timeToDate: (time: string, isUTC?: boolean) => {
      // 2020-01-01T09:00Z
      return new Date(`${date}T${time}${isUTC ? 'Z' : '+0700'}`)
    },
  }
}

export const clamp = (num: any, min: any, max: any) => {
  if (min === undefined && max === undefined) return num
  return num < min ? min : num > max ? max : num
}

export const lastItem = <T>(arr: T[]): T => arr[arr.length - 1]

export const pushList = <T>(arr: T[] = [], item: T): T[] => {
  arr.push(item)
  return arr
}
export const toMinute = (ms: number) => ms / 60 / 1000

export const withRange = (current: any, min: any, max: any, eq = true) => {
  return eq ? min <= current && current <= max : min < current && current < max
}

export const deepClone = <T>(value: T): T => JSON.parse(JSON.stringify(value))

export const resolvePath = (object: Record<any, any>, path: string) => path?.split('.').reduce((o, p) => o?.[p], object)

export const reducerKey = (key: any, defaultValue?: any) => {
  return (acc: any, curr: any) => {
    return acc + (defaultValue === undefined ? resolvePath(curr, key) : resolvePath(curr, key) || defaultValue)
  }
}

export const getDateInMonthCalendar = (year: number, month: number, fullCalendar?: boolean) => {
  const _date = new Date()
  _date.setDate(1)
  _date.setUTCFullYear(year)
  _date.setMonth(month - 1)

  const startDate = new Date(_date)
  const endDate = new Date(_date)
  endDate.setMonth(month)
  endDate.setDate(endDate.getDate() - 1)

  if (fullCalendar) {
    startDate.setDate(_date.getDate() - _date.getDay())
    endDate.setDate(endDate.getDate() + (6 - endDate.getDay()))
  }

  const dateList: string[] = []

  for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
    dateList.push(currentDate.toISOString().split('T')[0])
  }

  return dateList
}

export const cleanObject = <T, K extends keyof T>(obj: T, removeKeys?: K[]): Partial<T> => {
  const emptyKeyValue = Object.keys(obj).filter((key) => (obj as Record<any, any>)[key] === undefined && key)
  emptyKeyValue.forEach((key: any) => {
    if (!removeKeys?.length || removeKeys?.includes(key)) delete (obj as Record<any, any>)[key]
  })

  return obj
}
