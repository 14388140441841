import React, { useRef, useState, useEffect } from 'react'
import styles from './index.module.scss'

import { Formik } from 'formik'
import * as yup from 'yup'
import SummarySalaryTable from './table'

const generateMinuteToHours = (minute: number) => {
  return (minute / 60 / 8).toFixed(2)
}

interface ISummaryIncome {
  payrollList?: any
  period?: string
  date?: string
  isShowMoney?: boolean
}

const SummaryIncome: React.FC<ISummaryIncome> = (fcProps: ISummaryIncome): JSX.Element => {
  const [tableHeaderList, setTableHeaderList] = useState<any>(defaultSummaryHeaderList)
  const [sumTableHeaderList, setSumTableHeaderList] = useState<any>([])
  const validationSchema = yup.object({})
  const formikRef: any = useRef(null)
  console.log(fcProps, 'props summaryIncome')
  useEffect(() => {
    // ListPayroll({
    //   yearMonth: '2022-04',
    //   period: '1.1',
    // }).then((res: any) => {
    //   console.log(res.data.data.listPayroll, 'ressss')
    const listPayroll: any = fcProps.payrollList
    if (listPayroll && listPayroll.employeePayrollList.length) {
      const listTableHeader = [
        {
          text: 'ลำดับ',
          key: 'No.',
        },
        {
          text: 'รหัสพนักงาน',
          key: 'employeeID',
        },
        {
          text: 'ชื่อพนักงาน',
          key: 'employeeName',
        },
        {
          text: 'วันมาทำงาน',
          key: 'workDay',
        },
        {
          text: 'วันลาหัก',
          key: 'leaveDay',
        },
        { text: 'วันลาไม่หัก', key: 'leaveDeductMinute' },
        { text: 'วันหยุดโอที', key: 'otTypeOTMinute' },
        { text: 'วันโอที', key: 'otTypeWorkMinute' },
        {
          text: 'เงิน A',
          key: 'income',
          isfontWeightBold: true,
          isEnableShow: true,
        },
        { text: 'โอที C1(+)', key: 'otTypeWorkIncome', isEnableShow: true },
        { text: 'โอที C2(+)', key: 'otTypeOTIncome', isEnableShow: true },
        ...listPayroll.employeePayrollList[0].otherIncomeList.map((element: any) => {
          return { text: element.name, key: element.id, isEnableShow: true }
        }), // รายรับ D(+)
        ...listPayroll.employeePayrollList[0].welfareList.map((element: any) => {
          return { text: element.name, key: element.id, isEnableShow: true }
        }), // สวัสดิการ F(+)
        { text: 'เบี้ยขยัน E(+)', key: 'workDiligentIncome', isEnableShow: true },
        { text: 'เข้าก่อน B(+)', key: 'inEarlyIncome', isEnableShow: true },
        { text: 'พักเร็ว B(+)', key: 'breakEarlyIncome', isEnableShow: true },
        { text: 'ออกสาย B(+)', key: 'outLateIncome', isEnableShow: true },
        { text: 'รวมรายรับ', key: 'netPositiveIncome', isfontWeightBold: true, isEnableShow: true },
        ...listPayroll.employeePayrollList[0].otherExpenseList.map((element: any) => {
          return { text: element.name, key: element.id, isEnableShow: true }
        }), // รายหัก D (-)
        { text: 'เข้าสาย B(-)', key: 'inLateExpense', isEnableShow: true },
        { text: 'พักเกิน B(-)', key: 'breakLateExpense', isEnableShow: true },
        { text: 'ออกก่อน B(-)', key: 'outEarlyExpense', isEnableShow: true },
        { text: 'รวมรายจ่าย', key: 'netNegativeIncome', isfontWeightBold: true, isEnableShow: true },
        {
          text: 'รวมสุทธิ',
          key: 'netIncome',
          bodyBackgroundColor: '#E0E0E0',
          isEnableShow: true,
        },
      ]

      const newSumHeaderTableList = [
        'workDay',
        'leaveDay',
        'leaveDeductMinute',
        'otTypeOTMinute',
        'otTypeWorkMinute',
        'income',
        'otTypeWorkIncome',
        'otTypeOTIncome',
        ...listPayroll.employeePayrollList[0].otherIncomeList.map((element: any) => element.id),
        ...listPayroll.employeePayrollList[0].welfareList.map((element: any) => element.id),
        'workDiligentIncome',
        'inEarlyIncome',
        'breakEarlyIncome',
        'outLateIncome',
        'netPositiveIncome',
        ...listPayroll.employeePayrollList[0].otherExpenseList.map((element: any) => element.id),
        'inLateExpense',
        'breakLateExpense',
        'outEarlyExpense',
        'netNegativeIncome',
        'netIncome',
      ]
      const newValues: any = {}
      listPayroll.employeePayrollList.map((employeeElement: any) => {
        newValues[
          `${
            employeeElement?.employee?.department?.departmentName
              ? employeeElement?.employee?.department?.departmentName
              : 'no department'
          }`
        ] = []
      })
      listPayroll.employeePayrollList.map((employeeElement: any, employeeIndex: number) => {
        const packValue: any = {
          employeeID: employeeElement.employeeID,
          employeeName:
            (employeeElement?.employee?.fullName ? employeeElement?.employee?.fullName : null) +
            ' ' +
            (employeeElement?.employee?.lastName ? employeeElement?.employee?.lastName : null),
          workDay: generateMinuteToHours(employeeElement.workingMinute),
          leaveDay: generateMinuteToHours(employeeElement.leaveMinute),
          leaveDeductMinute: generateMinuteToHours(employeeElement.leaveDeductMinute),
          otTypeOTMinute: generateMinuteToHours(employeeElement.otTypeOTMinute),
          otTypeWorkMinute: generateMinuteToHours(employeeElement.otTypeWorkMinute),
          income: employeeElement.income,
          otTypeWorkIncome: employeeElement.otTypeWorkIncome,
          otTypeOTIncome: employeeElement.otTypeOTIncome,
          // ...employeeElement.otherExpenseList.map((element: any) => {
          //   return { [element.id]: element.amount }
          // }),
          // ...employeeElement.welfareList.map((element: any) => {
          //   return { [element.id]: element.amount }
          // }),
          workDiligentIncome: employeeElement.workDiligentIncome,
          inEarlyIncome: employeeElement.inEarlyIncome,
          breakEarlyIncome: employeeElement.breakEarlyIncome,
          outLateIncome: employeeElement.outLateIncome,
          netPositiveIncome: employeeElement.netPositiveIncome,
          // ...employeeElement.otherIncomeList.map((element: any) => {
          //   return { [element.id]: element.amount }
          // }),
          inLateExpense: employeeElement.inLateExpense,
          breakLateExpense: employeeElement.breakLateExpense,
          outEarlyExpense: employeeElement.outEarlyExpense,
          netNegativeIncome: employeeElement.netNegativeIncome,
          netIncome: employeeElement.netIncome,
        }

        employeeElement.otherExpenseList.map((element: any) => {
          packValue[element.id] = element.amount
        })
        employeeElement.welfareList.map((element: any) => {
          packValue[element.id] = element.amount
        })
        employeeElement.otherIncomeList.map((element: any) => {
          packValue[element.id] = element.amount
        })

        newValues[
          `${
            employeeElement?.employee?.department?.departmentName
              ? employeeElement?.employee?.department?.departmentName
              : 'no department'
          }`
        ].push(packValue)

        // newValues.push(packValue)
      })
      console.log(listTableHeader, 'listTableHeader')
      console.log(newSumHeaderTableList, 'newSumHeaderTableList')
      console.log(newValues, 'newValues')
      setTableHeaderList(listTableHeader)
      setSumTableHeaderList(newSumHeaderTableList)
      formikRef.current.setFieldValue('summary', newValues)
    }

    // })
  }, [fcProps.payrollList])

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        summary: {},
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        console.log(values, 'SUBMITTING')
      }}
    >
      {(props: any) => (
        <form className={styles.container} onSubmit={props.handleSubmit}>
          {/* <InputTextComponent
            _name="searchValues"
            _label="ค้นหาแผนก/รหัส/รายชื่อพนักงาน"
            _helperText={props.errors.searchValues}
          />
          <div className={styles.toolbarsContainer}>
            <article className={styles.downloadFileBtn}>
              <ButtonComponent
                _type="button"
                _colorBG="blue"
                _colorText="white"
                _variant="outlined"
                _isIcon="file"
                _text="ดาวน์โหลดไฟล์"
                _functionOnClick={() => {
                  console.log('button Click !!')
                }}
              />
            </article>
            <article className={styles.filterBtn}>
              <ButtonComponent
                _type="button"
                _colorBG="transparent"
                _colorText="blue"
                _variant="text"
                _isIcon="filter"
                _text="ตัวกรองข้อมูล"
                _functionOnClick={() => {
                  console.log('button Click !!')
                }}
              />
            </article>
          </div> */}
          <SummarySalaryTable
            tableHeaderList={tableHeaderList}
            tableBodyUniqueDepartment={Object.keys(props.values.summary).length ? props.values.summary : null}
            TableSummaryHeaderList={sumTableHeaderList}
            employeeCount={fcProps.payrollList.employeeCount}
            isShowMoney={fcProps.isShowMoney}
          />
        </form>
      )}
    </Formik>
  )
}

export default SummaryIncome

const mockUpSummaryValues = [
  {
    positionName: 'แผนกบัญชี',
    employeeList: [
      {
        employeeID: 'EM001',
        employeeName: 'นางสาวปาริฉัตร',
        workDay: '15',
        leaveDay: '0',
        otDay: '2',
        income: '5400',
        socialSecurityCost: '270',
        reserveFund: '',
        diligent: '500',
        foodCost: '',
        positionValue: '',
        otherExpense: '',
        studentLoan: '885',
        totalIncome: '4745',
      },
      {
        employeeID: 'EM002',
        employeeName: 'นางสุพิศ',
        workDay: '13',
        leaveDay: '2',
        otDay: '3',
        income: '7830',
        socialSecurityCost: '391.5',
        reserveFund: '',
        diligent: '',
        foodCost: '',
        positionValue: '1000',
        otherExpense: '',
        studentLoan: '',
        totalIncome: '8438',
      },
    ],
  },
  {
    positionName: 'แผนกการตลาด',
    employeeList: [
      {
        employeeID: 'EM0019',
        employeeName: 'นายปิยะพงศ์',
        workDay: '15',
        leaveDay: '0',
        otDay: '2',
        income: '5400',
        socialSecurityCost: '270',
        reserveFund: '',
        diligent: '500',
        foodCost: '',
        positionValue: '',
        otherExpense: '',
        studentLoan: '885',
        totalIncome: '4745',
      },
      {
        employeeID: 'EM0020',
        employeeName: 'นางสาวอ้อยใจ',
        workDay: '13',
        leaveDay: '2',
        otDay: '3',
        income: '7830',
        socialSecurityCost: '391.5',
        reserveFund: '',
        diligent: '',
        foodCost: '',
        positionValue: '1000',
        otherExpense: '',
        studentLoan: '',
        totalIncome: '8438',
      },
    ],
  },
]

// const sumTableHeaderList = [
//   'income',
//   'socialSecurityCost',
//   'reserveFund',
//   'diligent',
//   'foodCost',
//   'positionValue',
//   'otherExpense',
//   'studentLoan',
//   'totalIncome',
// ]

const defaultSummaryHeaderList = [
  {
    text: 'ลำดับ',
    key: 'No.',
  },
  {
    text: 'รหัสพนักงาน',
    key: 'employeeID',
  },
  {
    text: 'ชื่อพนักงาน',
    key: 'employeeName',
  },
  {
    text: 'วันมาทำงาน',
    key: 'workDay',
  },
  {
    text: 'วันลาหัก',
    key: 'leaveDay',
  },
  { text: 'วันลาไม่หัก', key: 'leaveDeductMinute' },
  { text: 'วันหยุดโอที', key: 'otTypeOTMinute' },
  { text: 'วันโอที', key: 'otTypeWorkMinute' },
  {
    text: 'เงิน A',
    key: 'income',
    isfontWeightBold: true,
  },
]

const tableSummaryHeaderList = [
  {
    text: 'ลำดับ',
    key: 'No.',
  },
  {
    text: 'รหัสพนักงาน',
    key: 'employeeID',
  },
  {
    text: 'ชื่อพนักงาน',
    key: 'employeeName',
  },
  {
    text: 'วันมาทำงาน',
    key: 'workDay',
  },
  {
    text: 'วันลา',
    key: 'leaveDay',
  },
  {
    text: 'การทำงานล่วง',
    key: 'otDay',
  },
  {
    text: 'รวมเงินเดือน',
    key: 'income',
    isfontWeightBold: true,
  },
  {
    text: 'ประกันสังคม',
    key: 'socialSecurityCost',
  },
  {
    text: 'กองทุนสำรอง',
    key: 'reserveFund',
  },
  {
    text: 'เบี้ยขยัน',
    key: 'diligent',
  },
  {
    text: 'ค่าอาหาร',
    key: 'foodCost',
  },
  {
    text: 'ค่าตำแหน่ง',
    key: 'positionValue',
  },
  {
    text: 'หักกิจ+คชจ',
    key: 'otherExpense',
  },
  {
    text: 'หักเงิน กยศ.',
    key: 'studentLoan',
  },
  {
    text: 'รวมสุทธิ',
    key: 'totalIncome',
    bodyBackgroundColor: '#E0E0E0',
  },
]
