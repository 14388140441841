import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@mui/material'

import styles from './index.module.scss'
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
} from '@mui/material'
import { VisibilityOff, Visibility, Search, Input } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CloseIcon from '@mui/icons-material/Close'
import { useFormikContext } from 'formik'
//Image
// import CloseIcon from '../../image/close.svg'

interface PropsInputText {
  _name: string
  _label: string
  _helperText?: string
  _descriptionText?: string
  _unit?: string
  _disabled?: boolean
  _value?: any
  _onChange?: any
}

export const InputTextAreaComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      InputLabelProps={{
        style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
      }}
      InputProps={{
        style: {
          fontFamily: 'KanitRegular',
          color: 'gray',
          borderRadius: 10,
          backgroundColor: 'white',
        },
      }}
      rows={4}
      multiline
      fullWidth
      size="small"
      id={props._name}
      label={props._label}
      name={props._name}
      value={values[props._name]}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
    />
  )
}
interface PropsInputTextCustom {
  _name: string
  _label?: string
  _helperText?: string
  _descriptionText?: string
  _value?: any
  _sx?: any
  _disabled?: any
}
export const InputTextComponentCustom: React.FC<PropsInputTextCustom> = (props: PropsInputTextCustom): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
      }}
      InputProps={{
        style: {
          color: 'gray',
          borderRadius: 4,
          backgroundColor: 'white',
          fontFamily: 'KanitRegular',
        },
      }}
      fullWidth
      size="small"
      placeholder={props._label}
      id={props._name}
      sx={props._sx}
      label={props._label}
      name={props._name}
      value={props._value}
      onChange={handleChange}
      helperText={<p>{touched[props._name] && errors[props._name] && props._helperText}</p>}
      error={touched[props._name] && Boolean(errors[props._name])}
      disabled={props._disabled}
    />
  )
}
export const InputTextComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
      }}
      InputProps={{
        style: {
          color: 'gray',
          borderRadius: 10,
          backgroundColor: 'white',
          fontFamily: 'KanitRegular',
        },
      }}
      fullWidth
      size="small"
      placeholder={props._label}
      id={props._name}
      label={props._label}
      name={props._name}
      value={values[props._name]}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
    />
  )
}
export const InputTextIconComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white' }}
        error={touched[props._name] && Boolean(errors[props._name])}
      >
        {props._label}
      </InputLabel>
      <OutlinedInput
        sx={{ borderRadius: 3, backgroundColor: 'white', fontFamily: 'KanitRegular' }}
        id={props._name}
        label={props._label}
        name={props._name}
        type="text"
        value={values[props._name]}
        onChange={handleChange}
        disabled={props._disabled}
        error={touched[props._name] && Boolean(errors[props._name])}
        endAdornment={
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        }
      />
      <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
    </FormControl>
  )
}

export const InputTextUnitComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel
        shrink
        htmlFor="outlined-adornment-text"
        sx={{ backgroundColor: 'white', padding: '0 5px', fontFamily: 'KanitRegular' }}
        error={touched[props._name] && Boolean(errors[props._name])}
      >
        {props._label}
      </InputLabel>
      <OutlinedInput
        placeholder={props._label}
        sx={{ borderRadius: 3, backgroundColor: 'white', fontFamily: 'KanitRegular' }}
        id={props._name}
        label={props._label}
        name={props._name}
        type="text"
        value={values[props._name]}
        onChange={handleChange}
        disabled={props._disabled}
        error={touched[props._name] && Boolean(errors[props._name])}
        endAdornment={
          <InputAdornment position="end">
            <span>{props._unit}</span>
          </InputAdornment>
        }
      />
      {!touched[props._name] && !Boolean(errors[props._name]) ? (
        <p className={styles.msg}>{props._descriptionText}</p>
      ) : (
        <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
      )}
    </FormControl>
  )
}

export const InputNumberUnitComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel
        shrink
        htmlFor="outlined-adornment-text"
        sx={{ backgroundColor: 'white', padding: '0 5px', fontFamily: 'KanitRegular' }}
        error={touched[props._name] && Boolean(errors[props._name])}
      >
        {props._label}
      </InputLabel>
      <OutlinedInput
        placeholder={props._label}
        sx={{ borderRadius: 1, backgroundColor: 'white', fontFamily: 'KanitRegular' }}
        id={props._name}
        label={props._label}
        name={props._name}
        type="number"
        value={values[props._name]}
        onChange={handleChange}
        disabled={props._disabled}
        error={touched[props._name] && Boolean(errors[props._name])}
        endAdornment={
          <InputAdornment position="end">
            <span>{props._unit}</span>
          </InputAdornment>
        }
      />
      {!touched[props._name] && !Boolean(errors[props._name]) ? (
        <p className={styles.msg}>{props._descriptionText}</p>
      ) : (
        <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
      )}
    </FormControl>
  )
}
export const InputPasswordComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white' }}
        error={touched[props._name] && Boolean(errors[props._name])}
      >
        {props._label}
      </InputLabel>
      <OutlinedInput
        sx={{ borderRadius: 3, backgroundColor: 'white' }}
        id={props._name}
        label={props._label}
        name={props._name}
        type={showPassword ? 'text' : 'password'}
        value={values[props._name]}
        onChange={handleChange}
        error={touched[props._name] && Boolean(errors[props._name])}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
    </FormControl>
  )
}

export const InputNumberComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: 'KanitRegular' },
      }}
      InputProps={{
        style: {
          fontFamily: 'KanitRegular',
          color: 'gray',
          borderRadius: 10,
        },
      }}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      onKeyPress={(event: any) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault()
        }
        if (event.target.value === '0' && event.key === '0') {
          event.preventDefault()
        }
      }}
      placeholder={props._label}
      fullWidth
      size="small"
      type="number"
      id={props._name}
      label={props._label}
      name={props._name}
      value={values[props._name]}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
    />
  )
}

export const InputNumberComponentCustom: React.FC<PropsInputTextCustom> = (
  props: PropsInputTextCustom,
): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: 'KanitRegular' },
      }}
      InputProps={{
        style: {
          fontFamily: 'KanitRegular',
          color: 'gray',
          borderRadius: 4,
          backgroundColor: '#FFFFFF',
          // height: '30px',
        },
      }}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      onKeyDown={(event: any) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') event.preventDefault()
      }}
      onKeyPress={(event: any) => {
        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
          event.preventDefault()
        }
        if (event.target.value === '0' && event.key === '0') {
          event.preventDefault()
        }
      }}
      placeholder={props._label}
      fullWidth
      size="small"
      type="number"
      sx={props._sx}
      id={props._name}
      label={props._label}
      name={props._name}
      value={props._value}
      disabled={props._disabled}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
    />
  )
}

export const InputNumberComponentCustomTran: React.FC<PropsInputTextCustom> = (
  props: PropsInputTextCustom,
): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      variant="standard"
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: 'KanitRegular', border: 'none' },
      }}
      InputProps={{
        style: {
          fontFamily: 'KanitRegular',
          color: 'black',
          borderRadius: 10,
          border: 'none',
          fontSize: 18,
          fontWeight: 'bold',
        },
      }}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      onKeyDown={(event: any) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') event.preventDefault()
      }}
      onKeyPress={(event: any) => {
        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
          event.preventDefault()
        }
        if (event.target.value === '0' && event.key === '0') {
          event.preventDefault()
        }
      }}
      placeholder={props._label}
      fullWidth
      size="small"
      type="number"
      sx={props._sx}
      id={props._name}
      label={props._label}
      name={props._name}
      value={props._value}
      disabled={props._disabled}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
    />
  )
}

export const InputDateComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <div>
      <TextField
        InputProps={{
          style: {
            fontFamily: 'KanitRegular',
            color: 'gray',
            borderRadius: 4,
          },
        }}
        fullWidth
        size="small"
        type="date"
        disabled={props._disabled}
        id={props._name}
        label={<span>{props._label}</span>}
        name={props._name}
        value={values[props._name]}
        onChange={handleChange}
        helperText={<p>{touched[props._name] && errors[props._name] && props._helperText}</p>}
        error={touched[props._name] && Boolean(errors[props._name])}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: 16, fontFamily: 'KanitRegular' },
        }}
      />
    </div>
  )
}

export const InputDateComponentCustom: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <div>
      <TextField
        InputProps={{
          style: {
            fontFamily: 'KanitRegular',
            color: 'gray',
            borderRadius: 4,
          },
        }}
        fullWidth
        size="small"
        type="date"
        disabled={props._disabled}
        id={props._name}
        label={<span>{props._label}</span>}
        name={props._name}
        value={props._value}
        onChange={props._onChange || handleChange}
        helperText={<p>{touched[props._name] && errors[props._name] && props._helperText}</p>}
        error={touched[props._name] && Boolean(errors[props._name])}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: 16, fontFamily: 'KanitRegular' },
        }}
      />
    </div>
  )
}

interface PropsInputDateCustom {
  _name: string
  _label?: string
  _helperText?: string
  _descriptionText?: string
  _value?: any
  _disabled?: boolean
  _onChange?: any
}
export const InputDateComponentCustome: React.FC<PropsInputDateCustom> = (props: PropsInputDateCustom): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      InputProps={{
        style: {
          fontFamily: 'KanitRegular',
          color: 'gray',
          borderRadius: 4,
        },
      }}
      fullWidth
      size="small"
      type="date"
      id={props._name}
      label={<span>{props._label}</span>}
      name={props._name}
      value={props._value}
      disabled={props._disabled}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
      InputLabelProps={{
        shrink: true,
        style: { fontSize: 16, fontFamily: 'KanitRegular' },
      }}
    />
  )
}

export const InputDateTimeComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      fullWidth
      size="small"
      type="datetime-local"
      id={props._name}
      label={<span>{props._label}</span>}
      name={props._name}
      value={values[props._name]}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        style: {
          borderRadius: 10,
          fontFamily: 'KanitRegular',
        },
      }}
    />
  )
}
export const InputTimeComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      fullWidth
      size="small"
      type="time"
      id={props._name}
      label={<span>{props._label}</span>}
      name={props._name}
      value={values[props._name]}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
      InputLabelProps={{
        shrink: true,
        style: {
          fontFamily: 'KanitRegular',
          color: 'gray',
          borderRadius: 10,
          backgroundColor: 'white',
        },
      }}
      InputProps={{
        style: {
          borderRadius: 10,
          backgroundColor: 'white',
        },
      }}
    />
  )
}

interface PropsInputTimeCustom {
  _name: string
  _label: string
  _helperText?: string
  _value?: any
  _disabled?: boolean
  _default?: any
}
export const InputTimeComponentCustom: React.FC<PropsInputTimeCustom> = (props: PropsInputTimeCustom): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      fullWidth
      size="small"
      disabled={props._disabled}
      type="time"
      id={props._name}
      defaultValue={props._default}
      label={<span>{props._label}</span>}
      name={props._name}
      value={props._value}
      onChange={handleChange}
      helperText={<p>{touched[props._name] && errors[props._name] && props._helperText}</p>}
      error={touched[props._name] && Boolean(errors[props._name])}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        style: {
          borderRadius: 4,
          fontFamily: 'KanitRegular',
        },
      }}
    />
  )
}

interface PropsInputSelectCustom {
  _name: string
  _label: string
  _helperText?: string
  _value?: any
  _menuList?: any
  _text?: any
  _placeholder?: string
  _disabled?: boolean
}
export const InputSelectComponentCustom: React.FC<PropsInputSelectCustom> = (
  props: PropsInputSelectCustom,
): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl fullWidth size="small">
      <InputLabel
        shrink
        id={props._name}
        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white', padding: '0px 5px' }}
      >
        {props._label}
      </InputLabel>
      <Select
        defaultValue={props._name}
        sx={{ borderRadius: 1, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
        labelId={props._name}
        id={props._name}
        placeholder={props._placeholder}
        value={props._value}
        label={props._label}
        onChange={handleChange}
        name={props._name}
        error={touched[props._name] && Boolean(errors[props._name])}
        disabled={props._disabled}
      >
        {props._menuList.map((data: any, index: number) => (
          <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
            {data.label}
          </MenuItem>
        ))}
      </Select>

      <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
    </FormControl>
  )
}

export const InputSelectComponentAddress: React.FC<PropsInputSelectCustom> = (
  props: PropsInputSelectCustom,
): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl fullWidth size="small">
      <InputLabel
        shrink
        id={props._name}
        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white', padding: '0px 5px' }}
      >
        {props._label}
      </InputLabel>
      <Select
        defaultValue={props._name}
        sx={{ borderRadius: 3, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
        labelId={props._name}
        id={props._name}
        placeholder={props._placeholder}
        value={props._value}
        label={props._label}
        onChange={handleChange}
        name={props._name}
        error={touched[props._name] && Boolean(errors[props._name])}
        disabled={props._disabled}
      >
        {props._menuList.map((data: any, index: number) => (
          <MenuItem key={index} value={data.province} sx={{ fontFamily: 'KanitRegular' }}>
            {data.province}
          </MenuItem>
        ))}
      </Select>

      <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
    </FormControl>
  )
}

interface PropsInputSelect {
  _name: string
  _label: string
  _helperText?: string
  _menuList: any[]
  _style?: any
}

export const InputSelectComponent: React.FC<PropsInputSelect> = (props: PropsInputSelect): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()
  return (
    <FormControl fullWidth size="small" style={props._style}>
      <InputLabel
        shrink
        id={props._name}
        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white', padding: '0px 5px' }}
      >
        {props._label}
      </InputLabel>
      <Select
        defaultValue={props._name}
        sx={{ borderRadius: 3, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
        labelId={props._name}
        id={props._name}
        value={values[props._name]}
        label={props._label}
        onChange={handleChange}
        name={props._name}
        error={touched[props._name] && Boolean(errors[props._name])}
      >
        {props._menuList.map((data: any, index: number) => (
          <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
            {data.label}
          </MenuItem>
        ))}
      </Select>

      <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
    </FormControl>
  )
}

export const InputSwitchComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, handleChange } = useFormikContext<any>()

  return (
    <FormControlLabel
      control={
        <Switch
          id={props._name}
          name={props._name}
          value={values[props._name]}
          onChange={handleChange}
          defaultChecked={values[props._name]}
        />
      }
      label={props._label}
    />
  )
}

interface PropsInputCheckbox {
  _label?: any
  _name: string
  _style?: any
  _labelStyle?: any
}

export const InputCheckboxComponent: React.FC<PropsInputCheckbox> = (props: PropsInputCheckbox): JSX.Element => {
  const { values, handleChange } = useFormikContext<any>()
  return (
    <FormControlLabel
      style={props._style}
      control={
        <Checkbox
          id={props._name}
          name={props._name}
          onChange={handleChange}
          checked={values[props._name]}
          defaultChecked={values[props._name]}
          sx={{
            color: '#5357BB',
            fontFamily: 'KanitRegular',

            '&.Mui-checked': {
              color: '#5357BB',
            },
          }}
        />
      }
      label={<span style={props._labelStyle}>{props._label}</span>}
    />
  )
}

interface PropsInputCheckboxCustom {
  _label?: any
  _name: string
  _style?: any
  _value?: any
}

export const InputCheckboxComponentCustom: React.FC<PropsInputCheckboxCustom> = (
  props: PropsInputCheckboxCustom,
): JSX.Element => {
  const { values, handleChange } = useFormikContext<any>()

  return (
    <FormControlLabel
      style={props._style}
      control={
        <Checkbox
          id={props._name}
          name={props._name}
          onChange={handleChange}
          // value={true}
          checked={props._value}
          defaultChecked={props._value}
          sx={{
            color: '#5357BB',

            '&.Mui-checked': {
              color: '#5357BB',
            },
          }}
        />
      }
      label={<span>{props._label}</span>}
    />
  )
}

interface PropsInputRadio {
  _name: string
  _menuList: any[]
  _helperText?: string
  _row?: boolean
}
interface PropsInputCheckbox {
  _label?: any
  _name: string
  _style?: any
}

export const InputRadioComponent: React.FC<PropsInputRadio> = (props: PropsInputRadio): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl component="fieldset">
      <RadioGroup
        row={props._row}
        aria-label={props._name}
        id={props._name}
        name={props._name}
        value={values[props._name]}
        onChange={handleChange}
        style={{ display: 'flex' }}
      >
        {props._menuList.map((data: any, index: number) => (
          <FormControlLabel
            key={index}
            value={data.value}
            control={
              <Radio
                sx={{
                  color: '#5357BB',
                  '&.Mui-checked': {
                    color: '#5357BB',
                  },
                }}
              />
            }
            label={<span>{data.label}</span>}
          />
        ))}
      </RadioGroup>
      <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
    </FormControl>
  )
}

interface PropsInputend {
  _name: string
  _label: string
  _helperText?: string
  _labelend: string
}

export const InputendComponent: React.FC<PropsInputend> = (props: PropsInputend): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white' }}
        error={touched[props._name] && Boolean(errors[props._name])}
      >
        {props._label}
      </InputLabel>
      <OutlinedInput
        sx={{ borderRadius: 3, backgroundColor: 'white' }}
        id={props._name}
        label={props._label}
        name={props._name}
        value={values[props._name]}
        onChange={handleChange}
        error={touched[props._name] && Boolean(errors[props._name])}
        endAdornment={<InputAdornment position="end">{props._labelend}</InputAdornment>}
      />
      <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
    </FormControl>
  )
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
interface PropsAutocomplete {
  _name: string
  _label: string
  _option: any[]
  _placeholder?: string
  _style?: any
}
//todo > Custom
export const AutocompleteComponent: React.FC<PropsAutocomplete> = (props: PropsAutocomplete): JSX.Element => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<any>()
  return (
    <Autocomplete
      multiple
      id={props._name}
      options={props._option}
      value={values[props._name]}
      style={{ ...props._style, width: 500, borderRadius: 3 }}
      onChange={(event, value, reason, details) => {
        console.log('details select', details)
        console.log('option select', details?.option.title)
        console.log('option select', details?.option.value)
        if (reason === 'selectOption') {
          // Add
          setFieldValue(props._name, [...values[props._name], details?.option], false)
        } else if (reason === 'removeOption') {
          // Remove
          const editArr = values[props._name].filter((value: any, index: any) => {
            return details?.option.title !== value.title
          })
          setFieldValue(props._name, editArr, false)
        } else if (reason === 'clear') {
          // Clear
          setFieldValue(props._name, [], false)
        }
      }}
      disableCloseOnSelect
      getOptionLabel={(_option) => _option.title}
      renderOption={(props, _option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            sx={{
              color: '#5357BB',
              '&.Mui-checked': {
                color: '#5357BB',
              },
            }}
            checked={selected}
          />
          {_option.title}
        </li>
      )}
      renderInput={({ InputLabelProps, InputProps, ...params }) => (
        <TextField
          InputLabelProps={{
            shrink: true,
            style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
            ...InputLabelProps,
          }}
          InputProps={{ style: { height: '100%', padding: 0 }, ...InputProps }}
          {...params}
          label={<span>{props._label}</span>}
          placeholder={props._placeholder}
        />
      )}
    />
  )
}

interface PropsAutocompleteCustom {
  _name: string
  _label: string
  _option: any[]
  _placeholder?: string
  _style?: any
  _value?: any
}
export const AutocompleteComponentCustom: React.FC<PropsAutocompleteCustom> = (
  props: PropsAutocompleteCustom,
): JSX.Element => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<any>()
  return (
    <Autocomplete
      multiple
      id={props._name}
      options={props._option}
      value={props._value}
      style={{ ...props._style, width: 500, borderRadius: 3 }}
      onChange={(event, value, reason, details) => {
        // console.log('details select', details)
        // console.log('option select', details?.option.title)
        // console.log('option select', details?.option.value)
        if (reason === 'selectOption') {
          // Add
          console.log(props._value, 'props._value')
          setFieldValue(props._name, [...props._value, details?.option], false)
        } else if (reason === 'removeOption') {
          // Remove
          const editArr = props._value.filter((value: any, index: any) => {
            return details?.option.title !== value.title
          })
          setFieldValue(props._name, editArr, false)
        } else if (reason === 'clear') {
          // Clear
          setFieldValue(props._name, [], false)
        }
      }}
      disableCloseOnSelect
      getOptionLabel={(_option) => _option.title}
      renderOption={(props, _option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            sx={{
              color: '#5357BB',
              '&.Mui-checked': {
                color: '#5357BB',
              },
            }}
            checked={selected}
          />
          {_option.title}
        </li>
      )}
      renderInput={({ InputLabelProps, InputProps, ...params }) => (
        <TextField
          InputLabelProps={{
            shrink: true,
            style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
            ...InputLabelProps,
          }}
          InputProps={{ style: { height: '100%', padding: 0 }, ...InputProps }}
          {...params}
          label={<span>{props._label}</span>}
          placeholder={props._placeholder}
        />
      )}
    />
  )
}

interface PropsAutoIconcomplete {
  _name: string
  _label: string
  _option: any[]
  _placeholder: string
  _isIcon?: boolean
}
export const AutocompleteIconComponent: React.FC<PropsAutoIconcomplete> = (
  props: PropsAutoIconcomplete,
): JSX.Element => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<any>()

  return (
    <Autocomplete
      multiple
      value={values[props._name]}
      onChange={(event, value, reason, details) => {
        console.log()
        if (reason === 'selectOption') {
          // Add
          setFieldValue(props._name, [...values[props._name], details?.option], false)
        } else if (reason === 'removeOption') {
          // Remove
          const editArr = values[props._name].filter((value: any, index: any) => {
            return details?.option.title !== value.title
          })
          setFieldValue(props._name, editArr, false)
        } else if (reason === 'clear') {
          // Clear
          setFieldValue(props._name, [], false)
        }
      }}
      limitTags={2}
      id={props._name}
      options={props._option}
      getOptionLabel={(option) => option.title}
      renderTags={(options) => {
        return options.map((option) => (
          <Fragment key={option.title}>
            {option.img}
            <p className={styles.showtext}>{option.title}</p>
          </Fragment>
        ))
      }}
      renderOption={(props, _option) => (
        <li {...props}>
          {_option.img}

          <div className={styles.textWrapper}>
            <p className={styles.title}> {_option.title}</p>
            <p className={styles.subtitle}> {_option.subtitle}</p>

            <button type="button" className={styles.btnClose} onClick={() => console.log('Delete')}>
              <CloseIcon color="secondary" />
            </button>
          </div>
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={props._label} placeholder={props._placeholder} />}
    />
  )
}
