import * as React from 'react'
//Styles
import styles from './index.module.scss'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { FormControl, OutlinedInput, InputAdornment } from '@mui/material'

interface PropsTableLeave {
  data?: any
}
export const TableLeave: React.FC<PropsTableLeave> = (props: PropsTableLeave): JSX.Element => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bold',
      fontFamily: 'KanitRegular',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: 'KanitRegular',
      lineHeight: 1,
      padding: '10px 15px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
      // pading: 0,
    },
  }))

  return (
    <TableContainer sx={{ height: '350px' }} component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">วันเริ่มต้น</StyledTableCell>
            <StyledTableCell align="center">วันสิ้นสุด</StyledTableCell>
            <StyledTableCell align="center">หมายเหตุ</StyledTableCell>
            <StyledTableCell align="center">สถานะ</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: any) => (
            <StyledTableRow key={row.dateStart}>
              <StyledTableCell align="center">{row.dateStart}</StyledTableCell>
              <StyledTableCell align="center">{row.dateEnd}</StyledTableCell>
              <StyledTableCell align="center">{row.annotation}</StyledTableCell>
              <StyledTableCell align="center">
                <p
                  className={
                    row.status === 'Approve'
                      ? styles.statusApprove
                      : row.status === 'Pending'
                      ? styles.statusPending
                      : styles.statusReject
                  }
                >
                  {row.status}
                </p>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const TableLeaveMiss: React.FC<PropsTableLeave> = (props: PropsTableLeave): JSX.Element => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bold',
      fontFamily: 'KanitRegular',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: 'KanitRegular',
      lineHeight: 1,
      padding: '10px 15px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
      // pading: 0,
    },
  }))
  // console.log('data >> Props TableLeaveMiss', props.data)
  return (
    <TableContainer sx={{ height: '350px' }} component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">วันเริ่มต้น</StyledTableCell>
            <StyledTableCell align="left">วันสิ้นสุด</StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: any) => (
            <StyledTableRow key={row.dateStart}>
              <StyledTableCell align="left">{row.dateStart}</StyledTableCell>
              <StyledTableCell align="left">{row.dateEnd}</StyledTableCell>
              <StyledTableCell align="left">{row.annotation}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface PropsTableLate {
  data?: any
}
export const TableLate: React.FC<PropsTableLate> = (props: PropsTableLate): JSX.Element => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      fontWeight: 'bold',
      fontFamily: 'KanitRegular',
      color: '#626262',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: 'KanitRegular',
      lineHeight: 1,
      padding: '15px 15px',
      color: '#626262',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  return (
    <TableContainer sx={{ height: '350px' }} component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">วันที่</StyledTableCell>
            <StyledTableCell align="center">เวลาเข้างาน</StyledTableCell>
            <StyledTableCell align="right">สาย (นาที)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: any) => (
            <StyledTableRow key={row.dateStart}>
              <StyledTableCell align="left">{row.date}</StyledTableCell>
              <StyledTableCell align="center">{row.timeCheckIn}</StyledTableCell>
              <StyledTableCell sx={{ minWidth: 180 }} align="right">
                {row.late}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface PropsTableOverTime {
  data?: any
}
export const TableOverTime: React.FC<PropsTableOverTime> = (props: PropsTableOverTime): JSX.Element => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      fontWeight: 'bold',
      fontFamily: 'KanitRegular',
      color: '#626262',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: 'KanitRegular',
      lineHeight: 1,
      padding: '15px 15px',
      color: '#626262',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  return (
    <TableContainer sx={{ height: '350px' }} component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">วันเริ่มต้น</StyledTableCell>
            <StyledTableCell align="left">วันสิ้นสุด</StyledTableCell>
            <StyledTableCell align="right">ล่วงเวลา (ชั่วโมง)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: any, index: number) => {
            return (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="left">{row.dateStart}</StyledTableCell>
                <StyledTableCell align="left">{row.dateEnd}</StyledTableCell>
                <StyledTableCell sx={{ minWidth: 180 }} align="right">
                  <FormControl sx={{ m: 0, width: 80 }} variant="outlined">
                    <OutlinedInput
                      size="small"
                      type="number"
                      id="outlined-adornment-weight"
                      value={row.overTime}
                      onChange={(e: any) => e.event.target.value}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                </StyledTableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface PropsTableWorkingTime {
  data?: any
}
export const TableWorkingTime: React.FC<PropsTableWorkingTime> = (props: PropsTableWorkingTime): JSX.Element => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      fontWeight: 'bold',
      fontFamily: 'KanitRegular',
      color: '#626262',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: 'KanitRegular',
      lineHeight: 1,
      padding: '5px 15px',
      color: '#626262',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  return (
    <TableContainer sx={{ height: '350px' }} component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">วันเริ่มต้น</StyledTableCell>
            <StyledTableCell align="left">วันสิ้นสุด</StyledTableCell>
            <StyledTableCell align="right">ชั่วโมงทำงาน</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: any, index: number) => {
            return (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="left">{row.dateStart}</StyledTableCell>
                <StyledTableCell align="left">{row.dateEnd}</StyledTableCell>
                <StyledTableCell sx={{ minWidth: 180 }} align="right">
                  <FormControl sx={{ m: 0, width: 80 }} variant="outlined">
                    <OutlinedInput
                      size="small"
                      type="number"
                      id="outlined-adornment-weight"
                      value={row.workingTime}
                      onChange={(e: any) => e.target.value}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                </StyledTableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface PropsTableCompensation {
  data?: any
}
export const TableCompensation: React.FC<PropsTableLeave> = (props: PropsTableLeave): JSX.Element => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bold',
      fontFamily: 'KanitRegular',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: 'KanitRegular',
      lineHeight: 1,
      padding: '10px 15px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
      // pading: 0,
    },
  }))

  return (
    <TableContainer sx={{ height: '350px' }} component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">รายการ</StyledTableCell>
            <StyledTableCell align="center">ปรเภทสวัสดิการ</StyledTableCell>
            <StyledTableCell align="center">ใช้จริง</StyledTableCell>
            <StyledTableCell align="center">จ่ายจริง</StyledTableCell>
            <StyledTableCell align="center">สถานะ</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: any) => (
            <StyledTableRow key={row.dateStart}>
              <StyledTableCell align="center">{row.dateStart}</StyledTableCell>
              <StyledTableCell align="center">{row.dateEnd}</StyledTableCell>
              <StyledTableCell align="center">{row.annotation}</StyledTableCell>
              <StyledTableCell align="center">{row.annotation}</StyledTableCell>
              <StyledTableCell align="center">
                <p
                  className={
                    row.status === 'Approve'
                      ? styles.statusApprove
                      : row.status === 'Pending'
                      ? styles.statusPending
                      : styles.statusReject
                  }
                >
                  {row.status}
                </p>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
