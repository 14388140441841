import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import 'react-calendar/dist/Calendar.css'
import Next from '../../image/nextCalendar.svg'
import Back from '../../image/backCalendar.svg'
import moment from 'moment'
import { Formik } from 'formik'
import { InputTextComponent, InputTextComponentCustom } from '../InputsFormik'
import ButtonComponent from '../Button'
import axios from 'axios'

const CalenderComponent: React.FC<any> = (props: any): JSX.Element => {
  // const [year, setYear] = useState<number>(new Date().getFullYear())

  const WeekDay: any[] = [
    { Fullname: 'Sunday', name: 'SUN' },
    { Fullname: 'Monday', name: 'MON' },
    { Fullname: 'Tuesday', name: 'TUE' },
    { Fullname: 'Wednesday', name: 'WED' },
    { Fullname: 'Thursday', name: 'THU' },
    { Fullname: 'Friday', name: 'FRI' },
    { Fullname: 'Saturday', name: 'SAT' },
  ]

  const months: any[] = [
    { month: 'January' },
    { month: 'Febuary' },
    { month: 'March' },
    { month: 'April' },
    { month: 'May' },
    { month: 'June' },
    { month: 'July' },
    { month: 'August' },
    { month: 'September' },
    { month: 'October' },
    { month: 'November' },
    { month: 'December' },
  ]

  const sumWeeks = (monthIndex: number) => {
    const value = moment().set('month', monthIndex).set('year', props.year)
    const startDay = value.clone().startOf('month').startOf('week')
    const endDay = value.clone().endOf('month').endOf('week')
    const Day = startDay.clone().subtract(1, 'day')
    const Week = []
    while (Day.isBefore(endDay, 'day')) {
      Week.push(
        Array(7)
          .fill(0)
          .map(() => Day.add(1, 'day').clone()),
      )
    }
    return Week
  }
  const [open, setOpen] = useState(false)
  // console.log(props.values, 'holidayYear')
  return (
    <div>
      <div className={styles.calender}>
        <div className={styles.header}>
          <img src={Back} alt="" onClick={() => props.setYear(props.year - 1)} className={styles.logo} />
          <p>{props.year}</p>
          <img src={Next} alt="" onClick={() => props.setYear(props.year + 1)} className={styles.logo} />
        </div>
        {}{' '}
        <div className={styles.calenderCard}>
          {months.map((itemCalendar: any, monthIndex: number) => (
            <div key={monthIndex} className={styles.cardMonth}>
              <p className={styles.monthName}> {itemCalendar.month} </p>
              <div className={styles.dayArea}> </div>

              <div className={styles.dateArea}>
                {WeekDay.map((weekday: any, Weekindex: number) => (
                  <div key={Weekindex}>
                    <p className={styles.textday}>{weekday.name}</p>
                  </div>
                ))}
                {sumWeeks(monthIndex).map((item: any) => {
                  return item.map((day: any, dayIndex: number) => (
                    <div
                      key={dayIndex}
                      className={(new Date(day).getMonth() === monthIndex) === false ? styles.disableDay : ''}
                    >
                      <p
                        className={
                          props.values.holidayYear.find((e: any) => e.date === moment(day).format('YYYY-MM-DD')) &&
                          (new Date(day).getMonth() === monthIndex) === true
                            ? styles.holidaySelect
                            : moment(day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
                            ? styles.today
                            : ''
                        }
                        onClick={() => {
                          setOpen(true)
                          if (props.values.holidayYear.find((e: any) => e.date === moment(day).format('YYYY-MM-DD'))) {
                            props.values.holidayYear = props.values.holidayYear.filter(
                              (e: any) => e.date !== moment(day).format('YYYY-MM-DD'),
                            )
                          } else {
                            {
                              props.values.holidayYear.push({
                                date: moment(day).format('YYYY-MM-DD'),
                                description: '',
                              })
                            }
                          }
                          props.setFieldValue('holidayYear', props.values.holidayYear)
                          console.log(props.values.holidayYear, 'values')
                        }}
                      >
                        {day.format('D').toString()}
                      </p>
                    </div>
                  ))
                })}
              </div>
            </div>
          ))}
        </div>
        <section className={styles.FooterCalenDar}>
          <div className={styles.btReset}>
            <ButtonComponent
              _colorBG="white"
              _colorText="red"
              _text="ล้างค่า"
              _variant="outlined"
              _type="button"
              _functionOnClick={() => props.setFieldValue('holidayYear', [])}
            />
          </div>
        </section>
      </div>
      <div className={styles.SumHoliday}>
        {props.values.holidayYear.length > 0 ? (
          <p className={styles.textSum}>จำนวนทั้งหมด {props.values.holidayYear.length} วัน</p>
        ) : (
          ''
        )}
      </div>
      <div className={styles.groupInput}>
        {props.values.holidayYear.map((item: any, ListIndex: number) => (
          <div className={styles.GrpupList} key={ListIndex}>
            <div className={styles.InputHoliday}>
              <p>{ListIndex + 1}.</p>
              <p>
                {new Date(item.date).toLocaleDateString('th-TH', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>

              <InputTextComponentCustom
                _name={`holidayYear[${ListIndex}].description`}
                _label="รายละเอียด"
                _value={item.description}
              />
            </div>
            <div className={styles.line}></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CalenderComponent
