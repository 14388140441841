export const queryGetTimeAttendanceStreamCount = `query GetTimeAttendanceReportStreamCount {
  getTimeAttendanceReportStreamCount {
    logTotal
    logProcessed
  }
}`

export const listTimeAttendanceReport = `
query Query($filter: ModelTimeAttendanceReportFilterInput, $sortDirection: ModelSortDirection, $limit: Int, $nextToken: String) {
  listTimeAttendanceReportTypeAttendance(filter: $filter, sortDirection: $sortDirection, limit: $limit, nextToken: $nextToken) {
    items {
      employeeID
      employee {
        id
        fullName
        lastName
        nickName
      }
      date
      scanIn
      scanOut
      lateMinute
    }
    nextToken
  }
}`

export const listTimeAttendanceReportTypeLeave = `
query Query($filter: ModelTimeAttendanceReportFilterInput, $sortDirection: ModelSortDirection, $limit: Int, $nextToken: String) {
  listTimeAttendanceReportTypeLeave(filter: $filter, sortDirection: $sortDirection, limit: $limit, nextToken: $nextToken) {
  items {
      employeeID
      employee {
        id
        fullName
        lastName
        nickName
      }
      date
      scanIn
      scanOut
      leaveForm
      leaveType
      remark
      leaveMinute
    }
    nextToken 
  }
}`

export const listTimeAttendanceReportTypeOT = `
query Query($filter: ModelTimeAttendanceReportFilterInput, $sortDirection: ModelSortDirection, $limit: Int, $nextToken: String) {
  listTimeAttendanceReportTypeOT(filter: $filter, sortDirection: $sortDirection, limit: $limit, nextToken: $nextToken) {
    items {
      employeeID
       employee {
         id
         fullName
         lastName
         nickName
       }
      date
      scanIn
      scanOut
      overtimeType
      dayType
      remark
      overtimeMinute
    }
    nextToken
  }
}`

//Department
export const listDepartment = `
query ListDepartment {
  listDepartment {
    items {
      id
      departmentName
      listEmployee {
        items {
          id
        }
      }
    }
  }
}
`
//! recheck
export const listDepartmentTable = ` 
query ListDepartment {
  listDepartment {
    items {
        id
        departmentName
        listEmployee {
          items {
            id
            fullName
            lastName
            status
            }
        }
    }
  }
}
`
export const getDepartmant = `
query Query($getDepartmentId: ID!) {
  getDepartment(id: $getDepartmentId) {
    id
    departmentName
    regulationWorkAttendance
    regulationLeave
    regulationCalendar
    regulationOverTime
    departmentDetail
  }
}
`

//Employee team
export const listTeamEmployee = `
query ListTeam {
  listTeam {
    items {  

      id
      teamName
      listMemberID

    }
  }
}
`
export const listTeamEmployeeTable = `
query Query {
  listTeam {
    items {
      teamName
      id
      teamLeader {
        id
        fullName
        lastName
        status
        position {
          positionName
          positionLevel
        }
      }
      listMember {
        items {
          getEmployee {
            id
            fullName
            lastName
            status
            position {
              positionName
              positionLevel
            }
          }
        }
      }
    }
  }
}
`
export const getTeam = `
query Query($getTeamId: ID!) {
  getTeam(id: $getTeamId) {
    id
    teamName
    teamLeader {
      id
      fullName
      lastName
      position {
        positionName
        positionLevel
      }
      profileImage
    }
    listMember {
      items {
        getEmployee {
          id
          fullName
          lastName
          profileImage
          position {
            positionName
            positionLevel
          }
        }
      }
    }
  }
}
`
// Position
export const listPosition = `
query ListTeam {
  listPosition {
    items {
      id
      positionName
      positionLevel
      remark
      listEmployee {
        items {
          id
        }
      }
    }
  }
}
`
export const getPosition = `
query Query($getPositionId: ID!) {
  getPosition(id: $getPositionId) {
    id
    positionName
    positionLevel
    remark
  }
}
`
//* Approve
export const listApprove = `
query Approve_list($filter: ModelApproveFilterInput) {
  listApprove(filter: $filter) {
    items {
      employeeID
      employee {
        id
        fullName
        lastName
        department {
          departmentName
        }
      }   
      Type
      status
      id

      timestamp
      statusTimeStamp

      approveBy

      createdAt
      updatedAt



      
      ... on ApproveLeave {
        leaveType
        leaveForm
        leaveFormHalfDay
        startedAt
        endedAt
        date
        leaveMinute
        leaveFormHalfDay
        isLeaveDeductMoney
        leaveDocumentAttachment
        remark
      }

      ... on ApproveOvertime
       {
        overtimeType
        date
        startedAt
        overtimeMinute
        endedAt
      }


      
    }
  }
}
`

//! ApproveAttendance
export const listApproveAttendance = `
query Items($filter: ModelApproveAttendanceFilterInput) {
  listApproveAttendance(filter: $filter) {
    items {
      id
      date
      createdAt
      startedAt
      endedAt
      status
      listLeave {
        startedAt
        endedAt
        remark
        status
        leaveType
        leaveFormHour
      }
      listAttendanceReport {
        lateMinute
        date
        scanIn
        scanOut
        Type
      }
      listOvertime {
        overtimeHour
        startedAt
        endedAt
      }
      listCompensation {
        compensationUsed
        compensationPaid
        compensationType
        compensationName
        status
      }
      employee {
        id
        fullName
        lastName
        position {
          positionName
        }
        employeeType
      }
    }
  }
}
`

//*Payment
export const listPayment = `
query Query {
  listPayment {
    items {
      id
      date
      createdAt
      paymentName
      status
      employee {
        id
        fullName
        lastName
        bankAccountNumber
        bankPaymentAmount
      }
    }
  }
}
`

export const listShift = `
query ListShift {
  listShift {
    getDefaultShift
    items {
      shiftID
      workDayPolicy {
        dayList
        timeList {
          startTime
          endTime
          isScanEnd
          isScanEnd
        }
      }
      breakPolicy {
        dayList
        timeList {
          startTime
          endTime
          isScanEnd
          isScanEnd
        }
      }
      employeeType
      shiftName
    }
  }
}`

export const listShiftColor = `
query ListShiftColor {
  listShiftColor {
    hexColorList
  }
}`

export const getShift = `
query GetShift($shiftId: ID!) {
  getShift(shiftID: $shiftId) {
    shiftID
    shiftName
    employeeType
    workDayPolicy {
      dayList
      totalHours
      timeList {
        isScanStart
        isScanEnd
        startTime
        endTime
      }
    }
    breakPolicy {
      dayList
      totalHours
      timeList {
        isScanStart
        isScanEnd
        startTime
        endTime
      }
    }
    workType
    shiftColorIndex
    shiftColor
  }
}`
//Holiday Calendar
export const queryListCalendar = `
query Query {
  listHoliday {
    getDefaultHoliday
    items {
      holidayID
      holidayName
      holidayYear
    }
    nextToken
    startedAt
    getDefaultHoliday
  }
}
`

//GetHoliday
export const queryGetHoliday = `
query Holiday_get($holidayId: ID!, $year: Int!) {
  getHoliday(holidayID: $holidayId, year: $year) {
    holidayID
    holidayName
    holidayYear
  }
}
`

//attendance
export const querylistAttendanceLog = `
query Query($nextToken: String, $sortDirection: ModelSortDirection) {
  listTimeAttendance(nextToken: $nextToken, sortDirection: $sortDirection) {
    items {
      employeeID
      timeID
      scanAt
      scanReason
      scanType
      locationType
      lat
      long
      imageUrl
      employee {
        fullName
        lastName
      }
    }
    nextToken
  }
}
`

export const queryListAttendanceReport = `
query REPORT_IMPORTANT($startDate: AWSDate!, $endDate: AWSDate!, $filter: ModelTimeAttendanceReportFilterInput, $nextToken: String) {
  listTimeAttendanceReportGroupByEmployee(startDate: $startDate, endDate: $endDate, filter: $filter, nextToken: $nextToken) {

    items {
      employeeID
  
      employee {
        id
        fullName
        lastName
        nickName
        department {
          departmentName
        }
      }

      items {

        employeeID
        dayType

        shiftName
        shiftStartTime
        shiftEndTime
        shiftVersion
        shiftMinute

        Type
        date

        scanIn
        inDiffMinute

        scanOut
        outDiffMinute

        inDiffMinute
        breakDiffMinute
        outDiffMinute
        
        workingMinute
        workingTimeList {
          startedAt
          endedAt
          workingMinute
        }

        overtimeMinute
        overtimeList {
          startedAt
          endedAt
          overtimeMinute
          overtimeType
          locationType
          status
        }

      leaveMinute
      leaveList {
        startedAt
        endedAt
        leaveMinute
        leaveType
        leaveForm
        leaveDocumentAttachment
        status
      }
      overtimeApproveList {
        startedAt
        endedAt
        overtimeMinute
        overtimeType
        locationType
        status
        overtimeApproveMinute
        overtimeRequestID
      }
      overtimeApproveMinute
      breakTimeList {
        startTime
        endTime
        startedAt
        endedAt
      }

      leaveDeductMinute
      leaveDeductList {
        startedAt
        endedAt
        leaveMinute
        leaveType
        leaveForm
        leaveDocumentAttachment
        status
      }

        isLink
        remark

      }
      
    }
    nextToken
  }
}
`

//ListPayroll
export const queryListPayroll = `
query Query($yearMonth: String!, $period: String, $nextToken: String) {
  listPayroll(yearMonth: $yearMonth, period: $period, nextToken: $nextToken) {
    yearMonth
    period
    employeeCount
    employeeTypeCount {
      FULLTIME_MONTH
      FULLTIME_DAY
      PARTTIME
      OUTSOURCE
    }
    employeeHireCount
    employeeRetireCount
    status
    createdAt
    updatedAt
    paySlipEmpPeriod
    periodList {
      period
      status
      paySlipEmpPeriod
      closeDate
    }
    totalPayroll
    closeDate
    nextToken
    employeePayrollList {
      employeeID
      
      leaveMinute
      paySlipURL
      employeePeriod
      paymentID
      daysInPeriod
      employee {
        id
        positionID
        departmentID
        nameTitle
        fullName
        lastName
        department {
          departmentName
        }
      }
      netIncome
      netNegativeIncome
      outEarlyExpense
      breakLateExpense
      inLateExpense
      otherExpenseList {
        id
        name
        incomeOrExpense
        incomeExpenseType
        variableOrFixed
        income40Type
        amount
      }
      netPositiveIncome
      outLateIncome
      breakEarlyIncome
      inEarlyIncome
      workDiligentIncome
      welfareList {
        id
        name
        income40Type
        amount
      }
      leaveDeductMinute
      otTypeWorkMinute
      otTypeOTMinute
      income
      otTypeOTIncome
      otherIncomeList {
        id
        name
        incomeOrExpense
        incomeExpenseType
        variableOrFixed
        income40Type
        amount
      }
      otTypeWorkIncome
      workingMinute
    }
    timeAttendanceReportList {
      employeeID
      employee {
        id
        fullName
        lastName
        department {
          id
          departmentName
        }
      }
      items {
        companyID
        date
        employeeID
        createdAt
        dayType
        Type
        updatedAt
        isLink
        remark
        shiftName
        shiftStartTime
        shiftEndTime
        shiftVersion
        shiftMinute
        scanIn
        scanOut
        inDiffMinute
        outDiffMinute
        breakDiffMinute
        workingTimeList {
          startedAt
          endedAt
          workingMinute
        }
        workingMinute
        overtimeList {
          startedAt
          endedAt
          overtimeMinute
          overtimeType
          locationType
          status
          overtimeApproveMinute
          overtimeRequestID
        }
        overtimeMinute
        overtimeApproveList {
          startedAt
          endedAt
          overtimeMinute
          overtimeType
          locationType
          status
          overtimeApproveMinute
          overtimeRequestID
        }
        overtimeApproveMinute
        leaveList {
          startedAt
          endedAt
          leaveMinute
          leaveType
          leaveForm
          leaveDocumentAttachment
          leaveRequestID
          status
        }
        leaveMinute
        leaveDeductList {
          startedAt
          endedAt
          leaveMinute
          leaveType
          leaveForm
          leaveDocumentAttachment
          leaveRequestID
          status
        }
        leaveDeductMinute
      }
    }
  }
}
`

//GetAttendanceReport
export const queryGetAttendance = `
query TimeReport_get($employeeId: ID!, $date: AWSDate!) {
  getTimeAttendanceReport(employeeID: $employeeId, date: $date) {

    employeeID
    dayType
    Type
    date
    shiftName
    shiftStartTime
    shiftEndTime
    shiftVersion
    shiftMinute

    scanIn
    scanOut

    workingTimeList {
      startedAt
      endedAt
      workingMinute
    }

    inDiffMinute
    breakDiffMinute
    outDiffMinute

    overtimeList {
      overtimeType
      startedAt
      endedAt
      status
    }
    leaveList {
      startedAt
      endedAt
      leaveMinute
      leaveType
      leaveForm
      leaveDocumentAttachment
      leaveRequestID
      status
    }
    leaveDeductList {
      startedAt
      endedAt
      leaveMinute
      leaveType
      leaveForm
      leaveDocumentAttachment
      leaveRequestID
      status
    }
    breakTimeList {
      startTime
      endTime
      startedAt
      endedAt
    }

    overtimeApproveList {
      startedAt
      endedAt
      overtimeMinute
      overtimeType
      locationType
      status
      overtimeApproveMinute
      overtimeRequestID
    }
    overtimeApproveMinute

    isLink
    workingMinute
    overtimeMinute
    leaveMinute
    remark
    leaveDeductMinute
  }
}
`
export const queryPayrollConfig = `
query GetPayrollConfig {
  getPayrollConfig {
    periodStart
    periodCount
    period1Day
    period2Day
    period3Day
    workingDayCount
    workingDayCountNewEmployee
    resetLeaveDay
    workingHourDivisor
    roundType
  }
}
`
// ShareCalendar
export const getShareCalendar = `
query GetShareCalendar($input: GetShareCalendarInput!) {
  getShareCalendar(input: $input) {
    year
    items {
      date
      isHoliday
      holidayDescription
      events {
        Type
        event
        employeeID
        employee {
          id
          fullName
          profileImage
          lastName
        }
      }
    }
  }
}
`

export const ListEmployee = `
query ListEmployee($filter: ModelEmployeeFilterInput) {
  listEmployee(filter: $filter) {
    items {
      id
      positionID
      departmentID
      status
      nickName
      fullName
      lastName
      middleName
      personalID
      sex
      hireDate
      retireDate
      department {
        id
        departmentName
      }
    }
  }
}
`

//GetEmployee
export const querygetEmployee = `
query Query($getEmployeeId: ID) {
  getEmployee(id: $getEmployeeId) {
    id
    positionID
    departmentID
    status
    employeePeriod
    nameTitle
    fullName
    middleName
    lastName
    personalID
    nationality
    religion
    ethnicity
    fullNameEnglish
    middleNameEnglish
    lastNameEnglish
    birthDate
    nickName
    sex
    personalRelation
    addressPersonalID {
      addressNumber
      group
      optional
      province
      subDistrict
      district
      zipCode
    }
    addressCurrent {
      addressNumber
      group
      optional
      province
      subDistrict
      district
      zipCode
    }
    fingerPrintId
    hireDate
    retireDate
    employeeType
    teamList
    leaderTeam
    employeeWorkStatus
    isTimeAttendance
    setShiftWorking
    shiftWorkingID
    workPlace
    packageGroupID
    holidayID
    holidayLink
    getHolidayID
    salaryOptional
    salary
    
    socialSecurity
    personalIDNumber
    hospitalService
    socialSecurityCalculate
    diligentAllowance
   
  
    employeeHandicappedAndAgeOut
    paymentMethod
    bankPayment
    bankAccountName
    bankAccountId
    profileImage
    username
    password
    
   
    personalTalent
    personalIdAttachment
    personalHouseAttachment
    personalEducationalAttachment
    personalTrainningAttachment
    personalOptionalAttachment
    lineId
    lineUserId
    tel
    email
    salaryUOM
    educationHighest
    unusedLatestSalary

    incomeExpense {
      incomeExpenseType
      name
      total
      unit
    }

    employeeFund {
      name
      fundNumber
      contractDate
      deductMethod
      fundRate
      contributionMethod
      contributionCompany
      contributionTotal
      beneficiary
      remark
    }

    taxDeductOne {
      year
      month
      total
    }
    taxDeductTwo {
      name
      rateDeduct
      totalDeduct
    }
    historyFamily {
      fullName
      age
      job
      relation
    }
    historyEducation {
      educationLevel
      educationName
      majorName
      startedYear
      endedYear
    }
    historyWork {
      workName
      startedAt
      endedAt
      positionName
      descPosition
      salary
      leaveReason
    }
    
  
   
    
    
  }
}
`

//QueryLeaveDocument
export const queryLeaveDocument = `
query LeaveListGroupByEmployee($nextToken: String, $filter: ModelLeaveFilterInput) {
  listLeaveGroupByEmployee(filter: $filter, nextToken: $nextToken) {
    items {

      employeeID

      employee {
        fullName
        lastName
      }

      items {
        leaveType
        leaveForm
        createdAt
        startedAt
        endedAt
        status
        id
        employee {
          id
          positionID
          departmentID
          status
          fullName
          lastName
        }
      }

    }
    nextToken
  }
}
`

//Document Overtime
export const queryOvertimeDocument = `
query ListOvertimeGroupByEmployee($nextToken: String, $filter: ModelOvertimeFilterInput) {
  listOvertimeGroupByEmployee(nextToken: $nextToken, filter: $filter) {
    items {
      employeeID
      employee {
        fullName
        lastName
      }
      items {
        startedAt
        endedAt
        overtimeType
        overtimeMinute
        status
        id
        remark
        date
        employee {
          id
          departmentID
          fullName
          lastName
        }
      }
    }
    nextToken
  }
}
`

//GetOvertimeDocument
export const querygetovertimeDocument = `
query GetOvertime($employeeId: ID!, $getOvertimeId: ID!) {
  getOvertime(employeeID: $employeeId, id: $getOvertimeId) {
    id
    employeeID
    createdAt
    date
    startedAt
    endedAt
    overtimeType
    status
    employee {
      id
      status
      fullName
      middleName
      lastName
    }
    
  }
}
`

//GetLeaveDocument
export const queryGetLeaveDocument = `
query GetLeave($getLeaveId: ID!, $employeeId: ID!) {
  getLeave(id: $getLeaveId, employeeID: $employeeId) {
    id
    employeeID
    createdAt
    date
    startedAt
    endedAt
    leaveType
    employee {
      id
      fullName
      middleName
      lastName
      departmentID
      positionID
    }
  }
}
`
