import React, { ChangeEvent } from 'react'
import styles from './index.module.scss'
import { useState } from 'react'
//Image
import ProfileBg from './images/profile-BG.svg'
import IconCamera from './images/camera.svg'
import { handleUploadFile } from 'src/utils/common'
interface Props {
  image: any
  setImage: any
  id?: any
  values?: any
  name?: any
}
const ProfileUpload: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <label className={styles.container}>
        <div className={styles.imageContainer}>
          {props.image === '' ? <img src={ProfileBg} alt="" /> : <img src={props.image} alt="profileBG" />}
        </div>

        <div className={styles.dotCamera}>
          <img src={IconCamera} alt="camera" />
        </div>
        <input
          type="file"
          id="img-upload"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={(e) => {
            Promise.all(handleUploadFile(e.target.files, `DocumentPersonal/${props.values.values.id}/file/`)).then(
              (personalOptionalAttachment: any) => {
                props.values.setFieldValue('personalOptionalAttachment', personalOptionalAttachment[0])
              },
            )
          }}
        />
      </label>
    </>
  )
}
export default ProfileUpload
