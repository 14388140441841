import React from 'react'
import styles from './index.module.scss'
import DownArrow from '../../image/downArrow.svg'
import Hr from '../../image/hr.svg'
import { ButtonComponent } from '../../component'

interface Props {
  topic: string
  data: any
  isSearch?: string
}
const AssignedCard: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <div className={styles.assignedCardContainer}>
        <section className={styles.assignedCountWrapper}>
          <img src={DownArrow} alt="arrow" />
          <p>{`${props.topic} (${props.data.length})`}</p>
        </section>
        <section className={styles.card}>
          {props.data
            .filter((dataFilter: any) => dataFilter.title.indexOf(props.isSearch) > -1)
            .map((data: any, index: number) => {
              return (
                <div key={index} className={styles.contentWrapper}>
                  <article className={styles.assignedCardRowWrapper}>
                    <img src={Hr} alt="hr" />{' '}
                    <div>
                      <p className={styles.textBold}>{data.title}</p>
                      {data.subtitle ? <p className={styles.textNormal}>{data.subtitle} </p> : ''}
                    </div>
                  </article>
                  {props.topic === 'Assigned' ? (
                    <div className={styles.btnBox}>
                      <ButtonComponent
                        _colorBG="white"
                        _colorText="black"
                        _text="Unassign"
                        _variant="outlined"
                        _type="button"
                        _functionOnClick={() => console.log('unassign')}
                      />
                    </div>
                  ) : (
                    <article className={styles.textAndBtnWrapper}>
                      <p className={styles.textNormal}>10 function</p>
                      <div className={styles.btnBox}>
                        <ButtonComponent
                          _colorBG="darkblue"
                          _colorText="white"
                          _text="Assign"
                          _variant="contained"
                          _type="button"
                          _functionOnClick={() => console.log('Assign')}
                        />
                      </div>
                    </article>
                  )}
                </div>
              )
            })}
        </section>
      </div>
    </>
  )
}
export default AssignedCard
