import React, { useState, useEffect } from 'react'
import { NavTopBarComponent, NavSideBarComponent } from '../component'

export const Layout: React.FC = (props: any) => {
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>
      <div className="main">{props.children}</div>
      <div className="footer">Footer</div>
    </div>
  )
}
