import React from 'react'
import styles from './index.module.scss'
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent, CardMasterDate } from '../../component'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { useNavigate } from 'react-router-dom'
// Components
const OvertimePolicyPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <div className="main">
          <div className={styles.container}>
            <section className={styles.topicPageWrapper}>
              <p className={styles.topicPage}>ระเบียบการทำงานล่วงเวลา</p>
            </section>
            <div className={styles.contentContainer}>
              <section className={styles.btnTopWrapper}>
                <div className={styles.btnTop}>
                  <ButtonComponent
                    _type="button"
                    _colorBG="blue"
                    _colorText="white"
                    _variant="outlined"
                    _isIcon="add"
                    _text="เพิ่มระเบียบทำงานล่วงเวลา"
                    _functionOnClick={() => navigate('../masterdata/overtimepolicy/add')}
                  />
                </div>
              </section>
              <section className={styles.cardDetailWrapper}>
                <CardMasterDate
                  _topic="OT วันหยุด"
                  _title="ค่าโอที"
                  _content="1.5 เท่า"
                  _functionOnClickEdit={() => navigate('../masterdata/overtimepolicy/edit')}
                  _functionOnClickDelete={() =>
                    dispatch(
                      allAction.modalConfirmAction.setOpenModalConfirm(
                        'ลบระเบียบการทำงานล่วงเวลา',
                        'คุณต้องการลบรายการ รายการทำงานล่วงเวลา ใช่หรือไม่',
                        'หากลบแล้ว ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                        'ใช่, ลบรายการ',
                        () => {
                          dispatch(
                            allAction.modalAction.setOpenModalAlertStatus(
                              'ลบข้อมูลรายการทำงานล่วงเวลาเรียบร้อยแล้ว',
                              '',
                              'success',
                            ),
                          )
                          dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        },
                      ),
                    )
                  }
                />
              </section>
            </div>
          </div>
        </div>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default OvertimePolicyPage
