import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
// Components
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent } from '../../component'
import ModalConfirm from './ModalConfirm'
import ListCard from './ListCard'
import ListSelectCard from './ListSelectCard'
import { queryListApprove } from '../../adapter/xhr'
import { ELeave, EOverTime } from '../AttendanceReport/EType'
import DetailPage from './DetailPage'
import { ModalApproveArr } from './modalApprove'

const ApprovalSystemPage: React.FC = (): JSX.Element => {
  //*List Show All
  const [zeroData, setZeroData] = useState<any>({})

  const [detailData, setDetailData] = useState<any>({})

  const [listApprove, setListApprove] = useState<any>([])
  const [selectList, setSelectList] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)

  const QueryApprove = () => {
    const filterStatus: any = {
      filter: {
        status: {
          eq: 'PENDING',
        },
      },
    }
    queryListApprove(filterStatus).then((res: any) => {
      setListApprove(res.data.data.listApprove.items)
      setZeroData(res.data.data.listApprove.items[0])
      // console.log(zeroData, 'zeroData')
    })
  }

  useEffect(() => {
    setDetailData(zeroData)
  }, [zeroData])

  // console.log(detailData, '1111111111111111')

  useEffect(() => {
    QueryApprove()
  }, [])
  // console.log('>> List All', listApprove)
  //Modal
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [isCall, setIsCall] = useState(false)
  const handleClose = () => setOpenModalConfirm(false)
  const [btType, setBtType] = useState<string>('')
  const [isCheckAll, setIsCheckAll] = useState<any>(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [listData2, setListData] = useState<any>([])

  useEffect(() => {
    setListData(listApprove)
  }, [listApprove])

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(listData2.map((li: any) => li))
    if (isCheckAll) {
      setIsCheck([])
    }
  }
  const handleClick = (e: any, id: any) => {
    const { value, checked } = e.target
    console.log(id, 'id')
    setIsCheck([...isCheck, listData2.find((item: any) => item.id === id)])
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item.id !== id))
    }
  }

  const CheckHandle = (e: any, index: any) => {
    console.log(e.target.checked, 'e')
    const check = e.target.checked
    const id = e.target.value
    const DataList: any = listApprove.find((item: any) => {
      return item.id === id
    })

    if (check === true) {
      selectList.push(DataList)
    } else {
      const arr = selectList.indexOf(DataList)
      selectList.splice(arr, 1)
    }
    console.log(selectList, 'selectList')
  }

  //ListSelectCard
  const [list, setList] = useState('รายการทั้งหมด')

  //ModalData
  const [modalData, setModalData] = useState([
    {
      title: 'อนุมัติทั้งหมด',
      subtitle: 'อนุมัติรายการที่เลือกทั้งหมด',
      description: 'จำนวนที่เลือก 11 รายการ',
    },
  ])

  //DetailCard

  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <div className="main">
          <ModalConfirm open={openModalConfirm} close={handleClose} data={modalData} isCall={isCall} />
          <div className={styles.container}>
            <section className={styles.topicPageWrapper}>
              <p className={styles.topicPage}>การอนุมัติรายการ </p>
              {isCheck.length > 0 ? (
                <div className={styles.btnTopicWrapper}>
                  <div className={styles.btnTopic}>
                    <ButtonComponent
                      _type="button"
                      _colorBG="blue"
                      _colorText="white"
                      _variant="outlined"
                      _text="อนุมัติที่เลือกทั้งหมด"
                      _functionOnClick={(e: any) => {
                        setOpenModal(true)
                        setBtType(e.target.textContent)
                      }}
                    />
                  </div>
                  <div className={styles.btnTopic}>
                    <ButtonComponent
                      _type="button"
                      _colorBG="red"
                      _colorText="white"
                      _variant="outlined"
                      _text="ปฎิเสธที่เลือกทั้งหมด"
                      _functionOnClick={(e: any) => {
                        setOpenModal(true)
                        setBtType(e.target.textContent)
                      }}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </section>
            <div className={styles.contentContainer}>
              <div className={styles.listCardWrapper}>
                <ListSelectCard
                  dataList={dataList}
                  list={list}
                  setList={setList}
                  data={listApprove}
                  _checked={isCheckAll}
                  _onChange={handleSelectAll}
                />

                <div className={styles.CardArea}>
                  {list === 'รายการทั้งหมด'
                    ? listApprove.map((listData: any, listDataIndex: number) => {
                        return (
                          <div key={listDataIndex} className={styles.listCard}>
                            <ListCard
                              _key={listData.id}
                              topic={listData.Type}
                              subtopic={
                                listData.leaveType
                                  ? `${ELeave[`${listData.leaveType}`]}`
                                  : listData.overtimeType
                                  ? `${EOverTime[`${listData.overtimeType}`]}`
                                  : ''
                              }
                              description={listData.Type}
                              departmentName={listData.employee?.department?.departmentName}
                              name={listData.employee?.fullName}
                              lastName={listData.employee?.lastName}
                              position={listData?.department}
                              date={listData.startedAt}
                              minuteHour={listData?.overtimeMinute ? (listData?.overtimeMinute / 60).toFixed(2) : ''}
                              _onChange={(e: any) => handleClick(e, listData.id)}
                              _checked={isCheck.map((item: any) => item.id).includes(listData.id)}
                              functionOnClick={() => {
                                setDetailData(listData)
                              }}
                            />
                          </div>
                        )
                      })
                    : listApprove
                        .filter((e: any) => e.Type.indexOf(list) > -1)
                        .map((listData: any, listDataIndex: number) => {
                          return (
                            <div key={listDataIndex} className={styles.listCard}>
                              <ListCard
                                _key={listData.id}
                                topic={listData.Type}
                                subtopic={
                                  listData.leaveType
                                    ? `${ELeave[`${listData.leaveType}`]}`
                                    : listData.overtimeType
                                    ? `${EOverTime[`${listData.overtimeType}`]}`
                                    : ''
                                }
                                description={listData.Type}
                                departmentName={listData.employee?.department?.departmentName}
                                name={listData.employee?.fullName}
                                lastName={listData.employee?.lastName}
                                position={listData?.department}
                                date={listData.startedAt}
                                minuteHour={listData?.overtimeMinute ? (listData?.overtimeMinute / 60).toFixed(2) : ''}
                                _onChange={(e: any) => handleClick(e, listData.id)}
                                _checked={isCheck.map((item: any) => item.id).includes(listData.id)}
                                functionOnClick={() => {
                                  setDetailData(listData)
                                }}
                              />
                            </div>
                          )
                        })}
                </div>
              </div>
              <div className={styles.detailCard}>
                <DetailPage
                  detailData={detailData}
                  QueryApprove={QueryApprove}
                  listApprove={listApprove}
                  zeroData={zeroData}
                />
                {/* {detailData ? : <p>เลือกรายการ</p>} */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer">Footer</div>
        <ModalApproveArr openModal={openModal} setOpenModal={setOpenModal} selectList={isCheck} type={btType} />
      </div>
    </>
  )
}
export default ApprovalSystemPage

const dataList = [
  {
    value: 'ALL',
    label: 'รายการทั้งหมด',
  },
  {
    value: 'APPROVE_LEAVE',
    label: 'การลา',
  },
  {
    value: 'APPROVE_COMPENSATION',
    label: 'สวัสดิการ',
  },
  {
    value: 'APPROVE_OVERTIME',
    label: 'ทำงานล่วงเวลา',
  },
  {
    value: 'APPROVE_PAYROLL',
    label: 'เงินเดือน',
  },
]
