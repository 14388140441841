import React, { useState } from 'react'
import styles from './index.module.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { InputNumberComponentCustom, PaginationBar } from '../../../component'
import { useFormikContext } from 'formik'
import { paginationFunc } from '../../../utils/common'

export interface ITableHeaderList {
  text: string
  key: string
  type: 'text' | 'input'
  inputType?: 'number'
}

interface ITableProps {
  topic: 'รายรับ' | 'รายจ่าย'
  tableHeaderList: ITableHeaderList[]
  tableBodyList: any[]
  prefixValues?: string
}

const ExpenseIncomeTable: React.FC<ITableProps> = (props: ITableProps): JSX.Element => {
  const [pageSite, setPageSite] = useState<number>(20)
  const [listItemIndex, setListItemIndex] = useState<number>(0)
  const { values } = useFormikContext<any>()
  return (
    <div className={styles.tableContainer}>
      <section className={styles.tableTopic}>{props.topic}</section>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              borderRight: '1px solid rgba(0, 0, 0, 0.2)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
              borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
            }}
          >
            {props?.tableHeaderList?.map((headerString: ITableHeaderList, headerIndex: number) => {
              return (
                <TableCell sx={{ border: 'none' }} align="center" key={headerIndex}>
                  {headerString.text}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableBodyList.length
            ? paginationFunc(props?.tableBodyList, pageSite)[listItemIndex]?.map(
                (tableBodyObj: any, tableBodyIndex: number) => {
                  return (
                    <TableRow
                      sx={{
                        borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                        borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                      }}
                      key={tableBodyIndex}
                    >
                      {props?.tableHeaderList?.map((headerString: ITableHeaderList, headerIndex: number) => {
                        return (
                          <>
                            {headerString.key === 'No.' ? (
                              <TableCell sx={{ border: 'none' }} align="center" key={headerIndex}>
                                {pageSite * listItemIndex + tableBodyIndex + 1}
                              </TableCell>
                            ) : headerString.type === 'input' ? (
                              <TableCell sx={{ border: 'none' }} align="center" key={headerIndex}>
                                <InputNumberComponentCustom
                                  _name={`${props?.prefixValues}[${tableBodyIndex}].${headerString.key}`}
                                  _value={values[`${props?.prefixValues}`][tableBodyIndex][headerString.key]}
                                />
                                {/* {tableBodyObj[headerString.key]} */}
                              </TableCell>
                            ) : (
                              <TableCell sx={{ border: 'none' }} align="center" key={headerIndex}>
                                {tableBodyObj[headerString.key]}
                              </TableCell>
                            )}
                          </>
                        )
                      })}
                    </TableRow>
                  )
                },
              )
            : null}
        </TableBody>
      </Table>
      <PaginationBar
        _dropDownValue={pageSite}
        _onDropdownChange={(e: any) => {
          setPageSite(e.target.value)
          setListItemIndex(0)
        }}
        _selectedNumber={listItemIndex + 1}
        _numberList={paginationFunc(props?.tableBodyList, pageSite).map((e: any, index: number) => index + 1)}
        _setSelectedNumber={(event: any, number: number, index: number) => {
          // console.log(number, index, 'index')
          if (listItemIndex !== index) {
            setListItemIndex(index)
            // if (index + 2 === paginationFunc(listAttendanceLog, pageSite).length && nextToken) {
            //   ListTimeAttenLog({ nextToken: nextToken }).then((res: any) => {
            //     if (res.data.data.listTimeAttendance.items && res.data.data.listTimeAttendance.items.length > 0) {
            //       setAttendanceLog([...listAttendanceLog, ...res.data.data.listTimeAttendance.items])
            //     }
            //     setNextToken(res.data.data.listTimeAttendance.nextToken)
            //   })
            // }
          }
        }}
      />
    </div>
  )
}

export default ExpenseIncomeTable
