import styles from './index.module.scss'
import React from 'react'
interface Props {
  comeBefore: number
  comeLate: number
  outBefore: number
  outLate: number
}
const ChatProgressBar = (props: Props) => {
  return (
    <div>
      <div className={styles.allChart}>
        <div className={styles.leftChart}>
          <div
            style={{
              width: `${props.comeBefore * 100}px`,
              backgroundColor: '#47A8F7',
              height: '12px',
              borderRadius: '5px',
            }}
          ></div>
          <div
            style={{
              width: `${props.comeLate * 100}px`,
              backgroundColor: '#7F80E9',
              height: '12px',
              borderRadius: '5px',
            }}
          ></div>
        </div>
        <div className={styles.rightChart}>
          <div
            style={{
              width: `${props.outBefore * 100}px`,
              backgroundColor: '#FF6161',
              height: '12px',
              borderRadius: '5px',
            }}
          ></div>
          <div
            style={{
              width: `${props.outLate * 100}px`,
              backgroundColor: '#D1DAE7',
              height: '12px',
              borderRadius: '5px',
            }}
          ></div>
        </div>
      </div>
      <div className={styles.detailZone}>
        <div className={styles.detailLeftZone}>
          <div className={styles.detailLeftItem}>
            <div className={styles.detailText}>มาก่อน</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div style={{ backgroundColor: '#47A8F7', width: '10px', height: '10px', borderRadius: '100%' }}></div>
              <div className={styles.detailCount}> {props.comeBefore}</div>
            </div>
          </div>
          <div className={styles.detailLeftItem}>
            <div className={styles.detailText}>มาสาย</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div style={{ backgroundColor: '#7F80E9', width: '10px', height: '10px', borderRadius: '100%' }}></div>
              <div className={styles.detailCount}> {props.comeLate}</div>
            </div>
          </div>
        </div>
        <div className={styles.detailRightZone}>
          <div className={styles.detailRightItem}>
            <div className={styles.detailText}>ออกก่อน</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div style={{ backgroundColor: '#FF6161', width: '10px', height: '10px', borderRadius: '100%' }}></div>
              <div className={styles.detailCount}> {props.outBefore}</div>
            </div>
          </div>
          <div className={styles.detailRightItem}>
            <div className={styles.detailText}>ออกสาย</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div style={{ backgroundColor: '#D1DAE7', width: '10px', height: '10px', borderRadius: '100%' }}></div>
              <div className={styles.detailCount}> {props.outLate}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatProgressBar
