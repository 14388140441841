import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../../state/actions'
import * as yup from 'yup'
import { Formik } from 'formik'
import { ButtonComponent, ButtonSelectCustom, InputTimeComponentCustom, ProfileUpload } from '../../../component'
import { InputDateComponentCustome, InputSelectComponentCustom } from '../../../component/InputsFormik'
import { CheckIntervals, getHour } from '../../../utils/common'
import { CreateOvertimeDocument } from '../../../adapter/xhr'
import { ESaveDescrip, SaveType } from '../EType'
import swal from 'sweetalert'

export const ModalOverTime: React.FC<any> = (props: any): JSX.Element => {
  const [status, setStatus] = React.useState('APPROVE')
  const dispatch = useDispatch()

  const statusType = (e: any) => {
    setStatus(e)
  }

  const OTList = [
    { label: 'วันทำงาน', value: 'WORK' },
    { label: 'ทำงานล่วงเวลา', value: 'OVERTIME' },
  ]

  const date = props.date
  const employeeId = props.attendance.employeeID

  const validationSchema = yup.object({
    overtimeType: yup.string().required('เลือกประเภทการทำงานล่วงเวลา'),
    startedAt: yup.string().required('กรอกเวลาเริ่มต้น'),
    endedAt: yup.string().required('กรอกเวลาสิ้นสุด'),
  })
  const otherProps = props

  // const Intervals =

  return (
    <Modal
      open={props.openModalOT}
      onClose={() => {
        props.setOpenModalOT(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalLeaveEdaite}>
        <div className={styles.card}>
          <Formik
            initialValues={{
              startedAt: '',
              endedAt: '',
              status: '',
              overtimeType: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              const DocumentOvertimeVariables = {
                input: {
                  date: date,
                  employeeID: employeeId,
                  startedAt: values.startedAt,
                  endedAt: values.endedAt,
                  status: status,
                  overtimeType: values.overtimeType,
                },
              }

              const NewIntervals: any = { startedAt: values.startedAt, endedAt: values.endedAt }
              const resultIntervalsCheck = CheckIntervals(otherProps.overtimeList, NewIntervals)

              if (resultIntervalsCheck === true) {
                swal({
                  icon: 'error',
                  title: 'มีช่วงเวลาโอทีนี้แล้ว',
                  text: 'สามารถสร้างรายการจากเอกสารแนะนำหรือเลือกช่วงเวลาอื่น',
                  dangerMode: true,
                })
              } else if (resultIntervalsCheck === false) {
                dispatch(
                  allAction.modalConfirmAction.setOpenModalConfirm(
                    `${ESaveDescrip[`${status}`]} OT`,
                    `วันที่ ${date} เวลา ${values.startedAt} - ${values.endedAt} `,
                    'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                    `ใช่,${ESaveDescrip[`${status}`]}`,
                    () => {
                      CreateOvertimeDocument(DocumentOvertimeVariables).then((res: any) => {
                        console.log(res, 'res')
                        props.overtimeList.push({
                          startedAt: values.startedAt,
                          endedAt: values.endedAt,
                          status: status,
                          overtimeType: values.overtimeType,
                          overtimeRequestID: res.data.data.createOvertimeRequest.id,
                        })
                        props.setFieldValue(`overtimeList`, props.overtimeList)
                      })
                      dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันทำรายการเรียบร้อย', '', 'success'))
                      dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                      props.setOpenModalOT(false)
                    },
                  ),
                )
              }
            }}
          >
            {(props: any) => (
              <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                {' '}
                <div className={styles.header}>
                  <div className={styles.bt}>
                    <ButtonComponent
                      _type={'button'}
                      _colorBG={'white'}
                      _colorText={'red'}
                      _variant={'text'}
                      _text={'ยกเลิก'}
                      _sx={{ fontSize: '16px' }}
                      _functionOnClick={() => otherProps.setOpenModalOT(false)}
                    />
                  </div>
                  <p className={styles.textHead}>สร้างใหม่</p>
                  <div className={styles.btSelect}>
                    <ButtonSelectCustom
                      _selectData={SaveType}
                      _onchange={(e: any) => {
                        statusType(e)
                      }}
                    />{' '}
                  </div>
                </div>
                <div className={styles.FormGroup}>
                  <div className={styles.filedInputFlex}>
                    <p>ประเภทโอที</p>
                    <div className={styles.inputBox}>
                      <InputSelectComponentCustom
                        _name="overtimeType"
                        _label={'ประเภทโอที'}
                        _menuList={OTList}
                        _helperText={props.errors.overtimeType}
                      />
                    </div>
                  </div>

                  <div className={styles.filedInputTime}>
                    <p>ช่วงเวลา</p>

                    <section className={styles.GroupBox}>
                      <div className={styles.InputTime}>
                        {' '}
                        <InputTimeComponentCustom
                          _name="startedAt"
                          _label={'เริ่มต้น'}
                          _helperText={props.errors.startedAt}
                        />
                      </div>
                      <div className={styles.InputTime}>
                        {' '}
                        <InputTimeComponentCustom
                          _name="endedAt"
                          _label={'สิ้นสุด'}
                          _helperText={props.errors.endedAt}
                        />
                      </div>
                    </section>
                  </div>
                  {props.values.startedAt && props.values.endedAt ? (
                    <p className={styles.sumHour}>
                      รวมเวลา {getHour(date, props.values.startedAt, props.values.endedAt)} ชั่วโมง
                    </p>
                  ) : (
                    ''
                  )}

                  <div className={styles.filedInputFlex}>
                    <p>วันที่</p>
                    <div className={styles.inutBox}>
                      <InputDateComponentCustome _name={'date'} _value={date} _disabled={true} _label={'วันที่'} />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
