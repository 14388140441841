import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import {
  NavTopBarComponent,
  NavSideBarComponent,
  InputTextComponent,
  InputDateComponent,
  // InputNumberComponent,
  ButtonComponent,
  InputTextIconComponent,
} from '../../component'
import TabsWork from './tab'
// import editIcon from '../../image/editcolor.svg'
import allAction from '../../state/actions'
import { useParams } from 'react-router-dom'

//Calendar
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CalendarPicker from '@mui/lab/CalendarPicker'

import Grid from '@mui/material/Grid'

//* API
import { queryListApproveAttendance } from '../../adapter/xhr'

const PayrollCreate: React.FC = (): JSX.Element => {
  const [date, setDate] = useState<Date | null>(new Date())
  console.log('date', date)
  const dispatch = useDispatch()
  const { id } = useParams<any>()
  console.log('>>ID in Edit Approve:', id)
  const [mode, setMode] = useState<'create' | 'update'>('create')
  useEffect(() => {
    if (id) {
      setMode('update')
      const listApproveAttendanceValiable = {
        filter: {
          employeeID: null,
          fullName: null,
          status: null,
          startedAt: null,
          endedAt: null,
        },
      }
      queryListApproveAttendance(listApproveAttendanceValiable).then((res: any) => {
        // console.log('RES > Approve Edit :', res.data.data.listApproveAttendance.items)
      })
    } else {
      setMode('create')
    }
  }, [])
  const validationSchema = yup.object({})
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            <h1 className={styles.title}>{id ? 'แก้ไขอนุมัติวันทำงาน' : 'เพิ่มอนุมัติวันทำงาน'}</h1>
          </div>
          <Formik
            initialValues={{
              date: '',
              employeeid: '',
              name: '',
              position: '',
              type: '',
              amount: 0,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              dispatch(
                allAction.modalConfirmAction.setOpenModalConfirm(
                  'ยืนยันการทำรายการ',
                  'คุณต้องการยืนยันการทำรายของ UserID?',
                  ' ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                  'ใช่, ทำรายการ',
                  () => {
                    dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        'สร้างสลิปเรียบร้อย สถานะ “Success”',
                        '',
                        'success',
                      ),
                    )
                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                  },
                ),
              )
            }}
          >
            {(props: any) => (
              <form onSubmit={props.handleSubmit}>
                <div className={styles.Boxarea}>
                  <div className={styles.boxtop}>
                    <div className={styles.inputBox}>
                      <p className={styles.title}>ข้อมูลทั่วไป</p>
                      <div className={styles.InputArea}>
                        <div className={styles.inputForm}>
                          <InputDateComponent _label="Start Date" _name="startDate" />
                        </div>
                        <div className={styles.inputForm}>
                          <InputDateComponent _label="End Date" _name="endDate" />
                        </div>
                        <div className={styles.inputForm}>
                          <InputTextIconComponent _label="รหัสพนักงาน" _name="employeeid" />
                        </div>
                        <div className={styles.inputForm}>
                          <InputTextComponent _label="ชื่อ-นามสกุล" _name="name" />
                        </div>
                        <div className={styles.inputForm}>
                          <InputTextComponent _label="ตำแหน่ง" _name="position" />
                        </div>
                        <div className={styles.inputForm}>
                          <InputTextComponent _label="ประเภทพนักงาน" _name="type" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.tabBox}>
                      <TabsWork />
                    </div>
                  </div>
                  <div className={styles.footerBox}>
                    <div className={styles.calendarContainer}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <CalendarPicker date={date} onChange={(newDate: any) => setDate(newDate)} />
                          </Grid>
                        </Grid>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className={styles.boxbottomCard}>
                    <section className={styles.headBox}>
                      <p className={styles.textBold}>บทสรุป</p>
                      <p className={styles.text}> รอบวันที่ 11/02/2564 -25/02/2564</p>
                    </section>
                    <section className={styles.bodyBox}>
                      <section className={styles.textColWrapper}>
                        <article className={styles.textRowWrapper}>
                          <p> ใช้สิทธิการลาทั้งหมด</p>
                          <p>3 วัน </p>
                        </article>
                        <article className={styles.textRowWrapper}>
                          <p> เวลามาสายทั้งหมด</p>
                          <p>10 นาที </p>
                        </article>
                        <article className={styles.textRowWrapper}>
                          <p> ชั่วโมงการทำงานล่วงเวลา</p>
                          <p>1 ชั่วโมง</p>
                        </article>
                      </section>
                      <section className={styles.textColWrapper}>
                        <article className={styles.textRowWrapper}>
                          <p> จำนวนการเข้าทำงาน</p>
                          <p>18 วัน</p>
                        </article>
                        <article className={styles.textRowWrapper}>
                          <p> สวัสดิการ (ใช้จริง)</p>
                          <p>3,000 บาท</p>
                        </article>
                        <article className={styles.textRowWrapper}>
                          <p> สวัสดิการ (จ่าย จริง)</p>
                          <p>500 บาท</p>
                        </article>
                      </section>
                    </section>
                  </div>
                  {/* <div className={styles.boxbottom}>
                    <div className={styles.inputBox}>
                      {' '}
                      <p className={styles.title}>สรุปรวม</p>
                      <div className={styles.inputArea}>
                        <div className={styles.inputForm}>
                          <InputNumberComponent _label="สรุปได้รับ" _name="amount" />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className={styles.button}>
                  <div className={styles.btnFooterWrapper}>
                    <div className={styles.btCancle}>
                      <ButtonComponent
                        _colorBG="white"
                        _colorText="red"
                        _text="ยกเลิก"
                        _variant="text"
                        _type="button"
                      />
                    </div>
                    <div className={styles.btCancle}>
                      <ButtonComponent
                        _colorBG="secondary"
                        _colorText="white"
                        _text="Save as Draft"
                        _variant="text"
                        _type="button"
                        _functionOnClick={() => {
                          dispatch(
                            allAction.modalConfirmAction.setOpenModalConfirm(
                              'ยืนยันการทำรายการ',
                              '',
                              'คุณต้องการยืนยันการ Save as Draft ของ UserID?',
                              'ใช่, ทำรายการ',
                              () => {
                                dispatch(
                                  allAction.modalAction.setOpenModalAlertStatus(
                                    'สร้างสลิปเรียบร้อย สถานะ “Success”',
                                    '',
                                    'success',
                                  ),
                                )
                                dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                              },
                            ),
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.btAdd}>
                    <ButtonComponent
                      _colorBG="blue"
                      _colorText="white"
                      _text="Save"
                      _variant="contained"
                      _type="submit"
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="footer">Footer</div>
    </div>
  )
}

export default PayrollCreate
