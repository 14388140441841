import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { NavTopBarComponent, NavSideBarComponent, PaginationBar } from '../../component'
import { useDispatch } from 'react-redux'
import { TableLeaveDocument } from './table'
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import SearchIcon from './image/search.svg'
import FilterIcon from './image/filter.svg'
import { ListLeaveDocument } from 'src/adapter/xhr'
import { paginationFunc } from 'src/utils/common'
import { LeaveGroupByEmployeeQuery } from 'src/utils/generated'
import { PopperFilter } from './Modal'

const LeaveDocument: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const [Listdocument, setListDocument] = useState<LeaveGroupByEmployeeQuery[]>([])
  const [pageSite, setPageSite] = useState<number>(20)
  const [listItemIndex, setListItemIndex] = useState<number>(0)
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openpopper, setOpenPopper] = useState(false)
  const [searchVal, setSearchVal] = useState('')

  const [fillLeavtype, setfillLeavtype] = useState<any>('')
  const [fillLeavForm, setfillLeavForm] = useState<any>('')
  const [fillStatus, setfillStatus] = useState<any>('')
  const [fillStartdate, setfillStartdate] = useState<any>('')
  const [fillEnddate, setfillEnddate] = useState<any>('')
  const [countFilter, setCounter] = useState<any>([])

  const handleClickPopper = (event: any) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper((previousOpen) => !previousOpen)
  }

  console.log([{ Filter: fillLeavtype }, { Filter: fillLeavForm }, { Filter: fillStatus }], 'FILTER')

  const CountFilter = () => {
    let count = 0
    countFilter.forEach((e: any) => {
      console.log(e)
      count = 3
    })

    return count
  }

  const Count = CountFilter()
  console.log(Count, 'Count')
  const filter = {
    leaveType: {
      beginsWith: fillLeavtype,
    },
    leaveForm: {
      beginsWith: fillLeavForm,
    },
    startedAt: {
      beginsWith: fillStartdate,
    },
    endedAt: {
      beginsWith: fillEnddate,
    },
    status: {
      beginsWith: fillStatus,
    },
  }

  // console.log(filter, 'filter')

  const [loading, setLoading] = useState(true)

  const QueryListDocument = () => {
    setLoading(true)
    ListLeaveDocument({ nextToken: nextToken, filter: filter }).then((res: any) => {
      // if (res.data.data.listLeaveGroupByEmployee.items && res.data.data.listLeaveGroupByEmployee.items.length > 0) {
      setListDocument(res.data.data.listLeaveGroupByEmployee.items)
      setLoading(false)
      // }
      setNextToken(res.data.data.listLeaveGroupByEmployee.nextToken)
    })
  }

  // console.log(loading, 'loadingloading')
  useEffect(() => {
    QueryListDocument()
  }, [])

  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <div className="main">
          <div className={styles.container}>
            <div className={styles.GroupArea}>
              <section className={styles.headArea}>
                <div className={styles.BoxInput}>
                  <FormControl fullWidth sx={{ m: 1, backgroundColor: '#FFFFFF', fontFamily: 'KanitRegular' }}>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={searchVal}
                      onChange={(e: any) => {
                        setSearchVal(e.target.value)
                        console.log(searchVal, 'searchVal')
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt="" />
                        </InputAdornment>
                      }
                      sx={{ fontFamily: 'KanitRegular' }}
                      placeholder="Search employee"
                    />
                  </FormControl>
                </div>
                <div className={styles.IconFilter}>
                  <img
                    src={FilterIcon}
                    alt=""
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      handleClickPopper(e)
                    }}
                  />
                  {countFilter > 0 ? <div className={styles.BadeCount}>{countFilter}</div> : ''}
                </div>
              </section>
              <section className={styles.TableLeave}>
                <TableLeaveDocument
                  Listdocument={
                    paginationFunc(Listdocument, pageSite)[listItemIndex]
                      ? paginationFunc(Listdocument, pageSite)[listItemIndex]
                      : []
                  }
                  nextToken={nextToken}
                  QueryListDocument={QueryListDocument}
                  searchVal={searchVal}
                  loading={loading}
                />

                <PaginationBar
                  _dropDownValue={pageSite}
                  _onDropdownChange={(e: any) => {
                    setPageSite(e.target.value)
                    setListItemIndex(0)
                  }}
                  _selectedNumber={listItemIndex + 1}
                  _numberList={paginationFunc(Listdocument, pageSite).map((e: any, index: number) => index + 1)}
                  _setSelectedNumber={(event: any, number: number, index: number) => {
                    if (listItemIndex !== index) {
                      setListItemIndex(index)
                      if (index + 2 === paginationFunc(Listdocument, pageSite).length && nextToken) {
                        ListLeaveDocument({ nextToken: nextToken, filter: filter }).then((res: any) => {
                          // if (
                          //   res.data.data.listLeaveGroupByEmployee.items &&
                          //   res.data.data.listLeaveGroupByEmployee.items.length > 0
                          // ) {
                          setListDocument([...Listdocument, ...res.data.data.listLeaveGroupByEmployee.items])
                          // }
                          setNextToken(res.data.data.listLeaveGroupByEmployee.nextToken)
                        })
                      }
                    }
                  }}
                />
              </section>
            </div>
          </div>
        </div>
        <div className="footer">Footer</div>
      </div>
      <PopperFilter
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        openpopper={openpopper}
        fillLeavtype={fillLeavtype}
        setfillLeavtype={setfillLeavtype}
        setOpenPopper={setOpenPopper}
        fillLeavForm={fillLeavForm}
        setfillLeavForm={setfillLeavForm}
        fillStatus={fillStatus}
        setfillStatus={setfillStatus}
        QueryListDocument={QueryListDocument}
        fillStartdate={fillStartdate}
        setfillStartdate={setfillStartdate}
        fillEnddate={fillEnddate}
        setfillEnddate={setfillEnddate}
        countFilter={countFilter}
        setCounter={setCounter}
        loading={loading}
        // GetcountFilter={GetcountFilter}
      />
    </>
  )
}
export default LeaveDocument
