import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop } from '@mui/material'
import TextField from '@mui/material/TextField'
import allAction from '../../../state/actions'
import { useDispatch } from 'react-redux'

//icon
import IconClose from '../../../image/close.svg'
import IconAlert from '../../../image/alert.svg'

//Table
import { TableLeave, TableLate, TableOverTime, TableWorkingTime, TableLeaveMiss } from './table'

//Components
import { ButtonComponent } from '../../../component'

interface Props {
  openModalEdit: boolean
  setOpenModalEdit: any
  dataTable: any
  topic?: string
}
const ModalEdit: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()

  const fontColor = {
    // style: { color: 'red' },
  }
  console.log('Modal Approve Edit >> props', props)

  useEffect(() => {
    console.log('Effect')
  }, [])

  return (
    <>
      <Modal
        open={props.openModalEdit}
        onClose={() => props.setOpenModalEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={styles.container}>
          <nav className={styles.navTop}>
            <p className={styles.textTopic}>{props.topic}</p>
            <button type="button" className={styles.btnClose} onClick={() => props.setOpenModalEdit(false)}>
              <img className={styles.iconClose} src={IconClose} alt="close" />
            </button>
          </nav>
          <div className={styles.detailContainer}>
            {props.topic === 'ข้อมูลวันทำงานล่วงเวลา' || props.topic === 'ข้อมูลการเข้า/ออกงาน' ? (
              <section className={styles.topContentWrapper}>
                <div className={styles.columnWrapper}>
                  <article>
                    <p className={styles.textTitle}>
                      {props.topic === 'ข้อมูลวันทำงานล่วงเวลา' ? 'ชั่วโมงทำงานล่วงเวลา' : 'ชั่วโมงทำงาน'}
                    </p>
                    <div className={styles.rowWrapper}>
                      <TextField
                        id="outlined-textarea"
                        type="number"
                        defaultValue="4"
                        multiline
                        className={styles.textFieldBG}
                      />
                      <p>ชั่วโมง</p>
                    </div>
                  </article>
                </div>
              </section>
            ) : (
              <section className={styles.topContentWrapper}>
                <div className={styles.columnWrapper}>
                  <article>
                    <p className={styles.textTitle}>
                      {props.topic === 'ข้อมูลวันมาสาย' ? 'เวลาที่มาสาย' : 'จำนวนวันลาที่มี'}
                    </p>
                    <div className={styles.rowWrapper}>
                      <article>
                        {' '}
                        <TextField
                          id="outlined-number"
                          type="number"
                          defaultValue="10"
                          className={styles.textFieldBG}
                        />
                        {props.topic === '' ? '' : ''}
                        <div className={styles.textAlertWrapper}>
                          <img src={IconAlert} alt="alert" />
                          <p className={styles.textAlert}>{`ใช้สิทธิ${props.topic}เกิน`}</p>
                        </div>
                      </article>

                      <p>{props.topic === 'ข้อมูลวันมาสาย' ? 'นาที' : 'วัน'}</p>
                    </div>
                  </article>
                  <article>
                    <p className={styles.textTitle}>
                      {props.topic === 'ข้อมูลวันมาสาย' ? 'มาสายได้ไม่เกิน' : 'จำนวนวันลาที่ใช้'}
                    </p>
                    <div className={styles.rowWrapper}>
                      <TextField
                        inputProps={fontColor}
                        id="outlined-number"
                        type="number"
                        defaultValue="10"
                        // multiline
                      />
                      <p>{props.topic === 'ข้อมูลวันมาสาย' ? 'นาที' : 'วัน'}</p>
                    </div>
                  </article>
                </div>
                <div className={styles.flexEnd}>
                  <p className={styles.textTitle}>จำนวนคงเหลือ</p>
                  <div className={styles.rowWrapper}>
                    <TextField type="number" id="outlined-number" defaultValue="10" />
                    <p> {props.topic === 'ข้อมูลวันมาสาย' ? 'นาที' : 'วัน'}</p>
                  </div>
                </div>
              </section>
            )}

            <section>
              {props.topic === 'ข้อมูลวันขาด' ? (
                <TableLeaveMiss data={props.dataTable} />
              ) : props.topic === 'ข้อมูลวันมาสาย' ? (
                <TableLate data={props.dataTable} />
              ) : props.topic === 'ข้อมูลวันทำงานล่วงเวลา' ? (
                <TableOverTime data={props.dataTable} />
              ) : props.topic === 'ข้อมูลการเข้า/ออกงาน' ? (
                <TableWorkingTime data={props.dataTable} />
              ) : (
                <TableLeave data={props.dataTable} />
              )}
            </section>
            <section className={styles.btnFooterWrapper}>
              <div className={styles.btnBox}>
                <ButtonComponent
                  _variant="text"
                  _text="Cancel"
                  _colorText="red"
                  _colorBG="white"
                  _type="button"
                  _functionOnClick={() => props.setOpenModalEdit(false)}
                />
              </div>
              <div className={styles.btnBox}>
                <ButtonComponent
                  _variant="contained"
                  _text="Confirm"
                  _colorText="white"
                  _colorBG="blue"
                  _type="button"
                  _functionOnClick={() => {
                    console.log('confirm')
                    dispatch(
                      allAction.modalConfirmAction.setOpenModalConfirm(
                        'ยืนยันการทำรายการ',
                        '',
                        'คุณต้องการยืนยันการทำรายของ UserID?',
                        'ใช่, ทำรายการ',
                        () => {
                          dispatch(
                            allAction.modalAction.setOpenModalAlertStatus('ยืนยันการแก้ไขเรียบร้อย', '', 'success'),
                          )
                          dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        },
                      ),
                    )
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default ModalEdit
