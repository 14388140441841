import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'

type User = {
  username: string
  partyID: string
}

const useUser = () => {
  const [user, setUser] = useState<Partial<User>>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [status, setStatus] = useState<'authorized' | 'unauthorized' | null>(null)

  const getUser = () => {
    setLoading(true)

    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken) {
      setLoading(false)
      setStatus('unauthorized')
      return
    }

    const res = jwtDecode(accessToken)
    setUser(res as User)
    setStatus('authorized')
    setLoading(false)
  }

  useEffect(() => {
    getUser()
  }, [])

  return { user, loading, status, getUser }
}

export default useUser
