import React, { useState, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
} from '@mui/material'

interface IMenu {
  value: number
  label: string
}

interface IPropsPagination {
  _onDropdownChange: any
  _dropDownValue: number
  //   _menuList: IMenu[]
  _numberList: number[]
  _selectedNumber: number
  _setSelectedNumber: any
}

const PaginationBar: React.FC<IPropsPagination> = (props: IPropsPagination): JSX.Element => {
  const menuArr = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]
  return (
    <div className={styles.barContainer}>
      <span className={styles.leftSideContent}>
        <p className={styles.paginationText}>View</p>
        <FormControl fullWidth size="small">
          <Select
            // defaultValue={props._name}
            sx={{ borderRadius: 3, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
            // labelId={props._name}
            // id={props._name}
            value={props._dropDownValue}
            // label={props._label}
            onChange={props._onDropdownChange}
            // name={props._name}
            // error={touched[props._name] && Boolean(errors[props._name])}
          >
            {menuArr.map((data: IMenu, index: number) => (
              <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
          {/* <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p> */}
        </FormControl>
        <p className={styles.paginationText}>row per page</p>
      </span>
      <span className={styles.rightSideContent}>
        <p className={styles.paginationText}>
          Showing {props._dropDownValue * (props._selectedNumber - 1) + 1} to{' '}
          {props._dropDownValue * props._selectedNumber} of entries {props._dropDownValue * props._numberList.length}
        </p>
        <span className={styles.numberContainer}>
          <button
            type="button"
            className={styles.paginationButton}
            onClick={(event: any) => {
              if (props._selectedNumber !== props._numberList[0]) {
                props._setSelectedNumber(event, props._selectedNumber - 3, props._selectedNumber - 2)
              }
            }}
          >
            {'<'}
          </button>
          {props?._numberList.map((number: number, index: number) =>
            (
              props._selectedNumber + 2 < props?._numberList.length
                ? number >= props._selectedNumber && number < props._selectedNumber + 3
                : number > props?._numberList.length - 3
            ) ? (
              <button
                type="button"
                key={index}
                className={number === props._selectedNumber ? styles.paginationButtonBold : styles.paginationButton}
                onClick={(event: any) => {
                  props._setSelectedNumber(event, number, index)
                }}
                disabled={number === props._selectedNumber}
              >
                {number}
              </button>
            ) : null,
          )}
          <button
            type="button"
            className={styles.paginationButton}
            onClick={(event: any) => {
              if (props._selectedNumber !== props._numberList[props._numberList.length - 1]) {
                props._setSelectedNumber(event, props._selectedNumber + 1, props._selectedNumber)
              }
            }}
          >
            {'>'}
          </button>
        </span>
      </span>
    </div>
  )
}
export default PaginationBar
