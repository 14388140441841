import React from 'react'
import styles from './index.module.scss'
import closeIcon from '../../image/close.svg'
import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import allAction from '../../state/actions'
import * as yup from 'yup'
import { InputTextComponent } from '../../component/InputsFormik'
import ButtonComponent from '../../component/Button'
const validationSchema = yup.object({
  forgot: yup.string().required('กรอกหมายเลขโทรศัพท์หรืออีเมล'),
})
const ModalForgot: React.FC<any> = (props: any): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalBox}>
        <div className={styles.card}>
          <div className={styles.head}>
            <p className={styles.textHead}>กู้คืนรหัสผ่านใหม่</p>
            <img src={closeIcon} alt="" onClick={() => props.setOpen(false)} className={styles.Closeicon} />
          </div>
          <p className={styles.text}>ป้อนหมายเลขโทรศัพท์หรืออีเมลที่ลงทะเบียนกับ LinkLook</p>
          <div className={styles.input}>
            <Formik
              initialValues={{
                forgot: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                dispatch(
                  allAction.modalAction.setOpenModalAlertStatus(
                    'รหัสผ่านใหม่ของคุณได้ถูกส่งไปยัง',
                    'th********@hotmail.com',
                    'info',
                  ),
                )
                props.setOpen(false)
              }}
            >
              {(props: any) => (
                <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                  <div className={styles.input}>
                    <InputTextComponent
                      _name="forgot"
                      _label="หมายเลขโทรศัพท์หรืออีเมล"
                      _helperText={props.errors.forgot}
                    />
                  </div>

                  <div className={styles.bottom}>
                    <div className={styles.btn}>
                      <ButtonComponent
                        _type="submit"
                        _colorBG="blue"
                        _colorText="white"
                        _variant="contained"
                        _text="ยืนยัน"
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalForgot
