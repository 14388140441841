import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
// import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Checkbox } from '@mui/material'
import styles from '../Menu_Employee/index.module.scss'
import { useNavigate } from 'react-router-dom'
//svg
import editIcon from '../../image/edit.svg'
import deleteIcon from '../../image/del.svg'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'

interface Props {
  teamLeader: any
  listMember: any
}

export const TableTeam: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [leaderList, setLeaderList] = React.useState([] as any)
  const [memberList, setMemberList] = React.useState([] as any)

  const Team = [
    {
      no: 1,
      id: '001',
      name: 'Thanyarat',
      position: 'หัวหน้า',
      depart: 'HR',
      status: 'Active',
    },
    {
      no: 2,
      id: '002',
      name: 'Beam',
      position: 'สมาชิก',
      depart: 'HR',
      status: 'Inactive',
    },
  ]
  const [users, setUsers] = React.useState([] as any)
  //checkbox
  React.useEffect(() => {
    // setLeaderList(props.teamLeader)
    const newMemberArr = []
    newMemberArr.push(props.teamLeader)
    console.log('newMemberArr', newMemberArr)
    console.log('teamL', props.teamLeader)
    props.listMember.items.map((e: any) => {
      console.log('xx', e.getEmployee)

      if (e.getEmployee.id !== props.teamLeader.id) {
        newMemberArr.push(e.getEmployee)
      }
    })
    setMemberList(newMemberArr)

    setUsers(Team)
  }, [])

  const handleChange = (e: any) => {
    const { name, checked } = e.target
    if (name === 'allSelect') {
      const tempMember = memberList.map((member: any) => {
        return { ...member, isChecked: checked }
      })
      setMemberList(tempMember)
    } else {
      const tempMember = memberList.map((member: any) =>
        member.fullName === name ? { ...member, isChecked: checked } : member,
      )
      setMemberList(tempMember)
    }
  }
  //* Check
  console.log('listMember :', memberList)
  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Checkbox
                name="allSelect"
                checked={!memberList.some((member: any) => member?.isChecked !== true)}
                onChange={handleChange}
                sx={{
                  color: '#5357BB',
                  '&.Mui-checked': {
                    color: '#5357BB',
                  },
                }}
              />
            </TableCell>
            <TableCell align="center">
              <h3>ID</h3>
            </TableCell>
            <TableCell align="center">
              <h3>Name</h3>
            </TableCell>
            <TableCell align="center">
              <h3>ระดับ</h3>
            </TableCell>
            <TableCell align="center">
              <h3>ตำแหน่ง</h3>
            </TableCell>
            <TableCell align="left">
              <h3>Status</h3>
            </TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {memberList.map((member: any, index: any) => (
            <TableRow key={member.id} sx={{ '&:last-child td, &:last-child th': {} }}>
              <TableCell align="center">
                <Checkbox
                  name={member.fullName}
                  checked={member?.isChecked || false}
                  onChange={handleChange}
                  sx={{
                    color: '#5357BB',
                    '&.Mui-checked': {
                      color: '#5357BB',
                    },
                  }}
                />
              </TableCell>
              <TableCell align="center">{member.id}</TableCell>
              <TableCell align="center">{member.fullName}</TableCell>
              <TableCell align="center">{member.position.positionLevel}</TableCell>
              <TableCell align="center">{member.position.positionName}</TableCell>
              <TableCell align="center">
                {' '}
                <div className={styles.status}>
                  <div
                    className={member.status.toLowerCase() == 'active' ? styles.statusActive : styles.statusinActive}
                  >
                    {' '}
                  </div>
                  <p className={styles.text}>{member.status}</p>
                </div>
              </TableCell>
              <TableCell align="left">
                {' '}
                <div className={styles.action}>
                  {' '}
                  <button type="button" className={styles.btEdit}>
                    {' '}
                    <img src={editIcon} alt="" onClick={() => navigate(`../employee/team/edit/${member.id}`)} />
                  </button>
                  <button type="button" className={styles.btDelete}>
                    {' '}
                    <img
                      src={deleteIcon}
                      alt=""
                      onClick={() =>
                        dispatch(
                          allAction.modalConfirmAction.setOpenModalConfirm(
                            'ลบข้อมูลพนักงาน',
                            'คุณต้องการลบข้อมูลพนักงาน',
                            'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                            'ใช่,ลบข้อมูล',
                            () => {
                              dispatch(allAction.modalAction.setOpenModalAlertStatus('ลบข้อมูลสำเร็จ', '', 'success'))
                              dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                            },
                          ),
                        )
                      }
                    />
                  </button>
                  <div> </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
