import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Formik, useFormikContext } from 'formik'
import { InputNumberComponentCustom, InputTimeComponentCustom } from '../../../component'
import DelIcon from '../../../image/delete.svg'
import {
  InputNumberComponentCustomTran,
  InputRadioComponent,
  InputSelectComponentCustom,
} from '../../../component/InputsFormik'
import { minuteToHourHH, timeToMinute } from '../../../utils/common'
import Switch from '../../../component/Switch'
import { Backdrop, Modal } from '@mui/material'

interface PropsInputCount {
  _value: any
  _name: string
  _scanIn?: any
  attendance?: any
  _funtion?: any
  _scanOut?: any
}
export const InputCountDiff: React.FC<PropsInputCount> = (props: PropsInputCount): JSX.Element => {
  const { setFieldValue } = useFormikContext<any>()

  const CheckInDiff = (scanIn: any, startShift: any) => {
    const scanMinutes = timeToMinute(scanIn)
    const shiftMinutes = timeToMinute(startShift)
    const sum = scanMinutes - shiftMinutes
    if (sum > 60) {
      return 0
    } else if (sum < -60) {
      return 0
    }
    return sum
  }

  const CheckOutDiff = (scanIn: any, EndShift: any) => {
    const scanMinutes = timeToMinute(scanIn)
    const shiftMinutes = timeToMinute(EndShift)
    const sum = scanMinutes - shiftMinutes
    if (sum > 60) {
      return 0
    } else if (sum < -60) {
      return 0
    }
    return sum
  }

  const CalTimeOut = (scanIn: any) => {
    const Time = timeToMinute(scanIn) - 1
    return minuteToHourHH(Time)
  }

  const CalTimeLate = (scanIn: any) => {
    const Time = timeToMinute(scanIn) + 1
    return minuteToHourHH(Time)
  }

  const inDiff = CheckInDiff(props._scanIn, props.attendance?.shiftStartTime)
  const OutDiff = CheckOutDiff(props._scanOut, props.attendance?.shiftEndTime)

  return (
    <section className={styles.CountInput}>
      {props._name === 'inDiffMinute' && (
        <div>
          {inDiff < 0 ? <p className={styles.TextHead}>เข้าก่อน</p> : <p className={styles.TextHead}>เข้าสาย</p>}
        </div>
      )}
      {props._name === 'breakDiffMinute' && (
        <div>
          {props._value < 0 ? <p className={styles.TextHead}>พักเร็ว</p> : <p className={styles.TextHead}>พักเกิน</p>}
        </div>
      )}
      {props._name === 'outDiffMinute' && (
        <div>
          {OutDiff < 0 ? <p className={styles.TextHead}>ออกก่อน</p> : <p className={styles.TextHead}>ออกหลัง</p>}
        </div>
      )}

      {props._name === 'inDiffMinute' ? (
        <div className={styles.InputNumber}>
          <div
            className={styles.DelButton}
            onClick={() => {
              setFieldValue(props._name, props._value - 1)
              setFieldValue('scanIn', CalTimeOut(props._scanIn))
            }}
          >
            -
          </div>
          <div className={styles.inputCustom}>
            <InputNumberComponentCustom _name={props._name} _label={'นาที'} _value={inDiff} />
          </div>
          <div
            className={styles.AddButton}
            onClick={() => {
              setFieldValue(props._name, props._value + 1)
              setFieldValue('scanIn', CalTimeLate(props._scanIn))
            }}
          >
            +
          </div>
        </div>
      ) : props._name === 'outDiffMinute' ? (
        <div className={styles.InputNumber}>
          <div
            className={styles.DelButton}
            onClick={() => {
              setFieldValue(props._name, props._value - 1)
              setFieldValue('scanOut', CalTimeOut(props._scanOut))
            }}
          >
            -
          </div>
          <div className={styles.inputCustom}>
            <InputNumberComponentCustom _name={props._name} _label={'นาที'} _value={OutDiff} />
          </div>
          <div
            className={styles.AddButton}
            onClick={() => {
              setFieldValue(props._name, props._value + 1)
              setFieldValue('scanOut', CalTimeLate(props._scanOut))
            }}
          >
            +
          </div>
        </div>
      ) : (
        <div className={styles.InputNumber}>
          <div
            className={styles.DelButton}
            onClick={() => {
              setFieldValue(props._name, props._value - 1)
            }}
          >
            -
          </div>
          <div className={styles.inputCustom}>
            <InputNumberComponentCustom _name={props._name} _label={'นาที'} _value={props._value} />
          </div>
          <div className={styles.AddButton} onClick={() => setFieldValue(props._name, props._value + 1)}>
            +
          </div>
        </div>
      )}
    </section>
  )
}

//LeaveInput
export const LeaveInput: React.FC<any> = (props: any): JSX.Element => {
  return (
    <div>
      {props.values?.leaveList?.map((itemLeave: any, Index: number) => (
        <section className={Index % 2 == 0 ? styles.GroupOT : styles.GroupOTCover} key={Index}>
          <div className={styles.FormGroup}>
            <div>
              <div className={styles.toggle}>
                {' '}
                <div className={styles.GroupToggle}>
                  <button
                    type="button"
                    onClick={() => {
                      props.setFieldValue(`leaveList[${Index}].type`, 'leave')
                    }}
                    className={itemLeave.type === 'leave' ? styles.ButtonLeaveSelect : styles.ButtonLeaveDeduct}
                  >
                    ลาไม่หักเงิน
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      props.setFieldValue(`leaveList[${Index}].type`, 'leaveDeduct')
                    }}
                    className={itemLeave.type === 'leaveDeduct' ? styles.ButtonLeaveSelect : styles.ButtonLeaveDeduct}
                  >
                    ลาหักเงิน
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.filedInputFlex}>
              <p>ประเภทการลา</p>
              <InputSelectComponentCustom
                _name={`leaveList[${Index}].leaveType`}
                _label={'ประเภทการลา'}
                _menuList={[
                  { label: 'ลาป่วย', value: 'REASON_SICK' },
                  { label: 'ลาพักร้อน', value: 'REASON_ANNUAL_LEAVE' },
                  { label: 'ลากิจ', value: 'REASON_PERSONAL_LEAVE' },
                  {
                    label: 'ขาด',
                    value: 'ABSENT',
                  },
                ]}
                _value={itemLeave.leaveType}
              />
            </div>

            <div className={styles.filedInput}>
              <p>ช่วงเวลา</p>
              <InputTimeComponentCustom
                _name={`leaveList[${Index}].startedAt`}
                _label={'เริ่มต้น'}
                _value={itemLeave.startedAt}
              />
              <InputTimeComponentCustom
                _name={`leaveList[${Index}].endedAt`}
                _label={'สิ้นสุด'}
                _value={itemLeave.endedAt}
              />
            </div>

            {/* <div className={styles.filedInputFlex}>
        <p>แนบไฟล์</p>
        <InputSelectComponent
          _name={item.leaveDocumentAttachment}
          _label={''}
          _menuList={[]}
        />
      </div> */}
          </div>

          {props.values.leaveList.length > 1 ? (
            <img
              src={DelIcon}
              alt=""
              onClick={() => {
                props.values.leaveList.splice(Index, 1)
                props.setFieldValue(`leaveList`, props.values.leaveList)
                // console.log(props.values.leaveList, 'values')
              }}
            />
          ) : (
            ''
          )}
        </section>
      ))}
    </div>
  )
}

//SumCard ==> BottomCard Modal
export const SumCard: React.FC<any> = (props: any): JSX.Element => {
  const [typeDoc, setTypeDoc] = useState('')
  const [workTime, setWorkTime] = useState<any>(0)

  useEffect(() => {
    setWorkTime(props.attendance.workingMinute / 60)
  }, [])

  console.log(workTime, 'workTime')

  const handleChange = (event: any, newValue: any) => {
    setWorkTime(newValue)
  }

  const GetDocumentPendingOT = () => {
    const OvertimeList: any = []
    const List = props.value.overtimeList?.filter((item: any) => item.status === 'PENDING')
    OvertimeList.push(...List)
    const totalMinutesLeave = OvertimeList.map((item: any) => item.overtimeApproveMinute).reduce(
      (a: number, b: number) => a + b,
      0,
    )
    return minuteToHourHH(totalMinutesLeave)
  }

  // console.log(props.value, 'values')

  const GetDocumentPendingLeave = (status: string) => {
    const LeaveList: any = []
    const LeaveListApprove: any = []
    const List = props.value.leaveList?.filter((item: any) => item.status === 'PENDING')
    LeaveList.push(...List)

    const ListApprove = props.value.leaveList?.filter((item: any) => item.status === 'APPROVE')
    LeaveListApprove.push(...ListApprove)
    const totalMinutesPending = LeaveList.map((item: any) => item.leaveMinute).reduce(
      (a: number, b: number) => a + b,
      0,
    )
    const totalMinutesApprove = LeaveListApprove.map((item: any) => item.leaveMinute).reduce(
      (a: number, b: number) => a + b,
      0,
    )
    if (status === 'PENDING') {
      return minuteToHourHH(totalMinutesPending)
    } else if (status === 'APPROVE') {
      return minuteToHourHH(totalMinutesApprove)
    }
  }
  const GetDocumentPendingLeaveDeduct = (status: string) => {
    const LeaveList: any = []
    const LeaveListApprove: any = []
    const List = props.value.leaveDeductList?.filter((item: any) => item.status === 'PENDING')
    LeaveList.push(...List)
    const ListApprove = props.value.leaveDeductList?.filter((item: any) => item.status === 'APPROVE')
    LeaveListApprove.push(...ListApprove)
    const totalMinutesPending = LeaveList.map((item: any) => item.leaveMinute).reduce(
      (a: number, b: number) => a + b,
      0,
    )
    const totalMinutesApprove = LeaveListApprove.map((item: any) => item.leaveMinute).reduce(
      (a: number, b: number) => a + b,
      0,
    )

    console.log(totalMinutesApprove, 'totalMinutesApprove')
    console.log(totalMinutesPending, 'totalMinutesApprove')

    if (status === 'PENDING') {
      return minuteToHourHH(totalMinutesPending)
    } else if (status === 'APPROVE') {
      return minuteToHourHH(totalMinutesApprove)
    }
  }
  const values = props.value.values

  return (
    <>
      <div className={styles.Box}>
        <div className={styles.switch}>
          <Switch
            options={[
              { label: 'อนุมัติแล้ว', value: 'approve' },
              { label: 'รอการอนุมัติ', value: 'pending' },
            ]}
            onChange={(value: any) => {
              setTypeDoc(value)
            }}
          />
        </div>
      </div>

      {props.value.isLink === true ? (
        <div className={styles.SumCard}>
          <div className={styles.cardTotalWork}>
            <p className={styles.textTotal}>{minuteToHourHH(props.report.workingMinute)}</p>
            <p className={styles.text}>ทำงาน (ชม.)</p>
          </div>
          <div className={styles.cardTotalOT}>
            <p className={styles.textTotal}>
              {typeDoc === 'approve' ? minuteToHourHH(props.report.overtimeApproveMinute) : GetDocumentPendingOT()}
            </p>
            <p className={styles.text}>ล่วงเวลา (ชม.)</p>
          </div>
          <div className={styles.cardTotalLeave}>
            <div className={styles.GroupText}>
              <p className={styles.textTotal}>
                {typeDoc === 'approve'
                  ? GetDocumentPendingLeaveDeduct('APPROVE')
                  : GetDocumentPendingLeaveDeduct('PENDING')}
              </p>
              <p className={styles.text}>ลาแบบหักเงิน (ชม.)</p>
            </div>
            <div className={styles.line}></div>
            <div className={styles.GroupText}>
              <p className={styles.textTotal}>
                {typeDoc === 'approve' ? GetDocumentPendingLeave('APPROVE') : GetDocumentPendingLeave('PENDING')}
              </p>
              <p className={styles.text}>ลาแบบไม่หักเงิน (ชม.)</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.SumCard}>
          <div className={styles.cardTotalWork}>
            <input
              type={'number'}
              value={workTime}
              onChange={(e: any) => {
                setWorkTime(e.target.value)
                props.setFieldValue('workingMinute', e.target.value)
              }}
              className={styles.InputBox}
            />
            {/* <InputNumberComponentCustomTran _name="workingMinute" _value={props.value.workingMinute} /> */}
            <p className={styles.text}>ทำงาน (ชม.)</p>
          </div>
          <div className={styles.cardTotalOT}>
            <InputNumberComponentCustomTran _name="overtimeMinute" _value={props.value.overtimeMinute} />
            <p className={styles.text}>ล่วงเวลา (ชม.)</p>
          </div>
          <div className={styles.cardTotalLeave}>
            <div className={styles.GroupText}>
              <div className={styles.input}>
                <InputNumberComponentCustomTran _name="leaveDeductMinute" _value={props.value.leaveDeductMinute} />
              </div>
              <p className={styles.text}>ลาหักเงิน(ชม.)</p>
            </div>
            <div className={styles.line}></div>
            <div className={styles.GroupText}>
              <div className={styles.input}>
                <InputNumberComponentCustomTran _name="leaveMinute" _value={props.value.leaveMinute} />
              </div>
              <p className={styles.text}>ลาไม่หักเงิน(ชม.)</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const ModalMultiplierOT: React.FC<any> = (props: any): JSX.Element => {
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalMulti}>
        <div className={styles.card}>
          <Formik
            initialValues={{
              typeOT: 'work',
            }}
            // validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              console.log(values, 'values')
            }}
          >
            {(formikProps: any) => (
              <form className={styles.wrapperForm} onSubmit={formikProps.handleSubmit}>
                <header className={styles.headText}>กำหนดตัวคูณ OT</header>
                <article className={styles.GroupArea}>
                  <section className={styles.Group}>
                    <p className={styles.TextHead}>ประเภท OT</p>
                    <InputRadioComponent
                      _name="typeOT"
                      _menuList={[
                        { label: 'OT-Work', value: 'work' },
                        { label: 'OT-OT', value: 'overtime' },
                      ]}
                      _row
                    />
                  </section>
                  <section className={styles.Group}>
                    <p className={styles.TextHead}>ตัวคูณ OT</p>
                    <p>เดิม x 1.5 เท่า</p>
                    <InputNumberComponentCustom _name="overtimemulti" />
                    <p className={styles.textSub}>คิดจากเงินเดือนรายชั่วโมง</p>
                  </section>
                </article>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
