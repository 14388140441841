import React, { useRef } from 'react'
import styles from './index.module.scss'

import { Formik } from 'formik'
import * as yup from 'yup'
import { PaginationBar } from '../../../component'
import { TableReportPayroll } from './table'
import { paginationFunc } from '../../../utils/common'

const WorkListPayroll: React.FC<any> = (props: any): JSX.Element => {
  const validationSchema = yup.object({})
  const [pageSite, setPageSite] = React.useState<number>(10)
  const [listItemIndex, setListItemIndex] = React.useState<number>(0)
  const formikRef: any = useRef(null)
  const ReportPayroll = props.payrollList
  // console.log(ReportPayroll, 'Report')

  return (
    <Formik
      innerRef={formikRef}
      initialValues={
        {
          // income: mockUpIncome,
          // expense: mockUpExpense,
        }
      }
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        console.log(values, 'SUBMITTING')
      }}
    >
      {(props: any) => (
        <form className={styles.container} onSubmit={props.handleSubmit}>
          {/* <InputTextComponent
            _name="searchValues"
            _label="ค้นหาแผนก/รหัส/รายชื่อพนักงาน"
            _helperText={props.errors.searchValues}
          />
          <div className={styles.toolbarsContainer}>
            <section className={styles.leftToolbars}>
              <article className={styles.configBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="blue"
                  _colorText="white"
                  _variant="outlined"
                  _isIcon="expenseIncome"
                  _text="ตั้งค่ารายจ่าย"
                  _functionOnClick={() => {
                    console.log('button Click !!')
                  }}
                />
              </article>
              <article className={styles.filterBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="transparent"
                  _colorText="blue"
                  _variant="text"
                  _isIcon="filter"
                  _text="ตัวกรองข้อมูล"
                  _functionOnClick={() => {
                    console.log('button Click !!')
                  }}
                />
              </article>
            </section>
          </div> */}

          <section className={styles.TableReport}>
            <TableReportPayroll
              ReportPayroll={ReportPayroll}
              report={
                paginationFunc(ReportPayroll.timeAttendanceReportList, pageSite)[listItemIndex]
                  ? paginationFunc(ReportPayroll.timeAttendanceReportList, pageSite)[listItemIndex]
                  : []
              }
            />
            <PaginationBar
              _dropDownValue={pageSite}
              _onDropdownChange={(e: any) => {
                setPageSite(e.target.value)
                setListItemIndex(0)
              }}
              _selectedNumber={listItemIndex + 1}
              _numberList={paginationFunc(ReportPayroll.timeAttendanceReportList, pageSite).map(
                (e: any, index: number) => index + 1,
              )}
              _setSelectedNumber={(event: any, number: number, index: number) => {
                if (listItemIndex !== index) {
                  setListItemIndex(index)
                  // console.log(paginationFunc(ReportPayroll, pageSite).length, 'Pagination')
                  // if (index + 2 === paginationFunc(ReportPayroll, pageSite).length && nextToken) {
                  //   QueryAttendanceReport({ nextToken: nextToken }).then((res: any) => {
                  //     if (
                  //       res.data.data.listTimeAttendanceReportGroupByEmployee.items &&
                  //       res.data.data.listTimeAttendanceReportGroupByEmployee.items.length > 0
                  //     ) {
                  //       setListAttend([...listAttend, ...res.data.data.listTimeAttendanceReportGroupByEmployee.items])
                  //     }
                  //     setNextToken(res.data.data.listTimeAttendanceReportGroupByEmployee.nextToken)
                  //   })
                  // }
                }
              }}
            />
          </section>
        </form>
      )}
    </Formik>
  )
}

export default WorkListPayroll
