import { Backdrop, Divider, Fade, Modal, Popper, TextField, Tooltip } from '@mui/material'
import * as React from 'react'
import styles from './index.module.scss'
import Profile from '../LeaveDocument/image/profileCard.svg'
import CloseIcon from '../../image/closeGray.svg'
import { DateStyleShort, dateToTime, toTime } from 'src/utils/common'
import { EOverTime } from '../AttendanceReport/EType'
import { ButtonComponent } from 'src/component'
import GlassIcon from '../LeaveDocument/image/hourglass.svg'
import {
  InputDateComponentCustom,
  InputRadioComponent,
  InputTextComponentCustom,
  InputTimeComponentCustom,
} from 'src/component/InputsFormik'
import { Formik } from 'formik'
import { DeleteOvertimeDocument, GetOvertimeDocument, UpdateOvertimeDocument } from 'src/adapter/xhr'
import swal from 'sweetalert'
import DeleteIcon from '../../image/deleteRedIcon.svg'
import CheckStatusIcon from '../LeaveDocument/image/checkstatus.svg'

export const ModalView: React.FC<any> = (props: any): JSX.Element => {
  console.log(props.OvertimeDocument, 'OvertimeDocument')
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalBox}>
        <div className={styles.card}>
          <section className={styles.GroupArea}>
            <nav className={styles.HeadText}>
              <p className={styles.Text}>รายละเอียด</p>
              <img src={CloseIcon} alt="" className={styles.Icon} onClick={() => props.setOpen(false)} />
            </nav>

            <section className={styles.GroupProfile}>
              <div className={styles.ProfileIcon}>
                <img src={Profile} alt="" />
                <div className={styles.border}>
                  <img src={GlassIcon} alt="" />
                  <p>32</p>
                </div>
              </div>

              <div className={styles.GroupDetail}>
                {props.OvertimeDocument?.map((item: any, index: number) => (
                  <>
                    <section className={styles.TextProfile} key={index}>
                      <p>
                        {item.employee.fullName} {item.employee.lastName}
                      </p>
                      <p className={styles.textSub}>{item.employee.id}</p>
                      <span className={styles.textHead}>
                        {/* วันที่สร้างเอกสาร <span className={styles.text}> </span> */}
                      </span>
                    </section>

                    <section className={styles.Detail}>
                      <aside className={styles.leftSide}>
                        <div className={styles.Group}>
                          <span className={styles.textHead}>รหัสเอกสาร </span>
                          <Tooltip title={<p>{item.id}</p>} placement="top" arrow>
                            <div className={styles.TextOverflow}>
                              <p>{item.id}</p>
                            </div>
                          </Tooltip>
                        </div>

                        <span className={styles.textHead}>
                          ประเภทโอที <span className={styles.textSub}>{EOverTime[`${item.overtimeType}`]}</span>
                        </span>
                        <span className={styles.textHead}>
                          วันที่ <span className={styles.textSub}>{item.date}</span>
                        </span>
                        <span className={styles.textHead}>
                          หมายเหตุ <span className={styles.textSub}></span>
                        </span>
                      </aside>
                      <aside className={styles.leftSide}>
                        <div className={styles.Status}>
                          <span className={styles.textHead}>สถานะ</span>
                          <div
                            className={
                              item.status === 'APPROVE'
                                ? styles.pill
                                : item.status === 'REJECT'
                                ? styles.pillReject
                                : styles.pillPending
                            }
                          >
                            {item.status}
                          </div>
                        </div>

                        <span className={styles.textHead}>
                          เวลา{' '}
                          <span className={styles.textSub}>
                            {dateToTime(item.startedAt)} - {dateToTime(item.endedAt)}
                          </span>
                        </span>
                        <span className={styles.textHead}>
                          วันสิ้นสุด <span className={styles.textSub}>{DateStyleShort(item.endedAt)}</span>
                        </span>
                      </aside>
                    </section>
                  </>
                ))}
              </div>
            </section>
          </section>

          <div className={styles.FooterButton}>
            <ButtonComponent
              _colorBG="blue"
              _text="Close"
              _colorText="white"
              _type={'button'}
              _variant={'text'}
              _functionOnClick={() => props.setOpen(false)}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const ModalEdite: React.FC<any> = (props: any): JSX.Element => {
  console.log(props.employeeID, props.documentID, 'variables')
  const formikRef: any = React.useRef(null)
  const otherProps = props
  const [overtimeList, setOvertimeList] = React.useState<any>(null)

  console.log(props, 'props')

  React.useEffect(() => {
    if (!props.open) return
    GetOvertimeDocument({
      employeeId: props.employeeID,
      getOvertimeId: props.documentID,
    }).then((res: any) => {
      const data = res.data.data.getOvertime
      setOvertimeList(data)
      if (data.date) {
        formikRef.current.setFieldValue('date', data.date)
      }
      if (data.startedAt) {
        formikRef.current.setFieldValue('startedAt', toTime(data.startedAt))
      }
      if (data.endedAt) {
        formikRef.current.setFieldValue('endedAt', toTime(data.endedAt))
      }
      if (data.overtimeType) {
        formikRef.current.setFieldValue('overtimeType', data.overtimeType)
      }
      console.log(res, 'GETOVERTIME')
    })
  }, [props.employeeID, props.documentID, props.open])

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Formik
        initialValues={{
          date: '',
          startedAt: '',
          endedAt: '',
          overtimeType: '',
        }}
        innerRef={formikRef}
        onSubmit={(values, actions) => {
          console.log(values)
          const OvertimeVariables = {
            input: {
              id: props.documentID,
              employeeID: props.employeeID,
              date: values.date,
              startedAt: values.startedAt,
              endedAt: values.endedAt,
              overtimeType: values.overtimeType,
            },
          }
          swal('', {
            title: 'บันทึกการแก้ไขรายการเอกสารโอที',
            text: '',
            buttons: ['ยกเลิก', 'บันทึกข้อมูล'],
            icon: 'warning',
          }).then((submit) => {
            if (submit) {
              UpdateOvertimeDocument(OvertimeVariables).then((res: any) => {
                console.log(res, 'Update OK')
              })
              swal('', {
                title: 'บันทึกการแก้ไขเรียบร้อยแล้ว',
                icon: 'success',
              })
              props.setOpen(false)
              setTimeout(() => {
                props.QueryListOvertimeDocument()
              }, 1000)
            }
          })
          console.log(OvertimeVariables, 'OvertimeVariables')
        }}
      >
        {(props: any) => (
          <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
            <div className={styles.modalBox}>
              <div className={styles.card}>
                <section className={styles.GroupArea}>
                  <nav className={styles.HeadText}>
                    <p className={styles.Text}>แก้ไขรายละเอียด</p>
                    <img src={CloseIcon} alt="" className={styles.Icon} onClick={() => otherProps.setOpen(false)} />
                  </nav>

                  <section className={styles.GroupProfile}>
                    <div className={styles.ProfileIcon}>
                      <img src={Profile} alt="" />
                      <div className={styles.border}>
                        <img src={GlassIcon} alt="" />
                        <p>32</p>
                      </div>
                    </div>

                    <div className={styles.GroupDetail}>
                      {overtimeList ? (
                        <section className={styles.TextProfile}>
                          <p>
                            {overtimeList.employee?.fullName} {overtimeList.employee?.lastName}
                          </p>
                          <p className={styles.textSub}>{overtimeList.employee?.id}</p>
                          <span className={styles.textHead}>
                            {/* วันที่สร้างเอกสาร <span>{DateStyleShort(overtimeList?.createdAt)}</span> */}
                          </span>
                          <div className={styles.Status}>
                            <div
                              className={
                                overtimeList.status === 'APPROVE'
                                  ? styles.pill
                                  : overtimeList.status === 'REJECT'
                                  ? styles.pillReject
                                  : styles.pillPending
                              }
                            >
                              {overtimeList.status}
                            </div>
                          </div>
                        </section>
                      ) : (
                        ''
                      )}

                      <section className={styles.GroupInput}>
                        <section className={styles.Box}>
                          <p className={styles.textHead}>วันที่</p>
                          <InputDateComponentCustom _name="date" _label="วันที่" _value={props.values.date} />
                        </section>

                        <section className={styles.Box}>
                          <p className={styles.textHead}>ช่วงเวลา</p>
                          <div className={styles.BoxFlex}>
                            <InputTimeComponentCustom
                              _name="startedAt"
                              _label="เวลาเริ่ม"
                              _value={props.values.startedAt}
                            />
                            <InputTimeComponentCustom
                              _name="endedAt"
                              _label="เวลาสิ้นสุด"
                              _value={props.values.endedAt}
                            />
                          </div>
                        </section>

                        <section className={styles.Box}>
                          <p className={styles.textHead}>ประเภท</p>

                          <div>
                            <InputRadioComponent
                              _name="overtimeType"
                              _menuList={[
                                { label: 'OT-Work', value: 'WORK' },
                                { label: 'OT-OT', value: 'OVERTIME' },
                              ]}
                              _row
                            />
                          </div>
                        </section>

                        <section className={styles.Box}>
                          <p className={styles.textHead}>จำนวนชั่วโมง</p>
                          <>
                            <InputTextComponentCustom
                              _name="minutes"
                              _disabled={true}
                              // _value={DateToMinutes(
                              //   `${props.values.date}T${props.values.startedAt}+007`,
                              //   `${props.values.date}T${props.values.endedAt}+007`,
                              // )}
                            />
                          </>
                        </section>
                      </section>
                    </div>
                  </section>
                </section>

                <div className={styles.FooterButton}>
                  <img
                    src={DeleteIcon}
                    alt="del"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      swal('', {
                        icon: 'warning',
                        title: 'ลบเอกสารโอที',
                        text: 'ไม่สามารถเปลี่ยนแปลงการแก้ไขได้',
                        buttons: ['ยกเลิก', 'ลบข้อมูล'],
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          DeleteOvertimeDocument({
                            input: { id: props.documentID, employeeID: props.employeeID },
                          }).then((res: any) => {
                            console.log(res, 'Delete OK')
                          })
                          setTimeout(() => {
                            otherProps.QueryListOvertimeDocument()
                          }, 1000)
                          otherProps.setOpen(false)
                          swal('', {
                            icon: 'success',
                            title: 'ลบรายการเอกสารเรียบร้อยแล้ว',
                          })
                        }
                      })
                    }}
                  />
                  <ButtonComponent
                    _colorBG="blue"
                    _text="บันทึก"
                    _colorText="white"
                    _type={'submit'}
                    _variant={'text'}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
export const PopperFilter: React.FC<any> = (props: any): JSX.Element => {
  const OtherProps = props

  const handleClick = (value: any) => {
    if (props.filterType !== value) {
      props.setFilterType(value)
    } else {
      props.setFilterType('')
    }
  }

  const handleClickStatus = (value: any) => {
    if (props.setfillStatus !== value) {
      props.setfillStatus(value)
    } else {
      props.setfillStatus('')
    }
  }

  return (
    <Popper transition placement="bottom" anchorEl={props.anchorEl} open={props.openpopper}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div className={styles.Popper}>
            <Formik
              initialValues={{
                startdate: '',
                enddate: '',
              }}
              // validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  props.QueryListOvertimeDocument()
                  props.setOpenPopper(false)
                }, 1000)
              }}
            >
              {(props: any) => (
                <form className={styles.Popper} onSubmit={props.handleSubmit}>
                  <div className={styles.pointer}></div>
                  <div className={styles.modal}>
                    <div className={styles.modalGroup}>
                      <section className={styles.Header}>
                        <p>ค้นหาแบบละเอียด</p>
                        <img src={CloseIcon} alt="" />
                      </section>

                      <div className={styles.GroupDetail}>
                        <section className={styles.BoxFilter}>
                          <p style={{ fontSize: '16px' }}>ค้นหาตามประเภท</p>
                          <div className={styles.GroupCard}>
                            {OTType.map((item: any, index: number) => (
                              <div
                                className={OtherProps.filterType === item.value ? styles.CardBlue : styles.Card}
                                key={index}
                                onClick={() => {
                                  handleClick(item.value)
                                }}
                              >
                                {item.label}
                              </div>
                            ))}
                          </div>
                        </section>
                        <Divider />

                        <section className={styles.BoxFilter}>
                          <p style={{ fontSize: '16px' }}>วันที่</p>
                          <div className={styles.GroupCardDate}>
                            <div className={styles.InputBox}>
                              <TextField
                                InputProps={{
                                  style: {
                                    fontFamily: 'KanitRegular',
                                    color: 'gray',
                                    borderRadius: 4,
                                  },
                                }}
                                fullWidth
                                size="small"
                                type="date"
                                label="วันที่"
                                name="startdate"
                                value={OtherProps.fillStartdate}
                                onChange={(e) => {
                                  OtherProps.setfillStartdate(e.target.value)
                                  console.log(e.target.value, 'e')
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: 14, fontFamily: 'KanitRegular' },
                                }}
                              />{' '}
                            </div>
                          </div>
                        </section>
                        <Divider />

                        <section className={styles.BoxFilter}>
                          <p style={{ fontSize: '16px' }}>สถานะ</p>
                          <div className={styles.GroupPill}>
                            {Status.map((item: any, index: number) => (
                              <div className={styles.Status} key={index}>
                                <div
                                  className={
                                    item.status === 'APPROVE'
                                      ? styles.pill
                                      : item.status === 'REJECT'
                                      ? styles.pillReject
                                      : styles.pillPending
                                  }
                                  onClick={() => {
                                    handleClickStatus(item.status)
                                  }}
                                >
                                  {item.status}
                                  {item.status === OtherProps.fillStatus ? (
                                    <img src={CheckStatusIcon} alt="checkstatus" className={styles.checkIcon} />
                                  ) : null}
                                </div>
                              </div>
                            ))}
                          </div>
                        </section>
                      </div>

                      <section className={styles.FooterButton}>
                        <ButtonComponent
                          _colorBG="white"
                          _text="ล้าง"
                          _type="button"
                          _variant="outlined"
                          _colorText="red"
                          _functionOnClick={() => {
                            OtherProps.setFilterType('')
                            OtherProps.setfillStartdate('')
                            OtherProps.setfillStatus('')
                          }}
                        />
                        <ButtonComponent
                          _colorBG="blue"
                          _text="ตกลง"
                          _type="button"
                          _variant="contained"
                          _colorText="white"
                          _functionOnClick={() => {
                            setTimeout(() => {
                              OtherProps.QueryListOvertimeDocument()
                              OtherProps.setOpenPopper(false)
                            }, 1000)
                          }}
                        />
                      </section>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Fade>
      )}
    </Popper>
  )
}

const OTType = [
  { label: 'โอทีวันทำงาน', value: 'WORK' },
  { label: 'โอทีวันหยุด', value: 'OVERTIME' },
]
const LeaveForm = ['ลาเต็มวัน', 'ลาครึ่งวันเช้า', 'ลาครึ่งวันบ่าย', 'ลาแบบกำหนดเอง']

const Status = [
  {
    status: 'APPROVE',
  },
  {
    status: 'PENDING',
  },
  {
    status: 'REJECT',
  },
]
