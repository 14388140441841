import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Fade, TextField, Tooltip } from '@mui/material'
import styles from './index.module.scss'
import NoteIcon from '../../../image/note.svg'
import quesIcon from '../../../image/question.svg'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Popper from '@mui/material/Popper'
import closeIcon from '../../../image/close.svg'
import NoteDataIcon from '../../../image/notedata.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { QueryGetAttendance, QueryListPayroll } from '../../../adapter/xhr'
import { useFormikContext } from 'formik'
import { minuteToHour } from '../../../utils/common'
import { ELeave, toTime } from '../../AttendanceReport/EType'

//TableReportPayroll   console.log(props.report.timeAttendanceReportList, 'props.ReportPayroll')

export const TableReportPayroll: React.FC<any> = (props: any): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [modalIndex, setModalIndex] = React.useState<any>()
  const [modalRowIndex, setModalRowIndex] = React.useState<any>()
  const [remarkIndex, setRemarkIndex] = React.useState<number>(0)

  const handleClick = (index: number, RowIndex: number) => {
    setModalIndex(index)
    setModalRowIndex(RowIndex)
  }

  const handleClickPopper = (event: any, index: number) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
    setRemarkIndex(index)
  }

  const handleClose = (index: number) => {
    setAnchorEl(null)
    setRemarkIndex(index - 1)
  }
  const EDayType: any = {
    WORKDAY: 'วันทำงาน',
    WEEKEND: 'วันหยุดสุดสัปดาห์',
    HOLIDAY: 'วันหยุดประจำปี',
  }

  //Colors Rows

  const statusStyle = (Type: any) => {
    switch (Type) {
      case 'ATTENDANCE':
        return '#FFFFFF'
        break
      case 'OVERTIME':
        return '#F8E8BD'
        break
      case 'LEAVE':
        return '#FFC5C8'
        break
      case 'WEEKEND':
        return '#DFDFDE'
        break
      case 'HOLIDAY':
        return '#E9C4E9'
        break
      case 'INCOMPLETE':
        return '#F5F4F6'
        break
      default:
        return '#FFFFFF'
    }
  }

  const Border = (Type: any) => {
    switch (Type) {
      case 'INCOMPLETE':
        return '#F32424'
        break
      default:
        return 'none'
    }
  }

  console.log(props.report, 'props.report')

  const Header = [
    'วันที่',
    'กะการทำงาน',
    'เวลาทำงานจริง',
    'ชั่วโมงการทำงานจริง',
    'การทำงานล่วงเวลา',
    'ลาหักเงิน',
    'ลาไม่หักเงิน',
    '',
  ]

  return (
    <>
      {props.report ? (
        <div>
          <div>
            {props.report.map((EmpItem: any, EmpIndex: number) => (
              <Accordion
                key={EmpIndex}
                sx={{ backgroundColor: '#CAD6EA', borderRadius: '7px 7px 0px 0px', border: 'none' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    position: 'sticky',
                    top: '0',
                    backgroundColor: '#CAD6EA',
                    borderRadius: '7px 7px 0px 0px',
                    border: 'none',
                  }}
                >
                  <section className={styles.HeadEmployee}>
                    <span className={styles.title}>
                      แผนก : <span className={styles.subtext}>{EmpItem.employee.department.departmentName}</span>
                    </span>
                    <div className={styles.Group}>
                      <span className={styles.title}>
                        ชื่อ - สกุล :
                        <span className={styles.subtext}>
                          {EmpItem.employee.fullName} {EmpItem.employee.lastName}
                        </span>
                      </span>
                      <span className={styles.title}>
                        รหัส : <span className={styles.subtext}>{EmpItem.employee.id}</span>
                      </span>
                    </div>
                  </section>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.tableContainer}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <p>วันที่</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>กะการทำงาน</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>เวลาทำงานจริง</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>ชั่วโมงการทำงานจริง</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>การทำงานล่วงเวลา</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>ลาหักเงิน</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>ลาไม่หักเงิน</p>
                          </TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {EmpItem.items.map((itemRow: any, RowIndex: any) => (
                          <TableRow sx={{ backgroundColor: statusStyle(itemRow.Type) }} key={RowIndex}>
                            <TableCell align="left">
                              <div className={styles.GroupDate}>
                                <p>
                                  {new Date(`${itemRow.date.slice('', 10)}`).toLocaleDateString('th-TH', {
                                    weekday: 'long',
                                  })}
                                </p>

                                <div
                                  className={
                                    itemRow.dayType === 'WORKDAY'
                                      ? styles.pill
                                      : itemRow.dayType === 'WEEKEND'
                                      ? styles.pillWeek
                                      : itemRow.dayType === 'HOLIDAY'
                                      ? styles.pillHoliday
                                      : ''
                                  }
                                >
                                  <p className={styles.textDay}>{EDayType[`${itemRow.dayType}`]}</p>
                                </div>
                              </div>

                              <p> {itemRow.date.split('', 10)}</p>
                            </TableCell>
                            <TableCell align="center">
                              {itemRow.shiftName ? (
                                <Tooltip
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: 'common.white',
                                        '& .MuiTooltip-arrow': {
                                          color: 'common.white',
                                        },
                                      },
                                    },
                                  }}
                                  title={
                                    <div className={styles.tooltip}>
                                      <div className={styles.head}>
                                        <section className={styles.shiftVer}>
                                          <p>{itemRow.shiftVersion}</p>
                                        </section>
                                      </div>

                                      <p>
                                        {itemRow.shiftStartTime} - {itemRow.shiftEndTime}
                                      </p>
                                      <p>{itemRow.shiftMinute / 60} ชั่วโมง</p>
                                    </div>
                                  }
                                  placement="top-start"
                                  arrow={true}
                                >
                                  <div className={styles.textShift}>
                                    <p> {itemRow.shiftName}</p> <img src={quesIcon} alt="" />{' '}
                                  </div>
                                </Tooltip>
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <div>
                                {itemRow.scanIn ? (
                                  <div className={styles.group}>
                                    {' '}
                                    <div className={itemRow.inDiffMinute > 0 ? styles.textScanLate : styles.textScan}>
                                      <p>{toTime(itemRow.scanIn)}</p>
                                      <div className={styles.groupPill}>
                                        <section className={styles.pillDiffEarly}>
                                          {itemRow.inDiffMinute > 0 ? <p>มาสาย</p> : <p>มาก่อน</p>}
                                        </section>
                                        <p className={styles.textIn}>{itemRow.inDiffMinute}</p>
                                      </div>
                                    </div>
                                    <div
                                      className={itemRow.outDiffMinute < 0 ? styles.textScanLate : styles.textScanOut}
                                    >
                                      <p>{toTime(itemRow.scanOut)}</p>
                                      <div className={styles.groupPill}>
                                        <section className={styles.pillDiffEarly}>
                                          {itemRow.outDiffMinute < 0 ? <p>ออกก่อน</p> : <p>ออกหลัง</p>}
                                        </section>
                                        <p className={styles.textIn}>{itemRow.outDiffMinute}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <h4>{minuteToHour(itemRow.workingMinute)} </h4>
                            </TableCell>
                            <TableCell align="center">
                              <h4> {minuteToHour(itemRow.overtimeMinute)} </h4>
                            </TableCell>
                            <TableCell align="center">
                              <h4>{minuteToHour(itemRow.leaveDeductMinute)}</h4>
                              <div className={styles.group}>
                                {itemRow?.leaveList?.slice(0, 2).map((itemLeave: any, IndexLeave: number) => (
                                  <div className={styles.groupPill} key={IndexLeave}>
                                    <section className={styles.pillDiffEarly}>
                                      {ELeave[`${itemLeave.leaveType}`]}
                                    </section>
                                  </div>
                                ))}
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <h4>{minuteToHour(itemRow.leaveMinute)}</h4>
                              <div className={styles.group}>
                                {itemRow?.leaveList?.slice(0, 2).map((itemLeave: any, IndexLeave: number) => (
                                  <div className={styles.groupPill} key={IndexLeave}>
                                    <section className={styles.pillDiffEarly}>
                                      {ELeave[`${itemLeave.leaveType}`]}
                                    </section>
                                  </div>
                                ))}
                              </div>
                            </TableCell>

                            <TableCell align="center">
                              <div className={styles.action}>
                                {itemRow.remark === null || itemRow.remark === '' ? (
                                  <img src={NoteIcon} alt="" onClick={(e: any) => handleClickPopper(e, EmpIndex)} />
                                ) : (
                                  <img src={NoteDataIcon} alt="" onClick={(e: any) => handleClickPopper(e, EmpIndex)} />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
            <Popper open={open} anchorEl={anchorEl} transition placement="left">
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <div className={styles.area}>
                    {' '}
                    <div className={styles.modal}>
                      <div className={styles.modalGroup}>
                        <div className={styles.header}>
                          {' '}
                          <p className={styles.text}>หมายเหตุ</p>
                          <img
                            src={closeIcon}
                            alt=""
                            className={styles.close}
                            onClick={() => handleClose(remarkIndex)}
                          />
                        </div>
                        <TextField
                          id="outlined-textarea"
                          name="remark"
                          type="text"
                          multiline
                          // value={remark}
                          className={styles.textFieldBG}
                        />
                      </div>
                    </div>
                    <div className={styles.pointer}></div>
                  </div>
                </Fade>
              )}
            </Popper>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
