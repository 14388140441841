import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
  NavTopBarComponent,
  NavSideBarComponent,
  ButtonComponent,
  InputTextComponent,
  ButtonSelect,
  InputSelectComponent,
  InputDateComponent,
  TableCheck,
} from '../../component'

//* Table
// import { TableData } from './table'
//* API
import { queryListApproveAttendance } from '../../adapter/xhr'
// import { ConstructionOutlined } from '@mui/icons-material'

// const leftHeader = ['ID', 'Name', 'ตำแหน่ง', 'ประเภทงาน']
// const rightHeader = ['วันที่เริ่มต้น', 'วันที่สิ้นสุด']
// const contentHeader = [
//   'จำนวนวันลาทั้งหมด',
//   'เวลามาสาย',
//   'ชั่วโมงการทำงานล่วงเวลา',
//   'จำนวนการมาทำงาน',
//   'สวัสดิการ',
//   'สถานะ',
// ]

const ApprovalAttendance: React.FC = (): JSX.Element => {
  const [listApproveAttendance, setListApproveAttendance] = useState([])
  const [isCheckList, setIsCheckList] = useState<boolean[]>([true, true])
  // console.log('>> Check', isCheckList)
  const [isCheckListMultiple, setIsCheckListMultiple] = useState([true, false]) //! CheckBox Multiple
  console.log('>> Check', isCheckList)
  console.log('>>> RES >>>', listApproveAttendance)
  const [dataApproveTable, setDataApproveTable] = useState<any>([]) //* Data in table
  // console.log('dataApproveTable >>', dataApproveTable)
  useEffect(() => {
    const listApproveAttendanceValiable = {
      filter: {
        employeeID: null,
        fullName: null,
        status: null,
        startedAt: null,
        endedAt: null,
      },
    }
    queryListApproveAttendance(listApproveAttendanceValiable).then((res: any) => {
      // console.log('RES >> listAttendance', res.data.data.listApproveAttendance.items)
      // setListApproveAttendance(res.data.data.listApproveAttendance.items)
      const newData: any[] = []
      const apiClone = []

      if (res?.data?.data?.listApproveAttendance?.items) {
        res.data.data.listApproveAttendance.items.map((listApproveAttendanceElement: any, index: number) => {
          console.log(listApproveAttendanceElement, 'eeeeeeee')
          return newData.push({
            isCheck: false,
            tableTopicStart: [
              listApproveAttendanceElement.id,
              // listApproveAttendanceElement.employee.fullName,
              // listApproveAttendanceElement.employee.lastName, //*  e.employee.position.positionName
              // listApproveAttendanceElement.employee.position.positionName,
              // listApproveAttendanceElement.employee.jobType,
            ],
            tableTopicEnd: [
              new Date(listApproveAttendanceElement.startedAt).toLocaleDateString('en-GB'),
              new Date(listApproveAttendanceElement.endedAt).toLocaleDateString('en-GB'),
            ], //new Date(props.date).toLocaleDateString('en-GB')
            data: [
              {
                content: [
                  `${listApproveAttendanceElement.listLeave.map((e: any) => {
                    const dayLeave: any = (new Date(e.endedAt).getTime() - new Date(e.startedAt).getTime()) / 86400
                    return parseInt(dayLeave)
                  })}วัน`,
                  `${listApproveAttendanceElement.listAttendanceReport
                    .filter((e: any) => e.lateMinute !== null)
                    .map((late: any) => {
                      return parseInt(late.lateMinute)
                    })
                    .reduce((a: number, b: number) => a + b, 0)} นาที`,
                  `${listApproveAttendanceElement.listOvertime
                    .map((e: any) => e.overtimeHour)
                    .reduce((a: number, b: number) => a + b, 0)} ชั่วโมง`,
                  `${listApproveAttendanceElement.listAttendanceReport.length} วัน`,
                  listApproveAttendanceElement.listCompensation.map((com: any) => {
                    return (
                      <>
                        ใช้จริง {com.compensationUsed} บาท <br /> จ่ายจริง {com.compensationPaid} บาท
                      </>
                    )
                  }),
                  listApproveAttendanceElement.status,
                ],
              },
            ],
            viewOnclick: () => {
              console.log('modal is open')
            },
            editOnclick: () => {
              // console.log('ID >>> ', editIndex)
              navigate(`../wage/approval/edit/${listApproveAttendanceElement.id}`)
            },
            deleteOnclick: () => {
              console.log('row is deleted')
            },
          })
        })
        console.log(newData, 'newData')
        setDataApproveTable(newData)
      }
    })
  }, [])
  const arrayData = []
  listApproveAttendance.map((e: any) => {
    return arrayData.push({ tableTopicStart: [e.id] })
  })

  const navigate = useNavigate()
  const Data = [
    {
      id: '1',
      title: 'จำนวนพนักงานทั้งหมด',
      item: '10',
      subtitle: 'จำนวนพนักงาน',
    },
    {
      id: '2',
      title: 'Pending',
      item: '4',
      subtitle: 'จำนวนพนักงาน',
    },
    {
      id: '3',
      title: 'Pay',
      item: '6',
      subtitle: 'จำนวนพนักงาน',
    },
  ]
  const validationSchema = yup.object({})
  //! -------------------------------------
  const mockUpApproveList = [
    {
      isCheck: false,
      tableTopicStart: [
        'id:344',
        'Name-1',
        'LastNAme-2',
        'positionName',
        // listApproveAttendanceElement.employee.lastName, //*  e.employee.position.positionName
        // listApproveAttendanceElement.employee.position.positionName,
        // listApproveAttendanceElement.employee.jobType,
      ],
      tableTopicEnd: ['11', '12'], //new Date(props.date).toLocaleDateString('en-GB')
      data: [
        {
          content: [
            `34วัน`,
            `56นาที`,
            `41 ชั่วโมง`,
            `21 วัน`,

            <>
              ใช้จริง 230 บาท <br /> จ่ายจริง 20 บาท
            </>,
            'Pending',

            //todo  > index is not correct
          ],
        },
      ],
      viewOnclick: () => {
        console.log('modal is open')
      },
      editOnclick: () => {
        console.log('ID >>> 1')
        navigate(`../wage/approval/edit/index0`)
      },
      deleteOnclick: () => {
        console.log('row is deleted')
      },
    },
    {
      isCheck: false,
      tableTopicStart: [
        'id:55555',
        'Name-2',
        'LastNAme-5',
        'positionName',
        // listApproveAttendanceElement.employee.lastName, //*  e.employee.position.positionName
        // listApproveAttendanceElement.employee.position.positionName,
        // listApproveAttendanceElement.employee.jobType,
      ],
      tableTopicEnd: ['11/03/2022', '12/03/2022'], //new Date(props.date).toLocaleDateString('en-GB')
      data: [
        {
          content: [
            `88วัน`,
            `77นาที`,
            `33 ชั่วโมง`,
            `23 วัน`,
            <>
              ใช้จริง 55 บาท <br /> จ่ายจริง 100 บาท
            </>,
            'Success',
          ],
        },
      ],
      viewOnclick: () => {
        console.log('modal is open')
      },
      editOnclick: () => {
        console.log('ID >>> 2')
        navigate(`../wage/approval/edit/index1`)
      },
      deleteOnclick: () => {
        console.log('row is deleted')
      },
    },
  ]
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            <h1 className={styles.title}>อนุมัตวันทำงาน</h1>
          </div>

          <div className={styles.cardSearch}>
            <Formik
              initialValues={{
                employeeId: '',
                name: '',
                status: '',
                startdate: '',
                enddate: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2))
                  actions.setSubmitting(false)
                }, 1000)
              }}
            >
              {(props: any) => (
                <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                  <div className={styles.InputArea}>
                    <div className={styles.inputBox}>
                      <InputTextComponent
                        _name="employeeId"
                        _label="รหัสพนักงาน"
                        _helperText={props.errors.employeeId}
                      />
                    </div>
                    <div className={styles.inputBox}>
                      <InputTextComponent _name="name" _label="ชื่อ - นามสกุล" _helperText={props.errors.username} />
                    </div>
                    <div className={styles.inputBox}>
                      <InputSelectComponent
                        _name="status"
                        _label="สถานะ"
                        _helperText={props.errors.selected}
                        _menuList={[
                          { label: 'Draft', value: 'draft' },
                          { label: 'Success', value: 'success' },
                        ]}
                      />
                    </div>
                  </div>
                  <div className={styles.InputArea}>
                    <div className={styles.inputBox}>
                      <InputDateComponent _name="startdate" _label="วันที่เริ่ม" _helperText={props.errors.hiredate} />
                    </div>

                    <span className={styles.text}>-</span>
                    <div className={styles.inputBox}>
                      <InputDateComponent _name="enddate" _label="วันที่สิ้นสุด" _helperText={props.errors.enddate} />
                    </div>
                  </div>

                  <div className={styles.btSearch}>
                    <ButtonComponent
                      _isIcon="search"
                      _variant="contained"
                      _text="ค้นหา"
                      _colorText="white"
                      _colorBG="blue"
                      _type="button"
                      _functionOnClick={() => console.log('search')}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className={styles.cardPayroll}>
            <div className={styles.content}>
              {Data.map((data, index) => {
                return (
                  <div className={styles.card} key={index}>
                    <p className={styles.title}>{data.title}</p>
                    <p className={styles.textNumber}>{data.item}</p>
                    <p className={styles.subtitle}>{data.subtitle}</p>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.cardTable}>
            <div className={styles.head}>
              <h1 className={styles.title}>รายชื่อพนักงาน</h1>
              <div className={styles.buttonBox}>
                <div className={styles.btImport}>
                  <ButtonComponent
                    _variant="contained"
                    _text="Import"
                    _colorText="white"
                    _colorBG="secondary"
                    _type="button"
                    _functionOnClick={() => navigate('ot/create')}
                  />
                </div>

                <div className={styles.btExport}>
                  <ButtonSelect
                    _text="Export"
                    _colorBG="secondary"
                    _colorText="white"
                    _variant="outlined"
                    _selectData={[]}
                  />
                </div>

                <div className={styles.btAdd}>
                  <ButtonComponent
                    _isIcon="add"
                    _variant="contained"
                    _text="เพิ่มรายการอนุมัติ"
                    _colorText="white"
                    _colorBG="blue"
                    _type="button"
                    _functionOnClick={() => navigate('../wage/approval/add')}
                  />
                </div>
              </div>
            </div>
            <div className={styles.table}>
              <TableCheck
                _dataList={mockUpApproveList}
                // _ClickEdit={() => navigate('/wage/approval/edit/:id')}
                _checkList={isCheckList}
                _setCheckList={setIsCheckList}
              />
            </div>

            {/* <div className={styles.sumBox}>
              <p className={styles.textsum}>ยอดรวมเงินเดือน ธันวาคม : 200,000 บาท</p>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer">Footer</div>
    </div>
  )
}

export default ApprovalAttendance
