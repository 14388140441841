import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import styles from './index.module.scss'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import dropIcon from '../../image/sort.svg'
import upIcon from '../../image/sortUp.svg'
import NextLogo from '../../image/next.svg'
import Checkbox from '@mui/material/Checkbox'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
export const TableData: React.FC<any> = (props: any): JSX.Element => {
  const navigate = useNavigate()
  const team = [
    {
      no: 1,
      name: 'Team A',
      detail: 'Team to manage Project HR',
      users: 8,
    },
    {
      no: 2,
      name: 'Team B',
      detail: 'Team to manage Project HR',
      users: 5,
    },
  ]
  const [users, setUsers] = useState([] as any)
  //sorting
  const [order, setOrder] = useState('ASC')
  const sorting = (col: any) => {
    if (order === 'ASC') {
      const sorted = [...users].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1))
      setUsers(sorted)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...users].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1))
      setUsers(sorted)
      setOrder('ASC')
    }
  }
  useEffect(() => {
    setUsers(team)
  }, [])

  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <div className={styles.headcolumn}>
                <h3>Team</h3>{' '}
                {order === 'ASC' ? (
                  <img src={dropIcon} onClick={() => sorting('name')} />
                ) : (
                  <img src={upIcon} onClick={() => sorting('name')} />
                )}
              </div>
            </TableCell>
            <TableCell align="center" style={{ minWidth: '100px' }}>
              <h3>Detail</h3>
            </TableCell>
            <TableCell align="center" style={{ minWidth: '100px' }}>
              <h3>Number of users</h3>
            </TableCell>
            <TableCell align="center" style={{ minWidth: '120px' }}></TableCell>
            <TableCell align="center" style={{ minWidth: '120px' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: any, index: any) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': {} }}>
              {/* https://github.com/mui/material-ui/issues/17791 */}
              <TableCell component={'th' as any} scope="row" align="left">
                {user.name}
              </TableCell>
              <TableCell align="center">{user.detail}</TableCell>
              <TableCell align="center">
                <div className={styles.numberus}>
                  <div className={styles.boxtext}>
                    <span className={styles.text}> {user.users} Users</span>
                  </div>
                </div>
              </TableCell>
              <TableCell align="center">
                {' '}
                <AvatarGroup max={5}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                  <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                  <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                  <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                  <Avatar alt="Trevor Henderson" src="/static/images/avatar/6.jpg" />
                  <Avatar alt="Trevor Henderson" src="/static/images/avatar/7.jpg" />
                  <Avatar alt="Trevor Henderson" src="/static/images/avatar/8.jpg" />
                </AvatarGroup>
              </TableCell>
              <TableCell align="center">
                <img
                  src={NextLogo}
                  alt=""
                  className={styles.nextLogo}
                  onClick={() => navigate('../usermanage/team/adduser')}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export const TableUser: React.FC<any> = (props: any): JSX.Element => {
  const navigate = useNavigate()
  const userData = [
    {
      no: 1,
      name: 'Beam',
      email: 'Thanyarat@gmail.com',
      path: '/static/images/avatar/1.jpg',
      role: 'User',
      department: 'Product',
      activity: '2 minutes ago',
      date: '18/11/2565',
      status: 'Pending',
    },
    {
      no: 2,
      name: 'Thanyarat.S',
      email: 'Thanyarat@gmail.com',
      path: '/static/images/avatar/1.jpg',
      role: 'Admin',
      department: 'Marketing',
      activity: '5 minutes ago',
      date: '16/11/2565',
      status: 'Verified',
    },
  ]
  const [users, setUsers] = useState([] as any)
  //sorting
  const [order, setOrder] = useState('ASC')
  const sorting = (col: any) => {
    if (order === 'ASC') {
      const sorted = [...users].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1))
      setUsers(sorted)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...users].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1))
      setUsers(sorted)
      setOrder('ASC')
    }
  }

  //checkbox
  useEffect(() => {
    setUsers(userData)
  }, [])
  const handleChange = (e: any) => {
    const { name, checked } = e.target
    if (name === 'allSelect') {
      const tempUser = users.map((user: any) => {
        return { ...user, isChecked: checked }
      })
      setUsers(tempUser)
    } else {
      const tempUser = users.map((user: any) => (user.name === name ? { ...user, isChecked: checked } : user))
      setUsers(tempUser)
    }
  }

  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Checkbox
                name="allSelect"
                checked={!users.some((user: any) => user?.isChecked !== true)}
                onChange={handleChange}
                sx={{
                  color: '#5357BB',
                  '&.Mui-checked': {
                    color: '#5357BB',
                  },
                }}
              />
            </TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="left" style={{ minWidth: '100px' }}>
              <div className={styles.headcolumn}>
                <h3>Username</h3>{' '}
                {order === 'ASC' ? (
                  <img src={dropIcon} onClick={() => sorting('name')} />
                ) : (
                  <img src={upIcon} onClick={() => sorting('name')} />
                )}
              </div>
            </TableCell>
            <TableCell align="left" style={{ minWidth: '100px' }}>
              <h3>Role</h3>
            </TableCell>
            <TableCell align="center" style={{ minWidth: '120px' }}>
              <h3>Department</h3>
            </TableCell>
            <TableCell align="left" style={{ minWidth: '100px' }}>
              <div className={styles.headcolumn}>
                <h3>Last Activity</h3>{' '}
                {order === 'ASC' ? (
                  <img src={dropIcon} onClick={() => sorting('activity')} />
                ) : (
                  <img src={upIcon} onClick={() => sorting('activity')} />
                )}
              </div>
            </TableCell>
            <TableCell align="left" style={{ minWidth: '100px' }}>
              <div className={styles.headcolumn}>
                <h3>Create date</h3>{' '}
                {order === 'ASC' ? (
                  <img src={dropIcon} onClick={() => sorting('date')} />
                ) : (
                  <img src={upIcon} onClick={() => sorting('date')} />
                )}
              </div>
            </TableCell>
            <TableCell align="center" style={{ minWidth: '120px' }}>
              <h3>Status</h3>
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: any, index: any) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': {} }}>
              <TableCell align="center">
                <Checkbox
                  name={user.name}
                  checked={user?.isChecked || false}
                  onChange={handleChange}
                  sx={{
                    color: '#5357BB',
                    '&.Mui-checked': {
                      color: '#5357BB',
                    },
                  }}
                />
              </TableCell>
              <TableCell component={'th' as any} scope="row" align="center">
                <Avatar alt="" src={user.path} />
              </TableCell>
              <TableCell align="left">
                {user.name}
                <br />
                {user.email}
              </TableCell>
              <TableCell align="left">{user.role}</TableCell>
              <TableCell align="center">{user.department}</TableCell>
              <TableCell align="left">
                Last Login <br />
                {user.activity}
              </TableCell>
              <TableCell align="left">{user.date}</TableCell>
              <TableCell align="center">
                <p className={user.status === 'Pending' ? styles.pendingText : styles.verifiedText}>{user.status}</p>
              </TableCell>
              <TableCell align="center">
                <img src={NextLogo} alt="" onClick={() => navigate('')} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
