import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { NavSideBarComponent, NavTopBarComponent } from '../../component'
import Calendar from '../../image/carendarItem.png'
import Cross from '../../image/cross.png'
// import { number } from 'yup'
import ChatProgressBar from './chartProgress'
// import ChartComponent from './chart'
import GraphBarLineCard from './chart'
// import { Padding } from '@mui/icons-material'
import { TableAttend } from './TableAttendance'
// import { TableAttendReport } from '../AttendanceReport/TableAttendance'
// import { height } from '@mui/system'

const Dashboard: React.FC = (): JSX.Element => {
  const today = new Date()
  //   const [comeBefore, setComeBefore] = useState(200)
  //   const [comeLate, setComeLate] = useState(14)
  //   const [outBefore, setOutBefore] = useState(150)
  //   const [outLate, setOutLate] = useState(60)
  //   const [nameDepartment, setNameDepartment] = useState('เเผนกบัญชี')
  // const [test, setTest] = useState<any>()
  const [capacityType, setCapacityType] = useState<any>('day')
  const [modalLeave, setModalLeave] = useState(false)
  const mockPayAll = [
    {
      id: '1',
      name: '2564',
      oneDepartment: [
        {
          name: 'แผนกบัญชี',
          useMoney: 150000,
        },
        {
          name: 'แผนกการตลาด',
          useMoney: 225000,
        },
        {
          name: 'แผนกฝ่ายบุคคล',
          useMoney: 105000,
        },
        {
          name: 'แผนกทั่วไป',
          useMoney: 120000,
        },
        {
          name: 'แผนกบริหาร',
          useMoney: 180000,
        },
      ],
    },
    {
      id: '2',
      name: '2565',
      oneDepartment: [
        {
          name: 'แผนกบัญชี',
          useMoney: 150000 / 2,
        },
        {
          name: 'แผนกการตลาด',
          useMoney: 225000 / 2,
        },
        {
          name: 'แผนกฝ่ายบุคคล',
          useMoney: 105000 / 2,
        },
        {
          name: 'แผนกทั่วไป',
          useMoney: 120000 / 2,
        },
        {
          name: 'แผนกบริหาร',
          useMoney: 180000 / 2,
        },
      ],
    },
  ]

  const [onePayDepartmentAll, setOnePayDepartmentAll] = useState({
    id: '1',
    name: '2564',
    oneDepartment: [
      {
        name: 'แผนกบัญชี',
        useMoney: 150000,
      },
      {
        name: 'แผนกการตลาด',
        useMoney: 225000,
      },
      {
        name: 'แผนกฝ่ายบุคคล',
        useMoney: 105000,
      },
      {
        name: 'แผนกทั่วไป',
        useMoney: 120000,
      },
      {
        name: 'แผนกบริหาร',
        useMoney: 180000,
      },
    ],
  })
  const mockPayWelfare = [
    {
      id: '1',
      name: '2564',
      data: [
        {
          name: 'แผนกบัญชี',
          budget: 44000,
          realUsed: 32500,
        },
        {
          name: 'แผนกการตลาด',
          budget: 66000,
          realUsed: 52100,
        },
        {
          name: 'แผนกฝ่ายบุคคล',
          budget: 30800,
          realUsed: 25102,
        },
        {
          name: 'แผนกทั่วไป',
          budget: 35200,
          realUsed: 20000,
        },
        {
          name: 'แผนกบริหาร',
          budget: 52800,
          realUsed: 30254,
        },
      ],
    },
    {
      id: '2',
      name: '2565',
      data: [
        {
          name: 'แผนกบัญชี',
          budget: 44000,
          realUsed: 42500,
        },
        {
          name: 'แผนกการตลาด',
          budget: 66000,
          realUsed: 45100,
        },
        {
          name: 'แผนกฝ่ายบุคคล',
          budget: 30800,
          realUsed: 28102,
        },
        {
          name: 'แผนกทั่วไป',
          budget: 35200,
          realUsed: 29800,
        },
        {
          name: 'แผนกบริหาร',
          budget: 52800,
          realUsed: 37800,
        },
      ],
    },
  ]

  const [onePayWelfare, setOnePayWelfare] = useState({
    id: '1',
    name: '2564',
    data: [
      {
        name: 'แผนกบัญชี',
        budget: 44000,
        realUsed: 32500 / 2,
      },
      {
        name: 'แผนกการตลาด',
        budget: 66000,
        realUsed: 52100 / 2,
      },
      {
        name: 'แผนกฝ่ายบุคคล',
        budget: 30800,
        realUsed: 25102 / 2,
      },
      {
        name: 'แผนกทั่วไป',
        budget: 35200,
        realUsed: 20000 / 2,
      },
      {
        name: 'แผนกบริหาร',
        budget: 52800,
        realUsed: 30254 / 2,
      },
    ],
  })

  const calculatePayAll = () => {
    const z = onePayDepartmentAll.oneDepartment
      .map((e: any, i: number) => {
        return e.useMoney
      })
      .reduce((pre, cur) => pre + cur)

    return z
  }
  // console.log(calculatePayAll())

  const mockDepartment = [
    {
      id: '1',
      name: 'พนักงานแผนกบัญชี',
      employee: [
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'tasdest1',
          img: 'https://www.man-es.com/images/default-source/energy-and-storage/man_chp_header_home.jpg?sfvrsn=3245e90b_0',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'teadsasst1',
          img: 'https://images.unsplash.com/photo-1599842057874-37393e9342df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8Z2lybHxlbnwwfHwwfHw%3D&w=1000&q=80',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
      ],
      maxEmployee: 20,
    },
    {
      id: '2',
      name: 'พนักงานฝ่ายขาย',
      employee: [
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tasdest1',
          img: 'https://www.man-es.com/images/default-source/energy-and-storage/man_chp_header_home.jpg?sfvrsn=3245e90b_0',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'test1',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'tedasst1',
          img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
      ],
      maxEmployee: 20,
    },
  ]

  const [nowCapacity, setNowCapacity] = useState({
    id: '1',
    name: 'พนักงานแผนกบัญชี',
    employee: [
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        scanOut: new Date('2022-06-16T14:01:58.135Z'),
        leaveCount: 2,
        shiftName: 'ShiftB',
        realWorkTime: '8.45ชม.',
        otTime: '1.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        scanOut: new Date('2022-06-16T14:01:58.135Z'),
        leaveCount: 2,
        shiftName: 'ShiftB',
        realWorkTime: '8.45ชม.',
        otTime: '1.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'tasdest1',
        img: 'https://www.man-es.com/images/default-source/energy-and-storage/man_chp_header_home.jpg?sfvrsn=3245e90b_0',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: null,
        scanOut: null,
        leaveCount: 5,
        shiftName: 'ShiftA',
        realWorkTime: '9.03ชม.',
        otTime: '3.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        scanOut: new Date('2022-06-16T14:01:58.135Z'),
        leaveCount: 2,
        shiftName: 'ShiftB',
        realWorkTime: '8.45ชม.',
        otTime: '1.25ชม.',
      },
      {
        name: 'tedasst1',
        img: 'https://img.freepik.com/free-photo/happiness-wellbeing-confidence-concept-cheerful-attractive-african-american-woman-curly-haircut-cross-arms-chest-self-assured-powerful-pose-smiling-determined-wear-yellow-sweater_176420-35063.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        scanOut: new Date('2022-06-16T14:01:58.135Z'),
        leaveCount: 2,
        shiftName: 'ShiftB',
        realWorkTime: '8.45ชม.',
        otTime: '1.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        scanOut: new Date('2022-06-16T14:01:58.135Z'),
        leaveCount: 2,
        shiftName: 'ShiftB',
        realWorkTime: '8.45ชม.',
        otTime: '1.25ชม.',
      },
      {
        name: 'teadsasst1',
        img: 'https://images.unsplash.com/photo-1599842057874-37393e9342df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8Z2lybHxlbnwwfHwwfHw%3D&w=1000&q=80',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        scanOut: new Date('2022-06-16T14:01:58.135Z'),
        leaveCount: 2,
        shiftName: 'ShiftB',
        realWorkTime: '8.45ชม.',
        otTime: '1.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        scanOut: new Date('2022-06-16T14:01:58.135Z'),
        leaveCount: 2,
        shiftName: 'ShiftB',
        realWorkTime: '8.45ชม.',
        otTime: '1.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        scanOut: new Date('2022-06-16T14:01:58.135Z'),
        leaveCount: 2,
        shiftName: 'ShiftB',
        realWorkTime: '8.45ชม.',
        otTime: '1.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        scanOut: new Date('2022-06-16T14:01:58.135Z'),
        leaveCount: 2,
        shiftName: 'ShiftB',
        realWorkTime: '8.45ชม.',
        otTime: '1.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T08:00:00.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
      {
        name: 'test1',
        img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
        comeBefore: true,
        comeLate: false,
        outBefore: true,
        outLate: false,
        scanIn: new Date('2022-06-16T09:01:58.135Z'),
        leaveCount: 1,
        shiftName: 'ShiftC',
        realWorkTime: '11.03ชม.',
        otTime: '2.25ชม.',
      },
    ],
    maxEmployee: 20,
  })
  const leaveCapacity = [
    {
      id: '1',
      name: 'พนักงานแผนกบัญชี',
      employee: [
        {
          name: 'หาญ วรรณเกตุ',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'MYO NGE',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'SAW LWUN',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'สุพิศ พิดพงษ์	',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'ศักรินทร์ เจ๊ะหมัด',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'กฤษฎา อินทะวงษ์',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'SAW WAR',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T08:00:00.135Z'),
          scanOut: new Date('2022-06-16T14:01:58.135Z'),
          leaveCount: 2,
          shiftName: 'ShiftB',
          realWorkTime: '8.45ชม.',
          otTime: '1.25ชม.',
        },
        {
          name: 'วัชรีย์ แจ่มประเสริฐ	',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'ศักรินทร์ เจ๊ะหมัด',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
        {
          name: 'วัชรีย์ แจ่มประเสริฐ',
          img: 'https://www.man-es.com/images/default-source/energy-and-storage/man_chp_header_home.jpg?sfvrsn=3245e90b_0',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: null,
          scanOut: null,
          leaveCount: 5,
          shiftName: 'ShiftA',
          realWorkTime: '9.03ชม.',
          otTime: '3.25ชม.',
        },
      ],
      maxEmployee: 20,
    },
    {
      id: '2',
      name: 'เเผนกการตลาด',
      employee: [
        {
          name: 'ปราณี สุภาคาร',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'เสรีย์ จันทร์แก้ว',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
      ],
      maxEmployee: 20,
    },
    {
      id: '3',
      name: 'เเผนกฝ่ายบุคคล',
      employee: [],
      maxEmployee: 20,
    },
    {
      id: '5',
      name: 'เเผนกบริหาร',
      employee: [
        {
          name: 'SAW WAR',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
      ],
      maxEmployee: 20,
    },
    {
      id: '4',
      name: 'เเผนกทั่วไป',
      employee: [
        {
          name: 'ศักรินทร์ เจ๊ะหมัด',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
        {
          name: 'วัชรีย์ แจ่มประเสริฐ',
          img: 'https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?w=2000',
          comeBefore: true,
          comeLate: false,
          outBefore: true,
          outLate: false,
          scanIn: new Date('2022-06-16T09:01:58.135Z'),
          leaveCount: 1,
          shiftName: 'ShiftC',
          realWorkTime: '11.03ชม.',
          otTime: '2.25ชม.',
        },
      ],
      maxEmployee: 20,
    },
  ]

  const RealDate = (e: 'date' | 'time') => {
    if (e == 'date') {
      const dayFormat = today.toLocaleDateString('th-TH', {
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        weekday: 'long',
      })
      return dayFormat
    }
    if (e == 'time') {
      const timeFormat = today.toLocaleTimeString('th-TH', { hour: 'numeric', minute: 'numeric' })
      return timeFormat
    }
  }

  const allEmployee = mockDepartment
    .map((e: any, i: number) => {
      return e.employee.length
    })
    .reduce((a: number, b: number) => a + b)

  const employeeWorkToday = mockDepartment
    .map((e: any, i: number) => {
      return e.employee
        .map((x: any, a: number) => {
          //   console.log(x.scanIn)
          if (x.scanIn != null) {
            return 1
          } else {
            return 0
          }
        })
        .reduce((a: number, b: number) => a + b)
    })
    .reduce((a: number, b: number) => a + b)

  const comeBefore = mockDepartment
    .map((e: any, i: number) => {
      return e.employee
        .map((x: any, a: number) => {
          //   console.log(x.scanIn)
          if (x.scanIn >= new Date('2022-06-16T08:30:00.135Z')) {
            return 1
          } else {
            return 0
          }
        })
        .reduce((a: number, b: number) => a + b)
    })
    .reduce((a: number, b: number) => a + b)

  const outBefore = mockDepartment
    .map((e: any, i: number) => {
      return e.employee
        .map((x: any, a: number) => {
          if (x.scanOut <= new Date('2022-06-16T17:00:00.135Z')) {
            return 1
          } else {
            return 0
          }
        })
        .reduce((a: number, b: number) => a + b)
    })
    .reduce((a: number, b: number) => a + b)

  const oneDepartmentEmployee = nowCapacity.employee.length

  const oneDepartmentEmployeeWorkToday = nowCapacity.employee
    .map((e: any, i: number) => {
      //   console.log(x.scanIn)
      if (e.scanIn != null) {
        return 1
      } else {
        return 0
      }
    })
    .reduce((a: any, b: any) => a + b)

  const oneDepartmentEmployeeComeBefore = nowCapacity.employee
    .map((e: any, i: number) => {
      if (e.scanIn >= new Date('2022-06-16T08:30:00.135Z')) {
        return 1
      } else {
        return 0
      }
    })
    .reduce((a: any, b: any) => a + b)

  const oneDepartmentEmployeeOutBeforeBefore = nowCapacity.employee
    .map((e: any, i: number) => {
      if (e.scanOut <= new Date('2022-06-16T17:00:00.135Z')) {
        return 1
      } else {
        return 0
      }
    })
    .reduce((a: any, b: any) => a + b)

  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>
      <div className="main">
        <div className={styles.newMain}>
          <div className={styles.leftZone}>
            {modalLeave && (
              <div className={styles.modalLeave}>
                <div className={styles.modalLeaveTopZone}>
                  <h1 className={styles.modalLeaveTopZoneText}>การลา</h1>
                  <img
                    className={styles.modalLeaveTopZoneImg}
                    onClick={() => setModalLeave(false)}
                    src={Cross}
                    alt="cross"
                  />
                </div>
                <div className={styles.modalLeaveSecondZone}>
                  <div className={styles.modalLeaveSecondColor}></div>
                  <p className={styles.modalLeaveSecondText}>รวมจำนวนครั้งการลาในปีนั้นๆ</p>
                </div>
                <div className={styles.modalLeaveAllUserZone}>
                  {leaveCapacity.map((e: any, i: number) => {
                    return (
                      <div className={styles.oneModalLeaveAllUserZone} key={i}>
                        <div className={styles.oneModalLeaveDepartmentZone}>
                          <div className={styles.oneModalLeaveDepartmentText}>{e.name}</div>
                          <div className={styles.oneModalLeaveDepartmentCount}>{e.employee.length}</div>
                        </div>
                        <div className={styles.allModalLeaveUserZone}>
                          {e.employee.map((a: any, x: number) => {
                            return (
                              <div className={styles.oneModalLeaveUserZone} key={x}>
                                <div className={styles.oneModalLeaveUserImgBox}>
                                  <img
                                    className={styles.oneModalLeaveUserImg}
                                    src="https://www.rraa.org.af/wp-content/uploads/2019/06/man.png"
                                    alt="userImg"
                                  />
                                  <div className={styles.oneModalLeaveUserImgCount}>{a.leaveCount}</div>
                                </div>
                                <div>{a.name}</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}

            <div className={styles.dateAndAllEmployee}>
              <div className={styles.dateZone}>
                <div className={styles.dateRow}>
                  <h1 className={styles.dateText}>{RealDate('date')}</h1>
                  <img className={styles.dateImg} src={Calendar} alt="calendar" />
                </div>
                <p>เวลา {RealDate('time')} น.</p>
              </div>
              <div className={styles.allEmployeeZone}>
                <div className={styles.allEmployeeTopZone}>
                  <div>พนักงานทั้งหมด</div>
                  <span className={styles.totalEmployee}>
                    <p className={styles.RealEmployee}>{employeeWorkToday}</p>/{allEmployee}คน
                  </span>
                </div>
                <ChatProgressBar
                  comeBefore={comeBefore}
                  comeLate={employeeWorkToday - comeBefore}
                  outBefore={outBefore}
                  outLate={employeeWorkToday - outBefore}
                />
              </div>
            </div>
            <div className={styles.capacityWorkZone}>
              <div className={styles.nameAndSearch}>
                <h1 className={styles.departmentNameText}>{nowCapacity.name}</h1>
                <select
                  onChange={(e: any) => {
                    const z: any = mockDepartment.find((a) => {
                      return a.id == e.target.value
                    })
                    setNowCapacity(z)
                  }}
                  className={styles.departmentNameSelect}
                >
                  {mockDepartment.map((e: any, i: number) => {
                    return (
                      <option value={e.id} key={i}>
                        {e.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className={styles.rowCountEmployee}>
                <h1>{oneDepartmentEmployeeWorkToday}</h1>
                <h3> / {oneDepartmentEmployee} คน</h3>
              </div>
              <ChatProgressBar
                comeBefore={oneDepartmentEmployeeComeBefore}
                comeLate={oneDepartmentEmployeeWorkToday - oneDepartmentEmployeeComeBefore}
                outBefore={oneDepartmentEmployeeOutBeforeBefore}
                outLate={oneDepartmentEmployeeWorkToday - oneDepartmentEmployeeOutBeforeBefore}
              />
              <div className={styles.capacityGraphZone}>
                <div className={styles.capacityGraph}>
                  <div className={styles.headInCapacityGraph}>
                    <div className={styles.headLeftInCapacityGraph}>
                      <h2>Capacity work</h2>
                      <div>
                        <div className={styles.headLeftInCapacityGraphItem}>
                          <div
                            style={
                              capacityType == 'day'
                                ? { backgroundColor: '#3F8624', width: '13px', height: '13px' }
                                : { backgroundColor: '#5357BB', width: '13px', height: '13px' }
                            }
                          ></div>
                          {capacityType == 'day' ? <p>เข้างาน</p> : <p>เวลาทำงาน</p>}
                        </div>
                        <div className={styles.headLeftInCapacityGraphItem}>
                          <div style={{ backgroundColor: '#f7af1d', width: '13px', height: '13px' }}></div>
                          {capacityType == 'day' ? <p>ออกงาน</p> : <p>OT</p>}
                        </div>
                      </div>
                    </div>
                    <div className={styles.headRightInCapacityGraph}>
                      <p
                        className={styles.btnItem}
                        onClick={() => setCapacityType('day')}
                        style={capacityType == 'day' ? { borderBottom: '3px solid #3A77DA' } : {}}
                      >
                        รายวัน
                      </p>
                      <p
                        className={styles.btnItem}
                        onClick={() => setCapacityType('month')}
                        style={capacityType == 'month' ? { borderBottom: '3px solid #3A77DA' } : {}}
                      >
                        รายเดือน
                      </p>
                      <p
                        className={styles.btnItem}
                        onClick={() => setCapacityType('year')}
                        style={capacityType == 'year' ? { borderBottom: '3px solid #3A77DA' } : {}}
                      >
                        รายปี
                      </p>
                    </div>
                  </div>
                  <div>
                    <p style={{ width: '100%', display: 'flex', justifyContent: 'center', color: '#B0B8C2' }}>
                      {capacityType == 'day'
                        ? 'จำนวนคน : เวลา'
                        : capacityType == 'month'
                        ? 'จำนวนชั่วโมงงาน(คนxชั่วโมง) : วันที่'
                        : 'จำนวนชั่วโมง : เดือน'}
                    </p>
                    <GraphBarLineCard
                      height={100}
                      stackX={true}
                      stackY={true}
                      labels={
                        capacityType == 'day'
                          ? [
                              '1.01-2.00',
                              '2.01-3.00',
                              '3.01-4.00',
                              '4.01-5.00',
                              '5.01-6.00',
                              '6.01-7.00',
                              '7.01-8.00',
                              '8.01-9.00',
                              '9.01-10.00',
                              '10.01-11.00',
                              '11.01-12.00',
                              '12.01-13.00',
                              '13.01-14.00',
                              '14.01-15.00',
                              '15.01-16.00',
                              '16.01-17.00',
                              '17.01-18.00',
                              '18.01-19.00',
                              '19.01-20.00',
                              '20.01-21.00',
                              '21.01-22.00',
                              '22.01-23.00',
                              '23.01-0.00',
                              '0.01-1.00',
                            ]
                          : capacityType == 'month'
                          ? [
                              '1',
                              '2',
                              '3',
                              '4',
                              '5',
                              '6',
                              '7',
                              '8',
                              '9',
                              '10',
                              '11',
                              '12',
                              '13',
                              '14',
                              '15',
                              '16',
                              '17',
                              '18',
                              '19',
                              '20',
                              '21',
                              '22',
                              '23',
                              '24',
                              '25',
                              '26',
                              '28',
                              '29',
                              '30',
                              '31',
                            ]
                          : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
                      }
                      firstLabel={capacityType == 'day' ? 'เข้างาน' : 'เวลาทำงาน'}
                      firstData={
                        capacityType == 'day'
                          ? [0, 0, 0, 0, 0, 3, 35, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                          : capacityType == 'month'
                          ? [
                              10, 212, 33, 13, 133, 21, 35, 71, 41, 52, 333, 134, 13, 55, 351, 121, 101, 222, 32, 111,
                              122, 32, 43, 15, 10, 212, 33, 13, 133, 21,
                            ]
                          : [13, 55, 351, 121, 101, 222]
                      }
                      firstColor={capacityType == 'day' ? '#3F8624' : '#5357BB'}
                      secondLabel={capacityType == 'day' ? 'ออกงาน' : 'OT'}
                      secondData={
                        capacityType == 'day'
                          ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                          : capacityType == 'month'
                          ? [
                              41, 52, 333, 134, 13, 55, 351, 121, 101, 222, 32, 111, 122, 32, 43, 15, 10, 10, 212, 33,
                              13, 133, 21, 35, 71, 212, 33, 13, 133, 21,
                            ]
                          : [13, 55, 351, 121, 101, 222, 32, 111, 122, 32, 43, 15]
                      }
                      secondColor={'#FF9900'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.totalDept}>
              <div className={styles.headTotalDept}>
                <h1>Total Dept.</h1>
                <div>
                  <div className={styles.headLeftInCapacityGraphItem}>
                    <div style={{ backgroundColor: '#3F8624', width: '13px', height: '13px' }}></div>
                    {<p>เข้างาน</p>}
                  </div>
                  <div onClick={() => setModalLeave(true)} className={styles.headLeftInCapacityGraphItem}>
                    <div style={{ backgroundColor: '#f7af1d', width: '13px', height: '13px' }}></div>
                    {<p>ลา</p>}
                  </div>
                </div>
              </div>
              <GraphBarLineCard
                height={70}
                stackX={false}
                stackY={false}
                labels={['แผนกบัญชี', 'แผนกการตลาด', 'แผนกฝ่ายบุคคล', 'แผนกทั่วไป', 'แผนกบริหาร']}
                firstLabel={'เข้างาน'}
                firstData={[21, 50, 32, 45, 25]}
                firstColor={'#3F8624'}
                secondLabel={'ลา'}
                secondData={[10, 2, 0, 2, 1]}
                secondColor={'#FF9900'}
              />
            </div>
          </div>
          <div className={styles.rightZone}>
            <div className={styles.payAll}>
              <div className={styles.headPayAll}>
                <h3 className={styles.headPayAllText}>ค่าใช้จ่ายทั้งหมด</h3>
                <select
                  onChange={(e: any) => {
                    const z: any = mockPayAll.find((a) => {
                      return a.id == e.target.value
                    })
                    setOnePayDepartmentAll(z)
                  }}
                  className={styles.selectPayAllYear}
                >
                  {mockPayAll.map((e: any, i: number) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className={styles.sumAllUseMoney}>
                <h2 className={styles.sumAllUseMoneyText}>
                  {calculatePayAll()
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </h2>
                <h3>บาท</h3>
              </div>
              <div className={styles.allDepartmentMoneyUsed}>
                {onePayDepartmentAll.oneDepartment.map((e: any, i: number) => {
                  return (
                    <div className={styles.oneDepartmentMoneyUsed} key={i}>
                      <p className={styles.oneDepartmentMoneyUsedText}>{e.name}</p>
                      <h3 className={styles.oneDepartmentMoneyUsedMoney}>
                        {e.useMoney.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </h3>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={styles.payWelfare}>
              <div className={styles.headWelfare}>
                <h3>ค่าใช้จ่ายสวัสดิการ</h3>
                <select
                  onChange={(e: any) => {
                    const z: any = mockPayWelfare.find((a) => {
                      return a.id == e.target.value
                    })
                    setOnePayWelfare(z)
                  }}
                  className={styles.selectWelfareYear}
                >
                  {mockPayWelfare.map((e: any, i: number) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className={styles.welfareZone}>
                <div className={styles.headWelfaree}>
                  <p className={styles.headWelfareItem}>แผนก</p>
                  <p className={styles.headWelfareItem}>ใช้จริง</p>
                  <p className={styles.headWelfareItem}>งบประมาณ</p>
                </div>
                <div className={styles.allItemWelfare}>
                  {onePayWelfare.data.map((e: any, i: number) => {
                    return (
                      <div className={styles.oneItemWelfare} key={i}>
                        <div className={styles.InWelfareItemName}>{e.name}</div>
                        <div className={styles.InWelfareItemMoney}>
                          {e.realUsed.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                        <div className={styles.InWelfareItemMoney}>
                          {e.budget.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.showTable}>
          <div className={styles.TableAttendance}>
            <p className={styles.header}>Time Attendance</p>
            <TableAttend />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
