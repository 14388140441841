import * as React from 'react'
import styles from './index.module.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ViewIcon from './image/viewBlue.svg'
import EditeIconDisabled from './image/edite.svg'
import EditeIcon from './image/editeBlue.svg'
import DeleteIconDisabled from './image/delete.svg'
import DeleteIcon from './image/deleteRed.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Profile from './image/profile.svg'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, LinearProgress } from '@mui/material'
import { ELeave, ELeavForm } from '../AttendanceReport/EType'
import { DateStyleShort } from 'src/utils/common'
import { DeleteLeaveDocument, ListLeaveDocument } from 'src/adapter/xhr'
import { ModalEdite, ModalView } from './Modal'
import swal from 'sweetalert'

export const TableLeaveDocument: React.FC<any> = (props: any): JSX.Element => {
  const Header = [
    'ประเภทการลา',
    'รูปแบบการลา',
    'วันที่สร้างเอกสาร',
    'วันที่เริ่ม',
    'วันที่สิ้นสุด',
    'การกระทำ',
    'สถานะ',
  ]
  const [LeaveDocument, setLeaveDocument] = React.useState<any>([])
  const [open, setOpen] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)

  const [employeeID, setEmployeeID] = React.useState<any>(null)
  const [LeaveID, setLeaveID] = React.useState<any>(null)

  return (
    <>
      {/* {console.log(props.loading, 'props.loading')} */}
      {props.loading ? (
        <LinearProgress />
      ) : (
        <>
          {' '}
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f4f6' }}>
                {Header.map((item: any, index: number) => (
                  <TableCell align="center" key={index} sx={{ border: 'none' }}>
                    <h4>{item}</h4>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
          {props.Listdocument?.length
            ? props.Listdocument.filter((LeaveItem: any) => {
                if (props.searchVal === '') {
                  return LeaveItem
                } else if (
                  LeaveItem.employee.fullName.toLocaleLowerCase().includes(props.searchVal.toLocaleLowerCase()) ||
                  LeaveItem.employee.lastName.toLocaleLowerCase().includes(props.searchVal.toLocaleLowerCase())
                ) {
                  return LeaveItem
                }
              }).map((LeaveItem: any, LeaveIndex: number) => (
                <Accordion
                  sx={{
                    boxShadow: 'none',
                  }}
                  key={LeaveIndex}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: '#EFF3FC',
                      border: 'none',
                      boxShadow: 'none',
                    }}
                  >
                    <div className={styles.profileArea}>
                      <img src={Profile} alt="" />
                      <p>
                        {LeaveItem.employee.fullName} {LeaveItem.employee.lastName}
                      </p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table aria-label="simple table">
                      <TableHead>
                        {LeaveItem.items.map((Document: any, DocIndex: number) => (
                          <TableRow key={DocIndex}>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <p> {ELeave[`${Document.leaveType}`]}</p>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <p>{ELeavForm[`${Document.leaveForm}`]}</p>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <p>{DateStyleShort(Document.createdAt)}</p>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <p>{DateStyleShort(Document.startedAt)}</p>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <p>{DateStyleShort(Document.endedAt)}</p>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <div className={styles.Action}>
                                <img
                                  src={ViewIcon}
                                  alt=""
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    ListLeaveDocument({
                                      nextToken: null,
                                      filter: {
                                        id: {
                                          beginsWith: Document.id,
                                        },
                                      },
                                    }).then((res: any) => {
                                      console.log(res.data.data.listLeaveGroupByEmployee.items[0].items, 'res ID'),
                                        setLeaveDocument(res.data.data.listLeaveGroupByEmployee.items[0].items)
                                    })
                                    setOpen(true)
                                  }}
                                />
                                {Document.status === 'APPROVE' || Document.status === 'REJECT' ? (
                                  <img src={EditeIconDisabled} alt="" style={{ pointerEvents: 'none' }} />
                                ) : (
                                  <img
                                    src={EditeIcon}
                                    alt=""
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setEmployeeID(Document.employee.id)
                                      setLeaveID(Document.id)
                                      setOpenModal(true)
                                    }}
                                  />
                                )}
                                {Document.status === 'APPROVE' || Document.status === 'REJECT' ? (
                                  <img src={DeleteIconDisabled} alt="" style={{ pointerEvents: 'none' }} />
                                ) : (
                                  <img
                                    src={DeleteIcon}
                                    alt=""
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      swal('', {
                                        icon: 'warning',
                                        title: 'ลบเอกสารการลา',
                                        text: 'ไม่สามารถเปลี่ยนแปลงการแก้ไขได้',
                                        buttons: ['ยกเลิก', 'ลบข้อมูล'],
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          DeleteLeaveDocument({
                                            input: { id: Document.id, employeeID: Document.employee.id },
                                          }).then((res: any) => {
                                            console.log(res, 'Delete OK')
                                          })
                                          swal('', {
                                            icon: 'success',
                                            title: 'ลบรายการเอกสารเรียบร้อยแล้ว',
                                          })
                                          setTimeout(() => {
                                            props.QueryListDocument()
                                          }, 1000)
                                        }
                                      })
                                    }}
                                  />
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <div className={styles.status}>
                                <div
                                  className={
                                    Document.status === 'APPROVE'
                                      ? styles.pill
                                      : Document.status === 'REJECT'
                                      ? styles.pillReject
                                      : styles.pillPending
                                  }
                                >
                                  <p>{Document.status}</p>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableHead>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              ))
            : ''}
        </>
      )}

      <ModalView LeaveDocument={LeaveDocument} open={open} setOpen={setOpen} />
      <ModalEdite
        open={openModal}
        setOpen={setOpenModal}
        employeeID={employeeID}
        LeaveID={LeaveID}
        QueryListDocument={props.QueryListDocument}
      />
    </>
  )
}
