import React, { useEffect, useState, useRef } from 'react'
import styles from './index.module.scss'
import {
  Modal,
  Backdrop,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AvatarGroup,
  Avatar,
  Checkbox,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import LinkIcon from '../../image/Link.svg'
import UnLinkIcon from '../../image/Unlink.svg'
import AddIcon from '../../image/addEmp.svg'
import BackIcon from '../../image/back.svg'
import CountIcon from '../../image/CountUser.svg'
import { Formik } from 'formik'
import { ButtonComponent, InputNumberComponentCustom, InputTextComponentCustom } from '../../component'
import DelIcon from '../../image/deleteRedIcon.svg'
import allAction from '../../state/actions'

export const ModalCreatePackage: React.FC<any> = (props: any): JSX.Element => {
  const [adduser, setAddUser] = useState(false)
  const [userList, setUserlist] = useState<any>([])
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [isCheck, setIsCheck] = useState<any>([])

  const otherProps = props
  const formikRef: any = useRef()
  const dispatch = useDispatch()
  const otherprops = props
  // console.log(props.listpackage.id, 'props.listpackage.id')
  // console.log(mode, 'mode')

  useEffect(() => {
    if (!props.open) return
    if (props.listpackage.id) {
      setMode('update')
    } else {
      setMode('create')
    }
  }, [props.open, mode])

  const handleClick = (e: any, id: any, item: any) => {
    const { value, checked } = e.target
    setUserlist([...userList, item])
    if (!checked) {
      setUserlist(userList.filter((item: any) => item.id !== id))
    }
  }

  const headerLeave = ['ชื่อ', 'อายุงาน (เดือน)', 'จำนวน (โควต้า)', 'จำนวนวันลา (ที่ได้เงิน)', 'ตัวเลือก']
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalApprove}>
        <div className={styles.card}>
          <Formik
            innerRef={formikRef}
            initialValues={{
              remark: '',
              quota: [],
              link: '',
            }}
            // validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              console.log(values)
            }}
          >
            {(props: any) => (
              <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                {!adduser ? (
                  <div>
                    <div className={styles.packageModal}>
                      <section className={styles.HeadData}>
                        {mode === 'create' ? (
                          <h2 className={styles.headModalText}>สร้างเเพ็กเกจใหม่</h2>
                        ) : (
                          <h2 className={styles.headModalText}>แก้ไขแพ็กเกจ</h2>
                        )}
                        <Divider />
                      </section>

                      <div className={styles.detailModalText}>
                        <div className={styles.nameOfPackage}>
                          <h4 className={styles.namePackage}>ชื่อเเพ็กเกจ</h4>
                          <div className={styles.inputPackage}>
                            <InputTextComponentCustom _name="name" _label="ชื่อแพ็กเกจ" />
                          </div>
                        </div>

                        <section className={styles.walfareArea}>
                          <h4>การลา</h4>
                          <TableContainer style={{ overflow: 'scroll', height: '300px' }}>
                            <Table>
                              <TableHead
                                style={{ backgroundColor: '#3A77DA', position: 'sticky', top: '0', zIndex: 5 }}
                              >
                                <TableRow>
                                  {headerLeave.map((itemTable: any, Index: number) => (
                                    <TableCell key={Index} align="center">
                                      <p className={styles.text}>{itemTable}</p>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody style={{ backgroundColor: '#ECF3FF' }}>
                                {otherprops.leaveData.map((itemLeave: any, Indexitem: number) => (
                                  <TableRow key={Indexitem}>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.name}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.workAge}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <section className={styles.GroupArea}>
                                        <div className={styles.inputBox}>
                                          <InputNumberComponentCustom
                                            _name={`quota[${Indexitem}]`}
                                            _value={props.values.quota[Indexitem]}
                                            _disabled={itemLeave.link === true ? true : false}
                                          />
                                        </div>
                                        <img
                                          src={itemLeave.link === true ? LinkIcon : UnLinkIcon}
                                          alt=""
                                          className={styles.Icon}
                                        />
                                      </section>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.total}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <Checkbox />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </section>

                        <section className={styles.walfareArea}>
                          <h4>สวัสดิการ</h4>
                          <TableContainer style={{ overflow: 'scroll', height: '300px' }}>
                            <Table>
                              <TableHead
                                style={{ backgroundColor: '#3A77DA', position: 'sticky', top: '0', zIndex: 5 }}
                              >
                                <TableRow>
                                  {headerLeave.map((itemTable: any, Index: number) => (
                                    <TableCell key={Index} align="center">
                                      <p className={styles.text}>{itemTable}</p>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody style={{ backgroundColor: '#ECF3FF' }}>
                                {otherprops.leaveData.map((itemLeave: any, Indexitem: number) => (
                                  <TableRow key={Indexitem}>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.name}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.workAge}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <section className={styles.GroupArea}>
                                        <div className={styles.inputBox}>
                                          <InputNumberComponentCustom
                                            _name={`quota[${Indexitem}]`}
                                            _value={props.values.quota[Indexitem]}
                                            _disabled={itemLeave.link === true ? true : false}
                                          />
                                        </div>
                                        <img
                                          src={itemLeave.link === true ? LinkIcon : UnLinkIcon}
                                          alt=""
                                          className={styles.Icon}
                                        />
                                      </section>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.total}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <Checkbox />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </section>
                        <div className={styles.AddPackage}>
                          <p>พนักงาน</p>
                          <img src={AddIcon} alt="" onClick={() => setAddUser(true)} className={styles.Icon} />
                        </div>
                      </div>
                    </div>
                    <section className={styles.bottom}>
                      <div className={styles.DelPackage}>
                        {mode === 'update' ? (
                          <div
                            className={styles.Group}
                            onClick={() => {
                              dispatch(
                                allAction.modalConfirmAction.setOpenModalConfirm(
                                  'คุณต้องการลบแพ็กเกจ',
                                  'ข้อมูล',
                                  'ไม่สามารถแก้ไขการเปลี่ยนแปลงได้',
                                  'ใช่,ลบข้อมูล',
                                  () => {
                                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                                    otherprops.setOpen(false)
                                    dispatch(
                                      allAction.modalAction.setOpenModalAlertStatus('ลบข้อมูลแพ็กเกจ 1', '', 'success'),
                                    )
                                  },
                                ),
                              )
                            }}
                          >
                            <img src={DelIcon} alt="" />
                            <p>ลบแพ็กเกจ</p>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className={styles.formButton}>
                        <div className={styles.button}>
                          <ButtonComponent
                            _colorBG="red"
                            _colorText="white"
                            _text="ยกเลิก"
                            _type="button"
                            _variant="text"
                            _functionOnClick={() => otherprops.setOpen(false)}
                          />
                        </div>
                        <div className={styles.button}>
                          <ButtonComponent
                            _colorBG="blue"
                            _colorText="white"
                            _text="บันทึก"
                            _type="submit"
                            _variant="text"
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                ) : (
                  <div>
                    <div className={styles.packageModal}>
                      <section className={styles.HeadData}>
                        <div className={styles.Groupleft}>
                          <img src={BackIcon} alt="" className={styles.Icon} onClick={() => setAddUser(false)} />
                          <h3 className={styles.headModalText}>สร้างแพ็กเกจใหม่</h3>
                          <h4>เพิ่มพนักงาน</h4>
                        </div>

                        <div className={styles.GroupRigth}>
                          <img src={CountIcon} alt="" />
                          <h4 className={styles.bade}>{userList.length}</h4>
                        </div>
                      </section>
                      <Divider />
                      <div className={styles.searchBox}>
                        <p className={styles.text}>ค้นหาพนักงาน</p>
                        <div className={styles.InputBox}>
                          <InputTextComponentCustom _name="search" />
                        </div>
                      </div>

                      <div className={styles.userArea}>
                        {otherprops.user.map((itemUser: any, userIndex: number) => (
                          <div key={userIndex} className={styles.departArea}>
                            <p>{itemUser.name}</p>
                            {itemUser.user.map((item: any, index: number) => (
                              <div key={index} className={styles.userCard}>
                                <div className={styles.Group}>
                                  <img src={item.img} alt="" />
                                  <p>{item.name} </p>
                                </div>
                                <Checkbox
                                  sx={{
                                    color: '#5357BB',
                                    '&.Mui-checked': {
                                      color: '#5357BB',
                                    },
                                  }}
                                  onChange={(e: any) => handleClick(e, item.id, item)}
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                    <section className={styles.bottom}>
                      <div className={styles.formButton}>
                        <div className={styles.button}>
                          <ButtonComponent
                            _colorBG="white"
                            _colorText="gray"
                            _text="รีเซ็ต"
                            _type="button"
                            _variant="text"
                            _functionOnClick={() => setUserlist([])}
                          />
                        </div>
                        <div className={styles.button}>
                          <ButtonComponent
                            _colorBG="transparent"
                            _colorText="blue"
                            _text="เพิ่มพนักงาน"
                            _type="submit"
                            _variant="outlined"
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
