import React, { useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { Formik } from 'formik'
import * as yup from 'yup'
import { ButtonComponent, InputSelectComponent } from '../../component'
import payIcon from '../../image/payIcon.svg'
import { TabPayroll } from './tab'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import SettingIcon from '../../image/setting.svg'
import HistoryIcon from '../../image/historyEdit.svg'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ModalHistory } from './modalHistory'
import {
  convertDateString,
  convertToPeriodList,
  getPeriodStartEndDate,
  PeriodElement,
} from '../../utils/periodCalCulate'
import { TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
// import { months } from '../../utils/common'
import { LinearProgress } from '@mui/material'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Layout } from '../../layout'
import { PayrollConfig, PayrollQuery } from '../../utils/generated'

const periodNumberToText: any = {
  1: 'แรก',
  2: 'สอง',
  3: 'สาม',
  4: 'สี่',
}

const Payroll: React.FC = (): JSX.Element => {
  const [date, setDate] = useState<Date>(new Date())
  // const [periodMap, setPeriodMap] = useState<PeriodElementMap>({})
  const [modalhistory, setModalHistory] = useState(false)
  const [isShowMoney, setIsShowMoney] = useState(true)
  const [selectedPeriod, setSelectedPeriod] = useState('ALL')
  const [nextToken, setNextToken] = useState<any>(null)

  const { loading, error, data, refetch } = useQuery<QueryListPayrollResponse>(queryListPayroll, {
    variables: {
      yearMonth: date.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
      }),
      period: selectedPeriod,
      // nextToken: '{"PK":"LINKLOOK|2022-07","SK":"LINKLOOK|1.1|1203|TIME_ATTENDANCE"}',
      // nextToken: data.pay,
    },
    // https://github.com/apollographql/react-apollo/issues/321
    notifyOnNetworkStatusChange: true, // for loading to be changed on refetch
  })

  useEffect(() => {
    if (loading === false) {
      // console.log(data?.listPayroll.nextToken, 'NEXT TOKEN')
      // setNextToken(data?.listPayroll.nextToken)
    }
  }, [data])

  // console.log(nextToken, 'nextToken')
  // console.log(JSON.parse(nextToken), 'BEAM TEST')
  // console.log(`{}`)
  // console.log(data.listPayroll.nextToken, 'data')

  // Get Payroll Config
  // Multiple useQuery: https://github.com/trojanowski/react-apollo-hooks/issues/120
  const { loading: loadingPC, error: errorPC, data: dataPC } = useQuery<QueryPayrollConfigResponse>(queryPayrollConfig)

  // useMutation on Create
  const [createPayroll, { loading: loadingCP, error: errorCP }] = useMutation(mutationCreatePayroll)

  const periodMap: PeriodElementMap = useMemo(() => {
    if (dataPC && !loadingPC && !errorPC) {
      const companyPayrollConfig = dataPC.getPayrollConfig
      const periodStart = companyPayrollConfig.periodStart

      const [periodStartDate, periodEndDate] = getPeriodStartEndDate(
        date.getFullYear(),
        date.getMonth() + 1,
        periodStart,
      )

      const periodList = [
        {
          period: 'ALL',
          periodStart: convertDateString(periodStartDate),
          periodEnd: convertDateString(periodEndDate),
        },
        {
          period: '1.1',
          periodStart: convertDateString(periodStartDate),
          periodEnd: convertDateString(periodEndDate),
        },
        ...convertToPeriodList(companyPayrollConfig.periodCount, periodStartDate, periodEndDate, companyPayrollConfig),
      ]
      console.log(periodList, 'convertToPeriodList')

      return periodList.reduce<PeriodElementMap>((map, ele) => {
        map[ele.period] = ele
        return map
      }, {})
    } else {
      return {}
    }
  }, [dataPC, date])

  const createPayrollAndRefetch = () => {
    const yearMonth = date.toLocaleString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
    })
    createPayroll({ variables: { yearMonth } }).then((res) => {
      console.log(res, 'ress createPayroll')

      refetch({
        variables: {
          yearMonth,
          period: selectedPeriod,
        },
      })
    })
  }

  const [closePayroll, setClosePayroll] = useState<any>()

  console.log('periodMap', periodMap)
  // console.log('selectedPeriod', selectedPeriod)
  // console.log('loading', loading)
  // console.log('loadingPC', loadingPC)
  // console.log('data', data)
  // console.log('dataPC', dataPC)

  const validationSchema = yup.object({})

  // If it is loading
  if (loading || loadingPC || loadingCP || Object.keys(periodMap).length < 1)
    return (
      <Layout>
        <div className={styles.container}>
          <TopHeader date={date} setDate={setDate} />
          <div className={styles.content}>
            <LinearProgress />
          </div>
        </div>
      </Layout>
    )
  // If there is any error or no data
  else if (error || !data || errorPC || !dataPC || errorCP)
    return (
      <Layout>
        <div className={styles.container}>
          <TopHeader date={date} setDate={setDate} />
          <div className={styles.content}>
            Error OR no data! {error?.message} {errorPC?.message} {errorCP?.message}
          </div>
        </div>
      </Layout>
    )
  // If listPayroll is null (No payroll data in that yearMonth)
  else if (data.listPayroll === null || dataPC.getPayrollConfig === null)
    return (
      <Layout>
        <div className={styles.container}>
          <TopHeader date={date} setDate={setDate} />
          <div className={styles.content}>
            <section className={styles.createList}>
              <img src={payIcon} alt="" />
              <section className={styles.groupText}>
                <p className={styles.text}>สร้างรายการคำนวณเงินเดือน</p>
                <p className={styles.text}>
                  {date.toLocaleString('th-TH', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </p>
              </section>
              <div className={styles.line}></div>
              <section className={styles.btCrtList}>
                <ButtonComponent
                  _colorBG="blue"
                  _colorText="white"
                  _text="สร้างรายการ"
                  _variant="text"
                  _type="button"
                  _isIcon="add"
                  _functionOnClick={createPayrollAndRefetch}
                />
              </section>
            </section>
          </div>
        </div>
      </Layout>
    )

  return (
    <Layout>
      <div className={styles.container}>
        {/* Top Header */}
        <TopHeader date={date} setDate={setDate} />
        {/* Actual Content */}
        <div className={styles.content}>
          <Formik
            // innerRef={formikRef}
            initialValues={{
              teamname: '',
              lead: [],
              member: [],
              detail: '',
              selectedPeriod: 'ALL',
            }}
            validationSchema={validationSchema}
            onSubmit={() => console.log('xxx')}
          >
            {(props: any) => (
              <form onSubmit={props.handleSubmit} className={styles.formgroup}>
                <div>
                  <div className={styles.cardList}>
                    <div className={styles.CardLeft}>
                      <section className={styles.card}>
                        <div className={styles.header}>
                          <div className={styles.TextLeft}>
                            <p className={styles.countEmp}>{data.listPayroll.employeeCount}</p>
                            <p className={styles.text}>พนักงานทั้งหมด</p>
                          </div>
                          <div className={styles.TextRigth}>
                            <span className={styles.text}>พนักงานเข้าใหม่ {data.listPayroll.employeeHireCount} คน</span>
                            <span className={styles.text}>พนักงานลาออก {data.listPayroll.employeeRetireCount} คน</span>
                          </div>
                        </div>
                        <div className={styles.line}></div>
                        <div className={styles.contentText}>
                          <span className={styles.text}>พนักงานรายเดือน</span>
                          <span className={styles.text}> {data.listPayroll.employeeTypeCount?.FULLTIME_MONTH} คน</span>
                          <span className={styles.text}>พนักงานรายวัน</span>
                          <span className={styles.text}> {data.listPayroll.employeeTypeCount?.FULLTIME_DAY} คน</span>
                          <span className={styles.text}>พนักงานพาร์ทไทม์</span>
                          <span className={styles.text}> {data.listPayroll.employeeTypeCount?.PARTTIME} คน</span>
                          <span className={styles.text}>พนักงานจ้างเหมา</span>
                          <span className={styles.text}> {data.listPayroll.employeeTypeCount?.OUTSOURCE} คน</span>
                        </div>
                        <p className={styles.textfooter}>
                          ณ เดือน
                          {date.toLocaleString('th-TH', {
                            month: 'long',
                            year: 'numeric',
                          })}
                        </p>
                      </section>
                      {/* )} */}
                      {/* {console.log(data.listPayroll, 'Payroll BeamLog')} */}

                      {data.listPayroll.status === 'PAID' ? (
                        <section className={styles.cardSuccess}>
                          <div className={styles.area}>
                            <p className={styles.textHead}>ปิดงวดสำเร็จ</p>
                            <p className={styles.textSub}>
                              จำนวนเงินทั้งหมดที่จ่าย{' '}
                              {data.listPayroll.totalPayroll
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                              บาท
                            </p>
                            <p className={styles.textSub}>จ่ายวันที่ 19 พฤษภาคม 2565</p>
                          </div>
                        </section>
                      ) : (
                        ''
                      )}
                    </div>

                    <section className={styles.cardLeft}>
                      <div className={styles.card}>
                        <div className={styles.header}>
                          <div className={styles.dropdownPeriod}>
                            <FormControl fullWidth size="small">
                              <InputLabel
                                shrink
                                id={props._name}
                                sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white', padding: '0px 5px' }}
                              >
                                {props._label}
                              </InputLabel>
                              <Select
                                defaultValue={props._name}
                                sx={{ borderRadius: 3, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
                                value={selectedPeriod}
                                onChange={(e) => {
                                  setSelectedPeriod(e.target.value)
                                }}
                              >
                                {Object.keys(periodMap)
                                  .map((period) => {
                                    return {
                                      label:
                                        period === 'ALL'
                                          ? 'รวมทั้งหมดทุกงวดที่มี'
                                          : `งวด${
                                              period === '1.1'
                                                ? 'เต็ม'
                                                : periodNumberToText[period.split('.')[1]] +
                                                  'เดือน' +
                                                  date.toLocaleString('th-TH', {
                                                    month: 'long',
                                                  })
                                            }`,
                                      value: period,
                                    }
                                  })
                                  .map((data, index) => (
                                    <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
                                      {data.label}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                          <img src={SettingIcon} alt="" />
                        </div>
                        <div className={styles.detaildate}>
                          <div className={styles.date}>
                            <p className={styles.dateText}>
                              {new Date(periodMap[selectedPeriod].periodStart).toLocaleString('th-TH', {
                                day: 'numeric',
                              })}
                            </p>
                            <p>
                              {new Date(periodMap[selectedPeriod].periodStart).toLocaleString('th-TH', {
                                month: 'long',
                              })}
                            </p>
                          </div>
                          -
                          <div className={styles.date}>
                            <p className={styles.dateText}>
                              {new Date(periodMap[selectedPeriod].periodEnd).toLocaleString('th-TH', {
                                day: 'numeric',
                              })}
                            </p>
                            <p>
                              {new Date(periodMap[selectedPeriod].periodEnd).toLocaleString('th-TH', {
                                month: 'long',
                              })}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <InputSelectComponent
                            _name={''}
                            _label={''}
                            _menuList={[{ label: 'สำนักงานใหญ่', value: 'datability' }]}
                          /> */}
                    </section>
                  </div>
                  <div className={styles.bottom}>
                    <section className={styles.history}>
                      <img src={HistoryIcon} alt="" />
                      <p className={styles.clickhere} onClick={() => setModalHistory(!modalhistory)}>
                        ประวัติการแก้ไขข้อมูลคลิกที่นี่
                      </p>
                    </section>
                    <section className={styles.buttonFooter}>
                      <div className={styles.button}>
                        <ButtonComponent
                          _colorBG="blue"
                          _colorText="white"
                          _text="สร้างรายการ"
                          _variant="text"
                          _type="button"
                          _isIcon="add"
                          _functionOnClick={createPayrollAndRefetch}
                        />
                      </div>
                      {/* <div className={styles.button}>
                            <ButtonComponent
                              _colorBG="blue"
                              _colorText="white"
                              _text="คำนวณเงินเดือน"
                              _variant="text"
                              _type="button"T
                              _isIcon="calculate"
                              _functionOnClick={() => console.log('calculate wage Click !!')}
                            />{' '}
                          </div> */}
                      <div className={styles.button}>
                        <ButtonComponent
                          _colorBG="white"
                          _colorText="blue"
                          _text="รีเซ็ตข้อมูลตั้งต้น"
                          _variant="text"
                          _type="button"
                          _isIcon="reset"
                          _functionOnClick={() => console.log('reset initial Data Click !!')}
                        />
                      </div>
                      <div className={styles.button}>
                        <ButtonComponent
                          _colorBG="blue"
                          _colorText="white"
                          _text={isShowMoney ? 'ปิดข้อมูลเงินเดือน' : 'เปิดข้อมูลเงินเดือน'}
                          _variant="text"
                          _type="button"
                          _isIcon="payroll"
                          _functionOnClick={() => {
                            console.log('close Income Click !!')
                            setIsShowMoney(!isShowMoney)
                          }}
                        />
                      </div>
                    </section>
                  </div>
                </div>
                {/* </div> */}
              </form>
            )}
          </Formik>
          <section className={styles.tabbar}>
            <TabPayroll
              payrollList={data.listPayroll}
              period={selectedPeriod}
              isShowMoney={isShowMoney}
              date={date.toISOString().slice(0, 7)}
            />
          </section>
        </div>
      </div>
      <ModalHistory modalhistory={modalhistory} setModalHistory={setModalHistory} />
    </Layout>
  )
}

const TopHeader: React.FC<TopHeaderProps> = (props: TopHeaderProps) => {
  return (
    <div className={styles.navtop}>
      <h1 className={styles.title}>คำนวณเงินเดือน</h1>
      <section className={styles.calendarMonth}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label="เลือกเดือน"
            openTo="month"
            views={['year', 'month']}
            value={props.date}
            onChange={(date) => {
              if (date) props.setDate(date)
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </section>
    </div>
  )
}

export default Payroll

// A PeriodElement Map maps period --> periodStart-periodEnd
interface PeriodElementMap {
  [period: string]: PeriodElement
}

interface TopHeaderProps {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
}

interface QueryListPayrollResponse {
  listPayroll: PayrollQuery
}

interface QueryPayrollConfigResponse {
  getPayrollConfig: PayrollConfig
}

const mutationCreatePayroll = gql`
  mutation CreatePayroll($yearMonth: String!) {
    createPayroll(yearMonth: $yearMonth)
  }
`

const queryPayrollConfig = gql`
  query GetPayrollConfig {
    getPayrollConfig {
      periodStart
      periodCount
      period1Day
      period2Day
      period3Day
      workingDayCount
      workingDayCountNewEmployee
      resetLeaveDay
      workingHourDivisor
      roundType
    }
  }
`

const queryListPayroll = gql`
  query Query($yearMonth: String!, $period: String, $nextToken: String) {
    listPayroll(yearMonth: $yearMonth, period: $period, nextToken: $nextToken) {
      yearMonth
      period
      employeeCount
      employeeTypeCount {
        FULLTIME_MONTH
        FULLTIME_DAY
        PARTTIME
        OUTSOURCE
      }
      employeeHireCount
      employeeRetireCount
      status
      createdAt
      updatedAt
      paySlipEmpPeriod
      periodList {
        period
        status
        paySlipEmpPeriod
        closeDate
      }
      totalPayroll
      closeDate
      nextToken
      employeePayrollList {
        employeeID
        leaveMinute
        paySlipURL
        employeePeriod
        paymentID
        daysInPeriod
        employee {
          id
          positionID
          departmentID
          nameTitle
          fullName
          lastName
          department {
            departmentName
          }
        }
        netIncome
        netNegativeIncome
        outEarlyExpense
        breakLateExpense
        inLateExpense
        otherExpenseList {
          id
          name
          incomeOrExpense
          incomeExpenseType
          variableOrFixed
          income40Type
          amount
        }
        netPositiveIncome
        outLateIncome
        breakEarlyIncome
        inEarlyIncome
        workDiligentIncome
        welfareList {
          id
          name
          income40Type
          amount
        }
        leaveDeductMinute
        otTypeWorkMinute
        otTypeOTMinute
        income
        otTypeOTIncome
        otherIncomeList {
          id
          name
          incomeOrExpense
          incomeExpenseType
          variableOrFixed
          income40Type
          amount
        }
        otTypeWorkIncome
        workingMinute
      }
      timeAttendanceReportList {
        employeeID
        employee {
          id
          fullName
          lastName
          department {
            id
            departmentName
          }
        }
        items {
          companyID
          date
          employeeID
          createdAt
          dayType
          Type
          updatedAt
          isLink
          remark
          shiftName
          shiftStartTime
          shiftEndTime
          shiftVersion
          shiftMinute
          scanIn
          scanOut
          inDiffMinute
          outDiffMinute
          breakDiffMinute
          workingTimeList {
            startedAt
            endedAt
            workingMinute
          }
          workingMinute
          overtimeList {
            startedAt
            endedAt
            overtimeMinute
            overtimeType
            locationType
            status
            overtimeApproveMinute
            overtimeRequestID
          }
          overtimeMinute
          overtimeApproveList {
            startedAt
            endedAt
            overtimeMinute
            overtimeType
            locationType
            status
            overtimeApproveMinute
            overtimeRequestID
          }
          overtimeApproveMinute
          leaveList {
            startedAt
            endedAt
            leaveMinute
            leaveType
            leaveForm
            leaveDocumentAttachment
            leaveRequestID
            status
          }
          leaveMinute

          leaveDeductMinute
        }
      }
    }
  }
`
