//   const [periodStartDate, periodEndDate] = getPeriodStartEndDate(year, month, periodStart)
export function getPeriodStartEndDate(year: number, month: number, periodStart: number): [Date, Date] {
  const monthNumber = month - 1
  const periodStartDate =
    periodStart === 1 ? new Date(year, monthNumber, periodStart) : new Date(year, monthNumber - 1, periodStart) //Month allows negative values
  const periodEndDate =
    periodStart === 1 ? new Date(year, monthNumber + 1, 0) : new Date(year, monthNumber, periodStart - 1) // If startAt 1, endAt "LASTDAY"; else start-1
  return [periodStartDate, periodEndDate]
}

enum EPeriodCount {
  One = 'ONE',
  Two = 'TWO',
  Three = 'THREE',
  Four = 'FOUR',
}

const periodCount2Number = {
  [EPeriodCount.One]: 1,
  [EPeriodCount.Two]: 2,
  [EPeriodCount.Three]: 3,
  [EPeriodCount.Four]: 4,
}

//   const daysInPeriod = getDaysInPeriod(periodStartDate, periodEndDate)
export function getDaysInPeriod(startDate: Date, endDate: Date) {
  const date = new Date(startDate.getTime())
  const days = []
  while (date <= endDate) {
    days.push(new Date(date.getTime()))
    date.setDate(date.getDate() + 1)
  }
  return days
}

function addDays(date: Date, days: number) {
  const newDate = new Date(date.getTime())
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

function getDateInRange(startDate: Date, endDate: Date, day: number) {
  const date = new Date(startDate.getTime())
  while (date <= endDate) {
    if (date.getDate() === day) return date
    date.setDate(date.getDate() + 1)
  }
  throw new Error('Require day to be between startDate and endDate!')
}

function convertToPeriodElement(period: string, startDate: Date, endDate: Date) {
  return {
    period,
    periodStart: convertDateString(startDate),
    periodEnd: convertDateString(endDate),
  }
}

export function convertDateString(date: Date): string {
  return date.toLocaleString('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

// const periodList = convertToPeriodList(
//     employeePeriod,
//     companyPeriod,
//     periodStartDate,
//     periodEndDate,
//     companyPayrollConfig,
//   )
/** Get the periodList from the employeePeriod and companyPeriod
 * @param {EEmployeePeriod} employeePeriod tells whether the Employee is FULL (1.1) / PERIOD (depends on company)
 *
 * @warning there is no employeePeriod on front end so I put the employeePeriod out of it
 *
 * @param {EPeriodCount} companyPeriod tells if this company has a period (if any) of ONE (1.1) / TWO (2.1/2.2) / THREE... / FOUR...
 * @param {Date} startDate is starting date of the period
 * @param {Date} endDate is the ending date of the period
 * @param {PayrollConfig} companyPayrollConfig
 * @return {PeriodElement[]} List PeriodElement Objects
 */
export function convertToPeriodList(
  companyPeriod: EPeriodCount,
  startDate: Date,
  endDate: Date,
  companyPayrollConfig: any,
): PeriodElement[] {
  //   // If Employee is Full period/ Company has only 1 period: Return only period 1.1
  //   if (employeePeriod === EEmployeePeriod.Full || companyPeriod === EPeriodCount.One) {
  //     return [convertToPeriodElement('1.1', startDate, endDate)]
  //   } else {
  // If Employee is PERIOD and Company isn't ONE
  // Need to check each case!
  const periodMax = periodCount2Number[companyPeriod]
  const periodElementList = []
  let periodStartDate: Date = startDate
  let periodEndDate: Date = startDate // didn't want to put it here but typescript requires, else periodStartDate will error
  for (let periodNo = 1; periodNo <= periodMax; periodNo++) {
    const period = `${periodMax}.${periodNo}`
    if (periodNo === 1) periodStartDate = startDate
    else periodStartDate = addDays(periodEndDate, 1)

    if (periodNo === periodMax) periodEndDate = endDate
    else {
      const dayToCheck = companyPayrollConfig[`period${periodNo}Day`] as number
      periodEndDate = getDateInRange(periodStartDate, endDate, dayToCheck)
    }

    periodElementList.push(convertToPeriodElement(period, periodStartDate, periodEndDate))
  }
  return periodElementList
  //   }
}

// A PeriodElement is a period
export interface PeriodElement {
  period: string // 1.1/X.1/X.2/.../X.x
  periodStart: string // ISO8601 date string ie. 2021-10-29
  periodEnd: string // ISO8601 date string ie. 2021-10-29
}
