interface IAction {
  type: string
  title: string
  subtitle: string
  description: string
  btnDone: string

  functionCallBack: () => void
  openModalConfirm: boolean
}

export interface IInitialStateModalConfirm {
  title: string
  subtitle: string
  description: string
  btnDone: string

  functionCallBack: () => void
  openModalConfirm: boolean
}

const initialState: IInitialStateModalConfirm = {
  title: '',
  subtitle: '',
  description: '',
  btnDone: '',
  functionCallBack: () => null,
  openModalConfirm: false,
}

const reducer = (state = initialState, action: IAction): any => {
  switch (action.type) {
    case 'SET_OPEN_MODAL_CONFIRM':
      return {
        ...state,
        title: action.title,
        subtitle: action.subtitle,
        description: action.description,
        btnDone: action.btnDone,

        functionCallBack: action.functionCallBack,
        openModalConfirm: true,
      }
    case 'SET_CLOSE_MODAL_CONFIRM':
      return {
        ...state,
        title: '',
        subtitle: '',
        description: '',
        btnDone: '',

        functionCallBack: () => null,
        openModalConfirm: false,
      }
    default:
      return state
  }
}

export default reducer
