import * as React from 'react'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Checkbox } from '@mui/material'
import imageShow from '../../image/image.svg'
import styles from '../Menu_Employee/index.module.scss'
import { useNavigate } from 'react-router-dom'
//svg
import editIcon from '../../image/edit.svg'
import deleteIcon from '../../image/del.svg'
import pinIcon from '../../image/pin.svg'
import tellIcon from '../../image/tell.svg'
import mailIcon from '../../image/mail.svg'
import lineIcon from '../../image/line.svg'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
// import { IndeterminateCheckBox, OpenInBrowser } from '@mui/icons-material'

function createData(img: any, id: any, name: any, hiredate: any, position: any, status: any) {
  return {
    img,
    id,
    name,
    hiredate,
    position,
    status,
  }
}

function Row(props: any) {
  const dispatch = useDispatch()
  const { row } = props
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  return (
    <React.Fragment>
      {props.employeeList.map((item: any, index: number) => (
        <TableRow
          key={index}
          sx={{ '& > *': { borderBottom: 'unset' }, fontFamily: 'KanitRegular', cursor: 'pointer' }}
          onClick={() => setOpen(!open)}
        >
          <TableCell align="center">
            <Checkbox
              color="primary"
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          <TableCell align="center">{row.img}</TableCell>
          <TableCell align="center">{/* <p>{item.id}</p> */}</TableCell>
          <TableCell align="center">
            <p>{row.name}</p>
          </TableCell>
          <TableCell align="center">
            <p>{row.hiredate}</p>
          </TableCell>
          <TableCell align="center">
            <p>{row.position}</p>
          </TableCell>
          <TableCell>
            <div className={styles.status}>
              <div className={row.status == 'Active' ? styles.statusActive : styles.statusinActive}> </div>
              <p className={styles.text}>{row.status}</p>
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.action}>
              <button type="button" className={styles.btEdit}>
                <img src={editIcon} alt="" onClick={() => navigate('../menuemployee/edit/:id')} />
              </button>
              <button type="button" className={styles.btDelete}>
                <img
                  src={deleteIcon}
                  alt=""
                  onClick={() =>
                    dispatch(
                      allAction.modalConfirmAction.setOpenModalConfirm(
                        'ลบข้อมูลพนักงาน',
                        'คุณต้องการลบข้อมูลพนักงาน',
                        'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                        'ยิืนยัน',
                        () => {
                          dispatch(allAction.modalAction.setOpenModalAlertStatus('ลบข้อมูลสำเร็จ', '', 'success'))
                          dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        },
                      ),
                    )
                  }
                />
              </button>
              <div> </div>
            </div>
          </TableCell>
        </TableRow>
      ))}

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className={styles.collapseBox}>
              <div className={styles.Box}>
                {' '}
                <p className={styles.text}>ข้อมูลการติดต่อ</p>
                <div className={styles.iconBox}>
                  <img src={pinIcon} alt="" className={styles.startIcon} />
                  <p className={styles.data}> 44/24 จ.อยุธยา 13130</p>
                </div>
                <div className={styles.iconBox}>
                  <img src={tellIcon} alt="" className={styles.startIcon} />
                  <p className={styles.data}> 082-482-9098</p>
                </div>
                <div className={styles.iconBox}>
                  <img src={mailIcon} alt="" className={styles.startIcon} />
                  <p className={styles.data}> admin2113@gmail.com</p>
                </div>
                <div className={styles.iconBox}>
                  <img src={lineIcon} alt="" className={styles.startIcon} />
                  <p className={styles.data}> admin.Line</p>
                </div>
              </div>
              <div className={styles.Box2}>
                {' '}
                <p className={styles.text}>ข้อมูลทั่วไป</p>
                <div className={styles.textBox}>
                  <p className={styles.subtext}>เพศ :</p>
                  <p>หญิง </p>
                </div>
                <div className={styles.textBox}>
                  <p className={styles.subtext}>วันเกิด :</p>
                  <p>13 November 1999 </p>
                </div>
                <div className={styles.textBox}>
                  <p className={styles.subtext}>เลขบัตรประจำตัว :</p>
                  <p>xxxx-xxxxxx-xx-xx-x </p>
                </div>
                <div className={styles.textBox}>
                  <p className={styles.subtext}>สถานภาพ :</p>
                  <p>โสด </p>
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
// const rows = [
//   createData(<img src={imageShow} />, '001', 'Thanyarat', 'mm/dd/yyyy', 'Admin', 'Active'),
//   createData(<img src={imageShow} />, '002', 'Beam', 'Admin', 'mm/dd/yyyy', 'Inactive'),
// ]

export default function CollapsibleTable(props: any) {
  // const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  return (
    <Table aria-label="collapsible table">
      <TableHead sx={{ position: 'sticky', top: '-10px', backgroundColor: '#cad6ea' }}>
        <TableRow>
          {/* <TableCell align="center">
            <Checkbox
              color="primary"
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell> */}
          {/* <TableCell align="center"></TableCell> */}
          <TableCell align="center">
            <h4>รหัสพนักงาน</h4>
          </TableCell>
          <TableCell align="left">
            <h4>ชื่อ - นามสกุล</h4>
          </TableCell>
          <TableCell align="center">
            <h4>วันเริ่มงาน</h4>
          </TableCell>
          <TableCell align="center">
            <h4>ตำแหน่ง</h4>
          </TableCell>
          {/* <TableCell align="left">
            <h4>สถานะ</h4>
          </TableCell> */}
          {/* <TableCell align="center"></TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {props.employeeList
            .filter((item: any) => {
              if (props.employeename === '') {
                return item
              } else if (
                item.fullName.toLocaleLowerCase().includes(props.employeename.toLocaleLowerCase()) ||
                item.lastName.toLocaleLowerCase().includes(props.employeename.toLocaleLowerCase())
              ) {
                return item
              }
            })
            .map((item: any, index: number) => (
              <TableRow
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`../employee/employee/edit/${item.id}`)}
              >
                {/* <TableCell align="center">
                  <Checkbox
                    color="primary"
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell> */}
                {/* <TableCell align="center"></TableCell> */}
                <TableCell align="center">
                  <p>{item.id}</p>
                </TableCell>
                <TableCell align="left">
                  <p>
                    {item.fullName} {item.lastName}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p>{item.hireDate}</p>
                </TableCell>
                <TableCell align="center">
                  <p>{item.department?.departmentName}</p>
                </TableCell>
                {/* <TableCell align="left">
                <div className={styles.status}>
                  <div className={item.status == 'ACTIVE' ? styles.statusActive : styles.statusinActive}> </div>
                  <p className={styles.text}>{item.status}</p>
                </div>
              </TableCell> */}
                {/* <TableCell align="center">
                <img
                  src={editIcon}
                  alt=""
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/employee/employee/edit/${item.id}`)
                    // console.log(item.id)
                  }}
                />
                <img src={deleteIcon} alt="" style={{ cursor: 'pointer' }} />
              </TableCell> */}
              </TableRow>
            ))}
          {/* 
          <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={styles.collapseBox}>
                  <p>OPEN</p>
                </div>
              </Collapse>
            </TableCell>
          </TableRow> */}
        </React.Fragment>

        {/* {rows.map((row) => (
          <Row key={row.id} row={row} employeeList={props.employeeList} />
        ))} */}
      </TableBody>
    </Table>
  )
}
