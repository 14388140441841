import * as React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import styles from './index.module.scss'

//ModalEdit
import ModalEdit from './ModalEdit'
//Icon
import editIcon from '../../image/editcolor.svg'

export default function TabsWork() {
  const [topicModal, setTopicModal] = React.useState('')
  const [modalDataTable, setModalDatatable] = React.useState<any>()
  const [value, setValue] = React.useState('1')
  const [openModalEdit, setOpenModalEdit] = React.useState(false)
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }

  const dataTableLeave = [
    {
      id: 1,
      dateStart: '6/01/2022',
      dateEnd: '9/12/2022',
      annotation: 'ลาป่วย',
      status: 'Approve',
    },
    {
      id: 2,
      dateStart: '6/01/2022',
      dateEnd: '9/12/2022',
      annotation: 'เจ็บคอ',
      status: 'Pending',
    },
    {
      id: 3,
      dateStart: '6/01/2022',
      dateEnd: '9/12/2022',
      annotation: 'ไม่สบาย',
      status: 'Reject',
    },
    {
      id: 4,
      dateStart: '6/01/2022',
      dateEnd: '9/12/2022',
      annotation: 'ป่วย',
      status: 'Approve',
    },
    {
      id: 5,
      dateStart: '6/01/2022',
      dateEnd: '10/12/2022',
      annotation: 'ลาป่วย',
      status: 'Approve',
    },
    {
      id: 6,
      dateStart: '6/01/2022',
      dateEnd: '10/12/2022',
      annotation: 'ลาป่วย',
      status: 'Approve',
    },
    {
      id: 7,
      dateStart: '6/01/2022',
      dateEnd: '10/12/2022',
      annotation: 'ลาป่วย',
      status: 'Approve',
    },
    {
      id: 8,
      dateStart: '6/01/2022',
      dateEnd: '10/12/2022',
      annotation: 'ลาป่วย',
      status: 'Approve',
    },
    {
      id: 9,
      dateStart: '6/01/2022',
      dateEnd: '10/12/2022',
      annotation: 'ลาป่วย',
      status: 'Approve',
    },
    {
      id: 10,
      dateStart: '6/01/2022',
      dateEnd: '10/12/2022',
      annotation: 'ลาป่วย',
      status: 'Approve',
    },
  ]

  const dataTableLate = [
    {
      date: '6/01/2022',
      timeCheckIn: '8 : 05 :12',
      late: 1,
    },
    {
      date: '7/01/2022',
      timeCheckIn: '8 : 05 :12',
      late: 5,
    },
    {
      date: '8/01/2022',
      timeCheckIn: '9 : 05 :12',
      late: 6,
    },
    {
      date: '9/01/2022',
      timeCheckIn: '8 : 05 :12',
      late: 2,
    },
    {
      date: '10/01/2022',
      timeCheckIn: '8 : 05 :12',
      late: 3,
    },
  ]

  const dataTableOvertime = [
    {
      id: 1,
      dateStart: '6/01/2022 8:05:12',
      dateEnd: '6/01/2022 18:05:12',
      overTime: '3',
    },
    {
      id: 2,
      dateStart: '6/01/2022 8:05:12',
      dateEnd: '6/01/2022 18:05:12',
      overTime: '3',
    },
    {
      id: 3,
      dateStart: '6/01/2022 8:05:12',
      dateEnd: '6/01/2022 18:05:12',
      overTime: '3',
    },
    {
      id: 4,
      dateStart: '6/01/2022 8:05:12',
      dateEnd: '6/01/2022 18:05:12',
      overTime: '2',
    },
  ]

  const dataTableWorkingtime = [
    {
      id: 1,
      dateStart: '6/01/2022 8:55:55',
      dateEnd: '6/01/2022 18:05:12',
      workingTime: '8',
    },
    {
      id: 2,
      dateStart: '6/01/2022 8:05:12',
      dateEnd: '6/01/2022 18:05:12',
      workingTime: '8',
    },
    {
      id: 3,
      dateStart: '6/01/2022 8:05:12',
      dateEnd: '6/01/2022 18:05:12',
      workingTime: '8',
    },
    {
      id: 4,
      dateStart: '6/01/2022 8:05:12',
      dateEnd: '6/01/2022 18:05:12',
      workingTime: '8',
    },
    {
      id: 5,
      dateStart: '6/01/2022 8:05:12',
      dateEnd: '6/01/2022 18:05:12',
      workingTime: '8',
    },
  ]

  const leaveData = [
    {
      no: '1',
      leave: 'ลาป่วย',
      count: 10,
      spentday: 12,
      hour: 0,
    },
    {
      no: '2',
      leave: 'ลากิจ',
      count: 10,
      spentday: 9,
      hour: 0,
    },
    {
      no: '3',
      leave: 'ลาพักร้อน',
      count: 7,
      spentday: 3,
      hour: 0,
    },
    {
      no: '4 ',
      leave: 'ลากิจไม่หักเงิน',
      count: 10,
      spentday: 3,
      hour: 0,
    },
    {
      no: '5',
      leave: 'ขาด',
      count: 7,
      spentday: 3,
      hour: 0,
    },
    {
      no: '6',
      leave: 'ลากิจไม่หักเงิน',
      count: 10,
      spentday: 3,
      hour: 0,
    },
  ]

  const benefitData = [
    {
      order: 'Playglao',
      compensationName: 'Playglao',
      compensationType: 'FOOD',
      compensationPaid: '100',
      compensationUsed: '100',
      status: 'Approve',
    },
    {
      order: 'Playglao',
      compensationName: 'Playglao',
      compensationType: 'SNACK',
      compensationPaid: '100',
      compensationUsed: '100',
      status: 'Approve',
    },
  ]

  const late = [{ no: '1', minute: 32 }]

  const OT = [{ no: '1', hour: '10:30' }]

  const hourWork = [{ no: '1', hour: '40' }]

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', fontFamily: 'Kanit' }}>
          <TabList
            variant="scrollable"
            className={styles.tabTopic}
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab label={<span className={styles.tabTopic}>วันลา</span>} value="1" />
            <Tab label={<span className={styles.tabTopic}>มาสาย</span>} value="2" />
            <Tab label={<span className={styles.tabTopic}>การทำงานล่วงเวลา</span>} value="3" />
            <Tab label={<span className={styles.tabTopic}>การเข้า/ออกงาน</span>} value="4" />
            <Tab label={<span className={styles.tabTopic}>สวัสดิการ</span>} value="5" />
          </TabList>
        </Box>

        <TabPanel value="1" className={styles.tabScroll}>
          <div className={styles.cardBox}>
            {' '}
            <div className={styles.content}>
              {leaveData.map((data) => (
                <div className={styles.card} key={data.no}>
                  <button
                    className={styles.btnEdit}
                    type="button"
                    onClick={() => {
                      setModalDatatable(dataTableLeave)
                      setTopicModal(`ข้อมูลวัน${data.leave}`)
                      setOpenModalEdit(true)
                    }}
                  >
                    <img src={editIcon} alt="" />
                  </button>
                  <div className={styles.textgroup}>
                    <span className={styles.title}>{data.leave}</span>
                  </div>

                  <div className={styles.bottom}>
                    <article className={styles.textgroup}>
                      <p className={styles.titleSpent}>{`สิทธิ${data.leave}ได้รับค่าจ้าง`}</p>
                      <p className={styles.titleSpent}>
                        {data.spentday} วัน {data.hour} ชั่วโมง
                      </p>
                    </article>
                    <article className={styles.textgroup}>
                      <p className={styles.titleremain}>สิทธิลาป่วยไม่ได้รับค่าจ้าง</p>
                      <p className={styles.titleremain}>
                        {data.spentday} วัน {data.hour} ชั่วโมง
                      </p>
                    </article>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>

        <TabPanel value="2" className={styles.tabScroll}>
          <div className={styles.cardLate}>
            {' '}
            <div className={styles.content}>
              {late.map((data) => (
                <div className={styles.card} key={data.no}>
                  <button
                    className={styles.btnEdit}
                    type="button"
                    onClick={() => {
                      setModalDatatable(dataTableLate)
                      setTopicModal('ข้อมูลวันมาสาย')
                      setOpenModalEdit(true)
                    }}
                  >
                    <img src={editIcon} alt="" />
                  </button>
                  <div className={styles.lategroup}>
                    <p className={styles.title}>มาสายรวม</p>
                    <div className={styles.lateText}>
                      <span className={styles.numtext}>
                        <span className={styles.text}>{data.minute}</span> /120
                      </span>
                    </div>
                    <p className={styles.subtext}>นาที</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>

        <TabPanel value="3" className={styles.tabScroll}>
          <div className={styles.cardLate}>
            {' '}
            <div className={styles.content}>
              {OT.map((data) => (
                <div className={styles.card} key={data.no}>
                  <button
                    className={styles.btnEdit}
                    type="button"
                    onClick={() => {
                      setTopicModal('ข้อมูลวันทำงานล่วงเวลา')
                      setModalDatatable(dataTableOvertime)
                      setOpenModalEdit(true)
                    }}
                  >
                    <img src={editIcon} alt="" />
                  </button>
                  <div className={styles.lategroup}>
                    <p className={styles.title}>ทำงานล่วงเวลารวม</p>
                    <div className={styles.lateText}>
                      <span className={styles.numtext}>
                        <span className={styles.text}>{data.hour}</span>
                      </span>
                    </div>
                    <p className={styles.subtext}>ชั่วโมง</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>

        <TabPanel value="4" className={styles.tabScroll}>
          <div className={styles.cardLate}>
            {' '}
            <div className={styles.content}>
              {hourWork.map((data) => (
                <div className={styles.card} key={data.no}>
                  <button
                    className={styles.btnEdit}
                    type="button"
                    onClick={() => {
                      setTopicModal('ข้อมูลการเข้า/ออกงาน')
                      setModalDatatable(dataTableWorkingtime)
                      setOpenModalEdit(true)
                    }}
                  >
                    <img src={editIcon} alt="" />
                  </button>
                  <div className={styles.lategroup}>
                    <p className={styles.title}>ทำงานรวมทั้งหมด</p>
                    <div className={styles.lateText}>
                      <span className={styles.numtext}>
                        <span className={styles.text}>{data.hour}</span>
                      </span>
                    </div>
                    <p className={styles.subtext}>ชั่วโมง</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
        <TabPanel value="5" className={styles.tabScroll}>
          <div className={styles.cardBox}>
            {' '}
            <div className={styles.content}>
              {benefitData.map((data, index: number) => (
                <div className={styles.card} key={index}>
                  <button
                    className={styles.btnEdit}
                    type="button"
                    onClick={() => {
                      setModalDatatable(benefitData)
                      setTopicModal(`ข้อมูลวัน${data.compensationType}`)
                      setOpenModalEdit(true)
                    }}
                  >
                    <img src={editIcon} alt="" />
                  </button>
                  <div className={styles.textgroup}>
                    <span className={styles.title}>{data.compensationType}</span>
                  </div>

                  <div className={styles.bottom}>
                    <article className={styles.textgroup}>
                      <p className={styles.titleSpent}>{`สิทธค่า${data.compensationType}ใช้จริง`}</p>
                      <p className={styles.titleSpent}>{data.compensationUsed} บาท</p>
                    </article>
                    <article className={styles.textgroup}>
                      <p className={styles.titleremain}>{`สิทธค่า${data.compensationType}ที่ใช้เกิน`} </p>
                      <p className={styles.titleremain}>{data.compensationPaid} บาท</p>
                    </article>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
      </TabContext>
      <ModalEdit
        openModalEdit={openModalEdit}
        setOpenModalEdit={setOpenModalEdit}
        dataTable={modalDataTable}
        topic={topicModal}
      />
    </Box>
  )
}
