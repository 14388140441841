import React, { FC, useCallback, useEffect, useState } from 'react'
import { classes } from '../../utils/common'
import styles from './index.module.scss'

interface Props {
  options: SwitchOption[]
  value?: string | number | boolean
  disables?: boolean
  onChange?: (e: string | number | boolean) => void
}

interface SwitchOption {
  label: string
  value: string | number | boolean
}

const Switch: FC<Props> = (props: Props) => {
  const [selectValue, setSelectValue] = useState<SwitchOption['value']>(() => props.value || props.options[0].value)

  const isSelect = useCallback(
    (option: SwitchOption) => {
      return option.value === selectValue
    },
    [selectValue],
  )

  useEffect(() => {
    props.onChange?.call(null, selectValue)
  }, [selectValue])

  return (
    <div className={styles.switchContainer}>
      {props.options.map((option) => (
        <button
          type="button"
          onClick={() => setSelectValue(option.value)}
          className={classes(isSelect(option) && styles.active)}
          key={option.value.toString()}
          disabled={props.disables}
        >
          {option.label}
        </button>
      ))}
    </div>
  )
}

export default Switch
