import React from 'react'
import styles from './index.module.scss'
import Fade from '@mui/material/Fade'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
//icon
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '../../image/edit.svg'
import DeleteIcon from '../../image/delete.svg'
import ViewIcon from '../../image/viewIcon.svg'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

const MenuItemList: React.FC<any> = (props: any): JSX.Element => {
  console.log('Props-MenuItem', props)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    // console.log('menu')

    // setSelectedRowItem([])
    setAnchorEl(null)
  }
  return (
    <>
      <button
        className={styles.btnMore}
        onClick={(event: any) => {
          // console.log('dataListIndex', dataListIndex)
          // handleClick(event, list)
          setAnchorEl(event.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </button>
      <Menu
        id={'fade-menu'}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose()
        }}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            props.handleClose
            // props.viewOnclick()
          }}
        >
          <ListItemIcon>
            {' '}
            <img src={ViewIcon} alt="Edit" />
          </ListItemIcon>
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            console.log('edit')
            handleClose()
            props.edit()
          }}
        >
          <ListItemIcon>
            <img src={EditIcon} alt="Edit" />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            // props.deleteOnclick()
          }}
        >
          <ListItemIcon>
            <img src={DeleteIcon} alt="Edit" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}
export default MenuItemList
