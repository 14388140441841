import React, { useRef, useState, useEffect } from 'react'
import styles from './index.module.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { InputNumberComponentCustom } from '../../../component'

export interface ITableHeaderList {
  text: string
  key: string
  isfontWeightBold?: boolean
  bodyBackgroundColor?: string
  isEnableShow?: boolean
}

interface ITableProps {
  //   topic: 'รายรับ' | 'รายจ่าย'
  tableHeaderList: ITableHeaderList[]
  tableBodyUniqueDepartment: any
  TableSummaryHeaderList: string[]
  employeeCount: number
  isShowMoney?: boolean
}

const CheckType = (type: any) => {
  if (typeof type === 'number') {
    return type.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return type
  }
}

const SummarySalaryTable: React.FC<ITableProps> = (props: ITableProps): JSX.Element => {
  return (
    <div className={styles.tableContainer}>
      {/* <section className={styles.tableTopic}>{props.topic}</section> */}
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              borderRight: '1px solid rgba(0, 0, 0, 0.2)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
              borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
              position: 'sticky',
              top: '0',
              backgroundColor: '#3A77DA',
              zIndex: 999,
            }}
          >
            {props.tableHeaderList.map((headerString: ITableHeaderList, headerIndex: number) => {
              return (
                <TableCell
                  // sx={
                  //   headerString.isfontWeightBold
                  //     ? { border: 'none', fontWeight: 'bold', color: 'white' }
                  //     : { border: 'none', color: 'white' }
                  // }
                  sx={
                    headerString.text === 'ลำดับ'
                      ? { position: 'sticky', left: 0, backgroundColor: '#3A77DA', color: '#FFFFFF' }
                      : headerString.text === 'รหัสพนักงาน'
                      ? {
                          position: 'sticky',
                          left: '65px',
                          backgroundColor: '#3A77DA',
                          color: '#FFFFFF',
                        }
                      : headerString.text === 'ชื่อพนักงาน'
                      ? {
                          position: 'sticky',
                          left: '171px',
                          backgroundColor: '#3A77DA',
                          color: '#FFFFFF',
                        }
                      : { border: 'none', color: 'white' }
                  }
                  align="center"
                  key={headerIndex}
                >
                  {headerString.text}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.tableBodyUniqueDepartment &&
            Object.keys(props?.tableBodyUniqueDepartment).length &&
            Object.keys(props?.tableBodyUniqueDepartment).map((departmentName: string, tableBodyIndex: number) => {
              return (
                <>
                  <TableRow
                    key={tableBodyIndex}
                    sx={{
                      borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                      borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                      color: '#3A77DA',
                      backgroundColor: '#CAD6EA',
                    }}
                  >
                    <TableCell sx={{ border: 'none' }} colSpan={props.tableHeaderList.length} align="left">
                      {departmentName}
                    </TableCell>
                  </TableRow>
                  {props?.tableBodyUniqueDepartment[departmentName]?.map(
                    (employeeElement: any, employeeIndex: number) => {
                      return (
                        <TableRow
                          sx={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                            borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                          }}
                          key={employeeIndex}
                        >
                          {props.tableHeaderList.map((headerString: ITableHeaderList, headerIndex: number) => {
                            return (
                              <>
                                {/* employeeID */}
                                {headerString.key === 'No.' ? (
                                  <TableCell
                                    sx={{
                                      border: 'none',
                                      position: 'sticky',
                                      left: 0,
                                      backgroundColor: '#E5E5E5',
                                      fontWeight: 'bold',
                                    }}
                                    align="center"
                                    key={headerIndex}
                                  >
                                    {tableBodyIndex + 1}
                                  </TableCell>
                                ) : headerString.key === 'employeeID' ? (
                                  <TableCell
                                    sx={{
                                      border: 'none',
                                      position: 'sticky',
                                      left: '65px',
                                      backgroundColor: '#E5E5E5',
                                      fontWeight: 'bold',
                                    }}
                                    align="center"
                                    key={headerIndex}
                                  >
                                    {employeeElement[headerString.key]}
                                  </TableCell>
                                ) : headerString.key === 'employeeName' ? (
                                  <TableCell
                                    sx={{
                                      border: 'none',
                                      position: 'sticky',
                                      left: '171px',
                                      backgroundColor: '#E5E5E5',
                                      fontWeight: 'bold',
                                    }}
                                    align="center"
                                    key={headerIndex}
                                  >
                                    {employeeElement[headerString.key]}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    sx={
                                      headerString.bodyBackgroundColor
                                        ? { border: 'none', backgroundColor: headerString.bodyBackgroundColor }
                                        : { border: 'none' }
                                    }
                                    align="center"
                                    key={headerIndex}
                                  >
                                    {headerString.isEnableShow && !props.isShowMoney
                                      ? 'xxx'
                                      : CheckType(employeeElement[headerString.key])}
                                  </TableCell>
                                )}
                              </>
                            )
                          })}
                        </TableRow>
                      )
                    },
                  )}
                  <TableRow
                    sx={{
                      borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                      borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                      color: '#3A77DA',
                      backgroundColor: '#CAD6EA',
                    }}
                  >
                    <TableCell sx={{ border: 'none' }} colSpan={3} align="left">
                      รวมจำนวนพนักงานของ {departmentName} {props?.tableBodyUniqueDepartment[departmentName]?.length} คน
                    </TableCell>
                    {props.TableSummaryHeaderList?.map((sumHeaderString: string, sumHeaderIndex: number) => {
                      let summaryArr: any[] = props?.tableBodyUniqueDepartment[departmentName]?.map(
                        (employeeElement: any) => {
                          if (employeeElement[sumHeaderString]) {
                            return employeeElement[sumHeaderString]
                          }
                        },
                      )
                      summaryArr = summaryArr.filter(Boolean)
                      if (!props.isShowMoney) {
                        return (
                          <TableCell sx={{ border: 'none' }} align="center">
                            xxx
                          </TableCell>
                        )
                      } else {
                        if (summaryArr.length !== 0) {
                          const totalSum = summaryArr
                            .map((summaryVal: any) => {
                              // console.log(summaryVal, 'summaryVal')
                              return parseFloat(summaryVal)
                            })
                            .reduce((prev: number, curr: number) => prev + curr)
                          return (
                            <TableCell sx={{ border: 'none' }} align="center">
                              {Number.isInteger(totalSum)
                                ? totalSum
                                : totalSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          )
                        } else {
                          return (
                            <TableCell sx={{ border: 'none' }} align="center">
                              0
                            </TableCell>
                          )
                        }
                      }
                    })}
                  </TableRow>
                </>
              )
            })}
          <TableRow
            sx={{
              borderRight: '1px solid rgba(0, 0, 0, 0.2)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
              borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
              backgroundColor: '#3A77DA',
            }}
          >
            <TableCell sx={{ border: 'none', color: 'white' }} colSpan={props.tableHeaderList.length - 1} align="left">
              รวมจำนวนพนักงานทั้งหมดของสำนักงานใหญ่ {props.employeeCount} คน
            </TableCell>
            <TableCell sx={{ border: 'none' }} align="center">
              {!props.isShowMoney
                ? 'xxx'
                : props?.tableBodyUniqueDepartment &&
                  Object.keys(props?.tableBodyUniqueDepartment).length &&
                  Object.keys(props?.tableBodyUniqueDepartment)
                    .map((departmentName: string) => {
                      const totalIncomeArr = props?.tableBodyUniqueDepartment[departmentName]
                        .map((employeeElement: any) => {
                          if (employeeElement.netIncome) {
                            return employeeElement.netIncome
                          }
                        })
                        .filter(Boolean)
                      const sumTotalIncome = totalIncomeArr.map((summaryVal: any) => {
                        return summaryVal.toFixed(2)
                      }).length
                        ? totalIncomeArr
                            .map((summaryVal: any) => {
                              return parseFloat(summaryVal)
                            })
                            .reduce((prev: number, curr: number) => prev + curr)
                        : 0

                      return sumTotalIncome
                    })
                    .reduce((prev: number, curr: number) => prev + curr)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default SummarySalaryTable
