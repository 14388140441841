import React, { useState, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import * as yup from 'yup'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import allAction from '../../state/actions'
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent } from '../../component'
import { Background, Compensation, Document, Employment, Payment, PersonalTab, Settings } from './InputFiled'
import { CreateEmployeeProfile, GetEmployeeProfile, UpdateEmployeeProfile } from '../../adapter/xhr'
// import ALertIcon from '../../image/alertRed.svg'
// import { Tooltip } from '@mui/material'
import moment from 'moment'
// import { LabTabs } from './tab'
const MenuEmployeeEdit: React.FC = (): JSX.Element => {
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [listTalent, setListTalent] = useState<any>([])
  // const [image, setImage] = useState('')
  const [tab, setTab] = useState<string>('personal')
  const formikRef: any = useRef()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>()

  useEffect(() => {
    if (id) {
      const FiledData = async () => {
        const response: any = await GetEmployeeProfile({ getEmployeeId: id }).then((res: any) => {
          setData(res.data.data.getEmployee)
          const data = res.data.data.getEmployee
          const setFieldRef = formikRef.current.setFieldValue
          console.log(data, 'data')

          //Tab1
          setFieldRef('nameTitle', data.nameTitle)
          setFieldRef('fullName', data.fullName)
          setFieldRef('middleName', data.middleName)
          setFieldRef('lastName', data.lastName)
          setFieldRef('personalID', data.personalID)
          setFieldRef('ethnicity', data.ethnicity)
          setFieldRef('nationality', data.nationality)

          setFieldRef('fullNameEnglish', data.fullNameEnglish)
          setFieldRef('middleNameEnglish', data.middleNameEnglish)
          setFieldRef('lastNameEnglish', res.data.data.getEmployee.lastNameEnglish)
          setFieldRef('sex', data.sex)
          if (data.personalRelation) {
            setFieldRef('personalRelation', data.personalRelation)
          }
          setFieldRef('birthDate', data?.birthDate)
          setFieldRef('nickName', data?.nickName)
          setFieldRef(`addressPersonalID.addressNumber`, data.addressPersonalID?.addressNumber)
          setFieldRef(`addressPersonalID.group`, data.addressPersonalID?.group)
          setFieldRef(`addressPersonalID.optional`, data.addressPersonalID?.optional)
          setFieldRef(`addressPersonalID.province`, data.addressPersonalID?.province)
          setFieldRef(`addressPersonalID.subDistrict`, data.addressPersonalID?.subDistrict)
          setFieldRef(`addressPersonalID.district`, data.addressPersonalID?.district)
          setFieldRef(`addressPersonalID.zipCode`, data.addressPersonalID?.zipCode)
          setFieldRef(`addressCurrent.addressNumber`, data.addressCurrent?.addressNumber)
          setFieldRef(`addressCurrent.group`, data.addressCurrent?.group)
          setFieldRef(`addressCurrent.optional`, data.addressCurrent?.optional)
          setFieldRef(`addressCurrent.province`, data.addressCurrent?.province)
          setFieldRef(`addressCurrent.subDistrict`, data.addressCurrent?.subDistrict)
          setFieldRef(`addressCurrent.district`, data.addressCurrent?.district)
          setFieldRef(`addressCurrent.zipCode`, data.addressCurrent?.zipCode)

          //Tab2
          setFieldRef(`id`, data?.id)
          setFieldRef(`fingerPrintId`, data?.fingerPrintId)
          setFieldRef(`hireDate`, data?.hireDate)
          setFieldRef(`retireDate`, data?.retireDate)
          setFieldRef(`departmentID`, data?.departmentID)
          setFieldRef(`positionID`, data?.positionID)
          //       // setFieldRef(`teamList`, data.teamList)
          //       // setFieldRef(`leaderTeam`, data.leaderTeam)
          if (data.employeeWorkStatus) {
            setFieldRef(`employeeWorkStatus`, data.employeeWorkStatus)
          }
          if (data.employeeType) {
            setFieldRef(`employeeType`, data.employeeType)
          }
          setFieldRef(`employeeWorkTypeShift`, data?.employeeWorkTypeShift)

          if (data.setShiftWorking) {
            setFieldRef(`setShiftWorking`, data.setShiftWorking)
          }
          setFieldRef(`shiftWorkingID`, data?.shiftWorkingID)

          if (data.workPlace) {
            setFieldRef(`workPlace`, data.workPlace)
          }
          //       // setFieldRef(`packageGroupID`, data.packageGroupID)
          //       // setFieldRef(`holidayID`, data.holidayID)
          if (data.holidayLink) {
            setFieldRef(`holidayLink`, data.holidayLink)
          }
          setFieldRef('isTimeAttendance', data.isTimeAttendance)

          //Tab3
          if (data.salaryOptional) {
            setFieldRef(`salaryOptional`, data.salaryOptional)
          }

          if (data.socialSecurityCalculate) {
            setFieldRef(`socialSecurityCalculate`, data.socialSecurityCalculate)
          }
          if (data.socialSecurity) {
            setFieldRef(`socialSecurity`, data.socialSecurity)
          }
          setFieldRef(`hospitalService`, data.hospitalService)
          if (data.personalIDNumber) {
            setFieldRef(`personalIDNumber`, data.personalIDNumber)
          }
          setFieldRef(`salary`, data.salary)
          if (data.incomeExpense) {
            setFieldRef(`incomeExpense`, data.incomeExpense)
          }
          if (data.employeeFund) {
            setFieldRef(`employeeFund`, data.employeeFund)
          }
          if (data.taxDeductOne) {
            setFieldRef(`taxDeductOne`, data.taxDeductOne)
          }
          if (data.taxDeductTwo) {
            setFieldRef(`taxDeductTwo`, data.taxDeductTwo)
          }
          setFieldRef(`diligentAllowance`, data.diligentAllowance)

          //Tab4
          if (data.paymentMethod) {
            setFieldRef(`paymentMethod`, data.paymentMethod)
          }

          setFieldRef(`bankPayment`, data?.bankPayment)
          setFieldRef(`bankAccountName`, data?.bankAccountName)
          setFieldRef(`bankAccountId`, data?.bankAccountId)

          //Tab5
          //       // setFieldRef(`bankPayment`, data.bankPayment)
          setFieldRef(`username`, data?.username)
          setFieldRef(`password`, data?.password)

          //Tab6
          if (data.historyFamily) {
            setFieldRef(`historyFamily`, data.historyFamily)
          }
          if (data.historyWork) {
            setFieldRef(`historyWork`, data.historyWork)
          }
          if (data.historyEducation) {
            setFieldRef(`historyEducation`, data.historyEducation)
          }

          if (data.personalTalent) {
            setListTalent(data.personalTalent)
          }
          if (data.personalIdAttachment) {
            setFieldRef(`personalIdAttachment`, data.personalIdAttachment)
          }
          if (data.personalHouseAttachment) {
            setFieldRef(`personalHouseAttachment`, data.personalHouseAttachment)
          }
          if (data.personalEducationalAttachment) {
            setFieldRef(`personalEducationalAttachment`, data.personalEducationalAttachment)
          }
          if (data.personalTrainningAttachment) {
            setFieldRef(`personalTrainningAttachment`, data.personalTrainningAttachment)
          }
          if (data.personalOptionalAttachment) {
            setFieldRef(`personalOptionalAttachment`, data.personalOptionalAttachment)
          }
          if (data.profileImage) {
            setFieldRef(`profileImage`, data.profileImage)
          }
        })
        setLoading(false)
      }
      FiledData()
      setMode('update')
    } else {
      setMode('create')
    }
  }, [])

  // console.log(loading, 'DATA')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const validationSchema = yup.object({
    //Tab1
    fullName: yup.string().required('กรอกชื่อ'),
    lastName: yup.string().required('กรอกนามสกุล'),
    birthDate: yup.date().required(),
    //Tab2
    hireDate: yup.date().required(),
    // retireDate: yup.date().required(),
    employeeType: yup.string().required('เลือกประเภทพนักงาน'),
    departmentID: yup.string().required('เลือกแผนกงาน'),
    positionID: yup.string().required('เลือกตำแหน่งงาน'),
    //Tab5
    username: yup.string().required('กรอกชื่อผู้ใช้'),
    password: yup.string().required('กรอกรหัสผ่าน'),
  })

  const TabData = [
    { name: 'personal', title: 'ข้อมูลพื้นฐาน' },
    { name: 'employment', title: 'ข้อมูลจ้างงาน' },
    { name: 'compensation', title: 'ข้อมูลค่าตอบแทน' },
    { name: 'payment', title: 'ข้อมูลการจ่ายเงิน' },
    { name: 'settings', title: 'การตั้งค่าผู้ใช้งาน' },
    { name: 'background', title: 'ประวัติส่วนตัว' },
    { name: 'document', title: 'เอกสาร' },
  ]

  const GetRetireDate = (EmployeeType: any, birthDate: string, hireDate: string) => {
    const probation: any = 90
    const hireDateNew = new Date(hireDate)
    if (EmployeeType === 'PROBATION') {
      const retireDate = moment(new Date(new Date().setDate(hireDateNew.getDate() + probation))).format('YYYY-MM-DD')
      console.log(moment(retireDate).isValid(), 'F')
      if (moment(retireDate).isValid() === false) return
      return retireDate
    } else {
    }
    return '2022-06-19'
  }

  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <div className="main">
          <div className={styles.container}>
            <div className={styles.navtop}>
              {mode === 'create' && <h1 className={styles.title}>เพิ่มพนักงาน</h1>}
              {mode === 'update' && <h1 className={styles.title}>แก้ไขข้อมูลพนักงาน</h1>}
            </div>
            <div className={styles.card_one}>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  //tab1
                  nameTitle: 'MR',
                  fullName: '',
                  middleName: '',
                  lastName: '',
                  personalID: '',
                  nationality: '',
                  ethnicity: '',
                  fullNameEnglish: '',
                  middleNameEnglish: '',
                  lastNameEnglish: '',
                  sex: 'MALE',
                  personalRelation: 'SINGLE',
                  birthDate: null,
                  nickName: '',
                  addressPersonalID: {
                    addressNumber: '',
                    group: '',
                    optional: '',
                    province: '',
                    subDistrict: '',
                    district: '',
                    zipCode: '',
                  },

                  addressCurrent: {
                    addressNumber: '',
                    group: '',
                    optional: '',
                    province: '',
                    subDistrict: '',
                    district: '',
                    zipCode: '',
                  },

                  //tab2
                  id: '',
                  fingerPrintId: '',
                  hireDate: '',
                  retireDate: '',
                  departmentID: '',
                  positionID: '',
                  teamList: '',
                  leaderTeam: '',
                  employeeWorkStatus: 'PROBATION',
                  employeeType: '',
                  employeeWorkTypeShift: '',
                  setShiftWorking: 'ENABLE',
                  shiftWorkingID: '',
                  workPlace: 'OFFICE',
                  packageGroupID: '',
                  holidayID: '',
                  holidayLink: 'DEFAULT',
                  //Tab3
                  salaryOptional: 'STABLE',
                  socialSecurityCalculate: 'BASE_ON_SALARY',
                  socialSecurity: 'PERSONAL_IDCARD',
                  hospitalService: '',
                  personalIDNumber: '',
                  diligentAllowance: false,
                  salary: 0,
                  incomeExpense: [
                    { incomeExpenseType: 'INCOME', name: 'TRAVEL', total: 0, unit: 'BAHT_PER_MONTH' },
                    { incomeExpenseType: 'INCOME', name: 'TRAVEL', total: 0, unit: 'BAHT_PER_MONTH' },
                    { incomeExpenseType: 'INCOME', name: 'TRAVEL', total: 0, unit: 'BAHT_PER_MONTH' },
                    { incomeExpenseType: 'INCOME', name: 'TRAVEL', total: 0, unit: 'BAHT_PER_MONTH' },
                    { incomeExpenseType: 'INCOME', name: 'TRAVEL', total: 0, unit: 'BAHT_PER_MONTH' },
                  ],
                  isTimeAttendance: false,

                  employeeFund: [
                    {
                      name: '',
                      fundNumber: 0,
                      contractDate: null,
                      deductMethod: null,
                      fundRate: 0,
                      contributionMethod: null,
                      contributionCompany: '1',
                      contributionTotal: null,
                      beneficiary: '',
                      remark: '',
                    },
                    // {
                    //   name: '',
                    //   fundNumber: 0,
                    //   contractDate: '',
                    //   deductMethod: '',
                    //   fundRate: 0,
                    //   contributionMethod: '',
                    //   contributionCompany: '',
                    //   contributionTotal: '',
                    //   beneficiary: '',
                    //   remark: '',
                    // },
                    // {
                    //   name: '',
                    //   fundNumber: 0,
                    //   contractDate: '',
                    //   deductMethod: '',
                    //   fundRate: 0,
                    //   contributionMethod: '',
                    //   contributionCompany: '',
                    //   contributionTotal: '',
                    //   beneficiary: '',
                    //   remark: '',
                    // },
                    // {
                    //   name: '',
                    //   fundNumber: 0,
                    //   contractDate: '',
                    //   deductMethod: '',
                    //   fundRate: 0,
                    //   contributionMethod: '',
                    //   contributionCompany: '',
                    //   contributionTotal: '',
                    //   beneficiary: '',
                    //   remark: '',
                    // },
                  ],
                  taxDeductOne: [{ year: '', month: '', total: 0 }],
                  employeeHandicappedAndAgeOut: '',
                  taxDeductTwo: [{ name: '', rateDeduct: 0, totalDeduct: 0 }],

                  //Tab4
                  paymentMethod: 'BANK',
                  bankPayment: null,
                  bankAccountName: null,
                  bankAccountId: '',

                  //Tab5
                  profileImage: '',
                  username: '',
                  password: '',

                  // tab6

                  historyFamily: [{ fullName: '', age: 0, job: '', relation: '' }],
                  historyEducation: [
                    { educationLevel: '', educationName: '', majorName: '', startedYear: '', endedYear: '' },
                    //Tab7
                  ],

                  historyWork: [
                    {
                      workName: '',
                      startedAt: null,
                      endedAt: null,
                      positionName: '',
                      descPosition: '',
                      salary: 0,
                      leaveReason: '',
                    },
                  ],
                  personalTalent: [],
                  personalIdAttachment: '',
                  personalHouseAttachment: '',
                  personalEducationalAttachment: '',
                  personalTrainningAttachment: '',
                  personalOptionalAttachment: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  // console.log(actions, 'actions')
                  const EmployeeVariables: any = {
                    input: {
                      //Tab1
                      id: values.id,
                      nameTitle: values.nameTitle,
                      fullName: values.fullName,
                      middleName: values.middleName,
                      lastName: values.lastName,
                      personalID: values.personalID,
                      nationality: values.nationality,
                      ethnicity: values.ethnicity,
                      fullNameEnglish: values.fullNameEnglish,
                      middleNameEnglish: values.middleNameEnglish,
                      lastNameEnglish: values.lastNameEnglish,
                      sex: values.sex,
                      personalRelation: values.personalRelation,
                      birthDate: values.birthDate,
                      nickName: values.nickName,
                      addressPersonalID: values.addressPersonalID,
                      addressCurrent: values.addressCurrent,
                      //Tab2
                      // id: values.id,
                      hireDate: values.hireDate,
                      retireDate: values.retireDate,
                      departmentID: values.departmentID,
                      positionID: values.positionID,
                      employeeWorkStatus: values.employeeWorkStatus,
                      employeeType: values.employeeType,
                      setShiftWorking: values.setShiftWorking,
                      workPlace: values.workPlace,
                      shiftWorkingID: values.shiftWorkingID,
                      holidayID: '',
                      holidayLink: values.holidayLink,
                      isTimeAttendance: values.isTimeAttendance,
                      //Tab3
                      salaryOptional: values.salaryOptional,
                      socialSecurityCalculate: values.socialSecurityCalculate,
                      socialSecurity: values.socialSecurity,
                      salary: values.salary,
                      hospitalService: values.hospitalService,
                      personalIDNumber: values.socialSecurityCalculate,
                      incomeExpense: values.incomeExpense,
                      employeeFund: values.employeeFund,
                      taxDeductOne: values.taxDeductOne,
                      employeeHandicappedAndAgeOut: values.employeeHandicappedAndAgeOut,
                      taxDeductTwo: values.taxDeductTwo,
                      diligentAllowance: values.diligentAllowance,

                      //Tab4
                      paymentMethod: values.paymentMethod,
                      bankPayment: values.bankPayment,
                      bankAccountName: values.bankAccountName,
                      bankAccountId: values.bankAccountId,
                      //Tab5
                      profileImage: values.profileImage,
                      username: values.username,
                      password: values.password,
                      //Tab6
                      historyFamily: values.historyFamily,
                      historyEducation: values.historyEducation,
                      historyWork: values.historyWork,
                      personalTalent: listTalent,
                      salaryUOM: 'MONTH',
                      employeePeriod: 'FULL',
                      // personalIdAttachment: values.personalIdAttachment,
                      status: 'ACTIVE',
                      //Tab7
                      personalIdAttachment: values.personalIdAttachment,
                      personalHouseAttachment: values.personalHouseAttachment,
                      personalEducationalAttachment: values.personalEducationalAttachment,
                      personalTrainningAttachment: values.personalTrainningAttachment,
                      personalOptionalAttachment: values.personalOptionalAttachment,
                    },
                  }

                  // if (!values.fullName) {
                  //   console.log('ERROR TAB1')
                  // }
                  if (mode === 'create') {
                    dispatch(
                      allAction.modalConfirmAction.setOpenModalConfirm(
                        `ต้องการเพิ่มข้อมูลพนักงาน`,
                        `${values.fullName}  ${values.lastName}`,
                        ``,
                        'ใช่,เพิ่มข้อมูล',
                        () => {
                          CreateEmployeeProfile(EmployeeVariables).then((res: any) => {
                            console.log(res, 'CREATE')
                          })
                          dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                          dispatch(
                            allAction.modalAction.setOpenModalAlertStatus('เพิ่มข้อมูลพนักงานเรียบร้อย', '', 'success'),
                          )
                          navigate('../employee/employee')
                        },
                      ),
                    )
                  } else {
                    dispatch(
                      allAction.modalConfirmAction.setOpenModalConfirm(
                        `แก้ไขข้อมูลพนักงาน`,
                        `${values.fullName}  ${values.lastName}`,
                        ``,
                        'ใช่,แก้ไขข้อมูล',
                        () => {
                          UpdateEmployeeProfile(EmployeeVariables).then((res: any) => {
                            console.log(res, 'Update')
                          })
                          dispatch(allAction.modalConfirmAction.setCloseModalConfirm())

                          dispatch(
                            allAction.modalAction.setOpenModalAlertStatus('แก้ไขข้อมูลพนักงานเรียบร้อย', '', 'success'),
                          )
                          navigate('../employee/employee')
                        },
                      ),
                    )
                  }

                  console.log(EmployeeVariables, 'Input')

                  // setTimeout(() => {
                  //   alert(JSON.stringify(values, null, 2))
                  //   actions.setSubmitting(false)
                  // }, 1000)
                }}
              >
                {(props: any) => (
                  <form onSubmit={props.handleSubmit} className={styles.GroupArea}>
                    <section className={styles.SideTab}>
                      <div className={styles.GroupTitle}>
                        {/* {props.errors.fullName || props.errors.lastName || props.errors.birthDate ? (
                          <p>ข้อมูลพื้นฐานไม่ครบถ้วน</p>
                        ) : props.errors.hireDate ||
                          props.errors.employeeType ||
                          props.errors.departmentID ||
                          props.errors.positionID ? (
                          <p>ข้อมูลค่าตอบแทนไม่ครบถ้วน</p>
                        ) : props.errors.username || props.errors.password ? (
                          <p>ข้อมูลการตั้งค่าผู้ใช้งานไม่ครบถ้วน</p>
                        ) : (
                          ''
                        )} */}
                        {TabData.map((item: any, index: number) => (
                          <div
                            className={tab !== item.name ? styles.Title : styles.TitleSelect}
                            key={index}
                            onClick={() => setTab(item.name)}
                          >
                            <div className={styles.GroupText}>
                              <p>
                                {index + 1}.{item.title}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className={styles.line}></div>
                    </section>
                    <section className={styles.DataArea}>
                      <div className={styles.GroupFiled}>
                        {tab === 'personal' ? (
                          <PersonalTab values={props} loading={loading} mode={mode} />
                        ) : tab === 'employment' ? (
                          <Employment values={props} />
                        ) : tab === 'compensation' ? (
                          <Compensation values={props} />
                        ) : tab === 'payment' ? (
                          <Payment values={props} />
                        ) : tab === 'settings' ? (
                          <Settings values={props} mode={mode} />
                        ) : tab === 'background' ? (
                          <Background values={props} listTalent={listTalent} setListTalent={setListTalent} />
                        ) : tab === 'document' ? (
                          <Document values={props} />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className={styles.ButtonFooter}>
                        <div className={styles.ButtonForm}>
                          <div className={styles.button}>
                            <ButtonComponent
                              _colorBG="white"
                              _colorText="red"
                              _text="ยกเลิก"
                              _type="button"
                              _variant="contained"
                              _functionOnClick={() => navigate(-1)}
                            />
                          </div>
                          <div className={styles.button}>
                            <ButtonComponent
                              _colorBG="blue"
                              _colorText="white"
                              _text="บันทึก"
                              _type="submit"
                              _variant="contained"
                              // _functionOnClick={() => console.log(props.handleSubmit, 'click')}
                            />
                          </div>
                        </div>
                      </div>
                    </section>

                    {/* <div></div> */}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default MenuEmployeeEdit
