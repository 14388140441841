import * as React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import styles from './index.module.scss'

export default function TabsWork() {
  const [value, setValue] = React.useState('1')
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }

  const leaveData = [
    {
      no: '1',
      leave: 'ลาป่วย',
      count: 10,
      spentday: 12,
      hour: 0,
    },
    {
      no: '2',
      leave: 'ลากิจ',
      count: 10,
      spentday: 9,
      hour: 0,
    },
    {
      no: '3',
      leave: 'ลาพักร้อน',
      count: 7,
      spentday: 3,
      hour: 0,
    },
    {
      no: '4 ',
      leave: 'ลากิจไม่หักเงิน',
      count: 10,
      spentday: 3,
      hour: 0,
    },
    {
      no: '5',
      leave: 'ลาพักร้อน',
      count: 7,
      spentday: 3,
      hour: 0,
    },
    {
      no: '6',
      leave: 'ลากิจไม่หักเงิน',
      count: 10,
      spentday: 3,
      hour: 0,
    },
  ]

  const late = [{ no: '1', minute: 32 }]

  const OT = [{ no: '1', hour: '10:30' }]

  const hourWork = [{ no: '1', hour: '40' }]

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList variant="scrollable" onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="วันลา" value="1" sx={{ fontFamily: 'KanitRegular' }} />
            <Tab label="มาสาย" value="2" sx={{ fontFamily: 'KanitRegular' }} />
            <Tab label="การทำงานล่วงเวลา " value="3" sx={{ fontFamily: 'KanitRegular' }} />
            <Tab label="การเข้า/ออกงาน " value="4" sx={{ fontFamily: 'KanitRegular' }} />
          </TabList>
        </Box>
        <TabPanel value="1">
          <div className={styles.cardBox}>
            {' '}
            <div className={styles.content}>
              {leaveData.map((data) => (
                <div className={styles.card} key={data.no}>
                  <div className={styles.textgroup}>
                    <span className={styles.title}>
                      {data.leave} <span className={styles.numtext}>{data.count}</span> วัน
                    </span>
                  </div>

                  <div className={styles.bottom}>
                    <div className={styles.textgroup}>
                      <span className={styles.titleSpent}>
                        ใช้ไป {data.spentday} วัน {data.hour} ชม.
                      </span>
                    </div>

                    <div className={styles.textgroup}>
                      <span className={styles.titleremain}>
                        คงเหลือ {data.spentday} วัน {data.hour} ชม.
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div className={styles.cardLate}>
            {' '}
            <div className={styles.content}>
              {late.map((data) => (
                <div className={styles.card} key={data.no}>
                  <div className={styles.lategroup}>
                    <p className={styles.title}>มาสายรวม</p>
                    <div className={styles.lateText}>
                      <span className={styles.numtext}>
                        <span className={styles.text}>{data.minute}</span> /120
                      </span>
                    </div>
                    <p className={styles.subtext}>นาที</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
        <TabPanel value="3">
          <div className={styles.cardLate}>
            {' '}
            <div className={styles.content}>
              {OT.map((data) => (
                <div className={styles.card} key={data.no}>
                  <div className={styles.lategroup}>
                    <p className={styles.title}>ทำงานล่วงเวลารวม</p>
                    <div className={styles.lateText}>
                      <span className={styles.numtext}>
                        <span className={styles.text}>{data.hour}</span>
                      </span>
                    </div>
                    <p className={styles.subtext}>ชั่วโมง</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>

        <TabPanel value="4">
          <div className={styles.cardLate}>
            {' '}
            <div className={styles.content}>
              {hourWork.map((data) => (
                <div className={styles.card} key={data.no}>
                  <div className={styles.lategroup}>
                    <p className={styles.title}>ทำงานรวมทั้งหมด</p>
                    <div className={styles.lateText}>
                      <span className={styles.numtext}>
                        <span className={styles.text}>{data.hour}</span>
                      </span>
                    </div>
                    <p className={styles.subtext}>ชั่วโมง</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
