import React from 'react'
import styles from './index.module.scss'
import closeIcon from '../../image/close.svg'
import { Modal, Backdrop, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material'

export const ModalHistory: React.FC<any> = (props: any): JSX.Element => {
  const Data = [
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
    { date: '2022-05-26', nameEdit: 'Thanyarat Samaikhrip', EditBy: 'Jackson', Detail: 'สร้างรายการ' },
  ]
  return (
    <Modal
      open={props.modalHistory}
      onClose={() => props.setModalHistory(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalHistory}>
        <div className={styles.card}>
          <div className={styles.head}>
            <p className={styles.textHead}>ประวัติการแก้ไข</p>
            <img src={closeIcon} alt="" onClick={() => props.setModalHistory(false)} className={styles.Closeicon} />
          </div>
          <div className={styles.line}></div>
          <div className={styles.tablehistory}>
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: ' #3A77DA' }}>
                <TableRow>
                  <TableCell align="center">
                    <p className={styles.textHead}>วันที่แก้ไข</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className={styles.textHead}>แก้ไขของ</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className={styles.textHead}>แก้ไขโดย</p>
                  </TableCell>
                  <TableCell align="center">
                    {' '}
                    <p className={styles.textHead}>แก้ไขรายการ</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Data.map((data: any, index: any) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': {} }}>
                    <TableCell align="center">
                      <p>{data.date}</p>
                    </TableCell>
                    <TableCell align="center">
                      <p>{data.nameEdit}</p>
                    </TableCell>
                    <TableCell align="center">
                      <p>{data.EditBy}</p>
                    </TableCell>
                    <TableCell align="center">
                      <p>{data.Detail}</p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  )
}
