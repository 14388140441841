import React, { createContext, FC, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Calendar, DateHeaderProps, EventProps, momentLocalizer, NavigateAction, View } from 'react-big-calendar'
import moment from 'moment'
import {
  calendarTypeColor,
  classes,
  dateToTime,
  deepClone,
  ECalendarEventType,
  ECalendarType,
  getCalendarThaiType,
  getThaiDate,
  toTime,
} from '../../../utils/common'
import {
  Button,
  Grid,
  MenuItem,
  Popper,
  Select,
  Stack,
  Modal,
  AvatarGroup,
  Avatar,
  Tooltip,
  Divider,
  Badge,
  Checkbox,
} from '@mui/material'
import styles from './index.module.scss'
import Switch from '../../../component/Switch'
import CloseIcon from '../../../image/closeGray.svg'
import DelIcon from '../../../image/delBadege.svg'
import NextIcon from '../../../image/nex.svg'
// import { Box } from '@mui/system'
import { ButtonComponent, ButtonSelectCustom } from '../../../component'
import { ELeave, ELeavForm, SaveType } from '../../AttendanceReport/EType'
import TimeIcon from '../../../image/clock.svg'
// import CalendarIcon from '../../../image/calendarIcon.svg'
import {
  InputDateComponentCustome,
  InputSelectComponentCustom,
  InputTextComponentCustom,
  InputTimeComponentCustom,
} from '../../../component/InputsFormik'
import { Formik, useFormikContext } from 'formik'
import AdddUserIcon from '../../../image/addEmp.svg'
import {
  CreateLeaveDocument,
  CreateOvertimeDocument,
  DelRequestLeave,
  DelRequestOvertime,
  QueryEmployeeList,
  UpdateAttendanceReport,
} from '../../../adapter/xhr'
import { useDispatch } from 'react-redux'
import allAction from '../../../state/actions'
import { filterEmployeeActive } from '../../Menu_Employee'

const localizer = momentLocalizer(moment)
type DateClickHandler = (date: Date, anchorEl: null | HTMLElement) => void

const Context = createContext<{ events?: CalendarEvent[]; onDateClick?: DateClickHandler }>({})

interface Props {
  events: CalendarEvent[]
  eventData: any[]
  onNavigate?: (newDate: Date, view: View, action: NavigateAction) => void
}
interface CalendarEvent {
  title: string
  start: Date
  end: Date
  type: ECalendarEventType
  date: string
  isHoliday: boolean
  holidayDescription: string
}
const CalendarEventComponent = (props: EventProps<CalendarEvent>) => {
  return <div>{props.event.title}</div>
}

const CalendarDateComponent = (props: DateHeaderProps) => {
  const context = useContext(Context)
  const currentDate = new Date()
  const isCurrentDate = props.date.toLocaleDateString() === currentDate.toLocaleDateString()
  const holiday = context?.events?.find(
    (e) => new Date(e.date).toLocaleDateString() === props.date.toLocaleDateString(),
  )

  const getDateLabel = useMemo(() => {
    return Number(props.label)
  }, [props.label])

  // console.log(props.date.toLocaleDateString(), currentDate.toLocaleDateString())
  return (
    <div>
      <style lang="scss">{`
        .circleDate {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          padding: 10px;
          border-radius: 50%;
          cursor: pointer;
          margin: 0.5rem;
          font-size: 21px;
        }

        .currentDate {
          background-color: rgba(247, 175, 29, 1) !important;
          color: #fff;
        }

        .holidayDate {
          background-color: rgba(83, 87, 187, 1);
          color: #fff;
          border: 4px solid rgba(83, 87, 187, 1);
        }
      `}</style>
      <Tooltip title={holiday?.holidayDescription || ''} placement="top" arrow>
        <div
          className={classes('circleDate', isCurrentDate && 'currentDate', holiday?.isHoliday && 'holidayDate')}
          onClick={(e) => {
            context.onDateClick?.call(null, props.date, e.currentTarget)
          }}
        >
          {getDateLabel}
        </div>
      </Tooltip>
    </div>
  )
}

export interface CalendarRef {
  handleNextMonth: () => Date
  handlePreviousMonth: () => Date
  handleToday: () => Date
}

const BigCalendar = (props: Props, ref: any) => {
  const currentDate = new Date()
  const [calendarDate, setCalendarDate] = useState<Date>(currentDate)

  const [showPopepr, setShowPopper] = useState<boolean>(false)
  const [openPopperDate, setOpenPopperDate] = useState<Date>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<{ events: any[]; date: Date }>({ events: [], date: calendarDate })

  const onDateClick: DateClickHandler = (date, anchorEl) => {
    const montCalendar = calendarDate.getMonth()
    const monthCurrent = new Date(date).getMonth()
    if (monthCurrent !== montCalendar) return
    else {
      setAnchorEl(anchorEl)
      setShowPopper(openPopperDate?.toLocaleDateString() !== date.toLocaleDateString() || !showPopepr)
      const _date = new Date(date)
      _date.setHours(7)
      setOpenPopperDate(_date)
    }
  }

  const handleChangeMonth = (month: number) => {
    const newDate = new Date(calendarDate.setMonth(month))

    return newDate
  }
  useImperativeHandle(ref, () => ({
    handleNextMonth: () => {
      // console.log('handleNextMonth')

      const newDate = handleChangeMonth(calendarDate.getMonth() + 1)
      setCalendarDate(newDate)

      return newDate
    },
    handlePreviousMonth: () => {
      // console.log('handlePreviousMonth')
      const newDate = handleChangeMonth(calendarDate.getMonth() - 1)
      setCalendarDate(newDate)

      return newDate
    },
    handleToday: () => {
      // console.log('handleToday')
      setCalendarDate(currentDate)
      return currentDate
    },
  }))

  const events = useMemo(() => {
    return props.events
  }, [props.events])

  const getEventType = (type: ECalendarEventType): ECalendarType => {
    const leaveReasonList = [
      'REASON_SICK',
      'REASON_PERSONAL_LEAVE',
      'REASON_PERSONAL_LEAVE_WITHOUT_DEDUCT_MONEY',
      'REASON_ANNUAL_LEAVE',
    ]
    if (leaveReasonList.includes(type)) return 'LEAVE'
    else return type as ECalendarType
  }

  const handleOpenModal = (event: CalendarEvent) => {
    setModalData({
      date: new Date(event.date),
      events: props.eventData
        .find((e) => e.date === event.date)
        .events.map((e: any) => {
          if (e.Type === 'LATE') {
            return {
              title: e.Type,
              type: e.event.leaveType || e.Type,
              date: new Date(event.date),
              start: new Date(`${event.date}T${e.event.shiftStartTime}+0700`),
              end: new Date(e.event.scanIn),
              employee: e,
            }
          } else
            return {
              title: e.Type,
              type: e.event.leaveType || e.Type,
              date: new Date(event.date),
              start: new Date(e.event.startedAt),
              end: new Date(e.event.endedAt),
              employee: e,
            }
        }),
    })
    setShowModal(true)
  }

  return (
    <Context.Provider value={{ events, onDateClick }}>
      <Calendar
        style={{
          height: '90vh',
        }}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['month']}
        toolbar={false}
        // onNavigate={props.onNavigate}
        components={{
          event: CalendarEventComponent,
          month: { dateHeader: CalendarDateComponent },
        }}
        // toolbar={false}
        dayPropGetter={(date) => {
          const style: React.CSSProperties = { backgroundColor: 'rgba(255, 255, 255, 1)' }

          if ([0, 6].includes(date.getDay())) {
            style.backgroundColor = 'rgba(232, 232, 232, 1)'
          }

          return {
            style,
          }
        }}
        // date={calendarDate}
        // getNow={() => new Date()}
        date={calendarDate}
        onSelectEvent={handleOpenModal}
        eventPropGetter={(event) => {
          const style: React.HTMLAttributes<HTMLDivElement>['style'] = {
            width: 'min-content',
            fontSize: '12px',
          }
          switch (getEventType(event.type)) {
            case 'LEAVE':
              style.backgroundColor = 'rgba(255, 150, 27, 0.1)'
              style.color = 'rgba(255, 159, 45, 1)'
              break
            case 'OVERTIME':
              style.backgroundColor = 'rgba(232, 232, 232, 1)'
              style.color = 'rgba(70, 69, 69, 1)'
              break
            case 'ABSENT':
              style.backgroundColor = 'rgba(224, 19, 34, 0.15)'
              style.color = 'rgba(224, 19, 34, 1)'
              break
            case 'LATE':
              style.backgroundColor = 'rgba(12, 108, 255, 0.15)'
              style.color = 'rrgba(12, 108, 255, 1)'
              break
          }
          return {
            style,
          }
        }}
      />
      <div className={styles.GroupPopper}>
        <Popper open={showPopepr} anchorEl={anchorEl} placement="top-start" className={styles.calendarPopper}>
          <div className={styles.group}>
            <CalendarPopper
              calendarDate={calendarDate}
              popperDate={openPopperDate}
              showPopepr={showPopepr}
              setShowPopper={setShowPopper}
            />
            <div className={styles.poin}></div>
          </div>
        </Popper>
      </div>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <CalendarModal {...modalData} open={showModal} setShowModal={setShowModal} />
      </Modal>
    </Context.Provider>
  )
}

//Modal Onclick digit date

const CalendarPopper = (props: any) => {
  const dispatch = useDispatch()
  const [userList, setUserlist] = useState<any>([])
  useEffect(() => {
    console.log(userList, 'userList')
  }, [userList])
  const [selectType, setSelectType] = useState<'Leave' | 'Overtime'>()
  const [popEmployee, setPopEmployee] = useState(false)
  const [anchorPop, setAnchorPop] = useState<HTMLElement | null>()
  const [status, setStatus] = React.useState('APPROVE')
  const [typeleave, setTypeLeave] = React.useState('')
  const [employee, setEmployeeList] = React.useState<any>([])

  useEffect(() => {
    QueryEmployeeList(filterEmployeeActive).then((res: any) => {
      setEmployeeList(res.data.data.listEmployee)
    })
  }, [])

  useEffect(() => {
    console.log(typeleave, 'typeleave')
  }, [typeleave])

  const LeaveList = [
    { label: 'ลาป่วย', value: 'REASON_SICK' },
    { label: 'ลาพักร้อน', value: 'REASON_ANNUAL_LEAVE' },
    { label: 'ลากิจ', value: 'REASON_PERSONAL_LEAVE' },
    {
      label: 'ขาด',
      value: 'ABSENT',
    },
  ]
  const LeavFormList = [
    {
      label: 'กำหนดเอง',
      value: 'CUSTOM_TIME',
    },
    {
      label: 'ลาเต็มวัน',
      value: 'FULLDAY_LEAVE',
    },
    {
      label: 'ลาครึ่งวันเช้า',
      value: 'HALFDAY_MORNING_LEAVE',
    },
    {
      label: 'ลาครึ่งวันบ่าย',
      value: 'HALFDAY_AFTERNOON_LEAVE',
    },
  ]

  const EType: any = {
    Leave: 'การลา',
    Overtime: 'การทำงานล่วงเวลา',
  }
  const OTList = [
    { label: 'วันทำงาน', value: 'WORK' },
    { label: 'ทำงานล่วงเวลา', value: 'OVERTIME' },
  ]
  const statusType = (e: any) => {
    setStatus(e)
  }

  const handleClick = (event: any) => {
    console.log(event.currentTarget, 'event.currentTarget')
    setAnchorPop(event.currentTarget)
    setPopEmployee((previousOpen) => !previousOpen)
  }
  const otherprops = props

  return (
    <div className={styles.PopperDocument}>
      <Formik
        initialValues={{
          leaveType: '',
          startedAt: '',
          endedAt: '',
          leaveDocumentAttachment: '',
          isLeaveDeductMoney: false,
          status: '',
          leaveForm: '',
          type: 'Leave',
          overtimeType: '',
          search: '',
        }}
        onSubmit={(values, actions) => {
          console.log(userList.length, 'employee')
          if (userList.length === 0) {
            dispatch(
              allAction.modalAction.setOpenModalAlertStatus(
                'กรุณาเลือกข้อมูลพนักงาน',
                'เลือกพนักงานที่ต้องการทำรายการ',
                'info',
              ),
            )
          } else {
            dispatch(
              allAction.modalConfirmAction.setOpenModalConfirm(
                `บันทึกข้อมูล ${EType[`${values.type}`]}`,
                `วันที่ ${values.startedAt} - ${values.endedAt}`,
                `จำนวนพนักงาน ${userList.length} คน`,
                'ใช่,บันทึก',
                () => {
                  if (values.type === 'Leave') {
                    const LeaveInput: any = {
                      input: {
                        date: new Date(props.popperDate).toISOString().slice(0, 10),
                        employeeID: '',
                        leaveType: values.leaveType,
                        leaveForm: values.leaveForm,
                        startedAt: values.startedAt,
                        endedAt: values.endedAt,
                        isLeaveDeductMoney: typeleave === 'leave' ? false : true,
                        status: status,
                      },
                    }
                    // console.log(LeaveInput, 'LeaveInput')

                    for (let i = 0; i < userList.length; i++) {
                      const Leavelist: any[] = []
                      const LeaveDeduct: any[] = []
                      const params: any = deepClone(LeaveInput)
                      params.input.employeeID = userList[i].id
                      CreateLeaveDocument(params).then((res: any) => {
                        // console.log(res, 'Leavelist')
                        const data = res.data.data.createLeaveRequest

                        if (data.isLeaveDeductMoney === false) {
                          Leavelist.push({
                            leaveType: data.leaveType,
                            startedAt: new Date(data.startedAt).toISOString(),
                            endedAt: new Date(data.endedAt).toISOString(),
                            status: data.status,
                            leaveForm: data.leaveForm,
                            leaveRequestID: data.id,
                          })
                        } else {
                          LeaveDeduct.push({
                            leaveType: data.leaveType,
                            startedAt: new Date(data.startedAt).toISOString(),
                            endedAt: new Date(data.endedAt).toISOString(),
                            status: data.status,
                            leaveForm: data.leaveForm,
                            leaveRequestID: data.id,
                          })
                        }

                        const InputReport = {
                          input: {
                            employeeID: userList[i].id,
                            date: new Date(props.popperDate).toISOString().slice(0, 10),
                            leaveDeductList: LeaveDeduct,
                            leaveList: Leavelist,
                          },
                        }
                        UpdateAttendanceReport(InputReport).then((res: any) => {
                          console.log(res)
                        })
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus('บันทึกข้อมูลการลาเรียบร้อย', '', 'success'),
                        )
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        otherprops.setShowPopper(false)
                      })

                      // 1. const doc = await mutation to main doc (params)
                      // 2. if (save and approve) then updateReport(doc.id)
                    }
                  } else {
                    const OvertimeInput: any = {
                      input: {
                        date: new Date(props.popperDate).toISOString().slice(0, 10),
                        employeeID: '',
                        startedAt: values.startedAt,
                        endedAt: values.endedAt,
                        status: status,
                        overtimeType: values.overtimeType,
                      },
                    }

                    for (let i = 0; i < userList.length; i++) {
                      const params: any = deepClone(OvertimeInput)
                      params.input.employeeID = userList[i].id

                      const OvertimeList: any[] = []

                      CreateOvertimeDocument(params).then((res: any) => {
                        const data = res.data.data.createOvertimeRequest

                        // console.log(res, 'RESSSSS')
                        OvertimeList.push({
                          startedAt: new Date(data.startedAt).toISOString(),
                          endedAt: new Date(data.endedAt).toISOString(),
                          status: data.status,
                          overtimeType: data.overtimeType,
                          overtimeRequestID: data.id,
                        })
                        const OvertimeInput: any = {
                          input: {
                            employeeID: userList[i].id,
                            date: new Date(props.popperDate).toISOString().slice(0, 10),
                            overtimeApproveList: OvertimeList,
                          },
                        }

                        UpdateAttendanceReport(OvertimeInput).then((res: any) => {
                          console.log('Update OK', res)
                        })
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus(
                            'บันทึกข้อมูลการทำงานล่วงเวลาเรียบร้อย',
                            '',
                            'success',
                          ),
                        )
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        otherprops.setShowPopper(false)
                      })

                      // 1. const doc = await mutation to main doc (params)
                      // 2. if (save and approve) then updateReport(doc.id)
                    }
                    console.log('OverTime')
                    console.log(values, 'values')
                  }
                },
              ),
            )
          }
        }}
      >
        {(props: any) => (
          <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
            <section className={styles.Header}>
              <div className={styles.button}>
                <ButtonComponent
                  _colorBG="red"
                  _colorText="white"
                  _text="ยกเลิก"
                  _type="button"
                  _variant="contained"
                  _functionOnClick={() => {
                    otherprops.setShowPopper(false)
                    // console.log('Close Popper')
                  }}
                />
              </div>
              <p>สร้างใหม่</p>
              <ButtonSelectCustom
                _text={'Save'}
                _selectData={SaveType}
                _onchange={(e: any) => {
                  statusType(e)
                }}
              />
            </section>
            <section className={styles.filedInputFlex}>
              <p>สร้าง</p>
              <InputSelectComponentCustom
                _label="สร้างรายการ"
                _name="type"
                _value={props.values.type}
                _menuList={[
                  { label: 'การลา', value: 'Leave' },
                  { label: 'การทำงานล่วงเวลา', value: 'Overtime' },
                ]}
              />
            </section>

            {props.values.type === 'Leave' ? (
              <div className={styles.FormGroup}>
                <div className={styles.filedInputFlex}>
                  <p>ประเภทการลา</p>
                  <div className={styles.inputBox}>
                    <InputSelectComponentCustom
                      _name="leaveType"
                      _label={'ประเภทการลา'}
                      _menuList={LeaveList}
                      _helperText={props.errors.leaveType}
                    />
                  </div>
                </div>

                <div className={styles.toggle}>
                  <div className={styles.button}>
                    <Switch
                      options={[
                        { label: 'ลาไม่หักเงิน', value: 'leave' },
                        { label: 'ลาหักเงิน', value: 'leaveDeduct' },
                      ]}
                      onChange={(value: any) => {
                        setTypeLeave(value)
                      }}
                    />
                  </div>
                </div>
                <div className={styles.filedInputFlex}>
                  <p>รูปแบบการลา</p>
                  <div className={styles.inputBox}>
                    <InputSelectComponentCustom
                      _name={`leaveForm`}
                      _label={'รูปแบบการลา'}
                      _placeholder="เลือกรูปแบบการลา"
                      _menuList={LeavFormList}
                    />
                  </div>
                </div>

                <div className={styles.filedInputTime}>
                  <p>ช่วงเวลา</p>
                  <div className={styles.GroupBox}>
                    <div className={styles.InputTime}>
                      <InputTimeComponentCustom
                        _name="startedAt"
                        _label={'เริ่มต้น'}
                        _helperText={props.errors.startedAt}
                      />
                    </div>
                    <div className={styles.InputTime}>
                      <InputTimeComponentCustom _name="endedAt" _label={'สิ้นสุด'} _helperText={props.errors.endedAt} />
                    </div>
                  </div>
                </div>

                {/* <div className={styles.filedInputFlex}>
                    <p>แนบไฟล์</p>
                    <div className={styles.inputUpload}>
                      <div className={styles.UploadBt}>
                        <UploadButton _name="leaveDocumentAttachment" />
                      </div>
                    </div>
                  </div> */}
                <div className={styles.filedInputFlex}>
                  <p>วันที่ </p>
                  <div className={styles.inputBox}>
                    <InputDateComponentCustome
                      _name={'date'}
                      _value={new Date(otherprops.popperDate).toISOString().slice(0, 10)}
                      _disabled={true}
                      _label={'วันที่'}
                    />
                  </div>
                </div>

                <div className={styles.filedInputFlex}>
                  <p>พนักงาน</p>
                  <div className={styles.Group}>
                    <AvatarGroup className={styles.allAvata} max={3}>
                      {userList.map((item: any, index: number) => {
                        return <Avatar key={index} alt={item.fullName} src={item.fullName} />
                      })}
                    </AvatarGroup>
                    <img src={AdddUserIcon} alt="" style={{ cursor: 'pointer' }} onClick={(e: any) => handleClick(e)} />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.FormGroup}>
                <div className={styles.filedInputFlex}>
                  <p>รูปแบบโอที</p>
                  <div className={styles.inputBox}>
                    <InputSelectComponentCustom
                      _name="overtimeType"
                      _label={'รูปแบบการทำงานล่วงเวลา'}
                      _placeholder="เลือกรูปแบบการทำงานล่วงเวลา"
                      _menuList={OTList}
                    />
                  </div>
                </div>

                <div className={styles.filedInputTime}>
                  <p>ช่วงเวลา</p>
                  <div className={styles.GroupBox}>
                    <div className={styles.InputTime}>
                      <InputTimeComponentCustom
                        _name="startedAt"
                        _label={'เริ่มต้น'}
                        _helperText={props.errors.startedAt}
                      />
                    </div>
                    <div className={styles.InputTime}>
                      <InputTimeComponentCustom _name="endedAt" _label={'สิ้นสุด'} _helperText={props.errors.endedAt} />
                    </div>
                  </div>
                </div>

                <div className={styles.filedInputFlex}>
                  <p>พนักงาน</p>
                  <div className={styles.Group}>
                    <AvatarGroup className={styles.allAvata} max={3}>
                      {userList.map((item: any, index: number) => {
                        return <Avatar key={index} alt={item.fullName} src={item.fullName} />
                      })}
                    </AvatarGroup>
                    <img src={AdddUserIcon} alt="" onClick={(e: any) => handleClick(e)} />
                  </div>
                </div>
              </div>
            )}
            <Popper open={popEmployee} anchorEl={anchorPop} placement="top" style={{ zIndex: '999999' }}>
              <PopperEmployeeList
                employee={employee}
                userList={userList}
                setUserlist={setUserlist}
                setPopEmployee={setPopEmployee}
                popEmployee={popEmployee}
              />
            </Popper>
          </form>
        )}
      </Formik>
    </div>
  )
}

const PopperEmployeeList = (props: any) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>()
  const handelSelect = (e: any, id: any, item: any) => {
    const { value, checked } = e.target
    props.setUserlist([...props.userList, item])
    if (!checked) {
      props.setUserlist(props.userList.filter((item: any) => item.id !== id))
    }
  }

  return (
    <div className={styles.PopperEmployee}>
      <section className={styles.AreaGroup}>
        <InputTextComponentCustom _name="search" _label="ค้นหาพนักงาน" />
        {console.log(values, 'valuesssssssss')}
      </section>
      <div className={styles.GroupEmp}>
        {props.employee.items
          .filter((item: any) => {
            if (values.search == '') {
              return item
            } else if (
              item.fullName.toLocaleLowerCase().includes(values.search.toLocaleLowerCase()) ||
              item.lastName.toLocaleLowerCase().includes(values.search.toLocaleLowerCase())
            ) {
              return item
            }
          })
          .map((itemUser: any, UserIndex: number) => (
            <section className={styles.EmpList} key={UserIndex}>
              <div className={styles.Form}>
                <Avatar src={itemUser.fullName} />
                <p>
                  {itemUser.fullName} {itemUser.lastName}
                </p>
              </div>
              <Checkbox
                sx={{
                  color: '#5357BB',
                  '&.Mui-checked': {
                    color: '#5357BB',
                  },
                }}
                onChange={(e: any) => handelSelect(e, itemUser.id, itemUser)}
                checked={props.userList.map((item: any) => item.id).includes(itemUser.id)}
              />
            </section>
          ))}
      </div>

      <section className={styles.Footer}>
        <div className={styles.ButtonForm}>
          <ButtonComponent
            _colorBG="gray"
            _text="ยกเลิก"
            _colorText="white"
            _type="button"
            _variant="contained"
            _functionOnClick={() => {
              props.setPopEmployee(false)
              props.setUserlist([])
            }}
          />
          <ButtonComponent
            _colorBG="blue"
            _text="บันทึก"
            _colorText="white"
            _type="button"
            _variant="contained"
            _functionOnClick={() => props.setPopEmployee(false)}
          />
        </div>
      </section>
    </div>
  )
}
interface CalendarModalProps {
  events: any[]
  date: Date
  open?: boolean
  setShowModal?: any
}

const CalendarModal: FC<CalendarModalProps> = (props: CalendarModalProps) => {
  const [Popepr, setPopper] = useState<boolean>(false)
  const [anchorElpop, setAnchorElpop] = useState<HTMLElement | null>()
  const [editeData, setEditData] = useState(false)
  const [dataEvent, setDataEvent] = useState<any>({})

  const dispatch = useDispatch()

  const handleClick = (event: any) => {
    setAnchorElpop(event.currentTarget)
    setPopper((previousOpen) => !previousOpen)
  }
  const eventUnique = useMemo(() => {
    if (!props.events?.length) return []

    const uniqueMap: any = {}

    props.events.forEach((e: any) => {
      const key = `${e.type}|${e.start.toISOString()}|${e.end.toISOString()}`
      if (!uniqueMap[key]) uniqueMap[key] = []
      uniqueMap[key].push(e)
    })

    const _events: any[] = []

    Object.keys(uniqueMap).forEach((key) => {
      const [_type, _start, _end] = key.split('|')
      const { eventTypeThaiName, calendarType } = getCalendarThaiType(_type as any)
      _events.push({
        title: eventTypeThaiName,
        type: calendarType,
        start: new Date(_start),
        end: new Date(_end),
        employeeList: uniqueMap[key].map((e: any) => e.employee),
      })
    })

    return _events
  }, [props.events])

  const emploeeEventList = useMemo(() => {
    if (!eventUnique?.length) return []

    const events = eventUnique.map((e: any) => {
      return {
        title: e.title,
        dateTimeTitle: `${dateToTime(e.start)} - ${dateToTime(e.end)}`,
        employeeList: e.employeeList,
      }
    })

    return events
  }, [eventUnique])

  emploeeEventList

  const sumEmployee = (type: string) => {
    const arr: any[] = []
    const sum = props.events.map((item: any) => {
      console.log(item, 'item')
      if (item.title === type) {
        return arr.push(item)
      }
    })

    return arr.length
  }

  const customerEvent = (event: any) => {
    return (
      <Stack justifyContent="space-between" style={{ height: '100%', paddingBottom: 8 }}>
        <div>{event.event.title}</div>
        <AvatarGroup
          max={4}
          style={{ display: 'flex', justifyContent: 'start' }}
          sx={{
            '.MuiAvatarGroup-avatar': {
              width: 24,
              height: 24,
              fontSize: 12,
            },
          }}
        >
          {event.event.employeeList.map((employee: any) => {
            return (
              <Avatar
                sx={{ width: 24, height: 24 }}
                key={employee.id}
                alt={employee.fullName}
                src={employee.profileImage}
              />
            )
          })}
        </AvatarGroup>
      </Stack>
    )
  }

  return (
    <div className={styles.calendarModalContainer}>
      <Grid container spacing={4} columns={{ xs: 12 }}>
        <Grid item xs={7} style={{ overflow: 'scroll', height: '100%' }}>
          <Calendar
            localizer={localizer}
            eventPropGetter={(event: any) => {
              const style: React.HTMLAttributes<HTMLDivElement>['style'] = {}
              const { main, light } = calendarTypeColor[event.type as ECalendarType]
              style.backgroundColor = light
              style.color = main
              style.border = 'none'
              style.borderLeft = `3px solid ${main}`
              return {
                style,
              }
            }}
            components={{
              event: customerEvent,
            }}
            events={eventUnique}
            startAccessor="start"
            endAccessor="end"
            views={['day']}
            defaultView="day"
            toolbar={false}
            defaultDate={props.date}
          />
        </Grid>

        <Grid item xs={5} className={styles.EvenList}>
          <section className={styles.HeadDate}>
            <div className={styles.GroupText}>
              <p>{getThaiDate(props.date).format('day date month year')}</p>
              <img src={CloseIcon} alt="" onClick={() => props.setShowModal(false)} style={{ cursor: 'pointer' }} />
            </div>
          </section>
          <Divider />
          <Stack spacing={4}>
            {!editeData ? (
              <p className={styles.textEdite} onClick={() => setEditData(!editeData)}>
                แก้ไขรายการ
              </p>
            ) : (
              <p className={styles.textEdite} onClick={() => setEditData(!editeData)}>
                เสร็จสิ้น
              </p>
            )}

            <div className={styles.Group}>
              {emploeeEventList.map((event: any, index: number) => {
                return (
                  <Stack key={index} className={styles.GroupList}>
                    {editeData ? (
                      <div>
                        <p className={styles.title}>{event.title}</p>
                        <p>{event.dateTimeTitle}</p>
                        <div className={styles.ListEdite}>
                          {event.employeeList.map((employee: any, employeeIndex: number) => {
                            return (
                              <div className={styles.GroupEmployee} key={employeeIndex}>
                                <div className={styles.form}>
                                  <div className={styles.Badge}>
                                    {employee.event.status === 'APPROVE' ? (
                                      <div className={styles.pillStatus}>
                                        <p className={styles.text}>{employee.event.status}</p>
                                      </div>
                                    ) : (
                                      <img
                                        src={DelIcon}
                                        alt=""
                                        className={styles.badge}
                                        onClick={() => {
                                          dispatch(
                                            allAction.modalConfirmAction.setOpenModalConfirm(
                                              `ต้องการลบข้อมูลเอกสาร ${employee.Type}`,
                                              `ของ ${employee.employee.fullName} ${employee.employee.lastName}`,
                                              'ไม่สามารถแก้ไขการเปลี่ยนแปลงได้',
                                              'ใช่,ลบข้อมูล',
                                              () => {
                                                dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                                                if (employee.Type === 'OVERTIME') {
                                                  DelRequestOvertime({
                                                    input: { employeeID: employee.employeeID, id: employee.event.id },
                                                  }).then((res: any) => console.log('Del', res))
                                                } else if (employee.Type === 'LEAVE') {
                                                  DelRequestLeave({
                                                    input: { employeeID: employee.employeeID, id: employee.event.id },
                                                  }).then((res: any) => console.log(res, 'DelLeave'))
                                                }
                                                // delRe
                                                console.log(`Close`, employee)
                                              },
                                            ),
                                          )
                                          console.log(`Close`, employee.event.id)
                                        }}
                                      />
                                    )}
                                    <Avatar
                                      alt={employee.fullName}
                                      src={employee.profileImage}
                                      className={employee.event.status === 'APPROVE' ? styles.avatar : ''}
                                    />
                                  </div>
                                  <p className={styles.NameText}>
                                    {employee.employee.fullName} {employee.employee.lastName}
                                  </p>
                                </div>
                                <img
                                  src={NextIcon}
                                  alt=""
                                  className={styles.Icon}
                                  onClick={(e: any) => {
                                    handleClick(e)
                                    setDataEvent(employee)
                                  }}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className={styles.title}>{event.title}</p>
                        <p>{event.dateTimeTitle}</p>
                        <AvatarGroup max={6} style={{ display: 'flex', justifyContent: 'start' }}>
                          {event.employeeList.map((employee: any) => {
                            return <Avatar key={employee.id} alt={employee.fullName} src={employee.profileImage} />
                          })}
                        </AvatarGroup>
                      </div>
                    )}
                  </Stack>
                )
              })}
            </div>
          </Stack>

          <section className={styles.bottomCard}>
            <Divider />
            <div className={styles.GroupData}>
              <p className={styles.txetHead}>ยอดรวมวันนี้</p>
              <div className={styles.GroupCard}>
                <div className={styles.Card}>
                  <p className={styles.title}>{sumEmployee('ABSENT')}</p>
                  <p className={styles.subtitle}>ขาด (คน)</p>
                </div>
                <div className={styles.CardLeave}>
                  <p className={styles.title}>{sumEmployee('LEAVE')}</p>
                  <p className={styles.subtitle}>ลา (คน)</p>
                </div>
                <div className={styles.CardLate}>
                  <p className={styles.title}>0</p>
                  <p className={styles.subtitle}>มาสาย (คน)</p>
                </div>
                <div className={styles.CardOt}>
                  <p className={styles.title}>{sumEmployee('OVERTIME')}</p>
                  <p className={styles.subtitle}>ล่วงเวลา (คน)</p>
                </div>
              </div>
            </div>
          </section>
        </Grid>
        <Popper open={Popepr} anchorEl={anchorElpop} placement="auto" style={{ zIndex: '999999' }}>
          <ShowEventPopper data={dataEvent} />
        </Popper>
      </Grid>
    </div>
  )
}

const ShowEventPopper = (props: any) => {
  return (
    <div className={styles.popper}>
      <section className={styles.GroupData}>
        {props.data.Type === 'OVERTIME' ? (
          <div className={styles.InputData}>
            <section className={styles.Group}>
              <p>สร้าง</p>
              <div className={styles.InputFiled}>
                <p>{props.data.Type}</p>
              </div>
            </section>
            <section className={styles.Group}>
              <p>ช่วงเวลา</p>
              <div className={styles.Grouptime}>
                <div className={styles.InputL}>
                  <p>{toTime(props.data.event.startedAt)}</p>
                </div>
                <div className={styles.InputR}>
                  <p>{toTime(props.data.event.endedAt)}</p>
                  <img src={TimeIcon} alt="" />
                </div>
              </div>
            </section>
            <section className={styles.Group}>
              <p>ช่วงวันที่</p>
              <div className={styles.InputFiled}>
                <p>
                  {new Date(props.data.event.startedAt).toLocaleDateString('th-TH', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                  })}
                </p>
                {/* <img src={CalendarIcon} alt="" /> */}
              </div>
            </section>
            <section className={styles.Group}>
              <p>พนักงาน</p>
              <div className={styles.EmpProfile}>
                <Avatar
                  alt={props.data.employee.fullName}
                  src={props.data.employee.profileImage}
                  className={styles.avatar}
                />
                <p>
                  {props.data.employee.fullName} {props.data.employee.lastName}
                </p>
              </div>
            </section>
          </div>
        ) : (
          <div className={styles.InputData}>
            <section className={styles.Group}>
              <p>สร้าง</p>
              <div className={styles.InputFiled}>
                <p>{props.data.Type}</p>
              </div>
            </section>
            <section className={styles.Group}>
              <p>ประเภทการลา</p>
              <div className={styles.InputFiled}>
                <p>{ELeave[`${props.data.event.leaveType}`]}</p>
              </div>
            </section>
            {props.data.event.isLeaveDeductMoney ? (
              <section className={styles.toggle}>
                {console.log(props.data.event.isLeaveDeductMoney, 'props.data.event.isLeaveDeductMoney ')}
                <div className={styles.button}>
                  <div className={props.data.event.isLeaveDeductMoney === true ? styles.btLeave : styles.bt}>
                    ลาหักเงิน
                  </div>
                  <div className={props.data.event.isLeaveDeductMoney === true ? styles.bt : styles.btLeave}>
                    ลาไม่หักเงิน
                  </div>
                </div>
              </section>
            ) : (
              ''
            )}

            <section className={styles.Group}>
              <p>รูปแบบการลา</p>
              <div className={styles.InputFiled}>
                {props.data.event.leaveForm ? (
                  <p>{ELeavForm[`${props.data.event.leaveForm}`]}</p>
                ) : (
                  <p>{ELeave[`${props.data.event.leaveType}`]}</p>
                )}
              </div>
            </section>
            <section className={styles.Group}>
              <p>ช่วงเวลา</p>
              <div className={styles.Grouptime}>
                <div className={styles.InputL}>
                  <p>{toTime(props.data.event.startedAt)}</p>
                </div>
                <div className={styles.InputR}>
                  <p>{toTime(props.data.event.endedAt)}</p>
                  <img src={TimeIcon} alt="" />
                </div>
              </div>
            </section>
            <section className={styles.Group}>
              <p>ช่วงวันที่</p>
              <div className={styles.InputFiled}>
                <p>
                  {new Date(props.data.event.startedAt).toLocaleDateString('th-TH', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                  })}
                </p>
                {/* <img src={CalendarIcon} alt="" /> */}
              </div>
            </section>
            <section className={styles.Group}>
              <p>พนักงาน</p>
              <div className={styles.EmpProfile}>
                <Avatar
                  alt={props.data.employee.fullName}
                  src={props.data.employee.profileImage}
                  className={styles.avatar}
                />
                <p>
                  {props.data.employee.fullName} {props.data.employee.lastName}
                </p>
              </div>
            </section>
          </div>
        )}

        {console.log(props.data, 'data')}
      </section>
      <section className={styles.pointer}></section>
    </div>
  )
}

export default React.forwardRef<CalendarRef, Props>(BigCalendar)
