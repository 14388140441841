import React from 'react'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import detailIcon from '../../image/view.svg'
import editIcon from '../../image/editicon.svg'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import delIcon from '../../image/del.svg'
import blockIcon from '../../image/block.svg'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import search from '../../image/search.svg'
import downIcon from '../../image/down.svg'
import hrIcon from '../../image/logo.svg'
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent } from '../../component'
import { Avatar, AvatarGroup, TextField, InputAdornment } from '@mui/material'
import { TableMember } from './table'

const UserManageTeamAdduser: React.FC = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const navigate = useNavigate()
  // const validationSchema = yup.object({})
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            <h1 className={styles.title}>ผู้ใช้งาน</h1>
          </div>

          <div className={styles.card}>
            <div className={styles.Head}>
              <div className={styles.textGroup}>
                <p className={styles.teamText}> Team A </p>
                <p className={styles.subtitle}>Team to manage Project HR</p>
                <div className={styles.box}>
                  <div className={styles.avatar}>
                    {' '}
                    <AvatarGroup max={4}>
                      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                      <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                      <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                      <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                      <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                    </AvatarGroup>
                  </div>
                  <button
                    className={styles.buttonEdit}
                    type="button"
                    onClick={() => navigate('../usermanage/team/adduser')}
                  >
                    <img src={editIcon} alt="" />
                    <span className={styles.text}>Change Team</span>
                  </button>
                </div>
              </div>
              <div className={styles.button}>
                <img src={detailIcon} alt="" onClick={handleClick} />
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => navigate('')}>
                  <span className={styles.menu}>
                    <img src={blockIcon} alt="" /> Block
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    dispatch(
                      allAction.modalConfirmAction.setOpenModalConfirm(
                        'ลบข้อมูลทีม ?',
                        'คุณต้องการลบข้อมูลทีม',
                        'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                        'ยืนยัน',
                        () => {
                          dispatch(allAction.modalAction.setOpenModalAlertStatus('ลบข้อมูลสำเร็จ', '', 'success'))
                          dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        },
                      ),
                    )
                  }
                >
                  <span className={styles.menu}>
                    <img src={delIcon} alt="" /> Remove
                  </span>
                </MenuItem>
              </Menu>
            </div>

            <div className={styles.Tableform}>
              <p className={styles.Tablehead}>รายชื่อทีม</p>
              <div className={styles.line}></div>
              <TableMember />
            </div>
          </div>

          <div className={styles.cardassign}>
            <div className={styles.Head}>
              <p className={styles.text}>Assigned</p>
              <div className={styles.search}>
                <TextField
                  label="ค้นหาโมดูล"
                  id="outlined-start-adornment"
                  sx={{ m: 1, width: '35ch' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={search} alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div className={styles.boxAssign}>
              <span className={styles.header}>
                <img src={downIcon} /> Assigned (1)
              </span>
              <div className={styles.card}>
                <div className={styles.textform}>
                  <img src={hrIcon} className={styles.Icondepart} />
                  <div className={styles.description}>
                    <p className={styles.title}>Human Resource </p>
                    <p className={styles.detail}>All 9 function assigned</p>
                  </div>
                </div>

                <div className={styles.button}>
                  <div className={styles.btAssign}>
                    <ButtonComponent
                      _variant="outlined"
                      _text="Unassign"
                      _colorText="black"
                      _colorBG="white"
                      _type="button"
                      _functionOnClick={() => navigate('')}
                    />
                  </div>
                </div>
              </div>

              <span className={styles.header}>
                <img src={downIcon} /> Not Assigned (1)
              </span>
              <div className={styles.card}>
                <div className={styles.textform}>
                  <img src={hrIcon} className={styles.Icondepart} />

                  <div className={styles.description}>
                    <p className={styles.title}>Inventory (ระบบคลังสินค้า) </p>
                  </div>
                </div>

                <div className={styles.button}>
                  <p className={styles.detail}>10 function</p>
                  <div className={styles.btAssign}>
                    <ButtonComponent
                      _variant="contained"
                      _text="assign"
                      _colorText="white"
                      _colorBG="secondary"
                      _type="button"
                      _functionOnClick={() => navigate('')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.formbutton}>
            <div className={styles.btBack}>
              <ButtonComponent
                _colorBG="black"
                _colorText="white"
                _text="ย้อนกลับ"
                _variant="contained"
                _type="submit"
                _functionOnClick={() => navigate(-1)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer">Footer</div>
    </div>
  )
}

export default UserManageTeamAdduser
