//Department
export const mutationCreateDepartment = `
mutation Mutation($input: CreateDepartmentInput!) {
  createDepartment2(input: $input) {
    departmentName
    regulationWorkAttendance
    regulationLeave
    regulationCalendar
    regulationOverTime
    departmentDetail
  }
}
`
export const mutationUpdateDepartment = `
mutation UpdateDepartment($input: UpdateDepartmentInput!) {
  updateDepartment(input: $input) {
    id
    departmentName
    regulationWorkAttendance
    regulationLeave
    regulationCalendar
    regulationOverTime
    departmentDetail
  }
}
`
export const mutationDeleteDepartment = `
mutation DeleteDepartment($input: DeleteDepartmentInput!) {
  deleteDepartment(input: $input) {
    id
    departmentName
  }
}
`

//Employee
export const mutationCreateTeam2 = `
mutation CreateTeam2($input: CreateTeamInput!) {
  createTeam2(input: $input) {
    id
    teamName
    teamLeaderID
    listMemberID
    remark
  }
}
`
export const mutationUpdateTeam = `
mutation Mutation($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    id
    teamName
  }
}

`
//Position
export const mutationCreatePosition = `
mutation CreatePosition2($input: CreatePositionInput!) {
  createPosition2(input: $input) {
    id
    positionName
    positionLevel
    remark
  }
}
`
export const mutationUpdatePosition = `
mutation Mutation($input: UpdatePositionInput!) {
  updatePosition(input: $input) {
    id
    positionName
    positionLevel
    remark
  }
}
`
export const mutationDeletePosition = `
mutation DeletePosition($input: DeletePositionInput!) {
  deletePosition(input: $input) {
    id
    positionName
    positionLevel
    remark
  }
}
`

export const mutationCreateTimeAttendanceBatch = `
mutation Mutation($input: CreateTimeAttendanceBatchInput!, $startDate: AWSDate!, $endDate: AWSDate!) {
  createTimeAttendanceBatch(input: $input, startDate: $startDate, endDate: $endDate)
}`

export const mutationUpdateTimeAttendanceReport = `
mutation UpdateTimeAttendanceReport($input: UpdateTimeAttendanceReportInput!) {
  updateTimeAttendanceReportTypeAttendance(input: $input) {
    employeeID
  }
}`

export const mutationUpdateLeaveAttendance = `
mutation UpdateTimeAttendanceReportTypeLeave($input: UpdateTimeAttendanceReportInput!) {
  updateTimeAttendanceReportTypeLeave(input: $input) {
    employeeID
  }
}`

export const mutationUpdateOTAttendance = `
mutation UpdateTimeAttendanceReportTypeOvertime($input: UpdateTimeAttendanceReportInput!) {
  updateTimeAttendanceReportTypeOvertime(input: $input) {
    employeeID
    date
  }
}`

export const mutationDeleteTimeAttendanceReport = `
mutation DeleteTimeAttendanceReport($input: DeleteTimeAttendanceReportInput!) {
  deleteTimeAttendanceReport(input: $input) {
    employeeID  
  }
}`

//Admin New
export const mutationCreateHolidayPolicy = `
mutation Mutation($input: CreateHolidayInput!, $year: Int!) {
  createHolidayOnYear(input: $input, year: $year) {
    holidayID
    holidayName
    holidayYear
  }
}
`

export const mutationCreateShift = `
mutation CreateShift($input: CreateShiftInput!) {
  createShift(input: $input) {
    shiftID
      workDayPolicy {
        dayList
        timeList {
          startTime
          endTime
          isScanEnd
          isScanEnd
        }
      }
      breakPolicy {
        dayList
        timeList {
          startTime
          endTime
          isScanEnd
          isScanEnd
        }
      }
      employeeType
      workType
  }
}`

export const mutationUpdateShift = `
mutation UpdateShift($input: UpdateShiftInput!) {
  updateShift(input: $input) {
    shiftID
  }
}`

export const mutationPutHexColorList = `
mutation PutShiftColor($input: PutShiftColorInput!) {
  putShiftColor(input: $input) {
    hexColorList
  }
}`

export const mutationUpdateDefaultShift = `
mutation UpdateDefaultShift($shiftId: ID!) {
  updateDefaultShift(shiftID: $shiftId) {
    shiftColor
  }
}`

export const mutationDeleteShift = `
mutation DeleteShift($input: DeleteShiftInput!) {
  deleteShift(input: $input) {
    shiftID
  }
}`
//UpdateHoliday
export const mutationUpdateHoliday = `
mutation Mutation($input: UpdateHolidayInput!) {
  updateHolidayOnYear(input: $input) {
    holidayID
    holidayName
    holidayYear
  }
}
`

//DelHoliday
export const mutationDelHoliday = `
mutation Mutation($holidayId: ID!) {
  deleteHoliday(holidayID: $holidayId) {
    holidayID
    holidayName
    holidayYear
  }
}
`

//Attendancereport
export const mutationAttendanceReport = `
mutation TimeReport_update($input: UpdateTimeAttendanceReportInput!) {
  updateTimeAttendanceReport(input: $input) {
    employeeID
    date
    scanIn
    scanOut
    workingTimeList {
      startedAt
      endedAt
    }

    inDiffMinute
    breakDiffMinute
    outDiffMinute

    overtimeList {
      overtimeType
      startedAt
      endedAt
    }


    overtimeApproveList {
      startedAt
      endedAt
      overtimeMinute
      overtimeType
      locationType
      status
      overtimeApproveMinute
      overtimeRequestID
    }

    leaveDeductList {
      leaveType
      startedAt
      endedAt
      leaveDocumentAttachment
      leaveForm
      leaveRequestID
      status
    }
    leaveList {
      leaveType
      startedAt
      endedAt
      leaveDocumentAttachment
      leaveMinute
      leaveForm
      leaveRequestID
      status
    }
    isLink

  }
}
`

export const mutationCreatePayroll = `
mutation CreatePayroll($yearMonth: String!) {
  createPayroll(yearMonth: $yearMonth)
}`

export const mutationPutEmployeePayroll = `
mutation PutEmployeePayroll($input: [PutEmployeePayrollInput!]!, $period: String!, $yearMonth: String!) {
  putEmployeePayroll(input: $input, period: $period, yearMonth: $yearMonth)
}`

//Mutation Document Leave
export const mutationCreateLeaveDocument = `
mutation CreateLeaveRequest($input: CreateLeaveInput!) {
  createLeaveRequest(input: $input) {
    id
    date
    employeeID
    leaveType
    isLeaveDeductMoney
    leaveForm
    startedAt
    endedAt
    leaveDocumentAttachment
    status
  }

}

`

//CreateOvertime
export const mutationCreateOvertimeDocument = `
mutation RequestOT_create($input: CreateOvertimeInput!) {
  createOvertimeRequest(input: $input) {
    id
    date
    employeeID
    startedAt
    endedAt
    status
    overtimeType
  }
}
 `

//ResetAttendance
export const mutationResetAttendance = `
 mutation ResetTimeAttendanceReport($input: ResetTimeAttendanceReportInput!) {
  resetTimeAttendanceReport(input: $input) {
    date
  }
}
 `

//Del OT Document
export const mutationDelRequestOverTime = `
mutation RequestOT_delete($input: DeleteRequestInput!) {
  deleteOvertimeRequest(input: $input) {
    employeeID
    id
  }
} 
 `

//Del Leave Document
export const mutationDeleteLeave = `
 mutation RequestLeave_delete($input: DeleteRequestInput!) {
  deleteLeaveRequest(input: $input) {
    employeeID
    id
  }
}
 `

//Approve Document
export const mutationApproveDocument = `
mutation Mutation($input: UpdateApprove!) {
  updateApprove(input: $input) {
    id
    employeeID
    approveBy
    status
    remarkApprove
    Type
    timestamp
    updatedAt
    createdAt
  }
}
 `

//Approve Document Arrray
export const mutationApproveBatch = `
 mutation Mutation($input: [UpdateApprove!]!) {

  updateApproveBatch(input: $input)

}
 `

export const mutationUpdateDefaultCalendar = `
 mutation Holiday_default($holidayId: ID!) {
  updateDefaultHoliday(holidayID: $holidayId) {
    holidayID
  }
}
 `

export const mutationLogin = `
mutation Login($username: String!, $password: String!, $partyId: String!) {
  login(username: $username, password: $password, partyID: $partyId)
}
 `

export const mutationRefreshToken = `
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken)
  }
`

//Employee Profile
export const mutationCreateEmployeeProfile = `
mutation Mutation($input: CreateEmployeeInput!) {
  createEmployee2(input: $input) {
    bankPayment
   id
 } 
 }
`

//UpdateEmployee
export const mutationupdateEmployeeProfile = `
mutation Mutation($input: UpdateEmployeeInput!) {
  updateEmployee2(input: $input) {
    id
    positionID
    departmentID
    fullName

  }
}
`

//Close Payroll
export const mutationClosePayroll = `
mutation ClosePayroll($yearMonth: String!, $period: String!, $closeDate: String) {
  closePayroll(yearMonth: $yearMonth, period: $period, closeDate: $closeDate) {
    employeeCount
    status
    createdAt
    updatedAt
    timeAttendanceReportList {
      employeeID
      employee {
        id
        positionID
        departmentID
      }
      items {
        companyID
        date
        employeeID
        dayType
      }
    }
    employeePayrollList {
      employeeID
      workingMinute
      leaveMinute
      leaveDeductMinute
      otTypeWorkMinute
      otTypeOTMinute
      income
      otTypeWorkIncome
      otTypeOTIncome
      otherIncomeList {
        id
        name
        incomeOrExpense
        incomeExpenseType
        variableOrFixed
        income40Type
        amount
      }
      workDiligentIncome
      inEarlyIncome
      breakEarlyIncome
      outLateIncome
      netPositiveIncome
      otherExpenseList {
        id
        name
        incomeOrExpense
        incomeExpenseType
        variableOrFixed
        income40Type
        amount
      }
      inLateExpense
      breakLateExpense
      netNegativeIncome
      netIncome
      daysInPeriod
      paySlipURL
      paymentID
    }
    totalPayroll
    closeDate
    nextToken
    employeeTypeCount {
      FULLTIME_MONTH
      FULLTIME_DAY
      PARTTIME
      OUTSOURCE
    }
    employeeRetireCount
    employeeHireCount
    paySlipEmpPeriod
  }
}
`

//Payroll Slip
export const mutationPayrollSlip = `
mutation PDF_SlipPeyment($input: DownloadOptional!) {
  downloadPDFDocument(input: $input)
}
`

//UpdateOvertimeDocument
export const mutationUpdateOvertime = `
mutation Overtime_update($input: UpdateOvertimeInput!) {
  updateOvertime2(input: $input) {
    id
    employeeID
    date
    startedAt
    endedAt
    overtimeType
    status
  }
}
`

//DeleteOvertimeDocument
export const mutationDeleteOvertime = `
mutation DeleteOvertime2($input: DeleteOvertimeInput!) {
  deleteOvertime2(input: $input) {
    id
    employeeID
    date
    startedAt
    endedAt
    overtimeType
    placeType
    status
  }
}
`

//UpdateLeaveDocument
export const mutationUpdateLeaveDocument = `
mutation Leave_update($input: UpdateLeaveInput!) {
  updateLeave2(input: $input) {
    id
    employeeID
    createdAt
    date
    startedAt
    endedAt
    leaveType
    status
  }
}
`

export const mutationDeleteLeaveDocument = `
mutation Leave_delete($input: DeleteLeaveInput!) {
  deleteLeave2(input: $input) {
    id
    employeeID
    createdAt
    date
    startedAt
    endedAt
    leaveType
    leaveForm
    status
  }
}
`
