import React from 'react'
import styles from './index.module.scss'
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent } from '../../component'
import { useNavigate } from 'react-router-dom'
// import allAction from '../../state/actions'
// import { useDispatch } from 'react-redux'
// import InfoIcon from '../../image/infoBlue.svg'
import CustomizedSwitches from '../../component/AntSwitch'

const LeavePolicyPage: React.FC = (): JSX.Element => {
  // const dispatch = useDispatch()
  const navigate = useNavigate()

  const LeaveDefault = [
    {
      name: 'ลาป่วย',
      id: '01',
      unit: 'year',
    },
    {
      name: 'ลาทำหมัน',
      id: '02',
    },
    {
      name: 'ลากิจ',
      id: '03',
    },
    {
      name: 'ลารับราชการทหาร',
      id: '04',
    },
    {
      name: 'ลาฝึกอบรม',
      id: '05',
    },
    {
      name: 'ลาคลอด',
      id: '06',
    },
    {
      name: 'ลาพักร้อน',
      id: '07',
    },
  ]
  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <div className="main">
          <div className={styles.container}>
            <section className={styles.topicPageWrapper}>
              <p className={styles.topicPage}>ระเบียบการลา</p>

              <div className={styles.button}>
                <ButtonComponent
                  _colorBG="blue"
                  _colorText="white"
                  _text="เพิ่มระเบียบการลา"
                  _type="button"
                  _variant="contained"
                  _functionOnClick={() => navigate('../masterdata/leavepolicy/edit')}
                />
              </div>
            </section>
            <div className={styles.contentContainer}>
              <section className={styles.TabList}>
                <div className={styles.AreaList}>
                  <p className={styles.title}>วันลาที่เพิ่มแล้ว</p>

                  <div className={styles.GroupList}>
                    <p className={styles.headText}>ประเภทวันลาตามกฎหมายแรงงาน</p>

                    <div className={styles.CardList}>
                      {LeaveDefault.map((item: any, itemIndex: number) => (
                        <div className={styles.card} key={itemIndex}>
                          <p>{item.name}</p>

                          <CustomizedSwitches
                            _name="isTimeAttendance"
                            // _onClick={(e: any) => props.values.setFieldValue('isTimeAttendance', e.target.checked)}
                            // _checked={props.values.values.isTimeAttendance}
                          />

                          {/* <img src={InfoIcon} alt="" /> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.DataShow}></section>

              {/* <section className={styles.btnTopWrapper}>
                <div className={styles.btnTop}>
                  <Button
                    _type="button"
                    _colorBG="blue"
                    _colorText="white"
                    _variant="outlined"
                    _isIcon="add"
                    _text="เพิ่มระเบียบการลา"
                    _functionOnClick={() => navigate('/masterdata/leavepolicy/add')}
                  />
                </div>
              </section>
              <section>
                <CardLeave
                  _data={leaveData}
                  _functionOnClickEdit={() => navigate('/masterdata/leavepolicy/edit')}
                  _functionOnClickDelete={() => {
                    console.log('delete')
                    dispatch(
                      allAction.modalConfirmAction.setOpenModalConfirm(
                        'ลบระเบียบการลา',
                        `คุณต้องการลบรายการ ${leaveData.topic}  ใช่หรือไม่`,
                        'หากลบแล้ว ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                        'ใช่, ลบรายการ',
                        () => {
                          dispatch(
                            allAction.modalAction.setOpenModalAlertStatus(
                              `ลบข้อมูลรายการ${leaveData.topic}เรียบร้อยแล้ว`,
                              '',
                              'success',
                            ),
                          )
                          dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        },
                      ),
                    )
                  }}
                />
              </section> */}
            </div>
          </div>
        </div>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default LeavePolicyPage

const leaveData = {
  topic: 'ลาป่วย',
  subTopic1: 'จำนวนวันที่ลาได้  56:',
  day1: '30',
  subTopic2: 'จำนวนวันลาที่ได้จ่ายเงิน :',
  day2: '29',
}
