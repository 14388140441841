export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: any
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any
  /** The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like "**{\\"upvotes\\": 10}**", lists like "**[1,2,3]**", and scalar values like "**\\"AWSJSON example string\\"**", "**1**", and "**true**" are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings.  Invalid JSON strings like "**{a: 1}**", "**{'a': 1}**" and "**Unquoted string**" will throw GraphQL validation errors. */
  AWSJSON: any
  /** The `AWSTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Time](https://en.wikipedia.org/wiki/ISO_8601#Times) string. In other words, this scalar type accepts time strings of the form `hh:mm:ss.SSS`.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**12:00:00.2**", "**12:00:00.277**" and "**12:00:00.123456789**" are all valid time strings. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**12:30**", "**12:30Z**", "**12:30:24-07:00**" and "**12:30:24.500+05:30**" are all valid time strings. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSTime: any
  /** The `AWSTimestamp` scalar type provided by AWS AppSync, represents the number of seconds that have elapsed since `1970-01-01T00:00Z`. Negative values are also accepted and these represent the number of seconds till `1970-01-01T00:00Z`.  Timestamps are serialized and deserialized as integers. The minimum supported timestamp value is **`-31557014167219200`** which corresponds to `-1000000000-01-01T00:00Z`. The maximum supported timestamp value is **`31556889864403199`** which corresponds to `1000000000-12-31T23:59:59.999999999Z`. */
  AWSTimestamp: any
}

export type Approve = {
  Type?: Maybe<EApproveType>
  approveBy?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  leftQuotaLeave?: Maybe<Scalars['String']>
  listName?: Maybe<Scalars['String']>
  quotaLeave?: Maybe<Scalars['String']>
  remarkApprove?: Maybe<Scalars['String']>
  shardID?: Maybe<Scalars['ID']>
  status?: Maybe<EApproveStatus>
  statusTimeStamp?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['AWSDateTime']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type ApproveAttendance = {
  __typename?: 'ApproveAttendance'
  approveLeave?: Maybe<ApproveQuery>
  compensation?: Maybe<CompensationQuery>
  compensationFoodIPay?: Maybe<Scalars['String']>
  compensationFoodQuota?: Maybe<Scalars['String']>
  compensationFoodUsed?: Maybe<Scalars['String']>
  compensationSnackIPay?: Maybe<Scalars['String']>
  compensationSnackQuota?: Maybe<Scalars['String']>
  compensationSnackUsed?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  date?: Maybe<Scalars['AWSDate']>
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  endedAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['ID']>
  lateQuota?: Maybe<Scalars['String']>
  lateWithPaid?: Maybe<Scalars['String']>
  lateWithoutPaid?: Maybe<Scalars['String']>
  leaveAbsentHour?: Maybe<Scalars['String']>
  leaveAbsentWithoutPaid?: Maybe<Scalars['String']>
  leaveAnnualQuota?: Maybe<Scalars['String']>
  leaveAnnualWithPaid?: Maybe<Scalars['String']>
  leaveAnnualWithoutPaid?: Maybe<Scalars['String']>
  leavePersonalQuota?: Maybe<Scalars['String']>
  leavePersonalWithPaid?: Maybe<Scalars['String']>
  leavePersonalWithoutPaid?: Maybe<Scalars['String']>
  leaveSickQuota?: Maybe<Scalars['String']>
  leaveSickWithPaid?: Maybe<Scalars['String']>
  leaveSickWithoutPaid?: Maybe<Scalars['String']>
  overtime?: Maybe<ApproveQuery>
  overtimeHour?: Maybe<Scalars['Int']>
  startedAt?: Maybe<Scalars['AWSDateTime']>
  status?: Maybe<EApproveAttendanceStatus>
  timeAttendanceReport?: Maybe<TimeAttendanceReportQuery>
  totalWorkday?: Maybe<Scalars['String']>
  totalWorkdayHour?: Maybe<Scalars['Int']>
}

export type ApproveAttendanceQuery = {
  __typename?: 'ApproveAttendanceQuery'
  items: Array<ApproveAttendance>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type ApproveConfig = {
  __typename?: 'ApproveConfig'
  LEAVE_REQUEST: ApproveFlowTemplate
  OT_REQUEST: ApproveFlowTemplate
  WELFARE_REQUEST: ApproveFlowTemplate
  WITHDRAW_REQUEST: ApproveFlowTemplate
}

export type ApproveFlowTemplate = {
  __typename?: 'ApproveFlowTemplate'
  approveFlowtype: EApproveFlowType
  stepList: Array<ApproveStep>
  treeLimitLevel?: Maybe<Scalars['String']>
}

export type ApproveLeave = Approve & {
  __typename?: 'ApproveLeave'
  Type?: Maybe<EApproveType>
  approveBy?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  date?: Maybe<Scalars['AWSDate']>
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  endedAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  isLeaveDeductMoney?: Maybe<Scalars['Boolean']>
  leaveDocumentAttachment?: Maybe<Scalars['String']>
  leaveForm?: Maybe<ELeaveForm>
  leaveFormHalfDay?: Maybe<ELeaveHalfDay>
  leaveMinute?: Maybe<Scalars['Int']>
  leaveType?: Maybe<ELeaveType>
  leftQuotaLeave?: Maybe<Scalars['String']>
  listName?: Maybe<Scalars['String']>
  quotaLeave?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  remarkApprove?: Maybe<Scalars['String']>
  shardID?: Maybe<Scalars['ID']>
  startedAt?: Maybe<Scalars['String']>
  status?: Maybe<EApproveStatus>
  statusTimeStamp?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['AWSDateTime']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type ApproveOvertime = Approve & {
  __typename?: 'ApproveOvertime'
  Type?: Maybe<EApproveType>
  approveBy?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  date?: Maybe<Scalars['AWSDate']>
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  endedAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  leftQuotaLeave?: Maybe<Scalars['String']>
  listName?: Maybe<Scalars['String']>
  overtimeMinute?: Maybe<Scalars['Int']>
  overtimeType?: Maybe<EOverTimeType>
  placeType?: Maybe<EPlaceType>
  quotaLeave?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  remarkApprove?: Maybe<Scalars['String']>
  shardID?: Maybe<Scalars['ID']>
  startedAt?: Maybe<Scalars['String']>
  status?: Maybe<EApproveStatus>
  statusTimeStamp?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['AWSDateTime']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type ApproveQuery = {
  __typename?: 'ApproveQuery'
  items: Array<Approve>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type ApproveStep = {
  __typename?: 'ApproveStep'
  approveStepType: EApproveStepType
  approvedAt?: Maybe<Scalars['AWSTimestamp']>
  approvedByUserID: Scalars['ID']
  employeeID?: Maybe<Scalars['ID']>
  headID?: Maybe<Scalars['ID']>
  levelID?: Maybe<Scalars['ID']>
  positionID?: Maybe<Scalars['ID']>
  remark?: Maybe<Scalars['String']>
}

export type BreakTimeReport = {
  __typename?: 'BreakTimeReport'
  endTime?: Maybe<Scalars['AWSTime']>
  endedAt?: Maybe<Scalars['AWSDateTime']>
  startTime?: Maybe<Scalars['AWSTime']>
  startedAt?: Maybe<Scalars['AWSDateTime']>
}

export type Company = {
  __typename?: 'Company'
  companyID: Scalars['ID']
  name?: Maybe<Scalars['String']>
  status: EStatus
}

export type Compensation = {
  __typename?: 'Compensation'
  compensationDocumentAttachment?: Maybe<Scalars['String']>
  compensationName?: Maybe<Scalars['String']>
  compensationType?: Maybe<ECompensationType>
  employeeID?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  remark?: Maybe<Scalars['String']>
  shareWith?: Maybe<Array<Maybe<Scalars['String']>>>
  status?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['String']>
}

export type CompensationQuery = {
  __typename?: 'CompensationQuery'
  items: Array<Compensation>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type CreateApproveAttendance = {
  compensationFoodIPay?: InputMaybe<Scalars['String']>
  compensationFoodQuota?: InputMaybe<Scalars['String']>
  compensationFoodUsed?: InputMaybe<Scalars['String']>
  compensationSnackIPay?: InputMaybe<Scalars['String']>
  compensationSnackQuota?: InputMaybe<Scalars['String']>
  compensationSnackUsed?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['AWSDateTime']>
  date: Scalars['AWSDate']
  employeeID: Scalars['ID']
  endedAt?: InputMaybe<Scalars['AWSDateTime']>
  lateQuota?: InputMaybe<Scalars['String']>
  lateWithPaid?: InputMaybe<Scalars['String']>
  lateWithoutPaid?: InputMaybe<Scalars['String']>
  leaveAbsentHour?: InputMaybe<Scalars['String']>
  leaveAbsentWithoutPaid?: InputMaybe<Scalars['String']>
  leaveAnnualQuota?: InputMaybe<Scalars['String']>
  leaveAnnualWithPaid?: InputMaybe<Scalars['String']>
  leaveAnnualWithoutPaid?: InputMaybe<Scalars['String']>
  leavePersonalQuota?: InputMaybe<Scalars['String']>
  leavePersonalWithPaid?: InputMaybe<Scalars['String']>
  leavePersonalWithoutPaid?: InputMaybe<Scalars['String']>
  leaveSickQuota?: InputMaybe<Scalars['String']>
  leaveSickWithPaid?: InputMaybe<Scalars['String']>
  leaveSickWithoutPaid?: InputMaybe<Scalars['String']>
  overtimeHour?: InputMaybe<Scalars['Int']>
  startedAt?: InputMaybe<Scalars['AWSDateTime']>
  status: EApproveAttendanceStatus
  totalWorkday?: InputMaybe<Scalars['String']>
  totalWorkdayHour?: InputMaybe<Scalars['Int']>
}

export type CreateCompensationInput = {
  compensationDocumentAttachment?: InputMaybe<Scalars['String']>
  compensationName?: InputMaybe<Scalars['String']>
  compensationType?: InputMaybe<ECompensationType>
  employeeID: Scalars['ID']
  remark?: InputMaybe<Scalars['String']>
  shareWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Scalars['String']>
  totalAmount?: InputMaybe<Scalars['String']>
}

export type CreateDepartmentInput = {
  departmentDetail?: InputMaybe<Scalars['String']>
  departmentName: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
  regulationCalendar: Array<ERegulationCalendar>
  regulationLeave: Array<ERegulationLeave>
  regulationOverTime: Array<ERegulationOverTime>
  regulationWorkAttendance: Array<ERegulationWorkAttendance>
}

export type CreateEmployeeInput = {
  addressCurrent?: InputMaybe<CreateFAddressPersonalId>
  addressPersonalID?: InputMaybe<CreateFAddressPersonalId>
  bankAccountId?: InputMaybe<Scalars['ID']>
  bankAccountName?: InputMaybe<EBankProviderThailand>
  bankPayment?: InputMaybe<EBankProviderThailand>
  birthDate?: InputMaybe<Scalars['AWSDate']>
  departmentID: Scalars['ID']
  diligentAllowance?: InputMaybe<Scalars['Boolean']>
  educationHighest?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  employeeFund?: InputMaybe<Array<InputMaybe<CreateFEmployeeFund>>>
  employeeHandicappedAndAgeOut?: InputMaybe<Scalars['Boolean']>
  employeePeriod?: InputMaybe<EEmployeePeriod>
  employeeType?: InputMaybe<EEmployeeType>
  employeeWorkStatus?: InputMaybe<EEmployeeWorkStatus>
  ethnicity?: InputMaybe<Scalars['String']>
  fingerPrintId?: InputMaybe<Scalars['ID']>
  fullName?: InputMaybe<Scalars['String']>
  fullNameEnglish?: InputMaybe<Scalars['String']>
  getHolidayID?: InputMaybe<Scalars['ID']>
  hireDate: Scalars['AWSDate']
  historyEducation?: InputMaybe<Array<InputMaybe<CreateFHistoryEducation>>>
  historyFamily?: InputMaybe<Array<InputMaybe<CreateFHistoryFamily>>>
  historyWork?: InputMaybe<Array<InputMaybe<CreateFHistoryWork>>>
  holidayID?: InputMaybe<Scalars['ID']>
  holidayLink?: InputMaybe<EHolidayLink>
  hospitalService?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  incomeExpense?: InputMaybe<Array<InputMaybe<CreateFIncomeExpense>>>
  isTimeAttendance?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  lastNameEnglish?: InputMaybe<Scalars['String']>
  leaderTeam?: InputMaybe<Scalars['ID']>
  lineId?: InputMaybe<Scalars['String']>
  lineUserId?: InputMaybe<Scalars['ID']>
  middleName?: InputMaybe<Scalars['String']>
  middleNameEnglish?: InputMaybe<Scalars['String']>
  nameTitle?: InputMaybe<ENameTitle>
  nationality?: InputMaybe<Scalars['String']>
  nickName?: InputMaybe<Scalars['String']>
  packageGroupID?: InputMaybe<Scalars['ID']>
  password?: InputMaybe<Scalars['String']>
  paymentMethod?: InputMaybe<EPaymentMethod>
  personalEducationalAttachment?: InputMaybe<Scalars['String']>
  personalHouseAttachment?: InputMaybe<Scalars['String']>
  personalID?: InputMaybe<Scalars['ID']>
  personalIDNumber?: InputMaybe<Scalars['ID']>
  personalIdAttachment?: InputMaybe<Scalars['String']>
  personalOptionalAttachment?: InputMaybe<Scalars['String']>
  personalRelation?: InputMaybe<EPersonalRelation>
  personalTalent?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  personalTrainningAttachment?: InputMaybe<Scalars['String']>
  positionID: Scalars['ID']
  profileImage?: InputMaybe<Scalars['String']>
  religion?: InputMaybe<Scalars['String']>
  retireDate: Scalars['AWSDate']
  salary?: InputMaybe<Scalars['Float']>
  salaryOptional?: InputMaybe<ESalaryOptional>
  salaryUOM?: InputMaybe<ESalaryUom>
  setShiftWorking?: InputMaybe<ESetShiftWorking>
  sex?: InputMaybe<ESex>
  shiftWorkingID?: InputMaybe<Scalars['ID']>
  socialSecurity?: InputMaybe<ESocialSecurity>
  socialSecurityCalculate?: InputMaybe<ESocialSecurityCalculate>
  status?: InputMaybe<EStatus>
  taxDeductOne?: InputMaybe<Array<InputMaybe<CreateFTaxDeductOne>>>
  taxDeductTwo?: InputMaybe<Array<InputMaybe<CreateFTaxDeductTwo>>>
  teamList?: InputMaybe<Scalars['ID']>
  tel?: InputMaybe<Scalars['String']>
  unusedLatestSalary?: InputMaybe<Scalars['Float']>
  username?: InputMaybe<Scalars['String']>
  workPlace?: InputMaybe<EPlaceType>
}

export type CreateFAddressPersonalId = {
  addressNumber?: InputMaybe<Scalars['String']>
  district?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['String']>
  optional?: InputMaybe<Scalars['String']>
  province?: InputMaybe<Scalars['String']>
  subDistrict?: InputMaybe<Scalars['String']>
  zipCode?: InputMaybe<Scalars['String']>
}

export type CreateFEmployeeFund = {
  beneficiary?: InputMaybe<Scalars['String']>
  contractDate?: InputMaybe<Scalars['AWSDate']>
  contributionCompany?: InputMaybe<Scalars['String']>
  contributionMethod?: InputMaybe<EFundDeductContribution>
  contributionTotal?: InputMaybe<Scalars['Float']>
  deductMethod?: InputMaybe<EFundDeductContribution>
  fundNumber?: InputMaybe<Scalars['Int']>
  fundRate?: InputMaybe<Scalars['Float']>
  name?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
}

export type CreateFHistoryEducation = {
  educationLevel?: InputMaybe<Scalars['String']>
  educationName?: InputMaybe<Scalars['String']>
  endedYear?: InputMaybe<Scalars['String']>
  majorName?: InputMaybe<Scalars['String']>
  startedYear?: InputMaybe<Scalars['String']>
}

export type CreateFHistoryFamily = {
  age?: InputMaybe<Scalars['Int']>
  fullName?: InputMaybe<Scalars['String']>
  job?: InputMaybe<Scalars['String']>
  relation?: InputMaybe<Scalars['String']>
}

export type CreateFHistoryWork = {
  descPosition?: InputMaybe<Scalars['String']>
  endedAt?: InputMaybe<Scalars['AWSDate']>
  leaveReason?: InputMaybe<Scalars['String']>
  positionName?: InputMaybe<Scalars['String']>
  salary?: InputMaybe<Scalars['Float']>
  startedAt?: InputMaybe<Scalars['AWSDate']>
  workName?: InputMaybe<Scalars['String']>
}

export type CreateFIncomeExpense = {
  incomeExpenseType?: InputMaybe<EIncomeExpenseType>
  name?: InputMaybe<EIncomeExpenseName>
  total?: InputMaybe<Scalars['Float']>
  unit?: InputMaybe<EWelfareQuotaUnit>
}

export type CreateFTaxDeductOne = {
  month?: InputMaybe<Scalars['String']>
  total?: InputMaybe<Scalars['Float']>
  year?: InputMaybe<Scalars['String']>
}

export type CreateFTaxDeductTwo = {
  name?: InputMaybe<Scalars['String']>
  rateDeduct?: InputMaybe<Scalars['Float']>
  totalDeduct?: InputMaybe<Scalars['Float']>
}

export type CreateHolidayInput = {
  holidayName?: InputMaybe<Scalars['String']>
  holidayYear?: InputMaybe<Array<InputMaybe<HolidayYearInput>>>
}

export type CreateLeaveConfig = {
  LeaveBeforehandDay?: InputMaybe<Scalars['Int']>
  employeeAllowSexList?: InputMaybe<Array<InputMaybe<ESex>>>
  employeeAllowTypeList?: InputMaybe<Array<InputMaybe<EEmployeeType>>>
  isActive?: InputMaybe<Scalars['Boolean']>
  isCountProbation?: InputMaybe<Scalars['Boolean']>
  isExceedQuota?: InputMaybe<Scalars['Boolean']>
  isLeaveAfterhand?: InputMaybe<Scalars['Boolean']>
  isLeaveBeforehand?: InputMaybe<Scalars['Boolean']>
  isLeaveCompound?: InputMaybe<Scalars['Boolean']>
  isLeaveDiligent?: InputMaybe<Scalars['Boolean']>
  isLeaveOnce?: InputMaybe<Scalars['Boolean']>
  isLookupNotWorkday?: InputMaybe<Scalars['Boolean']>
  leaveAmountType?: InputMaybe<ELeaveAmountType>
  leaveCompoundType?: InputMaybe<ECompoundType>
  leaveConfigType?: InputMaybe<ELeaveConfigType>
  leaveQuotaList?: InputMaybe<Array<InputMaybe<CreateLeaveQuota>>>
  maxLeaveCompound?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

export type CreateLeaveInput = {
  createdByEmployeeID?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['AWSDate']>
  employeeID?: InputMaybe<Scalars['ID']>
  employeeList?: InputMaybe<Array<Scalars['ID']>>
  endedAt?: InputMaybe<Scalars['String']>
  isLeaveDeductMoney?: InputMaybe<Scalars['Boolean']>
  leaveDocumentAttachment?: InputMaybe<Scalars['String']>
  leaveForm?: InputMaybe<ELeaveForm>
  leaveFormHalfDay?: InputMaybe<ELeaveHalfDay>
  leaveFormMinute?: InputMaybe<Scalars['Int']>
  leaveType?: InputMaybe<ELeaveReportType>
  remark?: InputMaybe<Scalars['String']>
  startedAt?: InputMaybe<Scalars['String']>
  status?: InputMaybe<EDocumentStatus>
  topic?: InputMaybe<ETopic>
}

export type CreateLeaveQuota = {
  maxLeavePaidDay?: InputMaybe<Scalars['Int']>
  quotaLeaveDay?: InputMaybe<Scalars['Int']>
  workMonthExperience?: InputMaybe<Scalars['Int']>
}

export type CreateOtherIncomeOrExpenseConfigInput = {
  calculateWithList?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  income40Type?: InputMaybe<E40Type>
  incomeExpenseType: EIncomeExpenseType
  incomeOrExpense: EIncomeOrExpense
  name: Scalars['String']
  roundType?: InputMaybe<ERoundType>
  status: EStatus
  variableOrFixed: EVariableOrFixed
}

export type CreateOvertimeConfigInput = {
  overtimeAfterMinute?: InputMaybe<Scalars['Int']>
  overtimeBeforeMinute?: InputMaybe<Scalars['Int']>
  overtimeDay?: InputMaybe<Array<InputMaybe<OvertimeMonthAndYearInput>>>
  overtimeDayStatus?: InputMaybe<EStatus>
  overtimeMonth?: InputMaybe<Array<InputMaybe<OvertimeMonthAndYearInput>>>
  overtimeMonthStatus?: InputMaybe<EStatus>
  overtimeMultiplyType?: InputMaybe<EOverTimeMultiplyType>
  overtimeRoundFloor?: InputMaybe<EOverTimeRoundFloor>
  overtimeRoundHour?: InputMaybe<EOvertimeHourHalf>
  overtimeRoundType?: InputMaybe<EOverTimeRoundType>
}

export type CreateOvertimeInput = {
  createdByEmployeeID?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['AWSDate']>
  employeeID?: InputMaybe<Scalars['ID']>
  endedAt: Scalars['AWSTime']
  isCustomOvertimeMultiply?: InputMaybe<Scalars['Boolean']>
  overtimeMinute?: InputMaybe<Scalars['Int']>
  overtimeMultiply?: InputMaybe<Scalars['Float']>
  overtimeType?: InputMaybe<EOverTimeType>
  placeType?: InputMaybe<EPlaceType>
  remark?: InputMaybe<Scalars['String']>
  startedAt: Scalars['AWSTime']
  status?: InputMaybe<EDocumentStatus>
}

export type CreatePackageGroupInput = {
  description?: InputMaybe<Scalars['String']>
  employeeUpdateList?: InputMaybe<Array<UpdateEmployeeIdInput>>
  leavePackageList: Array<PutLeaveConfigIdInput>
  name: Scalars['String']
  packageGroupID?: InputMaybe<Scalars['ID']>
  welfarePackageList: Array<PutWelfareConfigIdInput>
}

export type CreatePositionInput = {
  id?: InputMaybe<Scalars['ID']>
  positionLevel?: InputMaybe<Scalars['String']>
  positionName: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
}

export type CreateShiftInput = {
  breakPolicy?: InputMaybe<Array<InputMaybe<ShiftWorkPolicyInput>>>
  employeeType: Array<InputMaybe<EEmployeeType>>
  shiftColorIndex?: InputMaybe<Scalars['Int']>
  shiftName: Scalars['String']
  workDayPolicy?: InputMaybe<Array<InputMaybe<ShiftWorkPolicyInput>>>
  workType: EShiftWorkType
}

export type CreateTeamInput = {
  id?: InputMaybe<Scalars['ID']>
  listMemberID: Array<Scalars['ID']>
  remark?: InputMaybe<Scalars['String']>
  teamLeaderID: Scalars['ID']
  teamName: Scalars['String']
}

export type CreateTimeAttendanceBatchInput = {
  items: Array<CreateTimeAttendanceInput>
}

export type CreateTimeAttendanceInput = {
  deviceId?: InputMaybe<Scalars['String']>
  employeeID?: InputMaybe<Scalars['ID']>
  imageUrl?: InputMaybe<Scalars['String']>
  lat?: InputMaybe<Scalars['String']>
  locationType: ELocationType
  long?: InputMaybe<Scalars['String']>
  scanAt?: InputMaybe<Scalars['String']>
  scanReason: EScanReasonType
  scanType: EScanType
}

export type CreateTimeAttendanceReportInput = {
  Type?: InputMaybe<ETimeAttendanceReportType>
  breakDiffMinute?: InputMaybe<Scalars['Int']>
  companyID?: InputMaybe<Scalars['ID']>
  date: Scalars['String']
  dayType?: InputMaybe<EDayType>
  employeeID: Scalars['ID']
  inDiffMinute?: InputMaybe<Scalars['Int']>
  isLink?: InputMaybe<Scalars['Boolean']>
  leaveDeductList?: InputMaybe<Array<LeaveReportInput>>
  leaveDeductMinute?: InputMaybe<Scalars['Int']>
  leaveList?: InputMaybe<Array<LeaveReportInput>>
  leaveMinute?: InputMaybe<Scalars['Int']>
  outDiffMinute?: InputMaybe<Scalars['Int']>
  overtimeApproveList?: InputMaybe<Array<OvertimeReportInput>>
  overtimeApproveMinute?: InputMaybe<Scalars['Int']>
  overtimeList?: InputMaybe<Array<OvertimeReportInput>>
  overtimeMinute?: InputMaybe<Scalars['Int']>
  remark?: InputMaybe<Scalars['String']>
  scanIn?: InputMaybe<Scalars['AWSDateTime']>
  scanOut?: InputMaybe<Scalars['AWSDateTime']>
  shiftEndTime?: InputMaybe<Scalars['AWSTime']>
  shiftMinute?: InputMaybe<Scalars['Int']>
  shiftName?: InputMaybe<Scalars['String']>
  shiftStartTime?: InputMaybe<Scalars['AWSTime']>
  shiftVersion?: InputMaybe<Scalars['String']>
  workingMinute?: InputMaybe<Scalars['Int']>
  workingTimeList?: InputMaybe<Array<WorkingTimeReportInput>>
}

export type CustomQuota = {
  __typename?: 'CustomQuota'
  customQuota?: Maybe<Scalars['Int']>
  maxLeavePaidDay?: Maybe<Scalars['Int']>
  workMonthExperience?: Maybe<Scalars['Int']>
}

export type DeleteApprove = {
  id: Scalars['ID']
}

export type DeleteApproveAttendance = {
  date: Scalars['AWSDate']
  employeeID: Scalars['ID']
}

export type DeleteCompensationInput = {
  id: Scalars['ID']
}

export type DeleteDepartmentInput = {
  id: Scalars['ID']
}

export type DeleteEmployeeInput = {
  id: Scalars['ID']
}

export type DeleteLeaveConfig = {
  id: Scalars['ID']
}

export type DeleteLeaveInput = {
  createdByEmployeeID?: InputMaybe<Scalars['ID']>
  employeeID?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
}

export type DeleteOvertimeInput = {
  createdByEmployeeID?: InputMaybe<Scalars['ID']>
  employeeID?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
}

export type DeletePositionInput = {
  id: Scalars['ID']
}

export type DeleteRequestInput = {
  employeeID: Scalars['ID']
  id: Scalars['ID']
}

export type DeleteShiftInput = {
  shiftID: Scalars['ID']
}

export type DeleteTeamInput = {
  id: Scalars['ID']
}

export type DeleteTimeAttendanceReportInput = {
  Type: ETimeAttendanceReportType
  date: Scalars['AWSDate']
  employeeID: Scalars['ID']
}

export type Department = {
  __typename?: 'Department'
  departmentDetail?: Maybe<Scalars['String']>
  departmentName: Scalars['String']
  id: Scalars['ID']
  listEmployee?: Maybe<EmployeeQuery>
  regulationCalendar?: Maybe<Array<Maybe<ERegulationCalendar>>>
  regulationLeave?: Maybe<Array<Maybe<ERegulationLeave>>>
  regulationOverTime?: Maybe<Array<Maybe<ERegulationOverTime>>>
  regulationWorkAttendance?: Maybe<Array<Maybe<ERegulationWorkAttendance>>>
}

export type DepartmentQuery = {
  __typename?: 'DepartmentQuery'
  items: Array<Department>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type DownloadOptional = {
  documentType: EDocumentType
  employeeID?: InputMaybe<Scalars['ID']>
  endedAt?: InputMaybe<Scalars['AWSDate']>
  period?: InputMaybe<Scalars['String']>
  startedAt?: InputMaybe<Scalars['AWSDate']>
  year?: InputMaybe<Scalars['String']>
  yearMonth?: InputMaybe<Scalars['String']>
}

export enum E40Type {
  I401 = 'I401',
  I402 = 'I402',
  I403 = 'I403',
}

export enum EAction {
  Add = 'ADD',
  Delete = 'DELETE',
}

export enum EApproveAttendanceStatus {
  Draft = 'DRAFT',
  Success = 'SUCCESS',
}

export enum EApproveDocumentType {
  LeaveReqest = 'LEAVE_REQEST',
  OtRequest = 'OT_REQUEST',
  WelfareRequest = 'WELFARE_REQUEST',
  WithdrawRequest = 'WITHDRAW_REQUEST',
}

export enum EApproveFlowType {
  Custom = 'CUSTOM',
  Head = 'HEAD',
  None = 'NONE',
  Tree = 'TREE',
}

export enum EApproveStatus {
  Approve = 'APPROVE',
  Pending = 'PENDING',
  Reject = 'REJECT',
}

export enum EApproveStepType {
  Employee = 'EMPLOYEE',
  Head = 'HEAD',
  Level = 'LEVEL',
  Position = 'POSITION',
}

export enum EApproveType {
  ApproveCompensation = 'APPROVE_COMPENSATION',
  ApproveLeave = 'APPROVE_LEAVE',
  ApproveOvertime = 'APPROVE_OVERTIME',
  ApprovePayroll = 'APPROVE_PAYROLL',
}

export enum EAttendanceQuotaType {
  MinutePerDay = 'MINUTE_PER_DAY',
  MinutePerMonth = 'MINUTE_PER_MONTH',
}

export enum EAttendanceRangeType {
  NotExceed = 'NOT_EXCEED',
  OnTime = 'ON_TIME',
}

export enum EBankProviderThailand {
  Anz = 'ANZ',
  Chinese = 'CHINESE',
  Cimb = 'CIMB',
  Icbc = 'ICBC',
  Kasikorn = 'KASIKORN',
  KietNakin = 'KIET_NAKIN',
  Krungsri = 'KRUNGSRI',
  Krungthai = 'KRUNGTHAI',
  Krungthep = 'KRUNGTHEP',
  LandAndHouse = 'LAND_AND_HOUSE',
  MegePanich = 'MEGE_PANICH',
  StandardChadter = 'STANDARD_CHADTER',
  SumitomoMitsuiTrust = 'SUMITOMO_MITSUI_TRUST',
  ThaiCredit = 'THAI_CREDIT',
  ThaiPanich = 'THAI_PANICH',
  Thanachat = 'THANACHAT',
  Tisco = 'TISCO',
  Uob = 'UOB',
}

export enum ECompensationType {
  Company = 'COMPANY',
  Food = 'FOOD',
  Other = 'OTHER',
  RoadDrive = 'ROAD_DRIVE',
  RoadHightway = 'ROAD_HIGHTWAY',
  Snack = 'SNACK',
}

export enum ECompoundType {
  Full = 'FULL',
  Half = 'HALF',
}

export enum EDayType {
  Holiday = 'HOLIDAY',
  Weekend = 'WEEKEND',
  Workday = 'WORKDAY',
}

export enum EDeductType {
  Baht = 'BAHT',
  Multiple = 'MULTIPLE',
}

export enum EDocumentStatus {
  Approve = 'APPROVE',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Reject = 'REJECT',
}

export enum EDocumentType {
  EmployeeAllReportDoc = 'EMPLOYEE_ALL_REPORT_DOC',
  EmployeeLukjangReportDoc = 'EMPLOYEE_LUKJANG_REPORT_DOC',
  EmployeeNewReportDoc = 'EMPLOYEE_NEW_REPORT_DOC',
  PayrollSalaryPermonth = 'PAYROLL_SALARY_PERMONTH',
  PayrollSalarySlip = 'PAYROLL_SALARY_SLIP',
  RevenueFiftyThavi = 'REVENUE_FIFTY_THAVI',
  RevenueGorthorTwentyGor = 'REVENUE_GORTHOR_TWENTY_GOR',
  RevenuePorgnordorOneGor = 'REVENUE_PORGNORDOR_ONE_GOR',
  TimeAttendanceLeaveLateDoc = 'TIME_ATTENDANCE_LEAVE_LATE_DOC',
  TimeAttendanceLogDoc = 'TIME_ATTENDANCE_LOG_DOC',
  TimeAttendanceReportDoc = 'TIME_ATTENDANCE_REPORT_DOC',
}

export enum EEmployeePeriod {
  Full = 'FULL',
  Period = 'PERIOD',
}

export enum EEmployeeType {
  FulltimeDay = 'FULLTIME_DAY',
  FulltimeMonth = 'FULLTIME_MONTH',
  Outsource = 'OUTSOURCE',
  Parttime = 'PARTTIME',
}

export enum EEmployeeWorkStatus {
  Employee = 'EMPLOYEE',
  Probation = 'PROBATION',
  Retired = 'RETIRED',
}

export enum EEmployeeWorkType {
  ShiftWork = 'SHIFT_WORK',
  TimeAttendance = 'TIME_ATTENDANCE',
}

export enum EFundDeductContribution {
  Baht = 'BAHT',
  Percentage = 'PERCENTAGE',
}

export enum EHolidayLink {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
  Department = 'DEPARTMENT',
}

export enum EIncomeExpenseName {
  Travel = 'TRAVEL',
}

export enum EIncomeExpenseType {
  Auto = 'AUTO',
  Expense = 'EXPENSE',
  Fund = 'FUND',
  Income = 'INCOME',
  Welfare = 'WELFARE',
}

export enum EIncomeOrExpense {
  Expense = 'EXPENSE',
  Income = 'INCOME',
}

export enum ELeaveAmountType {
  PerYear = 'PER_YEAR',
  WorkLife = 'WORK_LIFE',
}

export enum ELeaveConfigType {
  ByCompany = 'BY_COMPANY',
  ByLaw = 'BY_LAW',
}

export enum ELeaveForm {
  CustomTime = 'CUSTOM_TIME',
  FulldayLeave = 'FULLDAY_LEAVE',
  HalfdayAfternoonLeave = 'HALFDAY_AFTERNOON_LEAVE',
  HalfdayMorningLeave = 'HALFDAY_MORNING_LEAVE',
}

export enum ELeaveHalfDay {
  HalfdayAfternoonLeave = 'HALFDAY_AFTERNOON_LEAVE',
  HalfdayMorningLeave = 'HALFDAY_MORNING_LEAVE',
}

export enum ELeaveReportType {
  Absent = 'ABSENT',
  ReasonAnnualLeave = 'REASON_ANNUAL_LEAVE',
  ReasonPersonalLeave = 'REASON_PERSONAL_LEAVE',
  ReasonSick = 'REASON_SICK',
  SuspendedJob = 'SUSPENDED_JOB',
}

export enum ELeaveStatus {
  Done = 'DONE',
  Pending = 'PENDING',
}

export enum ELeaveType {
  Absent = 'ABSENT',
  ReasonAnnualLeave = 'REASON_ANNUAL_LEAVE',
  ReasonPersonalLeave = 'REASON_PERSONAL_LEAVE',
  ReasonPersonalLeaveWithoutDeductMoney = 'REASON_PERSONAL_LEAVE_WITHOUT_DEDUCT_MONEY',
  ReasonSick = 'REASON_SICK',
  SuspendedJob = 'SUSPENDED_JOB',
}

export enum ELocationType {
  House = 'HOUSE',
  Office = 'OFFICE',
  Offsite = 'OFFSITE',
}

export enum ENameTitle {
  Miss = 'MISS',
  Mr = 'MR',
  Mrs = 'MRS',
}

export enum ENationality {
  Burmese = 'BURMESE',
  Cambodian = 'CAMBODIAN',
  Thai = 'THAI',
}

export enum EOverTimeMultiplyType {
  BahtPerHour = 'BAHT_PER_HOUR',
  BahtPerMinute = 'BAHT_PER_MINUTE',
  MultiplyPerWage = 'MULTIPLY_PER_WAGE',
}

export enum EOverTimeRoundFloor {
  Floor = 'FLOOR',
  Round = 'ROUND',
}

export enum EOverTimeRoundType {
  NotRound = 'NOT_ROUND',
  Round = 'ROUND',
}

export enum EOverTimeType {
  Overtime = 'OVERTIME',
  Work = 'WORK',
}

export enum EOvertimeHourHalf {
  FullHour = 'FULL_HOUR',
  HalfHour = 'HALF_HOUR',
}

export enum EPaidType {
  Baht = 'BAHT',
  Multiple = 'MULTIPLE',
}

export enum EPasswordType {
  CustomPassword = 'CUSTOM_PASSWORD',
  RandomPassword = 'RANDOM_PASSWORD',
}

export enum EPaymentMethod {
  Bank = 'BANK',
  Cash = 'CASH',
}

export enum EPaymentStatus {
  Paid = 'PAID',
  Pending = 'PENDING',
}

export enum EPaymentType {
  Other = 'OTHER',
  Payroll = 'PAYROLL',
}

export enum EPayrollStatus {
  Paid = 'PAID',
  Pending = 'PENDING',
}

export enum EPeriodCount {
  Four = 'FOUR',
  One = 'ONE',
  Three = 'THREE',
  Two = 'TWO',
}

export enum EPersonalRelation {
  Divorce = 'DIVORCE',
  Married = 'MARRIED',
  Single = 'SINGLE',
}

export enum EPlaceType {
  Office = 'OFFICE',
  Offsite = 'OFFSITE',
}

export enum EQuotaType {
  BreakLate = 'BREAK_LATE',
  InLate = 'IN_LATE',
  Leave = 'LEAVE',
  OutEarly = 'OUT_EARLY',
  Welfare = 'WELFARE',
}

export enum ERegulationCalendar {
  ShiftA = 'SHIFT_A',
  ShiftB = 'SHIFT_B',
  ShiftC = 'SHIFT_C',
}

export enum ERegulationLeave {
  ReasonAnnualLeave = 'REASON_ANNUAL_LEAVE',
  ReasonPersonalLeave = 'REASON_PERSONAL_LEAVE',
  ReasonSick = 'REASON_SICK',
}

export enum ERegulationOverTime {
  OtA = 'OT_A',
  OtB = 'OT_B',
  OtC = 'OT_C',
}

export enum ERegulationWorkAttendance {
  ShiftA = 'SHIFT_A',
  ShiftB = 'SHIFT_B',
  ShiftC = 'SHIFT_C',
}

export enum ERoundType {
  Floor = 'FLOOR',
  None = 'NONE',
  Round = 'ROUND',
}

export enum ESalaryOptional {
  JdCalculate = 'JD_CALCULATE',
  Stable = 'STABLE',
}

export enum ESalaryUom {
  Day = 'DAY',
  Month = 'MONTH',
  Project = 'PROJECT',
}

export enum EScanReasonType {
  ScanIn = 'SCAN_IN',
  ScanOut = 'SCAN_OUT',
}

export enum EScanType {
  Beacon = 'BEACON',
  FingerPrint = 'FINGER_PRINT',
  QrCode = 'QR_CODE',
  Web = 'WEB',
}

export enum ESetShiftWorking {
  Disable = 'DISABLE',
  Enable = 'ENABLE',
}

export enum ESex {
  Female = 'FEMALE',
  Male = 'MALE',
}

export enum EShiftLink {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
  Department = 'DEPARTMENT',
  Planning = 'PLANNING',
  Team = 'TEAM',
}

export enum EShiftWorkType {
  Hour = 'HOUR',
  SameDay = 'SAME_DAY',
  TodayTomorrow = 'TODAY_TOMORROW',
  YesterdayToday = 'YESTERDAY_TODAY',
}

export enum ESocialSecurity {
  ForeignIdcard = 'FOREIGN_IDCARD',
  License = 'LICENSE',
  PersonalIdcard = 'PERSONAL_IDCARD',
}

export enum ESocialSecurityCalculate {
  BaseOnSalary = 'BASE_ON_SALARY',
  NotBaseOn = 'NOT_BASE_ON',
}

export enum EStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum EThaiProvinces {
  AmnatCharoen = 'AMNAT_CHAROEN',
  AngThong = 'ANG_THONG',
  Bangkok = 'BANGKOK',
  Buogkan = 'BUOGKAN',
  BuriRam = 'BURI_RAM',
  Chachoengsao = 'CHACHOENGSAO',
  Chaiyaphum = 'CHAIYAPHUM',
  ChaiNat = 'CHAI_NAT',
  Chanthaburi = 'CHANTHABURI',
  ChiangMai = 'CHIANG_MAI',
  ChiangRai = 'CHIANG_RAI',
  ChonBuri = 'CHON_BURI',
  Chumphon = 'CHUMPHON',
  Kalasin = 'KALASIN',
  KamphaengPhet = 'KAMPHAENG_PHET',
  Kanchanaburi = 'KANCHANABURI',
  KhonKaen = 'KHON_KAEN',
  Krabi = 'KRABI',
  Lampang = 'LAMPANG',
  Lamphun = 'LAMPHUN',
  Loburi = 'LOBURI',
  Loei = 'LOEI',
  MaeHongSon = 'MAE_HONG_SON',
  MahaSarakham = 'MAHA_SARAKHAM',
  Mukdahan = 'MUKDAHAN',
  NakhonNayok = 'NAKHON_NAYOK',
  NakhonPathom = 'NAKHON_PATHOM',
  NakhonPhanom = 'NAKHON_PHANOM',
  NakhonRatchasima = 'NAKHON_RATCHASIMA',
  NakhonSawan = 'NAKHON_SAWAN',
  NakhonSiThammarat = 'NAKHON_SI_THAMMARAT',
  Nan = 'NAN',
  Narathiwat = 'NARATHIWAT',
  NongBuaLamPhu = 'NONG_BUA_LAM_PHU',
  NongKhai = 'NONG_KHAI',
  Nonthaburi = 'NONTHABURI',
  PathumThani = 'PATHUM_THANI',
  Pattani = 'PATTANI',
  Phangnga = 'PHANGNGA',
  Phatthalung = 'PHATTHALUNG',
  Phayao = 'PHAYAO',
  Phetchabun = 'PHETCHABUN',
  Phetchaburi = 'PHETCHABURI',
  Phichit = 'PHICHIT',
  Phitsanulok = 'PHITSANULOK',
  Phrae = 'PHRAE',
  PhraNakhonSiAyutthaya = 'PHRA_NAKHON_SI_AYUTTHAYA',
  Phuket = 'PHUKET',
  PrachinBuri = 'PRACHIN_BURI',
  PrachuapKhiriKhan = 'PRACHUAP_KHIRI_KHAN',
  Ranong = 'RANONG',
  Ratchaburi = 'RATCHABURI',
  Rayong = 'RAYONG',
  RoiEt = 'ROI_ET',
  SakonNakhon = 'SAKON_NAKHON',
  SamutPrakan = 'SAMUT_PRAKAN',
  SamutSakhon = 'SAMUT_SAKHON',
  SamutSongkhram = 'SAMUT_SONGKHRAM',
  Saraburi = 'SARABURI',
  Satun = 'SATUN',
  SaKaeo = 'SA_KAEO',
  SingBuri = 'SING_BURI',
  SiSaKet = 'SI_SA_KET',
  Songkhla = 'SONGKHLA',
  Sukhothai = 'SUKHOTHAI',
  SuphanBuri = 'SUPHAN_BURI',
  SuratThani = 'SURAT_THANI',
  Surin = 'SURIN',
  Tak = 'TAK',
  Trang = 'TRANG',
  Trat = 'TRAT',
  UbonRatchathani = 'UBON_RATCHATHANI',
  UdonThani = 'UDON_THANI',
  UthaiThani = 'UTHAI_THANI',
  Uttaradit = 'UTTARADIT',
  Yala = 'YALA',
  Yasothon = 'YASOTHON',
}

export enum ETimeAttendanceReportType {
  Attendance = 'ATTENDANCE',
  Holiday = 'HOLIDAY',
  Incomplete = 'INCOMPLETE',
  Leave = 'LEAVE',
  Null = 'NULL',
  Overtime = 'OVERTIME',
  Weekend = 'WEEKEND',
}

export enum ETopic {
  Leave = 'LEAVE',
  Overtime = 'OVERTIME',
}

export enum EVariableOrFixed {
  Fixed = 'FIXED',
  Variable = 'VARIABLE',
}

export enum EWelfareQuotaUnit {
  BahtPerDay = 'BAHT_PER_DAY',
  BahtPerMonth = 'BAHT_PER_MONTH',
  BahtPerYear = 'BAHT_PER_YEAR',
}

export enum EWorkingDayCount {
  Actual = 'ACTUAL',
  N30 = 'N30',
}

export enum EWorkingHourDivisor {
  N7 = 'N7',
  N8 = 'N8',
  N9 = 'N9',
  N85 = 'N85',
}

export type Employee = {
  __typename?: 'Employee'
  addressCurrent?: Maybe<FAddressPersonalId>
  addressPersonalID?: Maybe<FAddressPersonalId>
  bankAccountId?: Maybe<Scalars['ID']>
  bankAccountName?: Maybe<EBankProviderThailand>
  bankPayment?: Maybe<EBankProviderThailand>
  birthDate?: Maybe<Scalars['AWSDate']>
  department?: Maybe<Department>
  departmentID: Scalars['ID']
  diligentAllowance?: Maybe<Scalars['Boolean']>
  educationHighest?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  employeeFund?: Maybe<Array<Maybe<FEmployeeFund>>>
  employeeHandicappedAndAgeOut?: Maybe<Scalars['Boolean']>
  employeePeriod?: Maybe<EEmployeePeriod>
  employeeType?: Maybe<EEmployeeType>
  employeeWorkStatus?: Maybe<EEmployeeWorkStatus>
  ethnicity?: Maybe<Scalars['String']>
  fingerPrintId?: Maybe<Scalars['ID']>
  fullName?: Maybe<Scalars['String']>
  fullNameEnglish?: Maybe<Scalars['String']>
  getHolidayID?: Maybe<Scalars['ID']>
  hireDate?: Maybe<Scalars['AWSDate']>
  historyEducation?: Maybe<Array<Maybe<FHistoryEducation>>>
  historyFamily?: Maybe<Array<Maybe<FHistoryFamily>>>
  historyWork?: Maybe<Array<Maybe<FHistoryWork>>>
  holidayID?: Maybe<Scalars['ID']>
  holidayLink?: Maybe<EHolidayLink>
  hospitalService?: Maybe<Scalars['String']>
  id: Scalars['ID']
  incomeExpense?: Maybe<Array<Maybe<FIncomeExpense>>>
  isTimeAttendance?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  lastNameEnglish?: Maybe<Scalars['String']>
  leaderTeam?: Maybe<Scalars['ID']>
  lineId?: Maybe<Scalars['String']>
  lineUserId?: Maybe<Scalars['ID']>
  listLeaderTeam?: Maybe<TeamIdQuery>
  listLeave?: Maybe<LeaveQuery>
  listOvertime?: Maybe<OvertimeQuery>
  listTeam?: Maybe<TeamIdQuery>
  listTimeAttendance?: Maybe<TimeAttendanceQuery>
  listTimeAttendanceReport?: Maybe<TimeAttendanceReportQuery>
  middleName?: Maybe<Scalars['String']>
  middleNameEnglish?: Maybe<Scalars['String']>
  nameTitle?: Maybe<ENameTitle>
  nationality?: Maybe<Scalars['String']>
  nickName?: Maybe<Scalars['String']>
  packageGroupID?: Maybe<Scalars['ID']>
  password?: Maybe<Scalars['String']>
  paymentMethod?: Maybe<EPaymentMethod>
  personalEducationalAttachment?: Maybe<Scalars['String']>
  personalHouseAttachment?: Maybe<Scalars['String']>
  personalID?: Maybe<Scalars['ID']>
  personalIDNumber?: Maybe<Scalars['ID']>
  personalIdAttachment?: Maybe<Scalars['String']>
  personalOptionalAttachment?: Maybe<Scalars['String']>
  personalRelation?: Maybe<EPersonalRelation>
  personalTalent?: Maybe<Array<Maybe<Scalars['String']>>>
  personalTrainningAttachment?: Maybe<Scalars['String']>
  position?: Maybe<Position>
  positionID: Scalars['ID']
  profileImage?: Maybe<Scalars['String']>
  religion?: Maybe<Scalars['String']>
  retireDate?: Maybe<Scalars['AWSDate']>
  salary?: Maybe<Scalars['Float']>
  salaryOptional?: Maybe<ESalaryOptional>
  salaryUOM?: Maybe<ESalaryUom>
  setShiftWorking?: Maybe<ESetShiftWorking>
  sex?: Maybe<ESex>
  shiftWorkingID?: Maybe<Scalars['ID']>
  socialSecurity?: Maybe<ESocialSecurity>
  socialSecurityCalculate?: Maybe<ESocialSecurityCalculate>
  status?: Maybe<EStatus>
  taxDeductOne?: Maybe<Array<Maybe<FTaxDeductOne>>>
  taxDeductTwo?: Maybe<Array<Maybe<FTaxDeductTwo>>>
  teamList?: Maybe<Scalars['ID']>
  tel?: Maybe<Scalars['String']>
  unusedLatestSalary?: Maybe<Scalars['Float']>
  username?: Maybe<Scalars['String']>
  workPlace?: Maybe<EPlaceType>
}

export type EmployeeId = {
  __typename?: 'EmployeeID'
  getEmployee?: Maybe<Employee>
  id: Scalars['ID']
}

export type EmployeeIdQuery = {
  __typename?: 'EmployeeIDQuery'
  items: Array<EmployeeId>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type EmployeePayroll = {
  __typename?: 'EmployeePayroll'
  breakEarlyIncome?: Maybe<Scalars['Float']>
  breakLateExpense?: Maybe<Scalars['Float']>
  daysInPeriod?: Maybe<Scalars['Int']>
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  employeePeriod?: Maybe<Scalars['String']>
  inEarlyIncome?: Maybe<Scalars['Float']>
  inLateExpense?: Maybe<Scalars['Float']>
  income?: Maybe<Scalars['Float']>
  leaveDeductMinute?: Maybe<Scalars['Int']>
  leaveMinute?: Maybe<Scalars['Int']>
  netIncome?: Maybe<Scalars['Float']>
  netNegativeIncome?: Maybe<Scalars['Float']>
  netPositiveIncome?: Maybe<Scalars['Float']>
  otTypeOTIncome?: Maybe<Scalars['Float']>
  otTypeOTMinute?: Maybe<Scalars['Int']>
  otTypeWorkIncome?: Maybe<Scalars['Float']>
  otTypeWorkMinute?: Maybe<Scalars['Int']>
  otherExpenseList?: Maybe<Array<OtherIncomeOrExpense>>
  otherIncomeList?: Maybe<Array<OtherIncomeOrExpense>>
  outEarlyExpense?: Maybe<Scalars['Float']>
  outLateIncome?: Maybe<Scalars['Float']>
  paySlipURL?: Maybe<Scalars['String']>
  paymentID?: Maybe<Scalars['ID']>
  welfareList?: Maybe<Array<WelfareItem>>
  workDiligentIncome?: Maybe<Scalars['Float']>
  workingMinute?: Maybe<Scalars['Int']>
}

export type EmployeeQuery = {
  __typename?: 'EmployeeQuery'
  items: Array<Employee>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type EmployeeQuota = {
  __typename?: 'EmployeeQuota'
  approved?: Maybe<Scalars['Int']>
  balance?: Maybe<Scalars['Int']>
  begin?: Maybe<Scalars['Int']>
  companyID: Scalars['ID']
  employeeID: Scalars['ID']
  packageID: Scalars['ID']
  period: Scalars['String']
  prev?: Maybe<Scalars['Int']>
  quotaType: EQuotaType
  used?: Maybe<Scalars['Int']>
}

export type FAddressPersonalId = {
  __typename?: 'FAddressPersonalID'
  addressNumber?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  group?: Maybe<Scalars['String']>
  optional?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  subDistrict?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type FEmployeeFund = {
  __typename?: 'FEmployeeFund'
  beneficiary?: Maybe<Scalars['String']>
  contractDate?: Maybe<Scalars['AWSDate']>
  contributionCompany?: Maybe<Scalars['String']>
  contributionMethod?: Maybe<EFundDeductContribution>
  contributionTotal?: Maybe<Scalars['Float']>
  deductMethod?: Maybe<EFundDeductContribution>
  fundNumber?: Maybe<Scalars['Int']>
  fundRate?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
}

export type FHistoryEducation = {
  __typename?: 'FHistoryEducation'
  educationLevel?: Maybe<Scalars['String']>
  educationName?: Maybe<Scalars['String']>
  endedYear?: Maybe<Scalars['String']>
  majorName?: Maybe<Scalars['String']>
  startedYear?: Maybe<Scalars['String']>
}

export type FHistoryFamily = {
  __typename?: 'FHistoryFamily'
  age?: Maybe<Scalars['Int']>
  fullName?: Maybe<Scalars['String']>
  job?: Maybe<Scalars['String']>
  relation?: Maybe<Scalars['String']>
}

export type FHistoryWork = {
  __typename?: 'FHistoryWork'
  descPosition?: Maybe<Scalars['String']>
  endedAt?: Maybe<Scalars['AWSDate']>
  leaveReason?: Maybe<Scalars['String']>
  positionName?: Maybe<Scalars['String']>
  salary?: Maybe<Scalars['Float']>
  startedAt?: Maybe<Scalars['AWSDate']>
  workName?: Maybe<Scalars['String']>
}

export type FIncomeExpense = {
  __typename?: 'FIncomeExpense'
  incomeExpenseType?: Maybe<EIncomeExpenseType>
  name?: Maybe<EIncomeExpenseName>
  total?: Maybe<Scalars['Float']>
  unit?: Maybe<EWelfareQuotaUnit>
}

export type FTaxDeductOne = {
  __typename?: 'FTaxDeductOne'
  month?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['Float']>
  year?: Maybe<Scalars['String']>
}

export type FTaxDeductTwo = {
  __typename?: 'FTaxDeductTwo'
  name?: Maybe<Scalars['String']>
  rateDeduct?: Maybe<Scalars['Float']>
  totalDeduct?: Maybe<Scalars['Float']>
}

export type GetShareCalendarInput = {
  filter: ShareCalendarFilterInput
  month: Scalars['Int']
  year: Scalars['Int']
}

export type GetShiftPlanInput = {
  week: Scalars['Int']
  year: Scalars['Int']
}

export type Holiday = {
  __typename?: 'Holiday'
  holidayID: Scalars['ID']
  holidayName?: Maybe<Scalars['String']>
  holidayYear?: Maybe<Scalars['AWSJSON']>
}

export type HolidayQuery = {
  __typename?: 'HolidayQuery'
  getDefaultHoliday?: Maybe<Scalars['ID']>
  items: Array<Holiday>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type HolidayYearInput = {
  date?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
}

export type Leave = {
  __typename?: 'Leave'
  createdAt?: Maybe<Scalars['AWSDateTime']>
  createdByEmployeeID?: Maybe<Scalars['ID']>
  date?: Maybe<Scalars['AWSDate']>
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  endedAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  isLeaveDeductMoney?: Maybe<Scalars['Boolean']>
  leaveDocumentAttachment?: Maybe<Scalars['String']>
  leaveForm?: Maybe<ELeaveForm>
  leaveFormHalfDay?: Maybe<ELeaveHalfDay>
  leaveFormMinute?: Maybe<Scalars['Int']>
  leaveType?: Maybe<ELeaveReportType>
  remark?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['String']>
  status?: Maybe<EDocumentStatus>
  topic?: Maybe<ETopic>
}

export type LeaveConfig = {
  __typename?: 'LeaveConfig'
  LeaveBeforehandDay?: Maybe<Scalars['Int']>
  employeeAllowSexList?: Maybe<Array<Maybe<ESex>>>
  employeeAllowTypeList?: Maybe<Array<Maybe<EEmployeeType>>>
  id?: Maybe<Scalars['ID']>
  isActive?: Maybe<Scalars['Boolean']>
  isCountProbation?: Maybe<Scalars['Boolean']>
  isExceedQuota?: Maybe<Scalars['Boolean']>
  isLeaveAfterhand?: Maybe<Scalars['Boolean']>
  isLeaveBeforehand?: Maybe<Scalars['Boolean']>
  isLeaveCompound?: Maybe<Scalars['Boolean']>
  isLeaveDiligent?: Maybe<Scalars['Boolean']>
  isLeaveOnce?: Maybe<Scalars['Boolean']>
  isLookupNotWorkday?: Maybe<Scalars['Boolean']>
  leaveAmountType?: Maybe<ELeaveAmountType>
  leaveCompoundType?: Maybe<ECompoundType>
  leaveConfigType?: Maybe<ELeaveConfigType>
  leaveQuotaList?: Maybe<Array<Maybe<LeaveQuota>>>
  maxLeaveCompound?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type LeaveConfigId = {
  __typename?: 'LeaveConfigID'
  customQuota?: Maybe<Scalars['Int']>
  customQuotaList?: Maybe<Array<CustomQuota>>
  isCustom: Scalars['Boolean']
  leaveConfig?: Maybe<LeaveConfig>
  leaveConfigID: Scalars['ID']
}

export type LeaveConfigQuery = {
  __typename?: 'LeaveConfigQuery'
  items: Array<LeaveConfig>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type LeaveGroupByEmployeeQuery = {
  __typename?: 'LeaveGroupByEmployeeQuery'
  items: Array<LeaveResponse>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type LeaveQuery = {
  __typename?: 'LeaveQuery'
  items: Array<Leave>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type LeaveQuota = {
  __typename?: 'LeaveQuota'
  maxLeavePaidDay?: Maybe<Scalars['Int']>
  quotaLeaveDay?: Maybe<Scalars['Int']>
  workMonthExperience?: Maybe<Scalars['Int']>
}

export type LeaveReport = {
  __typename?: 'LeaveReport'
  endedAt?: Maybe<Scalars['AWSDateTime']>
  leaveDocumentAttachment?: Maybe<Scalars['String']>
  leaveForm?: Maybe<ELeaveForm>
  leaveMinute?: Maybe<Scalars['Int']>
  leaveRequestID?: Maybe<Scalars['ID']>
  leaveType?: Maybe<ELeaveReportType>
  startedAt?: Maybe<Scalars['AWSDateTime']>
  status?: Maybe<EDocumentStatus>
}

export type LeaveReportInput = {
  endedAt?: InputMaybe<Scalars['AWSDateTime']>
  leaveDocumentAttachment?: InputMaybe<Scalars['String']>
  leaveForm?: InputMaybe<ELeaveForm>
  leaveMinute?: InputMaybe<Scalars['Int']>
  leaveRequestID?: InputMaybe<Scalars['ID']>
  leaveType?: InputMaybe<ELeaveReportType>
  startedAt?: InputMaybe<Scalars['AWSDateTime']>
  status?: InputMaybe<EDocumentStatus>
}

export type LeaveResponse = {
  __typename?: 'LeaveResponse'
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  items?: Maybe<Array<Leave>>
}

export type ModelApproveAttendanceConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceReportConditionInput>>>
  employeeID?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelTimeAttendanceReportConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceReportConditionInput>>>
}

export type ModelApproveAttendanceFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceReportFilterInput>>>
  date?: InputMaybe<ModelStringInput>
  employeeID?: InputMaybe<ModelIdInput>
  endedAt?: InputMaybe<ModelStringInput>
  fullName?: InputMaybe<ModelStringInput>
  lastName?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelTimeAttendanceReportFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceReportFilterInput>>>
  startedAt?: InputMaybe<ModelStringInput>
  status?: InputMaybe<ModelStringInput>
}

export type ModelApproveConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelApproveConditionInput>>>
  employeeID?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelApproveConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelApproveConditionInput>>>
  status?: InputMaybe<ModelStringInput>
}

export type ModelApproveFilterInput = {
  Type?: InputMaybe<ModelStringInput>
  and?: InputMaybe<Array<InputMaybe<ModelApproveFilterInput>>>
  employeeID?: InputMaybe<ModelIdInput>
  id?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelApproveFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelApproveFilterInput>>>
  status?: InputMaybe<ModelStringInput>
}

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet',
}

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  eq?: InputMaybe<Scalars['Boolean']>
  ne?: InputMaybe<Scalars['Boolean']>
}

export type ModelCompensationConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelCompensationFilterInput>>>
  not?: InputMaybe<ModelCompensationFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelCompensationFilterInput>>>
}

export type ModelCompensationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelCompensationFilterInput>>>
  not?: InputMaybe<ModelCompensationFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelCompensationFilterInput>>>
}

export type ModelDepartmentConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelDepartmentConditionInput>>>
  departmentName?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelDepartmentConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelDepartmentConditionInput>>>
}

export type ModelDepartmentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelDepartmentFilterInput>>>
  departmentName?: InputMaybe<ModelStringInput>
  id?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelDepartmentFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelDepartmentFilterInput>>>
}

export type ModelEmployeeConditionInput = {
  age?: InputMaybe<ModelIntInput>
  and?: InputMaybe<Array<InputMaybe<ModelEmployeeConditionInput>>>
  name?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelEmployeeConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelEmployeeConditionInput>>>
}

export type ModelEmployeeFilterInput = {
  age?: InputMaybe<ModelIntInput>
  and?: InputMaybe<Array<InputMaybe<ModelEmployeeFilterInput>>>
  fullName?: InputMaybe<ModelStringInput>
  id?: InputMaybe<ModelIdInput>
  lastName?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelEmployeeFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelEmployeeFilterInput>>>
  status?: InputMaybe<ModelStringInput>
}

export type ModelFloatInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  eq?: InputMaybe<Scalars['Float']>
  ge?: InputMaybe<Scalars['Float']>
  gt?: InputMaybe<Scalars['Float']>
  le?: InputMaybe<Scalars['Float']>
  lt?: InputMaybe<Scalars['Float']>
  ne?: InputMaybe<Scalars['Float']>
}

export type ModelHolidayConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelLeaveConditionInput>>>
  id?: InputMaybe<ModelIdInput>
  name?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelLeaveConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelLeaveConditionInput>>>
}

export type ModelHolidayFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelLeaveFilterInput>>>
  id?: InputMaybe<ModelIdInput>
  name?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelLeaveFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelLeaveFilterInput>>>
}

export type ModelIdInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  beginsWith?: InputMaybe<Scalars['ID']>
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  contains?: InputMaybe<Scalars['ID']>
  eq?: InputMaybe<Scalars['ID']>
  ge?: InputMaybe<Scalars['ID']>
  gt?: InputMaybe<Scalars['ID']>
  le?: InputMaybe<Scalars['ID']>
  lt?: InputMaybe<Scalars['ID']>
  ne?: InputMaybe<Scalars['ID']>
  notContains?: InputMaybe<Scalars['ID']>
  size?: InputMaybe<ModelSizeInput>
}

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  eq?: InputMaybe<Scalars['Int']>
  ge?: InputMaybe<Scalars['Int']>
  gt?: InputMaybe<Scalars['Int']>
  le?: InputMaybe<Scalars['Int']>
  lt?: InputMaybe<Scalars['Int']>
  ne?: InputMaybe<Scalars['Int']>
}

export type ModelLeaveConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelLeaveConditionInput>>>
  createdByEmployeeID?: InputMaybe<ModelStringInput>
  employeeID?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelLeaveConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelLeaveConditionInput>>>
  status?: InputMaybe<ModelStringInput>
}

export type ModelLeaveConfigConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelOvertimeConfigConditionInput>>>
  id?: InputMaybe<ModelIdInput>
  name?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelOvertimeConfigConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelOvertimeConfigConditionInput>>>
}

export type ModelLeaveConfigFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelOvertimeConfigFilterInput>>>
  id?: InputMaybe<ModelIdInput>
  name?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelOvertimeConfigFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelOvertimeConfigFilterInput>>>
}

export type ModelLeaveFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelLeaveFilterInput>>>
  createdByEmployeeID?: InputMaybe<ModelStringInput>
  date?: InputMaybe<ModelStringInput>
  endedAt?: InputMaybe<ModelStringInput>
  id?: InputMaybe<ModelIdInput>
  leaveForm?: InputMaybe<ModelStringInput>
  leaveType?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelLeaveFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelLeaveFilterInput>>>
  startedAt?: InputMaybe<ModelStringInput>
  status?: InputMaybe<ModelStringInput>
}

export type ModelOvertimeConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelOvertimeConditionInput>>>
  createdByEmployeeID?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelOvertimeConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelOvertimeConditionInput>>>
  status?: InputMaybe<ModelStringInput>
}

export type ModelOvertimeConfigConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelOvertimeConfigConditionInput>>>
  not?: InputMaybe<ModelOvertimeConfigConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelOvertimeConfigConditionInput>>>
  overtimeConfigID?: InputMaybe<ModelIdInput>
}

export type ModelOvertimeConfigFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelOvertimeConfigFilterInput>>>
  not?: InputMaybe<ModelOvertimeConfigFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelOvertimeConfigFilterInput>>>
  overtimeConfigID?: InputMaybe<ModelIdInput>
}

export type ModelOvertimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelOvertimeFilterInput>>>
  createdByEmployeeID?: InputMaybe<ModelStringInput>
  date?: InputMaybe<ModelStringInput>
  id?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelOvertimeFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelOvertimeFilterInput>>>
  overtimeType?: InputMaybe<ModelStringInput>
  status?: InputMaybe<ModelStringInput>
}

export type ModelPackageGroupConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTeamConditionInput>>>
  name?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelTeamConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelTeamConditionInput>>>
  packageGroupID?: InputMaybe<ModelIdInput>
}

export type ModelPackageGroupFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTeamFilterInput>>>
  name?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelTeamFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelTeamFilterInput>>>
  packageGroupID?: InputMaybe<ModelIdInput>
}

export type ModelPaymentConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPaymentConditionInput>>>
  not?: InputMaybe<ModelPaymentConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelPaymentConditionInput>>>
}

export type ModelPaymentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPaymentFilterInput>>>
  not?: InputMaybe<ModelPaymentFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelPaymentFilterInput>>>
}

export type ModelPayrollConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPayrollConditionInput>>>
  employeeID?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelPayrollConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelPayrollConditionInput>>>
}

export type ModelPayrollFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPayrollFilterInput>>>
  not?: InputMaybe<ModelPayrollFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelPayrollFilterInput>>>
}

export type ModelPositionConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPositionConditionInput>>>
  not?: InputMaybe<ModelPositionConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelPositionConditionInput>>>
  positionName?: InputMaybe<ModelStringInput>
}

export type ModelPositionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPositionFilterInput>>>
  id?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelPositionFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelPositionFilterInput>>>
  positionName?: InputMaybe<ModelStringInput>
}

export type ModelShiftConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelShiftConditionInput>>>
  not?: InputMaybe<ModelShiftConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelShiftConditionInput>>>
}

export type ModelShiftFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelShiftFilterInput>>>
  not?: InputMaybe<ModelShiftFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelShiftFilterInput>>>
}

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  eq?: InputMaybe<Scalars['Int']>
  ge?: InputMaybe<Scalars['Int']>
  gt?: InputMaybe<Scalars['Int']>
  le?: InputMaybe<Scalars['Int']>
  lt?: InputMaybe<Scalars['Int']>
  ne?: InputMaybe<Scalars['Int']>
}

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>
  attributeType?: InputMaybe<ModelAttributeTypes>
  beginsWith?: InputMaybe<Scalars['String']>
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contains?: InputMaybe<Scalars['String']>
  eq?: InputMaybe<Scalars['String']>
  ge?: InputMaybe<Scalars['String']>
  gt?: InputMaybe<Scalars['String']>
  le?: InputMaybe<Scalars['String']>
  lt?: InputMaybe<Scalars['String']>
  ne?: InputMaybe<Scalars['String']>
  notContains?: InputMaybe<Scalars['String']>
  size?: InputMaybe<ModelSizeInput>
}

export type ModelTeamConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTeamConditionInput>>>
  not?: InputMaybe<ModelTeamConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelTeamConditionInput>>>
  teamName?: InputMaybe<ModelStringInput>
}

export type ModelTeamFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTeamFilterInput>>>
  id?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelTeamFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelTeamFilterInput>>>
  teamName?: InputMaybe<ModelStringInput>
}

export type ModelTimeAttendanceConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceConditionInput>>>
  employeeID?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelTimeAttendanceConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceConditionInput>>>
  status?: InputMaybe<ModelStringInput>
  timeID?: InputMaybe<ModelIdInput>
}

export type ModelTimeAttendanceFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceFilterInput>>>
  employeeID?: InputMaybe<ModelStringInput>
  not?: InputMaybe<ModelTimeAttendanceFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceFilterInput>>>
  timeID?: InputMaybe<ModelIdInput>
}

export type ModelTimeAttendanceReportConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceReportConditionInput>>>
  employeeID?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelTimeAttendanceReportConditionInput>
  or?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceReportConditionInput>>>
}

export type ModelTimeAttendanceReportFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceReportFilterInput>>>
  date?: InputMaybe<ModelStringInput>
  employeeID?: InputMaybe<ModelIdInput>
  not?: InputMaybe<ModelTimeAttendanceReportFilterInput>
  or?: InputMaybe<Array<InputMaybe<ModelTimeAttendanceReportFilterInput>>>
}

export type Mutation = {
  __typename?: 'Mutation'
  addEmployeeToPackageGroup?: Maybe<Scalars['String']>
  adminRegister?: Maybe<Scalars['String']>
  closePayroll?: Maybe<PayrollQuery>
  createAdminLeave?: Maybe<Leave>
  createApproveAttendance?: Maybe<ApproveAttendance>
  createCompensation?: Maybe<Compensation>
  createDepartment2?: Maybe<Department>
  createEmployee2?: Maybe<Employee>
  createHolidayOnYear?: Maybe<Holiday>
  createLeave2?: Maybe<Leave>
  createLeaveConfig?: Maybe<LeaveConfig>
  createLeaveRequest?: Maybe<Leave>
  createOtherIncomeOrExpenseConfig?: Maybe<OtherIncomeOrExpenseConfigItem>
  createOvertime2?: Maybe<Overtime>
  createOvertimeConfig?: Maybe<OvertimeConfig>
  createOvertimeRequest?: Maybe<Overtime>
  createPackageGroup?: Maybe<PackageGroup>
  createPayroll?: Maybe<Scalars['String']>
  createPosition2?: Maybe<Position>
  createShift?: Maybe<Shift>
  createTeam2?: Maybe<Team>
  createTimeAttendance?: Maybe<TimeAttendance>
  createTimeAttendanceBatch?: Maybe<Scalars['String']>
  createTimeAttendanceReport?: Maybe<TimeAttendanceReport>
  deleteApprove?: Maybe<Approve>
  deleteApproveAttendance?: Maybe<ApproveAttendance>
  deleteCompensation?: Maybe<Compensation>
  deleteDepartment?: Maybe<Department>
  deleteEmployee2?: Maybe<Employee>
  deleteHoliday?: Maybe<Holiday>
  deleteLeave2?: Maybe<Leave>
  deleteLeaveConfig?: Maybe<LeaveConfig>
  deleteLeaveRequest?: Maybe<Leave>
  deleteOtherIncomeOrExpenseConfig?: Maybe<OtherIncomeOrExpenseConfigItem>
  deleteOvertime2?: Maybe<Overtime>
  deleteOvertimeRequest?: Maybe<Leave>
  deletePackageGroup?: Maybe<PackageGroup>
  deletePosition?: Maybe<Position>
  deleteShift?: Maybe<Shift>
  deleteTeam2?: Maybe<Team>
  downloadPDFDocument?: Maybe<Scalars['String']>
  login?: Maybe<Scalars['String']>
  mockResetQuotaTrigger?: Maybe<Scalars['String']>
  putEmployeePayroll?: Maybe<Scalars['String']>
  putPayrollConfig?: Maybe<PayrollConfig>
  putShiftColor?: Maybe<ShiftColor>
  putShiftPlan?: Maybe<ShiftPlan>
  putSocialSecurityConfig?: Maybe<SocialSecurityConfig>
  refreshToken?: Maybe<Scalars['String']>
  resetTimeAttendanceReport?: Maybe<Array<Maybe<TimeAttendanceReport>>>
  updateApprove?: Maybe<Approve>
  updateApproveAttendance?: Maybe<ApproveAttendance>
  updateApproveBatch?: Maybe<Scalars['String']>
  updateCompensation?: Maybe<Compensation>
  updateDefaultHoliday?: Maybe<Holiday>
  updateDefaultShift?: Maybe<Shift>
  updateDepartment?: Maybe<Department>
  updateEmployee2?: Maybe<Employee>
  updateHolidayOnYear?: Maybe<Holiday>
  updateLeave2?: Maybe<Leave>
  updateLeaveConfig?: Maybe<LeaveConfig>
  updateOtherIncomeOrExpenseConfig?: Maybe<OtherIncomeOrExpenseConfigItem>
  updateOvertime2?: Maybe<Overtime>
  updateOvertimeConfig?: Maybe<OvertimeConfig>
  updatePackageGroup?: Maybe<PackageGroup>
  updatePosition?: Maybe<Position>
  updateShift?: Maybe<Shift>
  updateTeam2?: Maybe<Team>
  updateTimeAttendanceReport?: Maybe<TimeAttendanceReport>
}

export type MutationAddEmployeeToPackageGroupArgs = {
  employeeID: Scalars['ID']
  packageGroupID: Scalars['ID']
}

export type MutationAdminRegisterArgs = {
  email?: InputMaybe<Scalars['String']>
  partyID: Scalars['String']
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationClosePayrollArgs = {
  closeDate?: InputMaybe<Scalars['String']>
  period: Scalars['String']
  yearMonth: Scalars['String']
}

export type MutationCreateAdminLeaveArgs = {
  condition?: InputMaybe<ModelLeaveConditionInput>
  input: CreateLeaveInput
}

export type MutationCreateApproveAttendanceArgs = {
  condition?: InputMaybe<ModelApproveAttendanceConditionInput>
  input: CreateApproveAttendance
}

export type MutationCreateCompensationArgs = {
  input: CreateCompensationInput
}

export type MutationCreateDepartment2Args = {
  condition?: InputMaybe<ModelDepartmentConditionInput>
  input: CreateDepartmentInput
}

export type MutationCreateEmployee2Args = {
  condition?: InputMaybe<ModelEmployeeConditionInput>
  input: CreateEmployeeInput
}

export type MutationCreateHolidayOnYearArgs = {
  input: CreateHolidayInput
  year: Scalars['Int']
}

export type MutationCreateLeave2Args = {
  condition?: InputMaybe<ModelLeaveConditionInput>
  input: CreateLeaveInput
}

export type MutationCreateLeaveConfigArgs = {
  condition?: InputMaybe<ModelLeaveConfigConditionInput>
  input: CreateLeaveConfig
}

export type MutationCreateLeaveRequestArgs = {
  condition?: InputMaybe<ModelLeaveConditionInput>
  input: CreateLeaveInput
}

export type MutationCreateOtherIncomeOrExpenseConfigArgs = {
  input: CreateOtherIncomeOrExpenseConfigInput
}

export type MutationCreateOvertime2Args = {
  condition?: InputMaybe<ModelOvertimeConditionInput>
  input: CreateOvertimeInput
}

export type MutationCreateOvertimeConfigArgs = {
  condition?: InputMaybe<ModelOvertimeConfigConditionInput>
  input: CreateOvertimeConfigInput
}

export type MutationCreateOvertimeRequestArgs = {
  condition?: InputMaybe<ModelOvertimeConditionInput>
  input: CreateOvertimeInput
}

export type MutationCreatePackageGroupArgs = {
  input: CreatePackageGroupInput
}

export type MutationCreatePayrollArgs = {
  yearMonth: Scalars['String']
}

export type MutationCreatePosition2Args = {
  condition?: InputMaybe<ModelPositionConditionInput>
  input: CreatePositionInput
}

export type MutationCreateShiftArgs = {
  condition?: InputMaybe<ModelShiftConditionInput>
  input: CreateShiftInput
}

export type MutationCreateTeam2Args = {
  condition?: InputMaybe<ModelTeamConditionInput>
  input: CreateTeamInput
}

export type MutationCreateTimeAttendanceArgs = {
  condition?: InputMaybe<ModelTimeAttendanceConditionInput>
  input: CreateTimeAttendanceInput
}

export type MutationCreateTimeAttendanceBatchArgs = {
  endDate: Scalars['AWSDate']
  input: CreateTimeAttendanceBatchInput
  startDate: Scalars['AWSDate']
}

export type MutationCreateTimeAttendanceReportArgs = {
  condition?: InputMaybe<ModelTimeAttendanceReportConditionInput>
  input: CreateTimeAttendanceReportInput
}

export type MutationDeleteApproveArgs = {
  condition?: InputMaybe<ModelApproveConditionInput>
  input: DeleteApprove
}

export type MutationDeleteApproveAttendanceArgs = {
  condition?: InputMaybe<ModelApproveAttendanceConditionInput>
  input: DeleteApproveAttendance
}

export type MutationDeleteCompensationArgs = {
  input: DeleteCompensationInput
}

export type MutationDeleteDepartmentArgs = {
  condition?: InputMaybe<ModelDepartmentConditionInput>
  input: DeleteDepartmentInput
}

export type MutationDeleteEmployee2Args = {
  condition?: InputMaybe<ModelEmployeeConditionInput>
  input: DeleteEmployeeInput
}

export type MutationDeleteHolidayArgs = {
  holidayID: Scalars['ID']
}

export type MutationDeleteLeave2Args = {
  condition?: InputMaybe<ModelLeaveConditionInput>
  input: DeleteLeaveInput
}

export type MutationDeleteLeaveConfigArgs = {
  input: DeleteLeaveConfig
}

export type MutationDeleteLeaveRequestArgs = {
  condition?: InputMaybe<ModelLeaveConditionInput>
  input: DeleteRequestInput
}

export type MutationDeleteOtherIncomeOrExpenseConfigArgs = {
  incomeExpenseID: Scalars['ID']
  incomeOrExpense: EIncomeOrExpense
}

export type MutationDeleteOvertime2Args = {
  condition?: InputMaybe<ModelOvertimeConditionInput>
  input: DeleteOvertimeInput
}

export type MutationDeleteOvertimeRequestArgs = {
  condition?: InputMaybe<ModelLeaveConditionInput>
  input: DeleteRequestInput
}

export type MutationDeletePackageGroupArgs = {
  packageGroupID: Scalars['ID']
}

export type MutationDeletePositionArgs = {
  condition?: InputMaybe<ModelPositionConditionInput>
  input: DeletePositionInput
}

export type MutationDeleteShiftArgs = {
  input: DeleteShiftInput
}

export type MutationDeleteTeam2Args = {
  condition?: InputMaybe<ModelTeamConditionInput>
  input: DeleteTeamInput
}

export type MutationDownloadPdfDocumentArgs = {
  input: DownloadOptional
}

export type MutationLoginArgs = {
  partyID: Scalars['String']
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationMockResetQuotaTriggerArgs = {
  periodYear?: InputMaybe<Scalars['String']>
  periodYearMonth?: InputMaybe<Scalars['String']>
}

export type MutationPutEmployeePayrollArgs = {
  input: Array<PutEmployeePayrollInput>
  period: Scalars['String']
  yearMonth: Scalars['String']
}

export type MutationPutPayrollConfigArgs = {
  input: PutPayrollConfigInput
}

export type MutationPutShiftColorArgs = {
  input: PutShiftColorInput
}

export type MutationPutShiftPlanArgs = {
  condition?: InputMaybe<ModelShiftConditionInput>
  input: PutShiftPlanInput
}

export type MutationPutSocialSecurityConfigArgs = {
  input: PutSocialSecurityConfigInput
}

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']
}

export type MutationResetTimeAttendanceReportArgs = {
  input: ResetTimeAttendanceReportInput
}

export type MutationUpdateApproveArgs = {
  condition?: InputMaybe<ModelApproveConditionInput>
  input: UpdateApprove
}

export type MutationUpdateApproveAttendanceArgs = {
  condition?: InputMaybe<ModelApproveAttendanceConditionInput>
  input: UpdateApproveAttendance
}

export type MutationUpdateApproveBatchArgs = {
  condition?: InputMaybe<ModelApproveConditionInput>
  input: Array<UpdateApprove>
}

export type MutationUpdateCompensationArgs = {
  input: UpdateCompensationInput
}

export type MutationUpdateDefaultHolidayArgs = {
  holidayID: Scalars['ID']
}

export type MutationUpdateDefaultShiftArgs = {
  shiftID: Scalars['ID']
}

export type MutationUpdateDepartmentArgs = {
  condition?: InputMaybe<ModelDepartmentConditionInput>
  input: UpdateDepartmentInput
}

export type MutationUpdateEmployee2Args = {
  condition?: InputMaybe<ModelEmployeeConditionInput>
  input: UpdateEmployeeInput
}

export type MutationUpdateHolidayOnYearArgs = {
  input: UpdateHolidayInput
}

export type MutationUpdateLeave2Args = {
  condition?: InputMaybe<ModelLeaveConditionInput>
  input: UpdateLeaveInput
}

export type MutationUpdateLeaveConfigArgs = {
  input: UpdateLeaveConfig
}

export type MutationUpdateOtherIncomeOrExpenseConfigArgs = {
  input: UpdateOtherIncomeOrExpenseConfigInput
}

export type MutationUpdateOvertime2Args = {
  condition?: InputMaybe<ModelOvertimeConditionInput>
  input: UpdateOvertimeInput
}

export type MutationUpdateOvertimeConfigArgs = {
  condition?: InputMaybe<ModelOvertimeConfigConditionInput>
  input: UpdateOvertimeConfigInput
}

export type MutationUpdatePackageGroupArgs = {
  input: UpdatePackageGroupInput
}

export type MutationUpdatePositionArgs = {
  condition?: InputMaybe<ModelPositionConditionInput>
  input: UpdatePositionInput
}

export type MutationUpdateShiftArgs = {
  condition?: InputMaybe<ModelShiftConditionInput>
  input: UpdateShiftInput
}

export type MutationUpdateTeam2Args = {
  condition?: InputMaybe<ModelTeamConditionInput>
  input: UpdateTeamInput
}

export type MutationUpdateTimeAttendanceReportArgs = {
  condition?: InputMaybe<ModelTimeAttendanceReportConditionInput>
  input: UpdateTimeAttendanceReportInput
}

export type OtherIncomeOrExpense = {
  __typename?: 'OtherIncomeOrExpense'
  amount?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  income40Type?: Maybe<E40Type>
  incomeExpenseType: EIncomeExpenseType
  incomeOrExpense: EIncomeOrExpense
  name: Scalars['String']
  variableOrFixed: EVariableOrFixed
}

export type OtherIncomeOrExpenseConfig = {
  __typename?: 'OtherIncomeOrExpenseConfig'
  otherExpenseConfigList: Array<OtherIncomeOrExpenseConfigItem>
  otherIncomeConfigList: Array<OtherIncomeOrExpenseConfigItem>
}

export type OtherIncomeOrExpenseConfigItem = {
  __typename?: 'OtherIncomeOrExpenseConfigItem'
  calculateWithList?: Maybe<Array<Scalars['String']>>
  id: Scalars['ID']
  income40Type?: Maybe<E40Type>
  incomeExpenseType: EIncomeExpenseType
  incomeOrExpense: EIncomeOrExpense
  name: Scalars['String']
  roundType?: Maybe<ERoundType>
  status: EStatus
  variableOrFixed: EVariableOrFixed
}

export type Overtime = {
  __typename?: 'Overtime'
  createdAt?: Maybe<Scalars['AWSDateTime']>
  createdByEmployeeID?: Maybe<Scalars['ID']>
  date?: Maybe<Scalars['AWSDate']>
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  endedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isCustomOvertimeMultiply?: Maybe<Scalars['Boolean']>
  overtimeMinute?: Maybe<Scalars['Int']>
  overtimeMultiply?: Maybe<Scalars['Float']>
  overtimeType?: Maybe<EOverTimeType>
  placeType?: Maybe<EPlaceType>
  remark?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['String']>
  status?: Maybe<EDocumentStatus>
}

export type OvertimeConfig = {
  __typename?: 'OvertimeConfig'
  overtimeAfterMinute?: Maybe<Scalars['Int']>
  overtimeBeforeMinute?: Maybe<Scalars['Int']>
  overtimeDay?: Maybe<Scalars['AWSJSON']>
  overtimeDayStatus?: Maybe<EStatus>
  overtimeMonth?: Maybe<Scalars['AWSJSON']>
  overtimeMonthStatus?: Maybe<EStatus>
  overtimeMultiplyType?: Maybe<EOverTimeMultiplyType>
  overtimeRoundFloor?: Maybe<EOverTimeRoundFloor>
  overtimeRoundHour?: Maybe<EOvertimeHourHalf>
  overtimeRoundType?: Maybe<EOverTimeRoundType>
}

export type OvertimeConfigQuery = {
  __typename?: 'OvertimeConfigQuery'
  items: Array<OvertimeConfig>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type OvertimeGroupByEmployeeQuery = {
  __typename?: 'OvertimeGroupByEmployeeQuery'
  items: Array<OvertimeResponse>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type OvertimeMonthAndYearInput = {
  overtimeTypeList?: InputMaybe<Array<InputMaybe<OvertimeTypeInput>>>
  workDay?: InputMaybe<EDayType>
}

export type OvertimeQuery = {
  __typename?: 'OvertimeQuery'
  items: Array<Overtime>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type OvertimeReport = {
  __typename?: 'OvertimeReport'
  endedAt?: Maybe<Scalars['AWSDateTime']>
  locationType?: Maybe<ELocationType>
  overtimeApproveMinute?: Maybe<Scalars['Int']>
  overtimeMinute?: Maybe<Scalars['Int']>
  overtimeRequestID?: Maybe<Scalars['ID']>
  overtimeType?: Maybe<EOverTimeType>
  startedAt?: Maybe<Scalars['AWSDateTime']>
  status?: Maybe<EDocumentStatus>
}

export type OvertimeReportInput = {
  endedAt?: InputMaybe<Scalars['AWSDateTime']>
  locationType?: InputMaybe<ELocationType>
  overtimeApproveMinute?: InputMaybe<Scalars['Int']>
  overtimeMinute?: InputMaybe<Scalars['Int']>
  overtimeRequestID?: InputMaybe<Scalars['ID']>
  overtimeType?: InputMaybe<EOverTimeType>
  startedAt?: InputMaybe<Scalars['AWSDateTime']>
  status?: InputMaybe<EDocumentStatus>
}

export type OvertimeResponse = {
  __typename?: 'OvertimeResponse'
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  items?: Maybe<Array<Overtime>>
}

export type OvertimeTypeInput = {
  multiply?: InputMaybe<Scalars['Float']>
  overtimeType?: InputMaybe<EOverTimeType>
}

export type PackageGroup = {
  __typename?: 'PackageGroup'
  description?: Maybe<Scalars['String']>
  employeeCount?: Maybe<Scalars['Int']>
  employeeList?: Maybe<EmployeeIdQuery>
  leavePackageList: Array<LeaveConfigId>
  name: Scalars['String']
  packageGroupID: Scalars['ID']
  welfarePackageList: Array<WelfareConfigId>
}

export type PackageGroupQuery = {
  __typename?: 'PackageGroupQuery'
  items: Array<PackageGroup>
  nextToken?: Maybe<Scalars['String']>
}

export type Payment = {
  __typename?: 'Payment'
  createdAt?: Maybe<Scalars['AWSDateTime']>
  date?: Maybe<Scalars['AWSDate']>
  id?: Maybe<Scalars['ID']>
  paymentList?: Maybe<Array<PaymentLineItem>>
  paymentName?: Maybe<Scalars['String']>
  paymentTotal?: Maybe<Scalars['Float']>
  status?: Maybe<EPaymentStatus>
  type?: Maybe<EPaymentType>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type PaymentLineItem = {
  __typename?: 'PaymentLineItem'
  amount?: Maybe<Scalars['Float']>
  bankAccountNumber?: Maybe<Scalars['String']>
  lineNo?: Maybe<Scalars['Int']>
  payrollID?: Maybe<Scalars['ID']>
  recipentName?: Maybe<Scalars['String']>
}

export type PaymentQuery = {
  __typename?: 'PaymentQuery'
  items: Array<Payment>
}

export type PayrollConfig = {
  __typename?: 'PayrollConfig'
  period1Day?: Maybe<Scalars['Int']>
  period2Day?: Maybe<Scalars['Int']>
  period3Day?: Maybe<Scalars['Int']>
  periodCount: EPeriodCount
  periodStart: Scalars['Int']
  resetLeaveDay: Scalars['String']
  roundType?: Maybe<ERoundType>
  workingDayCount: EWorkingDayCount
  workingDayCountNewEmployee: EWorkingDayCount
  workingHourDivisor: EWorkingHourDivisor
}

export type PayrollEmployeeTypeCount = {
  __typename?: 'PayrollEmployeeTypeCount'
  FULLTIME_DAY?: Maybe<Scalars['Int']>
  FULLTIME_MONTH?: Maybe<Scalars['Int']>
  OUTSOURCE?: Maybe<Scalars['Int']>
  PARTTIME?: Maybe<Scalars['Int']>
}

export type PayrollQuery = {
  __typename?: 'PayrollQuery'
  closeDate?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  employeeCount?: Maybe<Scalars['Int']>
  employeeHireCount?: Maybe<Scalars['Int']>
  employeePayrollList?: Maybe<Array<EmployeePayroll>>
  employeeRetireCount?: Maybe<Scalars['Int']>
  employeeTypeCount?: Maybe<PayrollEmployeeTypeCount>
  nextToken?: Maybe<Scalars['String']>
  paySlipEmpPeriod?: Maybe<Scalars['String']>
  period: Scalars['String']
  periodList?: Maybe<Array<PeriodList>>
  status?: Maybe<EPayrollStatus>
  timeAttendanceReportList?: Maybe<Array<TimeAttendanceReportResponse>>
  totalPayroll?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  yearMonth: Scalars['ID']
}

export type PeriodList = {
  __typename?: 'PeriodList'
  closeDate?: Maybe<Scalars['String']>
  paySlipEmpPeriod?: Maybe<Scalars['String']>
  period?: Maybe<Scalars['String']>
  status?: Maybe<EPayrollStatus>
}

export type Position = {
  __typename?: 'Position'
  id: Scalars['ID']
  listEmployee?: Maybe<EmployeeQuery>
  positionLevel?: Maybe<Scalars['String']>
  positionName: Scalars['String']
  remark?: Maybe<Scalars['String']>
}

export type PositionEmployee = {
  __typename?: 'PositionEmployee'
  getEmployee?: Maybe<Employee>
  id: Scalars['ID']
}

export type PositionEmployeeQuery = {
  __typename?: 'PositionEmployeeQuery'
  items: Array<PositionEmployee>
  nextToken?: Maybe<Scalars['String']>
}

export type PositionQuery = {
  __typename?: 'PositionQuery'
  items: Array<Position>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type PutCustomQuotaInput = {
  customQuota?: InputMaybe<Scalars['Int']>
  maxLeavePaidDay?: InputMaybe<Scalars['Int']>
  workMonthExperience?: InputMaybe<Scalars['Int']>
}

export type PutEmployeePayrollInput = {
  breakEarlyIncome?: InputMaybe<Scalars['Float']>
  breakLateExpense?: InputMaybe<Scalars['Float']>
  daysInPeriod?: InputMaybe<Scalars['Int']>
  employeeID: Scalars['ID']
  inEarlyIncome?: InputMaybe<Scalars['Float']>
  inLateExpense?: InputMaybe<Scalars['Float']>
  income?: InputMaybe<Scalars['Float']>
  leaveDeductMinute?: InputMaybe<Scalars['Int']>
  leaveMinute?: InputMaybe<Scalars['Int']>
  netIncome?: InputMaybe<Scalars['Float']>
  netNegativeIncome?: InputMaybe<Scalars['Float']>
  netPositiveIncome?: InputMaybe<Scalars['Float']>
  otTypeOTIncome?: InputMaybe<Scalars['Float']>
  otTypeOTMinute?: InputMaybe<Scalars['Int']>
  otTypeWorkIncome?: InputMaybe<Scalars['Float']>
  otTypeWorkMinute?: InputMaybe<Scalars['Int']>
  otherExpenseList?: InputMaybe<Array<PutOtherIncomeOrExpenseInput>>
  otherIncomeList?: InputMaybe<Array<PutOtherIncomeOrExpenseInput>>
  outEarlyExpense?: InputMaybe<Scalars['Float']>
  outLateIncome?: InputMaybe<Scalars['Float']>
  paySlipURL?: InputMaybe<Scalars['String']>
  paymentID?: InputMaybe<Scalars['ID']>
  welfareList?: InputMaybe<Array<PutWelfareItemInput>>
  workDiligentIncome?: InputMaybe<Scalars['Float']>
  workingMinute?: InputMaybe<Scalars['Int']>
}

export type PutLeaveConfigIdInput = {
  customQuota?: InputMaybe<Scalars['Int']>
  customQuotaList?: InputMaybe<Array<PutCustomQuotaInput>>
  customQuotaUOM?: InputMaybe<EWelfareQuotaUnit>
  isCustom: Scalars['Boolean']
  leaveConfigID: Scalars['ID']
}

export type PutOtherIncomeOrExpenseInput = {
  amount?: InputMaybe<Scalars['Float']>
  id: Scalars['ID']
  income40Type?: InputMaybe<E40Type>
  incomeExpenseType: EIncomeExpenseType
  incomeOrExpense: EIncomeOrExpense
  name: Scalars['String']
  variableOrFixed: EVariableOrFixed
}

export type PutPayrollConfigInput = {
  period1Day?: InputMaybe<Scalars['Int']>
  period2Day?: InputMaybe<Scalars['Int']>
  period3Day?: InputMaybe<Scalars['Int']>
  periodCount: EPeriodCount
  periodStart: Scalars['Int']
  resetLeaveDay: Scalars['String']
  roundType?: InputMaybe<ERoundType>
  workingDayCount: EWorkingDayCount
  workingDayCountNewEmployee: EWorkingDayCount
  workingHourDivisor: EWorkingHourDivisor
}

export type PutShiftColorInput = {
  hexColorList: Array<Scalars['String']>
}

export type PutShiftPlanInput = {
  day: Scalars['Int']
  shiftPlanEmployeeList?: InputMaybe<Array<ShiftPlanEmployeeInput>>
  week: Scalars['Int']
  year: Scalars['Int']
}

export type PutSocialSecurityConfigInput = {
  dependencyList?: InputMaybe<Array<Scalars['String']>>
  employeePercent: Scalars['Float']
  employerPercent: Scalars['Float']
  status: EStatus
}

export type PutWelfareConfigIdInput = {
  customQuota?: InputMaybe<Scalars['Int']>
  customQuotaList?: InputMaybe<Array<PutCustomQuotaInput>>
  customQuotaUOM?: InputMaybe<EWelfareQuotaUnit>
  isCustom: Scalars['Boolean']
  welfareConfigID: Scalars['ID']
}

export type PutWelfareItemInput = {
  amount?: InputMaybe<Scalars['Float']>
  id: Scalars['ID']
  income40Type?: InputMaybe<E40Type>
  name: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  bark?: Maybe<Scalars['String']>
  getApprove?: Maybe<Approve>
  getDepartment?: Maybe<Department>
  getEmployee?: Maybe<Employee>
  getHoliday?: Maybe<Holiday>
  getLeave?: Maybe<Leave>
  getLeaveConfig?: Maybe<LeaveConfig>
  getOvertime?: Maybe<Overtime>
  getOvertimeConfig?: Maybe<OvertimeConfig>
  getPayrollConfig?: Maybe<PayrollConfig>
  getPosition?: Maybe<Position>
  getShareCalendar?: Maybe<ShareCalendar>
  getShift?: Maybe<Shift>
  getShiftPlan?: Maybe<ShiftPlan>
  getSocialSecurityConfig?: Maybe<SocialSecurityConfig>
  getTeam?: Maybe<Team>
  getTimeAttendanceReport?: Maybe<TimeAttendanceReport>
  getTimeAttendanceReportStreamCount?: Maybe<StreamCount>
  isScanIn?: Maybe<Scalars['Boolean']>
  listAdminLeave?: Maybe<LeaveQuery>
  listAdminOvertime?: Maybe<LeaveQuery>
  listApprove?: Maybe<ApproveQuery>
  listApproveAttendance?: Maybe<ApproveAttendanceQuery>
  listCompensation?: Maybe<CompensationQuery>
  listDepartment?: Maybe<DepartmentQuery>
  listEmployee?: Maybe<EmployeeQuery>
  listHoliday?: Maybe<HolidayQuery>
  listLeave?: Maybe<LeaveQuery>
  listLeaveByEmployeeID?: Maybe<LeaveQuery>
  listLeaveConfig?: Maybe<LeaveConfigQuery>
  listLeaveGroupByEmployee?: Maybe<LeaveGroupByEmployeeQuery>
  listLeaveRequest?: Maybe<LeaveQuery>
  listOtherIncomeOrExpenseConfig?: Maybe<OtherIncomeOrExpenseConfig>
  listOvertime?: Maybe<OvertimeQuery>
  listOvertimeByEmployeeID?: Maybe<OvertimeQuery>
  listOvertimeGroupByEmployee?: Maybe<OvertimeGroupByEmployeeQuery>
  listOvertimeRequest?: Maybe<OvertimeQuery>
  listPackageGroup?: Maybe<PackageGroupQuery>
  listPayment?: Maybe<PaymentQuery>
  listPayroll?: Maybe<PayrollQuery>
  listPosition?: Maybe<PositionQuery>
  listShift?: Maybe<ShiftQuery>
  listShiftColor?: Maybe<ShiftColor>
  listShiftPlan?: Maybe<ShiftPlanQuery>
  listTeam?: Maybe<TeamQuery>
  listTest?: Maybe<Scalars['String']>
  listTimeAttendance?: Maybe<TimeAttendanceQuery>
  listTimeAttendanceByEmployeeID?: Maybe<TimeAttendanceQuery>
  listTimeAttendanceReport?: Maybe<TimeAttendanceReportQuery>
  listTimeAttendanceReportByEmployeeID?: Maybe<TimeAttendanceReportQuery>
  listTimeAttendanceReportGroupByEmployee?: Maybe<TimeAttendanceReportGroupByEmployeeQuery>
}

export type QueryGetApproveArgs = {
  employeeID: Scalars['ID']
  id: Scalars['ID']
}

export type QueryGetDepartmentArgs = {
  id: Scalars['ID']
}

export type QueryGetEmployeeArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryGetHolidayArgs = {
  holidayID: Scalars['ID']
  year: Scalars['Int']
}

export type QueryGetLeaveArgs = {
  employeeID: Scalars['ID']
  id: Scalars['ID']
}

export type QueryGetLeaveConfigArgs = {
  id: Scalars['ID']
}

export type QueryGetOvertimeArgs = {
  employeeID: Scalars['ID']
  id: Scalars['ID']
}

export type QueryGetPositionArgs = {
  id: Scalars['ID']
}

export type QueryGetShareCalendarArgs = {
  input: GetShareCalendarInput
}

export type QueryGetShiftArgs = {
  shiftID: Scalars['ID']
}

export type QueryGetShiftPlanArgs = {
  input: GetShiftPlanInput
}

export type QueryGetTeamArgs = {
  id: Scalars['ID']
}

export type QueryGetTimeAttendanceReportArgs = {
  date: Scalars['AWSDate']
  employeeID: Scalars['ID']
}

export type QueryIsScanInArgs = {
  employeeID: Scalars['ID']
}

export type QueryListAdminLeaveArgs = {
  filter?: InputMaybe<ModelLeaveFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListAdminOvertimeArgs = {
  filter?: InputMaybe<ModelLeaveFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListApproveArgs = {
  filter?: InputMaybe<ModelApproveFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListApproveAttendanceArgs = {
  filter?: InputMaybe<ModelApproveAttendanceFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListCompensationArgs = {
  filter?: InputMaybe<ModelCompensationFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListDepartmentArgs = {
  filter?: InputMaybe<ModelDepartmentFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListEmployeeArgs = {
  filter?: InputMaybe<ModelEmployeeFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListHolidayArgs = {
  filter?: InputMaybe<ModelHolidayFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListLeaveArgs = {
  filter?: InputMaybe<ModelLeaveFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListLeaveByEmployeeIdArgs = {
  employeeID: Scalars['ID']
  filter?: InputMaybe<ModelLeaveFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListLeaveConfigArgs = {
  filter?: InputMaybe<ModelLeaveConfigFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListLeaveGroupByEmployeeArgs = {
  filter?: InputMaybe<ModelLeaveFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListOvertimeArgs = {
  filter?: InputMaybe<ModelOvertimeFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListOvertimeByEmployeeIdArgs = {
  employeeID: Scalars['ID']
  filter?: InputMaybe<ModelOvertimeFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListOvertimeGroupByEmployeeArgs = {
  filter?: InputMaybe<ModelOvertimeFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListPackageGroupArgs = {
  filter?: InputMaybe<ModelPackageGroupFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListPaymentArgs = {
  filter?: InputMaybe<ModelPaymentFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListPayrollArgs = {
  nextToken?: InputMaybe<Scalars['String']>
  period?: InputMaybe<Scalars['String']>
  yearMonth: Scalars['String']
}

export type QueryListPositionArgs = {
  filter?: InputMaybe<ModelPositionFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListShiftArgs = {
  filter?: InputMaybe<ModelShiftFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListShiftPlanArgs = {
  filter?: InputMaybe<ModelShiftFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListTeamArgs = {
  filter?: InputMaybe<ModelTeamFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListTestArgs = {
  pok?: InputMaybe<Scalars['ID']>
}

export type QueryListTimeAttendanceArgs = {
  filter?: InputMaybe<ModelTimeAttendanceFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListTimeAttendanceByEmployeeIdArgs = {
  employeeID: Scalars['ID']
  filter?: InputMaybe<ModelTimeAttendanceFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryListTimeAttendanceReportArgs = {
  filter?: InputMaybe<ModelTimeAttendanceReportFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListTimeAttendanceReportByEmployeeIdArgs = {
  employeeID: Scalars['ID']
  filter?: InputMaybe<ModelTimeAttendanceReportFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListTimeAttendanceReportGroupByEmployeeArgs = {
  endDate: Scalars['AWSDate']
  filter?: InputMaybe<ModelTimeAttendanceReportFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<ModelSortDirection>
  startDate: Scalars['AWSDate']
}

export type ResetTimeAttendanceReportInput = {
  endDate?: InputMaybe<Scalars['AWSDate']>
  startDate?: InputMaybe<Scalars['AWSDate']>
}

export type ShareCalendar = {
  __typename?: 'ShareCalendar'
  filter?: Maybe<ShareCalendarFilter>
  items?: Maybe<Array<Maybe<ShareCalendarDate>>>
  year: Scalars['Int']
}

export type ShareCalendarDate = {
  __typename?: 'ShareCalendarDate'
  date?: Maybe<Scalars['AWSDate']>
  events?: Maybe<Array<Maybe<ShareCalendarEvent>>>
  holidayDescription?: Maybe<Scalars['String']>
  isHoliday?: Maybe<Scalars['Boolean']>
}

export type ShareCalendarEvent = {
  __typename?: 'ShareCalendarEvent'
  Type?: Maybe<Scalars['String']>
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  event?: Maybe<Scalars['AWSJSON']>
}

export type ShareCalendarFilter = {
  __typename?: 'ShareCalendarFilter'
  isAbsent?: Maybe<Scalars['Boolean']>
  isAll?: Maybe<Scalars['Boolean']>
  isLate?: Maybe<Scalars['Boolean']>
  isLeave?: Maybe<Scalars['Boolean']>
  isOvertime?: Maybe<Scalars['Boolean']>
}

export type ShareCalendarFilterInput = {
  isAbsent?: InputMaybe<Scalars['Boolean']>
  isAll?: InputMaybe<Scalars['Boolean']>
  isLate?: InputMaybe<Scalars['Boolean']>
  isLeave?: InputMaybe<Scalars['Boolean']>
  isOvertime?: InputMaybe<Scalars['Boolean']>
}

export type Shift = {
  __typename?: 'Shift'
  breakPolicy?: Maybe<Array<Maybe<ShiftWorkPolicy>>>
  employeeType?: Maybe<Array<Maybe<EEmployeeType>>>
  shiftColor?: Maybe<Scalars['String']>
  shiftColorIndex?: Maybe<Scalars['Int']>
  shiftID: Scalars['ID']
  shiftName: Scalars['String']
  workDayPolicy?: Maybe<Array<Maybe<ShiftWorkPolicy>>>
  workType?: Maybe<EShiftWorkType>
}

export type ShiftColor = {
  __typename?: 'ShiftColor'
  hexColorList: Array<Scalars['String']>
}

export type ShiftPlan = {
  __typename?: 'ShiftPlan'
  shiftList?: Maybe<Scalars['AWSJSON']>
}

export type ShiftPlanEmployeeInput = {
  employeeID: Scalars['ID']
  shiftID: Scalars['ID']
}

export type ShiftPlanQuery = {
  __typename?: 'ShiftPlanQuery'
  items?: Maybe<Array<Maybe<ShiftPlan>>>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type ShiftQuery = {
  __typename?: 'ShiftQuery'
  getDefaultShift?: Maybe<Scalars['ID']>
  items: Array<Shift>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type ShiftWorkPolicy = {
  __typename?: 'ShiftWorkPolicy'
  dayList?: Maybe<Array<Maybe<Scalars['Int']>>>
  timeList?: Maybe<Array<Maybe<ShiftWorkPolicyTime>>>
  totalHours?: Maybe<Scalars['Float']>
}

export type ShiftWorkPolicyInput = {
  dayList: Array<InputMaybe<Scalars['Int']>>
  timeList?: InputMaybe<Array<InputMaybe<ShiftWorkPolicyTimeInput>>>
  totalHours?: InputMaybe<Scalars['Int']>
}

export type ShiftWorkPolicyTime = {
  __typename?: 'ShiftWorkPolicyTime'
  endTime: Scalars['String']
  isScanEnd?: Maybe<Scalars['Boolean']>
  isScanStart?: Maybe<Scalars['Boolean']>
  startTime: Scalars['String']
}

export type ShiftWorkPolicyTimeInput = {
  endTime: Scalars['String']
  isScanEnd?: InputMaybe<Scalars['Boolean']>
  isScanStart?: InputMaybe<Scalars['Boolean']>
  startTime: Scalars['String']
}

export type SocialSecurityConfig = {
  __typename?: 'SocialSecurityConfig'
  dependencyList?: Maybe<Array<Scalars['String']>>
  employeePercent: Scalars['Float']
  employerPercent: Scalars['Float']
  status: EStatus
}

export type StreamCount = {
  __typename?: 'StreamCount'
  logProcessed?: Maybe<Scalars['Int']>
  logTotal?: Maybe<Scalars['Int']>
}

export type Team = {
  __typename?: 'Team'
  id: Scalars['ID']
  listMember?: Maybe<EmployeeIdQuery>
  listMemberID: Array<Scalars['ID']>
  remark?: Maybe<Scalars['String']>
  teamLeader?: Maybe<Employee>
  teamLeaderID: Scalars['ID']
  teamName: Scalars['String']
}

export type TeamId = {
  __typename?: 'TeamID'
  getTeam?: Maybe<Team>
  id: Scalars['ID']
}

export type TeamIdQuery = {
  __typename?: 'TeamIDQuery'
  items: Array<TeamId>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type TeamQuery = {
  __typename?: 'TeamQuery'
  items: Array<Team>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type TimeAttendance = {
  __typename?: 'TimeAttendance'
  createdAt?: Maybe<Scalars['AWSDateTime']>
  deviceId?: Maybe<Scalars['String']>
  employee?: Maybe<Employee>
  employeeID: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['String']>
  locationType: ELocationType
  long?: Maybe<Scalars['String']>
  scanAt: Scalars['AWSDateTime']
  scanReason: EScanReasonType
  scanType: EScanType
  timeID: Scalars['ID']
}

export type TimeAttendanceConfig = {
  __typename?: 'TimeAttendanceConfig'
  breakEarlyPaidRate?: Maybe<Scalars['Int']>
  breakEarlyPaidType?: Maybe<EPaidType>
  breakOvertimeDedcutType?: Maybe<EDeductType>
  breakOvertimeDeductRate?: Maybe<Scalars['Int']>
  breakOvertimeQuotaMinute?: Maybe<Scalars['Int']>
  breakOvertimeQuotaType?: Maybe<EAttendanceQuotaType>
  breakOvertimeType?: Maybe<EAttendanceRangeType>
  isBreakEarly: Scalars['Boolean']
  isBreakEarlyDiligent?: Maybe<Scalars['Boolean']>
  isBreakEarlyPaid?: Maybe<Scalars['Boolean']>
  isBreakOvertime: Scalars['Boolean']
  isBreakOvertimeDeduct?: Maybe<Scalars['Boolean']>
  isBreakOvertimeDiligent?: Maybe<Scalars['Boolean']>
  isWorkInEarly: Scalars['Boolean']
  isWorkInEarlyDiligent?: Maybe<Scalars['Boolean']>
  isWorkInEarlyPaid?: Maybe<Scalars['Boolean']>
  isWorkInLate: Scalars['Boolean']
  isWorkInLateDeduct?: Maybe<Scalars['Boolean']>
  isWorkInLateDiligent?: Maybe<Scalars['Boolean']>
  isWorkOutEarly: Scalars['Boolean']
  isWorkOutEarlyDeduct?: Maybe<Scalars['Boolean']>
  isWorkOutEarlyDiligent?: Maybe<Scalars['Boolean']>
  isWorkOutLate: Scalars['Boolean']
  isWorkOutLateDiligent?: Maybe<Scalars['Boolean']>
  isWorkOutLatePaid?: Maybe<Scalars['Boolean']>
  isWorkOutLatePaidRate?: Maybe<Scalars['Int']>
  isWorkOutLatePaidType?: Maybe<EPaidType>
  maxBreakEarlyMinute?: Maybe<Scalars['Int']>
  maxBreakOvertimeMinute?: Maybe<Scalars['Int']>
  maxWorkInEarlyMinute?: Maybe<Scalars['Int']>
  maxWorkInLateMinute?: Maybe<Scalars['Int']>
  maxWorkInLateType?: Maybe<EAttendanceRangeType>
  maxWorkOutEarlyMinute?: Maybe<Scalars['Int']>
  maxWorkOutLateMinute?: Maybe<Scalars['Int']>
  workInEarlyPaidRate?: Maybe<Scalars['Int']>
  workInEarlyPaidType?: Maybe<EPaidType>
  workInLateDeductRate?: Maybe<Scalars['Boolean']>
  workInLateDeductType?: Maybe<EDeductType>
  workInLateQuotaMinute?: Maybe<Scalars['Int']>
  workInLateQuotaType?: Maybe<EAttendanceQuotaType>
  workOutEarlyDeductRate?: Maybe<Scalars['Int']>
  workOutEarlyDeductType?: Maybe<EDeductType>
  workOutEarlyQuotaMinute?: Maybe<Scalars['Int']>
  workOutEarlyQuotaType?: Maybe<EAttendanceQuotaType>
  workOutEarlyType?: Maybe<EAttendanceRangeType>
  workOutLateQuotaType?: Maybe<EAttendanceQuotaType>
}

export type TimeAttendanceQuery = {
  __typename?: 'TimeAttendanceQuery'
  items: Array<TimeAttendance>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type TimeAttendanceReport = {
  __typename?: 'TimeAttendanceReport'
  Type?: Maybe<ETimeAttendanceReportType>
  breakDiffMinute?: Maybe<Scalars['Int']>
  breakTimeList?: Maybe<Array<Maybe<BreakTimeReport>>>
  companyID: Scalars['ID']
  createdAt?: Maybe<Scalars['AWSDateTime']>
  date: Scalars['String']
  dayType: EDayType
  employee?: Maybe<Employee>
  employeeID: Scalars['ID']
  inDiffMinute?: Maybe<Scalars['Int']>
  isLink?: Maybe<Scalars['Boolean']>
  leaveDeductList?: Maybe<Array<LeaveReport>>
  leaveDeductMinute?: Maybe<Scalars['Int']>
  leaveList?: Maybe<Array<LeaveReport>>
  leaveMinute?: Maybe<Scalars['Int']>
  outDiffMinute?: Maybe<Scalars['Int']>
  overtimeApproveList?: Maybe<Array<OvertimeReport>>
  overtimeApproveMinute?: Maybe<Scalars['Int']>
  overtimeList?: Maybe<Array<OvertimeReport>>
  overtimeMinute?: Maybe<Scalars['Int']>
  remark?: Maybe<Scalars['String']>
  scanIn?: Maybe<Scalars['AWSDateTime']>
  scanOut?: Maybe<Scalars['AWSDateTime']>
  shiftEndTime?: Maybe<Scalars['AWSTime']>
  shiftMinute?: Maybe<Scalars['Int']>
  shiftName?: Maybe<Scalars['String']>
  shiftStartTime?: Maybe<Scalars['AWSTime']>
  shiftVersion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  workingMinute?: Maybe<Scalars['Int']>
  workingTimeList?: Maybe<Array<WorkingTimeReport>>
}

export type TimeAttendanceReportGroupByEmployeeQuery = {
  __typename?: 'TimeAttendanceReportGroupByEmployeeQuery'
  items: Array<TimeAttendanceReportResponse>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type TimeAttendanceReportQuery = {
  __typename?: 'TimeAttendanceReportQuery'
  items: Array<TimeAttendanceReport>
  nextToken?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['AWSTimestamp']>
}

export type TimeAttendanceReportResponse = {
  __typename?: 'TimeAttendanceReportResponse'
  employee?: Maybe<Employee>
  employeeID?: Maybe<Scalars['ID']>
  items?: Maybe<Array<TimeAttendanceReport>>
}

export type UpdateApprove = {
  Type?: InputMaybe<EApproveType>
  approveBy: Scalars['ID']
  createdAt?: InputMaybe<Scalars['AWSDateTime']>
  date?: InputMaybe<Scalars['AWSDate']>
  employeeID: Scalars['ID']
  endedAt?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  isLeaveDeductMoney?: InputMaybe<Scalars['Boolean']>
  leaveDocumentAttachment?: InputMaybe<Scalars['String']>
  leaveForm?: InputMaybe<ELeaveForm>
  leaveFormHalfDay?: InputMaybe<ELeaveHalfDay>
  leaveMinute?: InputMaybe<Scalars['Int']>
  leaveType?: InputMaybe<ELeaveType>
  overtimeMinute?: InputMaybe<Scalars['Int']>
  overtimeType?: InputMaybe<EOverTimeType>
  placeType?: InputMaybe<EPlaceType>
  remark?: InputMaybe<Scalars['String']>
  remarkApprove?: InputMaybe<Scalars['String']>
  shardID?: InputMaybe<Scalars['ID']>
  startedAt?: InputMaybe<Scalars['String']>
  status: EApproveStatus
  statusTimeStamp?: InputMaybe<Scalars['String']>
  timestamp?: InputMaybe<Scalars['AWSDateTime']>
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>
}

export type UpdateApproveAttendance = {
  compensationFoodIPay?: InputMaybe<Scalars['String']>
  compensationFoodQuota?: InputMaybe<Scalars['String']>
  compensationFoodUsed?: InputMaybe<Scalars['String']>
  compensationSnackIPay?: InputMaybe<Scalars['String']>
  compensationSnackQuota?: InputMaybe<Scalars['String']>
  compensationSnackUsed?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['AWSDateTime']>
  date: Scalars['AWSDate']
  employeeID: Scalars['ID']
  endedAt?: InputMaybe<Scalars['AWSDateTime']>
  lateQuota?: InputMaybe<Scalars['String']>
  lateWithPaid?: InputMaybe<Scalars['String']>
  lateWithoutPaid?: InputMaybe<Scalars['String']>
  leaveAbsentHour?: InputMaybe<Scalars['String']>
  leaveAbsentWithoutPaid?: InputMaybe<Scalars['String']>
  leaveAnnualQuota?: InputMaybe<Scalars['String']>
  leaveAnnualWithPaid?: InputMaybe<Scalars['String']>
  leaveAnnualWithoutPaid?: InputMaybe<Scalars['String']>
  leavePersonalQuota?: InputMaybe<Scalars['String']>
  leavePersonalWithPaid?: InputMaybe<Scalars['String']>
  leavePersonalWithoutPaid?: InputMaybe<Scalars['String']>
  leaveSickQuota?: InputMaybe<Scalars['String']>
  leaveSickWithPaid?: InputMaybe<Scalars['String']>
  leaveSickWithoutPaid?: InputMaybe<Scalars['String']>
  overtimeHour?: InputMaybe<Scalars['Int']>
  startedAt?: InputMaybe<Scalars['AWSDateTime']>
  status?: InputMaybe<EApproveAttendanceStatus>
  totalWorkday?: InputMaybe<Scalars['String']>
  totalWorkdayHour?: InputMaybe<Scalars['Int']>
}

export type UpdateCompensationInput = {
  compensationDocumentAttachment?: InputMaybe<Scalars['String']>
  compensationName?: InputMaybe<Scalars['String']>
  compensationType?: InputMaybe<ECompensationType>
  employeeID: Scalars['ID']
  id: Scalars['ID']
  remark?: InputMaybe<Scalars['String']>
  shareWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Scalars['String']>
  totalAmount?: InputMaybe<Scalars['String']>
}

export type UpdateDepartmentInput = {
  departmentDetail?: InputMaybe<Scalars['String']>
  departmentName?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  regulationCalendar?: InputMaybe<Array<InputMaybe<ERegulationCalendar>>>
  regulationLeave?: InputMaybe<Array<InputMaybe<ERegulationLeave>>>
  regulationOverTime?: InputMaybe<Array<InputMaybe<ERegulationOverTime>>>
  regulationWorkAttendance?: InputMaybe<Array<InputMaybe<ERegulationWorkAttendance>>>
}

export type UpdateEmployeeIdInput = {
  action: EAction
  customQuota?: InputMaybe<Scalars['Int']>
  customQuotaList?: InputMaybe<Array<PutCustomQuotaInput>>
  employeeID: Scalars['ID']
  isCustom: Scalars['Boolean']
  leaveConfigID: Scalars['ID']
}

export type UpdateEmployeeInput = {
  addressCurrent?: InputMaybe<CreateFAddressPersonalId>
  addressPersonalID?: InputMaybe<CreateFAddressPersonalId>
  bankAccountId?: InputMaybe<Scalars['ID']>
  bankAccountName?: InputMaybe<EBankProviderThailand>
  bankPayment?: InputMaybe<EBankProviderThailand>
  birthDate?: InputMaybe<Scalars['AWSDate']>
  departmentID?: InputMaybe<Scalars['ID']>
  diligentAllowance?: InputMaybe<Scalars['Boolean']>
  educationHighest?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  employeeFund?: InputMaybe<Array<InputMaybe<CreateFEmployeeFund>>>
  employeeHandicappedAndAgeOut?: InputMaybe<Scalars['Boolean']>
  employeePeriod?: InputMaybe<EEmployeePeriod>
  employeeType?: InputMaybe<EEmployeeType>
  employeeWorkStatus?: InputMaybe<EEmployeeWorkStatus>
  ethnicity?: InputMaybe<Scalars['String']>
  fingerPrintId?: InputMaybe<Scalars['ID']>
  fullName?: InputMaybe<Scalars['String']>
  fullNameEnglish?: InputMaybe<Scalars['String']>
  getHolidayID?: InputMaybe<Scalars['ID']>
  hireDate?: InputMaybe<Scalars['AWSDate']>
  historyEducation?: InputMaybe<Array<InputMaybe<CreateFHistoryEducation>>>
  historyFamily?: InputMaybe<Array<InputMaybe<CreateFHistoryFamily>>>
  historyWork?: InputMaybe<Array<InputMaybe<CreateFHistoryWork>>>
  holidayID?: InputMaybe<Scalars['ID']>
  holidayLink?: InputMaybe<EHolidayLink>
  hospitalService?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  incomeExpense?: InputMaybe<Array<InputMaybe<CreateFIncomeExpense>>>
  isTimeAttendance?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  lastNameEnglish?: InputMaybe<Scalars['String']>
  leaderTeam?: InputMaybe<Scalars['ID']>
  lineId?: InputMaybe<Scalars['String']>
  lineUserId?: InputMaybe<Scalars['ID']>
  middleName?: InputMaybe<Scalars['String']>
  middleNameEnglish?: InputMaybe<Scalars['String']>
  nameTitle?: InputMaybe<ENameTitle>
  nationality?: InputMaybe<Scalars['String']>
  nickName?: InputMaybe<Scalars['String']>
  packageGroupID?: InputMaybe<Scalars['ID']>
  password?: InputMaybe<Scalars['String']>
  paymentMethod?: InputMaybe<EPaymentMethod>
  personalEducationalAttachment?: InputMaybe<Scalars['String']>
  personalHouseAttachment?: InputMaybe<Scalars['String']>
  personalID?: InputMaybe<Scalars['ID']>
  personalIDNumber?: InputMaybe<Scalars['ID']>
  personalIdAttachment?: InputMaybe<Scalars['String']>
  personalOptionalAttachment?: InputMaybe<Scalars['String']>
  personalRelation?: InputMaybe<EPersonalRelation>
  personalTalent?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  personalTrainningAttachment?: InputMaybe<Scalars['String']>
  positionID?: InputMaybe<Scalars['ID']>
  profileImage?: InputMaybe<Scalars['String']>
  religion?: InputMaybe<Scalars['String']>
  retireDate?: InputMaybe<Scalars['AWSDate']>
  salary?: InputMaybe<Scalars['Float']>
  salaryOptional?: InputMaybe<ESalaryOptional>
  salaryUOM?: InputMaybe<ESalaryUom>
  setShiftWorking?: InputMaybe<ESetShiftWorking>
  sex?: InputMaybe<ESex>
  shiftWorkingID?: InputMaybe<Scalars['ID']>
  socialSecurity?: InputMaybe<ESocialSecurity>
  socialSecurityCalculate?: InputMaybe<ESocialSecurityCalculate>
  status?: InputMaybe<EStatus>
  taxDeductOne?: InputMaybe<Array<InputMaybe<CreateFTaxDeductOne>>>
  taxDeductTwo?: InputMaybe<Array<InputMaybe<CreateFTaxDeductTwo>>>
  teamList?: InputMaybe<Scalars['ID']>
  tel?: InputMaybe<Scalars['String']>
  unusedLatestSalary?: InputMaybe<Scalars['Float']>
  username?: InputMaybe<Scalars['String']>
  workPlace?: InputMaybe<EPlaceType>
}

export type UpdateHolidayInput = {
  holidayID: Scalars['ID']
  holidayName?: InputMaybe<Scalars['String']>
  holidayYear?: InputMaybe<Array<HolidayYearInput>>
  year?: InputMaybe<Scalars['Int']>
}

export type UpdateLeaveConfig = {
  LeaveBeforehandDay?: InputMaybe<Scalars['Int']>
  employeeAllowSexList?: InputMaybe<Array<InputMaybe<ESex>>>
  employeeAllowTypeList?: InputMaybe<Array<InputMaybe<EEmployeeType>>>
  id: Scalars['ID']
  isActive?: InputMaybe<Scalars['Boolean']>
  isCountProbation?: InputMaybe<Scalars['Boolean']>
  isExceedQuota?: InputMaybe<Scalars['Boolean']>
  isLeaveAfterhand?: InputMaybe<Scalars['Boolean']>
  isLeaveBeforehand?: InputMaybe<Scalars['Boolean']>
  isLeaveCompound?: InputMaybe<Scalars['Boolean']>
  isLeaveDiligent?: InputMaybe<Scalars['Boolean']>
  isLeaveOnce?: InputMaybe<Scalars['Boolean']>
  isLookupNotWorkday?: InputMaybe<Scalars['Boolean']>
  leaveAmountType?: InputMaybe<ELeaveAmountType>
  leaveCompoundType?: InputMaybe<ECompoundType>
  leaveConfigType?: InputMaybe<ELeaveConfigType>
  leaveQuotaList?: InputMaybe<Array<InputMaybe<CreateLeaveQuota>>>
  maxLeaveCompound?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

export type UpdateLeaveInput = {
  createdByEmployeeID?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['AWSDate']>
  employeeID?: InputMaybe<Scalars['ID']>
  endedAt?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  isLeaveDeductMoney?: InputMaybe<Scalars['Boolean']>
  leaveDocumentAttachment?: InputMaybe<Scalars['String']>
  leaveForm?: InputMaybe<ELeaveForm>
  leaveFormHalfDay?: InputMaybe<ELeaveHalfDay>
  leaveFormMinute?: InputMaybe<Scalars['Int']>
  leaveType?: InputMaybe<ELeaveReportType>
  remark?: InputMaybe<Scalars['String']>
  startedAt?: InputMaybe<Scalars['String']>
  status?: InputMaybe<EDocumentStatus>
  topic?: InputMaybe<ETopic>
}

export type UpdateOtherIncomeOrExpenseConfigInput = {
  calculateWithList?: InputMaybe<Array<Scalars['String']>>
  id: Scalars['ID']
  income40Type?: InputMaybe<E40Type>
  incomeExpenseType: EIncomeExpenseType
  incomeOrExpense: EIncomeOrExpense
  name: Scalars['String']
  roundType?: InputMaybe<ERoundType>
  status: EStatus
  variableOrFixed: EVariableOrFixed
}

export type UpdateOvertimeConfigInput = {
  overtimeAfter?: InputMaybe<Scalars['Float']>
  overtimeBefore?: InputMaybe<Scalars['Float']>
  overtimeDay?: InputMaybe<Array<InputMaybe<OvertimeMonthAndYearInput>>>
  overtimeDayStatus?: InputMaybe<EStatus>
  overtimeID: Scalars['ID']
  overtimeMonth?: InputMaybe<Array<InputMaybe<OvertimeMonthAndYearInput>>>
  overtimeMonthStatus?: InputMaybe<EStatus>
}

export type UpdateOvertimeInput = {
  createdByEmployeeID?: InputMaybe<Scalars['ID']>
  date?: InputMaybe<Scalars['AWSDate']>
  employeeID?: InputMaybe<Scalars['ID']>
  endedAt?: InputMaybe<Scalars['AWSTime']>
  id: Scalars['ID']
  isCustomOvertimeMultiply?: InputMaybe<Scalars['Boolean']>
  overtimeMinute?: InputMaybe<Scalars['Int']>
  overtimeMultiply?: InputMaybe<Scalars['Float']>
  overtimeType?: InputMaybe<EOverTimeType>
  placeType?: InputMaybe<EPlaceType>
  remark?: InputMaybe<Scalars['String']>
  startedAt?: InputMaybe<Scalars['AWSTime']>
  status?: InputMaybe<EDocumentStatus>
}

export type UpdatePackageGroupInput = {
  description?: InputMaybe<Scalars['String']>
  employeeUpdateList?: InputMaybe<Array<UpdateEmployeeIdInput>>
  leavePackageList: Array<PutLeaveConfigIdInput>
  name?: InputMaybe<Scalars['String']>
  packageGroupID: Scalars['ID']
  welfarePackageList: Array<PutWelfareConfigIdInput>
}

export type UpdatePositionInput = {
  id: Scalars['ID']
  positionLevel?: InputMaybe<Scalars['String']>
  positionName?: InputMaybe<Scalars['String']>
  remark?: InputMaybe<Scalars['String']>
}

export type UpdateShiftInput = {
  breakPolicy?: InputMaybe<Array<InputMaybe<ShiftWorkPolicyInput>>>
  employeeType?: InputMaybe<Array<InputMaybe<EEmployeeType>>>
  shiftColorIndex?: InputMaybe<Scalars['Int']>
  shiftID: Scalars['ID']
  shiftName?: InputMaybe<Scalars['String']>
  workDayPolicy?: InputMaybe<Array<InputMaybe<ShiftWorkPolicyInput>>>
  workType?: InputMaybe<EShiftWorkType>
}

export type UpdateTeamInput = {
  id: Scalars['ID']
  listMemberID?: InputMaybe<Array<Scalars['ID']>>
  remark?: InputMaybe<Scalars['String']>
  teamLeaderID?: InputMaybe<Scalars['ID']>
  teamName?: InputMaybe<Scalars['String']>
}

export type UpdateTimeAttendanceReportInput = {
  Type?: InputMaybe<ETimeAttendanceReportType>
  breakDiffMinute?: InputMaybe<Scalars['Int']>
  companyID?: InputMaybe<Scalars['ID']>
  date: Scalars['AWSDate']
  dayType?: InputMaybe<EDayType>
  employeeID: Scalars['ID']
  inDiffMinute?: InputMaybe<Scalars['Int']>
  isLink?: InputMaybe<Scalars['Boolean']>
  leaveDeductList?: InputMaybe<Array<InputMaybe<LeaveReportInput>>>
  leaveDeductMinute?: InputMaybe<Scalars['Int']>
  leaveList?: InputMaybe<Array<InputMaybe<LeaveReportInput>>>
  leaveMinute?: InputMaybe<Scalars['Int']>
  outDiffMinute?: InputMaybe<Scalars['Int']>
  overtimeApproveList?: InputMaybe<Array<InputMaybe<OvertimeReportInput>>>
  overtimeApproveMinute?: InputMaybe<Scalars['Int']>
  overtimeList?: InputMaybe<Array<InputMaybe<OvertimeReportInput>>>
  overtimeMinute?: InputMaybe<Scalars['Int']>
  remark?: InputMaybe<Scalars['String']>
  scanIn?: InputMaybe<Scalars['AWSDateTime']>
  scanOut?: InputMaybe<Scalars['AWSDateTime']>
  shiftEndTime?: InputMaybe<Scalars['AWSTime']>
  shiftMinute?: InputMaybe<Scalars['Int']>
  shiftName?: InputMaybe<Scalars['String']>
  shiftStartTime?: InputMaybe<Scalars['AWSTime']>
  shiftVersion?: InputMaybe<Scalars['String']>
  workingMinute?: InputMaybe<Scalars['Int']>
  workingTimeList?: InputMaybe<Array<InputMaybe<WorkingTimeReportInput>>>
}

export type WelfareConfig = {
  __typename?: 'WelfareConfig'
  compoundType?: Maybe<ECompoundType>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  employeeAllowSexList?: Maybe<Array<ESex>>
  employeeAllowTypeList: Array<EEmployeeType>
  income40Type?: Maybe<E40Type>
  isActive?: Maybe<Scalars['Boolean']>
  isCompound?: Maybe<Scalars['Boolean']>
  isCountProbation?: Maybe<Scalars['Boolean']>
  isExceedQuota?: Maybe<Scalars['Boolean']>
  maxCompound?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  roundType?: Maybe<ERoundType>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  welfareID: Scalars['ID']
  welfareQuotaList: Array<WelfareQuota>
}

export type WelfareConfigId = {
  __typename?: 'WelfareConfigID'
  customQuota?: Maybe<Scalars['Int']>
  customQuotaList?: Maybe<Array<CustomQuota>>
  customQuotaUOM?: Maybe<EWelfareQuotaUnit>
  isCustom: Scalars['Boolean']
  welfareConfig?: Maybe<WelfareConfig>
  welfareConfigID: Scalars['ID']
}

export type WelfareItem = {
  __typename?: 'WelfareItem'
  amount?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  income40Type?: Maybe<E40Type>
  name: Scalars['String']
}

export type WelfareQuota = {
  __typename?: 'WelfareQuota'
  quota?: Maybe<Scalars['Int']>
  quotaUOM?: Maybe<EWelfareQuotaUnit>
  workMonthExperience?: Maybe<Scalars['Int']>
}

export type WorkingTimeReport = {
  __typename?: 'WorkingTimeReport'
  endedAt?: Maybe<Scalars['AWSDateTime']>
  startedAt?: Maybe<Scalars['AWSDateTime']>
  workingMinute?: Maybe<Scalars['Int']>
}

export type WorkingTimeReportInput = {
  endedAt?: InputMaybe<Scalars['AWSDateTime']>
  startedAt?: InputMaybe<Scalars['AWSDateTime']>
  workingMinute?: InputMaybe<Scalars['Int']>
}

export type MockCompensation = {
  __typename?: 'mockCompensation'
  compensationName?: Maybe<Scalars['String']>
  compensationPaid?: Maybe<Scalars['String']>
  compensationType?: Maybe<Scalars['String']>
  compensationUsed?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}
