interface IAction {
  type: string
  textModalAlertStatus: string
  desModalAlertStatus: string
  status: 'success' | 'error' | 'info' | ''
}

export interface IInitialState {
  textModalAlertStatus: string
  desModalAlertStatus: string
  openModalAlertStatus: boolean
  status: 'success' | 'error' | 'info' | ''
}

const initialState: IInitialState = {
  textModalAlertStatus: '',
  desModalAlertStatus: '',
  openModalAlertStatus: false,
  status: '',
}

const reducer = (state = initialState, action: IAction): any => {
  switch (action.type) {
    case 'SET_OPEN_MODAL_ALERT_STATUS':
      return {
        ...state,
        textModalAlertStatus: action.textModalAlertStatus,
        desModalAlertStatus: action.desModalAlertStatus,
        openModalAlertStatus: true,
        status: action.status,
      }
    case 'SET_CLOSE_MODAL_ALERT_STATUS':
      return {
        ...state,
        textModalAlertStatus: '',
        openModalAlertStatus: false,
        status: '',
      }
    default:
      return state
  }
}

export default reducer
