import React, { useState } from 'react'
import styles from './index.module.scss'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import DropIcon from '../../image/dropBlue.svg'
interface Props {
  _text?: string
  _selectData: any
  _functionOnclick?: any
  _onchange?: any
  //   _variant: 'text' | 'outlined' | 'contained'
  //   _colorBG: 'blue' | 'green' | 'red' | 'white' | 'black' | 'secondary' | 'darkblue' | 'gray'
  //   _colorText: 'blue' | 'green' | 'red' | 'white' | 'black' | 'secondary' | 'gray'
}

const listColor: any = [
  {
    name: 'gray',
    code: '#626262',
  },
  {
    name: 'darkblue',
    code: '#004CA8',
  },
  {
    name: 'blue',
    code: '#3A77DA',
  },
  {
    name: 'green',
    code: '#8AC82D',
  },
  {
    name: 'red',
    code: '#FF6161',
  },
  {
    name: 'white',
    code: '#FFFFFF',
  },
  {
    name: 'black',
    code: '#000000',
  },
  {
    name: 'secondary',
    code: '#5357BB',
  },
]

const ButtonSelectCustom: React.FC<Props> = (props: Props): JSX.Element => {
  //   const codeBG: string = listColor.find((ele: any) => ele.name === props._colorBG).code
  //   const codeText: string = listColor.find((ele: any) => ele.name === props._colorText).code
  const [anchorEl, setAnchorEl] = useState(null)
  const [textvalue, setTextValue] = useState<string>('Save and Approve')

  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.groupButton}>
          <button className={styles.buttonOutlined} type="submit" onClick={props._functionOnclick}>
            <p>{textvalue}</p>
          </button>
          <button className={styles.buttonIcon} type="button" onClick={handleClick}>
            {' '}
            <img src={DropIcon} alt="" />
          </button>
        </div>

        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {props._selectData.map((data: any) => (
            <MenuItem
              key={data.menu}
              onClick={() => {
                setAnchorEl(null)
                setTextValue(data.menu)
                props._onchange(data.value)
              }}
            >
              <p className={styles.text}>{data.menu}</p>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  )
}
export default ButtonSelectCustom
