import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { NavTopBarComponent, NavSideBarComponent, ButtonComponent, CardMasterDate } from '../../component'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { useNavigate } from 'react-router-dom'
import { queryListShift, updateDefaultShift, deleteShift } from '../../adapter/xhr'
import { daySelect } from '../../utils/common'
// Components
const AttendancePage: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [listShift, setListShift] = useState<any[]>([])

  useEffect(() => {
    queryListShift()
      .then((res: any) => {
        console.log(res.data.data.listShift.items, 'ressss')
        setListShift(res.data.data.listShift.items)
      })
      .catch((err: any) => {
        console.log(err, 'err')
      })
  }, [])

  const mockUp = [
    {
      shiftName: 'CCCCC',
      getDefaultShift: true,
      employeeType: ['FULLTIME_DAY'],
      workType: 'HOUR',
      shiftColorIndex: 0,
      workDayPolicy: [
        {
          dayList: [0, 1, 3],
          timeList: [
            {
              isScanStart: true,
              isScanEnd: true,
              startTime: '08:30',
              endTime: '20:00',
            },
          ],
        },
      ],
      breakPolicy: [
        {
          dayList: [0, 1, 3],
          timeList: [
            {
              isScanStart: true,
              isScanEnd: true,
              startTime: '08:30',
              endTime: '20:00',
            },
          ],
        },
      ],
    },
  ]

  return (
    <>
      <div className="layout-main">
        <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside>
        <div className="main">
          <div className={styles.container}>
            <section className={styles.topicPageWrapper}>
              <p className={styles.topicPage}>ระเบียบการเข้าทำงาน</p>
            </section>
            <div className={styles.contentContainer}>
              <section className={styles.btnTopWrapper}>
                <div className={styles.btnTop}>
                  <ButtonComponent
                    _type="button"
                    _colorBG="blue"
                    _colorText="white"
                    _variant="outlined"
                    _isIcon="add"
                    _text="เพิ่มระเบียบการทำงาน"
                    _functionOnClick={() => navigate('../masterdata/attendance/add')}
                  />
                </div>
              </section>
              <section>
                {listShift.map((shiftElement: any, shiftIndex: number) => {
                  return (
                    <CardMasterDate
                      key={shiftIndex}
                      _topic={shiftElement.shiftName}
                      isDefault={shiftElement.getDefaultShift}
                      // _title="เวลาทำงาน"
                      _subTopic={shiftElement.workDayPolicy
                        .map(
                          (element: any, index: number) =>
                            `${daySelect[element.dayList[0]].title}${
                              element.dayList.length > 1
                                ? `-${daySelect[element.dayList[element.dayList.length - 1]].title}`
                                : ''
                            } ${element.timeList[0].startTime}-${element.timeList[0].endTime},`,
                        )
                        .join(',')}
                      _functionOnClickDefault={() => {
                        updateDefaultShift({ shiftId: shiftElement.shiftID }).then((res: any) => {
                          console.log(res.data.data, 'response defaultShift')
                        })
                      }}
                      _functionOnClickEdit={() => navigate(`../masterdata/attendance/edit/${shiftElement.shiftID}`)}
                      _functionOnClickDelete={() =>
                        dispatch(
                          allAction.modalConfirmAction.setOpenModalConfirm(
                            'ลบระเบียบการเข้าทำงาน',
                            'คุณต้องการลบรายการ Shift A ใช่หรือไม่',
                            'หากลบแล้ว ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                            'ใช่, ลบรายการ',
                            () => {
                              deleteShift({ input: { shiftID: shiftElement.shiftID } }).then((res: any) => {
                                console.log(res.data.data, 'response delete Shift')
                              })
                              dispatch(
                                allAction.modalAction.setOpenModalAlertStatus(
                                  'ลบข้อมูลระเบียบการเข้าทำงานเรียบร้อยแล้ว',
                                  '',
                                  'success',
                                ),
                              )
                              dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                            },
                          ),
                        )
                      }
                    />
                  )
                })}
              </section>
            </div>
          </div>
        </div>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default AttendancePage
