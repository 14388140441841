import React from 'react'
// import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'
import { Formik } from 'formik'
import * as yup from 'yup'
import { InputTextComponent } from '../../component/InputsFormik'
import ButtonComponent from '../../component/Button'

import { BellSVG, ChatSVG, QuestionSVG, HumanSVG, UserSVG, DatabilityLogo } from './images'

const HomePage: React.FC = (): JSX.Element => {
  const navigate = useNavigate()

  // const [anchorEl, setAnchorEl] = useState(null)

  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget)
  // }
  const validationSchema = yup.object({
    search: yup.string().required('กรุณากรอกข้อมูลที่ต้องการค้นหา'),
  })

  // const [open, setOpen] = React.useState(false)
  return (
    <>
      <div className={styles.container}>
        <section className={styles.navTop}>
          <div className={styles.leftSide}>Link</div>
          <div className={styles.rightSide}>
            <div>
              <div className={styles.rightSideText}>Look</div>
            </div>
            <div className={styles.iconNavTop}>
              <p>Trial expires in 14 Day</p>
              <img alt="BellSVG" src={BellSVG} width={30} height={30} />
              <img alt="ChatSVG" src={ChatSVG} width={30} height={30} />
              <img alt="QuestionSVG" src={QuestionSVG} width={30} height={30} />
            </div>
          </div>
        </section>

        <section className={styles.Textfield}>
          <Formik
            initialValues={{
              search: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2))
                actions.setSubmitting(false)
              }, 1000)
            }}
          >
            {(props: any) => (
              <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                <InputTextComponent _name="search" _label="ค้นหา" _helperText={props.errors.search} />
                <div className={styles.btn}>
                  <ButtonComponent
                    _type="submit"
                    _colorBG="blue"
                    _colorText="white"
                    _variant="contained"
                    _isIcon="search"
                    _text="ค้นหา"
                  />
                </div>
              </form>
            )}
          </Formik>
        </section>

        <section className={styles.iconMenu}>
          <div onClick={() => navigate('../usermanage/team')} className={styles.iconBox}>
            <div className={styles.iconMenuImg}>
              <img alt="iconUser" src={UserSVG} width={80} height={80} />
            </div>
            <p>User Management</p>
          </div>
          <div onClick={() => navigate('../employee/employee')} className={styles.iconBox}>
            <div className={styles.iconMenuImg}>
              <img alt="iconHuman" src={HumanSVG} width={80} height={80} />
            </div>
            <p>Human Resource</p>
          </div>
        </section>
        <section className={styles.footerLogoWrapper}>
          <p className={styles.textFooter}>From</p>
          <img src={DatabilityLogo} alt="logo" className={styles.logo} />
        </section>
      </div>
    </>
  )
}

export default HomePage
