import React from 'react'
import styles from './index.module.scss'
import closeIcon from '../../image/close.svg'
import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { ButtonComponent } from '../../component'
import { InputDateComponent } from '../../component'
import * as yup from 'yup'
import { Formik } from 'formik'
interface Props {
  open: boolean
  setOpen: any
}
export const ModalPayment: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()

  const MockPay = [
    { name: 'Thanyarat Samaikhrip', email: 'Thanyarat@gmail.com', pay: 10000 },
    { name: 'Sakunkarn Leelathawornkul', email: 'Sakunkarn@gamil.com', pay: 10000 },
  ]
  const validationSchema = yup.object({
    // nameOvertime: yup.string().required('Name overtime is required'),
    // typeOvertime: yup.string().required('Type overtime is required'),
    // wage: yup.string().required('Wage is required'),
  })
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalPayment}>
        <div className={styles.card}>
          <div className={styles.head}>
            <div className={styles.group}>
              <p className={styles.textHead}>สร้าง / ส่งสลิปเงินเดือน</p>
              <img src={closeIcon} alt="" onClick={() => props.setOpen(false)} className={styles.Closeicon} />
            </div>
          </div>
          <Formik
            initialValues={{
              date: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2))
                actions.setSubmitting(false)
              }, 1000)
              dispatch(
                allAction.modalConfirmAction.setOpenModalConfirm(
                  'ยืนยันการทำรายการ',
                  'คุณต้องการยืนยันการทำรายของ {รหัสพนักงาน} ?',
                  'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                  'ยืนยัน',
                  () => {
                    dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        'สร้างสลิปเรียบร้อย สถานะ “Success”',
                        '',
                        'success',
                      ),
                    )
                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                  },
                ),
              )
            }}
          >
            {(props: any) => (
              <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                {console.log('sss', props)}
                <div className={styles.box}>
                  <div className={styles.title}>
                    <p className={styles.text}>รายการ : งวดที่ 1</p>
                    <div className={styles.inputBox}>
                      <InputDateComponent _name="date" _label="ประจำวันที่" _helperText={props.errors.date} />
                    </div>
                  </div>
                  <div className={styles.listPay}>
                    <div className={styles.listhead}>
                      <p className={styles.texthead}>Recipient Name</p>
                      <p className={styles.texthead}>E-mail </p>
                      <p className={styles.texthead}>Amount</p>
                    </div>

                    <div className={styles.sub}>
                      {MockPay.map((item: any, index: number) => (
                        <div className={styles.listsub} key={index}>
                          <p>{item.name}</p>
                          <p>{item.email}</p>
                          <p>{item.pay} THB</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.title}>
                    <p className={styles.text}>จำนวนการจ่ายเงินทั้งหมด</p>
                  </div>
                  <div className={styles.cardAmount}>
                    <p className={styles.textsum}>20,000 </p>
                  </div>
                </div>

                <div className={styles.bottom}>
                  <div className={styles.buttonWrapper}>
                    <div className={styles.bt}>
                      {' '}
                      <ButtonComponent
                        _variant="contained"
                        _text="Cancle"
                        _colorText="red"
                        _type="button"
                        _functionOnClick={() => props.setOpen(false)}
                        _colorBG="white"
                      />
                    </div>
                    <div className={styles.bt}>
                      <ButtonComponent
                        _variant="outlined"
                        _text="Confirm"
                        _colorText="white"
                        _colorBG="blue"
                        _type="submit"
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
