import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { Formik } from 'formik'
import { ButtonComponent, InputTextComponentCustom } from '../../component'
import { EApprove } from './DetailPage'
import { ApproveDocument, ApproveDocumentBatch } from '../../adapter/xhr'
import allAction from '../../state/actions'

export const ModalApprove: React.FC<any> = (props: any): JSX.Element => {
  const otherProps = props
  const dispatch = useDispatch()
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalApprove}>
        <div className={styles.card}>
          <Formik
            initialValues={{
              remark: '',
            }}
            // validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              const statusNew = (type: any) => {
                if (otherProps.type === 'อนุมัติ') {
                  return 'APPROVE'
                } else {
                  return 'REJECT'
                }
              }

              const inputApprove = {
                input: {
                  id: otherProps.detailData.id,
                  employeeID: otherProps.detailData.employeeID,
                  approveBy: '',
                  status: statusNew(otherProps.type),
                  remarkApprove: values.remark,
                },
              }
              console.log(inputApprove, 'Variables')
              ApproveDocument(inputApprove).then((res) => {
                console.log(res, 'APPROVE')
              })
              otherProps.setOpen(false)
              setTimeout(() => {
                props.QueryApprove()
              }, 100)
              {
                otherProps.type === 'อนุมัติ'
                  ? dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        `อนุมัติรายการ ${EApprove[`${otherProps.detailData.Type}`]}`,
                        `ของ ${otherProps.detailData.employee.fullName} ${otherProps.detailData.employee.lastName}`,
                        'success',
                      ),
                    )
                  : dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        `ปฏิเสธรายการ ${EApprove[`${otherProps.detailData.Type}`]}`,
                        `ของ ${otherProps.detailData.employee.fullName} ${otherProps.detailData.employee.lastName}`,
                        'error',
                      ),
                    )
              }
            }}
          >
            {(props: any) => (
              <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                <div className={styles.GroupArea}>
                  <section className={styles.Topmodal}>
                    {otherProps.type === 'อนุมัติ' ? <p>อนุมัติรายการ</p> : <p>ปฏิเสธรายการ</p>}
                  </section>
                  <section className={styles.TextApprove}>
                    <span className={styles.text}>
                      {otherProps.type === 'อนุมัติ' ? <span>อนุมัติรายการ</span> : <span>ปฏิเสธรายการ</span>}
                      {EApprove[`${otherProps.detailData.Type}`]}
                    </span>
                    <p className={styles.text}>
                      ของ {otherProps.detailData.employee.fullName} {otherProps.detailData.employee.lastName}
                    </p>
                  </section>
                  <section className={styles.InputGroup}>
                    <InputTextComponentCustom _name="remark" _label="หมายเหตุ" />
                  </section>
                </div>

                <section className={styles.bottom}>
                  <div className={styles.buttonForm}>
                    <div className={styles.button}>
                      <ButtonComponent
                        _type="button"
                        _colorBG="red"
                        _colorText="white"
                        _variant="text"
                        _text="ยกเลิก"
                        _functionOnClick={() => {
                          otherProps.setOpen(false)
                        }}
                      />
                    </div>
                    <div className={styles.button}>
                      <ButtonComponent
                        _type="submit"
                        _colorBG="blue"
                        _colorText="white"
                        _variant="text"
                        _text={otherProps.type === 'อนุมัติ' ? 'อนุมัติ' : 'ปฏิเสธ'}
                      />
                    </div>
                  </div>
                </section>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export const ModalApproveArr: React.FC<any> = (props: any): JSX.Element => {
  const otherProps = props
  const dispatch = useDispatch()
  // console.log(props.selectList, 'select')

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.setOpenModal(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalApprove}>
        <div className={styles.card}>
          <Formik
            initialValues={{
              remark: '',
            }}
            // validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              console.log(values, 'values')

              const statusNew = (type: any) => {
                if (otherProps.type === 'อนุมัติที่เลือกทั้งหมด') {
                  return 'APPROVE'
                } else {
                  return 'REJECT'
                }
              }

              const NewInput: any[] = []
              const Input = otherProps.selectList.forEach((item: any) => {
                delete item.employee
                return NewInput.push({
                  ...item,
                  id: item.id,
                  employeeID: item.employeeID,
                  approveBy: '',
                  status: statusNew(otherProps.type),
                  remarkApprove: values.remark,
                })
              })
              const inputApprove = {
                input: NewInput,
              }

              console.log(inputApprove, 'inputApprove')

              ApproveDocumentBatch(inputApprove).then((res) => {
                console.log(res, 'APPROVE')
              })
              otherProps.setOpenModal(false)
              {
                otherProps.type === 'อนุมัติที่เลือกทั้งหมด'
                  ? dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        `อนุมัติรายการ ${EApprove[`${otherProps.detailData.Type}`]}`,
                        `จำนวนที่อนุมัติ ${otherProps.selectList.length} รายการ`,
                        'success',
                      ),
                    )
                  : dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        `ปฏิเสธรายการ ${EApprove[`${otherProps.detailData.Type}`]}`,
                        `จำนวนที่ปฏิเสธ ${otherProps.selectList.length} `,
                        'error',
                      ),
                    )
              }
            }}
          >
            {(props: any) => (
              <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                <div className={styles.GroupArea}>
                  <section className={styles.Topmodal}>
                    {otherProps.type === 'อนุมัติที่เลือกทั้งหมด' ? <p>อนุมัติรายการ</p> : <p>ปฏิเสธรายการ</p>}
                  </section>
                  <section className={styles.TextApprove}>
                    <span className={styles.text}>
                      {otherProps.type === 'อนุมัติที่เลือกทั้งหมด' ? (
                        <span>อนุมัติรายการที่เลือกทั้งหมด</span>
                      ) : (
                        <span>ปฏิเสธรายการที่เลือกทั้งหมด</span>
                      )}
                    </span>
                    <p className={styles.text}>รายการที่เลือก {otherProps.selectList.length} รายการ</p>
                  </section>
                  <section className={styles.InputGroup}>
                    <InputTextComponentCustom _name="remark" _label="หมายเหตุ" />
                  </section>
                </div>

                <section className={styles.bottom}>
                  <div className={styles.buttonForm}>
                    <div className={styles.button}>
                      <ButtonComponent
                        _type="button"
                        _colorBG="red"
                        _colorText="white"
                        _variant="text"
                        _text="ยกเลิก"
                        _functionOnClick={() => {
                          otherProps.setOpenModal(false)
                        }}
                      />
                    </div>
                    <div className={styles.button}>
                      <ButtonComponent
                        _type="submit"
                        _colorBG="blue"
                        _colorText="white"
                        _variant="text"
                        _text="ok"
                        _functionOnClick={() => console.log('click')}
                        // _text={otherProps.type === 'อนุมัติที่เลือกทั้งหมด' ? 'อนุมัติ' : 'ปฏิเสธ'}
                      />
                    </div>
                  </div>
                </section>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
