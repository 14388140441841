import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import {
  NavTopBarComponent,
  NavSideBarComponent,
  InputTextComponent,
  InputDateComponent,
  InputNumberComponent,
  ButtonComponent,
} from '../../component'
import TabsWork from './tab'
import editIcon from '../../image/editcolor.svg'
import allAction from '../../state/actions'
import { useParams } from 'react-router-dom'

const PayrollCreate: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  useEffect(() => {
    if (id) {
      setMode('update')
    } else {
      setMode('create')
    }
  }, [])
  const navigate = useNavigate()
  const validationSchema = yup.object({})
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            {mode === 'create' && <h1 className={styles.title}>สร้างสลิปเงินเดือน</h1>}
            {mode === 'update' && <h1 className={styles.title}>แก้ไขสลิปเงินเดือน</h1>}
          </div>
          <Formik
            initialValues={{
              date: '',
              employeeid: '',
              name: '',
              salary: 0,
              bonus: 0,
              commission: 0,
              ot: 0,
              sum: 0,
              vat: 0,
              social: 0,
              reserve: 0,
              guarantee: 0,
              other: 0,
              total: 0,
              amount: 0,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              {
                mode === 'create' &&
                  dispatch(
                    allAction.modalConfirmAction.setOpenModalConfirm(
                      'ยืนยันการสร้างสลิป',
                      '',
                      'คุณต้องการยืนยันการสร้างสลิปเงินเดือน ?',
                      'ใช่,สร้างสลิป',
                      () => {
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus(
                            'สร้างสลิปเรียบร้อย สถานะ “Success”',
                            '',
                            'success',
                          ),
                        )
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                      },
                    ),
                  )
              }
              {
                mode === 'update' &&
                  dispatch(
                    allAction.modalConfirmAction.setOpenModalConfirm(
                      'ยืนยันการแก้ไขรายการ',
                      '',
                      'คุณต้องการยืนยันการแก้ไขรายการ ?',
                      'ใช่,แก้ไขรายการ',
                      () => {
                        dispatch(allAction.modalAction.setOpenModalAlertStatus('แก้ไขสลิปเรียบร้อย', '', 'success'))
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                      },
                    ),
                  )
              }
            }}
          >
            {(props: any) => (
              <form onSubmit={props.handleSubmit}>
                <div className={styles.Boxarea}>
                  <div className={styles.boxtop}>
                    <div className={styles.inputBox}>
                      {' '}
                      <p className={styles.title}>ข้อมูลทั่วไป</p>
                      <div className={styles.InputArea}>
                        <div className={styles.inputForm}>
                          <InputDateComponent _label="Date" _name="date" />
                        </div>
                        <div className={styles.inputForm}>
                          <InputTextComponent _label="รหัสพนักงาน" _name="employeeid" />
                        </div>
                        <div className={styles.inputForm}>
                          <InputTextComponent _label="ชื่อ-นามสกุล" _name="name" />
                        </div>
                        <div className={styles.inputForm}>
                          <InputTextComponent _label="ตำแหน่ง" _name="position" />
                        </div>
                        <div className={styles.inputForm}>
                          <InputTextComponent _label="ประเภทพนักงาน" _name="type" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.tabBox}>
                      <TabsWork />
                    </div>
                  </div>

                  <div className={styles.boxmiddle}>
                    <div className={styles.iconBar}>
                      <img
                        src={editIcon}
                        className={styles.icon}
                        alt=""
                        onClick={() => navigate('../payroll/edit/:id')}
                      />
                    </div>
                    <div className={styles.inputBox}>
                      <div className={styles.leftBox}>
                        <p className={styles.title}>รายรับ</p>
                        <div className={styles.inputArea}>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="เงินเดือน" _name="salary" />
                          </div>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="โบนัส" _name="bonus" />
                          </div>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="คอมมิดชั่น" _name="commission" />
                          </div>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="ค่าล่วงเวลา" _name="ot" />
                          </div>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="รวมรายรับ" _name="sum" />
                          </div>
                        </div>
                      </div>
                      <div className={styles.rigthBox}>
                        <p className={styles.title}>รายจ่าย</p>
                        <div className={styles.inputArea}>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="ภาษีเงินได้" _name="vat" />
                          </div>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="ประกันสังคม" _name="social" />
                          </div>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="เงินสำรอง เลี้ยงชีพ" _name="reserve" />
                          </div>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="เงินประกัน" _name="guarantee" />
                          </div>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="อื่น ๆ" _name="other" />
                          </div>
                          <div className={styles.inputForm}>
                            <InputNumberComponent _label="รวมยอดหัก" _name="total" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.boxbottom}>
                    <div className={styles.inputBox}>
                      {' '}
                      <p className={styles.title}>ยอดรวมสุทธิ</p>
                      <div className={styles.inputArea}>
                        <div className={styles.inputForm}>
                          <InputNumberComponent _label="ยอดเงินทั้งหมด" _name="amount" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.button}>
                  <div className={styles.btCancle}>
                    <ButtonComponent
                      _colorBG="white"
                      _colorText="red"
                      _text="ยกเลิก"
                      _variant="text"
                      _type="button"
                      _functionOnClick={() => navigate(-1)}
                    />
                  </div>
                  <div className={styles.btAdd}>
                    <ButtonComponent
                      _colorBG="blue"
                      _colorText="white"
                      _text="ยืนยัน"
                      _variant="contained"
                      _type="submit"
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="footer">Footer</div>
    </div>
  )
}

export default PayrollCreate
