import jwt_decode from 'jwt-decode'
import { Dispatch } from 'redux'
import { Login } from 'src/adapter/xhr'
import { makeActionCreator } from 'src/state/actions/generateActionCreator'

// Actions
const LOGIN_REQUEST = 'LOGIN/LOGIN_REQUESTED'
const LOGIN_SUCCESS = 'LOGIN/LOGIN_SUCCESS'
const LOGIN_FAILED = 'LOGIN/LOGIN_FAILED'

interface IAction {
  type: string
  accessToken: string
  refreshToken: string
}

export interface IInitialStateLogin {
  isLoggingIn: boolean
  isLoggedIn: boolean
  accessToken: string | null
  decodedAccessToken: any
  refreshToken: string | null
}

const initialState: IInitialStateLogin = {
  isLoggingIn: false,
  // Needs to check for token, else we will bounce back to login page everytime we refresh a page (initialState will be false)
  isLoggedIn: localStorage.getItem('accessToken') ? true : false,
  accessToken: localStorage.getItem('accessToken'),
  decodedAccessToken: localStorage.getItem('accessToken')
    ? jwt_decode(localStorage.getItem('accessToken') as string)
    : {},
  refreshToken: localStorage.getItem('refreshToken'),
}

const reducer = (state = initialState, action: IAction): any => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        isLoggedIn: false,
        accessToken: null,
        decodedAccessToken: {},
        refreshToken: null,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        accessToken: action.accessToken,
        decodedAccessToken: jwt_decode(action.accessToken),
        refreshToken: action.refreshToken ? action.refreshToken : state.refreshToken,
      }
    case LOGIN_FAILED:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        accessToken: null,
        decodedAccessToken: {},
        refreshToken: null,
      }
    default:
      return state
  }
}

export default reducer

export const requestLogin = makeActionCreator(LOGIN_REQUEST)
export const loginSuccess = makeActionCreator(LOGIN_SUCCESS, 'accessToken', 'refreshToken')
export const loginFailed = makeActionCreator(LOGIN_FAILED)

export const logInUser = (username: string, password: string, partyId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(requestLogin())
    return Login({ username, password, partyId })
      .then((res) => {
        const token = JSON.parse(res.data.data.login)
        localStorage.setItem('accessToken', token.accessToken)
        localStorage.setItem('refreshToken', token.refreshToken)

        dispatch(loginSuccess(token.accessToken, token.refreshToken))
        return res
      })
      .catch((err) => {
        dispatch(loginFailed())
      })
  }
}
