import React, { useState } from 'react'
import styles from './index.module.scss'
import { Formik } from 'formik'
import * as yup from 'yup'
import { ButtonComponent, InputTextComponent, PaginationBar, UploadButton } from '../../../component'

import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField, Tooltip } from '@mui/material'

// import { TableData } from './table'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import emailIcon from '../../../image/email.svg'
import fileIcon from '../../../image/file.svg'
import printIcon from '../../../image/printer.svg'
import emailblueIcon from '../../../image/mailblue.svg'
import fileblueIcon from '../../../image/fileblue.svg'
import printblueIcon from '../../../image/printblue.svg'
import SCBIcon from '../../../image/Bank/scb.svg'
import KbankIcon from '../../../image/Bank/KBank.svg'
import BKIcon from '../../../image/Bank/bangkok.svg'
import KTBIcon from '../../../image/Bank/KTB.svg'
import UOBIcon from '../../../image/Bank/uob.svg'
import krungsriIcon from '../../../image/Bank/krungsri.svg'
import txtIcon from '../../../image/txt.svg'
import pdfIcon from '../../../image/pdf.svg'
import csvIcon from '../../../image/csvFile.svg'
import { useDispatch } from 'react-redux'
// import QuestionIcon from '../../../image/'
import allAction from '../../../state/actions'
import { ClosePayroll, GetPayrollSlip, ListPayroll } from '../../../adapter/xhr'
import { downloadFile, paginationFunc } from '../../../utils/common'
import { CheckBox } from '@mui/icons-material'

interface IClosePeriod {
  payrollList?: any
  period?: string
  date?: string
}

const ClosePeriod: React.FC<IClosePeriod> = (fcProps: IClosePeriod): JSX.Element => {
  const validationSchema = yup.object({})
  const [value, setValue] = useState(new Date())
  const dispatch = useDispatch()

  console.log(fcProps, 'function component props')
  const BankData = [
    {
      profile: `${SCBIcon}`,
      name: 'ธนาคารไทยพาณิชย์',
    },
    {
      profile: `${KbankIcon}`,
      name: 'ธนาคารกสิกร',
    },
    {
      profile: `${BKIcon}`,
      name: 'ธนาคารกรุงเทพ',
    },
    {
      profile: `${KTBIcon}`,
      name: 'ธนาคารกรุงไทย',
    },
    {
      profile: `${UOBIcon}`,
      name: 'ธนาคารยูโอบี',
    },
    {
      profile: `${krungsriIcon}`,
      name: 'ธนาคารกรุงศรี',
    },
  ]
  const Eperiod: any = {
    ALL: 'ทั้งหมด',
  }
  return (
    <Formik
      // innerRef={formikRef}
      initialValues={{
        income: [],
        expense: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        console.log(values, 'SUBMITTING')
      }}
    >
      {(props: any) => (
        <form className={styles.containerPeriod} onSubmit={props.handleSubmit}>
          <p className={styles.TextPeriod}>
            {fcProps.period === 'ALL' ? 'งวดเงินเดือนทั้งหมด' : `งวดเงินเดือนที่ ${fcProps.period}`}
          </p>
          <div className={styles.cardClose}>
            <div className={styles.date}>
              <p>วันที่ทำการปิดงวดเงินเดือน</p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  // disableFuture
                  // label="เลือกเดือน"
                  openTo="day"
                  views={['day']}
                  value={value}
                  onChange={(newValue: any) => {
                    setValue(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className={styles.button}>
              <ButtonComponent
                _colorBG="blue"
                _colorText="white"
                _text="ปิดงวด"
                _variant="text"
                // _isDisabled={fcProps.period === 'ALL' ? true : false}
                _type="button"
                _functionOnClick={() => {
                  console.log(fcProps.date, fcProps.payrollList.period, value.toISOString().split('T')[0])
                  if (fcProps.period === 'ALL') {
                    dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        'เลือกงวดเงินเดือนเพื่อปิดงวด',
                        'สามารถทำรายการปิดงวดเงินเดือนได้สูงสุด 1 รายการ',
                        'info',
                      ),
                    )
                  } else {
                    dispatch(
                      allAction.modalConfirmAction.setOpenModalConfirm(
                        'ต้องการปิดงวดเงินเดือน',
                        `งวดเงินเดือนที่ ${fcProps.period}`,
                        'ไม่สามารถเปลี่ยนแปลงข้อมูลเงินเดือนได้เมื่อปิดงวด',
                        'ใช่,ปิดงวด',
                        () => {
                          ClosePayroll({
                            yearMonth: fcProps.date,
                            period: fcProps.period,
                            closeDate: value.toISOString().split('T')[0],
                          }).then((res) => {
                            console.log(res, 'Close Payroll')
                          })
                          dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                          dispatch(
                            allAction.modalAction.setOpenModalAlertStatus(
                              'ปิดงวดเงินเดือนเรียบร้อยแล้ว',
                              '',
                              'success',
                            ),
                          )
                        },
                      ),
                    )
                  }
                }}
              />
              <img src="" alt="" />
            </div>
          </div>
          {console.log(fcProps?.payrollList?.status, 'fcProps?.payrollList?.status New')}
          {fcProps?.payrollList?.status === 'PAID' ? (
            // fcProps?.payrollList?.status === 'PAID'
            <>
              <div className={styles.cardPayment}>
                <section className={styles.Cardslip}>
                  <p className={styles.textHead}>สลิปเงินเดือน</p>
                  <InputTextComponent _name={'search'} _label={'ค้นหา'} />

                  <section className={styles.tabList}>
                    {fcProps.payrollList.employeePayrollList.map((item: any, Index: number) => (
                      <div className={styles.GroupText} key={Index}>
                        <p className={styles.textDetail}>
                          <Tooltip title={<p>{item.employeeID}</p>} placement="top" arrow>
                            <p className={styles.textId}>{item.employeeID} </p>
                          </Tooltip>
                          {item?.employee?.fullName + ' ' + item?.employee?.lastName}
                        </p>
                        <section className={styles.Icon}>
                          <img src={emailIcon} alt="" />
                          <img src={fileIcon} alt="" onClick={() => downloadFile(item.paySlipURL, item.paySlipURL)} />
                          <a href={item.paySlipURL} target="_blank" download rel="noreferrer">
                            <img src={printIcon} alt="" />
                          </a>
                        </section>
                      </div>
                    ))}
                  </section>
                  <section className={styles.buttonReport}>
                    <button
                      className={styles.btReport}
                      onClick={() => {
                        fcProps.payrollList.periodList.map((item: any, index: number) =>
                          downloadFile(item.paySlipEmpPeriod, item.paySlipEmpPeriod),
                        )
                      }}
                    >
                      ดาวน์โหลดสลิปเงินเดือนทั้งหมด <img src={fileblueIcon} alt="" />
                    </button>

                    <a
                      href={fcProps.payrollList.paySlipEmpPeriod}
                      target="_blank"
                      download
                      rel="noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <div className={styles.btReport}>
                        ปริ้นสลิปเงินเดือนทั้งหมด <img src={printblueIcon} alt="" />
                      </div>
                    </a>

                    <button className={styles.btReport}>
                      E - Slip <img src={emailblueIcon} alt="" />
                    </button>
                  </section>
                </section>
                <section className={styles.disabledCard}>
                  <p className={styles.textHead}>รายงานเอกสารนำส่งธนาคาร</p>

                  <section className={styles.tabList}>
                    {BankData.map((item: any, Index: number) => (
                      <div className={styles.GroupText} key={Index}>
                        <div className={styles.bankGroup}>
                          <img src={item.profile} alt="" />
                          <p className={styles.textDetail}>{item.name}</p>
                        </div>

                        <section className={styles.Icon}>
                          <img src={txtIcon} alt="" />
                          <img src={pdfIcon} alt="" />
                          <img src={csvIcon} alt="" />
                        </section>
                      </div>
                    ))}
                  </section>
                  {console.log('xxxx')}

                  <section className={styles.buttonUpload}>
                    <p className={styles.text}>อัพโหลดฟอร์มการนำส่งเงินเดือน</p>
                    <div className={styles.btUpload}>
                      <UploadButton />
                    </div>
                  </section>
                </section>
              </div>
              <div className={styles.cardPayment}>
                <section className={styles.Cardslip}>
                  <p className={styles.textHead}>รายงานผลการคำนวณเงินเดือน</p>
                  <section className={styles.buttonReport}>
                    <button className={styles.btReport}>
                      ดาวน์โหลดรายงาน <img src={fileblueIcon} alt="" />
                    </button>
                    <button className={styles.btReport}>
                      ปริ้นรายงาน <img src={printblueIcon} alt="" />
                    </button>
                    <button className={styles.btReport}>
                      ส่งจดหมายอิเล็กทอนิกส์ <img src={emailblueIcon} alt="" />
                    </button>
                  </section>
                </section>
                <section className={styles.disabledCard}>
                  <p className={styles.textHead}>นำส่งข้อมูล</p>
                  <section className={styles.buttonReport}>
                    <button className={styles.btReport}>
                      ดาวน์โหลดรายงาน <img src={fileblueIcon} alt="" />
                    </button>
                    <button className={styles.btReport}>
                      ปริ้นรายงาน <img src={printblueIcon} alt="" />
                    </button>
                    <button className={styles.btReport}>
                      ส่งจดหมายอิเล็กทอนิกส์ <img src={emailblueIcon} alt="" />
                    </button>
                  </section>
                </section>
              </div>
            </>
          ) : null}
        </form>
      )}
    </Formik>
  )
}

export default ClosePeriod
