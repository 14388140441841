import * as React from 'react'
// import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
// import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
//svg
// import editIcon from '../../image/edit.svg'
// import deleteIcon from '../../image/del.svg'
// import { useDispatch } from 'react-redux'
// import allAction from '../../state/actions'
import moment from 'moment'
import { TimeAttendance } from '../../utils/generated'

export const TableAttend: React.FC<{ log: TimeAttendance[] }> = (props: { log: TimeAttendance[] }): JSX.Element => {
  // const [leaderList, setLeaderList] = React.useState([] as any)
  const [memberList, setMemberList] = React.useState([] as any)

  const statusStyle = (Type: any) => {
    switch (Type) {
      case 'ATTENDANCE':
        return '#FFFFFF'
        break
      case 'OT':
        return '#FFD24C'
        break
      case 'LEAVE':
        return '#FF6363'
        break
      case 'WEEKEND':
        return '#DFDFDE'
        break
      case 'HOLIDAY':
        return '#FFA8A8'
        break
      default:
        return '#FFFFFF'
    }
  }

  const EScan: any = {
    SCAN_IN: 'สแกนเข้างาน',
    SCAN_OUT: 'สแกนออกงาน',
  }
  //* Check
  // console.log('listMember :', memberList)
  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center">
              <h4>รหัสพนักงาน</h4>
            </TableCell>
            <TableCell align="center">
              <h4>ชื่อ - นามสกุล</h4>
            </TableCell>
            <TableCell align="center">
              <h4>ประเภท</h4>
            </TableCell>
            <TableCell align="center">
              <h4>สถานที่</h4>
            </TableCell>
            <TableCell align="center">
              <h4>เวลาที่แสกน</h4>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.log.length
            ? props.log.map((EmpItem: any, EmpIndex: number) => (
                <TableRow key={EmpIndex}>
                  <TableCell align="center">
                    <img src={EmpItem.imageUrl} alt="" />
                  </TableCell>
                  <TableCell align="center">
                    <p> {EmpItem.employeeID}</p>
                  </TableCell>
                  <TableCell align="center">
                    <span>
                      {' '}
                      {EmpItem.employee?.fullName} {EmpItem.employee?.lastName}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <p>{EScan[`${EmpItem.scanReason}`]}</p>
                  </TableCell>
                  <TableCell align="center">
                    <p> {EmpItem.locationType}</p>
                  </TableCell>
                  <TableCell align="center">
                    <p> {moment(EmpItem.scanAt).format('lll')}</p>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </>
  )
}

// TableAttend.propTypes = {
//   log: PropTypes.array.isRequired,
// }
