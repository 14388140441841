import React, { useRef, useState, useEffect } from 'react'
import styles from './index.module.scss'

import { Formik } from 'formik'
import * as yup from 'yup'
import { ButtonComponent } from '../../../component'
import TableIncomeExpense, { ITableHeaderList } from './table'
import { putEmployeePayroll } from '../../../adapter/xhr'

interface IExpenseIncome {
  payrollList?: any
  period?: string
  date?: string
}

const ExpenseIncome: React.FC<IExpenseIncome> = (fcProps: IExpenseIncome): JSX.Element => {
  const validationSchema = yup.object({})
  const formikRef: any = useRef(null)
  const [incomeTableHeaderList, setIncomeTableHeaderList] = useState<any[]>([])
  const [expenseTableHeaderList, setExpenseTableHeaderList] = useState<any[]>([])

  const packTableHeader = (otherIncomeOrExpenseList: any[]) => {
    const tableHeaderList = [
      ...globalTableHeaderList,
      ...otherIncomeOrExpenseList
        .map((element: any) => {
          if (element.variableOrFixed === 'VARIABLE' && element.incomeExpenseType !== 'AUTO') {
            return { text: element.name, key: element.id, type: 'input', inputType: 'number' }
          }
        })
        .filter(Boolean),
    ]
    return tableHeaderList
  }

  const packValue = (valueList: any, otherIncomeOrOtherExpense: 'otherIncomeList' | 'otherExpenseList') => {
    const packValueList = valueList.map((employeeElement: any) => {
      const packValue: any = {
        departmentName: employeeElement?.employee?.department?.departmentName,
        employeeID: employeeElement?.employeeID,
        employeeName:
          (employeeElement?.employee?.fullName ? employeeElement?.employee?.fullName : null) +
          ' ' +
          (employeeElement?.employee?.lastName ? employeeElement?.employee?.lastName : null),
      }
      employeeElement[`${otherIncomeOrOtherExpense}`].map((element: any) => {
        if (element.variableOrFixed === 'VARIABLE' && element.incomeExpenseType !== 'AUTO') {
          packValue[element.id] = Math.abs(element.amount)
        }
      })
      return packValue
    })
    return packValueList
  }

  useEffect(() => {
    // queryListPayrollExpenseIncome({
    //   yearMonth: '2022-04',
    //   period: '1.1',
    // }).then((res: any) => {
    //   console.log(res, 'list PayrollResponse')
    const listPayroll: any = fcProps.payrollList
    console.log(listPayroll, 'listPayroll')
    if (listPayroll && listPayroll.employeePayrollList.length) {
      const newTableIncomeList = packTableHeader(listPayroll.employeePayrollList[0].otherIncomeList)
      setIncomeTableHeaderList(newTableIncomeList)
      const incomeValueList = packValue(listPayroll?.employeePayrollList, 'otherIncomeList')
      formikRef.current.setFieldValue('income', incomeValueList)

      const newTableExpenseList = packTableHeader(listPayroll.employeePayrollList[0].otherExpenseList)
      setExpenseTableHeaderList(newTableExpenseList)
      const expenseValueList = packValue(listPayroll?.employeePayrollList, 'otherExpenseList')
      formikRef.current.setFieldValue('expense', expenseValueList)
    }

    // })
  }, [fcProps.payrollList])

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        income: [],
        expense: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        const cloneListPayroll = fcProps.payrollList.employeePayrollList.map((employeePayrollElement: any) => {
          values.income.map((incomeElement: any) => {
            const { departmentName, employeeName, employeeID, ...incomeFilterElement } = incomeElement
            if (employeeID === employeePayrollElement.employeeID) {
              Object.entries(incomeFilterElement).forEach(([key, value]) => {
                employeePayrollElement.otherIncomeList.map((otherIncomeElement: any) => {
                  if (otherIncomeElement.id === key) {
                    value === '' || !value ? (otherIncomeElement.amount = 0) : (otherIncomeElement.amount = value)
                  }
                })
              })
            }
          })
          values.expense.map((expenseElement: any) => {
            const { departmentName, employeeName, employeeID, ...expenseFilterElement } = expenseElement
            if (employeeID === employeePayrollElement.employeeID) {
              Object.entries(expenseFilterElement).forEach(([key, value]: any) => {
                employeePayrollElement.otherExpenseList.map((otherExpenseElement: any) => {
                  if (otherExpenseElement.id === key) {
                    value === '' || !value
                      ? (otherExpenseElement.amount = 0)
                      : (otherExpenseElement.amount = -Math.abs(value))
                  }
                })
              })
            }
          })
          // workingMinute: tWork, // วันทำงาน
          //   leaveMinute: tLeave, // วันลาไม่หัก
          //   leaveDeductMinute: tLeaveDeduct, // วันลาหัก
          //   otTypeWorkMinute: tOTTypeWork, // วันทำงานวันหยุด (โอที)
          //   otTypeOTMinute: tOTTypeOT, // วันโอที
          //   // Money Columns
          //   income: A, // เงินเดือน A
          //   otTypeWorkIncome: C1, // OT C1 (+)
          //   otTypeOTIncome: C2, // OT C2 (+)
          //   otherIncomeList, // รายรับ D (+)
          //   welfareList, // สวัสดิการ F (+)
          //   workDiligentIncome: E, // เบี้ยขยัน E (+)
          //   inEarlyIncome: B_IE, // เข้าก่อน (+)
          //   breakEarlyIncome: B_BE, // พักเร็ว (+)
          //   outLateIncome: B_OL, // ออกสาย (+)
          //   netPositiveIncome: A + C1 + C2 + D_INCOME + E + B_IE + B_BE + B_OL +F, // รวมรายรับ
          //   otherExpenseList, // รายหัก D (-)
          //   inLateExpense: B_IL, // เข้าสาย (-)
          //   breakLateExpense: B_BL, // พักเกิน (-)
          //   outEarlyExpense: B_OE, // ออกก่อน (-)
          //   netNegativeIncome: D_EXPENSE + B_IL + B_BL + B_OE, // รวมรายจ่าย
          //   netIncome, // รวมสุทธิ
          const {
            income,
            otTypeWorkIncome,
            otTypeOTIncome,
            otherIncomeList,
            workDiligentIncome,
            welfareList,
            inEarlyIncome,
            breakEarlyIncome,
            outLateIncome,
            //expense
            otherExpenseList,
            inLateExpense,
            breakLateExpense,
            outEarlyExpense,
          } = employeePayrollElement

          employeePayrollElement.netPositiveIncome =
            income +
            otTypeWorkIncome +
            otTypeOTIncome +
            (otherIncomeList.length
              ? JSON.parse(JSON.stringify(otherIncomeList))
                  .map((incomeElement: any) => incomeElement.amount)
                  .reduce((a: number, b: number) => a + b)
              : 0) +
            workDiligentIncome +
            inEarlyIncome +
            breakEarlyIncome +
            outLateIncome +
            (welfareList.length
              ? JSON.parse(JSON.stringify(welfareList))
                  .map((welfareElement: any) => welfareElement.amount)
                  .reduce((a: number, b: number) => a + b)
              : 0)

          employeePayrollElement.netNegativeIncome =
            (otherExpenseList.length
              ? JSON.parse(JSON.stringify(otherExpenseList))
                  .map((expenseElement: any) => expenseElement.amount)
                  .reduce((a: number, b: number) => a + b)
              : 0) +
            inLateExpense +
            breakLateExpense +
            outEarlyExpense
          employeePayrollElement.netIncome =
            employeePayrollElement.netPositiveIncome + employeePayrollElement.netNegativeIncome
          const { employee, ...putVariables } = employeePayrollElement
          return putVariables
        })

        const variables = {
          yearMonth: fcProps.date,
          period: fcProps.period,
          input: cloneListPayroll,
        }
        console.log(variables, 'variables')
        putEmployeePayroll(variables).then((res: any) => {
          console.log(res, 'res putEmployeePayroll')
        })
      }}
    >
      {(props: any) => (
        <form className={styles.container} onSubmit={props.handleSubmit}>
          {/* <InputTextComponent
            _name="searchValues"
            _label="ค้นหาแผนก/รหัส/รายชื่อพนักงาน"
            _helperText={props.errors.searchValues}
          />
          <div className={styles.toolbarsContainer}>
            <section className={styles.leftToolbars}>
              <article className={styles.configBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="blue"
                  _colorText="white"
                  _variant="outlined"
                  _isIcon="expenseIncome"
                  _text="ตั้งค่ารายจ่าย"
                  _functionOnClick={() => {
                    console.log('button Click !!')
                  }}
                />
              </article>
              <article className={styles.filterBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="transparent"
                  _colorText="blue"
                  _variant="text"
                  _isIcon="filter"
                  _text="ตัวกรองข้อมูล"
                  _functionOnClick={() => {
                    console.log('button Click !!')
                  }}
                />
              </article>
            </section>
            <section className={styles.rightToolbars}>
              <label className={styles.fileUpload}>
                <article className={styles.chooseFileBtn}>
                  <ButtonComponent
                    _type="button"
                    _colorBG="lightBlue"
                    _colorText="blue"
                    _variant="outlined"
                    _text="เลือกไฟล์"
                  />
                </article>
                ไม่ได้เลือกไฟล์ใด
              </label>
              <input
                className={styles.inputfile}
                type="file"
                onChange={(event: any) => {
                  console.log(event.target.files)
                }}
              />
              <article className={styles.uploadFileBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="lightBlue"
                  _colorText="blue"
                  _variant="outlined"
                  _text="อัพโหลดไฟล์"
                />
              </article>
              <article className={styles.downloadTemplateBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="secondary"
                  _colorText="white"
                  _variant="outlined"
                  _text="ดาวน์โหลดเทมเพลท"
                />
              </article>
            </section>
          </div> */}
          <TableIncomeExpense
            topic="รายรับ"
            prefixValues="income"
            tableHeaderList={incomeTableHeaderList}
            tableBodyList={props.values.income}
          />
          <TableIncomeExpense
            topic="รายจ่าย"
            prefixValues="expense"
            tableHeaderList={expenseTableHeaderList}
            tableBodyList={props.values.expense}
          />
          {fcProps.payrollList ? (
            <section className={styles.submitSection}>
              <div className={styles.submitBtnContainer}>
                <ButtonComponent
                  _isDisabled={fcProps.period === 'ALL'}
                  _type="submit"
                  _colorBG="blue"
                  _colorText="white"
                  _variant="outlined"
                  _text="บันทึก"
                />
              </div>
            </section>
          ) : null}
        </form>
      )}
    </Formik>
  )
}

export default ExpenseIncome

const mockUpIncome = [
  {
    departmentName: 'บัญชี',
    employeeID: 'xxx-xxx-xxx-xxx',
    employeeName: 'Nattawat Jantarasombat',
    incentive: '0',
    diligent: '0',
    positionValue: '0',
    consultValue: '0',
    bonus: '0',
  },
]

const mockUpExpense = [
  {
    departmentName: 'การตลาด',
    employeeID: 'yyy-yyy-yyy-yyy',
    employeeName: 'Prayup Jc',
    socialSecurity: '0',
    studentLoan: '0',
    reserveFund: '0',
    other: '0',
  },
]

const globalTableHeaderList: ITableHeaderList[] = [
  {
    text: 'ลำดับ',
    key: 'No.',
    type: 'text',
  },
  {
    text: 'แผนก',
    key: 'departmentName',
    type: 'text',
  },
  {
    text: 'รหัสพนักงาน',
    key: 'employeeID',
    type: 'text',
  },
  {
    text: 'ชื่อพนักงาน',
    key: 'employeeName',
    type: 'text',
  },
]
