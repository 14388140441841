import React from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop } from '@mui/material'
import { ButtonComponent } from '../../../component'
import { useDispatch } from 'react-redux'
import allAction from '../../../state/actions/index'
import TextField from '@mui/material/TextField'
import FormControl, { useFormControl } from '@mui/material/FormControl'

interface Props {
  open: boolean
  close: any
  data: any
  btnDone?: string
  isCall?: boolean
}

const ModalConfirmApprove: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <>
      {props.data.map((data: any) => {
        return (
          <>
            <Modal
              open={props.open}
              onClose={props.close}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <div className={styles.modalBox}>
                <div className={styles.card}>
                  <div className={styles.textWrapper}>
                    <p className={styles.textTopic}>{data.title}</p>
                    <p className={styles.textSubtitlet}>{data.subtitle}</p>
                    <p className={styles.textDescription}>{data.description}</p>
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        id="outlined-basic"
                        label="หมายเหตุ"
                        variant="outlined"
                        size="small"
                        className={styles.bgTextfield}
                      />
                    </FormControl>
                  </div>
                  <div className={styles.bottom}>
                    <div className={styles.buttonWrapper}>
                      <div className={styles.btnBox}>
                        <ButtonComponent
                          _variant="outlined"
                          _text="ยกเลิก"
                          _colorText="white"
                          _colorBG="red"
                          _type="button"
                          _functionOnClick={() => {
                            props.close(false)
                            dispatch(
                              allAction.modalAction.setOpenModalAlertStatus('ปฎิเสธรายการ', 'เรียบร้อย', 'error'),
                            )
                          }}
                        />
                      </div>
                      <div className={styles.btnFooterWrapper}>
                        <div className={styles.btnBox}>
                          <ButtonComponent
                            _variant="outlined"
                            _text="อนุมัติ"
                            _colorText="white"
                            _colorBG="blue"
                            _type="button"
                            _functionOnClick={() => {
                              props.close(false)
                              dispatch(
                                allAction.modalAction.setOpenModalAlertStatus('อนุมัติรายการ', 'เรียบร้อย', 'success'),
                              )
                            }}
                          />
                        </div>
                        {props.isCall === true ? (
                          <div className={styles.btnBox}>
                            <ButtonComponent
                              _variant="text"
                              _text="โทรหา"
                              _colorText="secondary"
                              _colorBG="white"
                              _type="button"
                              _functionOnClick={() => {
                                window.open('tel:0952393564')
                                props.close(false)
                                dispatch(
                                  allAction.modalAction.setOpenModalAlertStatus(
                                    'อนุมัติรายการ',
                                    'เรียบร้อย',
                                    'success',
                                  ),
                                )
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        )
      })}
    </>
  )
}
export default ModalConfirmApprove
