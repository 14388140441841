import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import ButtonComponent from '../../component/Button'
import NavSideBarComponent from '../../component/NavSideBar'
import NavTopBarComponent from '../../component/NavTopBar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import search from '../../image/search.svg'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import detail from '../../image/detail.svg'
import delIcon from '../../image/del.svg'
import editIcon from '../../image/edit.svg'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { DepartmentTable } from './table_department'
import { queryListDepartment, queryListDepartmentTable, deleteDepartment } from '../../adapter/xhr'

const Department: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  //Departmentlist
  const [departmentList, setDepartmentList] = useState([])
  const [departmentListTable, setDepartmentListTable] = useState([])
  const [idDepartmentList, setIdDepartmentlist] = useState('')
  const [deleteDepartmentList, setdeleteDepartmentList] = useState()
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [searchbar, setSearchbar] = useState(false)
  const [showTable, setShowTable] = useState([] as any)
  const navigate = useNavigate()

  useEffect(() => {
    queryListDepartment().then((res: any) => {
      setDepartmentList(res.data.data.listDepartment.items)
    })
    queryListDepartmentTable().then((res: any) => {
      setDepartmentListTable(res.data.data.listDepartment.items)
    })
  }, [])
  // console.log('DPMList', departmentList)
  // console.log('DPMList ID', idDepartmentList)
  // console.log('DPMList Table', departmentListTable)
  // console.log('DPMList Delete', deleteDepartmentList)

  //todo:> set data table onClick Card
  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent />
      </div>
      <aside className="sidebar">
        <NavSideBarComponent />
      </aside>

      <div className="main">
        <div className={styles.container}>
          <div className={styles.navtop}>
            <h1 className={styles.title}>แผนกพนักงาน</h1>
            <div className={styles.head}>
              <div className={styles.iconBar}>
                {!searchbar && (
                  <div className={styles.ellipse}>
                    <img src={search} alt="" className={styles.search} onClick={() => setSearchbar(true)} />
                  </div>
                )}

                {searchbar && (
                  <div className={styles.searchbar} onClick={() => setSearchbar(false)}>
                    <TextField
                      label="Search Employee"
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: '25ch' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={search} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.cardView}>
            <div className={styles.head}>
              <div className={styles.btAdd}>
                {' '}
                <ButtonComponent
                  _isIcon="add"
                  _variant="contained"
                  _text="เพิ่มแผนก"
                  _colorText="white"
                  _colorBG="blue"
                  _type="button"
                  _functionOnClick={() => navigate('../employee/department/add')}
                />
              </div>
            </div>
            <div className={styles.content}>
              {departmentList.map((depart: any) => (
                <div
                  className={styles.card}
                  key={depart.id}
                  onClick={() => {
                    setIdDepartmentlist(depart.id)
                    setShowTable(depart.departmentName)
                  }}
                >
                  <div className={styles.headCard}>
                    <p className={styles.text}>{depart.departmentName} </p>
                    <img src={detail} alt="" className={styles.detail} onClick={handleClick} />
                  </div>
                  <div className={styles.bottomCard}>
                    <p className={styles.text}>Total employee</p>
                    <p className={styles.textTotal}>{depart.listEmployee.items.length}</p>
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => navigate(`../employee/department/edit/${idDepartmentList}`)}>
                      <span className={styles.menulist}>
                        <img src={editIcon} alt="" /> Edit
                      </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          allAction.modalConfirmAction.setOpenModalConfirm(
                            'ลบข้อมูลแผนก',
                            'คุณต้องการลบข้อมูลแผนก ?',
                            'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                            '',
                            () => {
                              const deleteDepartmentValiable = {
                                input: {
                                  id: idDepartmentList, //todo:get > idDepartmentList
                                },
                              }
                              deleteDepartment(deleteDepartmentValiable).then((res: any) => {
                                // console.log('delete department', res.data.data.deleteDepartment)
                                setdeleteDepartmentList(res.data.data.deleteDepartment)
                              })
                              dispatch(allAction.modalAction.setOpenModalAlertStatus('ลบข้อมูลสำเร็จ', '', 'success'))
                              dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                            },
                          ),
                        )
                      }}
                    >
                      <span className={styles.menulist}>
                        <img src={delIcon} alt="" /> Delete
                      </span>
                    </MenuItem>
                  </Menu>
                </div>
              ))}
            </div>
          </div>

          {idDepartmentList ? (
            departmentListTable
              .filter((departTable: any) => departTable.id === idDepartmentList)
              .map((departTableList: any) => {
                return (
                  <div className={styles.cardTable} key={departTableList.id}>
                    <div>
                      <p className={styles.title}>{departTableList.departmentName} </p>{' '}
                      <DepartmentTable tableList={departTableList.listEmployee} />
                    </div>
                  </div>
                )
              })
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="footer">Footer</div>
    </div>
  )
}

export default Department
