// import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ICombineReducers } from 'src/state/reducers'

const useCompanyID = (): string => {
  const dispatch = useDispatch()

  const getSubdomain = (domain: string): string => {
    const domainSplit = domain.split('.')
    if (domainSplit.length < 3) return 'innotech' // This is localhost
    // return domainSplit[domainSplit.length - 2]
    return domainSplit[0] // Change it back to first, since wildcard must be at far left
  }

  const subdomain = getSubdomain(window.location.host)
  const companyID = useSelector((state: ICombineReducers) => state.companyIDReducer.companyID)
  if (subdomain !== companyID) {
    dispatch({
      type: 'COMPANY/CHANGE',
      companyID: subdomain,
    })
  }

  return companyID
}

export default useCompanyID
