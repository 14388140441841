import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop, CircularProgress, Tooltip } from '@mui/material'
import { useDispatch } from 'react-redux'
import allAction from '../../../state/actions'
import * as yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import AddIcon from '../../../image/plusIcon.svg'
import { ButtonComponent, InputTimeComponentCustom } from '../../../component'
import LinkIcon from '../../../image/Link.svg'
import UnLinkIcon from '../../../image/Unlink.svg'
import { useRef } from 'react'
import {
  CreateOvertimeDocument,
  DelRequestLeave,
  DelRequestOvertime,
  QueryGetAttendance,
  UpdateAttendanceReport,
} from '../../../adapter/xhr'
import { InputSelectComponentCustom } from '../../../component/InputsFormik'
import { InputCountDiff, ModalMultiplierOT, SumCard } from './componentInput'
import { ModalLeave } from './ModalLeave'
import { ModalOverTime } from './ModalOT'
import { ELeave, EOverTime, EStatus } from '../EType'
import { combineList, deepClone, toTime } from '../../../utils/common'
import resetTimeAttendanceReport from '../../../utils/resetTimeAttendanceReport'
import { EScanReasonType } from '../../../utils/generated'
import DetailIcon from '../../../image/detailIcon.svg'
import swal from 'sweetalert'
import { ModalLeavePopper } from '../ModalPopper'
import Switch from '../../../component/Switch'

// import swal from '@sweetalert/with-react'

export const isSame = (startTime: any, endTime: any) => {
  return !moment(startTime, 'HH:mm').isSame(moment(endTime, 'HH:mm'))
}
export const ModalAttendaneEdite: React.FC<any> = (props: any): JSX.Element => {
  const [openModalmulti, setOpenModalmulti] = useState(false)
  const [tabAction, setTabAction] = useState<any>({ 0: false, 1: false })
  const dispatch = useDispatch()
  const formikRef: any = useRef()
  const [openModalLeave, setOpenModalLeave] = React.useState(false)
  const [openModalOT, setOpenModalOT] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  // const [attendance, loading] = useGetAttendance(props.employeeId, props.selectdate)
  const [attendance, setAttendance] = React.useState<any>({})
  const localizer = momentLocalizer(moment)

  const setFieldValueRef = (name: string, data: any) => {
    formikRef.current.setFieldValue(name, data)
  }

  React.useEffect(() => {
    if (!props.openmodal) return
    if (props.selectdate && props.employeeId) {
      QueryGetAttendance({ date: props.selectdate, employeeId: props.employeeId }).then((res) => {
        const _data = res.data.data.getTimeAttendanceReport
        console.log(_data, '_data Link')

        setAttendance(res.data.data.getTimeAttendanceReport)
        attendance ? setLoading(true) : setLoading(false)
        // console.log(formikRef.current, 'formikRef.current')
        if (_data.scanIn) {
          setFieldValueRef('scanIn', toTime(_data.scanIn))
        }
        if (_data.scanIn) {
          setFieldValueRef('scanIn', toTime(_data?.scanIn))
        }
        if (_data.scanOut) {
          setFieldValueRef('scanOut', toTime(_data?.scanOut))
        }
        const _leaveList: any[] = []

        if (_data.leaveList) {
          _data.leaveList.map((leaveElement: any) => {
            _leaveList.push({
              ...leaveElement,
              startedAt: toTime(leaveElement.startedAt),
              endedAt: toTime(leaveElement.endedAt),
              type: 'leave',
            })
          })
        }
        if (_data.leaveDeductList) {
          _data.leaveDeductList.map((leaveElement: any) => {
            _leaveList.push({
              ...leaveElement,
              startedAt: toTime(leaveElement.startedAt),
              endedAt: toTime(leaveElement.endedAt),
              type: 'leaveDeduct',
            })
          })
        }
        setFieldValueRef('leaveList', _leaveList)
        const _overTime: any[] = []

        if (_data.overtimeList) {
          _data.overtimeList.map((OverTimeList: any) => {
            _overTime.push({
              ...OverTimeList,
              startedAt: toTime(OverTimeList.startedAt),
              endedAt: toTime(OverTimeList.endedAt),
              type: 'overtime',
            })
          })
        }
        if (_data.overtimeApproveList) {
          _data.overtimeApproveList.map((OverTimeElement: any) => {
            _overTime.push({
              ...OverTimeElement,
              startedAt: toTime(OverTimeElement.startedAt),
              endedAt: toTime(OverTimeElement.endedAt),
              type: 'overtimeApprove',
            })
          })
        }
        setFieldValueRef('overtimeList', _overTime)
        if (_data.breakDiffMinute) {
          setFieldValueRef('breakDiffMinute', _data.breakDiffMinute)
        }
        if (_data.outDiffMinute) {
          setFieldValueRef('outDiffMinute', _data.outDiffMinute)
        }
        if (_data.inDiffMinute) {
          setFieldValueRef('inDiffMinute', _data.inDiffMinute)
        }
        if (_data.isLink) {
          setFieldValueRef('isLink', _data.isLink)
        }

        const WorkTimeList: any = []
        if (_data.workingTimeList) {
          _data.workingTimeList.map((workItem: any) => {
            WorkTimeList.push({
              ...workItem,
              startedAt: toTime(workItem.startedAt),
              endedAt: toTime(workItem.endedAt),
            })
          })

          setFieldValueRef('workingTimeList', WorkTimeList)
        }
        // if (_data.workingMinute) {
        //   setFieldValueRef('workingMinute', _data.workingMinute)
        // }
      })
    }
  }, [props.selectdate, props.employeeId, props.openmodal])

  const isSameOrBefore = (startTime: any, endTime: any) => {
    if (!startTime && !endTime) {
      return true
    }
    return moment(startTime, 'HH:mm').isSameOrBefore(moment(endTime, 'HH:mm'))
  }

  const validationSchema = yup.object({
    scanIn: yup
      .string()
      .test('start_time_test', 'เวลาสแกนเออกต้องมากกว่าเวลาแสกนเข้า', function (value) {
        const { scanOut } = this.parent
        return isSameOrBefore(value, scanOut)
      })
      .test('isSameTest', 'เวลาสแกนเข้าและแสกนออกเท่ากัน', function (value) {
        const { scanOut } = this.parent
        return isSame(value, scanOut)
      }),
    scanOut: yup.string(),
  })
  const otherprops = props

  const LeaveList = [
    { label: 'ลาป่วย', value: 'REASON_SICK' },
    { label: 'ลาพักร้อน', value: 'REASON_ANNUAL_LEAVE' },
    { label: 'ลากิจ', value: 'REASON_PERSONAL_LEAVE' },
    {
      label: 'ขาด',
      value: 'ABSENT',
    },
  ]
  const OTList = [
    { label: 'วันทำงาน', value: 'WORK' },
    { label: 'ทำงานล่วงเวลา', value: 'OVERTIME' },
  ]

  const GetEvent = (getAttendance: any) => {
    const newAttendance: any[] = []
    getAttendance?.leaveList?.map((eventLeave: any) => {
      return newAttendance.push({
        title: `${ELeave[`${eventLeave.leaveType}`]}`,
        allDay: false,
        start: new Date(`${attendance.date}:${eventLeave.startedAt}`), // 10.00 AM
        end: new Date(`${attendance.date}:${eventLeave.endedAt}`),
        type: 'Leave',
      })
    })
    getAttendance?.overtimeList?.map((eventOT: any) => {
      return newAttendance.push({
        title: `${EOverTime[`${eventOT.overtimeType}`]}`,
        allDay: false,
        start: new Date(`${attendance.date}:${eventOT.startedAt}`),
        end: new Date(`${attendance.date}:${eventOT.endedAt}`),
        type: 'OT',
      })
    })
    return newAttendance
  }

  const ResetAttendance = (values: any) => {
    const newOvertimeList: any[] = []
    const newOvertimeApprove: any[] = []
    const newOvertime = deepClone(values.overtimeList).map((overtimeObject: any) => {
      overtimeObject.startedAt = new Date(`${props.selectdate}:${overtimeObject.startedAt}`).toISOString()
      overtimeObject.endedAt = new Date(`${props.selectdate}:${overtimeObject.endedAt}`).toISOString()
      if (overtimeObject.type === 'overtime') {
        delete overtimeObject.type
        return newOvertimeList.push(overtimeObject)
      } else {
        delete overtimeObject.type
        return newOvertimeApprove.push(overtimeObject)
      }
    })

    const newLeaveDeductList: any[] = []
    const newLeaveList: any[] = []
    const LeaveList = deepClone(values.leaveList).map((leaveObject: any) => {
      leaveObject.startedAt = new Date(`${props.selectdate}:${leaveObject.startedAt}`).toISOString()
      leaveObject.endedAt = new Date(`${props.selectdate}:${leaveObject.endedAt}`).toISOString()
      if (leaveObject.type === 'leave') {
        delete leaveObject.type
        return newLeaveList.push(leaveObject)
      } else {
        delete leaveObject.type
        return newLeaveDeductList.push(leaveObject)
      }
    })

    const employees = [
      {
        id: props.employeeId,
      },
    ] as any[]

    const attendanceLogs = [
      {
        scanAt: `${props.selectdate}T${values.scanIn}+0700`,
        scanReason: EScanReasonType.ScanIn,
        employeeID: props.employeeId,
      },
      {
        scanAt: `${props.selectdate}T${values.scanOut}+0700`,
        scanReason: EScanReasonType.ScanOut,
        employeeID: props.employeeId,
      },
    ] as any[]

    // query defaultShift from ListShift

    const holidayList = [] as any[]

    const overtimeApproveList = newOvertimeApprove.map((e) => ({
      ...e,
      employeeID: props.employeeId,
      id: e.overtimeRequestID,
    })) as any[]

    const leaveList = combineList(
      newLeaveDeductList
        .filter((e) => e.leaveRequestID)
        .map((e) => ({
          ...e,
          employeeID: props.employeeId,
          id: e.leaveRequestID,
          isLeaveDeductMoney: true,
        })),
      newLeaveList
        .filter((e) => e.leaveRequestID)
        .map((e) => ({
          ...e,
          employeeID: props.employeeId,
          id: e.leaveRequestID,
        })),
    ) as any[]
    const overtimeConfig = {} as any
    const attendanceConfig = AttendanceConfig

    const startDate = new Date(props.selectdate)
    const endDate = new Date(props.selectdate)

    const [report] = resetTimeAttendanceReport({
      attendanceLogs,
      employees,
      defaultShift,
      attendanceConfig,
      startDate,
      endDate,
      leaveList,
      holidayList,
      overtimeApproveList,
      overtimeConfig,
    })
    return report
  }
  const handleTabAction = (index: any) => {
    if (tabAction[index] === true) {
      setTabAction({ ...tabAction, ...{ [index]: false } })
    } else {
      setTabAction({ ...tabAction, ...{ [index]: true } })
    }
  }

  const [hiddenleave, setHandleLeave] = useState<any>({ 0: false, 1: false })
  const [startAbsent, setStartAbsent] = useState('')
  const [endAbsent, setEndAbsent] = useState('')

  const handleTabActionLeave = (index: any) => {
    if (hiddenleave[index] === true) {
      setHandleLeave({ ...hiddenleave, ...{ [index]: false } })
    } else {
      setHandleLeave({ ...hiddenleave, ...{ [index]: true } })
    }
  }

  const [documentLeave, setDocumentLeave] = useState(false)

  return (
    <Modal
      open={props.openmodal}
      onClose={() => {
        props.setOpenModal(false)
        setAttendance({})
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalCalendarEvent}>
        <div className={styles.card}>
          <p>{attendance.isLink}</p>
          <div className={styles.InputGroup}>
            <Formik
              innerRef={formikRef}
              initialValues={{
                scanIn: '',
                scanOut: '',
                overtimeApproveList: [],
                overtimeList: [],
                leaveDeductList: [],
                leaveList: [],
                inDiffMinute: 0,
                breakDiffMinute: 0,
                outDiffMinute: 0,
                isLink: null,
                workingTimeList: [],
                remark: '',
                workingMinute: 0,
                overtimeMinute: 0,
                leaveMinute: 0,
                leaveDeductMinute: 0,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                const newOvertimeList: any[] = []
                const newOvertimeApprove: any[] = []

                const newOvertime = deepClone(values.overtimeList).map((overtimeObject: any) => {
                  overtimeObject.startedAt = new Date(`${props.selectdate}:${overtimeObject.startedAt}`).toISOString()
                  overtimeObject.endedAt = new Date(`${props.selectdate}:${overtimeObject.endedAt}`).toISOString()
                  if (overtimeObject.type === 'overtime') {
                    delete overtimeObject.type
                    return newOvertimeList.push(overtimeObject)
                  } else {
                    delete overtimeObject.type
                    return newOvertimeApprove.push(overtimeObject)
                  }
                })
                console.log(newOvertimeApprove, 'newOvertimeApprove')

                const newLeaveDeductList: any[] = []
                const newLeaveList: any[] = []
                const LeaveList = deepClone(values.leaveList).map((leaveObject: any) => {
                  leaveObject.startedAt = new Date(`${props.selectdate}:${leaveObject.startedAt}`).toISOString()
                  leaveObject.endedAt = new Date(`${props.selectdate}:${leaveObject.endedAt}`).toISOString()
                  if (leaveObject.type === 'leave') {
                    delete leaveObject.type
                    return newLeaveList.push(leaveObject)
                  } else {
                    delete leaveObject.type
                    return newLeaveDeductList.push(leaveObject)
                  }
                })

                const employees = [
                  {
                    id: props.employeeId,
                  },
                ] as any[]

                const attendanceLogs = [
                  {
                    scanAt: `${props.selectdate}T${values.scanIn}+0700`,
                    scanReason: EScanReasonType.ScanIn,
                    employeeID: props.employeeId,
                  },
                  {
                    scanAt: `${props.selectdate}T${values.scanOut}+0700`,
                    scanReason: EScanReasonType.ScanOut,
                    employeeID: props.employeeId,
                  },
                ] as any[]

                // query defaultShift from ListShift

                const holidayList = [] as any[]

                const overtimeApproveList = newOvertimeApprove.map((e) => ({
                  ...e,
                  employeeID: props.employeeId,
                  id: e.overtimeRequestID,
                })) as any[]

                const leaveList = combineList(
                  newLeaveDeductList
                    .filter((e) => e.leaveRequestID)
                    .map((e) => ({
                      ...e,
                      employeeID: props.employeeId,
                      id: e.leaveRequestID,
                      isLeaveDeductMoney: true,
                    })),
                  newLeaveList
                    .filter((e) => e.leaveRequestID)
                    .map((e) => ({
                      ...e,
                      employeeID: props.employeeId,
                      id: e.leaveRequestID,
                    })),
                ) as any[]

                const overtimeConfig = {} as any
                const attendanceConfig = AttendanceConfig
                const startDate = new Date(props.selectdate)
                const endDate = new Date(props.selectdate)

                console.log('>>||', values.overtimeList)

                const [report] = resetTimeAttendanceReport({
                  attendanceLogs,
                  employees,
                  defaultShift,
                  attendanceConfig,
                  startDate,
                  endDate,
                  leaveList,
                  holidayList,
                  overtimeApproveList,
                  overtimeConfig,
                })

                const eqTime = (t1: Date, t2: Date) =>
                  new Date(t1).toLocaleTimeString('th', { timeStyle: 'short' }) ===
                  new Date(t2).toLocaleTimeString('th', { timeStyle: 'short' })

                values.overtimeList
                  .filter((e: any) => e?.overtimeRequestID)
                  .forEach((ot: any) => {
                    const idx = report.overtimeList?.findIndex(
                      (e) =>
                        eqTime(new Date(`${report.date}T${ot.startedAt}+0700`), e.startedAt) &&
                        eqTime(new Date(`${report.date}T${ot.endedAt}+0700`), e.endedAt),
                    )
                    if (idx !== undefined && idx !== -1 && report?.overtimeList && report?.overtimeList[idx]) {
                      console.log(idx, ot.status, ot.startedAt)
                      report?.overtimeList.splice(idx, 1)
                    }
                  }),
                  console.log(report, 'report')

                console.log({
                  attendanceLogs,
                  employees,
                  defaultShift,
                  attendanceConfig,
                  startDate,
                  endDate,
                  leaveList,
                  holidayList,
                  overtimeApproveList,
                  overtimeConfig,
                })

                const variables: any = {
                  input: {
                    employeeID: report.employeeID,
                    date: report.date,
                    scanIn: report.scanIn,
                    scanOut: report.scanOut,
                    overtimeApproveList: report.overtimeApproveList,
                    overtimeList: report.overtimeList,
                    leaveDeductList: report.leaveDeductList,
                    leaveList: report.leaveList,
                    inDiffMinute: report.inDiffMinute,
                    breakDiffMinute: report.breakDiffMinute,
                    outDiffMinute: report.outDiffMinute,
                    isLink: values.isLink,
                    Type: report.Type,
                    leaveDeductMinute: report.leaveDeductMinute,
                    leaveMinute: report.leaveMinute,
                    overtimeApproveMinute: report.overtimeApproveMinute,
                    overtimeMinute: report.overtimeMinute,
                    workingMinute: report.workingMinute,
                    workingTimeList: report.workingTimeList,
                  },
                }

                console.log(values.isLink, 'LINK')
                if (values.isLink === false) {
                  const workingToMinute: number = values.workingMinute * 60
                  const variablesunLink: any = {
                    input: {
                      employeeID: props.employeeId,
                      date: props.date,
                      scanIn: `${new Date(`${props.date}T${values.scanIn}+0700`).toISOString()}`,
                      scanOut: `${new Date(`${props.date}T${values.scanOut}+0700`).toISOString()}`,
                      // scanOut: values.scanOut,
                      // overtimeApproveList: values.overtimeApproveList,
                      // overtimeList: values.overtimeList,
                      // leaveDeductList: values.leaveDeductList,
                      // leaveList: values.leaveList,
                      inDiffMinute: values.inDiffMinute,
                      breakDiffMinute: values.breakDiffMinute,
                      outDiffMinute: values.outDiffMinute,
                      isLink: values.isLink,
                      // Type: values.Type,
                      // leaveDeductMinute: report.leaveDeductMinute,
                      // leaveMinute: report.leaveMinute,
                      // overtimeApproveMinute: report.overtimeApproveMinute,
                      // overtimeMinute: report.overtimeMinute,
                      workingMinute: workingToMinute,
                      // workingTimeList: values.workingTimeList,
                    },
                  }
                  console.log(values.isLink, 'variablesunLink')

                  dispatch(
                    allAction.modalConfirmAction.setOpenModalConfirm(
                      `บันทึกข้อมูล `,
                      `วันที่ ${props.selectdate}`,
                      'ใช่หรือไม่',
                      'ใช่,ทำรายการ',
                      () => {
                        console.log(variablesunLink, 'variablesunLink')
                        UpdateAttendanceReport(variablesunLink).then((res: any) => {
                          console.log(res, 'Updateeee')
                        })
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        setTimeout(() => {
                          props.QueryReport(props.date, props.endAt)
                        }, 100)
                        props.setOpenModal(false)
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus('บันทึกการแก้ไขเรียบร้อยแล้ว', '', 'success'),
                        )
                      },
                    ),
                  )
                } else if (values.isLink === true) {
                  console.log(variables, 'variables')
                  dispatch(
                    allAction.modalConfirmAction.setOpenModalConfirm(
                      `บันทึกข้อมูล`,
                      `วันที่ ${props.selectdate}`,
                      'ใช่หรือไม่',
                      'ใช่,ทำรายการ',
                      () => {
                        console.log(variables, 'variables')
                        UpdateAttendanceReport(variables).then((res: any) => {
                          console.log(res, 'Updateeee')
                        })
                        dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        setTimeout(() => {
                          props.QueryReport(props.date, props.endAt)
                        }, 100)
                        props.setOpenModal(false)
                        dispatch(
                          allAction.modalAction.setOpenModalAlertStatus('บันทึกการแก้ไขเรียบร้อยแล้ว', '', 'success'),
                        )
                      },
                    ),
                  )
                }
              }}
            >
              {(props: any) => (
                <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                  <div>
                    {attendance.date === undefined ? (
                      <div className={styles.LoadCard}>
                        <CircularProgress size={100} thickness={4} sx={{ color: '#004CA8' }} />
                      </div>
                    ) : (
                      <div className={styles.Event}>
                        <div className={styles.calendarDay}>
                          {attendance.date && (
                            <Calendar
                              localizer={localizer}
                              eventPropGetter={(event: any) => {
                                const backgroundColor =
                                  event.type === 'Leave' ? '#FFC5C8' : event.type === 'OT' ? '#F8E8BD' : ''
                                return {
                                  style: {
                                    backgroundColor,
                                    border: '1px solid #FFFFFF',
                                    color: '#393939',
                                    fontSize: '14px',
                                  },
                                }
                              }}
                              events={GetEvent({
                                leaveList: props.values.leaveList,
                                overtimeList: props.values.overtimeList,
                              })}
                              startAccessor="start"
                              endAccessor="end"
                              views={['day']}
                              defaultView="day"
                              toolbar={false}
                              defaultDate={new Date(attendance.date)}
                            />
                          )}
                        </div>
                        <div className={styles.GroupInput}>
                          <section className={styles.headDate}>
                            <div className={styles.headButton}>
                              <div className={styles.buttonForm}>
                                <ButtonComponent
                                  _colorBG="white"
                                  _colorText="red"
                                  _text="ยกเลิก"
                                  _variant="text"
                                  _type="button"
                                  _functionOnClick={() => {
                                    otherprops.setOpenModal(false)
                                  }}
                                />
                              </div>
                              <div className={styles.buttonForm}>
                                <ButtonComponent
                                  _colorBG="white"
                                  _colorText="blue"
                                  _text="บันทึก"
                                  _variant="text"
                                  _type="submit"
                                />
                              </div>
                            </div>
                            <p className={styles.dateText}>
                              {new Date(attendance.date).toLocaleString('th-TH', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}
                            </p>
                            {attendance.shiftName ? (
                              <div className={styles.subText}>
                                <p> {attendance.shiftName}</p> :
                                <span>
                                  {attendance.shiftStartTime} - {attendance.shiftEndTime}
                                </span>
                                {attendance.shiftVersion ? (
                                  <div>
                                    <section className={styles.vershift}>
                                      <p> {attendance.shiftVersion}</p>
                                    </section>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                          </section>
                          <div className={styles.GroupOverFlow}>
                            <section className={styles.Group}>
                              <p className={styles.TextHead}>เวลาจริงจากเครื่องสแกนนิ้ว</p>
                              <div className={styles.filedInput}>
                                <p>ช่วงเวลา</p>
                                <InputTimeComponentCustom
                                  _name={'scanIn'}
                                  _label={'สแกนเข้างาน'}
                                  _value={props.values.scanIn}
                                />

                                <InputTimeComponentCustom
                                  _name="scanOut"
                                  _label={'สแกนออกงาน'}
                                  _value={props.values.scanOut}
                                />
                              </div>
                              {props.values.scanIn || props.values.scanOut ? (
                                <p className={styles.errortext}>{props.errors.scanIn}</p>
                              ) : (
                                ''
                              )}
                              <section className={styles.GroupBox}>
                                <section className={styles.CountInput}>
                                  <InputCountDiff
                                    _name="inDiffMinute"
                                    _value={props.values.inDiffMinute}
                                    _scanIn={props.values.scanIn}
                                    attendance={attendance}
                                    _scanOut={props.values.scanOut}
                                  />
                                </section>
                                <section className={styles.CountInput}>
                                  <InputCountDiff _name="breakDiffMinute" _value={props.values.breakDiffMinute} />
                                </section>
                                <section className={styles.CountInput}>
                                  <InputCountDiff
                                    _name="outDiffMinute"
                                    _value={props.values.outDiffMinute}
                                    attendance={attendance}
                                    _scanOut={props.values.scanOut}
                                  />
                                </section>
                              </section>
                              <div className={styles.line}></div>
                            </section>

                            <div className={styles.GroupEdit}>
                              <section className={styles.Group}>
                                <div className={styles.head}>
                                  <p className={styles.TextHead}>ช่วงเวลาทำงาน</p>
                                </div>
                              </section>
                              {props.values?.workingTimeList?.map((itemWork: any, IndexWorktime: number) => (
                                <section className={styles.Group} key={IndexWorktime}>
                                  <div className={styles.filedInput}>
                                    <p>ช่วงเวลา</p>
                                    <InputTimeComponentCustom
                                      _name={`workingTimeList[${IndexWorktime}].startedAt`}
                                      _label={'เริ่มต้น'}
                                      _value={itemWork.startedAt}
                                      _disabled={true}
                                    />
                                    <InputTimeComponentCustom
                                      _name={`workingTimeList[${IndexWorktime}].endedAt`}
                                      _label={'สิ้นสุด'}
                                      _value={itemWork.endedAt}
                                      _disabled={true}
                                    />
                                  </div>
                                </section>
                              ))}

                              <div className={styles.line}></div>
                              <section className={styles.Group}>
                                <div className={styles.head}>
                                  <p className={styles.TextHead}>การทำงานล่วงเวลา</p>
                                  <img
                                    src={AddIcon}
                                    alt=""
                                    onClick={() => {
                                      setOpenModalOT(true)
                                      props.setFieldValue('overtimeList', props.values.overtimeList)
                                    }}
                                  />
                                </div>
                                {props.values?.overtimeList?.map((itemOvertime: any, IndexOvertime: any) => (
                                  <section
                                    className={tabAction[IndexOvertime] === true ? styles.GroupOTCover : styles.GroupOT}
                                    key={IndexOvertime}
                                  >
                                    <div className={styles.headerButton}>
                                      {' '}
                                      <div className={styles.statusDoc}>
                                        <section
                                          className={
                                            itemOvertime.status === 'APPROVE'
                                              ? styles.pillApprove
                                              : itemOvertime.status === 'PENDING'
                                              ? styles.pillPending
                                              : itemOvertime.status === 'DRAFT'
                                              ? styles.pillDraft
                                              : itemOvertime.status === 'REJECT'
                                              ? styles.pillReject
                                              : styles.pillSuggest
                                          }
                                        >
                                          <p>{EStatus[`${itemOvertime.status}`]}</p>
                                        </section>
                                      </div>
                                    </div>

                                    <div className={styles.DocumentArea}>
                                      <div className={styles.FormGroup}>
                                        <div className={styles.filedInputFlex}>
                                          <p>ประเภทโอที</p>
                                          <InputSelectComponentCustom
                                            _name={`overtimeList[${IndexOvertime}].overtimeType`}
                                            _label={'ประเภทโอที'}
                                            _menuList={OTList}
                                            _value={itemOvertime.overtimeType}
                                            _disabled={
                                              itemOvertime.status === 'APPROVE' || itemOvertime.status === null
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                        <div className={styles.filedInput}>
                                          <p>ช่วงเวลา</p>

                                          <InputTimeComponentCustom
                                            _name={`overtimeList[${IndexOvertime}].startedAt`}
                                            _label={'เริ่มต้น'}
                                            _value={itemOvertime.startedAt}
                                            _disabled={
                                              itemOvertime.status === 'APPROVE' || itemOvertime.status === null
                                                ? true
                                                : false
                                            }
                                          />
                                          <InputTimeComponentCustom
                                            _name={`overtimeList[${IndexOvertime}].endedAt`}
                                            _label={'สิ้นสุด'}
                                            _value={itemOvertime.endedAt}
                                            _disabled={
                                              itemOvertime.status === 'APPROVE' || itemOvertime.status === null
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <img
                                          src={DetailIcon}
                                          style={{ cursor: 'pointer' }}
                                          alt=""
                                          onClick={() => handleTabAction(IndexOvertime)}
                                        />
                                      </div>
                                    </div>

                                    {tabAction[IndexOvertime] === true ? (
                                      <div className={styles.tabAction}>
                                        <section className={styles.AreaButton}>
                                          <img
                                            src={DetailIcon}
                                            style={{ cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => handleTabAction(IndexOvertime)}
                                          />
                                          {itemOvertime.status === 'APPROVE' ? (
                                            <div className={styles.buttonDocument}>ไปหน้าเอกสาร</div>
                                          ) : (
                                            <>
                                              <div className={styles.button}>
                                                <section
                                                  className={
                                                    itemOvertime.status === null
                                                      ? styles.buttonForm
                                                      : styles.buttonFormDisables
                                                  }
                                                  onClick={() => {
                                                    const eqTime = (t1: Date, t2: Date) =>
                                                      new Date(t1).toLocaleTimeString('th', { timeStyle: 'short' }) ===
                                                      new Date(t2).toLocaleTimeString('th', { timeStyle: 'short' })

                                                    swal('', {
                                                      title: 'บันทึกเอกสารโอที',
                                                      text: 'ไม่สามารถแก้ไขการเปลี่ยนแปลงได้',
                                                      buttons: ['ยกเลิก', 'บันทึกข้อมูล'],
                                                      icon: 'success',
                                                    }).then((savepending) => {
                                                      if (savepending) {
                                                        CreateOvertimeDocument({
                                                          input: {
                                                            date: otherprops.date,
                                                            employeeID: otherprops.employeeId,
                                                            startedAt: itemOvertime.startedAt,
                                                            endedAt: itemOvertime.endedAt,
                                                            status: 'PENDING',
                                                            overtimeType: itemOvertime.overtimeType,
                                                          },
                                                        }).then((res: any) => {
                                                          swal('', {
                                                            title: 'บันทึกรายการเรียบร้อย',
                                                            icon: 'success',
                                                          })
                                                          const _data = res.data.data.createOvertimeRequest
                                                          console.log(res)
                                                          props.values.overtimeList.push({
                                                            startedAt: itemOvertime.startedAt,
                                                            endedAt: itemOvertime.endedAt,
                                                            status: _data.status,
                                                            overtimeType: _data.overtimeType,
                                                            overtimeRequestID: _data.id,
                                                          })
                                                          props.setFieldValue(`overtimeList`, props.values.overtimeList)
                                                          props.values.overtimeList
                                                            .filter((e: any) => !e?.overtimeRequestID)
                                                            .forEach((ot: any) => {
                                                              const idx: any = props.values.overtimeList?.findIndex(
                                                                (e: any) =>
                                                                  eqTime(
                                                                    new Date(`${props.date}T${ot.startedAt}+0700`),
                                                                    e.startedAt,
                                                                  ),
                                                              )
                                                              if (
                                                                idx !== undefined &&
                                                                idx !== -1 &&
                                                                props.values.overtimeList &&
                                                                props.values.overtimeList[idx]
                                                              ) {
                                                                props.values.overtimeList.splice(idx, 1)
                                                                props.setFieldValue(
                                                                  `overtimeList`,
                                                                  props.values.overtimeList,
                                                                )
                                                                console.log(props.values.overtimeList, 'Val')
                                                              }
                                                            })
                                                        })
                                                      }
                                                    })
                                                  }}
                                                >
                                                  บันทึก
                                                </section>
                                                <section
                                                  className={
                                                    itemOvertime.status === null
                                                      ? styles.buttonForm
                                                      : styles.buttonFormDisables
                                                  }
                                                  onClick={() => {
                                                    const eqTime = (t1: Date, t2: Date) =>
                                                      new Date(t1).toLocaleTimeString('th', { timeStyle: 'short' }) ===
                                                      new Date(t2).toLocaleTimeString('th', { timeStyle: 'short' })
                                                    const NewReport = ResetAttendance(props.values)
                                                    swal('', {
                                                      title: 'บันทึกและอนุมัติเอกสารโอที',
                                                      text: 'ไม่สามารถแก้ไขการเปลี่ยนแปลงได้',
                                                      buttons: ['ยกเลิก', 'บันทึกข้อมูล'],
                                                      icon: 'success',
                                                    }).then((saveApprove) => {
                                                      if (saveApprove) {
                                                        CreateOvertimeDocument({
                                                          input: {
                                                            date: otherprops.date,
                                                            employeeID: otherprops.employeeId,
                                                            startedAt: itemOvertime.startedAt,
                                                            endedAt: itemOvertime.endedAt,
                                                            status: 'APPROVE',
                                                            overtimeType: itemOvertime.overtimeType,
                                                          },
                                                        }).then((res: any) => {
                                                          swal('', {
                                                            title: 'บันทึกรายการเรียบร้อย',
                                                            icon: 'success',
                                                          })
                                                          const _data = res.data.data.createOvertimeRequest
                                                          console.log(res)
                                                          props.values.overtimeList.push({
                                                            startedAt: itemOvertime.startedAt,
                                                            endedAt: itemOvertime.endedAt,
                                                            status: _data.status,
                                                            overtimeType: _data.overtimeType,
                                                            overtimeRequestID: _data.id,
                                                          })
                                                          props.setFieldValue(`overtimeList`, props.values.overtimeList)
                                                          console.log(
                                                            '===============================================',
                                                            props.values.overtimeList,
                                                          )
                                                          props.values.overtimeList
                                                            .filter((e: any) => !e?.overtimeRequestID)
                                                            .forEach((ot: any) => {
                                                              const idx: any = props.values.overtimeList?.findIndex(
                                                                (e: any) =>
                                                                  eqTime(
                                                                    new Date(`${props.date}T${ot.startedAt}+0700`),
                                                                    e.startedAt,
                                                                  ) &&
                                                                  eqTime(
                                                                    new Date(`${props.date}T${ot.endedAt}+0700`),
                                                                    e.endedAt,
                                                                  ),
                                                              )
                                                              console.log(idx, 'ggg')

                                                              if (
                                                                idx !== undefined &&
                                                                idx !== -1 &&
                                                                props.values.overtimeList &&
                                                                props.values.overtimeList[idx]
                                                              ) {
                                                                props.values.overtimeList.splice(idx, 1)
                                                                props.setFieldValue(
                                                                  `overtimeList`,
                                                                  props.values.overtimeList,
                                                                )
                                                                console.log(props.values.overtimeList, 'Val')
                                                              }
                                                            })

                                                          console.log(res, 'res')
                                                        })
                                                      }
                                                    })
                                                  }}
                                                >
                                                  บันทึกและอนุมัติ
                                                </section>
                                              </div>
                                              <div
                                                className={styles.buttonOT}
                                                onClick={() => {
                                                  setOpenModalmulti(true)
                                                  // swal('', {
                                                  //   title: 'กำหนดตัวคูณ OT',
                                                  //   content: {},
                                                  // })
                                                }}
                                              >
                                                กำหนดตัวคูณ OT
                                              </div>
                                              <div
                                                className={
                                                  itemOvertime.status === null
                                                    ? styles.buttonDeldisables
                                                    : styles.buttonDel
                                                }
                                                onClick={() => {
                                                  swal('', {
                                                    title: 'ลบข้อมูลเอกสาร',
                                                    text: 'ไม่สามารถแก้ไขการเปลี่ยนแปลงได้',
                                                    buttons: ['ยกเลิก', 'ลบข้อมูล'],
                                                    dangerMode: true,
                                                    icon: 'warning',
                                                  }).then((del) => {
                                                    if (del) {
                                                      props.values.overtimeList.splice(IndexOvertime, 1)
                                                      props.setFieldValue(`overtimeList`, props.values.overtimeList)
                                                      DelRequestOvertime({
                                                        input: {
                                                          employeeID: otherprops.employeeId,
                                                          id: itemOvertime.overtimeRequestID,
                                                        },
                                                      }).then((res: any) => {
                                                        swal('', {
                                                          icon: 'success',
                                                          title: 'ลบรายการเอกสารเรียบร้อย',
                                                          timer: 1500,
                                                        })
                                                        console.log(res, 'Del OK')
                                                      })
                                                    }
                                                  })
                                                }}
                                              >
                                                ลบ
                                              </div>
                                            </>
                                          )}
                                        </section>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </section>
                                ))}
                                <div className={styles.line}></div>
                              </section>
                              {attendance.dayType === 'WEEKEND' || attendance.dayType === 'HOLIDAY' ? (
                                ''
                              ) : (
                                <section className={styles.Group}>
                                  <div className={styles.head}>
                                    <p className={styles.TextHead}>ลา</p>
                                    <img
                                      src={AddIcon}
                                      alt=""
                                      onClick={() => {
                                        setOpenModalLeave(true)
                                        const leave = {
                                          leaveType: '',
                                          start: new Date(`${attendance.date}T05:00:00.000`),
                                          end: new Date(`${attendance.date}T06:00:00.000`),
                                          leaveDocumentAttachment: '',
                                          type: 'leave',
                                        }
                                        props.setFieldValue('leaveList', props.values.leaveList)
                                      }}
                                    />
                                  </div>
                                  {props.values?.leaveList?.map((itemLeave: any, Index: number) => (
                                    <>
                                      <section
                                        className={hiddenleave[Index] === true ? styles.GroupOTCover : styles.GroupOT}
                                        key={Index}
                                      >
                                        <div className={styles.headerButton}>
                                          <div className={styles.toggle}>
                                            <Switch
                                              disables={itemLeave.status === 'APPROVE' ? true : false}
                                              value={itemLeave.type}
                                              options={[
                                                { label: 'ลาไม่หักเงิน', value: 'leave' },
                                                { label: 'ลาหักเงิน', value: 'leaveDeduct' },
                                              ]}
                                              onChange={(value: any) => {
                                                if (value === 'leaveDeduct') {
                                                  props.setFieldValue(`leaveList[${Index}].type`, 'leaveDeduct')
                                                } else if (value === 'leave') {
                                                  props.setFieldValue(`leaveList[${Index}].type`, 'leave')
                                                }
                                              }}
                                            />
                                          </div>
                                          <div className={styles.statusDoc}>
                                            <section
                                              className={
                                                itemLeave.status === 'APPROVE'
                                                  ? styles.pillApprove
                                                  : itemLeave.status === 'PENDING'
                                                  ? styles.pillPending
                                                  : itemLeave.status === 'DRAFT'
                                                  ? styles.pillDraft
                                                  : styles.pillSuggest
                                              }
                                            >
                                              <p>{EStatus[`${itemLeave.status}`]}</p>
                                            </section>
                                          </div>
                                        </div>
                                        <div className={styles.DocumentArea}>
                                          <div className={styles.FormGroup}>
                                            <div className={styles.filedInputFlex}>
                                              <p>ประเภทการลา</p>
                                              <InputSelectComponentCustom
                                                _name={`leaveList[${Index}].leaveType`}
                                                _label={'ประเภทการลา'}
                                                _menuList={LeaveList}
                                                _value={itemLeave.leaveType}
                                                _disabled={
                                                  itemLeave.status === 'APPROVE' || itemLeave.leaveType === 'ABSENT'
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className={styles.filedInput}>
                                              <p>ช่วงเวลา</p>
                                              <InputTimeComponentCustom
                                                _name={`leaveList[${Index}].startedAt`}
                                                _label={'เริ่มต้น'}
                                                _value={itemLeave.startedAt}
                                                _disabled={
                                                  itemLeave.status === 'APPROVE' || itemLeave.leaveType === 'ABSENT'
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <InputTimeComponentCustom
                                                _name={`leaveList[${Index}].endedAt`}
                                                _label={'สิ้นสุด'}
                                                _value={itemLeave.endedAt}
                                                _disabled={
                                                  itemLeave.status === 'APPROVE' || itemLeave.leaveType === 'ABSENT'
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>

                                            {/* <div className={styles.filedInputFlex}>
                            <p>แนบไฟล์</p>
                            <UploadButton
                              _name={`leaveList[${Index}].leaveDocumentAttachment`}
                              _value={itemLeave.endedAt}
                            />
                          </div> */}
                                          </div>

                                          <div>
                                            <img
                                              src={DetailIcon}
                                              style={{ cursor: 'pointer' }}
                                              alt=""
                                              onClick={() => handleTabActionLeave(Index)}
                                            />
                                          </div>
                                        </div>

                                        {hiddenleave[Index] === true ? (
                                          <div className={styles.tabAction}>
                                            <section className={styles.AreaButton}>
                                              <img
                                                src={DetailIcon}
                                                style={{ cursor: 'pointer' }}
                                                alt=""
                                                onClick={() => handleTabActionLeave(Index)}
                                              />
                                              {itemLeave.status === 'APPROVE' ? (
                                                <div className={styles.buttonDocument}>ไปหน้าเอกสาร</div>
                                              ) : (
                                                <>
                                                  <div className={styles.button}>
                                                    <section
                                                      className={
                                                        itemLeave.status === null
                                                          ? styles.buttonForm
                                                          : styles.buttonFormDisables
                                                      }
                                                      onClick={() => {
                                                        console.log(documentLeave, 'documentLeave')

                                                        if (itemLeave.leaveType === 'ABSENT') {
                                                          setStartAbsent(itemLeave.startedAt)
                                                          setEndAbsent(itemLeave.endedAt)
                                                        }

                                                        setDocumentLeave(!documentLeave)
                                                      }}
                                                    >
                                                      สร้างเอกสารการลา
                                                    </section>
                                                  </div>
                                                  <div
                                                    className={
                                                      itemLeave.status === null
                                                        ? styles.buttonDeldisables
                                                        : styles.buttonDel
                                                    }
                                                    onClick={() => {
                                                      swal('', {
                                                        title: 'ลบข้อมูลเอกสาร',
                                                        text: 'ไม่สามารถแก้ไขการเปลี่ยนแปลงได้',
                                                        buttons: ['ยกเลิก', 'ลบข้อมูล'],
                                                        dangerMode: true,
                                                        icon: 'warning',
                                                      }).then((del) => {
                                                        if (del) {
                                                          props.values.leaveList.splice(Index, 1)
                                                          props.setFieldValue(`leaveList`, props.values.leaveList)
                                                          DelRequestLeave({
                                                            input: {
                                                              employeeID: otherprops.employeeId,
                                                              id: itemLeave.leaveRequestID,
                                                            },
                                                          }).then((res: any) => {
                                                            swal('', {
                                                              icon: 'success',
                                                              title: 'ลบรายการเอกสารเรียบร้อย',
                                                              timer: 1500,
                                                            })
                                                            console.log(res, 'Del OK')
                                                          })
                                                        }
                                                      })
                                                    }}
                                                  >
                                                    ลบ
                                                  </div>
                                                </>
                                              )}
                                            </section>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </section>
                                    </>
                                  ))}
                                  <div className={styles.line}></div>
                                </section>
                              )}
                            </div>
                          </div>
                          <section className={styles.LinkArea}>
                            <div className={styles.FlexGroup}>
                              {props.values.isLink === true ? (
                                <div className={styles.LinkText}>
                                  <img
                                    src={LinkIcon}
                                    alt=""
                                    onClick={() => {
                                      props.setFieldValue('isLink', false)
                                    }}
                                    className={styles.IconLink}
                                  />
                                  <div className={styles.grouptText}>
                                    <p className={styles.headerText}>ลิงค์เวลาให้สอดคล้องกัน</p>{' '}
                                    <p className={styles.textSub}>
                                      ลิงค์เวลา สามารถแก้ไขเวลาจากเครื่องสแกนนิ้วกับสร้างเอกสาร
                                      <br /> การลาได้เท่านั้น โดยผลรวมของข้อมูลจะสอดคล้องกัน
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className={styles.LinkText}>
                                  <img
                                    src={UnLinkIcon}
                                    alt=""
                                    onClick={() => {
                                      props.setFieldValue('isLink', true)
                                    }}
                                    className={styles.IconLink}
                                  />
                                  <div className={styles.grouptText}>
                                    <p className={styles.headerText}>ไม่ลิงค์เวลาให้สอดคล้องกัน</p>{' '}
                                    <p className={styles.textSub}>
                                      ไม่ลิงค์เวลา สามารถแก้ไขเวลาได้ทั้งหมดโดยผลรวมของข้อมูล จะไม่สอดคล้องกัน
                                    </p>
                                  </div>
                                </div>
                              )}{' '}
                            </div>
                            <SumCard
                              leaveList={props.values.leaveList}
                              date={attendance.date}
                              attendance={attendance}
                              value={props.values}
                              setFieldValue={props.setFieldValue}
                              report={ResetAttendance(props.values)}
                            />
                          </section>
                        </div>
                        <ModalLeave
                          openModalLeave={openModalLeave}
                          setOpenModalLeave={setOpenModalLeave}
                          values={props.values}
                          date={attendance.date}
                          attendance={attendance}
                          leaveList={props.values.leaveList}
                          setFieldValue={props.setFieldValue}
                        />
                        <ModalOverTime
                          openModalOT={openModalOT}
                          setOpenModalOT={setOpenModalOT}
                          values={props.values}
                          overtimeList={props.values.overtimeList}
                          setFieldValue={props.setFieldValue}
                          date={attendance.date}
                          attendance={attendance}
                        />
                        <ModalLeavePopper
                          startedAt={startAbsent}
                          endedAt={endAbsent}
                          open={documentLeave}
                          setOpen={setDocumentLeave}
                          values={props.values}
                          date={attendance.date}
                          attendance={attendance}
                          leaveList={props.values.leaveList}
                          setFieldValue={props.setFieldValue}
                        />
                        <ModalMultiplierOT open={openModalmulti} setOpen={setOpenModalmulti} />
                      </div>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        {/* )} */}
      </div>
      {/* )} */}
    </Modal>
  )
}

const defaultShift = {
  breakPolicy: [
    {
      dayList: [1, 2, 3, 4, 5, 6],
      timeList: [
        {
          endTime: '13:00',
          isScanEnd: true,
          isScanStart: false,
          startTime: '12:00',
        },
      ],
    },
  ],
  shiftID: '667cd6e9-432a-4202-baba-664e0a73e5ee',
  shiftName: 'Shift 1',
  Type: 'SHIFT',
  workDayPolicy: [
    {
      dayList: [1, 2, 3, 4, 5],
      timeList: [
        {
          endTime: '17:00',
          isScanEnd: true,
          isScanStart: true,
          startTime: '08:00',
        },
      ],
    },
    {
      dayList: [6],
      timeList: [
        {
          endTime: '15:00',
          isScanEnd: true,
          isScanStart: true,
          startTime: '08:00',
        },
      ],
    },
  ],
} as any

const AttendanceConfig = {
  breakEarlyPaidRate: 0.5,
  breakEarlyPaidType: 'MULTIPLE',
  breakOvertimeDedcutType: 'MULTIPLE',
  breakOvertimeDeductRate: 0.5,
  breakOvertimeQuotaMinute: 10,
  breakOvertimeQuotaType: 'MINUTE_PER_DAY',
  breakOvertimeType: 'NOT_EXCEED',
  isBreakEarly: true,
  isBreakEarlyDiligent: true,
  isBreakEarlyPaid: true,
  isBreakOvertime: true,
  isBreakOvertimeDeduct: true,
  isBreakOvertimeDiligent: true,
  isWorkInEarly: true,
  isWorkInEarlyDiligent: true,
  isWorkInEarlyPaid: true,
  isWorkInLate: true,
  isWorkInLateDeduct: true,
  isWorkInLateDiligent: true,
  isWorkOutEarly: true,
  isWorkOutEarlyDeduct: true,
  isWorkOutEarlyDiligent: true,
  isWorkOutLate: true,
  isWorkOutLateDiligent: true,
  isWorkOutLatePaid: true,
  isWorkOutLatePaidRate: 0.5,
  isWorkOutLatePaidType: 'MULTIPLE',
  maxBreakEarlyMinute: 15,
  maxBreakOvertimeMinute: 30,
  maxWorkInEarlyMinute: 15,
  maxWorkInLateMinute: 50,
  maxWorkInLateType: 'NOT_EXCEED',
  maxWorkOutEarlyMinute: 60,
  maxWorkOutLateMinute: 15,
  workInEarlyPaidRate: 0.5,
  workInEarlyPaidType: 'MULTIPLE',
  workInLateDeductRate: 0.5,
  workInLateDeductType: 'MULTIPLE',
  workInLateQuotaMinute: 30,
  workInLateQuotaType: 'MINUTE_PER_DAY',
  workOutEarlyDeductRate: 0.5,
  workOutEarlyDeductType: 'MULTIPLE',
  WorkOutEarlyQuotaMinute: 50,
  WorkOutEarlyQuotaType: 'MINUTE_PER_DAY',
  workOutEarlyType: 'NOT_EXEED',
  workOutLateQuotaType: 'MINUTE_PER_DAY',
  __typename: 'ATTENDANCE_CONFIG',
} as any
