import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Tooltip } from '@mui/material'
import styles from './index.module.scss'
//svg
import editIcon from '../../image/edit.svg'
import NoteIcon from '../../image/note.svg'
import quesIcon from '../../image/question.svg'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import NoteDataIcon from '../../image/notedata.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ModalAttendaneEdite } from './ModalAttendance'
import { QueryGetAttendance } from '../../adapter/xhr'
import { PooperRemark, PopperOverTime } from './popper'
import alertGrayIcon from '../../image/alertGray.svg'
import alertGreenIcon from '../../image/alertGreen.svg'
import alertWarningIcon from '../../image/alertWarnning.svg'
import alertRejectIcon from '../../image/alertRed.svg'
import { EDayType, ELeave, toTime } from './EType'
import { minuteToHour } from '../../utils/common'

export const TableAttendReport: React.FC<any> = (props: any): JSX.Element => {
  const [selectdate, setSelectDate] = React.useState<any>()
  const [openmodal, setOpenModal] = React.useState(false)
  const [openpopper, setOpenPopper] = React.useState(false)
  const [popperRemark, setPopperRemark] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [employeeId, setEmployeeId] = React.useState<any>()
  const [date, setDatePopper] = React.useState()
  const [employeePopper, setEmployeePopper] = React.useState()
  const [reportOT, setReportOT] = React.useState<any[]>([])

  const FindStatus = (data: any) => {
    const statusOT: any = []
    data?.map((item: any) => {
      return statusOT.push(item.status)
    })
    const findStatus = statusOT.find((item: any) => item === 'APPROVE')
    const findStatusPen = statusOT.find((item: any) => item === 'PENDING')
    const findStatusReject = statusOT.find((item: any) => item === 'REJECT')
    if (findStatus && findStatusPen && findStatusReject) {
      return 'PENDING'
    } else if (findStatus) {
      return 'APPROVE'
    } else if (findStatusPen) {
      return 'PENDING'
    } else if (findStatusReject) {
      return 'REJECT'
    } else {
      return 'NONE'
    }
  }

  //Colors Rows
  const statusStyle = (Type: any) => {
    switch (Type) {
      case 'ATTENDANCE':
        return '#FFFFFF'
        break
      case 'OVERTIME':
        return '#F8E8BD'
        break
      case 'LEAVE':
        return '#FFC5C8'
        break
      case 'WEEKEND':
        return '#DFDFDE'
        break
      case 'HOLIDAY':
        return '#E9C4E9'
        break
      case 'INCOMPLETE':
        return '#F5F4F6'
        break
      default:
        return '#FFFFFF'
    }
  }

  const Border = (Type: any) => {
    switch (Type) {
      case 'INCOMPLETE':
        return '#F32424'
        break
      default:
        return 'none'
    }
  }

  const QueryReport = () => {
    QueryGetAttendance({ date: date, employeeId: employeePopper }).then((res: any) => {
      const attenreport = res.data.data?.getTimeAttendanceReport
      const arr: any[] = []
      attenreport?.overtimeList.forEach((ot: any, index: number) => {
        if (ot.status !== null) return
        arr.push({ ...ot, type: 'overtime', index })
      })
      attenreport?.overtimeApproveList.forEach((ot: any) => {
        arr.push({ ...ot, type: 'overtimeApprove' })
      })
      setReportOT(arr.sort((a, b) => (a.type === 'overtime' ? -1 : 1)))
    })
  }

  React.useEffect(() => {
    QueryReport()
  }, [date, employeePopper])

  const handleClickPopper = (event: any) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper((previousOpen) => !previousOpen)
  }
  const handleClickPopperRemark = (event: any) => {
    setAnchorEl(event.currentTarget)
    setPopperRemark((previousOpen) => !previousOpen)
  }
  const _toTime = (date: Date | string) => {
    if (!isNaN(new Date(date).getTime())) {
      return toTime(date)
    } else {
      return date
    }
  }

  const Header = [
    'วันที่',
    'กะการทำงาน',
    'เวลาทำงานจริง',
    'ชั่วโมงการทำงานจริง',
    'การทำงานล่วงเวลา',
    'ลาหักเงิน',
    'ลาไม่หักเงิน',
    '',
  ]

  const OvertimeReducer = (OverTimeApproveList: any, OvertimeList: any) => {
    const OvertimeApproveMinutes = OverTimeApproveList?.map((item: any) => item.overtimeApproveMinute).reduce(
      (a: number, b: number) => a + b,
      0,
    )
    const OvertimeListMinutes = OvertimeList?.map((item: any) => item.overtimeMinute).reduce(
      (a: number, b: number) => a + b,
      0,
    )
    return minuteToHour(OvertimeApproveMinutes + OvertimeListMinutes)
  }

  return (
    <>
      {props.report ? (
        <div>
          <div>
            {props.report
              .filter((item: any) => {
                if (props.name === '') {
                  return item
                } else if (
                  item.employee.fullName.toLocaleLowerCase().includes(props.name.toLocaleLowerCase()) ||
                  item.employee.lastName.toLocaleLowerCase().includes(props.name.toLocaleLowerCase())
                ) {
                  return item
                }
              })
              .map((EmpItem: any, EmpIndex: number) => (
                <Accordion
                  key={EmpIndex}
                  sx={{
                    backgroundColor: '#CAD6EA',
                    borderRadius: '7px 7px 0px 0px',
                    border: 'none',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: '#CAD6EA',
                      borderRadius: '7px 7px 0px 0px',
                      border: 'none',
                    }}
                  >
                    <section className={styles.HeadEmployee}>
                      <span className={styles.title}>
                        แผนก : <span className={styles.subtext}>{EmpItem.employee.department?.departmentName}</span>
                      </span>
                      <div className={styles.Group}>
                        <span className={styles.title}>
                          ชื่อ - สกุล :
                          <span className={styles.subtext}>
                            {EmpItem.employee?.fullName} {EmpItem.employee?.lastName}
                          </span>
                        </span>
                        <span className={styles.title}>
                          รหัส : <span className={styles.subtext}>{EmpItem.employee?.id}</span>
                        </span>
                      </div>
                    </section>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {Header.map((item: any, Index: number) => (
                            <TableCell
                              align="center"
                              key={Index}
                              sx={{
                                position: 'sticky',
                                top: '70px',
                                backgroundColor: '#CAD6EA',
                                border: 'none',
                              }}
                            >
                              <p>{item}</p>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {EmpItem.items.map((itemRow: any, RowIndex: any) => (
                          <TableRow
                            sx={{
                              backgroundColor: statusStyle(itemRow.Type),
                              overflowX: 'scroll',
                              border: `1px solid ${Border(itemRow.Type)}`,
                            }}
                            key={RowIndex}
                            className={styles.tableR}
                          >
                            <TableCell align="left" sx={{ border: 'none' }}>
                              <div className={styles.GroupDate}>
                                <p>
                                  {new Date(`${itemRow.date.slice('', 10)}`).toLocaleDateString('th-TH', {
                                    weekday: 'long',
                                  })}
                                </p>

                                <div
                                  className={
                                    itemRow.dayType === 'WORKDAY'
                                      ? styles.pill
                                      : itemRow.dayType === 'WEEKEND'
                                      ? styles.pillWeek
                                      : itemRow.dayType === 'HOLIDAY'
                                      ? styles.pillHoliday
                                      : ''
                                  }
                                >
                                  <p className={styles.textDay}>{EDayType[`${itemRow.dayType}`]}</p>
                                </div>
                              </div>
                              <p> {itemRow.date.split('', 10)}</p>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              {itemRow.shiftName ? (
                                <Tooltip
                                  title={
                                    <p>
                                      {itemRow.shiftStartTime} - {itemRow.shiftEndTime}
                                    </p>
                                  }
                                  placement="top-start"
                                  arrow={true}
                                >
                                  <div className={styles.textShift}>
                                    <p> {itemRow.shiftName}</p> <img src={quesIcon} alt="" />
                                  </div>
                                </Tooltip>
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <div>
                                <div className={styles.group}>
                                  {itemRow.scanIn ? (
                                    <div className={itemRow.inDiffMinute > 0 ? styles.textScanLate : styles.textScan}>
                                      <p>{toTime(itemRow.scanIn)}</p>
                                      {itemRow.inDiffMinute === 0 ? (
                                        ''
                                      ) : (
                                        <div className={styles.groupPill}>
                                          <section className={styles.pillDiffEarly}>
                                            {itemRow.inDiffMinute > 0 ? <p>มาสาย</p> : <p>มาก่อน</p>}
                                          </section>
                                          {itemRow.inDiffMinute > 0 ? (
                                            <p className={styles.textIn}>+{itemRow.inDiffMinute}</p>
                                          ) : (
                                            <p className={styles.textIn}>{itemRow.inDiffMinute}</p>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    ''
                                  )}

                                  {itemRow.scanOut ? (
                                    <div
                                      className={itemRow.outDiffMinute < 0 ? styles.textScanLate : styles.textScanOut}
                                    >
                                      <p>{toTime(itemRow.scanOut)}</p>
                                      {itemRow.outDiffMinute === 0 ? (
                                        ''
                                      ) : (
                                        <div className={styles.groupPill}>
                                          <section className={styles.pillDiffEarly}>
                                            {itemRow.outDiffMinute < 0 ? <p>ออกก่อน</p> : <p>ออกหลัง</p>}
                                          </section>
                                          {itemRow.outDiffMinute > 0 ? (
                                            <p className={styles.textIn}>+{itemRow.outDiffMinute}</p>
                                          ) : (
                                            <p className={styles.textIn}>{itemRow.outDiffMinute}</p>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              {itemRow.scanIn && itemRow.scanOut ? <h4>{minuteToHour(itemRow?.workingMinute)}</h4> : ''}
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <div className={styles.overtimeGroup}>
                                <h4>{OvertimeReducer(itemRow.overtimeApproveList, itemRow.overtimeList)}</h4>

                                {/* <h4>{minuteToHour(itemRow.overtimeMinute + itemRow.overtimeApproveMinute)}</h4> */}
                                {minuteToHour(itemRow.overtimeMinute + itemRow.overtimeApproveMinute) && (
                                  <img
                                    src={
                                      FindStatus(itemRow?.overtimeList) === 'PENDING'
                                        ? alertWarningIcon
                                        : FindStatus(itemRow?.overtimeList) === 'APPROVE'
                                        ? alertGreenIcon
                                        : FindStatus(itemRow?.overtimeList) === 'REJECT'
                                        ? alertRejectIcon
                                        : FindStatus(itemRow?.overtimeList) === 'NONE'
                                        ? alertGrayIcon
                                        : ''
                                      // : FindStatus(itemRow?.overtimeList) === 'REJCET'
                                      // ? alertRejectIcon
                                      // : ''
                                    }
                                    alt=""
                                    className={styles.Icon}
                                    onClick={(event: any) => {
                                      handleClickPopper(event)
                                      setDatePopper(itemRow.date)
                                      setEmployeePopper(EmpItem.employeeID)
                                    }}
                                  />
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <h4>{minuteToHour(itemRow?.leaveDeductMinute)}</h4>

                              {/* <h4>{leaveMinute(itemRow.leaveDeductList, itemRow.breakTimeList, itemRow.date)}</h4> */}
                              <div className={styles.group}>
                                {itemRow?.leaveDeductList
                                  ?.slice(0, 2)
                                  .map((itemLeaveDeduct: any, IndexLeave: number) => (
                                    <div className={styles.groupPill} key={IndexLeave}>
                                      <section className={styles.pillDiffEarly}>
                                        {ELeave[`${itemLeaveDeduct.leaveType}`]}
                                      </section>
                                    </div>
                                  ))}
                              </div>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <h4>{minuteToHour(itemRow?.leaveMinute)}</h4>
                              {/* <h4>{leaveMinute(itemRow.leaveList, itemRow.breakTimeList, itemRow.date)}</h4> */}
                              <div className={styles.group}>
                                {itemRow?.leaveList?.slice(0, 2).map((itemLeave: any, IndexLeave: number) => (
                                  <div className={styles.groupPill} key={IndexLeave}>
                                    <section className={styles.pillDiffEarly}>
                                      {ELeave[`${itemLeave.leaveType}`]}
                                    </section>
                                  </div>
                                ))}
                              </div>
                            </TableCell>
                            <TableCell align="center" sx={{ border: 'none' }}>
                              <div className={styles.action}>
                                <img
                                  src={itemRow.remark === '' ? NoteIcon : NoteDataIcon}
                                  alt=""
                                  onClick={(event: any) => {
                                    handleClickPopperRemark(event)
                                    setDatePopper(itemRow.date)
                                    setEmployeePopper(EmpItem.employeeID)
                                  }}
                                  className={styles.NoteIcon}
                                />

                                <img
                                  src={editIcon}
                                  alt=""
                                  onClick={(event: any) => {
                                    setOpenModal(true)
                                    setSelectDate(itemRow.date)
                                    setEmployeeId(EmpItem.employeeID)
                                  }}
                                  className={styles.EditeIcon}
                                />
                              </div>{' '}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              ))}

            <ModalAttendaneEdite
              openmodal={openmodal}
              setOpenModal={setOpenModal}
              setListAttend={props.setListAttend}
              selectdate={selectdate}
              employeeId={employeeId}
              QueryReport={props.QueryReport}
              report={props.report}
              date={props.date}
              endAt={props.endDate}
            />
            <PopperOverTime
              openpopper={openpopper}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              report={props.report}
              reportOT={reportOT}
              date={date}
              employeeId={employeePopper}
              setListAttend={props.setListAttend}
              setOpenModal={setOpenModal}
              QueryReport={QueryReport}
            />
            <PooperRemark
              popperRemark={popperRemark}
              setPopperRemark={setPopperRemark}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              QueryReport={props.QueryReport}
              date={date}
              employeeId={employeePopper}
              endAt={props.endDate}
              startdate={props.date}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
