import * as React from 'react'
import styles from './index.module.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ViewIcon from '../LeaveDocument/image/viewBlue.svg'
import EditeIconDisabled from '../LeaveDocument/image/edite.svg'
import EditeIcon from '../LeaveDocument/image/editeBlue.svg'
import DeleteIconDisabled from '../LeaveDocument/image/delete.svg'
import DeleteIcon from '../LeaveDocument/image/deleteRed.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Profile from '../LeaveDocument/image/profile.svg'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ELeave, ELeavForm, EOverTime } from '../AttendanceReport/EType'
import { DateStyleShort, dateToTime, minuteToHour } from 'src/utils/common'
import { DeleteOvertimeDocument, ListLeaveDocument, ListOvertimeDocument } from 'src/adapter/xhr'
import { ModalEdite, ModalView } from './Modal'
import swal from 'sweetalert'

export const TableLeaveDocument: React.FC<any> = (props: any): JSX.Element => {
  const Header = ['วันที่', 'ช่วงเวลาทำ OT', 'ประเภท OT', 'รวมเวลา OT', 'การกระทำ', 'สถานะ']
  console.log(props, 'Listdocument')

  // const []
  const [OvertimeDocument, setOvertimeDocument] = React.useState<any>([])
  const [open, setOpen] = React.useState(false)
  const [openmodal, setOpenModal] = React.useState(false)

  const [documentID, setDocumentID] = React.useState('')
  const [employeeID, setEmployeeID] = React.useState('')
  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f5f4f6' }}>
            {Header.map((item: any, index: number) => (
              <TableCell align="center" key={index} sx={{ border: 'none' }}>
                <h4>{item}</h4>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
      {props.Listdocument?.length
        ? props.Listdocument.filter((OTItem: any) => {
            if (props.search === '') {
              return OTItem
            } else if (
              OTItem.employee.fullName.toLocaleLowerCase().includes(props.search.toLocaleLowerCase()) ||
              OTItem.employee.lastName.toLocaleLowerCase().includes(props.search.toLocaleLowerCase())
            ) {
              return OTItem
            }
          }).map((OTItem: any, LeaveIndex: number) => (
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
              key={LeaveIndex}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  position: 'sticky',
                  top: '0',
                  backgroundColor: '#EFF3FC',
                  border: 'none',
                  boxShadow: 'none',
                }}
              >
                <div className={styles.profileArea}>
                  <img src={Profile} alt="" />
                  <p>
                    {OTItem.employee.fullName} {OTItem.employee.lastName}
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Table aria-label="simple table">
                  <TableHead>
                    {OTItem.items.map((Document: any, DocIndex: number) => (
                      <TableRow key={DocIndex}>
                        <TableCell align="center" sx={{ border: 'none' }}>
                          <p>{DateStyleShort(Document.startedAt)}</p>
                        </TableCell>
                        <TableCell align="center" sx={{ border: 'none' }}>
                          <p>
                            {dateToTime(Document.startedAt)} - {dateToTime(Document.endedAt)}
                          </p>
                        </TableCell>
                        <TableCell align="center" sx={{ border: 'none' }}>
                          <p>{EOverTime[`${Document.overtimeType}`]}</p>
                        </TableCell>
                        <TableCell align="center" sx={{ border: 'none' }}>
                          <p>{minuteToHour(Document.overtimeMinute)}</p>
                        </TableCell>

                        <TableCell align="center" sx={{ border: 'none' }}>
                          <div className={styles.Action}>
                            <img
                              src={ViewIcon}
                              alt=""
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                console.log(Document.id, 'Document.id')
                                ListOvertimeDocument({
                                  nextToken: null,
                                  filter: {
                                    id: {
                                      beginsWith: Document.id,
                                    },
                                  },
                                }).then((res: any) => {
                                  setOvertimeDocument(res.data.data.listOvertimeGroupByEmployee.items[0].items)
                                })
                                setOpen(true)
                              }}
                            />
                            {Document.status === 'APPROVE' || Document.status === 'REJECT' ? (
                              <img src={EditeIconDisabled} alt="" style={{ pointerEvents: 'none' }} />
                            ) : (
                              <img
                                src={EditeIcon}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setOpenModal(true)
                                  setEmployeeID(Document.employee.id)
                                  setDocumentID(Document.id)
                                }}
                              />
                            )}
                            {Document.status === 'APPROVE' || Document.status === 'REJECT' ? (
                              <img src={DeleteIconDisabled} alt="" style={{ pointerEvents: 'none' }} />
                            ) : (
                              <img
                                src={DeleteIcon}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  console.log(Document.id, 'Document.id')
                                  swal('', {
                                    icon: 'warning',
                                    title: 'ลบเอกสารโอที',
                                    text: 'ไม่สามารถเปลี่ยนแปลงการแก้ไขได้',
                                    buttons: ['ยกเลิก', 'ลบข้อมูล'],
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      DeleteOvertimeDocument({
                                        input: { id: Document.id, employeeID: Document.employee.id },
                                      }).then((res: any) => {
                                        console.log(res, 'Delete OK')
                                      })
                                      setTimeout(() => {
                                        props.QueryListOvertimeDocument()
                                      }, 1000)
                                      swal('', {
                                        icon: 'success',
                                        title: 'ลบรายการเอกสารเรียบร้อยแล้ว',
                                      })
                                    }
                                  })
                                }}
                              />
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="center" sx={{ border: 'none' }}>
                          <div className={styles.status}>
                            <div
                              className={
                                Document.status === 'APPROVE'
                                  ? styles.pill
                                  : Document.status === 'REJECT'
                                  ? styles.pillReject
                                  : styles.pillPending
                              }
                            >
                              <p>{Document.status}</p>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableHead>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))
        : ''}
      <ModalView OvertimeDocument={OvertimeDocument} open={open} setOpen={setOpen} />
      <ModalEdite
        open={openmodal}
        setOpen={setOpenModal}
        documentID={documentID}
        employeeID={employeeID}
        QueryListOvertimeDocument={props.QueryListOvertimeDocument}
      />
    </>
  )
}
